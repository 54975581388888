import React from 'react';

import cx from 'classnames';
import {
  get,
  isEmpty,
} from 'lodash';
import { arrayOf } from 'prop-types';
import { Table } from 'semantic-ui-react';

import {
  column,
  tableState,
} from '../../../helpers/shapes';

const ASCENDING = "ascending";
const DESCENDING = "descending";

export const DataTableHeader = ({ columns, tableState }) => {
  const { onSortChange, sortOrder } = tableState;

  return (
    <Table.Header>
      <Table.Row>
        {!isEmpty(columns) &&
          columns.map(column => {
            const className = cx({ "sort-disabled": column.isSortDisabled, "d-none": column.isHidden });
            const sorted =
              !column.isSortDisabled &&
              column.path &&
              column.path === get(sortOrder, "column")
                ? get(sortOrder, "isAscending")
                  ? ASCENDING
                  : DESCENDING
                : null;

            return (
              <Table.HeaderCell
                data-html2canvas-ignore={column.isExcludeFromExport}
                className={className}
                key={column.title}
                width={column.width}
                sorted={sorted}
                onClick={() => {
                  if (column.isSortDisabled) {
                    return false;
                  }
                  onSortChange &&
                    onSortChange(column.path, sorted === ASCENDING);
                }}
              >
                {column.title}
              </Table.HeaderCell>
            );
          })}
      </Table.Row>
    </Table.Header>
  );
};

DataTableHeader.propTypes = {
  columns: arrayOf(column),
  tableState: tableState
};
