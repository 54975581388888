import cx from "classnames";
import React from "react";
import { Checkbox } from "semantic-ui-react";

export const FormCheckboxInput = ({
  input,
  label,
  size,
  className,
  autoComplete,
  meta: { touched, error }
}) => {
  const { name } = input;
  const isValid = !error;

  className = cx({ valid: isValid }, className);

  const checked = !!input.value;
  return (
    <div className="pt-2">
      <Checkbox
        size={size}
        label={label}
        name={name}
        checked={!!checked}
        autoComplete={autoComplete}
        className={className}
        onChange={event => {
          return input.onChange(checked ? 0 : 1);
        }}
      />
    </div>
  );
};
