import { schema } from 'normalizr';

import {
  REDUCER_NAME as NOTE_REDUCER_NAME,
} from '../../store/object/note/constants';
import { userSchema } from './user';

export const noteSchema = new schema.Entity(NOTE_REDUCER_NAME, {
  author: userSchema
});

export const notesSchema = [noteSchema];
