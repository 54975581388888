import * as reportConstants from "../constants";
import { makeReportConstants } from "../util";
export const REDUCER_NAME = "arsmMyActiveRoutes";

export const FETCH_RESULTS_URL = `${process.env.REACT_APP_API_URL}api/v2/arsm/my-active-routes`;

// Action Types
const defaultConstants = makeReportConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...reportConstants,
  REDUCER_NAME,
  FETCH_RESULTS_URL
};
