import { get, isNull, isString, isEmpty, isArray, isNaN } from "lodash";

export const composeValidators = (...validators) => (value, allValues) => 
  validators.reduce((error, validator) => error || validator(value, allValues), undefined);

export const requiredArray = (value) => {
  if (isArray(value)) {
    return value && value.length > 0 ? undefined : "Required";
  }
  return value ? undefined : "Required";
};

export const required = (value) => {
  if (isString(value)) {
    return value && value.trim() ? undefined : "Required";
  }
  return value ? undefined : "Required";
};

/**
 * Validation rule for non-negative input value
 * @param {*} value 
 * @returns {*} Validation error or undefined
 */
export const notNegative = (value) => {
  if (isString(value) && isEmpty(value.trim())) {
    return value && value.trim() ? undefined : "Required";
  }
  return parseFloat(value) >= 0 || isNaN(parseFloat(value)) ? undefined : "Cannot be negative";
};

/**
 * Validation rule for negative input value
 * @param {*} value 
 * @returns {*} Validation error or undefined
 */
export const notPositive = (value) => {
  if (isString(value) && isEmpty(value.trim())) {
    return value && value.trim() ? undefined : "Required";
  }
  return parseFloat(value) <= 0 || isNaN(parseFloat(value)) ? undefined : "Must be negative";
};

export const isWholeNumber = (value) => {
  return value % 1 === 0 ? undefined : "Must be a whole number";
};

export const isEmail = (value) => {
  return (
    value &&
    value.trim() &&
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  );
};

export const email = (value) => {
  return value &&
    value.trim() &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;
};

export const password = (value) => {
  return value &&
    value.trim() &&
    value.length < 6
    ? "Password must be at least 6 characters."
    : undefined;
};

export const date = (value) => {
  if(value === '' || isNull(value)) return undefined;
  const dateObject = new Date(value);
  return !(dateObject instanceof Date && !isNaN(dateObject));
};

export const requiredDate = (value) => {
  if(value === '' || isNull(value)) return 'Valid date is required';
  const dateObject = new Date(value);
  return !(dateObject instanceof Date && !isNaN(dateObject));
};

export const phone = (value) => {
  return value && value.trim() && value.length < 10
    ? "Invalid phone number"
    : undefined;
};
export const pin = (value) => {
  if (isNull(value)) {
    return undefined;
  }
  return value &&
    value.trim() &&
    (value.trim().length !== 4 || !/[0-9]+$/.test(value))
    ? "Invalid pin format"
    : undefined;
};
export const zipcode = (value) => {
  return value && value.trim() && value.match(/^\d{5}(?:[-\s]\d{4})?$/)
    ? undefined
    : "Invalid zip code";
};

export const matches = (value, allValues) => {
  if (value) {
    return value === get(allValues, "password")
      ? undefined
      : "Passwords Must Match";
  }
  return undefined;
};
