import { any } from "prop-types";
import React from "react";

export const OnOffCell = React.memo(({ value }) => {
  return <span className="on-off-cell pl-1">{value === "on" ? "Y" : "N"}</span>;
});

OnOffCell.propTypes = {
  value: any
};
