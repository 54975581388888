import React from "react";
import { Button, Card, Divider } from "semantic-ui-react";
import { Link } from 'react-router-dom';
import { StatsRow } from "../../StatsRow";
import { LINK_BED_INVOICE_LIST } from './../../../../../constants/links';

export const BedInvoicesCard = ({data}) => {
  return (
    <Card fluid>
      <Card.Content>
        <Card.Header className='card-tab-header'>
          Bed Invoices
        </Card.Header>
        <StatsRow simple sections={data} />
        <Divider className="mt-0" />
        <div>
          <Button as={Link} to={LINK_BED_INVOICE_LIST.route} basic>View All</Button>
        </div>
      </Card.Content>
    </Card>
  )
}
