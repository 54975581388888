import { bool, func, number } from "prop-types";
import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { MAX_LIMIT } from "../../../constants";
import CustomLimit from "../CustomLimit";

export const ListHeaderLimit = React.memo(
  ({
    limit,
    onLimitChange,
    total,
    customizable = false,
    maxLimit = MAX_LIMIT,
  }) => {
    return (
      <div className="list-header-limit d-flex">
        <span className="mr-1 fs-2">
          <strong>View lines:</strong>
        </span>

        <Button
          disabled={limit < 1}
          icon
          type="button"
          className="mr-1"
          size="mini"
          onClick={() => {
            onLimitChange(limit - 1);
          }}
        >
          <Icon name="minus" />
        </Button>

        {customizable ? (
          <CustomLimit
            limit={limit}
            maxLimit={maxLimit}
            onLimitChange={onLimitChange}
          />
        ) : (
          <span className="count mr-1 text-center c-blue">{limit || 0}</span>
        )}

        <Button
          disabled={limit >= maxLimit}
          icon
          type="button"
          size="mini"
          onClick={() => {
            onLimitChange(limit + 1);
          }}
        >
          <Icon name="plus" />
        </Button>

        {maxLimit !== MAX_LIMIT && <Button
          disabled={limit === maxLimit}
          icon
          type="button"
          size="mini"
          color="green"
          title={`View all (${maxLimit} lines)`}
          onClick={() => {
            onLimitChange(maxLimit);
          }}
        >
          <Icon name="list" />
        </Button> }
      </div>
    );
  }
);

ListHeaderLimit.propTypes = {
  limit: number,
  onLimitChange: func.isRequired,
  total: number,
  customizable: bool,
  maxLimit: number
};
