import { REPORT_REDUCER_NAME } from "../../report/constants";
import * as reportConstants from "../constants";
import { makeReportConstants } from "../util";
export const REDUCER_NAME = "valueByProductClass";
export const DATE = "date";
export const LOCATION_ID = "locationId";

export const SET_LOCATION_ID = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_LOCATION_ID`;
export const SET_DATE = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_DATE`;
export const FETCH_RESULTS_URL = `${process.env.REACT_APP_API_URL}admin/report/inventory_value_by_pc/:locationId/:date/json`;
export const FETCH_EXPORT_URL = `${process.env.REACT_APP_API_URL}admin/report/inventoryvalpc/:date/:type`;
export const FETCH_VALUES_BY_PRODUCT_CLASS_URL = `${process.env.REACT_APP_API_URL}admin/report/b/:productClassId/:locationId/:date/json`;

// Action Types
const defaultConstants = makeReportConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...reportConstants,
  DATE,
  LOCATION_ID,
  REDUCER_NAME,
  SET_LOCATION_ID,
  FETCH_RESULTS_URL,
  FETCH_EXPORT_URL,
  FETCH_VALUES_BY_PRODUCT_CLASS_URL,
  SET_DATE
};
