import { VIEW_REDUCER_NAME } from "../constants";
import * as viewConstants from "../constants";
import { makeViewConstants } from "../util";
export const REDUCER_NAME = "workOrders";

export const ASSIGNMENT_FILTER = "assignmentFilter";
export const ASSIGNMENT_FILTERED_LIST = "assignmentFilteredList";
export const ASSIGNMENT_FILTERED_OBJECTS = "assignmentFilteredObjects";

export const LOCATION_FILTER = "locationFilter";
export const LOCATION_FILTERED_LIST = "locationFilteredList";
export const LOCATION_FILTERED_OBJECTS = "locationFilteredObjects";

export const ROUTE_FILTER = "routeFilter";
export const ROUTE_FILTERED_LIST = "routeFilteredList";
export const ROUTE_FILTERED_OBJECTS = "routeFilteredObjects";

export const TYPE_FILTER = "typeFilter";
export const TYPE_FILTERED_LIST = "typeFilteredList";
export const TYPE_FILTERED_OBJECTS = "typeFilteredObjects";

export const STATUS_FILTER = "statusFilter";
export const STATUS_FILTERED_LIST = "statusFilteredList";
export const STATUS_FILTERED_OBJECTS = "statusFilteredObjects";

export const SET_ASSIGNMENT_FILTER = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_ASSIGNMENT_FILTER`;
export const SET_ASSIGNMENT_FILTERED_OBJECTS = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_ASSIGNMENT_FILTERED_OBJECTS`;
export const SET_ASSIGNMENT_FILTERED_LIST = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_ASSIGNMENT_FILTERED_LIST`;

export const SET_LOCATION_FILTER = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_LOCATION_FILTER`;
export const SET_LOCATION_FILTERED_OBJECTS = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_LOCATION_FILTERED_OBJECTS`;
export const SET_LOCATION_FILTERED_LIST = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_LOCATION_FILTERED_LIST`;

export const SET_ROUTE_FILTER = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_ROUTE_FILTER`;
export const SET_ROUTE_FILTERED_OBJECTS = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_ROUTE_FILTERED_OBJECTS`;
export const SET_ROUTE_FILTERED_LIST = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_ROUTE_FILTERED_LIST`;

export const SET_TYPE_FILTER = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_TYPE_FILTER`;
export const SET_TYPE_FILTERED_OBJECTS = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_TYPE_FILTERED_OBJECTS`;
export const SET_TYPE_FILTERED_LIST = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_TYPE_FILTERED_LIST`;

export const SET_STATUS_FILTER = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_STATUS_FILTER`;
export const SET_STATUS_FILTERED_OBJECTS = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_STATUS_FILTERED_OBJECTS`;
export const SET_STATUS_FILTERED_LIST = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_STATUS_FILTERED_LIST`;

// Action Types
const defaultConstants = makeViewConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...viewConstants,
  REDUCER_NAME,
  // ASSIGNMENT
  ASSIGNMENT_FILTERED_LIST,
  ASSIGNMENT_FILTER,
  SET_ASSIGNMENT_FILTER,
  SET_ASSIGNMENT_FILTERED_OBJECTS,
  ASSIGNMENT_FILTERED_OBJECTS,
  SET_ASSIGNMENT_FILTERED_LIST,
  // LOCATION
  LOCATION_FILTERED_LIST,
  LOCATION_FILTER,
  SET_LOCATION_FILTER,
  SET_LOCATION_FILTERED_OBJECTS,
  LOCATION_FILTERED_OBJECTS,
  SET_LOCATION_FILTERED_LIST,
  // ROUTE
  ROUTE_FILTERED_LIST,
  ROUTE_FILTER,
  SET_ROUTE_FILTER,
  SET_ROUTE_FILTERED_OBJECTS,
  ROUTE_FILTERED_OBJECTS,
  SET_ROUTE_FILTERED_LIST,
  // TYPE
  TYPE_FILTERED_LIST,
  TYPE_FILTER,
  SET_TYPE_FILTER,
  SET_TYPE_FILTERED_OBJECTS,
  TYPE_FILTERED_OBJECTS,
  SET_TYPE_FILTERED_LIST,
  // STATUS
  STATUS_FILTERED_LIST,
  STATUS_FILTER,
  SET_STATUS_FILTER,
  SET_STATUS_FILTERED_OBJECTS,
  STATUS_FILTERED_OBJECTS,
  SET_STATUS_FILTERED_LIST
};
