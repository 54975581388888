import { fromJS } from "immutable";
import { initialReportState } from "../reducer";

export const initialServiceCustomerReportState = constants => {
  return fromJS({
    ...initialReportState(constants).toJS(),
    [constants.BREAKDOWN]: [],
  });
};

export const serviceCustomerReportReducer = (state, action, constants) => {
  switch (action.type) {
    case constants.SET_BREAKDOWN:
      return state.setIn([constants.BREAKDOWN], fromJS(action.data));

    case constants.SET_IS_LOADED_BREAKDOWN:
      return state.setIn([constants.IS_LOADED_BREAKDOWN], action.isLoaded);

    case constants.SET_IS_LOADED_MAILER:
      return state.setIn([constants.IS_LOADED_MAILER], action.isLoaded);

    default:
      return state;
  }
};
