import dayjs from "dayjs";
import { fromJS } from "immutable";
import { initialViewState } from "../reducer";
import { END_DATE } from "./constants";

export const initialInventoryActivityViewState = constants => {
  const endDate = dayjs();
  return fromJS({
    ...initialViewState(constants).toJS(),
    [END_DATE]: endDate.format("MM/DD/YYYY")
  });
};

export const inventoryActivityReducer = (state, action, constants) => {
  switch (action.type) {
    case constants.SET_DATE:
      return state.set(END_DATE, action.date);
    default:
      return state;
  }
};
