import { get } from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  objectsSchema,
  routeSchema,
  routesSchema,
  upsertNormalizedEntities,
  usersSchema,
} from '../../../helpers/normalizers';
import { actions as routeRequestActions } from '../../request/route/actions';
import { sortAndLimitResults } from '../../view/actions';
import { actions as routeViewActions } from '../../view/route/actions';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';
import { routeSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(routeRequestActions.setLoadAllError(null));
    dispatch(routeRequestActions.setIsLoadingAll(true));
    try {
      const routes = await fetchObjectsFromAPI(constants.FETCH_LIST_URL);

      // Normalize the result and store the routes in redux
      const entities = get(normalize(routes, routesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));
      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(routes, tableState, (ids, count) => {
          dispatch(routeViewActions.setList(ids, count));
        })
      );
      dispatch(routeRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(routeRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(routeRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = id => {
  return async dispatch => {
    dispatch(routeRequestActions.setLoadError(null));
    dispatch(routeRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(`${constants.FETCH_URL}/${id}`);
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          route: routeSchema,
          allUsers: usersSchema
        }),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(routeRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(routeRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Convert an object before we save it on the api
 * @param data
 */
const convertObjectForSave = data => {
  return {
    ...data,
    users: data.users
      ? data.users.map(user => {
          return user.id;
        })
      : []
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(routeRequestActions.setSaveError(null));
    dispatch(routeRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.UPSERT_URL,
        convertObjectForSave(data)
      );
      const entities = get(
        normalize([result.route], routesSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.route);
      }
    } catch (e) {
      captureException(e);
      dispatch(routeRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(routeRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const routes = routeSelector().getDenormalizedObjects()(getState());
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(routes, tableState, (ids, count) => {
        dispatch(routeViewActions.setList(ids, count));
      })
    );
  };
};

export const actions = {
  ...objectActions,
  fetchObject,
  fetchObjects,
  updateSortFilterLimit,
  upsertObject
};
