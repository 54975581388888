import {
  get,
  keyBy,
  omit,
  uniq,
} from 'lodash';
import { normalize } from 'normalizr';
import { toast } from 'react-toastify';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  goodSchema,
  goodsSchema,
  inventoryActivitiesSchema,
  inventoryLotsSchema,
  invoicesSchema,
  notesSchema,
  objectsSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import { actions as goodRequestActions } from '../../request/good/actions';
import {
  actions as inventoryActivityRequestActions,
} from '../../request/inventoryActivity/actions';
import {
  actions as inventoryLotRequestActions,
} from '../../request/inventoryLot/actions';
import {
  actions as invoiceRequestActions,
} from '../../request/invoice/actions';
import { actions as noteRequestActions } from '../../request/note/actions';
import { sortAndLimitResults } from '../../view/actions';
import { actions as goodViewActions } from '../../view/good/actions';
import { goodViewSelector } from '../../view/good/selectors';
import {
  fetchNotesFromAPI,
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { INVENTORY_ACTIVITY_REDUCER_NAME } from '../inventoryActivity';
import { INVENTORY_LOT_REDUCER_NAME } from '../inventoryLot';
import { INVOICE_REDUCER_NAME } from '../invoice';
import { allConstants as constants } from './constants';
import { goodSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 */
export const fetchObjects = (tableState) => {
  return async (dispatch) => {
    dispatch(goodRequestActions.setLoadAllError(null));
    dispatch(goodRequestActions.setIsLoadingAll(true));
    try {
      const goods = await fetchObjectsFromAPI(constants.FETCH_LIST_URL);

      // Normalize the result and store the goods in redux
      const entities = get(normalize(goods, goodsSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      // Set all
      dispatch(goodViewActions.setAll(goods.map(good => good.id), goods.length));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(goods, tableState, (ids, count) => {
          dispatch(goodViewActions.setList(ids, count));
        })
      );
      dispatch(goodRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(goodRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(goodRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = (id) => {
  return async (dispatch) => {
    dispatch(goodRequestActions.setLoadError(null));
    dispatch(goodRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(`${constants.FETCH_URL}/${id}`);

      // Convert the crappy api values
      if (result.good) {
        result.good.active = parseInt(result.good.active, 10);
        result.good.poLines = result.poLines;
        result.good.goodVendors = result.goodVendors;
        result.good.prices = result.prices;
      }

      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          good: goodSchema,
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(goodRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(goodRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Save an object on the api
 * @param good
 * @param sellingPrices
 * @param goodVendors
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (good, sellingPrices, goodVendors = [], onSuccess) => {
  return async (dispatch) => {
    dispatch(goodRequestActions.setSaveError(null));
    dispatch(goodRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.UPSERT_URL,
        {
          good: convertObjectForSave(good),
          sellingPrices,
          goodVendors: keyBy(
            goodVendors.map((gv) => {
              return omit(gv, ["numbers"]);
            }),
            "id"
          ),
        },
        true,
        false,
        "data",
        good.id
      );

      const goodId = get(result, "good.id");
      if (goodId) {
        await dispatch(fetchObject(goodId));
      }

      if (onSuccess) {
        onSuccess(result.good);
      }
    } catch (e) {
      captureException(e);
      dispatch(goodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(goodRequestActions.setIsSaving(false));
    }
  };
};

const convertObjectForSave = (data) => {
  return {
    ...omit(data, ["poLines", "prices", "goodVendors"]),
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = (tableState) => {
  return async (dispatch, getState) => {
    const goods = goodSelector().getDenormalizedObjects()(getState());
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(goods, tableState, (ids, count) => {
        dispatch(goodViewActions.setList(ids, count));
      })
    );
  };
};

/**
 * Fetch the good counts for the given product class/location
 * @param locationId
 * @param productClassId
 * @param tableState
 * @returns {Function}
 */
export const fetchGoodCounts = (locationId, productClassId, tableState) => {
  return async (dispatch) => {
    dispatch(objectActions.reset());
    dispatch(goodRequestActions.setLoadAllError(null));
    dispatch(goodRequestActions.setIsLoadingAll(true));
    try {
      const result = await upsertObjectToAPI(
        constants.FETCH_GOOD_COUNTS_URL,
        {
          pcId: productClassId,
          locationId,
        },
        false,
        true,
        "data"
      );

      // Map the OH values onto the GOODs
      result.g.forEach((good) => {
        good.oh = result.oh[good.id] || { forsale: 0 };
      });

      const entities = get(normalize(result.g, goodsSchema), "entities", {});

      dispatch(upsertNormalizedEntities(entities));

      // Store the full list of ids in the reducer
      dispatch(
        goodViewActions.setRelatedObjects(
          constants.RELATION_GOODS,
          constants.ALL,
          result.g.map((good) => {
            return good.id;
          })
        )
      );

      dispatch(updateGoodSortFilterLimit(tableState));
    } catch (e) {
      captureException(e);
      dispatch(goodRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(goodRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the goods for the table
 * @param tableState
 * @returns {Function}
 */
export const updateGoodSortFilterLimit = (tableState) => {
  return async (dispatch, getState) => {
    const state = getState();
    const goods = goodViewSelector().getRelatedObjects(
      constants.RELATION_GOODS,
      constants.ALL
    )(state);

    // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(goods, tableState, (ids, count) => {
        dispatch(
          goodViewActions.setRelatedFilteredList(
            constants.RELATION_GOODS,
            constants.ALL,
            ids,
            count
          )
        );
      })
    );
  };
};

/**
 * Adjust the lot
 * @param data
 * @returns {Function}
 */
export const adjustInventory = (data) => {
  return async (dispatch) => {
    dispatch(objectActions.reset());
    dispatch(goodRequestActions.setSaveError(null));
    dispatch(goodRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.LOT_INVENTORY_CHANGE_URL,
        { receive: data },
        false,
        true,
        "inv"
      );
      toast.success("Lot Adjusted Successfully!");
    } catch (e) {
      captureException(e);
      dispatch(goodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(goodRequestActions.setIsSaving(false));
    }
  };
};


/**
 * Convert the good
 * @param data
 * @returns {Function}
 */
 export const convertInventory = (data) => {
  return async (dispatch) => {
    dispatch(goodRequestActions.setSaveError(null));
    dispatch(goodRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.GOOD_INVENTORY_CONVERT_URL,
        { convert: data },
        false,
        true,
        "conversion"
      );
      toast.success("Good Converted Successfully!");
      dispatch(objectActions.reset());
    } catch (e) {
      captureException(e);
      dispatch(goodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(goodRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Internal use adjust the lot
 * @param data
 * @returns {Function}
 */
export const intuseInventory = (data) => {
  return async (dispatch) => {
    dispatch(objectActions.reset());
    dispatch(goodRequestActions.setSaveError(null));
    dispatch(goodRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.LOT_INVENTORY_INTUSE_URL,
        { intuse: data },
        false,
        true,
        "inv"
      );
      toast.success("Lot Intuse Successful!");
    } catch (e) {
      captureException(e);
      dispatch(goodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(goodRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Shrink the lot
 * @param data
 * @returns {Function}
 */
export const shrinkInventory = (data) => {
  return async (dispatch) => {
    dispatch(objectActions.reset());
    dispatch(goodRequestActions.setSaveError(null));
    dispatch(goodRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.LOT_INVENTORY_SHRINK_URL,
        { shrinkage: data },
        false,
        true,
        "inv"
      );
      toast.success("Lot Shrinkage Successful!");
    } catch (e) {
      captureException(e);
      dispatch(goodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(goodRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Adjust the lot cost
 * @param lotId
 * @param cost
 * @returns {Function}
 */
export const changeLotCost = ({ lotId, cost }) => {
  return async (dispatch) => {
    dispatch(objectActions.reset());
    dispatch(goodRequestActions.setSaveError(null));
    dispatch(goodRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.LOT_COST_CHANGE_URL,
        {
          lotId,
          cost,
        },
        false,
        true,
        "data"
      );
      toast.success("Cost updated successfully!");
    } catch (e) {
      captureException(e);
      dispatch(goodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(goodRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Fetch the notes from the api and store their result in our reducer
 * @param id
 * @returns {Function}
 */
export const fetchNotes = (id) => {
  return async (dispatch) => {
    dispatch(noteRequestActions.setLoadAllError(null));
    dispatch(noteRequestActions.setIsLoadingAll(true));
    try {
      const notes = await dispatch(
        fetchNotesFromAPI(id, `${constants.FETCH_NOTE_LIST_URL}/${id}`)
      );

      dispatch(
        goodViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          id,
          notes.map((note) => {
            return note.id;
          })
        )
      );
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save a note on the api
 * @param parentId
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertNote = (parentId, data, onSuccess) => {
  return async (dispatch, getState) => {
    dispatch(noteRequestActions.setSaveError(null));
    dispatch(noteRequestActions.setIsSaving(true));
    try {
      data.kind = data.kind || "good";

      const result = await upsertObjectToAPI(constants.UPSERT_NOTE_URL, data);
      const entities = get(normalize([result], notesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      const notes = goodViewSelector().getNoteIds(parentId)(getState());
      const updatedIds = uniq([...notes, result.id]);
      dispatch(
        goodViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          parentId,
          updatedIds
        )
      );

      if (onSuccess) {
        onSuccess(notes);
      }
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Fetch the invoices associated with this good
 * @param goodId
 * @param tableState
 * @returns {Function}
 */
export const fetchInvoices = (goodId, tableState) => {
  return async (dispatch) => {
    dispatch(invoiceRequestActions.setLoadAllError(null));
    dispatch(invoiceRequestActions.setIsLoadingAll(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.FETCH_INVOICE_LIST_URL.replace(":goodId", goodId),
        "data.rows"
      );

      result.forEach((invoice) => {
        invoice.id = invoice.id || invoice.invoiceid;
      });

      const entities = get(normalize(result, invoicesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      // Store the full list of ids in the reducer
      dispatch(
        goodViewActions.setRelatedObjects(
          constants.RELATION_INVOICES,
          goodId,
          result.map((invoice) => {
            return invoice.id;
          })
        )
      );

      dispatch(updateInvoicesSortFilterLimit(goodId, tableState));
    } catch (e) {
      captureException(e);
      dispatch(invoiceRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(invoiceRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the invoices for the table
 * @param goodId
 * @param tableState
 * @returns {Function}
 */
export const updateInvoicesSortFilterLimit = (goodId, tableState) => {
  return async (dispatch, getState) => {
    const state = getState();
    const invoices = goodViewSelector().getRelatedObjects(
      INVOICE_REDUCER_NAME,
      goodId
    )(state);

    // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(invoices, tableState, (ids, count) => {
        dispatch(
          goodViewActions.setRelatedFilteredList(
            constants.RELATION_INVOICES,
            goodId,
            ids,
            count
          )
        );
      })
    );
  };
};

/**
 * Fetch the activities associated with this good
 * @param goodId
 * @param tableState
 * @returns {Function}
 */
export const fetchActivities = (goodId, tableState) => {
  return async (dispatch) => {
    dispatch(inventoryActivityRequestActions.setLoadAllError(null));
    dispatch(inventoryActivityRequestActions.setIsLoadingAll(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.FETCH_ACTIVITY_LIST_URL.replace(":goodId", goodId),
        "data.rows"
      );

      const entities = get(
        normalize(result, inventoryActivitiesSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Store the full list of ids in the reducer
      dispatch(
        goodViewActions.setRelatedObjects(
          constants.RELATION_INVENTORY_ACTIVITIES,
          goodId,
          result.map((inventoryActivity) => {
            return inventoryActivity.id;
          })
        )
      );

      dispatch(updateActivitiesSortFilterLimit(goodId, tableState));
    } catch (e) {
      captureException(e);
      dispatch(
        inventoryActivityRequestActions.setLoadAllError(getErrorMessage(e))
      );
    } finally {
      dispatch(inventoryActivityRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the activities for the table
 * @param goodId
 * @param tableState
 * @returns {Function}
 */
export const updateActivitiesSortFilterLimit = (goodId, tableState) => {
  return async (dispatch, getState) => {
    const state = getState();
    const activities = goodViewSelector().getRelatedObjects(
      INVENTORY_ACTIVITY_REDUCER_NAME,
      goodId
    )(state);

    // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(activities, tableState, (ids, count) => {
        dispatch(
          goodViewActions.setRelatedFilteredList(
            constants.RELATION_INVENTORY_ACTIVITIES,
            goodId,
            ids,
            count
          )
        );
      })
    );
  };
};

/**
 * Fetch the inventory lots associated with this good
 * @param goodId
 * @param tableState
 * @returns {Function}
 */
export const fetchLots = (goodId, locationId, tableState) => {
  return async (dispatch) => {
    dispatch(inventoryLotRequestActions.setLoadAllError(null));
    dispatch(inventoryLotRequestActions.setIsLoadingAll(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.FETCH_LOTS_LIST_URL.replace(":goodId", goodId).replace(
          ":locationId",
          locationId
        ),
        "data.lots"
      );

      result.forEach((lot) => {
        lot.id = lot.id || lot.lot_id;
      });

      const entities = get(
        normalize(result, inventoryLotsSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Store the full list of ids in the reducer
      dispatch(
        goodViewActions.setRelatedObjects(
          constants.RELATION_INVENTORY_LOTS,
          goodId,
          result.map((lot) => {
            return lot.id;
          })
        )
      );
      dispatch(updateLotsSortFilterLimit(goodId, tableState));
      dispatch(inventoryLotRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(inventoryLotRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(inventoryLotRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Reset list of lots.
 */
export const resetLots = () => {
  return async dispatch => {
    dispatch(goodViewActions.reset(constants.RELATION_INVENTORY_LOTS));
    dispatch(inventoryLotRequestActions.setLoadAllError(null));
    dispatch(inventoryLotRequestActions.setIsLoadedAll(false));
    dispatch(inventoryLotRequestActions.setIsLoadingAll(false));
  };
};

/**
 * Filter, Sort, and Trim the lots
 * @param goodId
 * @param tableState
 * @returns {Function}
 */
export const updateLotsSortFilterLimit = (goodId, tableState) => {
  return async (dispatch, getState) => {
    const state = getState();
    const lots = goodViewSelector().getRelatedObjects(
      INVENTORY_LOT_REDUCER_NAME,
      goodId
    )(state);
    // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(lots, tableState, (ids, count) => {
        dispatch(
          goodViewActions.setRelatedFilteredList(
            constants.RELATION_INVENTORY_LOTS,
            goodId,
            ids,
            count
          )
        );
      })
    );
  };
};

export const actions = {
  ...objectActions,
  adjustInventory,
  convertInventory,
  intuseInventory,
  shrinkInventory,
  changeLotCost,
  fetchGoodCounts,
  fetchActivities,
  fetchInvoices,
  fetchLots,
  fetchNotes,
  fetchObject,
  fetchObjects,
  updateGoodSortFilterLimit,
  updateSortFilterLimit,
  updateActivitiesSortFilterLimit,
  updateInvoicesSortFilterLimit,
  upsertNote,
  upsertObject,
  resetLots,
};
