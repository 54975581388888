import { REPORT_REDUCER_NAME } from "../constants";
import * as reportConstants from "../constants";
import { makeReportConstants } from "../util";
export const REDUCER_NAME = "serviceCustomer";
export const BREAKDOWN = "breakdown";
export const IS_LOADED_BREAKDOWN = "isLoadedBreakdown";
export const SET_BREAKDOWN = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_BREAKDOWN`;
export const SET_IS_LOADED_BREAKDOWN = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_IS_LOADED_BREAKDOWN`;

export const MAILER = "mailer";
export const IS_LOADED_MAILER = "isLoadedMailer";
export const SET_MAILER = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_MAILER`;
export const SET_IS_LOADED_MAILER = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_IS_LOADED_MAILER`;

export const FETCH_RESULTS_URL = `${process.env.REACT_APP_API_URL}api/v2/reports/service-customer`;
export const FETCH_BREAKDOWN_URL = `${process.env.REACT_APP_API_URL}admin/report/svccustbreakdown`;
export const FETCH_MAILER_URL = `${process.env.REACT_APP_API_URL}admin/report/svccustmailer`;

// Action Types
const defaultConstants = makeReportConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...reportConstants,
  REDUCER_NAME,
  FETCH_RESULTS_URL,
  FETCH_BREAKDOWN_URL,
  FETCH_MAILER_URL,
  SET_BREAKDOWN,
  IS_LOADED_BREAKDOWN,
  SET_IS_LOADED_BREAKDOWN,
  BREAKDOWN,
  SET_MAILER,
  IS_LOADED_MAILER,
  SET_IS_LOADED_MAILER,
  MAILER,
};
