import React from "react";
import { get } from "lodash";
import { any, object, number } from "prop-types";
import cx from "classnames";
import { formatCurrency } from "../../../../helpers/util";

export const CurrencyCell = React.memo(
  ({ value, decimalPlaces = 2, rendererProps = null }) => {
    if (!parseInt(value, 10)) {
      decimalPlaces = 0;
    }
    const classes = cx({
      "currency-cell": true,
      [get(rendererProps, "className")]: true,
    });
    return <span className={classes}>{formatCurrency(value)}</span>;
  }
);

CurrencyCell.propTypes = {
  value: any,
  decimalPlaces: number,
  rendererProps: object,
};
