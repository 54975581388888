import { fetchFileStreamFromAPI, fetchObjectsFromAPI } from "../../../../store/object/actions";
export const FETCH_API_CHANGELOG_URL = `${process.env.REACT_APP_API_URL}api/v2/changelog`;

export const fetchApiChangelog = async () => {
  const blob = await fetchFileStreamFromAPI(
    FETCH_API_CHANGELOG_URL,
    'CHANGELOG.md',
    'text/markdown',
    true,
  );
  return blob;
};

export const fetchExtendedSearchResults = async (apiEndpoint) => {
  const results = await fetchObjectsFromAPI(apiEndpoint, 'data.data.results')
  return results;
};
