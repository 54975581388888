import { fromJS } from "immutable";
import { initialReportState } from "../reducer";
import { PERIOD_ID } from "./constants";

export const initialTransferReportState = constants => {
  return fromJS({
    ...initialReportState(constants).toJS(),
    [PERIOD_ID]: null
  });
};

export const transferReportReducer = (state, action, constants) => {
  switch (action.type) {
    case constants.SET_PERIOD_ID:
      return state.set(PERIOD_ID, action.periodId);
    default:
      return state;
  }
};
