import { schema } from 'normalizr';

import {
  REDUCER_NAME as ROUTE_REDUCER_NAME,
} from '../../store/object/route/constants';
import { locationSchema } from './location';
import { userSchema } from './user';

export const routeSchema = new schema.Entity(ROUTE_REDUCER_NAME, {
  location: locationSchema,
  routeleader: userSchema,
  supervisor: userSchema
});

export const routesSchema = [routeSchema];
