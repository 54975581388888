import React from "react";
import { Button, Icon, Popup } from "semantic-ui-react";
import {
  EXPORT_TYPE_COPY,
  EXPORT_TYPE_EXCEL,
  EXPORT_TYPE_PDF
} from "../../../constants";

export const ExportPopup = React.memo(({ onClick }) => {
  return (
    <Popup
      flowing
      hoverable
      trigger={
        <Button primary className="btn-link" type="button" size="mini">
          Export List
          <Icon name="arrow alternate circle right outline" />
        </Button>
      }
    >
      <Button
        className="mr-4"
        type="button"
        primary
        onClick={() => {
          onClick(EXPORT_TYPE_COPY);
        }}
      >
        Copy
      </Button>
      <Button
        className="mr-4"
        type="button"
        color="green"
        onClick={() => {
          onClick(EXPORT_TYPE_EXCEL);
        }}
      >
        Excel
      </Button>
      <Button
        className="mr-4"
        type="button"
        color="red"
        onClick={() => {
          onClick(EXPORT_TYPE_PDF);
        }}
      >
        PDF
      </Button>
    </Popup>
  );
});
