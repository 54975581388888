import React, { useState } from "react";
import { object, array, func } from "prop-types";
import { get, isNull } from "lodash";
import { Button, Card, Icon, Image, Label, Reveal } from "semantic-ui-react";
import { toast } from "react-toastify";
import { PageFiller } from "../../../../../components/PageFiller";
import { PhotoUploadModal } from "../../../../../components/PhotoUploadModal";
import { CustomerAddresses } from "../CustomerAddresses";
import { imageUri } from "../../../../../helpers/util";
import { UpdateCustomerInfoModal } from "./UpdateCustomerInfoModal";

export const CustomerInfo = ({
  history,
  customer,
  customerIsLoading,
  account,
  accounts,
  openAccountSwitchModal,
  uploadProfilePhoto,
  upsertPortalObject,
  isSaving,
}) => {
  const [photoUploadModalIsShowing, setPhotoUploadModalIsShowing] = useState();
  const [
    updateCustomerModalIsShowing,
    setUpdateCustomerModalIsShowing,
  ] = useState();
  const customerPhoto = get(customer, "photo")
    ? imageUri(get(customer, "photo"))
    : null;
  const customerName = !!customer ? `${get(customer, "first_name")} ${get(customer, "last_name")}` : get(account, "customer_name");
  return (
    <Card className={"sidebar-customer-card b-0 pb-0"}>
      {isNull(account) ? (
        <div className="pb-2">
          <PageFiller
            lines={7}
            header={
              <div>
                <div className="dynamic-avatar-container my-2">
                  <div className='default-avatar'></div>
                </div>
              </div>
            }
          />
        </div>
      ) : (
        <Card.Content>
          <div className="dynamic-avatar-container my-2">
            {!customerIsLoading ? (
              <>
                <div
                  className="green-shadow pointer"
                  onClick={() => {
                    setPhotoUploadModalIsShowing(true);
                  }}
                >
                  <Icon name="plus" className="text-white" />
                </div>
                {customerPhoto ? <Image src={customerPhoto} size="massive" avatar /> : <div className='default-avatar'></div>}
              </>
            ) : (
              <div className="circle-loader">
                <Icon name="circle notched" loading />
              </div>
            )}
            <PhotoUploadModal
              open={photoUploadModalIsShowing}
              loading={customerIsLoading}
              onClose={() => {
                setPhotoUploadModalIsShowing(false);
              }}
              onUpload={(file) => {
                uploadProfilePhoto(
                  get(customer, "id"),
                  file,
                  (successResponse) => {
                    // Close modal and update customer.photo
                    toast.success("Success!", {
                      position: "bottom-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    setPhotoUploadModalIsShowing(false);
                  },
                  (errorResponse) => {
                    // Display error message.
                    toast.warn("Uh oh, there was an error.", {
                      position: "bottom-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    setPhotoUploadModalIsShowing(false);
                  }
                );
              }}
            />
          </div>
          <div className="fw-800 mb-2">{customerName}</div>
          <Reveal animated="move" instant disabled={accounts.length < 2}>
            <Reveal.Content visible>
              <Label color="green" size="large">
                Act: #{get(account, "account_number")}
              </Label>
            </Reveal.Content>
            <Reveal.Content hidden>
              <Button size="mini" onClick={openAccountSwitchModal}>
                Switch Account
              </Button>
            </Reveal.Content>
          </Reveal>

          {!customerIsLoading && (
            <>
              <CustomerAddresses customer={customer} />

              <div className="my-2 pl-2">
                Home Phone: {get(customer, "homephone") || "N/A"}
              </div>
              <div className="my-2 pl-2">
                Cell Phone: {get(customer, "cellphone") || "N/A"}
              </div>
              <div className="my-2 pl-2">
                Email: {get(customer, "email") || "N/A"}
              </div>

              <Button
                className="portal-button px-4 ml-2"
                onClick={() => {
                  setUpdateCustomerModalIsShowing(true);
                }}
              >
                Edit Information
              </Button>
            </>
          )}
        </Card.Content>
      )}
      <UpdateCustomerInfoModal
        open={updateCustomerModalIsShowing}
        onClose={() => {
          setUpdateCustomerModalIsShowing(false);
        }}
        onSubmit={(values) => {
          upsertPortalObject(values, (response) => {
            setUpdateCustomerModalIsShowing(false);
          })
        }}
        customer={customer}
        isSaving={isSaving}
      />
    </Card>
  );
};

CustomerInfo.propTypes = {
  account: object,
  accounts: array,
  openAccountSwitchModal: func,
  history: object,
  customer: object,
  upsertPortalObject: func,
};

CustomerInfo.defaultProps = {
  accounts: [],
};
