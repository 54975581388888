import { schema } from 'normalizr';

import {
  REDUCER_NAME as SERVICE_VISIT_REDUCER_NAME,
} from '../../store/object/serviceVisit/constants';
import { customerSchema } from './customer';
import { userSchema } from './user';

export const serviceVisitSchema = new schema.Entity(
  SERVICE_VISIT_REDUCER_NAME,
  { user: userSchema, customer: customerSchema }
);

export const serviceVisitsSchema = [serviceVisitSchema];
