import { schema } from 'normalizr';

import {
  REDUCER_NAME as GoodType_REDUCER_NAME,
} from '../../store/object/goodType/constants';
import { productClassSchema } from './productClass';
import { vendorsSchema } from './vendor';

export const goodTypeSchema = new schema.Entity(GoodType_REDUCER_NAME, {
  product_class: productClassSchema,
  vendors: vendorsSchema
});

export const goodTypesSchema = [goodTypeSchema];
