import {
  get,
  uniq,
} from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  locationSchema,
  locationsSchema,
  locationTypesSchema,
  notesSchema,
  objectsSchema,
  profitCentersSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import {
  actions as locationRequestActions,
} from '../../request/location/actions';
import { actions as noteRequestActions } from '../../request/note/actions';
import { sortAndLimitResults } from '../../view/actions';
import { actions as locationViewActions } from '../../view/location/actions';
import { locationViewSelector } from '../../view/location/selectors';
import {
  fetchNotesFromAPI,
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';
import { locationSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(locationRequestActions.setLoadAllError(null));
    dispatch(locationRequestActions.setIsLoadingAll(true));
    try {
      const locations = await fetchObjectsFromAPI(constants.FETCH_LIST_URL);

      // Normalize the result and store the locations in redux
      const entities = get(
        normalize(locations, locationsSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(locations, tableState, (ids, count) => {
          dispatch(locationViewActions.setList(ids, count));
        })
      );
      dispatch(locationRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(locationRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(locationRequestActions.setIsLoadingAll(false));
    }
  };
};

export const fetchObject = id => {
  return async dispatch => {
    dispatch(locationRequestActions.setLoadError(null));
    dispatch(locationRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(`${constants.FETCH_URL}/${id}`);

      // Convert the location types
      const locationTypes = [];
      Object.keys(result.locationTypes).forEach(key => {
        locationTypes.push({ id: key, name: result.locationTypes[key] });
      });
      result.locationTypes = locationTypes;

      // Normalize the result and store the roles/users in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          location: locationSchema,
          locationTypes: locationTypesSchema,
          profitcenters: profitCentersSchema
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));
      dispatch(locationRequestActions.setIsLoaded(id, true));
    } catch (e) {
      captureException(e);
      dispatch(locationRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(locationRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(locationRequestActions.setSaveError(null));
    dispatch(locationRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(constants.UPSERT_URL, data);
      const entities = get(
        normalize([result.location], locationsSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.location);
      }
    } catch (e) {
      captureException(e);
      dispatch(locationRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(locationRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Fetch the notes from the api and store their result in our reducer
 * @param id
 * @returns {Function}
 */
export const fetchNotes = id => {
  return async dispatch => {
    dispatch(noteRequestActions.setLoadAllError(null));
    dispatch(noteRequestActions.setIsLoadingAll(true));
    try {
      const notes = await dispatch(
        fetchNotesFromAPI(id, `${constants.FETCH_NOTE_LIST_URL}/${id}`)
      );
      dispatch(
        locationViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          id,
          notes.map(note => {
            return note.id;
          })
        )
      );
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save a note on the api
 * @param parentId
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertNote = (parentId, data, onSuccess) => {
  return async (dispatch, getState) => {
    dispatch(noteRequestActions.setSaveError(null));
    dispatch(noteRequestActions.setIsSaving(true));
    try {
      data.kind = data.kind || "location";

      const result = await upsertObjectToAPI(constants.UPSERT_NOTE_URL, data);
      const entities = get(normalize([result], notesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      const notes =
        locationViewSelector().getNoteIds(parentId)(getState()) || [];
      const updatedIds = uniq([...notes, result.id]);
      dispatch(
        locationViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          parentId,
          updatedIds
        )
      );

      if (onSuccess) {
        onSuccess(notes);
      }
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const locations = locationSelector().getDenormalizedObjects()(getState());
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(locations, tableState, (ids, count) => {
        dispatch(locationViewActions.setList(ids, count));
      })
    );
  };
};

export const actions = {
  ...objectActions,
  updateSortFilterLimit,
  fetchNotes,
  upsertNote,
  fetchObject,
  fetchObjects,
  upsertObject
};
