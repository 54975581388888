import React, {
  useEffect,
  useState,
} from 'react';

import cx from 'classnames';
import {
  arrayOf,
  bool,
  func,
  number,
  object,
  string,
} from 'prop-types';
import { connect } from 'react-redux';
import { Select } from 'semantic-ui-react';
import { filter } from 'lodash';
import { createOptionsFromUsers } from '../../../helpers/form/util';
import {
  actions as userObjectActions,
} from '../../../store/object/user/actions';
import { userSelector } from '../../../store/object/user/selectors';
import { userRequestSelector } from '../../../store/request/user/selectors';

const UserSelectComponent = ({
  disabled,
  fetchUsers,
  id,
  input,
  isLoadedAll,
  isLoadingAll,
  loadAllError,
  label,
  userId,
  users,
  onSelectUser,
  onSelectUserId,
  placeholder,
  required,
  multi,
  filterBy = false
}) => {
  if (input && input.value) {
    userId = input.value;
  }
  useEffect(() => {
    if (!isLoadedAll && !isLoadingAll && !loadAllError) {
      fetchUsers();
    }
  }, [isLoadedAll, isLoadingAll, fetchUsers, loadAllError]);

  const [hasFocus, setHasFocus] = useState(false);

  let userOptions = []
  if(filterBy){
    userOptions = createOptionsFromUsers( filter(users, filterBy) );
  } else {
    userOptions = createOptionsFromUsers(users);
  }
  
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true,
    "has-focus": hasFocus,
    "has-value": !!userId
  });
  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <Select
        upward={false}
        fluid
        disabled={disabled}
        size="huge"
        value={userId}
        search
        selection
        clearable
        name="user"
        loading={isLoadingAll}
        placeholder={placeholder}
        multiple={multi}
        options={userOptions}
        onOpen={() => {
          setHasFocus(true);
        }}
        openOnFocus={false}
        onClose={() => {
          setHasFocus(false);
        }}
        onChange={(e, { value }) => {
          if (onSelectUser) {
            onSelectUser(users.find(user => user.id === value));
          }
          if (onSelectUserId) {
            onSelectUserId(value);
          }
          if (input && input.onChange) {
            input.onChange(value);
          }
        }}
        onBlur={(e, { value }) => {
          if (value && input && input.onChange) {
            input.onChange(value);
          }
        }}
      />
    </div>
  );
};

UserSelectComponent.propTypes = {
  fetchUsers: func.isRequired,
  isLoadedAll: bool,
  isLoadingAll: bool,
  loadAllError: string,
  userId: number,
  onSelectUser: func,
  onSelectUserId: func,
  placeholder: string,
  users: arrayOf(object)
};

const mapStateToProps = (state, props) => {
  const userObjectSelector = userSelector();
  const requestSelector = userRequestSelector();
  return {
    isLoadingAll: requestSelector.getIsLoadingAll()(state),
    isLoadedAll: requestSelector.getIsLoadedAll()(state),
    loadAllError: requestSelector.getLoadAllError()(state),
    users: userObjectSelector.getDenormalizedObjects()(state)
  };
};

export const UserSelect = connect(
  mapStateToProps,
  {
    fetchUsers: userObjectActions.fetchObjects
  }
)(UserSelectComponent);
