import { get } from 'lodash';

import {
  filterCollection,
  sortAndLimit,
} from '../../components/DataTable/util';
import { DEFAULT_TABLE_STATE } from '../../constants';
import { REPORT_REDUCER_NAME } from './constants';

export const makeReportConstants = reducerName => {
  return {
    RESET: `store/${REPORT_REDUCER_NAME}/${reducerName}/RESET`,
    RESET_DATA: `store/${REPORT_REDUCER_NAME}/${reducerName}/RESET_DATA`,
    SET_IS_LOADED: `store/${REPORT_REDUCER_NAME}/${reducerName}/SET_IS_LOADED`,
    SET_IS_LOADING: `store/${REPORT_REDUCER_NAME}/${reducerName}/SET_IS_LOADING`,
    SET_LOAD_ERROR: `store/${REPORT_REDUCER_NAME}/${reducerName}/SET_LOAD_ERROR`,
    SET_PAGE: `store/${REPORT_REDUCER_NAME}/${reducerName}/SET_PAGE`,
    SET_RESULTS: `store/${REPORT_REDUCER_NAME}/${reducerName}/SET_RESULTS`
  };
};

/**
 * For a report view, sort and filter the results in memory
 * @param results
 * @param tableState
 * @param action
 * @returns {Function}
 */
export const sortAndLimitReportResults = (results, tableState, action) => {
  tableState = tableState || DEFAULT_TABLE_STATE;
  return dispatch => {
    const filteredRows = filterCollection(
      results,
      get(tableState, "search"),
      get(tableState, "columns"),
      get(tableState, "filter")
    );

    const page = sortAndLimit(filteredRows, tableState);
    if (action) {
      action(page, filteredRows.length);
    }
  };
};
