import { request } from 'gaxios';
import { get, omit, uniq, uniqBy, set } from "lodash";
import { normalize } from "normalizr";
import moment from "moment";
import { toast } from "react-toastify";
import { getErrorMessage, captureException } from "../../../helpers/error";
import {
  bedPacketsSchema,
  customerSchema,
  customersSchema,
  docsSchema,
  invoicesSchema,
  locationsSchema,
  notesSchema,
  objectsSchema,
  serviceVisitsSchema,
  statementsSchema,
  upsertNormalizedEntities,
  workOrdersSchema,
} from '../../../helpers/normalizers';
import {
  actions as customerRequestActions,
} from '../../request/customer/actions';
import { actions as docRequestActions } from '../../request/doc/actions';
import { actions as bedPacketRequestActions } from '../../request/bedPacket/actions';
import {
  actions as invoiceRequestActions,
} from '../../request/invoice/actions';
import { actions as noteRequestActions } from '../../request/note/actions';
import {
  actions as paymentRequestActions,
} from '../../request/payment/actions';
import {
  actions as paymentLogRequestActions,
} from '../../request/paymentLog/actions';
import {
  actions as serviceVisitRequestActions,
} from '../../request/serviceVisit/actions';
import {
  actions as statementRequestActions,
} from '../../request/statement/actions';
import {
  actions as workOrderRequestActions,
} from '../../request/workOrder/actions';
import { sortAndLimitResults } from '../../view/actions';
import { actions as customerViewActions } from '../../view/customer/actions';
import { customerViewSelector } from '../../view/customer/selectors';
import {
  destroyObjectToAPI,
  fetchFileFromAPI,
  fetchNotesFromAPI,
  fetchObjectsFromAPI,
  fetchObjectsFromAPIV2,
  makeObjectActions,
  uploadFileToAPI,
  upsertObjectToAPI,
} from '../actions';
import { INVOICE_REDUCER_NAME } from '../invoice';
import { PAYMENT_REDUCER_NAME } from '../payment';
import { PAYMENT_LOG_REDUCER_NAME } from '../paymentLog';
import { STATEMENT_REDUCER_NAME } from '../statement';
import { allConstants as constants } from './constants';
import { customerSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(customerRequestActions.setLoadAllError(null));
    dispatch(customerRequestActions.setIsLoadingAll(true));
    try {
      const response = await fetchObjectsFromAPIV2(
        constants.FETCH_LIST_V2_URL,
        tableState,
        "data"
      );
      // // Normalize the result and store the customers in redux
      const entities = get(
        normalize(get(response, "data"), customersSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        customerViewActions.setList(
          get(response, "data").map(customer => {
            return customer.id;
          }),
          get(response, "meta.total")
        )
      );
      dispatch(customerRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(customerRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(customerRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Load a list of objects
 */
export const searchObjects = (search, customerId, signal) => {
  return async (dispatch, getState) => {
    dispatch(customerRequestActions.setIsLoadingAll(true));
    dispatch(customerRequestActions.setLoadAllError(null));
    try {
      const customers = await upsertObjectToAPI(
        constants.SEARCH_LIST_URL,
        { search, customerId },
        false,
        true,
        "data.data",
        null,
        signal
      );

      // Normalize the result and store the customers in redux
      const entities = get(
        normalize(customers, customersSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      const stateCustomers = customerSelector().getObjects()(getState());
      dispatch(
        customerViewActions.setRelatedObjects(
          constants.RELATION_SEARCH,
          constants.RELATION_SEARCH,
          uniqBy([...stateCustomers, ...customers], 'id').map(customer => {
            return customer.id;
          })
        )
      );
    } catch (e) {
      captureException(e);
      dispatch(customerRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(customerRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = id => {
  return async (dispatch, getState) => {
    dispatch(customerRequestActions.setLoadError(null));
    dispatch(customerRequestActions.setIsLoading(true));
    try {
      const existingCustomer =
        customerSelector().getDenormalizedObject(id)(getState()) || {};
      const result = await fetchObjectsFromAPI(`${constants.FETCH_V2_URL}/${id}`);
      set(result, "customer.billing", get(result, "billing"));
      set(result, "customer.appointments", get(result, "appointments"));
      set(result, "customer.portal", get(result, "portal"));
      set(result, "customer", {
        ...existingCustomer,
        ...convertObjectFromLoad(get(result, "customer"))
      });

      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          customer: customerSchema
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));
      dispatch(customerRequestActions.setIsLoaded(id, true));
      return get(result, 'customer');
    } catch (e) {
      captureException(e);
      dispatch(customerRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(customerRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Fetch a single object from the api for the portal.
 * @param id
 * @returns {Function}
 */
export const fetchPortalObject = id => {
  return async (dispatch, getState) => {
    dispatch(customerRequestActions.setLoadError(null));
    dispatch(customerRequestActions.setIsLoading(true));
    try {
      const existingCustomer =
        customerSelector().getDenormalizedObject(id)(getState()) || {};
      const result = await fetchObjectsFromAPI(`${constants.FETCH_V2_PORTAL_URL}`);
      set(result, "customer.billing", get(result, "billing"));
      set(result, "customer.appointments", get(result, "appointments"));
      set(result, "customer.portal", get(result, "portal"));
      set(result, "customer", {
        ...existingCustomer,
        ...convertObjectFromLoad(get(result, "customer"))
      });

      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          customer: customerSchema
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));
      dispatch(customerRequestActions.setIsLoaded(id, true));
    } catch (e) {
      captureException(e);
      dispatch(customerRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(customerRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Delete customer (soft delete).
 * @param customer
 * @returns {Function}
 */
export const destroyObject = (customer, onSuccess, onError) => {
  return async dispatch => {
    dispatch(customerRequestActions.setLoadAllError(null));
    dispatch(customerRequestActions.setIsLoading(true));
    try {
      const response = await destroyObjectToAPI(
        `${constants.FETCH_URL}/${customer.id}`,
        {}
      );
      if(get(response, 'data.status')){
        toast.success("Customer deleted successfully!");
        if(onSuccess){
          onSuccess();
        }
      } else {
        toast.error("There was an error deleting this customer.");
        if(onError){
          onError();
        }
      }      
    } catch (e) {
      captureException(e);
      dispatch(customerRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(customerRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Convert an object before we save it on the api
 * @param data
 */
const convertServiceActivityForSave = data => {
  return {
    visit: {
      ...omit(data, ["services", "user"]),
      problems: data.problems.map(problem => {
        return problem.value;
      })
    }
  };
};

export const destroyServiceActivity = (serviceActivityId, customerId) => {
  return async dispatch => {
    dispatch(serviceVisitRequestActions.setSaveError(null));
    dispatch(serviceVisitRequestActions.setIsSaving(true));
    try {
      await fetchObjectsFromAPI(
        constants.DESTROY_SERVICE_ACTIVITY_URL.replace(":id", serviceActivityId)
      );

      await dispatch(fetchServiceVisits(customerId));
    } catch (e) {
      console.error(e);
      captureException(e);
      dispatch(serviceVisitRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(serviceVisitRequestActions.setIsSaving(false));
    }
  };
};

export const upsertServiceActivity = data => {
  return async dispatch => {
    dispatch(serviceVisitRequestActions.setSaveError(null));
    dispatch(serviceVisitRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.UPDATE_SERVICE_VISIT_URL,
        convertServiceActivityForSave(data),
        false,
        true
      );

      await dispatch(fetchServiceVisits(get(data, "customer.id")));
    } catch (e) {
      console.error(e);
      captureException(e);
      dispatch(serviceVisitRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(serviceVisitRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(customerRequestActions.setSaveError(null));
    dispatch(customerRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.UPSERT_V2_URL,
        convertObjectForSave(data),
        true,
        false,
        'data.data'
      );
      if (get(result, "customer.id")) {
        dispatch(fetchObject(result.customer.id));
      }

      if (onSuccess) {
        onSuccess(result.customer);
      }
    } catch (e) {
      captureException(e);
      dispatch(customerRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(customerRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
 export const upsertPortalObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(customerRequestActions.setSaveError(null));
    dispatch(customerRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.UPSERT_PORTAL_CUSTOMER_URL,
        convertObjectForSave(data),
        false,
        true,
        'data'
      );
      if (get(result, "customer.id")) {
        // Normalize the result and store the other attributes in redux
        const entities = get(
          normalize(result, {
            ...objectsSchema,
            customer: customerSchema
          }),
          "entities",
          {}
        );
        dispatch(upsertNormalizedEntities(entities));
        dispatch(customerRequestActions.setIsLoaded(get(result, "customer.id"), true));
      }

      if (onSuccess) {
        onSuccess(get(result, 'customer'));
      }
    } catch (e) {
      captureException(e);
      dispatch(customerRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(customerRequestActions.setIsSaving(false));
    }
  };
};

export const convertAddressesTypeBits = (addresses = []) => {
  return addresses.map(address => {
    const addressBitType = [];

    address.types.forEach(type => {
      if (type.key & address.type_bit) {
        addressBitType.push(type.key);
      }
    });

    return {
      ...address,
      types: address.types.filter(type => {
        return type.key & address.type_bit;
      }),
      addressBitType
    };
  })
};

export const convertObjectFromLoad = data => {
  return {
    ...data,
    addresses: convertAddressesTypeBits(data.addresses),
  };
};

export const updateAddressBitType = address => {
  return {
    ...address,
    type_bit: address.types.reduce((accumulator, type) => {
      return accumulator + (type.key || type.id);
    }, 0),
    addressBitType: address.types.map(type => {
      return type.key || type.id;
    })
  };
};

/**
 * Convert an object before we save it on the api
 * @param data
 */
const convertObjectForSave = data => {
  return {
    ...data,
    addresses: data.addresses
      ? data.addresses.map(address => {
          return updateAddressBitType(address);
        })
      : []
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const customers = customerSelector().getDenormalizedObjects()(getState());
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(customers, tableState, (ids, count) => {
        dispatch(customerViewActions.setList(ids, count));
      })
    );
  };
};

/**
 * Fetch the serviceVisits associated with this service level
 * @param customerId
 * @returns {Function}
 */
export const fetchServiceVisits = customerId => {
  return async dispatch => {
    dispatch(serviceVisitRequestActions.setLoadAllError(null));
    dispatch(serviceVisitRequestActions.setIsLoadingAll(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.FETCH_SERVICE_VISIT_LIST_URL.replace(
          ":customerId",
          customerId
        ),
        "data"
      );

      const entities = get(
        normalize(result, {
          ...objectsSchema,
          serviceLocations: locationsSchema,
          visits: serviceVisitsSchema
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));

      // Store the full list of ids in the reducer
      dispatch(
        customerViewActions.setRelatedObjects(
          constants.RELATION_SERVICE_VISITS,
          customerId,
          result.visits.map(serviceVisit => {
            return serviceVisit.id;
          })
        )
      );
    } catch (e) {
      captureException(e);
      dispatch(serviceVisitRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(serviceVisitRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Fetch the payments associated with this service level
 * @param customerId
 * @param tableState
 * @returns {Function}
 */
export const fetchPayments = (customerId, tableState) => {
  return async dispatch => {
    dispatch(paymentRequestActions.setLoadAllError(null));
    dispatch(paymentRequestActions.setIsLoadingAll(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.FETCH_PAYMENT_LIST_URL.replace(":customerId", customerId),
        "data"
      );

      const entities = get(normalize(result, objectsSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      // Store the full list of ids in the reducer
      dispatch(
        customerViewActions.setRelatedObjects(
          constants.RELATION_PAYMENTS,
          customerId,
          result.payments.map(payment => {
            return payment.id;
          })
        )
      );
      // We will need the customer balance on the payments modal.
      dispatch(
        customerViewActions.setRelatedObjects(
          constants.RELATION_BALANCE,
          customerId,
          result.balance
        )
      );

      dispatch(updatePaymentsSortFilterLimit(customerId, tableState));
    } catch (e) {
      captureException(e);
      dispatch(paymentRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(paymentRequestActions.setIsLoadingAll(false));
      dispatch(paymentRequestActions.setIsLoadedAll(true));
    }
  };
};

/**
 * Fetch the failed payments associated with this customer
 * @param customerId
 * @param tableState
 * @returns {Function}
 */
export const fetchFailedPayments = (customerId, tableState) => {
  return async dispatch => {
    dispatch(paymentLogRequestActions.setLoadAllError(null));
    dispatch(paymentLogRequestActions.setIsLoadingAll(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.FETCH_FAILED_PAYMENT_LIST_URL.replace(":customerId", customerId),
        "data.data"
      );

      // Put the paymentLogs in the store. (data.paymentLogs)
      const entities = get(normalize(result, objectsSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      // Store the full list of ids in the reducer
      dispatch(
        customerViewActions.setRelatedObjects(
          constants.RELATION_PAYMENT_LOGS,
          customerId,
          result.paymentLogs.map(payment => {
            return payment.id;
          })
        )
      );

      dispatch(updatePaymentLogsSortFilterLimit(customerId, tableState));
    } catch (e) {
      captureException(e);
      dispatch(paymentLogRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(paymentLogRequestActions.setIsLoadingAll(false));
      dispatch(paymentLogRequestActions.setIsLoadedAll(true));
    }
  };
};

/**
 * Fetch a single object from the api
 * @param paymentId
 * @returns {Function}
 */
export const reversePayment = (paymentId, onSuccess) => {
  return async (dispatch, getState) => {
    dispatch(paymentRequestActions.setSaveError(null));
    dispatch(paymentRequestActions.setIsSaving(true));
    try {
      const response = await fetchObjectsFromAPI(
        `${constants.REVERSE_PAYMENT_URL}/${paymentId}`,
        'data.data'
      );
      // If response.message exists, it means there was an error.
      if(get(response, 'message')){
        toast.error(get(response, 'message'));
        throw new Error(get(response, 'message'));
      }

      // If this exists in recent payments, we should update it.
      const customerId = get(response, 'payment.customer.id');
      if(customerId){
        const existingCustomer = customerSelector().getDenormalizedObject(customerId)(getState()) || {};
        const recentPayments = get(existingCustomer, 'recent_payments', []).map(payment => {
          if(payment.id === paymentId){
            payment.reversed = 1;
            payment.reversed_date = moment().format('YYYY-MM-DD');
          }
          return payment;
        });
        recentPayments.unshift(response.reverse_payment);
        set(existingCustomer, 'recent_payments', recentPayments);
        // Normalize the result and store the other attributes in redux
        const entities = get(
          normalize({customer: existingCustomer}, {
            ...objectsSchema,
            customer: customerSchema
          }),
          "entities",
          {}
        );
        dispatch(upsertNormalizedEntities(entities));
      }

      if (onSuccess) {
        onSuccess(response.payment);
      }
    } catch (e) {
      captureException(e);
      dispatch(paymentRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(paymentRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the payments for the table
 * @param customerId
 * @param tableState
 * @returns {Function}
 */
export const updatePaymentsSortFilterLimit = (customerId, tableState) => {
  return async (dispatch, getState) => {
    const state = getState();
    const payments = customerViewSelector().getRelatedObjects(
      PAYMENT_REDUCER_NAME,
      customerId
    )(state);

    // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(payments, tableState, (ids, count) => {
        dispatch(
          customerViewActions.setRelatedFilteredList(
            constants.RELATION_PAYMENTS,
            customerId,
            ids,
            count
          )
        );
      })
    );
  };
};

/**
 * Filter, Sort, and Trim the payment logs for the table
 * @param customerId
 * @param tableState
 * @returns {Function}
 */
export const updatePaymentLogsSortFilterLimit = (customerId, tableState) => {
  return async (dispatch, getState) => {
    const state = getState();
    const paymentLogs = customerViewSelector().getRelatedObjects(
      PAYMENT_LOG_REDUCER_NAME,
      customerId
    )(state);

    // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(paymentLogs, tableState, (ids, count) => {
        dispatch(
          customerViewActions.setRelatedFilteredList(
            constants.RELATION_PAYMENT_LOGS,
            customerId,
            ids,
            count
          )
        );
      })
    );
  };
};

/**
 * Fetch the invoices associated with this service level
 * @param customerId
 * @param tableState
 * @returns {Function}
 */
export const fetchInvoices = (customerId, tableState) => {
  return async dispatch => {
    dispatch(invoiceRequestActions.setLoadAllError(null));
    dispatch(invoiceRequestActions.setIsLoadingAll(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.FETCH_INVOICE_LIST_URL.replace(":customerId", customerId)
      );

      const entities = get(normalize(result, invoicesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      // Store the full list of ids in the reducer
      dispatch(
        customerViewActions.setRelatedObjects(
          constants.RELATION_INVOICES,
          customerId,
          result.map(invoice => {
            return invoice.id;
          })
        )
      );

      dispatch(updateInvoicesSortFilterLimit(customerId, tableState));
    } catch (e) {
      captureException(e);
      dispatch(invoiceRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(invoiceRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the invoices for the table
 * @param customerId
 * @param tableState
 * @returns {Function}
 */
export const updateInvoicesSortFilterLimit = (customerId, tableState) => {
  return async (dispatch, getState) => {
    const state = getState();
    const invoices = customerViewSelector().getRelatedObjects(
      INVOICE_REDUCER_NAME,
      customerId
    )(state);

    // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(invoices, tableState, (ids, count) => {
        dispatch(
          customerViewActions.setRelatedFilteredList(
            constants.RELATION_INVOICES,
            customerId,
            ids,
            count
          )
        );
      })
    );
  };
};

/**
 * Fetch the work orders associated with this service level
 * @param customerId
 * @returns {Function}
 */
export const fetchWorkOrders = customerId => {
  return async dispatch => {
    dispatch(workOrderRequestActions.setLoadAllError(null));
    dispatch(workOrderRequestActions.setIsLoadingAll(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.FETCH_WORK_ORDER_LIST_URL.replace(":customerId", customerId),
        "data"
      );

      const entities = get(
        normalize(result, { ...objectsSchema, workorders: workOrdersSchema }),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Store the full list of ids in the reducer
      dispatch(
        customerViewActions.setRelatedObjects(
          constants.RELATION_WORK_ORDERS,
          customerId,
          result.workorders.map(workOrder => {
            return workOrder.id;
          })
        )
      );
    } catch (e) {
      captureException(e);
      dispatch(workOrderRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(workOrderRequestActions.setIsLoadingAll(false));
    }
  };
};

export const fetchPaymentReceipt = paymentId => {
  return async dispatch => {
    dispatch(paymentRequestActions.setLoadError(null));
    dispatch(paymentRequestActions.setIsLoading(true));
    try {
      await fetchFileFromAPI(
        constants.FETCH_PAYMENT_RECEIPT_URL.replace(":paymentId", paymentId),
        `receipt-${paymentId}.pdf`
      );
    } catch (e) {
      captureException(e);
      dispatch(paymentRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(paymentRequestActions.setIsLoading(false));
    }
  };
};

export const fetchCustomerDump = (params = false) => {
  return async dispatch => {
    dispatch(customerRequestActions.setIsLoadingAll(true));
    dispatch(customerRequestActions.setLoadAllError(null));
    const url = params ? `${constants.FETCH_CUSTOMER_DUMP_URL}?${params}` : constants.FETCH_CUSTOMER_DUMP_URL
    try {
      await fetchFileFromAPI(
        url,
        `customer_bulk_dump_${moment().format('YYYY-MM-DD')}.csv`
        )
    } catch (e) {
      captureException(e);
      dispatch(customerRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(customerRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Get the customer payment profile information.
 * @param {*} customerId 
 * @returns 
 */
export const fetchPaymentProfiles = (customerId) => {
  return async (dispatch, getState) => {
    dispatch(customerRequestActions.setIsAuthorizeLoading(true));
    dispatch(customerRequestActions.setIsAuthorizeLoaded(false));
    dispatch(customerRequestActions.setAuthorizeLoadError(null));
    try {
      const existingCustomer =
        customerSelector().getDenormalizedObject(customerId)(getState()) || {};
      const result = await fetchObjectsFromAPI(
        constants.FETCH_PAYMENT_PROFILE_LIST_URL.replace(':customerId', customerId)
      )
      // Set the authorize ids
      const authorize = get(result, "authorize");
      if(authorize){
        set(existingCustomer, "billing.authorize", get(result, "authorize"));
      } else {
        delete existingCustomer.billing.authorize;
      }
      
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize({customer: existingCustomer}, {
          ...objectsSchema,
          customer: customerSchema
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));

      dispatch(customerRequestActions.setIsAuthorizeLoaded(true));
      dispatch(customerRequestActions.setIsAuthorizeLoading(false));
    } catch (e) {
      captureException(e);
      dispatch(customerRequestActions.setAuthorizeLoadError(getErrorMessage(e)));
    } finally {
      //dispatch(customerRequestActions.setIsAuthorizeLoading(false));
    }
  };
};

/**
 * Delete the customer authnet payment profile information and reset billing to manual
 * @param {*} customerId 
 * @param {*} authorizeCustomerId 
 * @param {*} paymentProfileId 
 * @returns 
 */
export const destroyPaymentProfile = (customerId, authorizeCustomerId, paymentProfileId) => {
  return async (dispatch, getState) => {
    dispatch(customerRequestActions.setIsAuthorizeLoading(true));
    dispatch(customerRequestActions.setIsAuthorizeLoaded(false));
    dispatch(customerRequestActions.setAuthorizeLoadError(null));
    try {
      const existingCustomer =
        customerSelector().getDenormalizedObject(customerId)(getState()) || {};
      // Delete a billing info from authorize.net customer account
      const data = {paymentProfileId, authorizeCustomerId};
      const result = await upsertObjectToAPI(
        constants.DESTROY_BILLING_URL.replace(":customerId", customerId),
        data,
        false,
        false,
        'data.data'
      );

      set(existingCustomer, "billing", get(result, "customer.billing"));
      // Set the authorize ids
      const authorize = get(result, "authorize");
      if(authorize){
        set(existingCustomer, "billing.authorize", get(result, "authorize"));
      } else {
        delete existingCustomer.billing.authorize;
      }
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize({customer: existingCustomer}, {
          ...objectsSchema,
          customer: customerSchema
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));

      dispatch(customerRequestActions.setIsAuthorizeLoaded(true));
      dispatch(customerRequestActions.setIsAuthorizeLoading(false));
    } catch (e) {
      captureException(e);
      dispatch(customerRequestActions.setAuthorizeLoadError(getErrorMessage(e)));
    } finally {
      //
    }
  };
};

export const resetAuthorize = () => {
  return async (dispatch, getState) => {
    dispatch(customerRequestActions.setIsAuthorizeLoaded(false));
    dispatch(customerRequestActions.setAuthorizeLoadError(null));
  }
};

/**
 * Save an object on the api
 * @param customerId
 * @param data
 * @param tableState
 * @returns {Function}
 */
export const upsertBilling = (customerId, data, onSuccess, onError) => {
  return async (dispatch, getState) => {
    dispatch(customerRequestActions.setSaveError(null));
    dispatch(customerRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.UPSERT_BILLING_URL.replace(":customerId", customerId),
        data,
        false,
        false,
        'data.data'
      );

      const existingCustomer =
      customerSelector().getDenormalizedObject(customerId)(getState()) || {};

      set(result, "customer", {
        ...existingCustomer,
        ...convertObjectFromLoad(get(result, "customer"))
      });

      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          customer: customerSchema
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));

      // if (get(result, "customer.id")) {
      //   dispatch(fetchObject(result.customer.id));
      // }
      if(onSuccess){
        onSuccess( get(result, 'customer') );
      }
    } catch (e) {
      captureException(e);
      dispatch(customerRequestActions.setSaveError(getErrorMessage(e)));
      if(onError){
        onError(e);
      }
    } finally {
      dispatch(customerRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Save an object on the api
 * @param customerId
 * @param data
 * @param tableState
 * @returns {Function}
 */
export const upsertACH = (customerId, data, onSuccess, onError) => {
  return async (dispatch, getState) => {
    dispatch(customerRequestActions.setSaveError(null));
    dispatch(customerRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.UPSERT_ACH_BILLING_URL.replace(":customerId", customerId),
        data,
        false,
        false,
        'data.data'
      );

      const existingCustomer =
      customerSelector().getDenormalizedObject(customerId)(getState()) || {};

      set(result, "customer", {
        ...existingCustomer,
        ...convertObjectFromLoad(get(result, "customer"))
      });

      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          customer: customerSchema
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));

      if(onSuccess){
        onSuccess( get(result, 'customer') );
      }
    } catch (e) {
      captureException(e);
      dispatch(customerRequestActions.setSaveError(getErrorMessage(e)));
      if(onError){
        onError(e);
      }
    } finally {
      dispatch(customerRequestActions.setIsSaving(false));
    }
  };
};


/**
 * Save an object on the api
 * @param customerId
 * @param data
 * @param tableState
 * @returns {Function}
 */
export const upsertPortalBilling = (customerId, data, callback = null) => {
  return async (dispatch, getState) => {
    dispatch(customerRequestActions.setSaveError(null));
    dispatch(customerRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.UPSERT_PORTAL_BILLING_URL,
        data,
        false,
        false,
        'data.data'
      );

      const existingCustomer =
      customerSelector().getDenormalizedObject(customerId)(getState()) || {};

      set(result, "customer", {
        ...existingCustomer,
        ...convertObjectFromLoad(get(result, "customer"))
      });

      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          customer: customerSchema
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));

      // if (get(result, "customer.id")) {
      //   dispatch(fetchObject(result.customer.id));
      // }
      if(callback){
        callback( get(result, 'customer') );
      }
    } catch (e) {
      console.error(e)
      captureException(e);
      dispatch(customerRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(customerRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Save an object on the api
 * @param customerId
 * @param data
 * @param tableState
 * @returns {Function}
 */
export const upsertPayment = (customerId, data, tableState, getPayments = true) => {
  return async (dispatch, getState) => {
    dispatch(paymentRequestActions.setSaveError(null));
    dispatch(paymentRequestActions.setIsSaving(true));
    try {
      const response = await upsertObjectToAPI(
        constants.UPSERT_PAYMENT_URL.replace(":customerId", customerId),
        data
      );
      // Add this to customer recent payments
      const existingCustomer = customerSelector().getDenormalizedObject(customerId)(getState()) || {};
      // Normalize the date & set it on customer
      set(response.data, 'date_taken', moment(get(response, 'data.date_taken.date')).format('YYYY-MM-DD'));
      existingCustomer.recent_payments.unshift(response.data);
      // Update the customer balance.
      set(existingCustomer, 'balance', get(response, 'balance'));

      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize({customer: existingCustomer}, {
          ...objectsSchema,
          customer: customerSchema
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(paymentRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(paymentRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Fetch the bed packets from the api and store their result in our reducer
 * @param customerId
 * @returns {Function}
 */
 export const fetchBedPackets = customerId => {
  return async dispatch => {
    dispatch(bedPacketRequestActions.setLoadAllError(null));
    dispatch(bedPacketRequestActions.setIsLoadingAll(true));
    try {
      const bedPackets = await fetchObjectsFromAPI(
        constants.FETCH_BED_PACKET_LIST_URL.replace(":customerId", customerId),
        `data.data`
      );
      const entities = get(
        normalize({bedPackets: bedPackets}, { ...objectsSchema, bedPackets: bedPacketsSchema }),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      dispatch(
        customerViewActions.setRelatedObjects(
          constants.RELATION_BED_PACKETS,
          customerId,
          bedPackets.map(bedPacket => {
            return bedPacket.id;
          })
        )
      );
    } catch (e) {
      captureException(e);
      dispatch(bedPacketRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(bedPacketRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Fetch the docs from the api and store their result in our reducer
 * @param customerId
 * @returns {Function}
 */
export const fetchDocs = customerId => {
  return async dispatch => {
    dispatch(docRequestActions.setLoadAllError(null));
    dispatch(docRequestActions.setIsLoadingAll(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.FETCH_DOC_LIST_URL.replace(":customerId", customerId),
        `data`
      );
      const entities = get(
        normalize(result, { ...objectsSchema, docs: docsSchema }),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      dispatch(
        customerViewActions.setRelatedObjects(
          constants.RELATION_DOCS,
          customerId,
          result.docs.map(doc => {
            return doc.id;
          })
        )
      );
    } catch (e) {
      captureException(e);
      dispatch(docRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(docRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Fetch the notes from the api and store their result in our reducer
 * @param id
 * @returns {Function}
 */
export const fetchNotes = id => {
  return async dispatch => {
    dispatch(noteRequestActions.setLoadAllError(null));
    dispatch(noteRequestActions.setIsLoadingAll(true));
    try {
      const notes = await dispatch(
        fetchNotesFromAPI(id, `${constants.FETCH_NOTE_LIST_URL}/${id}`)
      );

      dispatch(
        customerViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          id,
          notes.map(note => {
            return note.id;
          })
        )
      );
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save a photo on customer
 * @param customerId
 * @param file
 * @returns {Function}
 */
export const upsertProfilePhoto = (id, file, onSuccess, onError) => {
  return async (dispatch, getState) => {
    dispatch(customerRequestActions.setSaveError(null));
    dispatch(customerRequestActions.setIsSaving(true));
    try {
      const existingCustomer =
        customerSelector().getDenormalizedObject(id)(getState()) || {};

      const result = await uploadFileToAPI(
        constants.UPSERT_PROFILE_PHOTO_URL,
        file, 
        {
          type: "customer_profile_photo"
        },
        'data',
        'file'
      );

      const data = get(result, 'data');

      set(data, "customer", {
        ...existingCustomer,
        ...convertObjectFromLoad(get(data, "customer"))
      });

      
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(data, {
          ...objectsSchema,
          customer: customerSchema
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));
      dispatch(customerRequestActions.setIsLoaded(id, true));

      if(get(data, 'customer.photo')){
        onSuccess(data);
      } else {
        onError(data);
      }
    } catch (e) {
      captureException(e);
      console.error(e)
      dispatch(customerRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(customerRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Save a doc on the api
 * @param customerId
 * @param file
 * @returns {Function}
 */
export const upsertDoc = (customerId, file) => {
  return async (dispatch, getState) => {
    dispatch(docRequestActions.setSaveError(null));
    dispatch(docRequestActions.setIsSaving(true));
    try {
      await uploadFileToAPI(constants.UPSERT_DOC_URL, file, {
        type: "customer",
        objectId: customerId
      });

      dispatch(fetchDocs(customerId));
    } catch (e) {
      captureException(e);
      dispatch(docRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(docRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Remove a doc from the API
 * @param docId
 * @param customerId
 * @returns {Function}
 */
export const destroyDoc = (docId, customerId) => {
  return async (dispatch, getState) => {
    dispatch(docRequestActions.setSaveError(null));
    dispatch(docRequestActions.setIsSaving(true));
    try {
      await uploadFileToAPI(constants.DESTROY_DOC_URL.replace(":docId", docId));

      dispatch(fetchDocs(customerId));
    } catch (e) {
      captureException(e);
      dispatch(docRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(docRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Save a note on the api
 * @param parentId
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertNote = (parentId, data, onSuccess) => {
  return async (dispatch, getState) => {
    dispatch(noteRequestActions.setSaveError(null));
    dispatch(noteRequestActions.setIsSaving(true));
    try {
      data.kind = data.kind || "customer";

      const result = await upsertObjectToAPI(constants.UPSERT_NOTE_URL, data);
      const entities = get(normalize([result], notesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      const notes = customerViewSelector().getNoteIds(parentId)(getState());
      const updatedIds = uniq([...notes, result.id]);
      dispatch(
        customerViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          parentId,
          updatedIds
        )
      );

      if (onSuccess) {
        onSuccess(notes);
      }
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Fetch the statements associated with this service level
 * @param customerId
 * @param tableState
 * @returns {Function}
 */
export const fetchStatements = (customerId, tableState) => {
  return async dispatch => {
    dispatch(statementRequestActions.setLoadAllError(null));
    dispatch(statementRequestActions.setIsLoadingAll(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.FETCH_STATEMENT_LIST_URL.replace(":customerId", customerId),
        "data"
      );

      result.customer.aging = result.aging;
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          customer: customerSchema,
          statementlist: statementsSchema
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));

      // Store the full list of ids in the reducer
      dispatch(
        customerViewActions.setRelatedObjects(
          constants.RELATION_STATEMENTS,
          customerId,
          result.statementlist.map(statement => {
            return statement.id;
          })
        )
      );

      dispatch(updateStatementsSortFilterLimit(customerId, tableState));
    } catch (e) {
      captureException(e);
      dispatch(statementRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(statementRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Fetch the tax rate for a given customer
 * @param customerId
 * @returns {Function}
 */
export const fetchTaxRate = customerId => {
  return async (dispatch, getState) => {
    if (customerId) {
      const customer = customerSelector().getDenormalizedObject(customerId)(
        getState()
      );
      dispatch(customerRequestActions.setLoadError(null));
      dispatch(customerRequestActions.setIsLoading(true));
      try {
        const result = await fetchObjectsFromAPI(
          constants.FETCH_TAX_RATE.replace(":customerId", customerId),
          "data"
        );

        const { tax } = result;
        const entities = get(
          normalize(
            { customers: [{ ...customer, tax }] },
            {
              ...objectsSchema
            }
          ),
          "entities",
          {}
        );

        dispatch(upsertNormalizedEntities(entities));
      } catch (e) {
        captureException(e);
        dispatch(customerRequestActions.setLoadError(getErrorMessage(e)));
      } finally {
        dispatch(customerRequestActions.setIsLoading(false));
      }
    }
  };
};

/**
 * Filter, Sort, and Trim the statements for the table
 * @param customerId
 * @param tableState
 * @returns {Function}
 */
export const updateStatementsSortFilterLimit = (customerId, tableState) => {
  return async (dispatch, getState) => {
    const state = getState();
    const statements = customerViewSelector().getRelatedObjects(
      STATEMENT_REDUCER_NAME,
      customerId
    )(state);

    // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(statements, tableState, (ids, count) => {
        dispatch(
          customerViewActions.setRelatedFilteredList(
            constants.RELATION_STATEMENTS,
            customerId,
            ids,
            count
          )
        );
      })
    );
  };
};

/**
 * Updates the statement processing options on the customer
 * @param customerId
 * @param data
 * @param tableState
 * @returns {Function}
 */
export const saveStatementProcessingOptions = (
  customerId,
  data,
  tableState
) => {
  return async (dispatch, getState) => {
    dispatch(statementRequestActions.setSaveError(null));
    dispatch(statementRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.SAVE_STATEMENT_OPTIONS_URL.replace(":customerId", customerId),
        { id: customerId, processing: {
            ...data,
            process_email: get(data, 'process') === 'email' ? get(data, 'process_email', '') : ''
          }
        },
        false,
        true
      );
      dispatch(fetchStatements(customerId, tableState));
    } catch (e) {
      captureException(e);
      dispatch(statementRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(statementRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Post a visit on the work order
 * @param data
 * @returns {Function}
 */
export const upsertPostVisit = data => {
  return async dispatch => {
    dispatch(workOrderRequestActions.setSaveError(null));
    dispatch(workOrderRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.UPDATE_POST_VISIT_URL,
        { visit: data },
        false,
        true
      );
      toast.success("Visit Posted Successfully!");
    } catch (e) {
      captureException(e);
      dispatch(workOrderRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(workOrderRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Activate or Cancel Service
 * @param customerId
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const activateCancelService = (customerId, data) => {
  return async dispatch => {
    dispatch(customerRequestActions.setSaveError(null));
    dispatch(customerRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.ACTIVATE_CANCEL_URL.replace(":customerId", customerId),
        { activecancel: data },
        false,
        true
      );
      toast.success("Service updated successfully!");
    } catch (e) {
      captureException(e);
      dispatch(customerRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(customerRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Activate or Cancel Service
 * @param customerId
 * @param data
 * @returns {Function}
 */
 export const scheduleService = (customerId, data) => {
  return async dispatch => {
    dispatch(customerRequestActions.setSaveError(null));
    dispatch(customerRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.SCHEDULE_START_STOP_URL.replace(":id", customerId),
        { service: data },
        false,
        true
      );
      toast.success("Scheduled service updated successfully!");
    } catch (e) {
      captureException(e);
      dispatch(customerRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(customerRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Cancel a customer appointment.
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const cancelAppointment = (data, onSuccess = null) => {
  return async (dispatch, getState) => {
    dispatch(customerRequestActions.setSaveError(null));
    dispatch(customerRequestActions.setIsSaving(true));
    try {
      const existingCustomer =
        customerSelector().getDenormalizedObject( get(data, 'customer_id') )(getState()) || {};
      const result = await upsertObjectToAPI(
        constants.CANCEL_PORTAL_APPOINTMENT_URL.replace(':appointmentId', get(data, 'appointment_id')),
        data,
        false,
        true,
        'data.data'
      );

      set(result, "customer", {
        ...existingCustomer,
        ...convertObjectFromLoad(get(result, "customer"))
      });

      const entities = get(
        normalize(result, {
          ...objectsSchema,
          customer: customerSchema
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));

      if(onSuccess){
        onSuccess(result);
      }

    } catch (e) {
      captureException(e);
      dispatch(customerRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(customerRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Advance customer record in pipeline to portal registration.
 * @param customerId
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const registerCustomerUser = (customerId, data, onSuccess) => {
  return async dispatch => {
    dispatch(customerRequestActions.setSaveError(null));
    dispatch(customerRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.UPSERT_PORTAL_REGISTRATION_URL.replace(":customerId", customerId),
        data,
        false,
        true
      );
      dispatch( fetchObject(customerId) )
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      captureException(e);
      dispatch(customerRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(customerRequestActions.setIsSaving(false));
    }
  };
}

/**
 * Renew registration token.
 * @param onSuccess
 */
export const renewRegistrationToken = (
  data,
  onSuccess = null,
  onError = null
) => {
  return async (dispatch) => {
    dispatch(customerRequestActions.setSaveError(null));
    dispatch(customerRequestActions.setIsSaving(true));
    try {
      const response = await request({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}api/portal/register/token/renew`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: data,
      });
      if (get(response, "data.status")) {
        if (onSuccess) {
          onSuccess();
        }
      } else {
        if (onError) {
          onError();
        }
      }

    } catch (e) {
      console.error(e);
      const errorMessage = getErrorMessage(e);
      if (errorMessage) {
        dispatch(customerRequestActions.setSaveError(errorMessage));
      } else {
        dispatch(customerRequestActions.setSaveError("Could not reach remote server."));
      }
      captureException(e);
    } finally {
      dispatch(customerRequestActions.setIsSaving(false));
    }    
  };
};

/**
 * Resets isLoadedAll
 * @returns {Function}
 */
export const resetPaymentIsLoadedAll = () => {
  return async dispatch => {
    dispatch(paymentRequestActions.setIsLoadedAll(false));
  };
};

/**
 * Reset.
 * @param id
 * @returns {Function}
 */
export const reset = () => {
  return async (dispatch, getState) => {
    dispatch(customerRequestActions.setIsLoading(false));
    dispatch(customerRequestActions.setIsLoaded(false));
    dispatch(customerRequestActions.setIsLoadingAll(false));
    dispatch(customerRequestActions.setIsLoadedAll(false));
    dispatch(customerRequestActions.setLoadError(false));
    dispatch(upsertNormalizedEntities([]));
    dispatch(customerViewActions.setList([], 0));
  };
};

/**
 * Reset save error.
 * @param id
 * @returns {Function}
 */
export const resetSaveError = () => {
  return async (dispatch, getState) => {
    dispatch(customerRequestActions.setSaveError(null));
  };
};

export const actions = {
  ...objectActions,
  activateCancelService,
  cancelAppointment,
  destroyDoc,
  destroyObject,
  destroyPaymentProfile,
  destroyServiceActivity,
  fetchCustomerDump,
  fetchPaymentProfiles,
  fetchTaxRate,
  fetchInvoices,
  fetchBedPackets,
  fetchDocs,
  fetchNotes,
  fetchFailedPayments,
  fetchPayments,
  fetchPaymentReceipt,
  fetchServiceVisits,
  fetchObject,
  fetchPortalObject,
  fetchObjects,
  fetchStatements,
  fetchWorkOrders,
  registerCustomerUser,
  renewRegistrationToken,
  resetPaymentIsLoadedAll,
  resetSaveError,
  reversePayment,
  saveStatementProcessingOptions,
  scheduleService,
  searchObjects,
  resetAuthorize,
  updateSortFilterLimit,
  updateInvoicesSortFilterLimit,
  updatePaymentsSortFilterLimit,
  updatePaymentLogsSortFilterLimit,
  updateStatementsSortFilterLimit,
  upsertACH,
  upsertObject,
  upsertPortalObject,
  upsertDoc,
  upsertNote,
  upsertBilling,
  upsertPortalBilling,
  upsertPayment,
  upsertPostVisit,
  upsertServiceActivity,
  upsertProfilePhoto,
  reset,
};
