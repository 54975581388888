import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "good";
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}admin/good`;
export const FETCH_URL = `${process.env.REACT_APP_API_URL}admin/good`;
export const FETCH_GOOD_COUNTS_URL = `${process.env.REACT_APP_API_URL}admin/good/count1`;
export const LOT_COST_CHANGE_URL = `${process.env.REACT_APP_API_URL}admin/lotadjust`;
export const LOT_INVENTORY_CHANGE_URL = `${process.env.REACT_APP_API_URL}admin/adjust`;
export const LOT_INVENTORY_INTUSE_URL = `${process.env.REACT_APP_API_URL}admin/adjustintuse`;
export const LOT_INVENTORY_SHRINK_URL = `${process.env.REACT_APP_API_URL}admin/adjustshrink`;
export const GOOD_INVENTORY_CONVERT_URL = `${process.env.REACT_APP_API_URL}admin/good/convert`;
export const FETCH_ACTIVITY_LIST_URL = `${process.env.REACT_APP_API_URL}admin/good/activity/:goodId`;
export const FETCH_INVOICE_LIST_URL = `${process.env.REACT_APP_API_URL}admin/good/invoices/:goodId`;
export const FETCH_REORDER_POINTS_URL = `${process.env.REACT_APP_API_URL}admin/good/:goodId/reorder`;
export const FETCH_LOTS_LIST_URL = `${process.env.REACT_APP_API_URL}admin/good/:goodId/lots/:locationId`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}admin/good`;
export const FETCH_NOTE_LIST_URL = `${process.env.REACT_APP_API_URL}admin/note/good`;
export const UPSERT_NOTE_URL = `${process.env.REACT_APP_API_URL}admin/note`;
export const REDUCER_NAME = "goods";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_LIST_URL,
  FETCH_URL,
  FETCH_ACTIVITY_LIST_URL,
  FETCH_GOOD_COUNTS_URL,
  FETCH_INVOICE_LIST_URL,
  FETCH_NOTE_LIST_URL,
  FETCH_REORDER_POINTS_URL,
  FETCH_LOTS_LIST_URL,
  UPSERT_NOTE_URL,
  LOT_COST_CHANGE_URL,
  LOT_INVENTORY_CHANGE_URL,
  GOOD_INVENTORY_CONVERT_URL,
  LOT_INVENTORY_INTUSE_URL,
  LOT_INVENTORY_SHRINK_URL,
  UPSERT_URL,
  UPSERT_PROPERTY
};
