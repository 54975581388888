import React from "react";
import { Card } from "semantic-ui-react";
import { CardTabs } from "../../CardTabs";
import { Graph } from "./Panes/Graph";
import { Details } from "./Panes/Details";

export const CommissionsEarnedCard = ({graphData, detailsData}) => {
  const [activeTab, setActiveTab] = React.useState('graph');
  return (
    <Card fluid>
      <Card.Content>
        <Card.Header className='card-tab-header'>Commissions Earned</Card.Header>
        <CardTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={[
            {
              title: 'Graph',
              key: 'graph',
            },
            {
              title: 'Details',
              key: 'details',
            },
          ]}
          tabPanes={[
            {
              key: 'graph',
              content: <Graph data={graphData} />
            },            
            {
              key: 'details',
              content: <Details data={detailsData} />
            },
          ]}
           />
      </Card.Content>
    </Card>
  )
}
