import { get } from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  salesCallActionSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import {
  actions as salesCallActionRequestActions,
} from '../../request/salesCallAction/actions';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
} from '../actions';
import { allConstants as constants } from './constants';

const objectActions = makeObjectActions(constants);

/**
 * Retrieve a single object form the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = (id) => {
  return async (dispatch) => {
    dispatch(salesCallActionRequestActions.setLoadError(null));
    dispatch(salesCallActionRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.FETCH_URL.replace(":id", id),
        "data.item"
      );
      // Normalize the result and store the roles/users in redux
      const entities = get(
        normalize(result, salesCallActionSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
      dispatch(salesCallActionRequestActions.setIsLoaded(id, true));
    } catch (e) {
      captureException(e);
      dispatch(salesCallActionRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(salesCallActionRequestActions.setIsLoading(false));
    }
  };
};

export const actions = {
  ...objectActions,
  fetchObject,
};
