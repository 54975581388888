import { array } from "prop-types";
import React from "react";
import { find } from "lodash";
import { GoalsListTable } from "./Table";
import { actions as objectActions } from "../../../../../store/object/salesGoal/actions";
import { salesGoalViewSelector } from "../../../../../store/view/salesGoal/selectors";
import { connect } from 'react-redux';

/**
 * A component used to render the list of goals for the sales dashboard
 */
const GoalsListComponent = ({ goals, typeFilters }) => {
  const filteredGoals = goals.filter(goal => {
    return typeFilters.length > 0 ? (typeFilters.indexOf(goal.type) >= 0) : true;
  })
  const includeServiceRoute = !!find(filteredGoals, {type: 'billable_extra'})
  return (
    <section className="goal-list data-table-container">
      <div className="d-flex mt-2">
        <div className="flex-grow-3">
          <GoalsListTable goals={filteredGoals} includeServiceRoute={includeServiceRoute} />
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state, props) => {
  const viewSelector = salesGoalViewSelector();
  return {
    goals: viewSelector.getAll()(state)
  };
};

GoalsListComponent.propTypes = {
  goals: array,
  typeFilters: array,
};

export const GoalsList = connect(
  mapStateToProps,
  {
    ...objectActions
  }
)(GoalsListComponent);
