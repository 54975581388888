import { get } from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  objectsSchema,
  servicePeriodSchema,
  servicePeriodsSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import {
  actions as servicePeriodRequestActions,
} from '../../request/servicePeriod/actions';
import { sortAndLimitResults } from '../../view/actions';
import {
  actions as servicePeriodViewActions,
} from '../../view/servicePeriod/actions';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';
import { servicePeriodSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(servicePeriodRequestActions.setLoadAllError(null));
    dispatch(servicePeriodRequestActions.setIsLoadingAll(true));
    try {
      const servicePeriods = await fetchObjectsFromAPI(
        constants.FETCH_LIST_URL,
        "data.servicePeriods"
      );

      // Normalize the result and store the servicePeriods in redux
      const entities = get(
        normalize(servicePeriods, servicePeriodsSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(servicePeriods, tableState, (ids, count) => {
          dispatch(servicePeriodViewActions.setList(ids, count));
        })
      );
      dispatch(servicePeriodRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(servicePeriodRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(servicePeriodRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = id => {
  return async dispatch => {
    dispatch(servicePeriodRequestActions.setLoadError(null));
    dispatch(servicePeriodRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(`${constants.FETCH_URL}/${id}`);
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          period: servicePeriodSchema
        }),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(servicePeriodRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(servicePeriodRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(servicePeriodRequestActions.setSaveError(null));
    dispatch(servicePeriodRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(constants.UPSERT_URL, data);
      const entities = get(
        normalize([result.period], servicePeriodsSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.period);
      }
    } catch (e) {
      captureException(e);
      dispatch(servicePeriodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(servicePeriodRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const servicePeriods = servicePeriodSelector().getDenormalizedObjects()(
      getState()
    );
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(servicePeriods, tableState, (ids, count) => {
        dispatch(servicePeriodViewActions.setList(ids, count));
      })
    );
  };
};

export const actions = {
  ...objectActions,
  fetchObject,
  fetchObjects,
  updateSortFilterLimit,
  upsertObject
};
