import { func, string } from "prop-types";
import { isEmpty } from "lodash";
import React from "react";
import { Input } from "semantic-ui-react";
import { ExtendedSearch } from "../../ExtendedSearch";

export const ListHeaderSearch = React.memo(
  ({
    placeholder = "Search",
    search,
    onSearchChange,
    extendedSearchOptions = {},
    disabled = false,
    onEnter = null,
  }) => {
    if (!isEmpty(extendedSearchOptions)) {
      return (
        <ExtendedSearch
          placeholder={placeholder}
          options={extendedSearchOptions}
        />
      );
    }
    return (
      <>
        <div className="list-header-search position-relative">
          <Input
            autoFocus
            icon="search"
            size="small"
            placeholder={placeholder}
            value={search}
            onChange={onSearchChange}
            onKeyDown={(e) => {
              if (e.key === "Enter" && typeof onEnter === "function") {
                onEnter(e);
              }
            }}
            disabled={disabled}
          />
          {typeof onEnter === "function" && <div className={'text-search-tip'}>
            <small>Press Enter key to search</small>
          </div>
          }      
        </div>
      </>
    );
  }
);

ListHeaderSearch.propTypes = {
  placeholder: string,
  search: string,
  onSearchChange: func.isRequired,
  onEnter: func,
};
