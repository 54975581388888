import { renderActionCell } from "./ActionCell";

export const columns = [
  {
    title: "Actions",
    isExcludeFromExport: true,
    isSortDisabled: true,
    renderer: renderActionCell,
    path: "id"
  },
  {
    title: "Ident",
    path: "ident",
    isSearchable: true
  },
  {
    title: "Name",
    path: "name",
    isSearchable: true
  },
  {
    title: "Address",
    path: "mailingaddress_address1",
    isSearchable: true
  },
  {
    title: "City",
    path: "mailingaddress_city",
    isSearchable: true
  },
  {
    title: "State",
    path: "mailingaddress_state",
    isSearchable: true
  },
  {
    title: "Zip",
    path: "mailingaddress_postal_code",
    isSearchable: true
  },
  {
    title: "Active?",
    path: "active"
  }
];
