import { capitalize, get } from "lodash";
import { array, bool, func, number, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Container, Dimmer, Loader } from "semantic-ui-react";
import { ListComponent, ListHeader, PageAlert } from "../../../../components";
import {
  DRAFT,
  LINK_PURCHASE_ORDER_CREATE,
  PENDING,
  PURCHASE_ORDER_STATUS_MAP
} from "../../../../constants";
import { actions as objectActions } from "../../../../store/object/purchaseOrder/actions";
import { actions as viewActions } from "../../../../store/view/purchaseOrder/actions";
import { poRequestSelector } from "../../../../store/request/purchaseOrder/selectors";
import { poViewSelector } from "../../../../store/view/purchaseOrder/selectors";
import { DataTableWrapper } from "../../../../components/DataTable/Wrapper";
import { columns } from "./columns";
import { PurchaseOrderListFilters } from "./Filters";

class PurchaseOrderListComponent extends ListComponent {
  constructor(props) {
    super(props);
    const status = get(props, "match.params.status", null);
    const filter = status
      ? { status: PURCHASE_ORDER_STATUS_MAP[status] }
      : null;
    this.state = {
      ...this.state,
      columns,
      filter,
      sortOrder: {
        column: "number",
        isAscending: false
      }
    };
  }

  updateTable = () => {
    if (this.props.updateSortFilterLimit) {
      this.props.updateSortFilterLimit(this.props.dateFilter, this.state);
    }
  };

  componentDidMount() {
    const {
      dateFilter,
      isLoadingAll,
      purchaseOrders,
      fetchObjects,
      updateSortFilterLimit
    } = this.props;
    if (!isLoadingAll && isEmpty(purchaseOrders)) {
      fetchObjects(dateFilter, this.state);
    } else {
      // We don't need to load anything from the api, but we need to setup the first page of the grid
      updateSortFilterLimit(dateFilter, this.state);
    }
  }

  handleRefresh = () => {
    const { dateFilter, isLoadingAll, fetchObjects } = this.props;
    if (!isLoadingAll) {
      fetchObjects(dateFilter, this.state);
    }
  };

  componentDidUpdate(prevProps) {
    const { dateFilter, fetchObjects, updateSortFilterLimit } = this.props;
    const status = get(this.props, "match.params.status", null);
    const prevStatus = get(prevProps, "match.params.status", null);

    if (prevProps.dateFilter !== dateFilter) {
      fetchObjects(dateFilter, this.state);
    } else if (status !== prevStatus) {
      const filter = status
        ? { status: PURCHASE_ORDER_STATUS_MAP[status] }
        : null;
      this.setState(
        {
          filter
        },
        () => {
          updateSortFilterLimit(dateFilter, this.state);
        }
      );
    }
  }

  render() {
    const {
      dateFilter,
      total,
      isLoadingAll,
      loadError,
      purchaseOrders,
      setDateFilter
    } = this.props;
    const status = get(this.props, "match.params.status", null);
    const tableState = {
      ...this.state,
      onRefreshClick: this.handleRefresh,
      onExportClick: this.handleExportClick,
      onPageChange: this.handlePageChange,
      onSortChange: this.handleSortChange,
      onLimitChange: this.handleLimitChange,
      onSearchChange: this.handleSearchChange
    };
    return (
      <Container
        className="view-purchase-orders view-list view"
        as="article"
        fluid
      >
        <Dimmer active={isLoadingAll} inverted>
          <Loader size="large">Loading</Loader>
        </Dimmer>
        <ListHeader
          title="Purchase Orders"
          link={LINK_PURCHASE_ORDER_CREATE}
          searchPlaceholder="Search Purchase Orders"
          tableState={tableState}
          label={status && capitalize(status)}
          labelColor={
            status === PENDING ? "yellow" : status === DRAFT ? "grey" : "green"
          }
          total={total}
          limitCustomizable
          leftChildren={
            <PurchaseOrderListFilters
              dateFilter={dateFilter}
              onChangeFilter={value => {
                setDateFilter(value);
              }}
            />
          }
        />
        <PageAlert
          title="Error While Retrieving Purchase Orders"
          message={loadError}
        />
        <DataTableWrapper
          columns={columns}
          total={total}
          isLoadingAll={isLoadingAll}
          rows={purchaseOrders}
          tableState={tableState}
        />
      </Container>
    );
  }
}
const mapStateToProps = (state, props) => {
  const requestSelector = poRequestSelector();
  const viewSelector = poViewSelector();
  return {
    dateFilter: viewSelector.getDateFilter()(state),
    isLoadedAll: requestSelector.getIsLoadedAll()(state),
    isLoadingAll: requestSelector.getIsLoadingAll()(state),
    loadError: requestSelector.getLoadAllError()(state),
    purchaseOrders: viewSelector.getDateRangeFilteredList()(state),
    total: viewSelector.getDateRangeFilteredTotal()(state)
  };
};

PurchaseOrderListComponent.displayName = "PurchaseOrderList";
PurchaseOrderListComponent.propTypes = {
  exportObjects: func.isRequired,
  dateFilter: string.isRequired,
  fetchObjects: func.isRequired,
  isLoadedAll: bool,
  isLoadingAll: bool,
  loadError: string,
  purchaseOrders: array.isRequired,
  setDateFilter: func.isRequired,
  total: number,
  updateSortFilterLimit: func.isRequired
};

export const PurchaseOrderList = connect(
  mapStateToProps,
  {
    ...objectActions,
    setDateFilter: viewActions.setDateFilter
  }
)(PurchaseOrderListComponent);
