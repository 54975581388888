import { get, isEmpty, orderBy } from "lodash";

/**
 * Generate a list of options from an array of strings
 * @param values
 * @returns {*}
 */
export const createOptionsFromArray = values => {
  if (isEmpty(values)) {
    return [];
  }
  return values.map(value => {
    return { key: value, value, text: value };
  });
};

export const createOptionsFromObjects = (
  objects,
  valuePath = "id",
  textPath = "name"
) => {
  if (isEmpty(objects)) {
    return [];
  }

  return orderBy(
    objects.map(object => {
      return {
        key: get(object, [valuePath]),
        value: get(object, [valuePath]),
        text: get(object, [textPath])
      };
    }),
    [option => (option.text ? option.text.toLowerCase() : "")],
    ["asc"]
  );
};

export const createOptionsForItemSearch = objects => {
  if (isEmpty(objects)) {
    return [];
  }

  return orderBy(
    objects.map(object => {
      return {
        key: get(object, "id"),
        count: get(object, "inv.forsale"),
        sku: get(object, "sku"),
        text: `${get(object, "displayName")} (${parseFloat(get(object, "inv.forsale"))} for sale)`,
        value: get(object, "id"),
        disabled: Number(get(object, "inv.forsale", 0)) === 0
      };
    }),
    [option => (option.text ? option.text.toLowerCase() : "")],
    ["asc"]
  );
};

/**
 * Create options specific to customers
 * @param objects
 * @returns {*[]|Array}
 */
export const createOptionsFromUsers = objects => {
  if (isEmpty(objects)) {
    return [];
  }
  return orderBy(
    objects.map(object => {
      return {
        key: get(object, "id"),
        value: get(object, "id"),
        text: `${get(object, "name")} (${get(object, "email")})`
      };
    }),
    [option => (option.text ? option.text.toLowerCase() : "")],
    ["asc"]
  );
};

/**
 * Create options specific to customers
 * @param objects
 * @returns {*[]|Array}
 */
export const createOptionsFromCustomers = objects => {
  if (isEmpty(objects)) {
    return [];
  }
  return orderBy(
    objects.map(object => {
      return {
        key: get(object, "id"),
        value: get(object, "id"),
        text: `${get(object, "last_name")}, ${get(object, "first_name")} (${get(
          object,
          "identifier"
        )})`
      };
    }),
    [option => (option.text ? option.text.toLowerCase() : "")],
    ["asc"]
  );
};
