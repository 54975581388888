import cx from "classnames";
import React from "react";
import { Checkbox } from "semantic-ui-react";
import { findIndex, isArray } from "lodash";

export const FormCheckboxGroupInput = ({
  input,
  placeholder,
  option,
  focus,
  className,
  meta: { touched, error }
}) => {
  const { name } = input;
  const isValid = !error;
  const value = input.value
    ? input.value.toJS
      ? input.value.toJS()
      : input.value
    : {};

  className = cx({ valid: isValid }, className);

  const valueIndex = findIndex(value, v => {
    return v.id === option.id || (v.key && v.key === option.id);
  });
  return (
    <div className="pt-2">
      <Checkbox
        value={option.id}
        disabled={option.disabled}
        id={`${name}${option.id}`}
        label={placeholder}
        name={name}
        focus={focus}
        className={className}
        checked={valueIndex >= 0}
        onChange={event => {
          const newValue = value && isArray(value) ? [...value] : [];
          if (event.target.checked) {
            newValue.push(option);
          } else {
            newValue.splice(valueIndex, 1);
          }

          return input.onChange(newValue);
        }}
      />
    </div>
  );
};
