import React from "react";
import { get } from "lodash";
import cx from 'classnames';
import { Select } from "semantic-ui-react";
import { any, bool, func, string } from "prop-types";

const DefaultLocationSelect = React.memo(({
  input,
  label,
  error = false,
  required,
  options
}) => {
  const value = get(input, 'value', '');
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true,
  });
  return (
    <div className="form-input-container">
      {label && (
        <label className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
        <Select
          upward={false}
          fluid
          value={value}
          search
          name={input.name}
          error={error}
          selection
          options={options}
          onChange={(e, { value }) => {
            if (input && input.onChange) {
              input.onChange(value);
            }
          }}
          openOnFocus={false}
        />
    </div>
  );
});

DefaultLocationSelect.propTypes = {
  onChange: func,
  label: string,
  input: any,
  error: any,
  required: bool,
};


export { DefaultLocationSelect };