import dayjs from 'dayjs';
import { fromJS } from 'immutable';
import { get } from 'lodash';

import { getUser } from '../../../helpers/auth';
import { initialReportState } from '../reducer';
import {
  DATE,
  LOCATION_ID,
} from './constants';

export const initialValueByProductClassReportState = constants => {
  const date = dayjs().format("MM/DD/YYYY");
  const user = getUser();
  return fromJS({
    ...initialReportState(constants).toJS(),
    [DATE]: date,
    [LOCATION_ID]: get(user, "defaultLocation.id")
  });
};

export const valueByProductClassReportReducer = (state, action, constants) => {
  switch (action.type) {
    case constants.SET_DATE:
      return state.set(DATE, action.date);
    case constants.SET_LOCATION_ID:
      return state.set(LOCATION_ID, action.locationId);
    default:
      return state;
  }
};
