import { func } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { ErrorBoundaryComponent } from "../../../components";
import * as actions from "../../../store/auth/actions";
import { WEB_ROUTE_LOGIN } from './../../../constants/routes';

class LogoutComponent extends ErrorBoundaryComponent {
  componentDidMount() {
    const { history, logout } = this.props;
    logout();
    history.push(WEB_ROUTE_LOGIN);
  }

  render() {
    return null;
  }
}

LogoutComponent.propTypes = {
  logout: func,
};

const mapStateToProps = createStructuredSelector({});

export const Logout = withRouter(
  connect(
    mapStateToProps,
    actions
  )(LogoutComponent)
);
