import { get } from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  objectsSchema,
  serviceSchema,
  servicesSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import {
  actions as serviceRequestActions,
} from '../../request/service/actions';
import { sortAndLimitResults } from '../../view/actions';
import { actions as serviceViewActions } from '../../view/service/actions';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';
import { serviceSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(serviceRequestActions.setLoadAllError(null));
    dispatch(serviceRequestActions.setIsLoadingAll(true));
    try {
      const services = await fetchObjectsFromAPI(
        constants.FETCH_LIST_URL,
        "data.services"
      );

      // Normalize the result and store the services in redux
      const entities = get(normalize(services, servicesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(services, tableState, (ids, count) => {
          dispatch(serviceViewActions.setList(ids, count));
        })
      );
      dispatch(serviceRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(serviceRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(serviceRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = id => {
  return async dispatch => {
    dispatch(serviceRequestActions.setLoadError(null));
    dispatch(serviceRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(`${constants.FETCH_URL}/${id}`);
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          svc: serviceSchema
        }),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(serviceRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(serviceRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(serviceRequestActions.setSaveError(null));
    dispatch(serviceRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(constants.UPSERT_URL, data, true, false, 'data.data', data.id);
      const entities = get(
        normalize([result], servicesSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.service);
      }
    } catch (e) {
      captureException(e);
      dispatch(serviceRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(serviceRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const services = serviceSelector().getDenormalizedObjects()(getState());
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(services, tableState, (ids, count) => {
        dispatch(serviceViewActions.setList(ids, count));
      })
    );
  };
};

export const actions = {
  ...objectActions,
  fetchObject,
  fetchObjects,
  updateSortFilterLimit,
  upsertObject
};
