import React from 'react';
import { get } from 'lodash';
import { Form as FinalForm } from 'react-final-form';
import {
  Button,
  Form,
  Grid,
} from 'semantic-ui-react';
import { renderSelect, renderMonthSelect, renderYearSelect, renderInput } from './../../../../../helpers/form/renderers';
import { SALES_GOAL_TYPES } from './../../../../../constants/general';
import dayjs from 'dayjs';

export const AddGoalForm = ({ onSubmit, isSavingGoal, typeFilters, includeServiceRoute = false, serviceRoutes = [] }) => {
  const filteredGoalTypes = SALES_GOAL_TYPES.filter(goal => {
    return typeFilters.length > 0 ? (typeFilters.indexOf(goal.value) >= 0) : true;
  })
  const initialValues = { type: get(filteredGoalTypes[0], 'value', ''), month: dayjs().format('M'), year: dayjs().format('YYYY'), value: 0 };
  let serviceRouteOptions = [];
  if(includeServiceRoute && serviceRoutes.length > 0){
    initialValues.service_route_id = 1;
    serviceRouteOptions = serviceRoutes.map(route => {
      return {
        value: route.id,
        text: `Route ${route.name}`,
      }
    })
  }
  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={values => {
        onSubmit({ ...values });
      }}
    >
      {({ handleSubmit, valid, values, form }) => {
        const typeField = {
          name: "type",
          label: "Type",
          size: "large",
          renderer: renderSelect,
          options: filteredGoalTypes,
        }
        const monthField = {
          name: "month",
          label: "Month",
          size: "large",
          onSelect:  (month) => {
            form.change('month', month)
          }
        }
        const yearField = {
          name: "year",
          label: "Year",
          size: "large",
          onSelect:  (year) => {
            form.change('year', year)
          }
        }
        const valueField = {
          name: "value",
          label: "Value",
          size: "large",
          type: "number",
          onChange:  (value) => {
            form.change('value', value)
          }
        }
        const serviceRouteField = {
          name: "service_route_id",
          label: "Route",
          size: "large",
          renderer: renderSelect,
          options: serviceRouteOptions
        }
        return (
          <Form onSubmit={handleSubmit} className='w-100'>
            <Grid className='text-left w-100'>
              <Grid.Row>
                <Grid.Column width={3}>
                  {includeServiceRoute && renderSelect(serviceRouteField, serviceRouteOptions)}
                </Grid.Column>
                <Grid.Column width={4}>
                  {renderSelect(typeField, filteredGoalTypes)}
                </Grid.Column>
                <Grid.Column width={2}>
                  {renderMonthSelect(monthField)}
                </Grid.Column>
                <Grid.Column width={2}>
                  {renderYearSelect(yearField)}
                </Grid.Column>
                <Grid.Column width={2}>
                  {renderInput(valueField)}
                </Grid.Column>
                <Grid.Column width={3}>
                  <Button fluid loading={isSavingGoal} className="mt-4" color='green'>Set Goal</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        );
      }}
    </FinalForm>
  );
};
