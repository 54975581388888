import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Grid, Header, Icon } from "semantic-ui-react";
import { MESSAGE_INSUFFICIENT_PERMISSIONS } from "../../../constants/general";
import { AppHeader } from "../App/Header";
import { AppSidebar } from "../App/Sidebar";

const ErrorComponent = ({ statusCode }) => {
  const history = useHistory();
  const getStatusMessage = (statusCode) => {
    switch (statusCode) {
      case 403:
        return MESSAGE_INSUFFICIENT_PERMISSIONS;
      default:
        return "Unknown error";
    }
  };
  return (
    <div className="view-app d-flex justify-flex-start">
      <AppSidebar />
      <div className="view-app-container">
        <AppHeader />
        <main className="view-app-main position-relative">
          <Grid
            className="w-100"
            columns="equal"
            textAlign="center"
            verticalAlign="middle"
          >
            <Grid.Row columns="equal" textAlign="center">
              <Grid.Column>
                <Header icon textAlign="center">
                  <Header.Content>
                    <Icon name="warning circle" />
                    <div className="mt-2">{statusCode}</div>
                  </Header.Content>
                  <Header.Subheader>
                    {getStatusMessage(statusCode)}
                    <Link
                      to={{}}
                      onClick={() => {
                        history.goBack();
                      }}
                      className="d-block mt-3"
                    >
                      <span className="item-label">Go back</span>
                    </Link>
                  </Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </main>
      </div>
    </div>
  );
};

export const Error = ErrorComponent;
