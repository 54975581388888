import React from "react";
import { array } from "prop-types";

export const CustomerPortalInvoiceQtyCell = React.memo(
  ({ value }) => {
    const qty = value.reduce((previousValue, currentValue) => {
      return previousValue + parseFloat(currentValue.qty);
    }, 0);
    return <span className="cell mr-1">{qty}</span>;
  }
);

CustomerPortalInvoiceQtyCell.propTypes = {
  value: array,
};
