import React from "react";
import cx from "classnames";
import { get } from "lodash";
import { bool, object, func } from "prop-types";
import { Button, Card } from "semantic-ui-react";

export const AccountCard = ({ account, active, onSelect }) => {
  return (
    <Card
      className={cx({
        "mx-1 my-0 account-card": true,
        active: active,
      })}
    >
      <Card.Content>
        <p>
          <strong>{get(account, "customer_name")}</strong>
        </p>
        <p>Act: #{get(account, "account_number")}</p>
        <p>{get(account, "service_address.address1", " ")}</p>
        <p>Service Level: {get(account, "service_level")}</p>
      </Card.Content>
      <Card.Content extra className="p-0">
        <Button
          fluid
          primary={!active}
          color={active ? "green" : "blue"}
          onClick={() => {
            if (active) {
              return;
            }
            onSelect(account);
          }}
        >
          {active ? "Current" : "Select"}
        </Button>
      </Card.Content>
    </Card>
  );
};

AccountCard.propTypes = {
  account: object,
  active: bool,
  onSelect: func,
};
