import React from 'react';

import {
  get,
  isArray,
  isEmpty,
} from 'lodash';
import { Field } from 'react-final-form';

import { AddressAutoCompleteInput } from '../../components/Form/AddressAutoCompleteInput';
import { BucketSelect } from '../../components/Form/BucketSelect';
import { CheckboxGroup } from '../../components/Form/CheckboxGroup';
import { FormCheckboxInput } from '../../components/Form/CheckboxInput';
import { CustomerSelect } from '../../components/Form/CustomerSelect';
import { FormDatePickerInput } from '../../components/Form/DatePickerInput';
import {
  FormDateTimePickerInput,
} from '../../components/Form/DateTimePickerInput';
import { FormInput } from '../../components/Form/Input';
import { GoodVendorSelect } from '../../components/Form/GoodVendorSelect';
import { LocationSelect } from '../../components/Form/LocationSelect';
import { MonthSelect } from '../../components/Form/MonthSelect';
import { YearSelect } from '../../components/Form/YearSelect';
import { LotSelect } from '../../components/Form/LotSelect';
import { ProductClassSelect } from '../../components/Form/ProductClassSelect';
import { ProductTypeSelect } from '../../components/Form/ProductTypeSelect';
import { ProfitCenterSelect } from '../../components/Form/ProfitCenterSelect';
import { FormSelectInput } from '../../components/Form/SelectInput';
import { ServiceLevelSelect } from '../../components/Form/ServiceLevelSelect';
import { ServicePeriodSelect } from '../../components/Form/ServicePeriodSelect';
import { ServiceScheduleSelect } from '../../components/Form/ServiceScheduleSelect';
import { ServiceRouteSelect } from '../../components/Form/ServiceRouteSelect';
import { FormTextArea } from '../../components/Form/TextArea';
import { SprayMixContainerSelect } from '../../components/Form/SprayMixContainerSelect';
import { SprayMixRecipeSelect } from '../../components/Form/SprayMixRecipeSelect';
import { TaxCodeSelect } from '../../components/Form/TaxCodeSelect';
import { UserPinInput } from '../../components/Form/UserPinInput';
import { UserSelect } from '../../components/Form/UserSelect';
import { VendorSelect } from '../../components/Form/VendorSelect';

// This is for react-final-form issue: https://github.com/final-form/react-final-form/issues/130
const identity = value => (value);

/**
 * Render a list of fields for a basic form
 * @param fields
 * @param form The FormApi from react final form
 */
export const renderBasicForm = (fields, form) => {
  return (
    <>
      {!isEmpty(fields) &&
        fields.map(field => {
          if (field.excluded) {
            return null;
          }

          /* Handle conditional fields for rendering */
          if (field.conditional && form) {
            const fieldToCheck = form.getFieldState(field.conditional.name);
            if (fieldToCheck) {
              if(isArray(field.conditional.value)){
                if (
                  fieldToCheck &&
                  field.conditional.value.indexOf(fieldToCheck.value) === false
                ) {
                  return null;
                }
              } else
              if (
                fieldToCheck &&
                fieldToCheck.value !== field.conditional.value
              ) {
                return null;
              }
            }
          }

          if (field.decorator) {
            field.decorator(field, form);
          }

          if (field.renderer) {
            return field.renderer(field);
          }
          return renderInput(field);
        })}
    </>
  );
};

export const renderInput = field => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      required={field.required}
      className={field.className}
      appendedLabel={field.appendedLabel}
      labelPosition={field.labelPosition || 'right'}
      label={field.label}
      component={FormInput}
      inputType={field.inputType || field.type || "text"}
      size={field.size || "large"}
      transparent={field.transparent}
      disabled={field.disabled}
      readOnly={field.readOnly}
      validate={field.validate}
      initialValue={field.value}
      forceShowError={field.forceShowError}
      min={field.min}
      max={field.max}
      step={field.step}
      selectOnFocus={field.selectOnFocus}
      padded={field.padded}
      floatingError={field.floatingError}
      focus={field.focus}
      subscription={field.subscription && { value: true }}
    />
  );
};

export const renderTextArea = field => {
  return (
    <Field
      id={field.name}
      parse={identity}
      key={field.name}
      name={field.name}
      required={field.required}
      className={field.className}
      label={field.label}
      disabled={field.disabled}
      component={FormTextArea}
      rows={field.rows}
      type={field.type || "text"}
      size={field.size || "large"}
      validate={field.validate}
      focus={field.focus ? field.focus.toString() : undefined}
    />
  );
};

/**
 * Renders a select field
 * @param field
 */
export const renderDateTimePickerInput = field => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      component={FormDateTimePickerInput}
      size={field.size || "large"}
      validate={field.validate}
      dateFormat={field.dateFormat}
      minDate={field.minDate}
      maxDate={field.maxDate}
    />
  );
};

/**
 * Renders a select field
 * @param field
 */
export const renderDatePickerInput = field => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      placeholder={field.placeholder}
      component={FormDatePickerInput}
      size={field.size || "large"}
      validate={field.validate}
      initialValue={field.value}
      forceShowError={field.forceShowError}
    />
  );
};

/**
 * Renders a select field
 * @param field
 * @param options
 */
export const renderSelect = (field, options) => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      options={options || field.options}
      skipSort={field.skipSort}
      required={field.required}
      className={field.className}
      label={field.label}
      multi={field.multiple}
      component={FormSelectInput}
      size={field.size || "large"}
      validate={field.validate}
      initialValue={field.value}
      clearable={field.clearable}
      onChange={field.onChange}
    />
  );
};

/**
 * Renders a location select component
 * @param field
 * @returns {*}
 */
export const renderLocationSelect = (field, options) => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      component={LocationSelect}
      locationId={get(options, 'locationId', 0)}
      size={field.size || "large"}
      validate={field.validate}
      onChange={field.onChange}
      serviceLocationsOnly={field.serviceLocationsOnly}
    />
  );
};

/**
 * Renders a GoodVendor select component
 * @param field
 * @returns {*}
 */
 export const renderGoodVendorSelect = (field, options) => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      component={GoodVendorSelect}
      goodVendorId={get(options, 'goodVendorId', 0)}
      size={field.size || "large"}
      validate={field.validate}
      onChange={field.onChange}
    />
  );
};

/**
 * Renders a month select component
 * @param field
 * @returns {*}
 */
 export const renderMonthSelect = (field, options) => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      component={MonthSelect}
      size={field.size || "large"}
      validate={field.validate}
      onSelect={field.onSelect}
    />
  );
};

/**
 * Renders a month select component
 * @param field
 * @returns {*}
 */
 export const renderYearSelect = (field, options) => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      component={YearSelect}
      size={field.size || "large"}
      validate={field.validate}
      onSelect={field.onSelect}
    />
  );
};

/**
 * Renders a profit center select component
 * @param field
 * @returns {*}
 */
export const renderProfitCenterSelect = field => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      component={ProfitCenterSelect}
      size={field.size || "large"}
      validate={field.validate}
    />
  );
};

/**
 * Renders a customer select component
 * @param field
 * @returns {*}
 */
export const renderCustomerSelect = field => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      component={CustomerSelect}
      size={field.size || "large"}
      validate={field.validate}
    />
  );
};

/**
 * Renders a user select component
 * @param field
 * @returns {*}
 */
export const renderUserSelect = field => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      component={UserSelect}
      size={field.size || "large"}
      validate={field.validate}
      multi={field.multiple}
      filterBy={field.filterBy}
    />
  );
};

/**
 * Renders a vendor select component
 * @param field
 * @returns {*}
 */
export const renderVendorSelect = field => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      component={VendorSelect}
      size={field.size || "large"}
      validate={field.validate}
    />
  );
};

/**
 * Renders a service level select component
 * @param field
 * @param options
 * @returns {*}
 */
export const renderServiceLevelSelect = (field, options) => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      component={ServiceLevelSelect}
      size={field.size || "large"}
      validate={field.validate}
      upward={field.upward}
    />
  );
};

/**
 * Renders a service period select component
 * @param field
 * @param options
 * @returns {*}
 */
export const renderServicePeriodSelect = (field, options) => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      component={ServicePeriodSelect}
      size={field.size || "large"}
      validate={field.validate}
      upward={field.upward}
    />
  );
};

/**
 * Renders a service schedule select component
 * @param field
 * @param options
 * @returns {*}
 */
 export const renderServiceScheduleSelect = (field, options) => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      component={ServiceScheduleSelect}
      size={field.size || "large"}
      validate={field.validate}
      upward={field.upward}
    />
  );
};

/**
 * Renders a service route select component
 * @param field
 * @param options
 * @returns {*}
 */
 export const renderServiceRouteSelect = (field, options) => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      component={ServiceRouteSelect}
      size={field.size || "large"}
      validate={field.validate}
    />
  );
};

/**
 * Renders a product class select component
 * @param field
 * @param options
 * @returns {*}
 */
export const renderProductClassSelect = (field, options) => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      component={ProductClassSelect}
      size={field.size || "large"}
      validate={field.validate}
    />
  );
};

/**
 * Renders a product type select component
 * @param field
 * @param options
 * @returns {*}
 */
export const renderProductTypeSelect = (field, options) => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      component={ProductTypeSelect}
      size={field.size || "large"}
      validate={field.validate}
    />
  );
};

/**
 * Renders a bucket select component
 * @param field
 * @param options
 * @returns {*}
 */
export const renderBucketSelect = (field, options) => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      component={BucketSelect}
      size={field.size || "large"}
      validate={field.validate}
    />
  );
};

/**
 * Renders an inventory lot select component
 * @param field
 * @param options
 * @returns {*}
 */
export const renderLotSelect = (field, options) => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      goodId={get(options, 'goodId')}
      currentLocation={get(options, 'locationId')}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      component={LotSelect}
      size={field.size || "large"}
      validate={field.validate}
    />
  );
};

/**
 * Renders a spray mix select component
 * @param field
 * @param options
 * @returns {*}
 */
export const renderSprayMixContainerSelect = (field, options) => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      component={SprayMixContainerSelect}
      size={field.size || "large"}
      validate={field.validate}
      onChange={field.onchange}
    />
  );
};

/**
 * Renders a spray mix select component
 * @param field
 * @param options
 * @returns {*}
 */
export const renderSprayMixRecipeSelect = (field, options) => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      component={SprayMixRecipeSelect}
      size={field.size || "large"}
      validate={field.validate}
      onChange={field.onchange}
    />
  );
};

/**
 * Renders a "Yes/No" select field
 * @param field
 */
export const renderChemicalBaseUseTypeSelect = field => {
  return renderSelect(field, [
    { key: "standard", value: "standard", text: "Standard" },
    { key: "low_high", value: "low_high", text: "Low/High" },
    { key: "variable", value: "variable", text: "Variable (can be overridden)" },
  ]);
};

/**
 * Renders a "Yes/No" select field
 * @param field
 */
export const renderYesNo = field => {
  return renderSelect(field, [
    { key: "Y", value: "Y", text: "Yes" },
    { key: "N", value: "N", text: "No" }
  ]);
};

/**
 * Renders a yes/no field tied to an integer value
 * @param field
 * @returns {*}
 */
export const renderYesNoInteger = field => {
  return renderSelect(field, [
    { key: "Y", value: 1, text: "Yes" },
    { key: "N", value: 0, text: "No" }
  ]);
};

/**
 * Renders a "Yes/No" select field
 * @param field
 */
export const renderCheckbox = field => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      className={field.className}
      label={field.label}
      component={FormCheckboxInput}
      size={field.size || "large"}
      validate={field.validate}
    />
  );
};

/**
 * Renders a checkbox group.
 * @param field
 */
export const renderCheckboxGroup = field => {
  return (
    <CheckboxGroup
      key={field.name}
      columns={field.columns || 1}
      options={field.options}
      name={field.name}
      labelPath={field.labelPath}
      label={field.label}
    />
  );
};

/**
 * Renders a user pin input.
 * @param field
 */
export const renderPinField = field => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      required={field.required}
      className={field.className}
      appendedLabel={field.appendedLabel}
      label={field.label}
      component={UserPinInput}
      inputType={field.inputType || "text"}
      size={field.size || "large"}
      transparent={field.transparent}
      disabled={field.disabled}
      readOnly={field.readOnly}
      validate={field.validate}
      initialValue={field.value}
      focus={field.focus}
    />
  );
};

/**
 * Renders a tax code select.
 * @param field
 */
export const renderTaxCodeSelect = field => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      required={field.required}
      className={field.className}
      label={field.label}
      component={TaxCodeSelect}
      size={field.size || "large"}
      validate={field.validate}
    />
  );
}

export const renderAddressAutoCompleteInput = field => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      required={field.required}
      className={field.className}
      label={field.label}
      component={AddressAutoCompleteInput}
      size={field.size || "large"}
      validate={field.validate}
      focus={field.focus}
      placeholder={field.placeholder}
      onSelect={field.onSelect}
    />
  );
};