import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';

import { objectsSchema } from '../../../helpers/normalizers';
import { objectReducer } from '../../object/selectors';
import { makeViewSelector } from '../selectors';
import { allConstants as constants } from './constants';

export const poViewReducer = () => state =>
  state.getIn([constants.VIEW_REDUCER_NAME, constants.REDUCER_NAME]);

export const poViewSelector = () => {
  return {
    ...makeViewSelector(constants, poViewReducer),
    getDateFilter: () => {
      return createSelector(
        poViewReducer(),
        viewState => {
          return viewState.get(constants.DATE_FILTER);
        }
      );
    },

    getDateRangeFilteredObjects: () => {
      return createSelector(
        poViewReducer(),
        objectReducer(),
        (viewState, objectState) => {
          const dateFilter = viewState.get(constants.DATE_FILTER);
          let ids = viewState.getIn([
            constants.DATE_FILTERED_OBJECTS,
            dateFilter
          ]);
          if (ids) {
            ids = ids.toJS();
            const entities = objectState.toJS();
            return denormalize(
              { [constants.REDUCER_NAME]: ids },
              objectsSchema,
              entities
            )[[constants.REDUCER_NAME]];
          }
          return [];
        }
      );
    },

    getDateRangeFilteredList: () => {
      return createSelector(
        poViewReducer(),
        objectReducer(),
        (viewState, objectState) => {
          const dateFilter = viewState.get(constants.DATE_FILTER);
          let ids = viewState.getIn([
            constants.DATE_FILTERED_LIST,
            dateFilter,
            constants.LIST
          ]);

          if (ids) {
            ids = ids.toJS();
            const entities = objectState.toJS();
            return denormalize(
              { [constants.REDUCER_NAME]: ids },
              objectsSchema,
              entities
            )[[constants.REDUCER_NAME]];
          }
          return [];
        }
      );
    },
    getDateRangeFilteredTotal: () =>
      createSelector(
        poViewReducer(),
        state => {
          const dateFilter = state.get(constants.DATE_FILTER);
          return (
            state.getIn([
              constants.DATE_FILTERED_LIST,
              dateFilter,
              constants.TOTAL
            ]) || 0
          );
        }
      )
  };
};
