import {
  get,
} from 'lodash';
import dayjs from 'dayjs';
import { normalize } from 'normalizr';
import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  bedInvoicesSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import {
  actions as bedInvoiceRequestActions,
} from '../../request/bedInvoice/actions';
import { sortAndLimitResults } from '../../view/actions';
import { actions as bedInvoiceViewActions } from '../../view/bedInvoice/actions';
import {
  fetchFileFromAPI,
  fetchObjectsFromAPI,
  makeObjectActions,
} from '../actions';
import { allConstants as constants } from './constants';
import { bedInvoiceSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 * @param tableState
 * @returns {Function}
 */
export const fetchObjects = (tableState, additionalFilters) => {
  return async dispatch => {
    dispatch(bedInvoiceRequestActions.setLoadAllError(null));
    dispatch(bedInvoiceRequestActions.setIsLoadingAll(true));
    try {
      let url = constants.FETCH_LIST_URL;
      const query = new URLSearchParams(additionalFilters).toString();
      if (query) {
        url = `${url}?${query}`;
      }

      const response = await fetchObjectsFromAPI(url);
      const invoices = get(response, "invoices", []);
      // Normalize the result and store the invoices in redux
      const entities = get(normalize(invoices, bedInvoicesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(invoices, tableState, (ids, count) => {
          dispatch(bedInvoiceViewActions.setList(ids, count));
        })
      );
      dispatch(bedInvoiceRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(bedInvoiceRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(bedInvoiceRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Spit out an excel version of the report
 * @returns {Function}
 */
export const fetchXlsx = (originalStartDate, originalEndDate, filter, fetchAll) => {
  return async dispatch => {
    let startDate = originalStartDate;
    let endDate = originalEndDate;

    if (!startDate) {
      startDate = new Date(0)
    }

    if (!endDate) {
      endDate = new Date()
    }

    if (fetchAll) {
      startDate = new Date(0);
      endDate = new Date();
    }
    dispatch(bedInvoiceRequestActions.setLoadError(null));
    dispatch(bedInvoiceRequestActions.setIsLoading(true));
    try {
      let url = constants.FETCH_BED_PACKETS_EXPORTS_CSV_URL.replace(
        ":startDate",
        dayjs(startDate).format("YYYY-MM-DD")
      ).replace(":endDate", dayjs(endDate).format("YYYY-MM-DD"));
      // Parameterize filter object and append to url if not empty
      if (filter && Object.keys(filter).length > 0) {
        const queryString = Object.keys(filter).map((key) => {
          return `${key}=${filter[key]}`;
        });
        url += `?${queryString.join("&")}`;
      }
      await fetchFileFromAPI(
        url,
        `all-bed-packets-${startDate}-${endDate}.csv`
      );
    } catch (e) {
      console.error(e);
      captureException(e);
      dispatch(bedInvoiceRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(bedInvoiceRequestActions.setIsLoading(false));
    }
  };
};


/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const invoices = bedInvoiceSelector().getDenormalizedObjects()(getState());
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(invoices, tableState, (ids, count) => {
        dispatch(bedInvoiceViewActions.setList(ids, count));
      })
    );
  };
};

export const actions = {
  ...objectActions,
  fetchObjects,
  fetchXlsx,
  updateSortFilterLimit,
};
