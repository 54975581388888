import React from "react";
import { StatsRow } from "../../../../StatsRow";

export const YearToDate = ({ data }) => {
  return (
    <>
      <StatsRow divided sections={data} />
    </>
  );
};
