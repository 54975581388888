import { schema } from 'normalizr';

import {
  REDUCER_NAME as INVOICE_REDUCER_NAME,
} from '../../store/object/invoice/constants';
import { customerSchema } from './customer';
import { locationSchema } from './location';
import { profitCenterSchema } from './profitCenter';

export const invoiceSchema = new schema.Entity(INVOICE_REDUCER_NAME, {
  customer: customerSchema,
  location: locationSchema,
  profit_center: profitCenterSchema
});

export const invoicesSchema = [invoiceSchema];
