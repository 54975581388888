import { renderCurrencyCell } from "../../../../components/DataTable/Cells/renderers";
import { renderDateCell } from "../../../../components/DataTable/Cells/renderers";
import { renderActionCell } from "./ActionCell";

export const columns = [
  {
    title: "Actions",
    isExcludeFromExport: true,
    isSortDisabled: true,
    renderer: renderActionCell,
    path: "id"
  },
  {
    title: "PO #",
    path: "number",
    isSearchable: true
  },
  {
    title: "Date",
    path: "order_date",
    renderer: renderDateCell
  },
  {
    title: "Author",
    path: "user.email",
    isSearchable: true
  },
  {
    title: "Status",
    path: "status"
  },
  {
    title: "Location",
    path: "location.name",
    isSearchable: true
  },
  {
    title: "Vendor",
    path: "vendor.name",
    isSearchable: true
  },
  {
    title: "Estimate",
    path: "estimate_subtotal",
    isNumeric: true,
    renderer: renderCurrencyCell
  },
  {
    title: "Retail",
    path: "total",
    isNumeric: true,
    renderer: renderCurrencyCell
  }
];
