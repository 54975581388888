import { get } from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  objectsSchema,
  productTypeSchema,
  productTypesSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import {
  actions as productTypeRequestActions,
} from '../../request/productType/actions';
import { sortAndLimitResults } from '../../view/actions';
import {
  actions as productTypeViewActions,
} from '../../view/productType/actions';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';
import { productTypeSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Retrieve a single object form the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = id => {
  return async dispatch => {
    dispatch(productTypeRequestActions.setLoadError(null));
    dispatch(productTypeRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(`${constants.FETCH_URL}/${id}`);
      // Normalize the result and store the roles/users in redux
      const entities = get(
        normalize(result, { ...objectsSchema, pc: productTypeSchema }),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
      dispatch(productTypeRequestActions.setIsLoaded(id, true));
    } catch (e) {
      captureException(e);
      dispatch(productTypeRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(productTypeRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Load a list of objects
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(productTypeRequestActions.setLoadAllError(null));
    dispatch(productTypeRequestActions.setIsLoadingAll(true));
    try {
      const productTypes = await fetchObjectsFromAPI(constants.FETCH_LIST_URL);

      // Normalize the result and store the productTypes in redux
      const entities = get(
        normalize(productTypes, productTypesSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(productTypes, tableState, (ids, count) => {
          dispatch(productTypeViewActions.setList(ids, count));
        })
      );
      dispatch(productTypeRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(productTypeRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(productTypeRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(productTypeRequestActions.setSaveError(null));
    dispatch(productTypeRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(constants.UPSERT_URL, data);
      const entities = get(
        normalize([result.pc], productTypesSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.pc);
      }
    } catch (e) {
      captureException(e);
      dispatch(productTypeRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(productTypeRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const productTypes = productTypeSelector().getDenormalizedObjects()(
      getState()
    );
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(
        productTypes,
        tableState,
        productTypeViewActions.setList
      )
    );
  };
};

export const actions = {
  ...objectActions,
  updateSortFilterLimit,
  fetchObjects,
  fetchObject,
  upsertObject
};
