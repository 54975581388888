import { get } from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  cyclesSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import { actions as cycleRequestActions } from '../../request/cycle/actions';
import { sortAndLimitResults } from '../../view/actions';
import { actions as cycleViewActions } from '../../view/cycle/actions';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';
import { cycleSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(cycleRequestActions.setLoadAllError(null));
    dispatch(cycleRequestActions.setIsLoadingAll(true));
    try {
      const cycles = await fetchObjectsFromAPI(constants.FETCH_LIST_URL);

      // Normalize the result and store the cycles in redux
      const entities = get(normalize(cycles, cyclesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(cycles, tableState, (ids, count) => {
          dispatch(cycleViewActions.setList(ids, count));
        })
      );
      dispatch(cycleRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(cycleRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(cycleRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(cycleRequestActions.setSaveError(null));
    dispatch(cycleRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(constants.UPSERT_URL, data);
      const entities = get(
        normalize([result.cycle], cyclesSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.cycle);
      }
    } catch (e) {
      captureException(e);
      dispatch(cycleRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(cycleRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const cycles = cycleSelector().getDenormalizedObjects()(getState());
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(cycles, tableState, (ids, count) => {
        dispatch(cycleViewActions.setList(ids, count));
      })
    );
  };
};

export const actions = {
  ...objectActions,
  fetchObjects,
  updateSortFilterLimit,
  upsertObject
};
