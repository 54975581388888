export const VIEW_REDUCER_NAME = "view";

// State Variables
export const LIST = "list";
export const ALL = "all";
export const RELATED_OBJECTS = "relatedObjects";
export const RELATED_FILTERED_LIST = "relatedFilteredList";
export const DATA = "data";
export const TOTAL = "total";
export const NOTES = "notes";
export const DOCS = "docs";
