import * as reportConstants from "../constants";
import { makeReportConstants } from "../util";
export const REDUCER_NAME = "updatedPaymentProfiles";

export const FETCH_RESULTS_URL = `${process.env.REACT_APP_API_URL}api/v2/reports/customer-billing-account-updates`;
export const FETCH_RESULTS_XLS_URL = `${process.env.REACT_APP_API_URL}api/v2/reports/customer-billing-account-updates/export?format=xlsx`;

// Action Types
const defaultConstants = makeReportConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...reportConstants,
  REDUCER_NAME,
  FETCH_RESULTS_URL,
  FETCH_RESULTS_XLS_URL,
};
