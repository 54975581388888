import { makeActionCreator } from '../../../helpers/actions';
import { makeViewActions } from '../actions';
import { allConstants as constants } from './constants';

const viewActions = makeViewActions(constants);

const setLocationId = makeActionCreator(constants.SET_LOCATION_ID, "id");
const setProductClassId = makeActionCreator(
  constants.SET_PRODUCTION_CLASS_ID,
  "id"
);
export const actions = { ...viewActions, setLocationId, setProductClassId };
