import { lazy } from "react";

/**
 * Lazy load all of the components
 */

export const AppointmentListComponent = lazy(() =>
  import("../Appointment/AppointmentList").then(module => ({
    default: module.AppointmentList
  }))
);
export const AppointmentDetailsComponent = lazy(() =>
  import("../Appointment/Details").then(module => ({
    default: module.AppointmentDetails
  }))
);
export const BedInvoiceListComponent = lazy(() =>
  import("../Invoice/BedInvoiceList").then(module => ({
    default: module.BedInvoiceList
  }))
);
export const BedPacketComponent = lazy(() =>
  import("../BedPacket/BedPacket").then(module => ({
    default: module.BedPacket
  }))
);
export const BedPacketListComponent = lazy(() =>
  import("../BedPacket/BedPacketList").then(module => ({
    default: module.BedPacketList
  }))
);
export const SprayMixComponent = lazy(() =>
  import("../Inventory/SprayMix").then(module => ({
    default: module.SprayMix
  }))
);
export const ChemicalDetailsComponent = lazy(() =>
  import("../Inventory/ChemicalDetails").then(module => ({
    default: module.ChemicalDetails
  }))
);
export const ChemicalListComponent = lazy(() =>
  import("../Inventory/ChemicalList").then(module => ({
    default: module.ChemicalList
  }))
);
export const CycleListComponent = lazy(() =>
  import("../Inventory/CycleList").then(module => ({
    default: module.CycleList
  }))
);
export const CustomerDetailsComponent = lazy(() =>
  import("../Customer/Details").then(module => ({
    default: module.CustomerDetails
  }))
);
export const CustomerListComponent = lazy(() =>
  import("../Customer/CustomerList").then(module => ({
    default: module.CustomerList
  }))
);
export const CustomerMapComponent = lazy(() =>
  import("../Customer/CustomerMap").then(module => ({
    default: module.CustomerMap
  }))
);
export const DashboardComponent = lazy(() =>
  import("../Dashboard").then(module => ({ default: module.Dashboard }))
);
export const SalesDashboardComponent = lazy(() =>
  import("../SalesDashboard").then(module => ({ default: module.SalesDashboard }))
);
export const ARSMDashboardComponent = lazy(() =>
  import("../ARSMDashboard").then(module => ({ default: module.ARSMDashboard }))
);
export const EstimateListComponent = lazy(() =>
  import("../Estimate/EstimateList").then(module => ({
    default: module.EstimateList
  }))
);
export const EstimateDetailsComponent = lazy(() =>
  import("../Estimate/Details").then(module => ({
    default: module.EstimateDetails
  }))
);
export const GoodCountListComponent = lazy(() =>
  import("../Inventory/GoodCountList").then(module => ({
    default: module.GoodCountList
  }))
);
export const GoodTypeDetailsComponent = lazy(() =>
  import("../Inventory/GoodTypeDetails").then(module => ({
    default: module.GoodTypeDetails
  }))
);
export const GoodTypeListComponent = lazy(() =>
  import("../Inventory/GoodTypeList").then(module => ({
    default: module.GoodTypeList
  }))
);
export const GroupDetailsComponent = lazy(() =>
  import("../Group/Details").then(module => ({
    default: module.GroupDetails
  }))
);
export const GroupListComponent = lazy(() =>
  import("../Group/GroupList").then(module => ({
    default: module.GroupList
  }))
);

export const InvoiceDetailsComponent = lazy(() =>
  import("../Invoice/Details").then(module => ({
    default: module.InvoiceDetails
  }))
);
export const InvoiceListComponent = lazy(() =>
  import("../Invoice/InvoiceList").then(module => ({
    default: module.InvoiceList
  }))
);
export const InvoiceCreateComponent = lazy(() =>
  import("../Invoice/Create").then(module => ({
    default: module.InvoiceCreate
  }))
);

export const GoodDetailsComponent = lazy(() =>
  import("../Inventory/GoodDetails").then(module => ({
    default: module.GoodDetails
  }))
);
export const GoodListComponent = lazy(() =>
  import("../Inventory/GoodList").then(module => ({
    default: module.GoodList
  }))
);
export const InventoryActivityListComponent = lazy(() =>
  import("../Inventory/InventoryActivityList").then(module => ({
    default: module.InventoryActivityList
  }))
);
export const InventoryHistoryListComponent = lazy(() =>
  import("../Inventory/InventoryHistoryList").then(module => ({
    default: module.InventoryHistoryList
  }))
);
export const LotCostChangeComponent = lazy(() =>
  import("../Inventory/LotCostChange").then(module => ({
    default: module.LotCostChange
  }))
);

export const LocationListComponent = lazy(() =>
  import("../Location/LocationList").then(module => ({
    default: module.LocationList
  }))
);

export const LocationDetailsComponent = lazy(() =>
  import("../Location/LocationDetails").then(module => ({
    default: module.LocationDetails
  }))
);

export const RoleListComponent = lazy(() =>
  import("../Role/RoleList").then(module => ({ default: module.RoleList }))
);
export const RoleDetailsComponent = lazy(() =>
  import("../Role/Details").then(module => ({
    default: module.RoleDetails
  }))
);

export const ProductClassListComponent = lazy(() =>
  import("../Inventory/ProductClassList").then(module => ({
    default: module.ProductClassList
  }))
);
export const ProductClassDetailsComponent = lazy(() =>
  import("../Inventory/ProductClassDetails").then(module => ({
    default: module.ProductClassDetails
  }))
);

export const ProfitCenterDetailsComponent = lazy(() =>
  import("../Location/ProfitCenterDetails").then(module => ({
    default: module.ProfitCenterDetails
  }))
);
export const ProfitCenterListComponent = lazy(() =>
  import("../Location/ProfitCenterList").then(module => ({
    default: module.ProfitCenterList
  }))
);
export const PurchaseOrderCreateComponent = lazy(() =>
  import("../PurchaseOrder/Create").then(module => ({
    default: module.PurchaseOrderCreate
  }))
);
export const PurchaseOrderDetailsComponent = lazy(() =>
  import("../PurchaseOrder/Details").then(module => ({
    default: module.PurchaseOderDetails
  }))
);
export const PurchaseOrderListComponent = lazy(() =>
  import("../PurchaseOrder/PurchaseOrderList").then(module => ({
    default: module.PurchaseOrderList
  }))
);

export const ServiceDetailsComponent = lazy(() =>
  import("../Service/ServiceDetails").then(module => ({
    default: module.ServiceDetails
  }))
);

export const ServiceListComponent = lazy(() =>
  import("../Service/ServiceList").then(module => ({
    default: module.ServiceList
  }))
);
export const ServiceLevelListComponent = lazy(() =>
  import("../Service/ServiceLevelList").then(module => ({
    default: module.ServiceLevelList
  }))
);
export const ServiceLevelDetailsComponent = lazy(() =>
  import("../Service/ServiceLevelDetails").then(module => ({
    default: module.ServiceLevelDetails
  }))
);
export const ServicePeriodListComponent = lazy(() =>
  import("../Service/ServicePeriodList").then(module => ({
    default: module.ServicePeriodList
  }))
);
export const ServicePeriodDetailsComponent = lazy(() =>
  import("../Service/ServicePeriodDetails").then(module => ({
    default: module.ServicePeriodDetails
  }))
);
export const ServicePriceListComponent = lazy(() =>
  import("../Service/ServicePriceList").then(module => ({
    default: module.ServicePriceList
  }))
);
export const ServicePriceDetailsComponent = lazy(() =>
  import("../Service/ServicePriceDetails").then(module => ({
    default: module.ServicePriceDetails
  }))
);
export const ServiceScheduleListComponent = lazy(() =>
  import("../Service/ServiceScheduleList").then(module => ({
    default: module.ServiceScheduleList
  }))
);
export const ServiceScheduleDetailsComponent = lazy(() =>
  import("../Service/ServiceScheduleDetails").then(module => ({
    default: module.ServiceScheduleDetails
  }))
);

export const ServiceRouteDetailsComponent = lazy(() =>
  import("../Service/RouteDetails").then(module => ({
    default: module.ServiceRouteDetails
  }))
);

export const ServiceRouteListComponent = lazy(() =>
  import("../Service/RouteList").then(module => ({
    default: module.ServiceRouteList
  }))
);

export const ServiceVisitsComponent = lazy(() =>
  import("../Service/ServiceVisits").then(module => ({
    default: module.ServiceVisits
  }))
);

export const ServiceStatementDetailsComponent = lazy(() =>
  import("../Service/StatementDetails").then(module => ({
    default: module.ServiceStatementDetails
  }))
);

export const ServiceStatementPeriodComponent = lazy(() =>
  import("../Service/StatementPeriod").then(module => ({
    default: module.ServiceStatementPeriod
  }))
);

export const ServiceStatementFilesListComponent = lazy(() =>
  import("../Service/StatementFilesList").then(module => ({
    default: module.ServiceStatementFilesList
  }))
);

export const ServiceStatementListComponent = lazy(() =>
  import("../Service/StatementList").then(module => ({
    default: module.ServiceStatementList
  }))
);

export const AutoStatementsComponent = lazy(() =>
  import("../Service/AutoRunTax").then(module => ({
    default: module.AutoRunTax
  }))
);

export const AutoPaymentsComponent = lazy(() =>
  import("../Service/AutoPayments").then(module => ({
    default: module.AutoPayments
  }))
);

export const ServiceErorLogListComponent = lazy(() =>
  import("../Service/ErrorLogList").then(module => ({
    default: module.ServiceErrorLogList
  }))
);

export const TransferDetailsComponent = lazy(() =>
  import("../Inventory/TransferDetails").then(module => ({
    default: module.TransferDetails
  }))
);
export const TransferListComponent = lazy(() =>
  import("../Inventory/TransferList").then(module => ({
    default: module.TransferList
  }))
);

export const UserDetailsComponent = lazy(() =>
  import("../User/Details").then(module => ({ default: module.UserDetails }))
);
export const UserListComponent = lazy(() =>
  import("../User/UserList").then(module => ({ default: module.UserList }))
);
export const VendorListComponent = lazy(() =>
  import("../Vendor/VendorList").then(module => ({
    default: module.VendorList
  }))
);
export const VendorDetailsComponent = lazy(() =>
  import("../Vendor/Details").then(module => ({
    default: module.VendorDetails
  }))
);
export const WorkOrderDetailsComponent = lazy(() =>
  import("../WorkOrder/Details").then(module => ({
    default: module.WorkOrderDetails
  }))
);
export const WorkOrderListComponent = lazy(() =>
  import("../WorkOrder/WorkOrderList").then(module => ({
    default: module.WorkOrderList
  }))
);

//
// Utilities
//
export const ReversalsUtility = lazy(() =>
  import("../Utilities/Reversals").then(module => ({
    default: module.ReversalsUtility
  }))
);
export const ImportUtility = lazy(() =>
  import("../Utilities/Import").then(module => ({
    default: module.ImportUtility
  }))
);

//
// Settings
//
export const DashboardSettings = lazy(() =>
  import("../Settings").then(module => ({
    default: module.DashboardSettings
  }))
);

//
// REPORTS
//
export const Reports = lazy(() =>
  import("../Reports").then(module => ({
    default: module.Reports
  }))
);
export const ActivityByLocationReportComponent = lazy(() =>
  import("../Reports/Inventory/ActivityByLocation").then(module => ({
    default: module.ActivityByLocationReport
  }))
);
export const FatFingerReportComponent = lazy(() =>
  import("../Reports/Statement/FatFinger").then(module => ({
    default: module.FatFingerReport
  }))
);
export const CommissionReportComponent = lazy(() =>
  import("../Reports/Other/Commission").then(module => ({
    default: module.CommissionReport
  }))
);
export const NonRoseSalesReportComponent = lazy(() =>
  import("../Reports/Retail/NonRoseSales").then(module => ({
    default: module.NonRoseSalesReport
  }))
);
export const GcActivityReportComponent = lazy(() =>
  import("../Reports/Retail/GcActivity").then(module => ({
    default: module.GcActivityReport
  }))
);
export const InventoryAdjustmentsReportComponent = lazy(() =>
  import("../Reports/Other/InventoryAdjustments").then(module => ({
    default: module.InventoryAdjustmentsReport
  }))
);
export const TransferReportComponent = lazy(() =>
  import("../Reports/Other/Transfer").then(module => ({
    default: module.TransferReport
  }))
);
export const InventorySalesReportComponent = lazy(() =>
  import("../Reports/Inventory/InventorySales").then(module => ({
    default: module.InventorySalesReport
  }))
);
export const InventoryReportComponent = lazy(() =>
  import("../Reports/Inventory/Inventory").then(module => ({
    default: module.InventoryReport
  }))
);
export const OpenItemsReportComponent = lazy(() =>
  import("../Reports/Other/OpenItems").then(module => ({
    default: module.OpenItemsReport
  }))
);
export const PaidRosesReportComponent = lazy(() =>
  import("../Reports/Retail/PaidRoses").then(module => ({
    default: module.PaidRosesReport
  }))
);
export const ReorderPointsReportComponent = lazy(() =>
  import("../Reports/Inventory/ReorderPoints").then(module => ({
    default: module.ReorderPointsReport
  }))
);
export const RosesOnHandReportComponent = lazy(() =>
  import("../Reports/Inventory/RosesOnHand").then(module => ({
    default: module.RosesOnHandReport
  }))
);
export const SalesTaxSummaryReportComponent = lazy(() =>
  import("../Reports/Statement/SalesTaxSummary").then(module => ({
    default: module.SalesTaxSummaryReport
  }))
);
export const SalesTaxDetailReportComponent = lazy(() =>
  import("../Reports/Statement/SalesTaxDetail").then(module => ({
    default: module.SalesTaxDetailReport
  }))
);
export const ServiceAccountsReceivableComponent = lazy(() =>
  import("../Reports/Other/ServiceAccountsReceivable").then(module => ({
    default: module.ServiceAccountsReceivableReport
  }))
);
export const TabsReportComponent = lazy(() =>
  import("../Reports/ServiceCustomer/Tabs").then(module => ({
    default: module.TabsReport
  }))
);
export const ExpiringCCReportComponent = lazy(() =>
  import("../Reports/ServiceCustomer/ExpiringCC").then(module => ({
    default: module.ExpiringCCReport
  }))
);
export const UpdatedPaymentProfilesReportComponent = lazy(() =>
  import("../Reports/ServiceCustomer/UpdatedPaymentProfiles").then(module => ({
    default: module.UpdatedPaymentProfilesReport
  }))
);
export const ActiveCustomersReportComponent = lazy(() =>
  import("../Reports/ServiceCustomer/ActiveCustomers").then(module => ({
    default: module.ActiveCustomersReport
  }))
);
export const InvoiceTaxesReportComponent = lazy(() =>
  import("../Reports/Other/InvoiceTaxes").then(module => ({
    default: module.InvoiceTaxesReport
  }))
);
export const ProductClassReceiptsReportsComponent = lazy(() =>
  import("../Reports/Other/ProductClassReceipts").then(module => ({
    default: module.ProductClassReceiptsReport
  }))
);
export const ServiceRevenueSummaryReportComponent = lazy(() =>
  import("../Reports/Statement/ServiceRevenueSummary").then(module => ({
    default: module.ServiceRevenueSummaryReport
  }))
);
export const ServiceRevenueDetailReportComponent = lazy(() =>
  import("../Reports/Statement/ServiceRevenueDetail").then(module => ({
    default: module.ServiceRevenueDetailReport
  }))
);
export const StartsStopsReportComponent = lazy(() =>
  import("../Reports/Other/StartsStops").then(module => ({
    default: module.StartsStopsReport
  }))
);
export const ValueByProductClassComponent = lazy(() =>
  import("../Reports/Inventory/ValueByProductClass").then(module => ({
    default: module.ValueByProductClassReport
  }))
);
export const WorkOrderStatusReportComponent = lazy(() =>
  import("../Reports/Other/WorkOrderStatus").then(module => ({
    default: module.WorkOrderStatusReport
  }))
);

export const FinancialReport = lazy(() =>
  import("../Reports/Other/Financial").then(module => ({
    default: module.FinancialReport
  }))
);
