import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "period";
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}admin/statement/periods`;
export const FETCH_EDIT_URL = `${process.env.REACT_APP_API_URL}admin/statement/editPeriod`;
export const FETCH_VIEW_URL = `${process.env.REACT_APP_API_URL}admin/statement/viewPeriod`;
export const FETCH_INVOICE_VIEW_URL = `${process.env.REACT_APP_API_URL}api/v2/invoices/period`;
export const FETCH_INVOICE_PREVIOUS_VIEW_URL = `${process.env.REACT_APP_API_URL}api/v2/invoices-previous/period`;
export const FETCH_STATEMENT_VIEW_URL = `${process.env.REACT_APP_API_URL}api/v2/statements/period`;
export const INIT_PERIOD_URL = `${process.env.REACT_APP_API_URL}admin/statement/initPeriod/:periodId`;
export const RETALLY_PERIOD_URL = `${process.env.REACT_APP_API_URL}admin/statement/retally/:periodId`;
export const LATE_FEES_URL = `${process.env.REACT_APP_API_URL}admin/statement/latefees/:periodId`;
export const LOCK_ROUTE_URL = `${process.env.REACT_APP_API_URL}admin/statement/lockroute/:periodId`;
export const LOCK_PERIOD_URL = `${process.env.REACT_APP_API_URL}admin/statement/lockperiod/:periodId`;
export const GENERATE_PDFS_URL = `${process.env.REACT_APP_API_URL}admin/statement/generatePeriodPdfs/:periodId/:num`;
export const MAIL_STATEMENTS_URL = `${process.env.REACT_APP_API_URL}api/v2/statements/mailstatements/:periodId`;
export const FETCH_FILES_LIST_URL = `${process.env.REACT_APP_API_URL}api/v2/export/period/list-pdf/:periodId`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}admin/statement/savePeriod`;
export const CC_AUTO_URL = `${process.env.REACT_APP_API_URL}admin/payment/perioddata/:periodId/ccauto`;
export const ACH_AUTO_URL = `${process.env.REACT_APP_API_URL}admin/payment/perioddata/:periodId/achauto`;
export const FETCH_TSV_FILE = `${process.env.REACT_APP_API_URL}admin/payment/file/:periodId/:type`;
export const FETCH_PERIOD_FILE = `${process.env.REACT_APP_API_URL}api/v2/export/period/pdf/:periodId/:folder/:filename`;
export const POST_PAYMENT_URL = `${process.env.REACT_APP_API_URL}admin/payment/post`;
export const POST_BULK_PAYMENT_URL = `${process.env.REACT_APP_API_URL}admin/payment/bulk_post_payment`;
export const POST_ACH_BULK_PAYMENT_URL = `${process.env.REACT_APP_API_URL}admin/payment/bulk-ach-auto-pay`;
export const POST_TAXES_URL = `${process.env.REACT_APP_API_URL}api/v2/taxes/post`;
export const QUEUE_TAXES_URL = `${process.env.REACT_APP_API_URL}api/v2/taxes/queue`;
export const FETCH_QUEUE_STATUS_URL = `${process.env.REACT_APP_API_URL}api/v2/statements/queue_status/:periodId`;
export const UPLOAD_NEWSLETTER_URL = `${process.env.REACT_APP_API_URL}api/v2/statements/upload/newsletter/:periodId`;
export const FETCH_NEWSLETTER_PDF = `${process.env.REACT_APP_API_URL}api/v2/export/newsletter/:periodId`;
export const DELETE_NEWSLETTER_PDF = `${process.env.REACT_APP_API_URL}api/v2/statements/delete/newsletter/:periodId`;
export const RETALLY_LEDGER_URL = `${process.env.REACT_APP_API_URL}api/v2/statements/retally-activity/:periodId`;
export const REDUCER_NAME = "periods";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_LIST_URL,
  FETCH_FILES_LIST_URL,
  UPSERT_URL,
  FETCH_EDIT_URL,
  GENERATE_PDFS_URL,
  LOCK_PERIOD_URL,
  LOCK_ROUTE_URL,
  LATE_FEES_URL,
  FETCH_VIEW_URL,
  FETCH_INVOICE_VIEW_URL,
  FETCH_INVOICE_PREVIOUS_VIEW_URL,
  FETCH_STATEMENT_VIEW_URL,
  INIT_PERIOD_URL,
  RETALLY_PERIOD_URL,
  CC_AUTO_URL,
  ACH_AUTO_URL,
  FETCH_TSV_FILE,
  FETCH_PERIOD_FILE,
  POST_PAYMENT_URL,
  POST_BULK_PAYMENT_URL,
  POST_ACH_BULK_PAYMENT_URL,
  UPSERT_PROPERTY,
  POST_TAXES_URL,
  QUEUE_TAXES_URL,
  FETCH_QUEUE_STATUS_URL,
  MAIL_STATEMENTS_URL,
  UPLOAD_NEWSLETTER_URL,
  FETCH_NEWSLETTER_PDF,
  DELETE_NEWSLETTER_PDF,
  RETALLY_LEDGER_URL,
};
