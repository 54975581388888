import { array, bool, func, object, shape, string } from "prop-types";

export const column = shape({
  title: string.isRequired,
  path: string | array,
  renderer: func,
  sortPaths: array,
  rendererProps: object,
  isExcludedFromExport: bool,
  isSortDisabled: bool,
  isNumeric: bool
});
