import { REPORT_REDUCER_NAME } from "../../report/constants";
import * as reportConstants from "../constants";
import { makeReportConstants } from "../util";
export const REDUCER_NAME = "serviceRevenueSummary";
export const PERIOD_ID = "periodId";
export const PRICE_LEVELS = "priceLevels";
export const ROUTES = "routes";
export const PROFIT_CENTERS = "profitCenters";

export const SET_PERIOD_ID = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_PERIOD_ID`;
export const SET_PROFIT_CENTERS = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_PROFIT_CENTERS`;
export const SET_ROUTES = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/`;
export const SET_PRICE_LEVELS = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_PRICE_LEVELS`;
export const FETCH_RESULTS_URL = `${process.env.REACT_APP_API_URL}admin/report/svcrevsum/:periodId`;

// Action Types
const defaultConstants = makeReportConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...reportConstants,
  PERIOD_ID,
  REDUCER_NAME,
  SET_PERIOD_ID,
  FETCH_RESULTS_URL,
  SET_PROFIT_CENTERS,
  SET_PRICE_LEVELS,
  SET_ROUTES,
  PRICE_LEVELS,
  ROUTES,
  PROFIT_CENTERS
};
