import { fromJS } from "immutable";
import { initialReportState } from "../reducer";

export const initialSprayMixMonthlySummaryReportState = constants => {
  return fromJS({
    ...initialReportState(constants).toJS(),
  });
};

export const sprayMixMonthlySummaryReportReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
