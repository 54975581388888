import { get } from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  goodTypeSchema,
  goodTypesSchema,
  objectsSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import {
  actions as goodTypeRequestActions,
} from '../../request/goodType/actions';
import { sortAndLimitResults } from '../../view/actions';
import { actions as goodTypeViewActions } from '../../view/goodType/actions';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';
import { goodTypeSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Retrieve a single object form the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = id => {
  return async dispatch => {
    dispatch(goodTypeRequestActions.setLoadError(null));
    dispatch(goodTypeRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        `${constants.FETCH_URL}/${id}`,
        "data"
      );
      // Normalize the result and store the roles/users in redux
      const entities = get(
        normalize(result, { ...objectsSchema, good: goodTypeSchema }),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
      dispatch(goodTypeRequestActions.setIsLoaded(id, true));
    } catch (e) {
      captureException(e);
      dispatch(goodTypeRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(goodTypeRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Load a list of objects
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(goodTypeRequestActions.setLoadAllError(null));
    dispatch(goodTypeRequestActions.setIsLoadingAll(true));
    try {
      const goodTypes = await fetchObjectsFromAPI(constants.FETCH_LIST_URL);

      // Normalize the result and store the goodTypes in redux
      const entities = get(
        normalize(goodTypes, goodTypesSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(goodTypes, tableState, (ids, count) => {
          dispatch(goodTypeViewActions.setList(ids, count));
        })
      );
      dispatch(goodTypeRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(goodTypeRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(goodTypeRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(goodTypeRequestActions.setSaveError(null));
    dispatch(goodTypeRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.UPSERT_URL,
        data,
        false,
        true
      );
      const entities = get(
        normalize([result.good], goodTypesSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.good);
      }
    } catch (e) {
      captureException(e);
      dispatch(goodTypeRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(goodTypeRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const goodTypes = goodTypeSelector().getDenormalizedObjects()(getState());
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(goodTypes, tableState, (ids, count) => {
        dispatch(goodTypeViewActions.setList(ids, count));
      })
    );
  };
};

export const actions = {
  ...objectActions,
  fetchObject,
  fetchObjects,
  updateSortFilterLimit,
  upsertObject
};
