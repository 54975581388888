import { get, has, isArray } from "lodash";

import {
  WEB_ROUTE_DEFAULT,
  WEB_ROUTE_LOGIN,
  WEB_ROUTE_NOT_FOUND,
  WEB_ROUTE_PORTAL_DEFAULT,
  WEB_ROUTE_PORTAL_PEEK_DEFAULT,
} from "../constants/routes";
import {
  SESSION_CURRENT_PORTAL_ACCOUNT,
  SESSION_PORTAL_ACCOUNTS,
  SESSION_REFRESH_TOKEN_NAME,
  SESSION_TOKEN_NAME,
  SESSION_USER_NAME,
  SESSION_PERMISSIONS_NAME,
  SESSION_API_VERSION,
  SESSION_API_ENVIRONMENT,
} from "../constants/session";

export const assertViewPermission = (
  history,
  permissions = [],
  permissionId = null
) => {
  const authUser = getUser();
  permissionId = permissionId
    ? permissionId
    : get(history, "location.pathname", "")
        .replace("/", "")
        .replaceAll("/", ".");
  // Sales Dashboard - ARSM only
  if(permissionId === 'admin.sales' || permissionId === 'admin.arsm'){
    const isARSM = get(authUser, 'all_roles', []).filter(role => {
      return role.identifier === 'ROLE_ROUTE_SUPERVISOR';
    });
    if(isARSM.length === 0){
      return false;
    }    
  }
  // Find permissionId in pre-defined permissions
  if (
    isArray(permissions) &&
    !permissions.find((permission) => {
      if (permission.id === permissionId) {
        return true;
      }
      if (
        permissionId.indexOf(permission.id) === 0 &&
        permissionId.split(".").length - 1 === permission.id.split(".").length
      ) {
        permissionId = permission.id;
        return true;
      }
      return false;
    })
  ) {
    return true;
  }

  // Else, check is user has permission
  return hasPermission(authUser, permissionId);
};

export const hasPermission = (user, permissionToCheck) => {
  if (has(user, "permissions")) {
    return user.permissions.filter((permission) => {
      return permission.id === permissionToCheck;
    }).length > 0
      ? true
      : false;
  } else {
    return false;
  }
};

export const hasRoles = (user, rolesToCheck = []) => {
  if (has(user, "roles")) {
    return user.roles.filter((role) => {
      return rolesToCheck.indexOf(role.identifier) >= 0;
    }).length > 0
      ? true
      : false;
  } else {
    return false;
  }
};

export const setToken = (token, refreshToken) => {
  localStorage.setItem(SESSION_TOKEN_NAME, token);
  if (refreshToken) {
    localStorage.setItem(SESSION_REFRESH_TOKEN_NAME, refreshToken);
  }
};

export const setUser = (user) => {
  const accounts = get(user, "all_accounts", [])
  if(isArray(accounts)){
    setPortalAccounts(accounts);
  } else {
    setPortalAccounts([]);
  }
  const permissions = get(user, "permissions", [])
  if(isArray(permissions)){
    setPermissions(permissions);
  }
  localStorage.setItem(SESSION_USER_NAME, JSON.stringify(user));
};

/**
 * Set api version to local storage
 * @param {string} apiVersion 
 */
export const setApiVersion = (apiVersion) => {
  localStorage.setItem(SESSION_API_VERSION, apiVersion);
}

/**
 * Set api environment to local storage
 * @param {string} apiEnvironment
 */
export const setApiEnvironment = (apiEnvironment) => {
  localStorage.setItem(SESSION_API_ENVIRONMENT, apiEnvironment);
}

export const setPermissions = (permissions) => {
  localStorage.setItem(SESSION_PERMISSIONS_NAME, JSON.stringify(permissions));
};

export const setCurrentAccount = (account) => {
  return localStorage.setItem(
    SESSION_CURRENT_PORTAL_ACCOUNT,
    JSON.stringify(account)
  );
};

export const setPortalAccounts = (accounts) => {
  return localStorage.setItem(
    SESSION_PORTAL_ACCOUNTS,
    JSON.stringify(accounts)
  );
};

/**
 * Get api version from local storage
 * @returns {string} apiVersion
 */
export const getApiVersion = () => {
  return localStorage.getItem(SESSION_API_VERSION);
}

/**
 * Get api environment from local storage
 * @returns {string} apiEnvironment
 */
export const getApiEnvironment = () => {
  return localStorage.getItem(SESSION_API_ENVIRONMENT);
}

export const getPermissions = () => {
  let perms = localStorage.getItem(SESSION_PERMISSIONS_NAME);
  if (perms && perms !== 'undefined') {
    return JSON.parse(perms);
  }
  return [];
};

export const getToken = () => {
  return localStorage.getItem(SESSION_TOKEN_NAME);
};

export const getRefreshToken = () => {
  return localStorage.getItem(SESSION_REFRESH_TOKEN_NAME);
};

export const getCurrentAccount = () => {
  const account = localStorage.getItem(SESSION_CURRENT_PORTAL_ACCOUNT);
  if (account && account !== 'undefined') {
    return get(JSON.parse(account), 'customer.identifier');
  }
  return null;
};

export const getCurrentAccountObject = () => {
  const account = localStorage.getItem(SESSION_CURRENT_PORTAL_ACCOUNT);
  if (account && account !== 'undefined') {
    return JSON.parse(account);
  }
  return null;
};

export const getPortalAccounts = () => {
  const accounts = localStorage.getItem(SESSION_PORTAL_ACCOUNTS);
  if (accounts) {
    let accountsArray = JSON.parse(accounts);
    return accountsArray;
  }
  return [];
};

export const getUser = () => {
  const user = localStorage.getItem(SESSION_USER_NAME);
  if (user) {
    let userModel = JSON.parse(user);

    userModel.hasRole = function(roleToCheck) {
      return isArray(this.all_roles) && this.all_roles.filter((role) => {
        return role.identifier === roleToCheck;
      }).length > 0
        ? true
        : false;
    };

    userModel.hasPermission = function(permissionToCheck) {
      return isArray(this.permissions) && this.permissions.filter((permission) => {
        return permission.id === permissionToCheck;
      }).length > 0
        ? true
        : false;
    };

    userModel.currentAccount =
      userModel.currentAccount ||
      (get(userModel, 'accounts', []).length === 1 ? userModel.accounts[0] : null);

    return userModel;
  }
  return user;
};

export const goToDefaultRoute = (history) => {
  history.push(WEB_ROUTE_DEFAULT);
};

export const goToPortalRoute = (history) => {
  history.push(WEB_ROUTE_PORTAL_DEFAULT);
};

export const goToLogin = (history) => {
  history.push(WEB_ROUTE_LOGIN);
};

export const routePrefix = (location) => {
  const prefix = `/${location.pathname
    .replace("/", "")
    .split("/")
    .shift()}`;
  switch (prefix) {
    case WEB_ROUTE_PORTAL_PEEK_DEFAULT:
    case WEB_ROUTE_PORTAL_DEFAULT:
    case WEB_ROUTE_DEFAULT:
    case WEB_ROUTE_LOGIN:
    case "/":
      return prefix;
    default:
      return WEB_ROUTE_NOT_FOUND;
  }
};
