import { upsertObjectToAPI } from "../../../../store/object/actions";
import { CALCULATE_TAXES_URL } from "../../../../store/object/location/constants";
import { getErrorMessage } from "../../../../helpers/error";
import { captureException } from "@sentry/browser";
import { toast } from "react-toastify";

export const calculateTax = async (invoice) => {
  try {
    const response = await upsertObjectToAPI(
      CALCULATE_TAXES_URL,
      { 
        invoice: invoice,
      },
      false,
      true
    );
    return response;
  } catch (e) {
    const message = getErrorMessage(e);
    toast.error(message);
    captureException(e);
  }
  return;
};
