import { fromJS } from "immutable";
import { initialReportState } from "../reducer";
import { PERIOD_ID, DETAILS, SUMMARY } from "./constants";

export const initialSalesNonRoseReportState = constants => {
  return fromJS({
    ...initialReportState(constants).toJS(),
    [PERIOD_ID]: null,
    [DETAILS]: [],
    [SUMMARY]: []
  });
};

export const salesNonRoseReportReducer = (state, action, constants) => {
  switch (action.type) {
    case constants.SET_PERIOD_ID:
      return state.set(PERIOD_ID, action.periodId);

    case constants.SET_DETAILS:
      return state.setIn([constants.DETAILS], fromJS(action.data));

    case constants.SET_SUMMARY:
      return state.setIn([constants.SUMMARY], fromJS(action.data));
    default:
      return state;
  }
};
