import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "sprayMixRecipe";
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}api/v2/spray-mix/recipe`;
export const FETCH_NOTE_LIST_URL = `${process.env.REACT_APP_API_URL}admin/note/spraymixrecipe`;
export const FETCH_URL = `${process.env.REACT_APP_API_URL}api/v2/spray-mix/recipe`;
export const DELETE_URL = `${process.env.REACT_APP_API_URL}api/v2/spray-mix/recipe/delete/:id`;
export const TOGGLE_URL = `${process.env.REACT_APP_API_URL}api/v2/spray-mix/recipe/toggle/:id`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}api/v2/spray-mix/recipe`;
export const UPSERT_NOTE_URL = `${process.env.REACT_APP_API_URL}admin/note`;
export const REDUCER_NAME = "sprayMixRecipes";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_LIST_URL,
  FETCH_NOTE_LIST_URL,
  FETCH_URL,
  DELETE_URL,
  TOGGLE_URL,
  UPSERT_URL,
  UPSERT_NOTE_URL,
  UPSERT_PROPERTY
};
