import React from "react";
import { CurrencyCell } from "./CurrencyCell";
import { CustomerCell } from "./CustomerCell";
import { DateCell } from "./DateCell";
import { DateTimeCell } from "./DateTimeCell";
import { DatePeriodCell } from "./DatePeriodCell";
import { FloatCell } from "./FloatCell";
import { InputCell } from "./InputCell";
import { IntCell } from "./IntCell";
import { TaxCell } from "./TaxCell";
import { YesNoCell } from "./YesNoCell";
import { YesNoIntCell } from "./YesNoIntCell";
import { OnOffCell } from "./OnOffCell";
import { PrintedYesNoCell } from "./PrintedYesNoCell";
import { NonNullCell } from "./NonNullCell";
import { CustomerNameLinked } from "./CustomerNameLinked";
import { WorkOrderTypeCell } from './WorkOrderTypeCell';
import { CustomerPortalEstimateStatusCell } from "./CustomerPortalEstimateStatusCell";
import { CustomerPortalInvoiceItemsCell } from "./CustomerPortalInvoiceItemsCell";
import { CustomerPortalInvoiceQtyCell } from "./CustomerPortalInvoiceQtyCell";
import { CustomerPortalInvoicePriceCell } from "./CustomerPortalInvoicePriceCell";

export const renderInputCell = props => {
  return <InputCell {...props} />;
};

export const renderIntCell = props => {
  return <IntCell {...props} />;
};

export const renderFloatCell = props => {
  return <FloatCell {...props} />;
};

export const renderCurrencyCell = props => {
  return <CurrencyCell {...props} />;
};

export const renderDateCell = props => {
  return <DateCell {...props} />;
};

export const renderDateTimeCell = props => {
  return <DateTimeCell {...props} />;
};

export const renderDatePeriodCell = props => {
  return <DatePeriodCell {...props} />;
};

export const renderYesNoCell = props => {
  return <YesNoCell {...props} />;
};

export const renderYesNoIntCell = props => {
  return <YesNoIntCell {...props} />;
};

export const renderOnOffCell = props => {
  return <OnOffCell {...props} />;
};

export const renderPrintedYesNoCell = props => {
  return <PrintedYesNoCell {...props} />;
};

export const renderCustomerCell = props => {
  return <CustomerCell {...props} />;
}

export const renderCustomerNameLinked = props => {
  return <CustomerNameLinked {...props} />;
};

export const renderPortalEstimateStatusCell = props => {
  return <CustomerPortalEstimateStatusCell {...props} />;
};

export const renderInvoiceItemsCell = props => {
  return <CustomerPortalInvoiceItemsCell {...props} />;
};

export const renderInvoiceQtyCell = props => {
  return <CustomerPortalInvoiceQtyCell {...props} />;
};

export const renderInvoicePriceCell = props => {
  return <CustomerPortalInvoicePriceCell {...props} />;
};

export const renderNonNullCell = props => {
  return <NonNullCell {...props} />;
};

export const renderWorkOrderTypeCell = props => {
  return <WorkOrderTypeCell {...props} />;
};

export const renderTaxCell = props => {
  return <TaxCell {...props} />;
};
