import React from "react";

import { array, arrayOf, bool, object } from "prop-types";

import { column, tableState } from "../../../helpers/shapes";
import { DataTable } from "../../DataTable";
import cx from "classnames";

export const DataTableWrapper = React.memo((props) => {
  const { padded } = props;
  return (
    <section
      className={cx({
        "data-table-wrapper": true,
        "pt-4": padded,
        "w-100 mb-5": true,
      })}
    >
      <DataTable {...props} />
    </section>
  );
});

DataTableWrapper.propTypes = {
  columns: arrayOf(column).isRequired,
  tableProps: object,
  tableState: tableState.isRequired,
  rows: array,
  padded: bool,
};

DataTableWrapper.defaultProps = {
  padded: true,
};
