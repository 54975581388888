import { isEmpty } from 'lodash';

import {
  APPOINTMENT_REDUCER_NAME,
  appointmentObjectActions,
} from '../../store/object/appointment';
import {
  BED_ACTION_REDUCER_NAME,
  bedActionObjectActions,
} from '../../store/object/bedAction';
import {
  BED_INVOICE_REDUCER_NAME,
  bedInvoiceObjectActions,
} from '../../store/object/bedInvoice';
import {
  BED_PACKET_REDUCER_NAME,
  bedPacketObjectActions,
} from '../../store/object/bedPacket';
import {
  BIG_COMMERCE_REDUCER_NAME,
  bigCommerceObjectActions,
} from '../../store/object/bigCommerce';
import {
  CHEMICAL_REDUCER_NAME,
  chemicalObjectActions,
} from '../../store/object/chemical';
import {
  CUSTOMER_REDUCER_NAME,
  customerObjectActions,
} from '../../store/object/customer';
import {
  CYCLE_REDUCER_NAME,
  cycleObjectActions,
} from '../../store/object/cycle';
import {
  DELIVERY_ACTION_REDUCER_NAME,
  deliveryActionObjectActions,
} from '../../store/object/deliveryAction';
import {
  DOC_REDUCER_NAME,
  docObjectActions,
} from '../../store/object/doc';
import {
  ESTIMATE_REDUCER_NAME,
  estimateObjectActions,
} from '../../store/object/estimate';
import {
  GOOD_REDUCER_NAME,
  goodObjectActions,
} from '../../store/object/good';
import {
  GOOD_SERVICE_REDUCER_NAME,
  goodServiceObjectActions,
} from '../../store/object/goodService';
import {
  GOOD_TYPE_REDUCER_NAME,
  goodTypeObjectActions,
} from '../../store/object/goodType';
import { goodVendorObjectActions } from '../../store/object/goodVendor';
import {
  REDUCER_NAME as GOOD_VENDOR_REDUCER_NAME,
} from '../../store/object/goodVendor/constants';
import {
  GROUP_REDUCER_NAME,
  groupObjectActions,
} from '../../store/object/group';
import {
  INFO_CALL_ACTION_REDUCER_NAME,
  infoCallActionObjectActions,
} from '../../store/object/infoCallAction';
import {
  INVENTORY_ACTIVITY_REDUCER_NAME,
  inventoryActivityActions,
} from '../../store/object/inventoryActivity';
import {
  INVENTORY_LOT_REDUCER_NAME,
  inventoryLotActions,
} from '../../store/object/inventoryLot';
import {
  INVOICE_REDUCER_NAME,
  invoiceObjectActions,
} from '../../store/object/invoice';
import {
  LOCATION_REDUCER_NAME,
  locationObjectActions,
} from '../../store/object/location';
import {
  LOCATION_TYPE_REDUCER_NAME,
  locationTypeObjectActions,
} from '../../store/object/locationType';
import {
  NOTE_REDUCER_NAME,
  noteObjectActions,
} from '../../store/object/note';
import {
  NOTE_CATEGORY_REDUCER_NAME,
  noteCategoryObjectActions,
} from '../../store/object/noteCategory';
import {
  PAYMENT_REDUCER_NAME,
  paymentObjectActions,
} from '../../store/object/payment';
import {
  PAYMENT_LOG_REDUCER_NAME,
  paymentLogObjectActions,
} from '../../store/object/paymentLog';
import {
  PERIOD_REDUCER_NAME,
  periodObjectActions,
} from '../../store/object/period';
import {
  PERMISSION_REDUCER_NAME,
  permissionObjectActions,
} from '../../store/object/permission';
import {
  PICKUP_ACTION_REDUCER_NAME,
  pickupActionObjectActions,
} from '../../store/object/pickupAction';
import {
  PRODUCT_CLASS_REDUCER_NAME,
  productClassObjectActions,
} from '../../store/object/productClass';
import {
  PRODUCT_TYPE_REDUCER_NAME,
  productTypeObjectActions,
} from '../../store/object/productType';
import {
  PROFIT_CENTER_REDUCER_NAME,
  profitCenterObjectActions,
} from '../../store/object/profitCenter';
import {
  PURCHASE_ORDER_REDUCER_NAME,
  purchaseOrderObjectActions,
} from '../../store/object/purchaseOrder';
import {
  ROLE_REDUCER_NAME,
  roleObjectActions,
} from '../../store/object/role';
import {
  ROUTE_REDUCER_NAME,
  routeObjectActions,
} from '../../store/object/route';
import {
  SALES_CALL_ACTION_REDUCER_NAME,
  salesCallActionObjectActions,
} from '../../store/object/salesCallAction';
import {
  SALES_GOAL_REDUCER_NAME,
  salesGoalObjectActions,
} from '../../store/object/salesGoal';
import {
  SERVICE_REDUCER_NAME,
  serviceObjectActions,
} from '../../store/object/service';
import {
  SERVICE_LEVEL_REDUCER_NAME,
  serviceLevelObjectActions,
} from '../../store/object/serviceLevel';
import {
  SERVICE_PERIOD_REDUCER_NAME,
  servicePeriodObjectActions,
} from '../../store/object/servicePeriod';
import {
  SERVICE_PRICE_REDUCER_NAME,
  servicePriceObjectActions,
} from '../../store/object/servicePrice';
import {
  SERVICE_SCHEDULE_REDUCER_NAME,
  serviceScheduleObjectActions,
} from '../../store/object/serviceSchedule';
import {
  SERVICE_VISIT_REDUCER_NAME,
  serviceVisitObjectActions,
} from '../../store/object/serviceVisit';
import {
  SETTING_REDUCER_NAME,
  settingObjectActions,
} from '../../store/object/setting';
import {
  SHIPMENT_ACTION_REDUCER_NAME,
  shipmentActionObjectActions,
} from '../../store/object/shipmentAction';
import {
  SPOT_PLANT_CALL_REDUCER_NAME,
  spotPlantCallObjectActions,
} from '../../store/object/spotPlantCall';
import {
  SPRAY_MIX_CONTAINER_REDUCER_NAME,
  sprayMixContainerObjectActions,
} from '../../store/object/sprayMixContainer';
import {
  SPRAY_MIX_RECIPE_REDUCER_NAME,
  sprayMixRecipeObjectActions,
} from '../../store/object/sprayMixRecipe';
import {
  STATEMENT_REDUCER_NAME,
  statementObjectActions,
} from '../../store/object/statement';
import {
  TAX_CODE_REDUCER_NAME,
  taxCodeObjectActions,
} from '../../store/object/taxCode';
import {
  TRANSFER_REDUCER_NAME,
  transferObjectActions,
} from '../../store/object/transfer';
import {
  USER_REDUCER_NAME,
  userObjectActions,
} from '../../store/object/user';
import {
  VENDOR_REDUCER_NAME,
  vendorObjectActions,
} from '../../store/object/vendor';
import {
  WORK_ORDER_REDUCER_NAME,
  workOrderObjectActions,
} from '../../store/object/workOrder';
import { appointmentsSchema } from './appointment';
import { bedActionsSchema } from './bedAction';
import { bedInvoicesSchema } from './bedInvoice';
import { bedPacketsSchema } from './bedPacket';
import { bigCommercesSchema } from './bigCommerce';
import { chemicalsSchema } from './chemical';
import { customersSchema } from './customer';
import { cyclesSchema } from './cycle';
import { deliveryActionsSchema } from './deliveryAction';
import { docsSchema } from './doc';
import { estimatesSchema } from './estimate';
import { goodsSchema } from './good';
import { goodServicesSchema } from './goodService';
import { goodTypesSchema } from './goodType';
import { goodVendorsSchema } from './goodVendor';
import { groupsSchema } from './group';
import { infoCallActionsSchema } from './infoCallAction';
import { inventoryActivitiesSchema } from './inventoryActivity';
import { inventoryLotsSchema } from './inventoryLot';
import { invoicesSchema } from './invoice';
import { locationsSchema } from './location';
import { locationTypesSchema } from './locationType';
import { notesSchema } from './note';
import { noteCategoriesSchema } from './noteCategory';
import { paymentsSchema } from './payment';
import { paymentLogsSchema } from './paymentLog';
import { periodsSchema } from './period';
import { permissionsSchema } from './permission';
import { pickupActionsSchema } from './pickupAction';
import { productClassesSchema } from './productClass';
import { productTypesSchema } from './productType';
import { profitCentersSchema } from './profitCenter';
import { purchaseOrdersSchema } from './purchaseOrder';
import { rolesSchema } from './role';
import { routesSchema } from './route';
import { salesCallActionsSchema } from './salesCallAction';
import { salesGoalsSchema } from './salesGoal';
import { servicesSchema } from './service';
import { serviceLevelsSchema } from './serviceLevel';
import { servicePeriodsSchema } from './servicePeriod';
import { servicePricesSchema } from './servicePrice';
import { serviceSchedulesSchema } from './serviceSchedule';
import { serviceVisitsSchema } from './serviceVisit';
import { settingsSchema } from './setting';
import { sprayMixContainersSchema } from './sprayMixContainer';
import { sprayMixRecipesSchema } from './sprayMixRecipe';
import { shipmentActionsSchema } from './shipmentAction';
import { spotPlantCallsSchema } from './spotPlantCall';
import { statementsSchema } from './statement';
import { taxCodesSchema } from './taxCode';
import { transfersSchema } from './transfer';
import { usersSchema } from './user';
import { vendorsSchema } from './vendor';
import { workOrdersSchema } from './workOrder';

export * from "./appointment";
export * from "./bedAction";
export * from "./bedInvoice";
export * from "./bedPacket";
export * from "./bigCommerce";
export * from "./cycle";
export * from "./chemical";
export * from "./customer";
export * from "./deliveryAction";
export * from "./doc";
export * from "./estimate";
export * from "./good";
export * from "./goodType";
export * from "./goodService";
export * from "./goodVendor";
export * from "./group";
export * from "./infoCallAction";
export * from "./inventoryActivity";
export * from "./inventoryLot";
export * from "./invoice";
export * from "./location";
export * from "./locationType";
export * from "./note";
export * from "./noteCategory";
export * from "./payment";
export * from "./paymentLog";
export * from "./pickupAction";
export * from "./productClass";
export * from "./productType";
export * from "./profitCenter";
export * from "./purchaseOrder";
export * from "./role";
export * from "./period";
export * from "./permission";
export * from "./route";
export * from "./salesGoal";
export * from "./salesCallAction";
export * from "./service";
export * from "./serviceLevel";
export * from "./servicePeriod";
export * from "./servicePrice";
export * from "./serviceSchedule";
export * from "./serviceVisit";
export * from "./setting";
export * from "./shipmentAction";
export * from "./spotPlantCall";
export * from "./sprayMixContainer";
export * from "./sprayMixRecipe";
export * from "./statement";
export * from "./taxCode";
export * from "./transfer";
export * from "./vendor";
export * from "./user";
export * from "./workOrder";

/**
 * All of the schemas that we support normalization on.  Used in the upsertNormalizedEntities function below to inspect
 * the entities that are returned from the normalize() function in the api requests
 * @type {*[]}
 */
export const schemaNames = [
  APPOINTMENT_REDUCER_NAME,
  BED_ACTION_REDUCER_NAME,
  BED_INVOICE_REDUCER_NAME,
  BED_PACKET_REDUCER_NAME,
  BIG_COMMERCE_REDUCER_NAME,
  CYCLE_REDUCER_NAME,
  CHEMICAL_REDUCER_NAME,
  CUSTOMER_REDUCER_NAME,
  DELIVERY_ACTION_REDUCER_NAME,
  DOC_REDUCER_NAME,
  ESTIMATE_REDUCER_NAME,
  GOOD_REDUCER_NAME,
  GOOD_TYPE_REDUCER_NAME,
  GOOD_SERVICE_REDUCER_NAME,
  GOOD_VENDOR_REDUCER_NAME,
  GROUP_REDUCER_NAME,
  INFO_CALL_ACTION_REDUCER_NAME,
  INVENTORY_ACTIVITY_REDUCER_NAME,
  INVENTORY_LOT_REDUCER_NAME,
  INVOICE_REDUCER_NAME,
  LOCATION_REDUCER_NAME,
  LOCATION_TYPE_REDUCER_NAME,
  NOTE_REDUCER_NAME,
  NOTE_CATEGORY_REDUCER_NAME,
  PAYMENT_REDUCER_NAME,
  PAYMENT_LOG_REDUCER_NAME,
  PERIOD_REDUCER_NAME,
  PERMISSION_REDUCER_NAME,
  PICKUP_ACTION_REDUCER_NAME,
  PRODUCT_CLASS_REDUCER_NAME,
  PRODUCT_TYPE_REDUCER_NAME,
  PROFIT_CENTER_REDUCER_NAME,
  PURCHASE_ORDER_REDUCER_NAME,
  ROLE_REDUCER_NAME,
  ROUTE_REDUCER_NAME,
  SALES_CALL_ACTION_REDUCER_NAME,
  SALES_GOAL_REDUCER_NAME,
  SERVICE_REDUCER_NAME,
  SERVICE_LEVEL_REDUCER_NAME,
  SERVICE_PERIOD_REDUCER_NAME,
  SERVICE_PRICE_REDUCER_NAME,
  SERVICE_SCHEDULE_REDUCER_NAME,
  SERVICE_VISIT_REDUCER_NAME,
  SETTING_REDUCER_NAME,
  SHIPMENT_ACTION_REDUCER_NAME,
  SPOT_PLANT_CALL_REDUCER_NAME,
  SPRAY_MIX_CONTAINER_REDUCER_NAME,
  SPRAY_MIX_RECIPE_REDUCER_NAME,
  STATEMENT_REDUCER_NAME,
  TAX_CODE_REDUCER_NAME,
  TRANSFER_REDUCER_NAME,
  VENDOR_REDUCER_NAME,
  USER_REDUCER_NAME,
  WORK_ORDER_REDUCER_NAME
];

/**
 * Should match the redux store so that we can denormalize the state and get full objects
 */
export const objectsSchema = {
  [APPOINTMENT_REDUCER_NAME]: appointmentsSchema,
  [BED_ACTION_REDUCER_NAME]: bedActionsSchema,
  [BED_INVOICE_REDUCER_NAME]: bedInvoicesSchema,
  [BED_PACKET_REDUCER_NAME]: bedPacketsSchema,
  [BIG_COMMERCE_REDUCER_NAME]: bigCommercesSchema,
  [CYCLE_REDUCER_NAME]: cyclesSchema,
  [CHEMICAL_REDUCER_NAME]: chemicalsSchema,
  [CUSTOMER_REDUCER_NAME]: customersSchema,
  [DELIVERY_ACTION_REDUCER_NAME]: deliveryActionsSchema,
  [DOC_REDUCER_NAME]: docsSchema,
  [ESTIMATE_REDUCER_NAME]: estimatesSchema,
  [GOOD_REDUCER_NAME]: goodsSchema,
  [GOOD_TYPE_REDUCER_NAME]: goodTypesSchema,
  [GOOD_SERVICE_REDUCER_NAME]: goodServicesSchema,
  [GOOD_VENDOR_REDUCER_NAME]: goodVendorsSchema,
  [GROUP_REDUCER_NAME]: groupsSchema,
  [INFO_CALL_ACTION_REDUCER_NAME]: infoCallActionsSchema,
  [INVENTORY_ACTIVITY_REDUCER_NAME]: inventoryActivitiesSchema,
  [INVENTORY_LOT_REDUCER_NAME]: inventoryLotsSchema,
  [INVOICE_REDUCER_NAME]: invoicesSchema,
  [LOCATION_REDUCER_NAME]: locationsSchema,
  [LOCATION_TYPE_REDUCER_NAME]: locationTypesSchema,
  [NOTE_REDUCER_NAME]: notesSchema,
  [NOTE_CATEGORY_REDUCER_NAME]: noteCategoriesSchema,
  [PAYMENT_REDUCER_NAME]: paymentsSchema,
  [PAYMENT_LOG_REDUCER_NAME]: paymentLogsSchema,
  [PERIOD_REDUCER_NAME]: periodsSchema,
  [PERMISSION_REDUCER_NAME]: permissionsSchema,
  [PICKUP_ACTION_REDUCER_NAME]: pickupActionsSchema,
  [PRODUCT_CLASS_REDUCER_NAME]: productClassesSchema,
  [PRODUCT_TYPE_REDUCER_NAME]: productTypesSchema,
  [PROFIT_CENTER_REDUCER_NAME]: profitCentersSchema,
  [PURCHASE_ORDER_REDUCER_NAME]: purchaseOrdersSchema,
  [ROLE_REDUCER_NAME]: rolesSchema,
  [ROUTE_REDUCER_NAME]: routesSchema,
  [SALES_CALL_ACTION_REDUCER_NAME]: salesCallActionsSchema,
  [SALES_GOAL_REDUCER_NAME]: salesGoalsSchema,
  [SERVICE_REDUCER_NAME]: servicesSchema,
  [SERVICE_LEVEL_REDUCER_NAME]: serviceLevelsSchema,
  [SERVICE_PERIOD_REDUCER_NAME]: servicePeriodsSchema,
  [SERVICE_PRICE_REDUCER_NAME]: servicePricesSchema,
  [SERVICE_SCHEDULE_REDUCER_NAME]: serviceSchedulesSchema,
  [SERVICE_VISIT_REDUCER_NAME]: serviceVisitsSchema,
  [SETTING_REDUCER_NAME]: settingsSchema,
  [SHIPMENT_ACTION_REDUCER_NAME]: shipmentActionsSchema,
  [SPOT_PLANT_CALL_REDUCER_NAME]: spotPlantCallsSchema,
  [SPRAY_MIX_CONTAINER_REDUCER_NAME]: sprayMixContainersSchema,
  [SPRAY_MIX_RECIPE_REDUCER_NAME]: sprayMixRecipesSchema,
  [STATEMENT_REDUCER_NAME]: statementsSchema,
  [TAX_CODE_REDUCER_NAME]: taxCodesSchema,
  [TRANSFER_REDUCER_NAME]: transfersSchema,
  [VENDOR_REDUCER_NAME]: vendorsSchema,
  [USER_REDUCER_NAME]: usersSchema,
  [WORK_ORDER_REDUCER_NAME]: workOrdersSchema
};

/**
 * Store the normalized entities in the redux state
 * @param entities
 * @returns {Function}
 */
export const upsertNormalizedEntities = entities => {
  return dispatch => {
    schemaNames.forEach(schemaName => {
      if (!isEmpty(entities[schemaName])) {
        switch (schemaName) {
          case APPOINTMENT_REDUCER_NAME:
            dispatch(appointmentObjectActions.upsert(entities[schemaName]));
            break;
          case BED_ACTION_REDUCER_NAME:
            dispatch(bedActionObjectActions.upsert(entities[schemaName]));
            break;
          case BED_INVOICE_REDUCER_NAME:
            dispatch(bedInvoiceObjectActions.upsert(entities[schemaName]));
            break;
          case BED_PACKET_REDUCER_NAME:
            dispatch(bedPacketObjectActions.upsert(entities[schemaName]));
            break;
          case BIG_COMMERCE_REDUCER_NAME:
            dispatch(bigCommerceObjectActions.upsert(entities[schemaName]));
            break;
          case CYCLE_REDUCER_NAME:
            dispatch(cycleObjectActions.upsert(entities[schemaName]));
            break;
          case CHEMICAL_REDUCER_NAME:
            dispatch(chemicalObjectActions.upsert(entities[schemaName]));
            break;
          case CUSTOMER_REDUCER_NAME:
            dispatch(customerObjectActions.upsert(entities[schemaName]));
            break;
          case DELIVERY_ACTION_REDUCER_NAME:
            dispatch(deliveryActionObjectActions.upsert(entities[schemaName]));
            break;
          case DOC_REDUCER_NAME:
            dispatch(docObjectActions.upsert(entities[schemaName]));
            break;
          case ESTIMATE_REDUCER_NAME:
            dispatch(estimateObjectActions.upsert(entities[schemaName]));
            break;
          case GOOD_REDUCER_NAME:
            dispatch(goodObjectActions.upsert(entities[schemaName]));
            break;
          case GOOD_TYPE_REDUCER_NAME:
            dispatch(goodTypeObjectActions.upsert(entities[schemaName]));
            break;
          case GOOD_SERVICE_REDUCER_NAME:
            dispatch(goodServiceObjectActions.upsert(entities[schemaName]));
            break;
          case GOOD_VENDOR_REDUCER_NAME:
            dispatch(goodVendorObjectActions.upsert(entities[schemaName]));
            break;
          case GROUP_REDUCER_NAME:
            dispatch(groupObjectActions.upsert(entities[schemaName]));
            break;
          case INFO_CALL_ACTION_REDUCER_NAME:
            dispatch(infoCallActionObjectActions.upsert(entities[schemaName]));
            break;
          case INVENTORY_ACTIVITY_REDUCER_NAME:
            dispatch(inventoryActivityActions.upsert(entities[schemaName]));
            break;
          case INVENTORY_LOT_REDUCER_NAME:
            dispatch(inventoryLotActions.upsert(entities[schemaName]));
            break;
          case INVOICE_REDUCER_NAME:
            dispatch(invoiceObjectActions.upsert(entities[schemaName]));
            break;
          case LOCATION_REDUCER_NAME:
            dispatch(locationObjectActions.upsert(entities[schemaName]));
            break;
          case LOCATION_TYPE_REDUCER_NAME:
            dispatch(locationTypeObjectActions.upsert(entities[schemaName]));
            break;
          case NOTE_REDUCER_NAME:
            dispatch(noteObjectActions.upsert(entities[schemaName]));
            break;
          case NOTE_CATEGORY_REDUCER_NAME:
            dispatch(noteCategoryObjectActions.upsert(entities[schemaName]));
            break;
          case PAYMENT_REDUCER_NAME:
            dispatch(paymentObjectActions.upsert(entities[schemaName]));
            break;
          case PAYMENT_LOG_REDUCER_NAME:
            dispatch(paymentLogObjectActions.upsert(entities[schemaName]));
            break;
          case PERIOD_REDUCER_NAME:
            dispatch(periodObjectActions.upsert(entities[schemaName]));
            break;
          case PERMISSION_REDUCER_NAME:
            dispatch(permissionObjectActions.upsert(entities[schemaName]));
            break;
          case PICKUP_ACTION_REDUCER_NAME:
            dispatch(pickupActionObjectActions.upsert(entities[schemaName]));
            break;
          case PRODUCT_CLASS_REDUCER_NAME:
            dispatch(productClassObjectActions.upsert(entities[schemaName]));
            break;
          case PRODUCT_TYPE_REDUCER_NAME:
            dispatch(productTypeObjectActions.upsert(entities[schemaName]));
            break;
          case PROFIT_CENTER_REDUCER_NAME:
            dispatch(profitCenterObjectActions.upsert(entities[schemaName]));
            break;
          case PURCHASE_ORDER_REDUCER_NAME:
            dispatch(purchaseOrderObjectActions.upsert(entities[schemaName]));
            break;
          case ROLE_REDUCER_NAME:
            dispatch(roleObjectActions.upsert(entities[schemaName]));
            break;
          case ROUTE_REDUCER_NAME:
            dispatch(routeObjectActions.upsert(entities[schemaName]));
            break;
          case SALES_CALL_ACTION_REDUCER_NAME:
            dispatch(salesCallActionObjectActions.upsert(entities[schemaName]));
            break;
          case SALES_GOAL_REDUCER_NAME:
            dispatch(salesGoalObjectActions.upsert(entities[schemaName]));
            break;
          case SERVICE_REDUCER_NAME:
            dispatch(serviceObjectActions.upsert(entities[schemaName]));
            break;
          case SERVICE_LEVEL_REDUCER_NAME:
            dispatch(serviceLevelObjectActions.upsert(entities[schemaName]));
            break;
          case SERVICE_PERIOD_REDUCER_NAME:
            dispatch(servicePeriodObjectActions.upsert(entities[schemaName]));
            break;
          case SERVICE_PRICE_REDUCER_NAME:
            dispatch(servicePriceObjectActions.upsert(entities[schemaName]));
            break;
          case SERVICE_SCHEDULE_REDUCER_NAME:
            dispatch(serviceScheduleObjectActions.upsert(entities[schemaName]));
            break;
          case SERVICE_VISIT_REDUCER_NAME:
            dispatch(serviceVisitObjectActions.upsert(entities[schemaName]));
            break;
          case SETTING_REDUCER_NAME:
            dispatch(settingObjectActions.upsert(entities[schemaName]));
            break;
          case SHIPMENT_ACTION_REDUCER_NAME:
            dispatch(shipmentActionObjectActions.upsert(entities[schemaName]));
            break;
          case SPOT_PLANT_CALL_REDUCER_NAME:
            dispatch(spotPlantCallObjectActions.upsert(entities[schemaName]));
            break;
          case SPRAY_MIX_CONTAINER_REDUCER_NAME:
            dispatch(sprayMixContainerObjectActions.upsert(entities[schemaName]));
            break;
          case SPRAY_MIX_RECIPE_REDUCER_NAME:
            dispatch(sprayMixRecipeObjectActions.upsert(entities[schemaName]));
            break;
          case STATEMENT_REDUCER_NAME:
            dispatch(statementObjectActions.upsert(entities[schemaName]));
            break;
          case TAX_CODE_REDUCER_NAME:
            dispatch(taxCodeObjectActions.upsert(entities[schemaName]));
            break;
          case TRANSFER_REDUCER_NAME:
            dispatch(transferObjectActions.upsert(entities[schemaName]));
            break;
          case USER_REDUCER_NAME:
            dispatch(userObjectActions.upsert(entities[schemaName]));
            break;
          case VENDOR_REDUCER_NAME:
            dispatch(vendorObjectActions.upsert(entities[schemaName]));
            break;
          case WORK_ORDER_REDUCER_NAME:
            dispatch(workOrderObjectActions.upsert(entities[schemaName]));
            break;
          default:
            break;
        }
      }
    });
  };
};
