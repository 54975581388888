import React from 'react';

import {
  chunk,
  get,
  isEmpty,
} from 'lodash';
import {
  arrayOf,
  string,
} from 'prop-types';
import { Field } from 'react-final-form';
import { Grid } from 'semantic-ui-react';

import { formField } from '../../../helpers/shapes';
import { FormCheckboxGroupInput } from '../CheckboxGroupInput';

/**
 * A component used to render a group of checkboxes
 */
export const CheckboxGroup = ({
  options,
  name,
  labelPath = "",
  columns = 1,
  label = "",
}) => {
  if (isEmpty(options)) {
    return null;
  }
  const chunks = chunk(options, columns);
  return (
    <div className="checkbox-group">
      <Grid>
        <Grid.Row columns={columns} className={'pb-0'}>
          <Grid.Column>
            <label>{label}</label>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={columns} className={'pt-0'}>
          {chunks.map((chunk, index) => {
            return (
              <Grid.Column key={index}>
                {chunk.map(option => {
                  return (
                    <Field
                      key={option.id}
                      name={name}
                      option={option}
                      placeholder={option.text || get(option, labelPath, "")}
                      component={FormCheckboxGroupInput}
                    />
                  );
                })}
              </Grid.Column>
            );
          })}
        </Grid.Row>
      </Grid>
    </div>
  );
};

CheckboxGroup.propTypes = {
  labelPath: string,
  name: string,
  options: arrayOf(formField).isRequired
};
