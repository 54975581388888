import { isEmpty } from 'lodash';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';

import { objectsSchema } from '../../../helpers/normalizers';
import {
  makeObjectSelector,
  objectReducer,
} from '../selectors';
import { statementReducer } from '../statement/selectors';
import { allConstants as constants } from './constants';

export const customerReducer = () => state => {
  return state.getIn([constants.OBJECT_REDUCER_NAME, constants.REDUCER_NAME]);
};

export const customerSelector = () => {
  return makeObjectSelector(constants, customerReducer);
};

export const getCustomerFromStatementId = statementId => {
  return createSelector(
    objectReducer(),
    customerReducer(),
    statementReducer(),
    (objects, customers, statements) => {
      if (statementId) {
        const statement = statements.get(`${statementId}`);
        if (statement) {
          const customerId = statement.get("customer_id");
          if (customerId) {
            const denormalizedResults = denormalize(
              { [constants.REDUCER_NAME]: [customerId] },
              objectsSchema,
              objects.toJS()
            )[[constants.REDUCER_NAME]];

            if (!isEmpty(denormalizedResults)) {
              return denormalizedResults[0];
            }
          }
        }
      }

      return null;
    }
  );
};
