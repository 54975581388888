import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { AppSidebar } from "../App/Sidebar";
import { AppHeader } from "../App/Header";

const LoadingComponent = () => {
  return (
    <div className="view-app d-flex justify-flex-start">
      <AppSidebar />
      <div className="view-app-container">
        <AppHeader />
        <main className="view-app-main position-relative">
          <Dimmer active page>
            <Loader size='big' />
            <p>Initializing WRC</p>
          </Dimmer>
        </main>
      </div>
    </div>
  );
};

export const Loading = LoadingComponent;
