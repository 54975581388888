import React from "react";
import { any } from "prop-types";
import { Button, Card } from "semantic-ui-react";
import { CardTabs } from "../../CardTabs";
import { Graph } from "./Panes/Graph";
import { MonthToDate } from "./Panes/MonthToDate";
import { YearToDate } from "./Panes/YearToDate/index";
import { ROLE_COMMISSION_MGR } from "../../../../../constants";

export const SignedProposalsCard = ({
  graphData,
  monthToDateData,
  yearToDateData,
  openSetGoalsModal,
  user,
}) => {
  const [activeTab, setActiveTab] = React.useState("graph");
  return (
    <Card fluid>
      <Card.Content>
        <Card.Header className="card-tab-header">Signed Proposals</Card.Header>
        <CardTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={[
            {
              title: "Graph",
              key: "graph",
            },
            {
              title: "Month-to-date",
              key: "month-to-date",
            },
            {
              title: "Year-to-date",
              key: "year-to-date",
            },
          ]}
          tabPanes={[
            {
              key: "graph",
              content: <Graph data={graphData} />,
            },
            {
              key: "month-to-date",
              content: <MonthToDate data={monthToDateData} />,
            },
            {
              key: "year-to-date",
              content: <YearToDate data={yearToDateData} />,
            },
          ]}
        />
      </Card.Content>
      {user.hasRole(ROLE_COMMISSION_MGR) && (
        <Card.Content extra>
          <Button basic onClick={openSetGoalsModal}>Set Goals</Button>
        </Card.Content>
      )}
    </Card>
  );
};

SignedProposalsCard.propTypes = {
  graphData: any,
  monthToDateData: any,
  yearToDateData: any,
};
