import { schema } from 'normalizr';

import {
  REDUCER_NAME as WORK_ORDER_REDUCER_NAME,
} from '../../store/object/workOrder/constants';
import { invoiceSchema } from './invoice';
import { userSchema } from './user';

export const workOrderSchema = new schema.Entity(WORK_ORDER_REDUCER_NAME, {
  customer: userSchema,
  invoice: invoiceSchema
});

export const workOrdersSchema = [workOrderSchema];
