import { get } from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  infoCallActionSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import {
  actions as infoCallActionRequestActions,
} from '../../request/infoCallAction/actions';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';

const objectActions = makeObjectActions(constants);

/**
 * Retrieve a single object form the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = (id) => {
  return async (dispatch) => {
    dispatch(infoCallActionRequestActions.setLoadError(null));
    dispatch(infoCallActionRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.FETCH_URL.replace(":id", id),
        "data.infocall"
      );
      // Normalize the result and store the roles/users in redux
      const entities = get(
        normalize(result, infoCallActionSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
      dispatch(infoCallActionRequestActions.setIsLoaded(id, true));
    } catch (e) {
      captureException(e);
      dispatch(infoCallActionRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(infoCallActionRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async (dispatch) => {
    dispatch(infoCallActionRequestActions.setSaveError(null));
    dispatch(infoCallActionRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        data.id ? `${constants.UPSERT_URL}/${data.id}` : constants.UPSERT_URL,
        data,
        false
      );
      const entities = get(
        normalize(result.infocall, infoCallActionSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      dispatch(infoCallActionRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(infoCallActionRequestActions.setIsSaving(false));
    }
  };
};

export const actions = {
  ...objectActions,
  fetchObject,
  upsertObject,
};
