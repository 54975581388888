import { makeActionCreator } from '../../helpers/actions';

/**
 * Creates a set of actions used by most of the reducers
 * @param constants
 */
export const makeRequestActions = (constants) => {
  return {
    setIsLoading: makeActionCreator(
      constants.SET_IS_LOADING,
      "isLoading"
    ),
    setIsAuthorizeLoading: makeActionCreator(
      constants.SET_IS_AUTHORIZE_LOADING,
      "isAuthorizeLoading"
    ),
    setIsSaving: makeActionCreator(constants.SET_IS_SAVING, "isSaving"),
    setIsCalculating: makeActionCreator(
      constants.SET_IS_CALCULATING,
      "isCalculating"
    ),
    setIsLoadingAll: makeActionCreator(
      constants.SET_IS_LOADING_ALL,
      "isLoadingAll"
    ),
    setIsLoaded: makeActionCreator(
      constants.SET_IS_LOADED,
      "id",
      "isLoaded"
    ),
    setIsAuthorizeLoaded: makeActionCreator(
      constants.SET_IS_AUTHORIZE_LOADED,
      "isAuthorizeLoaded"
    ),
    setIsLoadedAll: makeActionCreator(
      constants.SET_IS_LOADED_ALL,
      "isLoadedAll"
    ),
    setAuthorizeLoadError: makeActionCreator(constants.SET_AUTHORIZE_LOAD_ERROR, "errorAuthorize"),
    setLoadError: makeActionCreator(constants.SET_LOAD_ERROR, "error"),
    setLoadAllError: makeActionCreator(constants.SET_LOAD_ALL_ERROR, "error"),
    setSaveError: makeActionCreator(constants.SET_SAVE_ERROR, "error"),
    setCalculateError: makeActionCreator(
      constants.SET_CALCULATE_ERROR,
      "error"
    ),
    setSmsError: makeActionCreator(constants.SET_SMS_ERROR, "error"),
    setMailError: makeActionCreator(constants.SET_MAIL_ERROR, "error"),    
    setIsQueueStatusLoaded: makeActionCreator(
      constants.SET_IS_QUEUE_STATUS_LOADED,
      "isQueueStatusLoaded"
    ),
    setIsQueueStatusLoading: makeActionCreator(
      constants.SET_IS_QUEUE_STATUS_LOADING,
      "isQueueStatusLoading"
    ),
  };
};
