import { fromJS } from "immutable";
import { INVENTORY_SALES_REPORT_TYPE_ALL } from "../../../constants";
import { initialReportState } from "../reducer";
import { PERIOD_ID, TYPE } from "./constants";

export const initialInventorySalesReportState = constants => {
  return fromJS({
    ...initialReportState(constants).toJS(),
    [PERIOD_ID]: null,
    [TYPE]: INVENTORY_SALES_REPORT_TYPE_ALL
  });
};

export const inventorySalesReportReducer = (state, action, constants) => {
  switch (action.type) {
    case constants.SET_PERIOD_ID:
      return state.set(PERIOD_ID, action.periodId);
    case constants.SET_TYPE:
      return state.set(TYPE, action.reportType);
    default:
      return state;
  }
};
