import { schema } from 'normalizr';

import {
  REDUCER_NAME as CUSTOMER_REDUCER_NAME,
} from '../../store/object/customer/constants';
import { notesSchema } from './note';
import { userSchema } from './user';

export const customerSchema = new schema.Entity(CUSTOMER_REDUCER_NAME, {
  csr: userSchema,
  notes: notesSchema,
});

export const customersSchema = [customerSchema];
