import { any } from "prop-types";
import React from "react";
import { find, get } from "lodash";
import { OPTIONS_WORK_ORDER_TYPE_ALL } from "../../../../constants";

export const WorkOrderTypeCell = React.memo(({ value }) => {
  const type = get(
    find(OPTIONS_WORK_ORDER_TYPE_ALL, type => {
      return type.id === value;
    }),
    "name"
  );
  return <span className="pl-1">{type}</span>;
});

WorkOrderTypeCell.propTypes = {
  value: any
};
