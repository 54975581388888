import React from "react";
import ReactMarkdown from "react-markdown";
import { Button, Modal } from "semantic-ui-react";
import { TabPanel } from "../../../../components/TabPanel";
import slugify from "slugify";

const changelog = require("../../../../CHANGELOG.md");

const ChangelogViewer = ({
  open,
  onClose,
  history,
  match,
  fetchApiChangelog,
}) => {
  const [markdown, setMarkdown] = React.useState();
  const [apiMarkdown, setApiMarkdown] = React.useState();
  const [markdownIsLoaded, setMarkdownIsLoaded] = React.useState();
  const [markdownIsLoading, setMarkdownIsLoading] = React.useState();
  const [apiMarkdownIsLoading, setApiMarkdownIsLoading] = React.useState();

  React.useEffect(() => {
    if(open){    
      // Set markdown for frontend changelog.
      if(!markdown && !markdownIsLoading){
        setMarkdownIsLoading(true);
        fetch(changelog)
          .then((response) => {
            return response.text();
          })
          .then((text) => {
            setMarkdown(text);
            setMarkdownIsLoading(false);
          });
      }    

      if (!apiMarkdown && !apiMarkdownIsLoading) {
        // Set markdown for backend changelog.
        setApiMarkdownIsLoading(true);
        fetchApiChangelog().then((file) => {
          const reader = new FileReader();
          reader.addEventListener("loadend", (e) => {
            const text = e.srcElement.result;
            setApiMarkdown(text);
            setMarkdownIsLoaded(true);
            setApiMarkdownIsLoading(false);
          });
          reader.readAsText(file);
        });
      }
    }
  }, [
    open,
    markdown,
    markdownIsLoaded,
    markdownIsLoading,
    apiMarkdown,
    apiMarkdownIsLoading,
    fetchApiChangelog,
    setMarkdown,
    setApiMarkdown,
    setMarkdownIsLoaded,
    setMarkdownIsLoading,
  ]);

  const panes = [
    {
      slug: slugify("changelog", { lower: true }),
      menuItem: "Frontend Changelog",
      render: () => {
        return <ReactMarkdown children={markdown} />;
      },
    },

    {
      slug: slugify("changelog-api", { lower: true }),
      menuItem: "API Changelog",
      render: () => {
        return <ReactMarkdown children={apiMarkdown} />;
      },
    },
  ];

  return (
    <Modal open={open} onClose={() => {
      onClose();
      setApiMarkdown(null);
    }}>
      <Modal.Header>
        <h2 className="mb-2">Release Notes</h2>
      </Modal.Header>
      <Modal.Content scrolling className="px-4 py-0">
        <TabPanel history={history} match={match} panes={panes} />
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={() => {
          onClose();
          setApiMarkdown(null);
        }}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ChangelogViewer;
