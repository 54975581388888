import React from "react";

import { withRouter } from "react-router-dom";
import { Button, Card, Grid, Icon } from "semantic-ui-react";

import { ErrorBoundaryComponent } from "../../../components";
import { object } from "prop-types";

class PageNotFoundComponent extends ErrorBoundaryComponent {
  render() {
    const { history } = this.props;
    return (
      <div className="view-login full-height">
        <div className="rose" />
        <Grid
          centered
          columns={1}
          verticalAlign="middle"
          className="full-height"
        >
          <Grid.Column>
            <Card className="hide-opacity centered middle big-shadow fade-in-and-scale text-center">
              <Card.Content className="p-8">
                <Icon
                  name="ban"
                  color="red"
                  className="mb-3 mx-auto"
                  size="big"
                />
                <Card.Header>404</Card.Header>
                <Card.Description>
                  Sorry, this page does not exist.
                  <div className="mt-2">
                    <Button
                      basic
                      size="mini"
                      color="red"
                      onClick={() => {
                        history.push("/");
                      }}
                    >
                      Go Back
                    </Button>
                  </div>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

PageNotFoundComponent.propTypes = {
  history: object,
};

export const PageNotFound = withRouter(PageNotFoundComponent);
