import { get } from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  paymentLogsSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import {
  actions as paymentLogRequestActions,
} from '../../request/paymentLog/actions';
import { sortAndLimitResults } from '../../view/actions';
import {
  actions as paymentLogViewActions,
} from '../../view/paymentLog/actions';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
} from '../actions';
import { allConstants as constants } from './constants';
import { paymentLogSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(paymentLogRequestActions.setLoadAllError(null));
    dispatch(paymentLogRequestActions.setIsLoadingAll(true));
    try {
      const paymentLogs = await fetchObjectsFromAPI(constants.FETCH_LIST_URL);

      // Normalize the result and store the paymentLogs in redux
      const entities = get(
        normalize(paymentLogs, paymentLogsSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(paymentLogs, tableState, (ids, count) => {
          dispatch(paymentLogViewActions.setList(ids, count));
        })
      );
      dispatch(paymentLogRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(paymentLogRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(paymentLogRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const paymentLogs = paymentLogSelector().getDenormalizedObjects()(
      getState()
    );
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(paymentLogs, tableState, (ids, count) => {
        dispatch(paymentLogViewActions.setList(ids, count));
      })
    );
  };
};

export const actions = {
  ...objectActions,
  fetchObjects,
  updateSortFilterLimit
};
