import { fromJS } from "immutable";
import * as constants from "./constants";

const initialRequestState = () => {
  return fromJS({
    [constants.IS_LOADED_ALL]: false,
    [constants.IS_LOADED]: {},
    [constants.IS_QUEUE_STATUS_LOADED]: false,
    [constants.IS_AUTHORIZE_LOADED]: false,
    [constants.IS_LOADING]: false,
    [constants.IS_QUEUE_STATUS_LOADING]: false,
    [constants.IS_AUTHORIZE_LOADING]: false,
    [constants.IS_LOADING_ALL]: false,
    [constants.LOAD_ALL_ERROR]: null,
    [constants.IS_SAVING]: false,
    [constants.IS_CALCULATING]: false,
    [constants.LOAD_ERROR]: null,
    [constants.LOAD_AUTHORIZE_ERROR]: null,
    [constants.SAVE_ERROR]: null,
    [constants.CALCULATE_ERROR]: null,
  });
};

/**
 * Create a generic request reducer for all of the requests in the app
 * @param constants
 * @param initialState
 * @returns {Function}
 */
export const makeRequestReducer = (
  constants,
  initialState = initialRequestState
) => {
  return (state = initialState(constants), action) => {
    switch (action.type) {
      case constants.SET_IS_LOADING_ALL:
        return state.set(constants.IS_LOADING_ALL, !!action.isLoadingAll);

      case constants.SET_IS_LOADED:
        return state.setIn([constants.IS_LOADED, action.id], !!action.isLoaded);

      case constants.SET_IS_LOADED_ALL:
        return state.set(constants.IS_LOADED_ALL, !!action.isLoadedAll);

      case constants.SET_IS_QUEUE_STATUS_LOADED:
        return state.set(constants.IS_QUEUE_STATUS_LOADED, !!action.isQueueStatusLoaded);

      case constants.SET_IS_QUEUE_STATUS_LOADING:
        return state.set(constants.IS_QUEUE_STATUS_LOADING, !!action.isQueueStatusLoading);

      case constants.SET_IS_AUTHORIZE_LOADED:
        return state.set(constants.IS_AUTHORIZE_LOADED, !!action.isAuthorizeLoaded);

      case constants.SET_IS_AUTHORIZE_LOADING:
        return state.set(constants.IS_AUTHORIZE_LOADING, !!action.isAuthorizeLoading);        

      case constants.SET_LOAD_ERROR:
        return state.set(constants.LOAD_ERROR, action.error);

      case constants.SET_LOAD_AUTHORIZE_ERROR:
        return state.set(constants.LOAD_AUTHORIZE_ERROR, action.errorAuthorize);

      case constants.SET_LOAD_ALL_ERROR:
        return state.set(constants.LOAD_ALL_ERROR, action.error);

      case constants.SET_IS_LOADING:
        return state.set(constants.IS_LOADING, !!action.isLoading);

      case constants.SET_IS_SAVING:
        return state.set(constants.IS_SAVING, !!action.isSaving);

      case constants.SET_IS_CALCULATING:
        return state.set(constants.IS_CALCULATING, !!action.isCalculating);

      case constants.SET_SAVE_ERROR:
        return state.set(constants.SAVE_ERROR, action.error);

      case constants.SET_CALCULATE_ERROR:
        return state.set(constants.CALCULATE_ERROR, action.error);

      case constants.SET_SMS_ERROR:
        return state.set(constants.SMS_ERROR, action.error);

      case constants.SET_MAIL_ERROR:
        return state.set(constants.MAIL_ERROR, action.error);

      case constants.RESET:
        return initialState(constants);

      default:
        return state;
    }
  };
};
