import React from "react";
import { array, bool } from "prop-types";
import { Divider } from "semantic-ui-react";
import cx from "classnames";

export const StatsRow = ({ sections = [], divided = false, simple = false }) => {
  return (
    <div className="stats-container">
      {sections.map((section, index) => {
        return (<div key={section.id}>
            <div className="stats-section d-flex">
              <div className="stats-rows">
                {section.rows.map((row) => {
                  return (
                    <div className={cx({
                      "stats-row": true,
                      "with-big-stat": !!section.bigStat,
                      simple: simple
                      })} key={row.id}>
                      <div className={cx({
                        'stats-cell': true,
                        simple: simple
                      })}>{row.title}</div>
                      <div className="stats-cell">
                        {row.value}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="big-stat">{section.bigStat}</div>
            </div>
            {((index + 1) !== sections.length) && divided ? <Divider /> : <div className='py-1'></div>}
            </div>
        );
      })}
    </div>
  );
};

StatsRow.propTypes = {
  sections: array,
  divided: bool,
  simple: bool,
};
