export const REPORT_REDUCER_NAME = "report";

// State Variables
export const IS_LOADED = "isLoaded";
export const IS_LOADING = "isLoading";
export const PREVIOUS_IS_LOADED = "previousIsLoaded";
export const PREVIOUS_IS_LOADING = "previousIsLoading";
export const LOAD_ERROR = "loadError";
export const RESULTS = "results";
export const PREVIOUS = "previous";
export const PAGE = "page";
export const DATA = "data";
export const TOTAL = "total";
