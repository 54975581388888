import { schema } from 'normalizr';

import {
  SALES_CALL_ACTION_REDUCER_NAME,
} from '../../store/object/salesCallAction';

export const salesCallActionSchema = new schema.Entity(
  SALES_CALL_ACTION_REDUCER_NAME
);

export const salesCallActionsSchema = [salesCallActionSchema];
