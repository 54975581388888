import { get, isArray } from "lodash";
import { array, bool, func, object, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Dimmer, Loader } from "semantic-ui-react";
import { PageAlert, PageHeader, ErrorBoundaryComponent } from "../../../../components";
import * as actions from "../../../../store/object/user/actions";
import * as groupActions from "../../../../store/object/group/actions";
import * as roleActions from "../../../../store/object/role/actions";
import * as permissionActions from "../../../../store/object/permission/actions";
import * as locationActions from "../../../../store/object/location/actions";
import * as routeActions from "../../../../store/object/route/actions";

import { locationSelector as locationObjectSelector } from "../../../../store/object/location/selectors";
import { routeSelector as routeObjectSelector } from "../../../../store/object/route/selectors";
import { groupSelector as groupObjectSelector } from "../../../../store/object/group/selectors";
import { roleSelector as roleObjectSelector } from "../../../../store/object/role/selectors";
import { permissionSelector as permissionObjectSelector } from "../../../../store/object/permission/selectors";
import { userSelector } from "../../../../store/object/user/selectors";
import { userRequestSelector } from "../../../../store/request/user/selectors";
import { groupRequestSelector } from "../../../../store/request/group/selectors";
import { roleRequestSelector } from "../../../../store/request/role/selectors";
import { permissionRequestSelector } from "../../../../store/request/permission/selectors";
import { locationRequestSelector } from "../../../../store/request/location/selectors";
import { routeRequestSelector } from "../../../../store/request/route/selectors";
import { UserDetailsForm } from "./Form";
import { WEB_ROUTE_USER_LIST } from "../../../../constants";
import { getUser } from "../../../../helpers/auth";
import { toast } from "react-toastify";

class UserDetailsComponent extends ErrorBoundaryComponent {
  state = {
    isDeleting: false
  }
  componentDidMount() {
    const {
      fetchObject,
      fetchGroups,
      fetchRoles,
      fetchPermissions,
      fetchLocations,
      fetchRoutes,
      isLoading,
      isLoadingGroups,
      isLoadedAllGroups,
      isLoadingRoles,
      isLoadedAllRoles,
      isLoadingPermissions,
      isLoadedAllPermissions,
      isLoadedAllLocations,
      isLoadingAllLocations,
      isLoadedAllRoutes,
      isLoadingAllRoutes,
    } = this.props;
    const userId = get(this.props, "match.params.id", null);

    // Load the object
    if (userId && !isLoading) {
      fetchObject(userId);
    }
    // Load groups, roles, permissions, locations, routes
    if (!isLoadingGroups && !isLoadedAllGroups) {
      fetchGroups();
    }
    if (!isLoadingRoles && !isLoadedAllRoles) {
      fetchRoles();
    }
    if (!isLoadingPermissions && !isLoadedAllPermissions) {
      fetchPermissions();
    }
    if (!isLoadedAllLocations && !isLoadingAllLocations) {
      fetchLocations();
    }
    if (!isLoadedAllRoutes && !isLoadingAllRoutes) {
      fetchRoutes();
    }
  }

  handleSubmit = (values) => {
    const { history, upsertObject } = this.props;
    let defaultLocation = {id: get(values, 'default_location.id')};
    const newUser = Object.prototype.hasOwnProperty.call(values, 'id') === false ? true : false;

    upsertObject({...values, default_location: defaultLocation}, (user) => {
      if(user && user.id && newUser) {
        toast.success("User created successfully");
        history.push(`/admin/users/edit/${user.id}`);
        return;
      }
      toast.success("User saved successfully");
    });
  };

  handleDelete = () => {
    const { history, destroyObject, user } = this.props;
    this.setState({isDeleting: true})
    destroyObject(user, () => {
      this.setState({isDeleting: false})
      history.push(WEB_ROUTE_USER_LIST);
    });
  };

  render() {
    const {
      isLoading,
      isLoadingGroups,
      isLoadingRoles,
      isLoadingPermissions,
      upsertProfilePhoto,
      isSaving,
      saveError,
      loadError,
      history,
      user,
      roles = [],
      groups = [],
      permissions = [],
      locations = [],
      routes = [],
    } = this.props;
    const { isDeleting } = this.state;
    const isAllLoading = isLoading;
    // If this user is a customer only.
    const allRoles = get(user, 'all_roles', []);
    const isNonStaff = isArray(allRoles) && allRoles.length === 1 && allRoles[0].identifier === 'ROLE_CUSTOMER';
    return (
      <Container
        className="view-user-details view-details view"
        as="article"
        fluid
      >
        <Dimmer active={isAllLoading || isSaving} inverted>
          <Loader size="large">{isLoading ? "Loading" : "Submitting"}</Loader>
        </Dimmer>
        <PageHeader title={isNonStaff ? "Customer User" : "User"} history={history} />
        <PageAlert title="Error" message={saveError} />
        <PageAlert title="Error" message={loadError} />
        {(!isAllLoading && !loadError && !isSaving) && (
          <UserDetailsForm
            isStaff={!isNonStaff}
            isDeleting={isDeleting}
            isSaving={isSaving}
            isLoading={isLoading}
            onSubmit={this.handleSubmit}
            onDelete={this.handleDelete}
            user={user}
            roles={roles}
            groups={groups}
            permissions={permissions}
            locations={locations}
            routes={routes}
            isLoadingGroups={isLoadingGroups}
            isLoadingRoles={isLoadingRoles}
            isLoadingPermissions={isLoadingPermissions}
            history={history}
            uploadProfilePhoto={upsertProfilePhoto}
            authUser={getUser()}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => {
  const requestSelector = userRequestSelector();
  const objectSelector = userSelector();

  const groupSelector = groupObjectSelector();
  const groupRequestSelectorHandle = groupRequestSelector();
  const roleSelector = roleObjectSelector();
  const roleRequestSelectorHandle = roleRequestSelector();
  const permissionSelector = permissionObjectSelector();
  const permissionRequestSelectorHandle = permissionRequestSelector();
  const locationSelector = locationObjectSelector();
  const locationRequestSelectorHandle = locationRequestSelector();
  const routeSelector = routeObjectSelector();
  const routeRequestSelectorHandle = routeRequestSelector();
  const userId = get(props, "match.params.id", null);
  return {
    isLoading: requestSelector.getIsLoading()(state),
    isSaving: requestSelector.getIsSaving()(state),
    saveError: requestSelector.getSaveError()(state),
    loadError: requestSelector.getLoadError()(state),
    isLoadingGroups: groupRequestSelectorHandle.getIsLoadingAll()(state),
    isLoadingRoles: roleRequestSelectorHandle.getIsLoadingAll()(state),
    isLoadingPermissions: permissionRequestSelectorHandle.getIsLoadingAll()(state),
    isLoadedAllGroups: groupRequestSelectorHandle.getIsLoadedAll()(state),
    isLoadedAllRoles: roleRequestSelectorHandle.getIsLoadedAll()(state),
    isLoadedAllPermissions: permissionRequestSelectorHandle.getIsLoadedAll()(
      state
    ),
    user: objectSelector.getDenormalizedObject(userId)(state),
    groups: groupSelector.getDenormalizedObjects()(state),
    roles: roleSelector.getDenormalizedObjects()(state),
    permissions: permissionSelector.getObjects()(state),
    locations: locationSelector.getDenormalizedObjects()(state),
    routes: routeSelector.getDenormalizedObjects()(state),
    isLoadedAllLocations: locationRequestSelectorHandle.getIsLoadedAll()(state),
    isLoadingAllLocations: locationRequestSelectorHandle.getIsLoadingAll()(state),
    isLoadedAllRoutes: routeRequestSelectorHandle.getIsLoadedAll()(state),
    isLoadingAllRoutes: routeRequestSelectorHandle.getIsLoadingAll()(state),
  };
};

UserDetailsComponent.displayName = "UserDetails";
UserDetailsComponent.propTypes = {
  fetchObject: func.isRequired,
  history: object,
  groups: array,
  isLoading: bool,
  isSaving: bool,
  roles: array,
  permissions: array,
  saveError: string,
  upsertObject: func.isRequired,
  user: object,
};

export const UserDetails = withRouter(
  connect(
    mapStateToProps,
    {
      ...actions,
      fetchGroups: groupActions.fetchObjects,
      fetchRoles: roleActions.fetchObjects,
      fetchPermissions: permissionActions.fetchObjects,
      fetchLocations: locationActions.fetchObjects,
      fetchRoutes: routeActions.fetchObjects,
    }
  )(UserDetailsComponent)
);
