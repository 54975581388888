import { required } from "../../../../../helpers/form";

export const fields = [
  {
    name: "name",
    label: "Name",
    size: "large",
    required: true,
    validate: required
  },
  {
    name: "identifier",
    label: "Identifier",
    size: "large",
    required: true,
    validate: required
  }
];
