import { REPORT_REDUCER_NAME } from "../../report/constants";
import * as reportConstants from "../constants";
import { makeReportConstants } from "../util";
export const REDUCER_NAME = "reorderPoints";
export const DATE = "date";

export const SET_DATE = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_DATE`;
export const FETCH_RESULTS_URL = `${process.env.REACT_APP_API_URL}admin/good/checkReorder`;

// Action Types
const defaultConstants = makeReportConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...reportConstants,
  DATE,
  REDUCER_NAME,
  FETCH_RESULTS_URL,
  SET_DATE
};
