import React, { useState } from "react";
import cx from "classnames";
import { func, string, bool, object, number, any } from "prop-types";
import { Select } from "semantic-ui-react";
import { TARGET_GALLONS } from "../../../constants/general";

/**
 * Spray Mix Container Select Component
 * @param {*} disabled
 * @param {*} id
 * @param {*} input
 * @param {*} label
 * @param {*} gallons
 * @param {*} placeholder
 * @param {*} required
 * @param {*} onChange 
 */
const SprayMixContainerSelectComponent = ({
  disabled,
  id,
  input,
  label,
  gallons,
  placeholder,
  required,
  onChange,
}) => {
  if (input && input.value) {
    gallons = input.value;
  }
  const [hasFocus, setHasFocus] = useState(false);
  const options = TARGET_GALLONS.map((value) => {
    return {
      key: value,
      value: value,
      text: `${value} gallons`,
    };
  });

  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true,
    "has-focus": hasFocus,
    "has-value": !!gallons,
  });
  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <Select
        upward={false}
        fluid
        disabled={disabled}
        size="huge"
        value={gallons}
        search
        name="units"
        placeholder={placeholder}
        selection
        options={options}
        onOpen={() => {
          setHasFocus(true);
        }}
        openOnFocus={false}
        onClose={() => {
          setHasFocus(false);
        }}
        onChange={(e, { value }) => {
          if (input && input.onChange) {
            input.onChange(value);
          }
          if(onChange) {
            onChange(value);
          }
        }}
        onBlur={(e, { value }) => {
          if (value && input && input.onChange) {
            input.onChange(value);
          }
          if(onChange) {
            onChange(value);
          }
        }}
      />
    </div>
  );
};

SprayMixContainerSelectComponent.propTypes = {
  onSelect: func,
  placeholder: string,
  disabled: bool,
  id: any,
  input: object,
  label: string,
  gallons: number,
  required: bool,
};

export const SprayMixContainerSelect = SprayMixContainerSelectComponent;
