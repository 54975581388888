import React from "react";
import { connect } from "react-redux";
import { Container } from "semantic-ui-react";
import { PageHeader, ErrorBoundaryComponent } from "../../../components";
import { DashboardQuickLinkSection } from "./QuickLinkSection";
import {
  business,
  quickLinks,
  reports,
  utilities
} from "./QuickLinkSection/links";

class DashboardComponent extends ErrorBoundaryComponent {
  render() {
    return (
      <Container className="view-dashboard view-list view" as="article" fluid>
        <PageHeader title="Admin Dashboard" />
        <section className="dashboard-quicklinks-container">
          <DashboardQuickLinkSection
            links={quickLinks}
            title="Quick Links"
            color="blue"
          />
          <DashboardQuickLinkSection
            links={reports}
            title="Reports"
            color="green"
          />
          <DashboardQuickLinkSection
            links={utilities}
            title="Utilities"
            color="yellow"
            additionalClasses="no-flex"
          />
          <DashboardQuickLinkSection
            links={business}
            title="Business"
            color="red"
          />
        </section>
      </Container>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
export const Dashboard = connect(mapStateToProps)(DashboardComponent);
