import React from "react";
import { get } from "lodash";
import { Modal } from "semantic-ui-react";
import { AccountCard } from "./AccountCard";
// import { AccountPagination } from "./AccountPagination";
import { object, func } from "prop-types";
import { bool } from "prop-types";

export const AccountSelectModal = ({
  accounts = [],
  activeAccount,
  user,
  isOpen,
  onClose,
  onSelect,
}) => {
  // Will paginate accounts, show max of 3 at a time [desktop]
  return (
    <Modal open={isOpen} closeIcon>
      <Modal.Content className="pb-8">
        <h2 className="text-center">{get(user, "name")}</h2>
        <p className="text-center py-2">
          Choose which Account Dashboard to view
        </p>

        <div className="d-flex justify-flex-center w-100">
          {accounts.map((account) => (
            <AccountCard
              key={get(account, "id")}
              account={account}
              active={get(activeAccount, "id") === get(account, "id")}
              onSelect={(account) => {
                // Set account to active.
                onSelect(account);
                // Close modal.
                onClose();
              }}
            />
          ))}
        </div>

        {/* <AccountPagination /> */}
      </Modal.Content>
    </Modal>
  );
};

AccountSelectModal.propTypes = {
  activeAccount: object,
  user: object,
  isOpen: bool,
  onClose: func,
  onSelect: func,
};

AccountSelectModal.defaultProps = {
  activeAccount: null,
  user: null,
};
