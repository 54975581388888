import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "vendor";
export const FETCH_URL = `${process.env.REACT_APP_API_URL}admin/vendor`;
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}admin/vendor`;
export const FETCH_NOTE_LIST_URL = `${process.env.REACT_APP_API_URL}admin/note/vendor`;
export const FETCH_ITEM_LIST_URL = `${process.env.REACT_APP_API_URL}admin/po/getVendorItems`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}admin/vendor`;
export const UPSERT_NOTE_URL = `${process.env.REACT_APP_API_URL}admin/note`;

export const REDUCER_NAME = "vendors";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_LIST_URL,
  FETCH_URL,
  FETCH_NOTE_LIST_URL,
  FETCH_ITEM_LIST_URL,
  UPSERT_URL,
  UPSERT_NOTE_URL,
  UPSERT_PROPERTY
};
