import React from "react";
import {
  Button,
  Card,
  Grid,
  Header,
  Icon,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import { ButtonGuard } from "../ButtonGuard";

export const DeleteConfirmCard = ({
  onDelete,
  onCancel,
  description,
  loading,
  guard,
}) => {
  return (
    <Grid stretched padded>
      <Grid.Row verticalAlign="middle" textAlign="center" columns={1}>
        <Grid.Column>
          <Card centered className="p-5 n-auto my-5 b-0">
            {loading ? (
              <>
                <Dimmer active inverted>
                  <Loader />
                </Dimmer>
              </>
            ) : (
              <>
                <div>
                  <Icon name="warning sign" size="huge" />
                </div>
                <Header as="h3">You are about to delete {description}</Header>
                <div className="my-3">Are you sure you want to do this?</div>
                <Button.Group vertical className="mb-4">
                  <ButtonGuard
                    className="mb-1"
                    color="red"
                    onClick={onDelete}
                    guard={guard}
                  >
                    Delete
                  </ButtonGuard>
                  <Button primary size="small" onClick={onCancel}>
                    Cancel
                  </Button>
                </Button.Group>
              </>
            )}
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

DeleteConfirmCard.defaultProps = {
  onCancel: () => {},
  description: "this item",
  key: "delete",
  guard: null,
};
