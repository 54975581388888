import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";

export const DashboardQuickLinkSection = React.memo(
  ({ color, links, title, additionalClasses = "" }) => {
    const className = cx(["dashboard-section", color, additionalClasses]);
    return (
      <article className={className}>
        <header>
          <h3 className="section-title">{title}</h3>
        </header>
        <ul className="dashboard-section-links h-100">
          {links.map((quickLink, index) => {
            return (
              <li key={index}>
                <Link
                  to={{
                    pathname: quickLink.link.route,
                    state: quickLink.link.state,
                    hash: quickLink.link.hash,
                  }}
                >
                  <span className="icon">
                    <img src={quickLink.icon} alt="" />
                  </span>
                  <span className="title">{quickLink.title}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </article>
    );
  }
);
