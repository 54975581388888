import React from "react";
import { Search } from "semantic-ui-react";
import { debounce } from "lodash";
import { fetchExtendedSearchResults } from "../../views/Admin/App/Header/requests";

export const ExtendedSearch = React.memo(
  ({ placeholder = "Search", options }) => {
    const [searchValue, setSearchValue] = React.useState();
    const [searchResults, setSearchResults] = React.useState([]);
    const [isSearching, setIsSearching] = React.useState();
    const [searchTimeout, setSearchTimeout] = React.useState();

    let doSearch = false;
    
    const debouncedSearch = debounce((value) => {
      if(!doSearch) return false;
      setSearchResults([]);
      fetchResults(value);
    }, 1600);

    const fetchResults = async (query) => {
      setIsSearching(true);
      doSearch = false;
      let response = fetchExtendedSearchResults(options.makeEndpoint(query))
      response.then( results => {
        setSearchResults( options.setSearchResults(results) );
        setIsSearching(false);
      })
    }

    const allowSearch = () => {
      doSearch = true;
    }

    return (
      <Search
        className='mr-5'
        loading={isSearching}
        disabled={isSearching}
        noResultsMessage={isSearching ? 'Searching...' : 'No results found.'}
        placeholder={placeholder}
        onResultSelect={options.onResultSelect}
        onSearchChange={(e, {value}) => {
          setSearchValue(value);
          doSearch = false;
          clearTimeout(searchTimeout);
          setSearchTimeout(setTimeout(allowSearch, 1500));
          debouncedSearch(value);
        }}
        results={searchResults}
        value={searchValue}
      />
    )
  }
);
