import { get } from 'lodash';
import { normalize } from 'normalizr';
import { toast } from 'react-toastify';

import { AUTO_PAY_TYPE } from '../../../constants';
import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  objectsSchema,
  periodSchema,
  periodsSchema,
  routesSchema,
  statementsSchema,
  invoicesSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import { actions as periodRequestActions } from '../../request/period/actions';
import { sortAndLimitResults } from '../../view/actions';
import { actions as periodViewActions } from '../../view/period/actions';
import { periodViewSelector } from '../../view/period/selectors';
import {
  fetchFileFromAPI,
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
  uploadFileToAPI,
} from '../actions';
import { STATEMENT_REDUCER_NAME } from '../statement';
import { INVOICE_REDUCER_NAME } from "../invoice";
import { allConstants as constants } from './constants';
import { periodSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(periodRequestActions.setLoadAllError(null));
    dispatch(periodRequestActions.setIsLoadingAll(true));
    try {
      const periods = await fetchObjectsFromAPI(
        constants.FETCH_LIST_URL,
        "data.periods"
      );

      // Normalize the result and store the periods in redux
      const entities = get(normalize(periods, periodsSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(periods, tableState, (ids, count) => {
          dispatch(periodViewActions.setList(ids, count));
        })
      );
      dispatch(periodRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(periodRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Load a list of objects
 */
 export const fetchFilesList = (periodId) => {
  return async dispatch => {
    try {
      dispatch(periodRequestActions.setIsLoadingAll(true));
      return await fetchObjectsFromAPI(
        constants.FETCH_FILES_LIST_URL.replace(':periodId', periodId),
        "data"
      );
    } catch (e) {
      captureException(e);
      dispatch(periodRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(periodRequestActions.setSaveError(null));
    dispatch(periodRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(constants.UPSERT_URL, data, false);
      const entities = get(
        normalize([result.period], periodsSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.period);
      }
    } catch (e) {
      captureException(e);
      dispatch(periodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = id => {
  return async dispatch => {
    dispatch(periodRequestActions.setLoadError(null));
    dispatch(periodRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        `${constants.FETCH_EDIT_URL}/${id}`,
        `data`
      );
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          period: periodSchema,
          routeList: routesSchema
        }),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(periodRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchViewObject = (id, tableState, onSuccess) => {
  return async dispatch => {
    dispatch(periodRequestActions.setLoadError(null));
    dispatch(periodRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        `${constants.FETCH_VIEW_URL}/${id}`,
        `data`
      );
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          period: periodSchema,
          statements: statementsSchema
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));

      // Store the full list of ids in the reducer
      dispatch(
        periodViewActions.setRelatedObjects(
          constants.RELATION_STATEMENTS,
          id,
          result.statements.map(statement => {
            return statement.id;
          })
        )
      );

      dispatch(updateStatementsSortFilterLimit(id, tableState));

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      captureException(e);
      dispatch(periodRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Fetch statements related to period from api.
 * @param id
 * @returns {Function}
 */
 export const fetchStatementViewObject = (id, tableState, onSuccess) => {
  return async dispatch => {
    dispatch(periodRequestActions.setLoadError(null));
    dispatch(periodRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        `${constants.FETCH_STATEMENT_VIEW_URL}/${id}`,
        `data.data`
      );
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          period: periodSchema,
          statements: statementsSchema
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));

      // Store the full list of ids in the reducer
      dispatch(
        periodViewActions.setRelatedObjects(
          constants.RELATION_STATEMENTS,
          id,
          result.statements.map(statement => {
            return statement.id;
          })
        )
      );

      dispatch(updateStatementsSortFilterLimit(id, tableState));

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      toast.error("Error fetching results.");
      captureException(e);
      dispatch(periodRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Fetch invoices related to period from the api
 * @param id
 * @returns {Function}
 */
 export const fetchInvoiceViewObject = (id, tableState, onSuccess) => {
  return async dispatch => {
    dispatch(periodRequestActions.setLoadError(null));
    dispatch(periodRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        `${constants.FETCH_INVOICE_VIEW_URL}/${id}`,
        `data.data`
      );
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          period: periodSchema,
          invoices: invoicesSchema
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));

      // Store the full list of ids in the reducer
      dispatch(
        periodViewActions.setRelatedObjects(
          constants.RELATION_INVOICES,
          id,
          result.invoices.map(invoice => {
            return invoice.id;
          })
        )
      );

      dispatch(updateInvoicesSortFilterLimit(id, tableState));

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      toast.error("Error fetching results.");
      captureException(e);
      dispatch(periodRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Fetch updated invoices related to period from the api
 * @param id
 * @returns {Function}
 */
 export const fetchPreviousInvoiceViewObject = (id, tableState, onSuccess) => {
  return async dispatch => {
    dispatch(periodRequestActions.setLoadError(null));
    dispatch(periodRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        `${constants.FETCH_INVOICE_PREVIOUS_VIEW_URL}/${id}`,
        `data.data`
      );
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          period: periodSchema,
          invoices: invoicesSchema
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));

      // Store the full list of ids in the reducer
      dispatch(
        periodViewActions.setRelatedObjects(
          constants.RELATION_INVOICES,
          id,
          result.invoices.map(invoice => {
            return invoice.id;
          })
        )
      );

      dispatch(updateInvoicesSortFilterLimit(id, tableState));

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      captureException(e);
      dispatch(periodRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the statements for the table
 * @param periodId
 * @param tableState
 * @returns {Function}
 */
export const updateStatementsSortFilterLimit = (periodId, tableState) => {
  return async (dispatch, getState) => {
    periodId = `${periodId}`;
    const state = getState();
    const statements = periodViewSelector().getRelatedObjects(
      STATEMENT_REDUCER_NAME,
      periodId
    )(state);

    // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(statements, tableState, (ids, count) => {
        dispatch(
          periodViewActions.setRelatedFilteredList(
            constants.RELATION_STATEMENTS,
            periodId,
            ids,
            count
          )
        );
      })
    );
  };
};

/**
 * Filter, Sort, and Trim the invoices for the table
 * @param periodId
 * @param tableState
 * @returns {Function}
 */
 export const updateInvoicesSortFilterLimit = (periodId, tableState) => {
  return async (dispatch, getState) => {
    periodId = `${periodId}`;
    const state = getState();
    const invoices = periodViewSelector().getRelatedObjects(
      INVOICE_REDUCER_NAME,
      periodId
    )(state);

    // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(invoices, tableState, (ids, count) => {
        dispatch(
          periodViewActions.setRelatedFilteredList(
            constants.RELATION_INVOICES,
            periodId,
            ids,
            count
          )
        );
      })
    );
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const periods = periodSelector().getDenormalizedObjects()(getState());
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(periods, tableState, (ids, count) => {
        dispatch(periodViewActions.setList(ids, count));
      })
    );
  };
};

export const runCCAuto = periodId => {
  return async dispatch => {
    dispatch(periodRequestActions.setSaveError(null));
    dispatch(periodRequestActions.setIsSaving(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.CC_AUTO_URL.replace(":periodId", periodId),
        "data"
      );
      const { period, num, total, statements, declined_transactions } = result;
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize({ ...period, num, total, statements, declined_transactions }, periodSchema),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(periodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsSaving(false));
    }
  };
};

export const runACHAuto = periodId => {
  return async dispatch => {
    dispatch(periodRequestActions.setSaveError(null));
    dispatch(periodRequestActions.setIsSaving(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.ACH_AUTO_URL.replace(":periodId", periodId),
        "data"
      );
      const { period, num, total, statements, declined_transactions } = result;
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize({ ...period, num, total, statements, declined_transactions }, periodSchema),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(periodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsSaving(false));
    }
  };
};

export const fetchPeriodTSVFile = (periodId, type = AUTO_PAY_TYPE.ccAuto) => {
  return async dispatch => {
    dispatch(periodRequestActions.setLoadError(null));
    dispatch(periodRequestActions.setIsLoading(true));
    try {
      await fetchFileFromAPI(
        constants.FETCH_TSV_FILE.replace(":periodId", periodId).replace(":type", type),
        `paymentfile-${periodId}-receipt.txt`
      );
    } catch (e) {
      captureException(e);
      dispatch(periodRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsLoading(false));
    }
  };
};

export const fetchPeriodFile = (periodId, folder, filename) => {
  return async dispatch => {
    dispatch(periodRequestActions.setLoadError(null));
    dispatch(periodRequestActions.setIsLoading(true));
    try {
      await fetchFileFromAPI(
        constants.FETCH_PERIOD_FILE.replace(":periodId", periodId).replace(":folder", (folder || "null")).replace(":filename", filename),
        filename
      );
    } catch (e) {
      captureException(e);
      dispatch(periodRequestActions.setLoadError(getErrorMessage(e)));
      toast.error("Error. File not found.");
    } finally {
      dispatch(periodRequestActions.setIsLoading(false));
    }
  };
};

export const fetchRetallyLedger = (periodId) => {
  return async dispatch => {
    dispatch(periodRequestActions.setLoadError(null));
    dispatch(periodRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.RETALLY_LEDGER_URL.replace(":periodId", periodId),
        "data.data"
      );
      return result;
    } catch (e) {
      captureException(e);
      dispatch(periodRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsLoading(false));
    }
  };
};

export const postPayment = data => {
  return async dispatch => {
    dispatch(periodRequestActions.setSaveError(null));
    dispatch(periodRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(constants.POST_PAYMENT_URL, { data });
      toast.success("Payment posted successfully!");
    } catch (e) {
      captureException(e);
      dispatch(periodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsSaving(false));
    }
  };
};

export const postBulkPayment = data => {
  return async dispatch => {
    dispatch(periodRequestActions.setSaveError(null));
    dispatch(periodRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(constants.POST_BULK_PAYMENT_URL, { data });
      toast.success("Payments posted successfully!");
    } catch (e) {
      captureException(e);
      dispatch(periodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsSaving(false));
    }
  };
};

export const postBulkAchPayment = data => {
  return async dispatch => {
    dispatch(periodRequestActions.setSaveError(null));
    dispatch(periodRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(constants.POST_ACH_BULK_PAYMENT_URL, { data });
      toast.success("Payments posted successfully!");
    } catch (e) {
      captureException(e);
      dispatch(periodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsSaving(false));
    }
  };
};

export const initPeriod = periodId => {
  return async dispatch => {
    dispatch(periodRequestActions.setSaveError(null));
    dispatch(periodRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.INIT_PERIOD_URL.replace(":periodId", periodId),
        {}
      );
      toast.success("Period initialization process started successfully!");
    } catch (e) {
      captureException(e);
      dispatch(periodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsSaving(false));
    }
  };
};

export const retallyPeriod = periodId => {
  return async dispatch => {
    dispatch(periodRequestActions.setSaveError(null));
    dispatch(periodRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.RETALLY_PERIOD_URL.replace(":periodId", periodId),
        {}
      );
      toast.success(
        "Retallying Period - you will receive an email when it is finished"
      );
    } catch (e) {
      captureException(e);
      dispatch(periodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsSaving(false));
    }
  };
};

export const lockPeriod = periodId => {
  return async dispatch => {
    dispatch(periodRequestActions.setSaveError(null));
    dispatch(periodRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.LOCK_PERIOD_URL.replace(":periodId", periodId),
        {}
      );
      toast.success("Locked statements in period");
    } catch (e) {
      captureException(e);
      dispatch(periodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsSaving(false));
    }
  };
};

export const updateRoutes = periodId => {
  return async dispatch => {
    dispatch(periodRequestActions.setSaveError(null));
    dispatch(periodRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.LOCK_ROUTE_URL.replace(":periodId", periodId),
        {}
      );
      toast.success("Locked customer routes to statements");
    } catch (e) {
      captureException(e);
      dispatch(periodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsSaving(false));
    }
  };
};

export const addLateFees = periodId => {
  return async dispatch => {
    dispatch(periodRequestActions.setSaveError(null));
    dispatch(periodRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.LATE_FEES_URL.replace(":periodId", periodId),
        {}
      );
      toast.success(
        "Updating Late Fees - you will receive an email when it is finished"
      );
    } catch (e) {
      captureException(e);
      dispatch(periodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsSaving(false));
    }
  };
};

export const generatePDFs = (periodId, num) => {
  return async dispatch => {
    dispatch(periodRequestActions.setSaveError(null));
    dispatch(periodRequestActions.setIsSaving(true));
    try {
      await fetchObjectsFromAPI(
        constants.GENERATE_PDFS_URL.replace(":periodId", periodId).replace(
          ":num",
          num
        )
      );
      toast.success("Regenerating PDF statements");
    } catch (e) {
      captureException(e);
      dispatch(periodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsSaving(false));
    }
  };
};

export const mailStatements = (periodId, isTest, emailOverride, selectedRows = []) => {
  return async dispatch => {
    dispatch(periodRequestActions.setSaveError(null));
    dispatch(periodRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.MAIL_STATEMENTS_URL.replace(":periodId", periodId),
        {
          isTest: isTest,
          emailOverride: emailOverride,
          selectedRows: selectedRows,
        },
        false,
        true,
      )
    } catch (e) {
      captureException(e);
      dispatch(periodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Push multiple order ids to horizon queue for TaxPost
 * 
 * @param {*} ids 
 * @param {*} type 
 * @param {*} action 
 * @param {*} options 
 * @returns 
 */
export const runTaxQueue = (ids, type, action, options = {}) => {
  return async dispatch => {
    dispatch(periodRequestActions.setLoadError(null));
    dispatch(periodRequestActions.setIsLoading(true));
    try {
      const response = await upsertObjectToAPI(
        constants.QUEUE_TAXES_URL,
        { 
          ids: ids,
          type: type,
          action: action,
          options: options,
        },
        false,
        true
      );
      dispatch(periodRequestActions.setIsLoading(false));
      return response;
    } catch (e) {
      dispatch(periodRequestActions.setIsLoading(false));
      console.error(e);
      return e;
    }
  }
};

export const fetchQueueStatus = (periodId, tableState) => {
  return async (dispatch, getState) => {
    // dispatch(periodRequestActions.setSaveError(null));
    dispatch(periodRequestActions.setIsQueueStatusLoading(true));
    try {
      const response = await fetchObjectsFromAPI(
        constants.FETCH_QUEUE_STATUS_URL.replace(":periodId", periodId)
      );
      const statuses = response.queueStatus.statement_statuses;
      // Must get all statements for this period and update their statuses
      const state = getState();
      const statements = periodViewSelector().getRelatedObjects(
        STATEMENT_REDUCER_NAME,
        periodId
      )(state);

      statements.map(statement => {
        if(statuses[statement.id]){
          statement.statuses = statuses[statement.id];
        } else {
          statement.statuses = [];
        }
        return statement;
      })

      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize({statements: statements}, {
          ...objectsSchema,
          statements: statementsSchema
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));
      dispatch(
        sortAndLimitResults(statements, tableState, (ids, count) => {
          dispatch(
            periodViewActions.setRelatedFilteredList(
              constants.RELATION_STATEMENTS,
              periodId,
              ids,
              count
            )
          );
        })
      );

      return response.queueStatus;
    } catch (e) {
      captureException(e);
      // dispatch(periodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsQueueStatusLoaded(true));
      dispatch(periodRequestActions.setIsQueueStatusLoading(false));
    }
  };
};

export const uploadNewsletter = (periodId, file) => {
  return async (dispatch) => {
    dispatch(periodRequestActions.setSaveError(null));
    dispatch(periodRequestActions.setIsSaving(true));
    try {
      return await uploadFileToAPI(constants.UPLOAD_NEWSLETTER_URL.replace(':periodId', periodId), file, {
        type: "newsletter",
      }, "data", "file");
    } catch (e) {
      console.error(e);
      captureException(e);
      dispatch(periodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsSaving(false));
    }
  };
};

export const fetchNewsletter = (periodId) => {
  return async (dispatch) => {
    dispatch(periodRequestActions.setSaveError(null));
    dispatch(periodRequestActions.setIsSaving(true));
    try {
      await fetchFileFromAPI(
        constants.FETCH_NEWSLETTER_PDF.replace(':periodId', periodId),
        "WRC-Newsletter.pdf"
        );
    } catch (e) {
      console.error(e);
      captureException(e);
      dispatch(periodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsSaving(false));
    }
  };
};

export const destroyNewsletter = (periodId) => {
  return async (dispatch) => {
    dispatch(periodRequestActions.setSaveError(null));
    dispatch(periodRequestActions.setIsSaving(true));
    try {
      return await upsertObjectToAPI(
        constants.DELETE_NEWSLETTER_PDF.replace(':periodId', periodId),
        "newsletter.pdf",
        false,
        true
        );
    } catch (e) {
      console.error(e);
      captureException(e);
      dispatch(periodRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(periodRequestActions.setIsSaving(false));
    }
  };
};

export const actions = {
  ...objectActions,
  runACHAuto,
  runCCAuto,
  fetchPeriodTSVFile,
  fetchPeriodFile,
  fetchObjects,
  fetchViewObject,
  fetchFilesList,
  postPayment,
  postBulkPayment,
  postBulkAchPayment,
  updateSortFilterLimit,
  updateStatementsSortFilterLimit,
  upsertObject,
  retallyPeriod,
  initPeriod,
  lockPeriod,
  generatePDFs,
  updateRoutes,
  addLateFees,
  fetchQueueStatus,
  runTaxQueue,
  mailStatements,
  uploadNewsletter,
  fetchNewsletter,
  destroyNewsletter,
  fetchRetallyLedger,
};
