import React, {
  useEffect,
  useState,
} from 'react';

import cx from 'classnames';
import {
  arrayOf,
  bool,
  func,
  number,
  object,
  string,
} from 'prop-types';
import { connect } from 'react-redux';
import { Select } from 'semantic-ui-react';

import { createOptionsFromObjects } from '../../../helpers/form/util';
import {
  actions as locationObjectActions,
} from '../../../store/object/location/actions';
import { locationSelector } from '../../../store/object/location/selectors';
import {
  locationRequestSelector,
} from '../../../store/request/location/selectors';

const LocationSelectComponent = ({
  disabled,
  fetchLocations,
  id,
  input,
  isLoadedAll,
  isLoadingAll,
  loadAllError,
  label,
  locationId,
  locations,
  onSelectLocation,
  onSelectLocationId,
  placeholder,
  required,
  error = false,
  onChange,
  serviceLocationsOnly = false,
}) => {
  if (input && input.value) {
    locationId = input.value;
  }
  useEffect(() => {
    if (!isLoadedAll && !isLoadingAll && !loadAllError) {
      fetchLocations();
    }
  }, [isLoadedAll, isLoadingAll, fetchLocations, loadAllError]);

  const [hasFocus, setHasFocus] = useState(false);
  const locationOptions = createOptionsFromObjects(locations.filter(location => {
    if (serviceLocationsOnly) {
      return location.active.toUpperCase() === "Y" && Number(location.type) === 2
    }
    return location.active.toUpperCase() === "Y"
  }));
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true,
    "has-focus": hasFocus,
    "has-value": !!locationId
  });
  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <Select
        upward={false}
        fluid
        disabled={disabled}
        error={error}
        size="huge"
        value={locationId}
        search
        name="location"
        loading={isLoadingAll}
        placeholder={placeholder}
        selection
        options={locationOptions}
        onOpen={() => {
          setHasFocus(true);
        }}
        openOnFocus={false}
        onClose={() => {
          setHasFocus(false);
        }}
        onChange={(e, { value }) => {
          if (onSelectLocation) {
            onSelectLocation(locations.find(location => location.id === value));
          }
          if (onSelectLocationId) {
            onSelectLocationId(value);
          }
          if (input && input.onChange) {
            input.onChange(value);
          }
          if (onChange) {
            onChange(value)
          }
        }}
        onBlur={(e, { value }) => {
          if (value && input && input.onChange) {
            input.onChange(value);
          }
        }}
      />
    </div>
  );
};

LocationSelectComponent.propTypes = {
  fetchLocations: func.isRequired,
  isLoadedAll: bool,
  isLoadingAll: bool,
  loadAllError: string,
  locationId: number,
  onSelectLocation: func,
  onSelectLocationId: func,
  placeholder: string,
  locations: arrayOf(object),
  serviceLocationsOnly: bool,
};

const mapStateToProps = (state, props) => {
  const locationObjectSelector = locationSelector();
  const requestSelector = locationRequestSelector();
  return {
    isLoadingAll: requestSelector.getIsLoadingAll()(state),
    isLoadedAll: requestSelector.getIsLoadedAll()(state),
    loadAllError: requestSelector.getLoadAllError()(state),
    locations: locationObjectSelector.getDenormalizedObjects()(state)
  };
};

export const LocationSelect = connect(
  mapStateToProps,
  {
    fetchLocations: locationObjectActions.fetchObjects
  }
)(LocationSelectComponent);
