import React from 'react';

import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';

import { PORTAL_MENU } from '../../../../constants/menu';
import { PortalMenuItem } from '../MenuItem';

const PortalMenuComponent = ({ location }) => {
  return (
    <ul className="portal-menu">
      {PORTAL_MENU.map((item) => {
        return (
          <PortalMenuItem item={item} location={location} key={item.key} />
        );
      })}
    </ul>
  );
};

PortalMenuComponent.propTypes = {
  location: object.isRequired,
};

export const PortalMenu = withRouter(PortalMenuComponent);
