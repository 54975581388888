import { get } from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  bigCommerceSchema,
  bigCommercesSchema,
  objectsSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import {
  actions as bigCommerceRequestActions,
} from '../../request/bigCommerce/actions';
import { sortAndLimitResults } from '../../view/actions';
import {
  actions as bigCommerceViewActions,
} from '../../view/bigCommerce/actions';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';

const objectActions = makeObjectActions(constants);

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = (id) => {
  return async (dispatch) => {
    dispatch(bigCommerceRequestActions.setLoadError(null));
    dispatch(bigCommerceRequestActions.setIsLoaded(id, false));
    dispatch(bigCommerceRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.FETCH_BIG_COMMERCE_ORDER_URL.replace(":bigCommerceId", id),
        "data"
      );
      let orderWithParsed = result.order;
      orderWithParsed.parsed = result.parsed;
      result.order = orderWithParsed;
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          order: bigCommerceSchema,
        }),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
      dispatch(bigCommerceRequestActions.setIsLoaded(id, true));
    } catch (e) {
      captureException(e);
      dispatch(bigCommerceRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(bigCommerceRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Load a list of objects
 */
export const fetchObjects = (startDate, tableState) => {
  return async (dispatch) => {
    dispatch(bigCommerceRequestActions.setLoadAllError(null));
    dispatch(bigCommerceRequestActions.setIsLoadingAll(true));
    try {
      const bigCommerce = await fetchObjectsFromAPI(
        constants.FETCH_BIG_COMMERCE_LIST_URL.replace(":startDate", startDate),
        "data.orders"
      );
      // Normalize the result and store the bigCommerces in redux
      const entities = get(
        normalize(bigCommerce, bigCommercesSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
      tableState.limit = bigCommerce.length;
      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(bigCommerce, tableState, (ids, count) => {
          dispatch(bigCommerceViewActions.setList(ids, count));
        })
      );
      dispatch(bigCommerceRequestActions.setIsLoadedAll(true));
    } catch (e) {
      console.error(e);
      //captureException(e);
      dispatch(bigCommerceRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(bigCommerceRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Import the BigCommerce
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertImport = (data, onSuccess = null, onError = null) => {
  return async (dispatch) => {
    dispatch(bigCommerceRequestActions.setSaveError(null));
    dispatch(bigCommerceRequestActions.setIsSaving(true));
    try {
      const url = constants.UPSERT_IMPORT_BIG_COMMERCE_ORDER_URL.replace(
        ":orderId",
        data.id
      );
      const result = await upsertObjectToAPI(url, data, false, true);
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      console.error(e);
      captureException(e);
      dispatch(bigCommerceRequestActions.setSaveError(getErrorMessage(e)));
      if (onError) {
        onError(e);
      }
    } finally {
      dispatch(bigCommerceRequestActions.setIsSaving(false));
    }
  };
};

export const actions = {
  ...objectActions,
  fetchObject,
  fetchObjects,
  upsertImport,
};
