import { any } from "prop-types";
import React from "react";

export const YesNoIntCell = React.memo(({ row, value }) => {
  return (
    <span className="yes-no-int-cell pl-1">
      {parseInt(value, 10) ? "Y" : "N"}
    </span>
  );
});

YesNoIntCell.propTypes = {
  value: any
};
