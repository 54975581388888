import { schema } from 'normalizr';

import {
  REDUCER_NAME as PAYMENT_REDUCER_NAME,
} from '../../store/object/payment/constants';
import { locationSchema } from './location';
import { userSchema } from './user';

export const paymentSchema = new schema.Entity(PAYMENT_REDUCER_NAME, {
  location: locationSchema,
  user: userSchema
});

export const paymentsSchema = [paymentSchema];
