import { schema } from 'normalizr';

import {
  REDUCER_NAME as SPRAY_MIX_CONTAINER_REDUCER_NAME,
} from '../../store/object/sprayMixContainer/constants';
import { notesSchema } from './note';

export const sprayMixContainerSchema = new schema.Entity(SPRAY_MIX_CONTAINER_REDUCER_NAME, {
  notes: notesSchema,
});

export const sprayMixContainersSchema = [sprayMixContainerSchema];
