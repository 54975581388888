import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "sprayMixContainer";
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}api/v2/spray-mix/container/list`;
export const FETCH_NOTE_LIST_URL = `${process.env.REACT_APP_API_URL}admin/note/spraymixcontainer`;
export const FETCH_URL = `${process.env.REACT_APP_API_URL}api/v2/spray-mix/container/:id`;
export const FETCH_LABELS_PDF_URL = `${process.env.REACT_APP_API_URL}api/v2/spray-mix/container/print`;
export const DUMP_OBJECT_URL = `${process.env.REACT_APP_API_URL}api/v2/spray-mix/container/dump/:id`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}api/v2/spray-mix/container`;
export const UPSERT_NOTE_URL = `${process.env.REACT_APP_API_URL}admin/note`;
export const VALIDATE_OBJECT_URL = `${process.env.REACT_APP_API_URL}api/v2/spray-mix/container/validate`;
export const REDUCER_NAME = "sprayMixContainers";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_LIST_URL,
  FETCH_URL,
  FETCH_NOTE_LIST_URL,
  FETCH_LABELS_PDF_URL,
  DUMP_OBJECT_URL,
  UPSERT_URL,
  UPSERT_NOTE_URL,
  VALIDATE_OBJECT_URL,
  UPSERT_PROPERTY,
};
