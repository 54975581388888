import { any } from "prop-types";
import React, { useState } from "react";
import { get } from "lodash";
import { Input } from "semantic-ui-react";

export const InputCell = ({ row, value, rendererProps, tableProps }) => {
  const [currentValue, setCurrentValue] = useState(
    get(rendererProps, "formatter") ? rendererProps.formatter(value) : value
  );

  return (
    <span className="input-cell">
      <Input
        fluid
        type={get(rendererProps, "type") || "text"}
        value={currentValue}
        onChange={(event, { value }) => {
          setCurrentValue(value);
          tableProps.onChangeInput(
            row.id,
            get(rendererProps, "formatter")
              ? rendererProps.formatter(value)
              : value
          );
        }}
      />
    </span>
  );
};

InputCell.propTypes = {
  value: any
};
