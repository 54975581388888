import { schema } from 'normalizr';

import {
  REDUCER_NAME as INVENTORY_ACTIVITY_REDUCER_NAME,
} from '../../store/object/inventoryActivity/constants';
import { goodSchema } from './good';
import { productClassSchema } from './productClass';

export const inventoryActivitySchema = new schema.Entity(
  INVENTORY_ACTIVITY_REDUCER_NAME,
  {
    good: goodSchema,
    product_class: productClassSchema
  }
);

export const inventoryActivitiesSchema = [inventoryActivitySchema];
