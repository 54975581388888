import { fromJS } from 'immutable';
import { get } from 'lodash';

import { getUser } from '../../helpers/auth';
import {
  CONFIRMATION_MODAL_IS_VISIBLE,
  CONFIRMATION_MODAL_TEXT,
  CONFIRMATION_MODAL_YES_CLICKED,
  CURRENT_LOCATION_ID,
  CURRENT_LOCATION_IS_LOCKED,
  CURRENT_PORTAL_ACCOUNT_ID,
  LOCK_CURRENT_LOCATION,
  PORTAL_ACCOUNT_SELECT_MODAL_IS_VISIBLE,
  PORTAL_ACCOUNTS,
  RESET,
  SET_CONFIRMATION_MODAL_TEXT,
  SET_CURRENT_LOCATION_ID,
  SET_CURRENT_PORTAL_ACCOUNT_ID,
  SET_PORTAL_ACCOUNTS,
  TOGGLE_CONFIRMATION_MODAL_VISIBLE,
  TOGGLE_CONFIRMATION_MODAL_YES_CLICKED,
  TOGGLE_PORTAL_ACCOUNT_SELECT_MODAL,
  SIDEBAR_IS_OPEN,
  SET_SIDEBAR_IS_OPEN,
} from "./constants";

const initialState = () => {
  const user = getUser();
  const accounts = get(user, "accounts", []);
  return fromJS({
    [CURRENT_LOCATION_ID]: get(user, "defaultLocation.id", null) || null,
    [CURRENT_LOCATION_IS_LOCKED]: false,
    [CONFIRMATION_MODAL_TEXT]: {},
    [CONFIRMATION_MODAL_IS_VISIBLE]: false,
    [CONFIRMATION_MODAL_YES_CLICKED]: false,
    [CURRENT_PORTAL_ACCOUNT_ID]:
      accounts.length === 1 ? accounts[0].id : null,
    [PORTAL_ACCOUNT_SELECT_MODAL_IS_VISIBLE]: false,
    [SIDEBAR_IS_OPEN]: true,
  });
};

function appReducer(state = initialState(), action) {
  switch (action.type) {
    case TOGGLE_CONFIRMATION_MODAL_YES_CLICKED:
      return state.set(CONFIRMATION_MODAL_YES_CLICKED, action.isClicked);

    case TOGGLE_CONFIRMATION_MODAL_VISIBLE:
      return state.set(CONFIRMATION_MODAL_IS_VISIBLE, action.isVisible);

    case TOGGLE_PORTAL_ACCOUNT_SELECT_MODAL:
      return state.set(PORTAL_ACCOUNT_SELECT_MODAL_IS_VISIBLE, action.isAccountSelectVisible);

    case SET_CONFIRMATION_MODAL_TEXT:
      return state.set(CONFIRMATION_MODAL_TEXT, fromJS(action.text));

    case SET_CURRENT_LOCATION_ID:
      return state.set(CURRENT_LOCATION_ID, action.locationId);

    case SET_CURRENT_PORTAL_ACCOUNT_ID:
      return state.set(CURRENT_PORTAL_ACCOUNT_ID, action.accountId);

    case SET_PORTAL_ACCOUNTS:
      return state.set(PORTAL_ACCOUNTS, action.accounts);

    case LOCK_CURRENT_LOCATION:
      return state.set(CURRENT_LOCATION_IS_LOCKED, action.isLocked);

    case SET_SIDEBAR_IS_OPEN:
      return state.set(SIDEBAR_IS_OPEN, action.isOpen);

    case RESET:
      return initialState();

    default:
      return state;
  }
}

export default appReducer;
