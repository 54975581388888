import React from "react";
import { array, string } from "prop-types";
import { Card, Divider, Grid } from "semantic-ui-react";

export const PipelineFunnelCard = ({
  bigStats = [],
  totals = [],
  updatedAt,
}) => {
  return (
    <Card fluid>
      <Card.Content>
        <Card.Header className="card-tab-header">Pipeline Funnel</Card.Header>
        <Grid>
          <Grid.Row columns="equal">
            <Grid.Column>
              <p>Updated: { updatedAt }</p>
              <Divider />
              {totals.map((total) => {
                return (
                  <div
                    className="d-flex justify-space-between pb-3"
                    key={total.title}
                  >
                    <div>{total.title}</div>
                    <div>{total.total.toLocaleString()}</div>
                  </div>
                );
              })}
            </Grid.Column>
            <Grid.Column>
              <div style={{ marginTop: "-3rem" }}>
                {bigStats.map((bigStat) => {
                  return (
                    <Card fluid key={bigStat.title}>
                      <Card.Content>
                        {bigStat.title}{" "}
                        <div className="big-stat pull-right sm">
                          {bigStat.total}
                        </div>
                      </Card.Content>
                    </Card>
                  );
                })}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Content>
    </Card>
  );
};

PipelineFunnelCard.propTypes = {
  bigStats: array,
  totals: array,
  updatedAt: string,
};
