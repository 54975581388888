import { get } from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  goodVendorSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import { goodVendorsSchema } from '../../../helpers/normalizers/goodVendor';
import {
  actions as goodVendorRequestActions,
} from '../../request/goodVendor/actions';
import { sortAndLimitResults } from '../../view/actions';
import {
  actions as goodVendorViewActions,
} from '../../view/goodVendor/actions';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
} from '../actions';
import { allConstants as constants } from './constants';
import { goodVendorObjectActions } from './index';
import { goodVendorSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(goodVendorObjectActions.reset());
    dispatch(goodVendorRequestActions.setLoadAllError(null));
    dispatch(goodVendorRequestActions.setIsLoadingAll(true));
    const vendorId = get(tableState, "filter.vendorId");
    try {
      const goodVendors = await fetchObjectsFromAPI(
        `${constants.FETCH_LIST_URL}/${vendorId}`
      );

      // Normalize the result and store the goodVendors in redux
      const entities = get(
        normalize(goodVendors, goodVendorsSchema),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(goodVendors, tableState, (ids, count) => {
          dispatch(goodVendorViewActions.setList(ids, count));
        })
      );
      dispatch(goodVendorRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(goodVendorRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(goodVendorRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Load a list of objects
 */
 export const fetchList = tableState => {
  return async dispatch => {
    dispatch(goodVendorObjectActions.reset());
    dispatch(goodVendorRequestActions.setLoadAllError(null));
    dispatch(goodVendorRequestActions.setIsLoadingAll(true));
    try {
      const goodVendors = await fetchObjectsFromAPI(
        `${constants.FETCH_GOOD_VENDOR_LIST_URL}`, 'data.gv'
      );

      // Normalize the result and store the goodVendors in redux
      const entities = get(
        normalize(goodVendors, goodVendorsSchema),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(goodVendors, tableState, (ids, count) => {
          dispatch(goodVendorViewActions.setList(ids, count));
        })
      );
      dispatch(goodVendorRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(goodVendorRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(goodVendorRequestActions.setIsLoadingAll(false));
    }
  };
};

export const getPriceForVendorGood = item => {
  return async dispatch => {
    dispatch(goodVendorRequestActions.setLoadError(null));
    dispatch(goodVendorRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        `${constants.FETCH_GOOD_VENDOR_PRICE_URL}/${item.id}/${item.good.id}`,
        "data"
      );

      const itemWithPrice = { ...item, price: parseFloat(result.price) };
      // Normalize the result and store the roles/users in redux
      const entities = get(
        normalize(itemWithPrice, goodVendorSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
      return itemWithPrice;
    } catch (e) {
      captureException(e);
      dispatch(goodVendorRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(goodVendorRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const goodVendors = goodVendorSelector().getDenormalizedObjects()(
      getState()
    );
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(goodVendors, tableState, (ids, count) => {
        dispatch(goodVendorViewActions.setList(ids, count));
      })
    );
  };
};

export const actions = {
  ...objectActions,
  getPriceForVendorGood,
  fetchObjects,
  fetchList,
  updateSortFilterLimit
};
