import { schema } from 'normalizr';

import {
  REDUCER_NAME as PERIOD_REDUCER_NAME,
} from '../../store/object/period/constants';
import { locationSchema } from './location';

export const periodSchema = new schema.Entity(PERIOD_REDUCER_NAME, {
  location: locationSchema
});

export const periodsSchema = [periodSchema];
