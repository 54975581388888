import { get } from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  objectsSchema,
  upsertNormalizedEntities,
  settingSchema,
  settingsSchema,
} from '../../../helpers/normalizers';
import { actions as settingRequestActions } from '../../request/setting/actions';
import { actions as viewSettingActions } from '../../view/setting/actions';
import { sortAndLimitResults } from '../../view/actions';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';


const objectActions = makeObjectActions(constants);

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = setting => {
  return async dispatch => {
    dispatch(settingRequestActions.setLoadError(null));
    dispatch(settingRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(constants.FETCH_URL.replace(':setting', setting));
      // Normalize the result and store the settings in redux.
      const entities = get(
        normalize(result, { ...objectsSchema, setting: settingSchema }),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(settingRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(settingRequestActions.setIsLoading(false));
    }
  };
};
/**
 * Load a list of purchase orders
 */
export const fetchObjects = (tableState) => {
  return async dispatch => {
    dispatch(settingRequestActions.setLoadAllError(null));
    dispatch(settingRequestActions.setIsLoadingAll(true));
    try {
      const settings = await fetchObjectsFromAPI(constants.FETCH_LIST_URL);

      // Normalize the result and store the settings in redux
      const entities = get(normalize(settings, settingsSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(settings, tableState, (ids, count) => {
          dispatch(viewSettingActions.setList(ids, count));
        })
      );
      dispatch(settingRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(settingRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(settingRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(settingRequestActions.setSaveError(null));
    dispatch(settingRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.UPSERT_URL,
        {settings: data}
      );
      const entities = get(
        normalize(result.data, settingsSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.data);
      }
    } catch (e) {
      captureException(e);
      dispatch(settingRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(settingRequestActions.setIsSaving(false));
    }
  };
};

export const actions = {
  ...objectActions,
  fetchObjects,
  fetchObject,
  upsertObject,
};
