import {
  get,
  isArray,
} from 'lodash';

const stackTrace = require("stack-trace");
const Coralogix = require("coralogix-logger");

/**
 * Replacement for captureException. 
 * Sends exception and stacktrace to Coralogix.
 * @param error
 * @returns {*}
 */
export const captureException = (error) => {
  const trace = stackTrace.get();
  const logger = new Coralogix.CoralogixLogger("App");
  const errorMessage = getErrorMessage(error);
  const componentStack = trace.join("\n\t")
  const text = `${errorMessage}\nStacktrace:\n\t${componentStack}`
  const log = new Coralogix.Log({
      severity:Coralogix.Severity.error,
      text: text,
  });
  // send log to coralogix
  logger.addLog(log);
}

/**
 * Try to pull a good message out of an exception
 * @param error
 * @returns {*}
 */
export const getErrorMessage = (error) => {
  const responseMessage = get(error, "response.data.message") ? get(error, "response.data.message") : get(error, "response.data.error");
  const errorArray = Object.values(get(error, "response.data.errors", {}));
  if (responseMessage) {
    if( isArray(responseMessage) ){
      return responseMessage.join("\n");
    }
    if( errorArray.length ){
      return errorArray.join("\n");
    }
    return responseMessage;
  }
  return get(error, "message", "Unknown error.");
};
