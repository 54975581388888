import { fromJS } from "immutable";
import { initialViewState } from "../reducer";
import { LOCATION_ID, PRODUCTION_CLASS_ID } from "./constants";

export const initialGoodViewState = constants => {
  return fromJS({
    ...initialViewState(constants).toJS(),
    [LOCATION_ID]: null,
    [PRODUCTION_CLASS_ID]: null
  });
};

export const goodReducer = (state, action, constants) => {
  switch (action.type) {
    case constants.SET_LOCATION_ID:
      return state.set(LOCATION_ID, action.id);
    case constants.SET_PRODUCTION_CLASS_ID:
      return state.set(PRODUCTION_CLASS_ID, action.id);
    default:
      return state;
  }
};
