import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

// Admin
export const UPSERT_PROPERTY = "invoice";
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}admin/invoice`;
export const INVOICE_EXPORT_URL = `${process.env.REACT_APP_API_URL}api/v2/export/invoice/excel`;
export const FETCH_URL = `${process.env.REACT_APP_API_URL}admin/invoice`;
export const FETCH_TAXES_URL = `${process.env.REACT_APP_API_URL}api/v2/invoice/getTaxes`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}admin/invoice`;
export const FETCH_INVOICE_MAIL_URL = `${process.env.REACT_APP_API_URL}admin/invoice/:invoiceId/mail`;
export const FETCH_INVOICE_RECEIPT_URL = `${process.env.REACT_APP_API_URL}admin/invoice/pdf/:invoiceId`;
export const FETCH_INVOICE_GIFT_URL = `${process.env.REACT_APP_API_URL}admin/invoice/:invoiceId/gift`;
export const FETCH_WORK_ORDER_LIST_URL = `${process.env.REACT_APP_API_URL}admin/invoice/workorders/:invoiceId`;
export const FETCH_NOTE_LIST_URL = `${process.env.REACT_APP_API_URL}admin/note/invoice`;
export const REVERSE_PAYMENT_URL = `${process.env.REACT_APP_API_URL}admin/invoice/payment/reverse`;
export const UPSERT_NOTE_URL = `${process.env.REACT_APP_API_URL}admin/note`;

// Portal
export const FETCH_PORTAL_INVOICE_RECEIPT_URL = `${process.env.REACT_APP_API_URL}api/portal/invoice/receipt/:invoiceId`;
export const FETCH_PORTAL_LIST_URL = `${process.env.REACT_APP_API_URL}api/portal/invoice/paginate`;


export const REDUCER_NAME = "invoices";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_LIST_URL,
  FETCH_INVOICE_MAIL_URL,
  FETCH_INVOICE_RECEIPT_URL,
  FETCH_INVOICE_GIFT_URL,
  FETCH_NOTE_LIST_URL,
  FETCH_TAXES_URL,
  FETCH_WORK_ORDER_LIST_URL,
  REVERSE_PAYMENT_URL,
  UPSERT_NOTE_URL,
  UPSERT_URL,
  FETCH_URL,
  FETCH_PORTAL_LIST_URL,
  FETCH_PORTAL_INVOICE_RECEIPT_URL,
  UPSERT_PROPERTY
};
