import React, {
  useEffect,
  useState,
} from 'react';

import cx from 'classnames';
import {
  arrayOf,
  bool,
  func,
  object,
  string,
} from 'prop-types';
import { connect } from 'react-redux';
import { Select } from 'semantic-ui-react';

import { createOptionsFromObjects } from '../../../helpers/form/util';
import {
  actions as servicePeriodObjectActions,
} from '../../../store/object/servicePeriod/actions';
import {
  servicePeriodSelector,
} from '../../../store/object/servicePeriod/selectors';
import {
  servicePeriodRequestSelector,
} from '../../../store/request/servicePeriod/selectors';

const ServicePeriodSelectComponent = ({
  disabled,
  fetchServicePeriods,
  id,
  input,
  isLoadedAll,
  isLoadingAll,
  loadAllError,
  label,
  placeholder,
  onSelectServicePeriod,
  servicePeriodId,
  servicePeriods,
  required,
  upward,
}) => {
  if (input && input.value) {
    servicePeriodId = input.value;
  }

  const [hasFocus, setHasFocus] = useState(false);
  useEffect(() => {
    if (!isLoadedAll && !isLoadingAll && !loadAllError) {
      fetchServicePeriods();
    }
  }, [isLoadedAll, isLoadingAll, fetchServicePeriods, loadAllError]);

  const servicePeriodOptions = createOptionsFromObjects(servicePeriods);
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-2": true,
    "d-block": true,
    "has-focus": hasFocus,
    "has-value": !!servicePeriodId
  });
  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <Select
        {...input}
        upward={upward}
        error={required && !servicePeriodId}
        loading={isLoadingAll}
        fluid
        disabled={disabled}
        size="huge"
        value={servicePeriodId}
        search
        name="servicePeriod"
        placeholder={placeholder}
        selection
        clearable
        options={servicePeriodOptions}
        onOpen={() => {
          setHasFocus(true);
        }}
        openOnFocus={false}
        onClose={() => {
          setHasFocus(false);
        }}
        onChange={(e, { value }) => {
          if (onSelectServicePeriod) {
            onSelectServicePeriod(value);
          }
          if (input && input.onChange) {
            input.onChange(value);
          }
        }}
        onBlur={(e, { value }) => {
          if (value && input && input.onChange) {
            input.onChange(value);
          }
        }}
      />
    </div>
  );
};

ServicePeriodSelectComponent.propTypes = {
  fetchServicePeriods: func.isRequired,
  isLoadedAll: bool,
  isLoadingAll: bool,
  loadAllError: string,
  onSelectServicePeriod: func,
  placeholder: string,
  servicePeriods: arrayOf(object)
};

const mapStateToProps = (state, props) => {
  const servicePeriodObjectSelector = servicePeriodSelector();
  const requestSelector = servicePeriodRequestSelector();
  return {
    isLoadingAll: requestSelector.getIsLoadingAll()(state),
    isLoadedAll: requestSelector.getIsLoadedAll()(state),
    loadAllError: requestSelector.getLoadAllError()(state),
    servicePeriods: servicePeriodObjectSelector.getDenormalizedObjects()(state)
  };
};

export const ServicePeriodSelect = connect(
  mapStateToProps,
  {
    fetchServicePeriods: servicePeriodObjectActions.fetchObjects
  }
)(ServicePeriodSelectComponent);
