import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "user";
export const FETCH_URL = `${process.env.REACT_APP_API_URL}admin/user`;
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}admin/user`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}admin/user`;
export const CHECK_PIN = `${process.env.REACT_APP_API_URL}admin/user/check-pin`;
export const FETCH_ORGANIZATION_LIST_URL = `${process.env.REACT_APP_API_URL}api/v2/user/organization`;

export const UPSERT_PROFILE_PHOTO_URL = `${process.env.REACT_APP_API_URL}api/v2/user/profile-photo`;

export const REDUCER_NAME = "users";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_URL,
  FETCH_LIST_URL,
  UPSERT_URL,
  UPSERT_PROPERTY,
  UPSERT_PROFILE_PHOTO_URL,
  CHECK_PIN,
  FETCH_ORGANIZATION_LIST_URL,
};
