import { request } from 'gaxios';
import { get, isArray } from 'lodash';

import { captureException } from '../../helpers/error';

import {
  SESSION_CURRENT_PORTAL_ACCOUNT,
  SESSION_REFRESH_TOKEN_NAME,
  SESSION_TOKEN_NAME,
  SESSION_USER_NAME,
} from '../../constants/session';
import { makeActionCreator } from '../../helpers/actions';
import {
  getToken,
  getUser as getUserOnSession,
  getCurrentAccount,
  setCurrentAccount as setCurrentAccountOnSession,
  setPortalAccounts as setPortalAccountsOnSession,
  setToken as setTokenOnSession,
  setUser as setUserOnSession,
  setApiVersion as setApiVersionOnSession,
  setApiEnvironment as setApiEnvironmentOnSession,
} from '../../helpers/auth';
import { getErrorMessage } from '../../helpers/error';
import { fetchObjects as fetchLocations } from '../object/location/actions';
import {
  RESET,
  SET_AUTHENTICATION_ERROR,
  SET_IS_AUTHENTICATING,
  SET_IS_REFRESHING,
  SET_IS_VERIFYING_TOKEN,
  SET_REFRESH_ERROR,
  SET_USER,
  SET_VERIFICATION_ERROR,
} from './constants';
import { setCurrentLocationId } from '../../store/app/actions';

export const reset = makeActionCreator(RESET);
export const setIsRefreshingSession = makeActionCreator(
  SET_IS_REFRESHING,
  "status"
);
export const setRefreshError = makeActionCreator(
  SET_REFRESH_ERROR, 
  "error");
export const setIsAuthenticating = makeActionCreator(
  SET_IS_AUTHENTICATING,
  "status"
);
export const setAuthenticationError = makeActionCreator(
  SET_AUTHENTICATION_ERROR,
  "error"
);
export const setIsVerifyingToken = makeActionCreator(
  SET_IS_VERIFYING_TOKEN,
  "status"
);
export const setVerificationError = makeActionCreator(
  SET_VERIFICATION_ERROR,
  "error"
);
export const setUser = makeActionCreator(SET_USER, "user");

/**
 * Refresh logged in user attributes.
 * @param onSuccess
 */
export const refreshUser = (onSuccess = null, onError = null) => {
  return async (dispatch) => {
    dispatch(setIsRefreshingSession(true));
    try {
      const response = await request({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}auth-user`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-authtoken": getToken(),
          "x-account": getCurrentAccount(),
          "x-version": process.env.REACT_APP_VERSION,
        },
      });
      const apiVersion = get(response, "data.api_version");
      const apiEnvironment = get(response, "data.api_environment");
      setApiVersionOnSession(apiVersion);
      setApiEnvironmentOnSession(apiEnvironment);
      if (get(response, "data.user")) {
        const user = get(response, "data.user");
        setUserOnSession(user);
        dispatch(setCurrentLocationId(get(user, "default_location.id")));
        dispatch(fetchLocations());
      }
      dispatch(setIsRefreshingSession(false));
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      console.error(e)
      if(onError){
        onError(e)
      }
    }
  };
};

/**
 * Login with a user's email/password
 * @param email
 * @param password
 * @param onSuccess
 */
export const loginWithEmailPassword = ({ email, password }, onSuccess) => {
  return async (dispatch) => {
    dispatch(setIsAuthenticating(true));
    try {
      const response = await request({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}login`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          email,
          password,
        },
      });

      const user = get(response, "data.user", {});
      const token = get(user, "api_token");
      setTokenOnSession(token);
      setUserOnSession(user);
      
      dispatch(setIsAuthenticating(false));      
      
      if (onSuccess) {
        onSuccess(getUserOnSession());
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      if (errorMessage) {
        if(errorMessage !== 'These credentials do not match our records.'){
          console.error(errorMessage);
          dispatch(setAuthenticationError('Server error.'));
        } else {
          dispatch(setAuthenticationError(errorMessage));
        }
        
      } else {
        dispatch(setAuthenticationError("Could not reach remote server."));
      }
      dispatch(setIsAuthenticating(false));
      captureException(e);
    }
  };
};

export const requestPasswordReset = (email, onSuccess) => {
  return async (dispatch) => {
    dispatch(setIsAuthenticating(true));
    dispatch(setAuthenticationError(null));
    try {
      const response = await request({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}password/forgot`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          email,
        },
      });

      const success = get(response, 'data.status') === 'success';
      if(success){
        if (onSuccess) {
          onSuccess(get(response, 'data'));
        }
      } else {
        dispatch(setAuthenticationError(get(response, 'data.message')));
      }
      dispatch(setIsAuthenticating(false));
      
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      if (errorMessage) {
        if(errorMessage !== 'These credentials do not match our records.'){
          console.error(errorMessage);
          dispatch(setAuthenticationError('Server error.'));
        } else {
          dispatch(setAuthenticationError(errorMessage));
        }
        
      } else {
        dispatch(setAuthenticationError("Could not reach remote server."));
      }
      dispatch(setIsAuthenticating(false));
      captureException(e);
    }
  };
};


export const resetPassword = (data, token, onSuccess) => {
  return async (dispatch) => {
    dispatch(setIsAuthenticating(true));
    dispatch(setAuthenticationError(null));
    try {
      data.token = token;
      const response = await request({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}password/reset`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...data,
        },
      });

      const success = get(response, 'data.status') === 'success';
      if(success){
        if (onSuccess) {
          onSuccess(get(response, 'data'));
        }
      } else {
        dispatch(setAuthenticationError(get(response, 'data.message')));
      }
      dispatch(setIsAuthenticating(false));
      
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      if (errorMessage) {
        if(errorMessage !== 'These credentials do not match our records.'){
          console.error(errorMessage);
          dispatch(setAuthenticationError('Server error.'));
        } else {
          dispatch(setAuthenticationError(errorMessage));
        }
        
      } else {
        dispatch(setAuthenticationError("Could not reach remote server."));
      }
      dispatch(setIsAuthenticating(false));
      captureException(e);
    }
  };
}

/**
 * Logout the auth user.
 */
export function logout() {
  return (dispatch) => {
    dispatch(reset());

    setTokenOnSession(null);
    setUserOnSession(null);
    localStorage.removeItem(SESSION_TOKEN_NAME);
    localStorage.removeItem(SESSION_REFRESH_TOKEN_NAME);
    localStorage.removeItem(SESSION_USER_NAME);
    localStorage.removeItem(SESSION_CURRENT_PORTAL_ACCOUNT);
  };
}

/**
 * Verify registration token is valid.
 * @param onSuccess
 */
export const verifyRegistrationToken = (
  data,
  onSuccess = null,
  onError = null
) => {
  return async (dispatch) => {
    dispatch(setIsVerifyingToken(true));
    try {
      const response = await request({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}api/portal/register/token/verify`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: data,
      });
      if (get(response, "data.status")) {
        if (onSuccess) {
          onSuccess();
        }
      } else {
        if (onError) {
          onError();
        }
      }
      dispatch(setIsVerifyingToken(false));
    } catch (e) {
      console.error("verifyRegistrationToken error", e);
      const errorMessage = getErrorMessage(e);
      console.error("error message", errorMessage)
      if (errorMessage) {
        dispatch(setVerificationError(errorMessage));
      } else {
        dispatch(setVerificationError("Could not reach remote server."));
      }
      dispatch(setIsVerifyingToken(false));
      captureException(e);
    }
  };
};


/**
 * Post customer user portal registration.
 * @param data
 * @param onSuccess
 */
export const postCustomerRegistration = (data, onSuccess = null) => {
  return async (dispatch) => {
    dispatch(setIsAuthenticating(true));
    try {
      const response = await request({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}api/portal/register`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: data,
      });

      const user = get(response, "data.user", {});
      const token = get(user, "api_token");
      setTokenOnSession(token);
      setUserOnSession(user);
      if(isArray(user.accounts)){
        setPortalAccountsOnSession( user.accounts );
        // If customer has only 1 acccount.
        if(user.accounts.length === 1){
          setCurrentAccountOnSession( user.accounts[0].account_number );
        }
      }
      dispatch(setIsAuthenticating(false));

      if (onSuccess) {
        onSuccess(getUserOnSession());
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      if (errorMessage) {
        dispatch(setAuthenticationError(errorMessage));
      } else {
        dispatch(setAuthenticationError("Could not reach remote server."));
      }
      dispatch(setIsAuthenticating(false));
      captureException(e);
    }
  };
};
