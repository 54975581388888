import { makeActionCreator } from '../../../helpers/actions';
import { makeViewActions } from '../actions';
import { allConstants as constants } from './constants';

const viewActions = makeViewActions(constants);

const setDateFilter = makeActionCreator(
  constants.SET_DATE_FILTER,
  "dateFilter"
);
const setDateFilteredList = makeActionCreator(
  constants.SET_DATE_FILTERED_LIST,
  "dateFilter",
  "data",
  "count"
);
const setDateFilteredObjects = makeActionCreator(
  constants.SET_DATE_FILTERED_OBJECTS,
  "dateFilter",
  "data"
);
export const actions = {
  ...viewActions,
  setDateFilter,
  setDateFilteredObjects,
  setDateFilteredList
};
