import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "estimate";
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}api/v2/estimates`;
export const FETCH_URL = `${process.env.REACT_APP_API_URL}api/v2/estimates`;
export const FETCH_V1_URL = `${process.env.REACT_APP_API_URL}admin/estimate`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}api/v2/estimates`;
export const UPSERT_V1_URL = `${process.env.REACT_APP_API_URL}admin/estimate`;
export const SEND_EMAIL_URL = `${process.env.REACT_APP_API_URL}admin/estimate/mail`;
export const SEND_NOTIFICATION_URL = `${process.env.REACT_APP_API_URL}admin/estimate/notify`;
export const SEND_SMS_URL = `${process.env.REACT_APP_API_URL}admin/estimate/text/:estimateId/:number`;
export const FETCH_NOTE_LIST_URL = `${process.env.REACT_APP_API_URL}admin/note/estimate`;
export const FETCH_DOC_LIST_URL = `${process.env.REACT_APP_API_URL}admin/doc/estimate/:estimateId`;
export const UPSERT_NOTE_URL = `${process.env.REACT_APP_API_URL}admin/note`;
export const UPSERT_DOC_URL = `${process.env.REACT_APP_API_URL}admin/doc/upload`;
export const DESTROY_DOC_URL = `${process.env.REACT_APP_API_URL}admin/doc/del/:docId`;
export const SIGN_URL = `${process.env.REACT_APP_API_URL}estimate/sign/:id/:hash`;
export const CLONE_URL = `${process.env.REACT_APP_API_URL}admin/estimate/clone/:id`;
export const FETCH_PDF_URL = `${process.env.REACT_APP_API_URL}admin/estimate/pdf/:estimateId`;
export const FETCH_SERVICE_PRICE_URL = `${process.env.REACT_APP_API_URL}admin/estimate/getServicePrice/:bushCount/:priceLevel/:serviceLevel/:servicePeriodId`;
export const DOWNLOAD_PDF_URL = `${process.env.REACT_APP_API_URL}admin/estimate/pdf/dl/:estimateId`;
export const MAKE_BED_PACKET_URL = `${process.env.REACT_APP_API_URL}api/v2/estimates/bed-packet/create`;
export const FETCH_ESTIMATE_DUMP_URL = `${process.env.REACT_APP_API_URL}api/v2/export/estimate/excel`;
export const REDUCER_NAME = "estimates";

// Portal
export const FETCH_PORTAL_ESTIMATE_PDF_URL = `${process.env.REACT_APP_API_URL}api/portal/estimate/pdf/:estimateId`;
export const FETCH_PORTAL_LIST_URL = `${process.env.REACT_APP_API_URL}api/portal/estimate/paginate`;

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_LIST_URL,
  FETCH_PORTAL_LIST_URL,
  FETCH_PORTAL_ESTIMATE_PDF_URL,
  FETCH_DOC_LIST_URL,
  FETCH_URL,
  FETCH_V1_URL,
  SEND_EMAIL_URL,
  UPSERT_URL,
  UPSERT_V1_URL,
  UPSERT_DOC_URL,
  DESTROY_DOC_URL,
  FETCH_NOTE_LIST_URL,
  SEND_SMS_URL,
  FETCH_PDF_URL,
  FETCH_SERVICE_PRICE_URL,
  DOWNLOAD_PDF_URL,
  SEND_NOTIFICATION_URL,
  UPSERT_NOTE_URL,
  SIGN_URL,
  CLONE_URL,
  UPSERT_PROPERTY,
  MAKE_BED_PACKET_URL,
  FETCH_ESTIMATE_DUMP_URL,
};
