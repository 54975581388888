import { fromJS } from "immutable";
import { initialReportState } from "../reducer";
import dayjs from "dayjs";

export const initialStartsStopsReportState = constants => {
  return fromJS({
    ...initialReportState(constants).toJS(),
    [constants.BREAKDOWN]: [],
    [constants.SUMMARY]: [],
    [constants.SCHEDULED_SUMMARY]: [],
    [constants.SUMMARY_START_DATE]: dayjs().startOf('month').format('MM/DD/YYYY'),
    [constants.SUMMARY_END_DATE]: dayjs().endOf('month').format('MM/DD/YYYY'),
    [constants.PROFIT_CENTER_SUMMARY_START_DATE]: dayjs().startOf('month').format('MM/DD/YYYY'),
    [constants.PROFIT_CENTER_SUMMARY_END_DATE]: dayjs().endOf('month').format('MM/DD/YYYY'),
  });
};

export const startsStopsReportReducer = (state, action, constants) => {
  switch (action.type) {
    case constants.SET_BREAKDOWN:
      return state.setIn([constants.BREAKDOWN], fromJS(action.data));
    case constants.SET_IS_LOADED_BREAKDOWN:
      return state.setIn([constants.IS_LOADED_BREAKDOWN], action.isLoaded);

    case constants.SET_SUMMARY:
      return state.setIn([constants.SUMMARY], fromJS(action.data));
    case constants.SET_IS_LOADED_SUMMARY:
      return state.setIn([constants.IS_LOADED_SUMMARY], action.isLoaded);
    case constants.SET_SCHEDULED_SUMMARY:
      return state.setIn([constants.SCHEDULED_SUMMARY], fromJS(action.data));
    case constants.SET_IS_LOADED_SCHEDULED_SUMMARY:
      return state.setIn([constants.IS_LOADED_SCHEDULED_SUMMARY], action.isLoaded);
    case constants.SET_SUMMARY_START_DATE:
      return state.setIn([constants.SUMMARY_START_DATE], action.data);
    case constants.SET_SUMMARY_END_DATE:
      return state.setIn([constants.SUMMARY_END_DATE], action.data);

    case constants.SET_PROFIT_CENTER_SUMMARY:
      return state.setIn([constants.PROFIT_CENTER_SUMMARY], fromJS(action.data));
    case constants.SET_IS_LOADED_PROFIT_CENTER_SUMMARY:
      return state.setIn([constants.IS_LOADED_PROFIT_CENTER_SUMMARY], action.isLoaded);
    case constants.SET_PROFIT_CENTER_SUMMARY_START_DATE:
      return state.setIn([constants.PROFIT_CENTER_SUMMARY_START_DATE], action.data);
    case constants.SET_PROFIT_CENTER_SUMMARY_END_DATE:
      return state.setIn([constants.PROFIT_CENTER_SUMMARY_END_DATE], action.data);
    default:
      return state;
  }
};
