import { schema } from 'normalizr';

import {
  REDUCER_NAME as APPOINTMENT_REDUCER_NAME,
} from '../../store/object/appointment/constants';
import { customerSchema } from './customer';
import { estimatesSchema } from './estimate';
import { userSchema } from './user';

export const appointmentSchema = new schema.Entity(APPOINTMENT_REDUCER_NAME, {
  customer: customerSchema,
  estimates: estimatesSchema,
  user: userSchema
});

export const appointmentsSchema = [appointmentSchema];
