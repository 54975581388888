import React, { useEffect, useState } from "react";
import { Accordion, Icon, List } from "semantic-ui-react";
import cx from "classnames";
import { Field } from "react-final-form";
import { array, bool, object } from "prop-types";
import { get, findIndex, isArray, sortBy, groupBy } from "lodash";

export const PermissionsList = ({ permissions, form, disabled, user }) => {
  const current = form.getFieldState("permissions")?.value
    ? form.getFieldState("permissions")?.value
    : [];
  const [isActive, setIsActive] = useState(
    current.map((permission) => {
      return permission.id;
    })
  );
  const [isGroupActive, setIsGroupActive] = useState([]);
  useEffect(() => {
    if (current.length !== isActive.length) {
      setIsActive(
        current.map((item) => {
          return item.id;
        })
      );
    }
  }, [isActive, current, setIsActive]);
  const groupedPermissions = groupBy(permissions, "group");
  return (
    <Accordion styled fluid className="my-2">
      {Object.keys(groupedPermissions).map((key) => {
        const isOpen = isGroupActive.indexOf(key) >= 0;
        return (
          <div key={key}>
            <Accordion.Title
              active={isOpen}
              index={0}
              onClick={() => {
                const newIsOpen = [...isGroupActive];
                if (!isOpen) {
                  newIsOpen.push(key);
                } else {
                  newIsOpen.splice(newIsOpen.indexOf(key), 1);
                }
                setIsGroupActive(newIsOpen);
              }}
            >
              <Icon name="dropdown" />
              {key}
              <span className="pull-right">
                {groupedPermissions[key].reduce(
                  (previousValue, currentValue, currentIndex) => {
                    return isActive.indexOf(currentValue.id) >= 0
                      ? previousValue + 1
                      : previousValue;
                  },
                  0
                )}
                /{groupedPermissions[key].length}
              </span>
            </Accordion.Title>
            <Accordion.Content active={isOpen}>
              <List relaxed>
                {sortBy(groupedPermissions[key], ["id"]).map((permission) => {
                  const active = isActive.indexOf(permission.id) >= 0;
                  let disabled = false;
                  let disabledReason = "";
                  if (permission.id === "admin.invoices.bed.unlock" && user.has_role['Commission mgr'] === false) {
                    disabled = true;
                    disabledReason = "Role of Commission mgr is required to unlock invoices.";
                  }
                  return (
                    <List.Item
                      key={permission.id}
                      title={disabled ? disabledReason : ''}
                      className={cx(["pb-2", "pl-2", "mb-1"])}
                    >
                      <List.Icon
                        name={active ? "lock open" : "lock"}
                        size="small"
                        color={disabled ? "red" : "grey"}
                        verticalAlign="middle"
                      />
                      <List.Content>
                        <List.Content floated="right" className="mt-1">
                          <Field
                            key={permission.id}
                            name={"permissions"}
                            option={permission}
                            disabled={disabled}
                            component={(component) => {
                              const valueIndex = findIndex(
                                get(component, "input.value"),
                                (v) => {
                                  return (
                                    v.id === get(component, "option.id") ||
                                    (v.key &&
                                      v.key === get(component, "option.id"))
                                  );
                                }
                              );
                              return (
                                <div className={"onoffswitch"}>
                                  <input
                                    type="checkbox"
                                    name="permissions"
                                    className="onoffswitch-checkbox"
                                    checked={valueIndex >= 0}
                                    value={permission.id}
                                    onChange={() => {}}
                                  />
                                  <label
                                    title={disabled ? disabledReason : ''}
                                    className={`onoffswitch-label ${disabled && 'onoffswitch-label-disabled' }`}
                                    onClick={() => {
                                      if (disabled) {
                                        return;
                                      }
                                      const newValue =
                                        get(component, "input.value") &&
                                        isArray(get(component, "input.value"))
                                          ? [...get(component, "input.value")]
                                          : [];
                                      if (valueIndex < 0) {
                                        newValue.push(permission);
                                      } else {
                                        newValue.splice(valueIndex, 1);
                                      }
                                      setIsActive(
                                        newValue.map((val) => {
                                          return val.id;
                                        })
                                      );
                                      return get(component, "input").onChange(
                                        newValue
                                      );
                                    }}
                                  ></label>
                                </div>
                              );
                            }}
                          />
                        </List.Content>
                        <List.Header>{permission.name}</List.Header>
                        <List.Description>
                          {permission.description}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  );
                })}
              </List>
            </Accordion.Content>
          </div>
        );
      })}
    </Accordion>
  );
};

PermissionsList.propTypes = {
  permissions: array,
  user: object,
  disabled: bool,
};

PermissionsList.defaultProps = {
  permissions: [],
  user: {},
  disabled: false,
};
