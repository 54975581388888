import { func, object } from "prop-types";
import React from "react";
import { Form as FinalForm } from "react-final-form";
import { Button, Form } from "semantic-ui-react";
import { renderBasicForm } from "../../../../../helpers/form";
import { fields } from "./fields";

export const GroupDetailsForm = ({ group, onSubmit }) => {
  if (!group) {
    return null;
  }
  return (
    <section className="group-details-form">
      <FinalForm initialValues={group} onSubmit={onSubmit}>
        {({ handleSubmit, valid }) => (
          <Form onSubmit={handleSubmit}>
            {renderBasicForm(fields)}
            <footer className="mt-3">
              <Button primary size="large" disabled={!valid}>
                Submit
              </Button>
            </footer>
          </Form>
        )}
      </FinalForm>
    </section>
  );
};

GroupDetailsForm.propTypes = {
  group: object,
  onSubmit: func.isRequired
};
