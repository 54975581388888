import { isEmpty } from 'lodash';

const chunkWaypoints = (waypoints) => {
  const MAX_WAYPOINTS = 25;
  const chunks = [];
  let chunk = {
    origin: null,
    waypoints: [],
    destination: null,
  };
  // Loop through the waypoints
  waypoints.forEach((waypoint, index) => {
    if (index === 0) {
      // This is origin of first chunk
      chunk.origin = waypoint.location;
    }
    if (chunk.waypoints.length === MAX_WAYPOINTS - 2) {
      // We are full on this chunk
      chunk.destination = waypoint.location;
      chunks.push(chunk);
      chunk = {
        origin: waypoint.location,
        waypoints: [],
        destination: null,
      };
    }
    else {
      // If this is the last waypoint, finish the current chunk
      if (index === waypoints.length - 1) {
        chunk.destination = waypoint.location;
        chunks.push(chunk);
        return;
      }
      // Add this waypoint to the chunk
      chunk.waypoints.push(waypoint);
    }
  });
  // Return the chunks
  return chunks;
};

const extractMarkersFromMapData = (routes = {}, selectedRoutes = []) => {
  const markers = [];
  if (isEmpty(routes) === false) {
    const routeKeys = Object.keys(routes);
    for (let i = 0; i < routeKeys.length; i++) {
      const routeKey = routeKeys[i];
      if(!selectedRoutes.includes(routeKey)) {
        continue;
      }
      const route = routes[routeKey];
      const routeMarkers = extractMarkersFromRoute(route).map(setMarkerLabelToRouteLetter);
      markers.push(...routeMarkers);
    }
  }
  return markers;
};

const extractMarkersFromRoute = (route) => {
  const markers = [];
  if (route) {
    const { waypoints } = route;
    waypoints.forEach((waypoint) => {
      markers.push(waypoint.location);
    });
  }
  return markers;
};

const setMarkerLabelToRouteLetter = (marker) => {
  marker.label = marker.customData.route;
  return marker;
}


export { chunkWaypoints, extractMarkersFromMapData, extractMarkersFromRoute, setMarkerLabelToRouteLetter };
