import { array, bool, func, number, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Container, Dimmer, Loader } from "semantic-ui-react";
import { actions as objectActions } from "../../../../store/object/group/actions";
import { ListComponent, ListHeader, PageAlert } from "../../../../components";
import { LINK_GROUP_CREATE } from "../../../../constants";
import { groupRequestSelector } from "../../../../store/request/group/selectors";
import { groupViewSelector } from "../../../../store/view/group/selectors";
import { DataTableWrapper } from "../../../../components/DataTable/Wrapper";
import { columns } from "./columns";

class GroupListComponent extends ListComponent {
  constructor(props) {
    super(props);
    this.state.columns = columns;
    this.state.sortOrder = {
      column: "name",
      isAscending: true
    };
  }

  componentDidMount() {
    const {
      isLoadingAll,
      isLoadedAll,
      fetchObjects,
      updateSortFilterLimit
    } = this.props;
    if (!isLoadedAll && !isLoadingAll) {
      fetchObjects(this.state);
    } else {
      // We don't need to load anything from the api, but we need to setup the first page of the grid
      updateSortFilterLimit(this.state);
    }
  }

  render() {
    const { total, loadError, isLoadingAll, objects } = this.props;
    const tableState = {
      ...this.state,
      onRefreshClick: this.handleRefresh,
      onExportClick: this.handleExportClick,
      onPageChange: this.handlePageChange,
      onSortChange: this.handleSortChange,
      onLimitChange: this.handleLimitChange,
      onSearchChange: this.handleSearchChange
    };
    return (
      <Container className="view-groups view-list view" as="article" fluid>
        <Dimmer active={isLoadingAll} inverted>
          <Loader size="large">Loading</Loader>
        </Dimmer>
        <ListHeader
          searchPlaceholder="Search Groups"
          title="Groups"
          link={LINK_GROUP_CREATE}
          tableState={tableState}
          total={total}
          limitCustomizable
        />
        <PageAlert title="Error While Retrieving Groups" message={loadError} />
        <DataTableWrapper
          columns={columns}
          rows={objects}
          tableState={tableState}
          total={total}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const requestSelector = groupRequestSelector();
  const viewSelector = groupViewSelector();
  return {
    isLoadedAll: requestSelector.getIsLoadedAll()(state),
    isLoadingAll: requestSelector.getIsLoadingAll()(state),
    loadError: requestSelector.getLoadAllError()(state),
    objects: viewSelector.getList()(state),
    total: viewSelector.getTotal()(state)
  };
};

GroupListComponent.displayName = "GroupList";
GroupListComponent.propTypes = {
  exportObjects: func.isRequired,
  fetchObjects: func.isRequired,
  objects: array.isRequired,
  isLoadedAll: bool,
  isLoadingAll: bool,
  loadError: string,
  total: number,
  updateSortFilterLimit: func.isRequired
};

export const GroupList = connect(
  mapStateToProps,
  { ...objectActions }
)(GroupListComponent);
