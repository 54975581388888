import { REPORT_REDUCER_NAME } from "../../report/constants";
import * as reportConstants from "../constants";
import { makeReportConstants } from "../util";
export const REDUCER_NAME = "inventorySales";
export const PERIOD_ID = "periodId";
export const TYPE = "type";

export const SET_PERIOD_ID = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_PERIOD_ID`;
export const SET_TYPE = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_TYPE`;

export const FETCH_RESULTS_URL = `${process.env.REACT_APP_API_URL}admin/report/inventorysales?periodId=:periodId&type=:type`;

// Action Types
const defaultConstants = makeReportConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...reportConstants,
  PERIOD_ID,
  REDUCER_NAME,
  SET_PERIOD_ID,
  FETCH_RESULTS_URL,
  SET_TYPE,
  TYPE
};
