import { array, bool, func, string, shape } from "prop-types";

export const formField = shape({
  name: string.isRequired,
  label: string,
  options: array,
  optionCreator: func,
  required: bool,
  size: string
});
