import React from 'react';

import { object } from 'prop-types';
import { connect } from 'react-redux';
import {
  Redirect,
  Route,
  withRouter,
} from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { ROLE_EMPLOYEE } from '../../constants';
import {
  WEB_ROUTE_DEFAULT,
  WEB_ROUTE_FORBIDDEN,
  WEB_ROUTE_LOGIN,
} from '../../constants/routes';
import {
  getToken,
  getUser,
  routePrefix,
} from '../../helpers/auth';

const PrivateRouteComponent = ({ component, ...props }) => {
  const { location } = props;
  const token = getToken();
  const prefix = routePrefix(location);
  const user = getUser();
  const shouldRender = prefix === WEB_ROUTE_DEFAULT;
  /**
   * Only let through user with role EMPLOYEE
   */
  const userHasPermission = user && user.hasRole(ROLE_EMPLOYEE);
  
  if (token && shouldRender && userHasPermission) {
    return <Route {...props} component={component} />;
  } else if (!token && location.pathname !== WEB_ROUTE_LOGIN && shouldRender) {
    return <Redirect to={WEB_ROUTE_LOGIN} />;
  } else if (!userHasPermission && shouldRender) {
    return <Redirect to={WEB_ROUTE_FORBIDDEN} />;
  } else {
    return null;
  }
};

PrivateRouteComponent.propTypes = {
  contact: object,
};

const mapStateToProps = createStructuredSelector({});

export const PrivateRoute = withRouter(
  connect(mapStateToProps)(PrivateRouteComponent)
);
