import React, { useEffect, useState } from "react";
import { get } from "lodash";
import cx from 'classnames';
import { Select } from "semantic-ui-react";
import { any, bool, func, string } from "prop-types";
import { fetchObjectsFromAPI } from "../../../../../../store/object/actions";
import { createOptionsFromObjects } from "../../../../../../helpers/form/util";
import { FETCH_ORGANIZATION_LIST_URL } from "../../../../../../store/object/user/constants";

const fetchList = () => {
  return new Promise((resolve) => {
    fetchObjectsFromAPI(FETCH_ORGANIZATION_LIST_URL, "data.data")
    .then((data) => {
      resolve(data);
    })
    .catch((e) => {
      console.error(e);
      resolve([]);
    });
  });
};

const OrganizationSelect = React.memo(({
  input,
  label,
  error = false,
  required,
}) => {
  const [organizations, setOrganizations] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!isLoaded && !isLoading) {
      setIsLoading(true);
      fetchList().then((organizations) => {
        setOrganizations(organizations.map((organization) => {
          return { id: organization, text: organization };
        }));
        setIsLoaded(true);
        setIsLoading(false);
      });
    }
  }, [isLoaded, isLoading]);
  const options = createOptionsFromObjects(organizations, 'id', 'text');
  const value = get(input, 'value', '');
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true,
  });
  return (
    <div className="form-input-container">
      {label && (
        <label className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
        <Select
          upward={false}
          fluid
          value={value}
          search
          name={input.name}
          error={error}
          selection
          options={options}
          onChange={(e, { value }) => {
            if (input && input.onChange) {
              input.onChange(value);
            }
          }}
          openOnFocus={false}
        />
    </div>
  );
});

OrganizationSelect.propTypes = {
  onChange: func,
  label: string,
  input: any,
  error: any,
  required: bool,
};


export { OrganizationSelect };