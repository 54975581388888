import React from "react";
import { any } from "prop-types";
import { capitalize } from "lodash";
import { Button } from "semantic-ui-react";
import cx from "classnames";

export const CustomerPortalEstimateStatusCell = React.memo(
  ({ value }) => {
    const getButtonColor = (status) => {
      switch (status) {
        case "approved":
          return "text-green";
        case "declined":
          return "text-red";
        case "pending":
          return "text-orange";
        case "draft":
        default:
          return "text-gray";
      }
    };
    const classes = cx({
      "m-0 fs-5 p-1 transparent b-0": true,
      [getButtonColor(value)]: true,
    });
    return (
      <div className="text-center d-flex flex-row justify-flex-center align-items-center">
        <span className="cell mr-1">{capitalize(value)}</span>
        <Button icon="file pdf outline" className={classes} />
      </div>
    );
  }
);

CustomerPortalEstimateStatusCell.propTypes = {
  value: any,
};
