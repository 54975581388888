import { get } from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  customersSchema,
  objectsSchema,
  servicePriceSchema,
  servicePricesSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import {
  actions as servicePriceRequestActions,
} from '../../request/servicePrice/actions';
import { servicePriceViewSelector } from '../../view/servicePrice/selectors';
import {
  actions as customerRequestActions,
} from '../../request/customer/actions';
import { sortAndLimitResults } from '../../view/actions';
import {
  actions as servicePriceViewActions,
} from '../../view/servicePrice/actions';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { CUSTOMER_REDUCER_NAME } from '../customer';
import { allConstants as constants } from './constants';
import { servicePriceSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(servicePriceRequestActions.setLoadAllError(null));
    dispatch(servicePriceRequestActions.setIsLoadingAll(true));
    try {
      const results = await fetchObjectsFromAPI(
        constants.FETCH_LIST_URL,
        "data"
      );

      // We have to update the objects to link them for the normalizer
      const { serviceLevels, servicePeriods, serviceSchedules } = results;
      results.serviceLevelPrices.forEach(price => {
        price.service_level = serviceLevels.find(level => {
          return level.id === price.service_level_id;
        });
        price.service_period = servicePeriods.find(period => {
          return period.id === price.service_period_id;
        });
        price.service_schedule = serviceSchedules.find(schedule => {
          return schedule.id === price.service_schedule_id;
        });
      });

      // Normalize the result and store the servicePrices in redux
      const entities = get(
        normalize(results, {
          ...objectsSchema,
          serviceLevelPrices: servicePricesSchema
        }),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(
          results.serviceLevelPrices,
          tableState,
          (ids, count) => {
            dispatch(servicePriceViewActions.setList(ids, count));
          }
        )
      );
      dispatch(servicePriceRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(servicePriceRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(servicePriceRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = id => {
  return async dispatch => {
    dispatch(servicePriceRequestActions.setLoadError(null));
    dispatch(servicePriceRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(`${constants.FETCH_URL}/${id}`);

      // We have to update the objects to link them for the normalizer
      const { allLevels, allPeriods, allSchedules } = result;
      const price = result.svc;
      price.service_level = allLevels.find(level => {
        return level.id === price.service_level_id;
      });
      price.service_period = allPeriods.find(period => {
        return period.id === price.service_period_id;
      });
      price.service_schedule = allSchedules.find(schedule => {
        return schedule.id === price.service_schedule_id;
      });

      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(
          {
            price,
            serviceLevels: allLevels,
            servicePeriods: allPeriods,
            serviceSchedules: allSchedules
          },
          {
            ...objectsSchema,
            price: servicePriceSchema
          }
        ),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(servicePriceRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(servicePriceRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Fetch the customers associated with this service price
 * @param servicePriceId
 * @param tableState
 * @returns {Function}
 */
 export const fetchCustomers = (servicePriceId, tableState) => {
  return async dispatch => {
    dispatch(customerRequestActions.setLoadAllError(null));
    dispatch(customerRequestActions.setIsLoadingAll(true));
    try {
      const result = await fetchObjectsFromAPI(
        `${constants.FETCH_CUSTOMER_LIST_URL}/${servicePriceId}`,
        "data"
      );

      if(result.customers){
        result.customers = result.customers.map(customer => {
          try {
            customer.service_info.options = JSON.parse(customer.service_info.options)
          } catch (e) {
            console.error(e)
          }          
          return customer;
        })
      }

      const entities = get(
        normalize(result.customers, customersSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Store the full list of ids in the reducer
      dispatch(
        servicePriceViewActions.setRelatedObjects(
          constants.RELATION_CUSTOMERS,
          servicePriceId,
          result.customers.map(customer => {
            return customer.id;
          })
        )
      );

      dispatch(updateCustomersSortFilterLimit(servicePriceId, tableState));
    } catch (e) {
      captureException(e);
      dispatch(customerRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(customerRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(servicePriceRequestActions.setSaveError(null));
    dispatch(servicePriceRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(constants.UPSERT_URL, data);
      const entities = get(
        normalize([result.svc], servicePricesSchema),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.svc);
      }
    } catch (e) {
      captureException(e);
      dispatch(servicePriceRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(servicePriceRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
 export const upsertCustomerBulkUpdate = (data, onSuccess) => {
  return async dispatch => {
    dispatch(servicePriceRequestActions.setSaveError(null));
    dispatch(servicePriceRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(constants.UPSERT_CUSTOMER_SERVICE_PRICE_BULK_UPDATE, {data: data});
     
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      dispatch(servicePriceRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(servicePriceRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const servicePrices = servicePriceSelector().getDenormalizedObjects()(
      getState()
    );
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(servicePrices, tableState, (ids, count) => {
        dispatch(servicePriceViewActions.setList(ids, count));
      })
    );
  };
};

/**
 * Filter, Sort, and Trim the customers for the table
 * @param servicePriceId
 * @param tableState
 * @returns {Function}
 */
 export const updateCustomersSortFilterLimit = (servicePriceId, tableState) => {
  return async (dispatch, getState) => {
    const state = getState();
    const customers = servicePriceViewSelector().getRelatedObjects(
      CUSTOMER_REDUCER_NAME,
      servicePriceId
    )(state);

    // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(customers, tableState, (ids, count) => {
        dispatch(
          servicePriceViewActions.setRelatedFilteredList(
            constants.RELATION_CUSTOMERS,
            servicePriceId,
            ids,
            count
          )
        );
      })
    );
  };
};

export const actions = {
  ...objectActions,
  fetchObject,
  fetchObjects,
  updateSortFilterLimit,
  upsertObject,
  fetchCustomers,
  updateCustomersSortFilterLimit,
  upsertCustomerBulkUpdate,
};
