import React from "react";
import MarkerClusterer from 'marker-clusterer-plus';
import { Circle } from "./Circle";
import { chunkWaypoints, extractMarkersFromRoute } from "./helpers";

/**
 * Check if google is available and set it to state
 * @returns {object}
 */
const useGoogle = () => {
  const [google, setGoogle] = React.useState(null);

  // Function to periodically check if google is available
  const checkGoogleAvailability = React.useCallback(() => {
    if (window.google) {
      setGoogle(window.google);
    } else {
      setTimeout(checkGoogleAvailability, 100); // Check again in 100 milliseconds
    }
  }, []);

  React.useEffect(() => {
    checkGoogleAvailability();
  }, [checkGoogleAvailability]);

  return google;
};

/**
 * Initialize a google map
 * @param {*} param0 
 * @returns {object}
 */
const useMap = ({
  route,
  markers,
  center,
  zoom = 7,
  fitBounds = false,
  clusterMarkers = false,
  showRoute = false,
}) => {
  const google = useGoogle();
  const [map, setMap] = React.useState();
  const [mapZoom] = React.useState(zoom);
  const [mapCenter] = React.useState(center);
  const ref = React.useRef();
  const currentRef = ref.current;
  if(route){
    markers = extractMarkersFromRoute(route);
  }
  const { mapMarkers, setMarkers } = useMapMarkers(map, markers, fitBounds, clusterMarkers);
  const { setRoute, clearRoute } = useMapRoute(map, route, markers);

  // Set the map on mount
  React.useEffect(() => {
    if(google && currentRef && !map){
      setMap(new google.maps.Map(currentRef, {
        center: mapCenter || { lat: 35.9533, lng: -78.9725 }, // This is WRC address
        zoom: mapZoom,
        mapTypeControl: false,
      }));
    }
  }, [google, currentRef, mapCenter, mapZoom, map, setMap]);

  // Set the markers when the markers change
  React.useEffect(() => {
    if(map && markers.length !== mapMarkers.length){
      setMarkers(markers);
    }
  }, [map, markers, mapMarkers, setMarkers]);

  // Set the route if there is one
  React.useEffect(() => {
    if(map && route){
      if (showRoute) {
        setRoute();
      } else {
        clearRoute();
      }
    }
  }, [map, route, showRoute, setRoute, clearRoute]);

  return {
    map: {
      ref,
      map,
      markers: mapMarkers,
    },
  };
};

/**
 * Set the bounds of the map to fit the markers
 * @param {*} map 
 * @param {*} markers 
 * @returns {object}
 */
const useFitBounds = (map, markers) => {
  const google = useGoogle();
  // Fit bounds on mount, and when the markers change
  const fitBounds = React.useCallback(() => {
    if(google && map && markers.length > 0){
      const bounds = new google.maps.LatLngBounds();
      markers.map(item => {
        bounds.extend({
          lat: item.lat,
          lng: item.lng,
        });
        return item.id
      });
      // Bound the map around the markers
      if(map && typeof map.fitBounds === 'function'){
        map.setCenter(bounds.getCenter());
        map.fitBounds(bounds, 0);
      }
    }    
  }, [markers, google, map]);
  return {
    fitBounds,
  };
};

/**
 * Create an info window
 * @returns {object}
 */
const useInfoWindow = () => {
  const google = useGoogle();
  const [infoWindow, setInfoWindow] = React.useState(null);
  React.useEffect(() => {
    if(google && !infoWindow){
      setInfoWindow(new google.maps.InfoWindow());
    }
  }, [google, infoWindow]);
  return infoWindow;
};

/**
 * Create markers on the map
 * @param {*} map
 * @param {*} markers
 * @param {*} fit
 * @param {*} clusterMarkers
 * @returns {object}
 */
const useMapMarkers = (map, markers = [], fit = false, clusterMarkers = true) => {
  const google = useGoogle();
  const { fitBounds } = useFitBounds(map, markers);
  const infoWindow = useInfoWindow();
  const {setMarkerClusters} = useMapMarkerClusters();
  const [mapMarkers, setMapMarkers] = React.useState([]);
  // Create callback function to set the markers
  const setMarkers = React.useCallback((markers) => {
    if (map && google && infoWindow && markers.length !== mapMarkers.length) {
      const newMarkers = markers.map((marker, index) => {
        const label = typeof marker.label === 'string' ? {
          text: marker.label,
          color: '#fff',
          fontSize: '12px',
        } : marker.label;
        const options = {
          key: index,
          map,
          position: marker,
          label,
        };
        if (marker.customData) {
          options.customData = marker.customData;
          options.icon = {
            url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(Circle({ color: marker.customData.color }))}`,
            scaledSize: new window.google.maps.Size(36, 36), // Adjust the size as needed
          };
        }
        const m = new google.maps.Marker(options);
        m.addListener('click', () => {
          if (infoWindow) {
            infoWindow.close();
            infoWindow.setContent(marker.info || "No Info");
            infoWindow.open(m.getMap(), m);
          }
        });
        return m;
        
      });
      setMapMarkers(newMarkers);
      if (fit && markers && markers.length > 1) {
        fitBounds();
      }
      if (clusterMarkers) {
        setMarkerClusters(map, newMarkers);
      }
    }
  }, [map, google, infoWindow, mapMarkers, fit, clusterMarkers, fitBounds, setMarkerClusters]);
  return {
    setMarkers,
    mapMarkers,
  };
};

/**
 * Cluster markers on the map
 * @param {*} map 
 * @param {*} markers 
 * @param {*} fit 
 * @returns {object}
 */
const useMapMarkerClusters = () => {
  const [cluster, setCluster] = React.useState(null);
  // Create callback function to set marker clusters
  const setMarkerClusters = React.useCallback((map, markers = []) => {
    if (map) {
      // Initialize the MarkerClusterer
      const clusterInstance = new MarkerClusterer(map, markers, {
        gridSize: 50, // Adjust to your needs
        maxZoom: 15,  // Adjust to your needs
      });
      setCluster(clusterInstance);
    }
  }, []);
  // Clean up when unmounted or when markers change
  React.useEffect(() => {
    return () => {
      if(cluster){
        cluster.clearMarkers();
      }
    };
  }, [cluster]);
  return {
    markerClusters: cluster,
    setMarkerClusters,
  };
};

/**
 * Draw a route on the map
 * @param {*} map
 * @param {*} route
 * @param {*} markers
 * @returns {object}
 */
const useMapRoute = (map, route, markers) => {
  const google = useGoogle();
  const { fitBounds } = useFitBounds(map, markers);
  const [loaded, setLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [directionsDisplays, setDirectionsDisplays] = React.useState([]);
  const setRoute = React.useCallback(() => {
    if (map && route && google && !loaded && !loading) {
      setLoading(true);
      const directionsService = new google.maps.DirectionsService();
      const {waypoints} = route;
      const chunkedWaypoints = chunkWaypoints(waypoints);
      const displays = [];
      // Create an array of promises for each chunk
      const promises = chunkedWaypoints.map((chunk) => {
        return new Promise((resolve, reject) => {
          // Create a DirectionsRenderer object to display the route
          const directionsDisplay = new google.maps.DirectionsRenderer({
            map: map,
          });
          directionsDisplay.setOptions({
            polylineOptions: {
              strokeColor: 'green',
              strokeWeight: 5,
              strokeOpacity: 0.5,
            },
            suppressMarkers: true,
          });
          // Calculate the route
          directionsService.route({
            origin: chunk.origin,
            destination: chunk.destination,
            waypoints: chunk.waypoints,
            travelMode: 'DRIVING'
          }, function(response, status) {
            if (status === 'OK') {
              directionsDisplay.setDirections(response);
              displays.push(directionsDisplay);
              resolve();
            } else {
              console.error('Directions request failed due to ' + status);
              reject();
            }
          });
        });
      });
      // Wait for all promises to resolve before setting done flag and fitting bounds
      Promise.all(promises).then(() => {
        setLoaded(true);
        setLoading(false);
        fitBounds();
        setDirectionsDisplays(displays);
      }).catch((error) => {
        console.error('Error calculating route: ' + error);
      });
    }
  }, [map, route, google, fitBounds, loaded, loading, setDirectionsDisplays]);
  const clearRoute = React.useCallback(() => {
    if (map && loaded && directionsDisplays) {
      directionsDisplays.forEach((directionsDisplay) => {
        directionsDisplay.setMap(null);
      });
      // setDirectionsDisplays([]);
      setLoaded(false);
    }
  }, [map, loaded, directionsDisplays]);
  return {
    setRoute,
    clearRoute,
  }
};

/**
 * Zoom to max zoom allowed for center of map
 * @param {*} map
 * @param {*} center
 * @param {*} zoom
 * @returns {object}
 */
const useMaxZoomService = () => {
  const google = useGoogle();
  // Update the map center and zoom when the props change
  const zoomToCenter = React.useCallback((map, center, zoom = 16) => {
    if(map && google){
      map.setCenter(center);
      let maxZoomService = new google.maps.MaxZoomService();
      maxZoomService.getMaxZoomAtLatLng(center, function(response) {
        if (response.status !== 'OK') {
          map.setZoom(zoom);
          return;
        }
        map.setZoom(response.zoom - 1);
      });
    }
  }, [google]);
  return {
    zoomToCenter,
  };
};

export { useGoogle, useFitBounds, useMap, useMapMarkers, useMapRoute, useMaxZoomService };
