import React, { Component } from "react";
import { bool, func, object } from "prop-types";
import { createStructuredSelector } from "reselect";
import { Button, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import {
  getConfirmationModalText,
  isConfirmationModalVisible
} from "../../../store/app/selectors";

import {
  toggleConfirmationModalVisible,
  toggleConfirmationModalYesClicked
} from "../../../store/app/actions";

const Coralogix = require("coralogix-logger");

class ConfirmationModalComponent extends Component {
  
  logger;

  componentDidMount(){
    this.logger = new Coralogix.CoralogixLogger("App");
  }
  
  componentDidCatch(error, errorInfo) {
    const message = get(error, 'message', 'Error');
    const componentStack = get(errorInfo, 'componentStack');
    const text = `${message}\nStacktrace:\n\t${componentStack}`
    const log = new Coralogix.Log({
        severity:Coralogix.Severity.error,
        text: text,
    });
    // send log to coralogix
    if(this.logger){
      this.logger.addLog(log);
    }
  }

  render() {
    const {
      isOpen,
      toggleConfirmationModalYesClicked,
      toggleConfirmationModalVisible
    } = this.props;
    const text = this.props.text || {};
    return (
      <Modal
        open={isOpen}
        onClose={() => {
          toggleConfirmationModalVisible(false);
        }}
      >
        <Modal.Header>{text.title}</Modal.Header>
        <Modal.Content>{text.content}</Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            onClick={() => {
              toggleConfirmationModalVisible(false);
              toggleConfirmationModalYesClicked(true);
            }}
            icon="checkmark"
            color={text.isDanger ? "red" : "blue"}
            labelPosition="right"
            content="Yes"
          />
          <Button
            type="button"
            onClick={() => {
              toggleConfirmationModalVisible(false);
            }}
            icon="x"
            labelPosition="right"
            content="No"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

ConfirmationModalComponent.propTypes = {
  isOpen: bool,
  text: object,
  toggleConfirmationModalVisible: func.isRequired,
  toggleConfirmationModalYesClicked: func.isRequired
};

const mapStateToProps = createStructuredSelector({
  isOpen: isConfirmationModalVisible(),
  text: getConfirmationModalText()
});

export const ConfirmationModal = withRouter(
  connect(
    mapStateToProps,
    {
      toggleConfirmationModalVisible,
      toggleConfirmationModalYesClicked
    }
  )(ConfirmationModalComponent)
);
