import {
  get,
  omit,
} from 'lodash';
import { normalize } from 'normalizr';
import { toast } from 'react-toastify';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  chemicalSchema,
  chemicalsSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import {
  actions as chemicalRequestActions,
} from '../../request/chemical/actions';
import { sortAndLimitResults } from '../../view/actions';
import { actions as chemicalViewActions } from '../../view/chemical/actions';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';
import { chemicalSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(chemicalRequestActions.setLoadAllError(null));
    dispatch(chemicalRequestActions.setIsLoadingAll(true));
    try {
      const chemicals = await fetchObjectsFromAPI(constants.FETCH_LIST_URL);

      // Normalize the result and store the chemicals in redux
      const entities = get(
        normalize(chemicals, chemicalsSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(chemicals, tableState, (ids, count) => {
          dispatch(chemicalViewActions.setList(ids, count));
        })
      );
      dispatch(chemicalRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(chemicalRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(chemicalRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Toggle the active status on a chemical
 * @param chemical
 * @returns {Function}
 */
export const toggleActive = chemical => {
  return async dispatch => {
    dispatch(chemicalRequestActions.setLoadAllError(null));
    dispatch(chemicalRequestActions.setIsLoadingAll(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.TOGGLE_URL.replace(":chemicalId", chemical.id),
        "data"
      );

      // Normalize the result and store the chemicals in redux
      const entities = get(
        normalize(result.chemical, chemicalSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
      dispatch(chemicalRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(chemicalRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(chemicalRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Toggle the active status on a chemical
 * @param chemical
 * @returns {Function}
 */
export const destroyObject = chemical => {
  return async dispatch => {
    dispatch(chemicalRequestActions.setLoadAllError(null));
    dispatch(chemicalRequestActions.setIsLoadingAll(true));
    try {
      await upsertObjectToAPI(
        constants.DELETE_URL.replace(":chemicalId", chemical.id),
        {}
      );
      dispatch(fetchObjects());
      toast.success("Spray Mix Definition deleted successfully!");
    } catch (e) {
      captureException(e);
      dispatch(chemicalRequestActions.setLoadAllError(getErrorMessage(e)));
      dispatch(chemicalRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = id => {
  return async dispatch => {
    dispatch(chemicalRequestActions.setLoadError(null));
    dispatch(chemicalRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        `${constants.FETCH_URL}/${id}`,
        "data"
      );
      const chemical = { ...result.item, list: result.list };

      // Normalize the result and store the other attributes in redux
      const entities = get(normalize(chemical, chemicalSchema), "entities", {});

      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(chemicalRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(chemicalRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(chemicalRequestActions.setSaveError(null));
    dispatch(chemicalRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(constants.UPSERT_URL, {
        sprayMix: omit(data, "list")
      });

      const entities = get(
        normalize(result.definition, chemicalSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.definition);
      }
    } catch (e) {
      captureException(e);
      dispatch(chemicalRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(chemicalRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Duplicate an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const duplicateObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(chemicalRequestActions.setIsLoadingAll(true));
    await dispatch(upsertObject({ ...omit(data, "id") }, onSuccess));
    dispatch(chemicalRequestActions.setIsLoadingAll(false));
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const chemicals = chemicalSelector().getDenormalizedObjects()(getState());
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(chemicals, tableState, (ids, count) => {
        dispatch(chemicalViewActions.setList(ids, count));
      })
    );
  };
};

export const actions = {
  ...objectActions,
  destroyObject,
  duplicateObject,
  fetchObject,
  fetchObjects,
  toggleActive,
  updateSortFilterLimit,
  upsertObject
};
