import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import cx from 'classnames';
import {
  debounce,
  isEmpty,
} from 'lodash';
import {
  arrayOf,
  bool,
  func,
  number,
  object,
  string,
} from 'prop-types';
import { connect } from 'react-redux';
import { Select } from 'semantic-ui-react';

import { createOptionsFromCustomers } from '../../../helpers/form/util';
import { RELATION_SEARCH } from '../../../store/object/constants';
import { CUSTOMER_REDUCER_NAME } from '../../../store/object/customer';
import {
  actions as customerObjectActions,
} from '../../../store/object/customer/actions';
import {
  customerRequestSelector,
} from '../../../store/request/customer/selectors';
import { customerViewSelector } from '../../../store/view/customer/selectors';

const CustomerSelectComponent = ({
  disabled,
  searchCustomers,
  id,
  input,
  isLoadingAll,
  label,
  customerId,
  customers,
  onSelectCustomer,
  onSelectCustomerId,
  placeholder,
  required
}) => {
  if (input && input.value) {
    customerId = input.value;
  }

  const [hasFocus, setHasFocus] = useState(false);
  const customerOptions = createOptionsFromCustomers(customers);
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true,
    "has-focus": hasFocus,
    "has-value": !!customerId
  });

  const debouncedSearchCustomers = debounce(searchQuery => {
    searchCustomers(searchQuery);
  }, 300);

  useEffect(() => {
    if (!isLoadingAll && customerId && isEmpty(customerOptions)) {
      searchCustomers(null, customerId);
    }
  }, [
    customerId,
    debouncedSearchCustomers,
    isLoadingAll,
    searchCustomers,
    customerOptions
  ]);

  const onSearchChange = useCallback(
    (e, data) => {
      if (data.searchQuery && data.searchQuery.length > 2) {
        debouncedSearchCustomers(data.searchQuery);
      }
    },
    [debouncedSearchCustomers]
  );
  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <Select
        upward={false}
        fluid
        disabled={disabled}
        size="huge"
        value={customerId}
        search
        name="customer"
        loading={isLoadingAll}
        placeholder={placeholder}
        selection
        noResultsMessage={isLoadingAll ? "Loading..." : "No Results Found"}
        options={customerOptions}
        onOpen={() => {
          setHasFocus(true);
        }}
        openOnFocus={false}
        onClose={() => {
          setHasFocus(false);
        }}
        onSearchChange={onSearchChange}
        onChange={(e, { value }) => {
          if (onSelectCustomer) {
            onSelectCustomer(customers.find(customer => customer.id === value));
          }
          if (onSelectCustomerId) {
            onSelectCustomerId(value);
          }
          if (input && input.onChange) {
            input.onChange(value);
          }
        }}
        onBlur={(e, { value }) => {
          if (value && input && input.onChange) {
            input.onChange(value);
          }
        }}
      />
    </div>
  );
};

CustomerSelectComponent.propTypes = {
  isLoadedAll: bool,
  isLoadingAll: bool,
  loadAllError: string,
  customerId: number,
  onSelectCustomer: func,
  onSelectCustomerId: func,
  placeholder: string,
  searchCustomers: func.isRequired,
  customers: arrayOf(object)
};

const mapStateToProps = (state, props) => {
  const viewSelector = customerViewSelector();
  const requestSelector = customerRequestSelector();
  return {
    isLoadingAll: requestSelector.getIsLoadingAll()(state),
    isLoadedAll: requestSelector.getIsLoadedAll()(state),
    loadAllError: requestSelector.getLoadAllError()(state),
    customers: viewSelector.getRelatedObjects(
      RELATION_SEARCH,
      RELATION_SEARCH,
      CUSTOMER_REDUCER_NAME
    )(state)
  };
};

export const CustomerSelect = connect(
  mapStateToProps,
  {
    searchCustomers: customerObjectActions.searchObjects
  }
)(CustomerSelectComponent);
