import * as routes from './routes';

/**
 * Customer Portal Links
 */
export const LINK_PORTAL_DASHBOARD = {
  label: "Home",
  key: "home",
  icon: "home",
  route: routes.WEB_ROUTE_PORTAL_DEFAULT,
}

export const LINK_PORTAL_SERVICE = {
  label: "Service",
  key: "service",
  icon: "truck",
  route: routes.WEB_ROUTE_PORTAL_SERVICE,
}

export const LINK_PORTAL_BILLING = {
  label: "Billing",
  key: "billing",
  icon: "credit card",
  route: routes.WEB_ROUTE_PORTAL_BILLING,
}


/**
 * Employee Dashboard Links
 */

export const LINK_ACTIVITY_LIST = {
  label: "Activity",
  key: "activity",
  route: routes.WEB_ROUTE_ACTIVITY_LIST
};

export const LINK_CUSTOMER_LIST = {
  route: routes.WEB_ROUTE_CUSTOMER_LIST,
  label: "Customers",
  key: "customers"
};

export const LINK_CUSTOMER_EDIT = {
  route: routes.WEB_ROUTE_CUSTOMER_EDIT,
  state: {
    parent: "customers",
    sibling: "customers"
  }
};

export const LINK_CUSTOMER_MAP = {
  route: routes.WEB_ROUTE_CUSTOMER_MAP,
  label: "Map",
  key: "map",
  state: {
    parent: "customers",
    sibling: "customers"
  }
};

export const LINK_APPOINTMENT_LIST = {
  route: routes.WEB_ROUTE_APPOINTMENT_LIST,
  label: "Appointments",
  key: "appointments",
  state: {
    parent: "customers"
  }
};

export const LINK_APPOINTMENT_EDIT = {
  route: routes.WEB_ROUTE_APPOINTMENT_EDIT,
  state: {
    parent: "appointments",
    sibling: "appointments"
  }
};

export const LINK_CUSTOMER_CREATE = {
  ...LINK_CUSTOMER_EDIT,
  route: routes.WEB_ROUTE_CUSTOMER_CREATE
};

export const SECTION_CUSTOMERS = {
  label: "Customers",
  key: "customers",
  icon: "customers",
  route: routes.WEB_ROUTE_CUSTOMER_LIST,
  children: [LINK_CUSTOMER_LIST, LINK_APPOINTMENT_LIST, LINK_CUSTOMER_MAP]
};

export const LINK_CYCLE_COUNT_LIST = {
  label: "Cycle Count",
  key: "cycle-count",
  route: routes.WEB_ROUTE_CYCLE_COUNT_LIST
};

export const LINK_CYCLE_COUNT_EDIT = {
  route: routes.WEB_ROUTE_CYCLE_COUNT_EDIT,
  state: {
    parent: "Cycle Count",
    sibling: "cycle-count"
  }
};

export const LINK_CYCLE_COUNT_CREATE = {
  ...LINK_CYCLE_COUNT_EDIT,
  route: routes.WEB_ROUTE_CYCLE_COUNT_CREATE
};

export const LINK_SALES_DASHBOARD = {
  route: routes.WEB_ROUTE_SALES_DASHBOARD,
  label: "Sales Dashboard",
  key: "sales-dashboard",
  icon: "dashboard",
  state: {
    parent: "Admin Dashboard",
    sibling: "arsm-dashboard"
  }
};

export const LINK_ARSM_DASHBOARD = {
  route: routes.WEB_ROUTE_ARSM_DASHBOARD,
  label: "ARSM Dashboard",
  key: "arsm-dashboard",
  icon: "dashboard",
  state: {
    parent: "Admin Dashboard",
    sibling: "sales-dashboard"
  }
};

export const LINK_BED_INVOICE_LIST = {
  route: routes.WEB_ROUTE_BED_INVOICE_LIST,
  label: "Bed Invoices",
  key: "bed-invoices",
  icon: "dashboard",
};

export const LINK_DASHBOARD = {
  label: "Admin Dashboard",
  key: "admin-dashboard",
  route: routes.WEB_ROUTE_DEFAULT,
  icon: "dashboard",
  children: [LINK_SALES_DASHBOARD, LINK_ARSM_DASHBOARD, LINK_BED_INVOICE_LIST]
};

export const LINK_BED_PACKET_LIST = {
  route: routes.WEB_ROUTE_BED_PACKET_LIST,
  label: "Bed Packets",
  key: "bed-packets"
};

export const LINK_BED_PACKET_EDIT = {
  route: routes.WEB_ROUTE_BED_PACKET_EDIT,
  state: {
    parent: "bed-packets",
    sibling: "bed-packets"
  }
};

export const LINK_ESTIMATE_LIST = {
  route: routes.WEB_ROUTE_ESTIMATE_LIST,
  label: "Bed Estimates",
  key: "bed-estimates"
};

export const LINK_ESTIMATE_EDIT = {
  route: routes.WEB_ROUTE_ESTIMATE_EDIT,
  state: {
    parent: "bed-estimates",
    sibling: "bed-estimates"
  }
};

export const LINK_ESTIMATE_CREATE = {
  ...LINK_ESTIMATE_EDIT,
  route: routes.WEB_ROUTE_ESTIMATE_CREATE
};

export const LINK_FS_ADJ = {
  route: routes.WEB_ROUTE_FS_ADJ,
  label: "FS Adj",
  key: "fs-adj"
};

export const LINK_GOOD_LIST = {
  route: routes.WEB_ROUTE_GOOD_LIST,
  label: "Goods",
  key: "goods"
};

export const LINK_GOOD_EDIT = {
  route: routes.WEB_ROUTE_GOOD_EDIT,
  state: {
    parent: "inventory",
    sibling: "goods"
  }
};

export const LINK_GOOD_CREATE = {
  ...LINK_GOOD_EDIT,
  route: routes.WEB_ROUTE_GOOD_CREATE
};

export const LINK_GROUP_LIST = {
  route: routes.WEB_ROUTE_GROUP_LIST,
  label: "Groups",
  key: "groups"
};

export const LINK_GROUP_EDIT = {
  route: routes.WEB_ROUTE_GROUP_EDIT,
  state: {
    parent: "users-groups",
    sibling: "groups"
  }
};

export const LINK_GROUP_CREATE = {
  ...LINK_GROUP_EDIT,
  route: routes.WEB_ROUTE_GROUP_CREATE
};

export const LINK_HISTORY = {
  route: routes.WEB_ROUTE_HISTORY_LIST,
  label: "History",
  key: "history"
};

export const LINK_INVOICE_LIST = {
  route: routes.WEB_ROUTE_INVOICE_LIST,
  icon: "invoices",
  label: "Retail",
  key: "invoices"
};

export const LINK_INVOICE_EDIT = {
  route: routes.WEB_ROUTE_INVOICE_EDIT,
  state: {
    parent: "invoices",
    sibling: "invoices"
  }
};

export const LINK_INVOICE_CREATE = {
  ...LINK_INVOICE_EDIT,
  route: routes.WEB_ROUTE_INVOICE_CREATE
};

export const LINK_LOCATION_LIST = {
  route: routes.WEB_ROUTE_LOCATION_LIST,
  label: "Locations",
  icon: "locations",
  key: "locations"
};

export const LINK_LOCATION_EDIT = {
  route: routes.WEB_ROUTE_LOCATION_EDIT,
  state: {
    parent: "locations",
    sibling: "locations"
  }
};

export const LINK_LOCATION_CREATE = {
  ...LINK_LOCATION_EDIT,
  route: routes.WEB_ROUTE_LOCATION_CREATE
};

export const LINK_LOT_COST_CHANGE = {
  route: routes.WEB_ROUTE_LOT_COST_CHANGE,
  label: "Lot Cost Change",
  key: "lot-cost-change"
};

export const LINK_MANAGE_IA = {
  route: routes.WEB_ROUTE_MANAGE_IA,
  label: "Manage IA",
  key: "manage-ia"
};

export const LINK_GOOD_TYPE_LIST = {
  route: routes.WEB_ROUTE_GOOD_TYPE_LIST,
  label: "Good Type",
  key: "good-types",
  state: {
    parent: "inventory",
    sibling: "goods"
  }
};

export const LINK_GOOD_TYPE_EDIT = {
  route: routes.WEB_ROUTE_GOOD_TYPE_EDIT,
  state: {
    parent: "inventory",
    sibling: "goods"
  }
};

export const LINK_GOOD_TYPE_CREATE = {
  ...LINK_GOOD_TYPE_EDIT,
  route: routes.WEB_ROUTE_GOOD_TYPE_CREATE
};

export const LINK_PRODUCT_CLASS_LIST = {
  route: routes.WEB_ROUTE_PRODUCT_CLASS_LIST,
  label: "Product Class",
  key: "product-class"
};

export const LINK_PRODUCT_CLASS_EDIT = {
  route: routes.WEB_ROUTE_PRODUCT_CLASS_EDIT,
  state: {
    parent: "inventory",
    sibling: "product-class"
  }
};

export const LINK_PRODUCT_CLASS_CREATE = {
  ...LINK_PRODUCT_CLASS_EDIT,
  route: routes.WEB_ROUTE_PRODUCT_CLASS_CREATE
};

export const LINK_PROFIT_CENTER_LIST = {
  route: routes.WEB_ROUTE_PROFIT_CENTER_LIST,
  label: "Profit Centers",
  key: "profit-centers"
};

export const LINK_PROFIT_CENTER_EDIT = {
  route: routes.WEB_ROUTE_PROFIT_CENTER_EDIT,
  state: {
    parent: "profit-centers",
    sibling: "profit-centers"
  }
};

export const LINK_PROFIT_CENTER_CREATE = {
  ...LINK_PROFIT_CENTER_EDIT,
  route: routes.WEB_ROUTE_PROFIT_CENTER_CREATE
};

export const LINK_PURCHASE_ORDER_LIST_ALL = {
  route: routes.WEB_ROUTE_PO_ALL,
  label: "All",
  key: "all"
};

export const LINK_PURCHASE_ORDER_LIST_DRAFT = {
  route: routes.WEB_ROUTE_PO_DRAFT,
  label: "Draft",
  key: "draft"
};

export const LINK_PURCHASE_ORDER_LIST_PENDING = {
  route: routes.WEB_ROUTE_PO_PENDING,
  label: "Pending",
  key: "pending"
};

export const LINK_PURCHASE_ORDER_LIST_APPROVED = {
  route: routes.WEB_ROUTE_PO_APPROVED,
  label: "Approved",
  key: "approved"
};

export const LINK_PURCHASE_ORDER_EDIT = {
  route: routes.WEB_ROUTE_PO_EDIT,
  state: {
    parent: "purchase-orders",
    sibling: "all"
  }
};

export const LINK_PURCHASE_ORDER_CREATE = {
  ...LINK_PURCHASE_ORDER_EDIT,
  route: routes.WEB_ROUTE_PO_CREATE
};

export const LINK_ROLE_LIST = {
  route: routes.WEB_ROUTE_ROLE_LIST,
  label: "Roles",
  key: "roles"
};

export const LINK_ROLE_EDIT = {
  route: routes.WEB_ROUTE_ROLE_EDIT,
  state: {
    parent: "users-groups",
    sibling: "roles"
  }
};

export const LINK_ROLE_CREATE = {
  ...LINK_ROLE_EDIT,
  route: routes.WEB_ROUTE_ROLE_CREATE
};

export const LINK_SERVICE_ROUTE_LIST = {
  route: routes.WEB_ROUTE_SERVICE_ROUTE_LIST,
  label: "Service Routes",
  key: "service-routes"
};

export const LINK_SERVICE_ROUTE_EDIT = {
  route: routes.WEB_ROUTE_SERVICE_ROUTE_EDIT,
  state: {
    parent: "service",
    sibling: "service-routes"
  }
};

export const LINK_SERVICE_ROUTE_CREATE = {
  ...LINK_SERVICE_ROUTE_EDIT,
  route: routes.WEB_ROUTE_SERVICE_ROUTE_CREATE
};

export const LINK_SERVICE_ACTIVITY = {
  route: routes.WEB_ROUTE_SERVICE_ACTIVITY_LIST,
  label: "Service Activity",
  key: "service-activity"
};

export const LINK_SERVICE_ACTIVITY_GRID = {
  route: routes.WEB_ROUTE_SERVICE_ACTIVITY_LIST,
  label: "Service Activity Grid",
  key: "service-activity-grid",
  hash: "#grid",
};

export const LINK_SERVICE_LIST = {
  route: routes.WEB_ROUTE_SERVICE_LIST,
  label: "Service Mgmt",
  key: "service-mgmt"
};

export const LINK_SERVICE_LEVEL_LIST = {
  route: routes.WEB_ROUTE_SERVICE_LEVEL_LIST,
  label: "Service Levels",
  key: "service-levels",
  state: {
    parent: "service",
    sibling: "service-mgmt"
  }
};

export const LINK_SERVICE_LEVEL_EDIT = {
  route: routes.WEB_ROUTE_SERVICE_LEVEL_EDIT,
  state: {
    parent: "service",
    sibling: "service-mgmt"
  }
};

export const LINK_SERVICE_LEVEL_CREATE = {
  ...LINK_SERVICE_LEVEL_EDIT,
  route: routes.WEB_ROUTE_SERVICE_LEVEL_CREATE
};

export const LINK_SERVICE_PERIOD_LIST = {
  route: routes.WEB_ROUTE_SERVICE_PERIOD_LIST,
  label: "Service Levels",
  key: "service-periods",
  state: {
    parent: "service",
    sibling: "service-mgmt"
  }
};

export const LINK_SERVICE_PERIOD_EDIT = {
  route: routes.WEB_ROUTE_SERVICE_PERIOD_EDIT,
  state: {
    parent: "service",
    sibling: "service-mgmt"
  }
};

export const LINK_SERVICE_PERIOD_CREATE = {
  ...LINK_SERVICE_PERIOD_EDIT,
  route: routes.WEB_ROUTE_SERVICE_PERIOD_CREATE
};

export const LINK_SERVICE_SCHEDULE_LIST = {
  route: routes.WEB_ROUTE_SERVICE_SCHEDULE_LIST,
  label: "Service Schedules",
  key: "service-schedules",
  state: {
    parent: "service",
    sibling: "service-mgmt"
  }
};

export const LINK_SERVICE_SCHEDULE_EDIT = {
  route: routes.WEB_ROUTE_SERVICE_SCHEDULE_EDIT,
  state: {
    parent: "service",
    sibling: "service-mgmt"
  }
};

export const LINK_SERVICE_SCHEDULE_CREATE = {
  ...LINK_SERVICE_SCHEDULE_EDIT,
  route: routes.WEB_ROUTE_SERVICE_SCHEDULE_CREATE
};

export const LINK_SERVICE_PRICE_LIST = {
  route: routes.WEB_ROUTE_SERVICE_PRICE_LIST,
  label: "Service Pricing",
  key: "service-pricing",
  state: {
    parent: "service",
    sibling: "service-mgmt"
  }
};

export const LINK_SERVICE_PRICE_EDIT = {
  route: routes.WEB_ROUTE_SERVICE_PRICE_EDIT,
  state: {
    parent: "service",
    sibling: "service-mgmt"
  }
};

export const LINK_SERVICE_PRICE_CREATE = {
  ...LINK_SERVICE_PRICE_EDIT,
  route: routes.WEB_ROUTE_SERVICE_PRICE_CREATE
};

export const LINK_SERVICE_EDIT = {
  route: routes.WEB_ROUTE_SERVICE_EDIT,
  state: {
    parent: "service",
    sibling: "service-mgmt"
  }
};

export const LINK_SERVICE_CREATE = {
  ...LINK_SERVICE_EDIT,
  route: routes.WEB_ROUTE_SERVICE_CREATE
};

export const LINK_SERVICE_STATEMENT_LIST = {
  route: routes.WEB_ROUTE_SERVICE_STATEMENT_LIST,
  label: "Statements",
  key: "service-statements"
};

export const LINK_SERVICE_RUNTAX_LIST = {
  route: routes.WEB_ROUTE_SERVICE_AUTORUNTAX_VIEW.replace(':id', 0),
  label: "Run Tax",
  key: "run-tax"
};

export const LINK_SERVICE_STATEMENT_VIEW = {
  route: routes.WEB_ROUTE_SERVICE_STATEMENT_VIEW,
  state: {
    parent: "service",
    sibling: "service-statements"
  }
};

export const LINK_SERVICE_STATEMENT_EDIT = {
  route: routes.WEB_ROUTE_SERVICE_STATEMENT_EDIT,
  state: {
    parent: "service",
    sibling: "service-statements"
  }
};

export const LINK_SERVICE_STATEMENT_CREATE = {
  ...LINK_SERVICE_STATEMENT_EDIT,
  route: routes.WEB_ROUTE_SERVICE_STATEMENT_CREATE
};

export const LINK_SERVICE_AUTOSTATEMENT_VIEW = {
  route: routes.WEB_ROUTE_SERVICE_AUTORUNTAX_VIEW,
  state: {
    parent: "service",
    sibling: "service-statements"
  }
};

export const LINK_SERVICE_PAYMENT_LIST = {
  route: routes.WEB_ROUTE_SERVICE_PAYMENT_LIST,
  label: "Auto Payments",
  key: "service-payments"
};

export const LINK_SERVICE_ERROR_LOG = {
  route: routes.WEB_ROUTE_SERVICE_ERROR_LOG,
  label: "Error Log",
  key: "service-error-log"
};

export const LINK_SPRAY_MIX = {
  route: routes.WEB_ROUTE_SPRAY_MIX,
  label: "Spray Mix",
  key: "spray-mix"
};

export const LINK_CHEMICAL_LIST = {
  route: routes.WEB_ROUTE_CHEMICAL_LIST,
  label: "Spray Mix",
  key: "chemicals"
};

export const LINK_CHEMICAL_EDIT = {
  route: routes.WEB_ROUTE_CHEMICAL_EDIT,
  state: {
    parent: "inventory",
    sibling: "chemicals"
  }
};

export const LINK_CHEMICAL_CREATE = {
  ...LINK_CHEMICAL_EDIT,
  route: routes.WEB_ROUTE_CHEMICAL_CREATE
};

export const LINK_TRANSFER_LIST = {
  route: routes.WEB_ROUTE_TRANSFER_LIST,
  label: "Transfers",
  key: "transfers"
};

export const LINK_TRANSFER_EDIT = {
  route: routes.WEB_ROUTE_TRANSFER_EDIT,
  state: {
    parent: "inventory",
    sibling: "transfers"
  }
};

export const LINK_TRANSFER_CREATE = {
  ...LINK_TRANSFER_EDIT,
  route: routes.WEB_ROUTE_TRANSFER_CREATE
};

export const LINK_USER_LIST = {
  route: routes.WEB_ROUTE_USER_LIST,
  label: "Users",
  key: "users"
};

export const LINK_USER_EDIT = {
  route: routes.WEB_ROUTE_USER_EDIT,
  state: {
    parent: "users-groups",
    sibling: "users"
  }
};

export const LINK_USER_CREATE = {
  ...LINK_USER_EDIT,
  route: routes.WEB_ROUTE_USER_CREATE
};

export const LINK_VENDOR_LIST = {
  key: "vendors",
  route: routes.WEB_ROUTE_VENDOR_LIST,
  label: "Vendors",
  icon: "vendors"
};

export const LINK_VENDOR_EDIT = {
  route: routes.WEB_ROUTE_VENDOR_EDIT,
  state: {
    parent: "vendors",
    sibling: "vendors"
  }
};

export const LINK_VENDOR_CREATE = {
  ...LINK_VENDOR_EDIT,
  route: routes.WEB_ROUTE_VENDOR_CREATE
};

export const LINK_REPORT_STARTS_STOPS = {
  label: "Adds/Discontinues",
  key: "report-starts-stops",
  route: routes.WEB_ROUTE_REPORT_STARTS_STOPS,
  state: {
    parent: "other-reports"
  }
};

export const LINK_REPORT_SERVICE_ACCOUNTS_RECEIVABLE = {
  label: "Service Accounts Receivable",
  key: "report-service-accounts-receivable",
  route: routes.WEB_ROUTE_REPORT_SERVICE_ACCOUNTS_RECEIVABLE,
  state: {
    parent: "other-reports"
  }
};

export const LINK_REPORT_SERVICE_ACTIVITY = {
  route: routes.WEB_ROUTE_REPORT_SERVICE_ACTIVITY,
  state: {
    parent: "reports"
  }
};

export const LINK_UTIL_REVERSE = {
  route: routes.WEB_ROUTE_UTILITY_REVERSE,
  state: {
    parent: "reports"
  }
};

export const LINK_UTIL_IMPORT = {
  route: routes.WEB_ROUTE_UTILITY_IMPORT,
  state: {
    parent: "reports"
  }
};
export const LINK_WORK_ORDER_LIST = {
  key: "work-orders",
  icon: "work-orders",
  route: routes.WEB_ROUTE_WORK_ORDER_LIST,
  label: "Work Orders"
};

export const LINK_WORK_ORDER_EDIT = {
  route: routes.WEB_ROUTE_WORK_ORDER_EDIT,
  state: {
    parent: "work-orders",
    sibling: "work-orders"
  }
};

export const LINK_REPORT_INVENTORY = {
  route: routes.WEB_ROUTE_REPORT_INVENTORY,
  key: "report-inventory",
  label: "Inventory Report",
  state: {
    parent: "inventory-reports"
  }
};
export const LINK_REPORT_REORDER_POINTS = {
  route: routes.WEB_ROUTE_REPORT_REORDER_POINTS,
  key: "report-reorder-points",
  label: "Reorder Points",
  state: {
    parent: "inventory-reports"
  }
};

export const LINK_REPORT_INVENTORY_SALES_REPORT = {
  route: routes.WEB_ROUTE_REPORT_INVENTORY_SALES_REPORT,
  key: "report-inventory-sales",
  label: "Inventory Sales",
  state: {
    parent: "inventory-reports"
  }
};
export const LINK_REPORT_VALUE_BY_PRODUCT_CLASS = {
  route: routes.WEB_ROUTE_REPORT_VALUE_BY_PRODUCT_CLASS,
  key: "report-value-by-product-class",
  label: "Value by Product Class",
  state: {
    parent: "inventory-reports"
  }
};
export const LINK_REPORT_ROSES_ON_HAND = {
  route: routes.WEB_ROUTE_REPORT_ROSES_ON_HAND,
  key: "report-roses-on-hand",
  label: "Roses on Hand",
  state: {
    parent: "inventory-reports"
  }
};
export const LINK_REPORT_ACTIVITY_BY_LOT_COST = {
  route: routes.WEB_ROUTE_REPORT_ACTIVITY_BY_LOT_COST,
  key: "report-activity-by-lot-cost",
  label: "Activity By Lot Cost",
  state: {
    parent: "inventory-reports"
  }
};
export const LINK_REPORT_ACTIVITY_BY_LOCATION = {
  route: routes.WEB_ROUTE_REPORT_ACTIVITY_BY_LOCATION,
  key: "report-activity-by-location",
  label: "Activity By Location",
  state: {
    parent: "inventory-reports"
  }
};
export const LINK_REPORT_GC_ACTIVITY = {
  route: routes.WEB_ROUTE_REPORT_GC_ACTIVITY,
  key: "gc-activity",
  label: "GC Activity",
  state: {
    parent: "retail-reports"
  }
};
export const LINK_REPORT_PAID_ROSES = {
  route: routes.WEB_ROUTE_REPORT_PAID_ROSES,
  key: "paid-roses",
  label: "Paid Roses",
  state: {
    parent: "retail-reports"
  }
};

export const LINK_REPORT_FAT_FINGER = {
  route: routes.WEB_ROUTE_REPORT_FAT_FINGER,
  key: "fat-finger",
  label: "Fat Finger",
  state: {
    parent: "statement-reports"
  }
};
export const LINK_REPORT_SERVICE_REVENUE_DETAIL = {
  route: routes.WEB_ROUTE_REPORT_SERVICE_REVENUE_DETAIL,
  key: "service-revenue-detail",
  label: "Service Revenue Detail",
  state: {
    parent: "statement-reports"
  }
};
export const LINK_REPORT_SERVICE_REVENUE = {
  route: routes.WEB_ROUTE_REPORT_SERVICE_REVENUE,
  key: "service-revenue",
  label: "Service Revenue Summary",
  state: {
    parent: "statement-reports"
  }
};
export const LINK_REPORT_SALES_TAX_SUMMARY = {
  route: routes.WEB_ROUTE_REPORT_SALES_TAX_SUMMARY,
  key: "sales-tax-summary",
  label: "Sales Tax Summary",
  state: {
    parent: "statement-reports"
  }
};
export const LINK_REPORT_SALES_TAX_DETAIL = {
  route: routes.WEB_ROUTE_REPORT_SALES_TAX_DETAIL,
  key: "sales-tax-detail",
  label: "Sales Tax Detail",
  state: {
    parent: "statement-reports"
  }
};
export const LINK_REPORT_TABS = {
  route: routes.WEB_ROUTE_REPORT_TABS,
  key: "tabs",
  label: "Tabs",
  state: {
    parent: "service-customer-reports"
  }
};
export const LINK_REPORT_EXPIRING_CC = {
  route: routes.WEB_ROUTE_REPORT_EXPIRING_CC,
  key: "expiring-cc",
  label: "Expiring CC",
  state: {
    parent: "service-customer-reports"
  }
};
export const LINK_REPORT_ACTIVE_CUSTOMERS = {
  route: routes.WEB_ROUTE_REPORT_ACTIVE_CUSTOMERS,
  key: "active-customers",
  label: "Active Customers",
  state: {
    parent: "service-customer-reports"
  }
};

export const LINK_REPORT_COMMISSION = {
  route: routes.WEB_ROUTE_REPORT_COMMISSION,
  key: "commission",
  label: "Commission",
  state: {
    parent: "other-reports"
  }
};
export const LINK_REPORT_OPEN_ITEM = {
  route: routes.WEB_ROUTE_REPORT_OPEN_ITEM,
  key: "open-item",
  label: "Open Item",
  state: {
    parent: "other-reports"
  }
};
export const LINK_REPORT_INVENTORY_ADJUSTMENTS = {
  route: routes.WEB_ROUTE_REPORT_INVENTORY_ADJUSTMENTS,
  key: "inventory-adjustments",
  label: "Inventory Adjustments",
  state: {
    parent: "other-reports"
  }
};
export const LINK_REPORT_INVENTORY_ADJUSTMENTS_V2 = {
  route: routes.WEB_ROUTE_REPORT_INVENTORY_ADJUSTMENTS_V2,
  key: "inventory-adjustments-v2",
  label: "Inventory Adjustments v2",
  state: {
    parent: "other-reports"
  }
};
export const LINK_REPORT_TRANSFER = {
  route: routes.WEB_ROUTE_REPORT_TRANSFER,
  key: "transfer-report",
  label: "Transfer Report",
  state: {
    parent: "other-reports"
  }
};
export const LINK_REPORT_FINANCIAL = {
  route: routes.WEB_ROUTE_REPORT_FINANCIAL,
  key: "financial-report",
  label: "Financials",
  state: {
    parent: "other-reports"
  }
};
export const LINK_REPORT_INVOICE_DIFF_STATUS = {
  route: routes.WEB_ROUTE_REPORT_INVOICE_DIFF_STATUS,
  key: "invoice-diff-status",
  label: "Invoice Diff Status",
  state: {
    parent: "other-reports"
  }
};
export const LINK_REPORT_PRODUCT_CLASS_RECEIPTS = {
  route: routes.WEB_ROUTE_REPORT_PRODUCT_CLASS_RECEIPTS,
  key: "product-class-receipts",
  label: "Product Class Receipts",
  state: {
    parent: "other-reports"
  }
};
export const LINK_REPORT_INVOICE_TAX_REPORT = {
  route: routes.WEB_ROUTE_REPORT_INVOICE_TAX_REPORT,
  key: "invoice-tax-report",
  label: "Invoice Tax Report",
  state: {
    parent: "other-reports"
  }
};
export const LINK_REPORT_WORK_ORDER_STATUS = {
  route: routes.WEB_ROUTE_REPORT_WORK_ORDER_STATUS,
  key: "work-order-status",
  label: "Work Order Status",
  state: {
    parent: "other-reports"
  }
};

export const LINK_WORK_ORDER_CREATE = {
  ...LINK_WORK_ORDER_EDIT,
  route: routes.WEB_ROUTE_WORK_ORDER_CREATE
};

export const SECTION_INVENTORY = {
  label: "Inventory",
  key: "inventory",
  icon: "inventory",
  route: routes.WEB_ROUTE_GOOD_LIST,
  children: [
    LINK_GOOD_LIST,
    LINK_PRODUCT_CLASS_LIST,
    LINK_TRANSFER_LIST,
    LINK_SPRAY_MIX,
    LINK_ACTIVITY_LIST,
    LINK_HISTORY,
    LINK_FS_ADJ,
    LINK_CYCLE_COUNT_LIST,
    LINK_LOT_COST_CHANGE
  ]
};

export const SECTION_LOCATIONS = {
  label: "Locations",
  key: "locations",
  icon: "locations",
  route: routes.WEB_ROUTE_PROFIT_CENTER_LIST,
  children: [LINK_PROFIT_CENTER_LIST, LINK_LOCATION_LIST]
};

export const SECTION_PURCHASE_ORDERS = {
  label: "Purchase Orders",
  key: "purchase-orders",
  icon: "purchase-orders",
  route: routes.WEB_ROUTE_PO_ALL,
  children: [
    LINK_PURCHASE_ORDER_LIST_ALL,
    LINK_PURCHASE_ORDER_LIST_DRAFT,
    LINK_PURCHASE_ORDER_LIST_PENDING,
    LINK_PURCHASE_ORDER_LIST_APPROVED
  ]
};

export const SECTION_SERVICE = {
  label: "Service",
  key: "service",
  icon: "service",
  route: routes.WEB_ROUTE_SERVICE_ROUTE_LIST,
  children: [
    LINK_SERVICE_ROUTE_LIST,
    LINK_SERVICE_ACTIVITY,
    LINK_SERVICE_LIST,
    LINK_SERVICE_STATEMENT_LIST,
    LINK_SERVICE_RUNTAX_LIST,
    LINK_SERVICE_PAYMENT_LIST,
    LINK_SERVICE_ERROR_LOG
  ]
};

export const SECTION_USER_GROUPS = {
  label: "Users/Groups",
  key: "users-groups",
  icon: "inventory",
  route: routes.WEB_ROUTE_USER_LIST,
  children: [LINK_USER_LIST, LINK_GROUP_LIST, LINK_ROLE_LIST]
};

export const LINK_REPORTS = {
  label: "Reports",
  key: "reports",
  icon: "reports",
  route: routes.WEB_ROUTE_REPORTS
};

export const SECTION_INVENTORY_REPORTS = {
  label: "Inventory Reports",
  key: "inventory-reports",
  icon: "reports",
  route: routes.WEB_ROUTE_REPORT_INVENTORY,
  children: [
    LINK_REPORT_INVENTORY,
    LINK_REPORT_REORDER_POINTS,
    LINK_REPORT_INVENTORY_SALES_REPORT,
    LINK_REPORT_VALUE_BY_PRODUCT_CLASS,
    LINK_REPORT_ROSES_ON_HAND,
    LINK_REPORT_ACTIVITY_BY_LOCATION
  ]
};

export const SECTION_RETAIL_REPORTS = {
  label: "Retail Reports",
  key: "retail-reports",
  icon: "reports",
  route: routes.WEB_ROUTE_REPORT_GC_ACTIVITY,
  children: [LINK_REPORT_GC_ACTIVITY, LINK_REPORT_PAID_ROSES]
};

export const SECTION_STATEMENT_REPORTS = {
  label: "Statement Reports",
  key: "statement-reports",
  icon: "reports",
  route: routes.WEB_ROUTE_REPORT_FAT_FINGER,
  children: [
    LINK_REPORT_FAT_FINGER,
    LINK_REPORT_SERVICE_REVENUE,
    LINK_REPORT_SERVICE_REVENUE_DETAIL,
    LINK_REPORT_SALES_TAX_SUMMARY,
    LINK_REPORT_SALES_TAX_DETAIL
  ]
};

export const SECTION_SERVICE_CUSTOMER_REPORTS = {
  label: "Customer Reports",
  key: "service-customer-reports",
  icon: "reports",
  route: routes.WEB_ROUTE_REPORT_TABS,
  children: [LINK_REPORT_TABS, LINK_REPORT_EXPIRING_CC]
};
export const SECTION_OTHER_REPORTS = {
  label: "Other Reports",
  key: "other-reports",
  icon: "reports",
  route: routes.WEB_ROUTE_REPORT_COMMISSION,
  children: [
    LINK_REPORT_COMMISSION,
    LINK_REPORT_SERVICE_ACCOUNTS_RECEIVABLE,
    LINK_REPORT_STARTS_STOPS,
    LINK_REPORT_OPEN_ITEM,
    LINK_REPORT_INVENTORY_ADJUSTMENTS,
    LINK_REPORT_TRANSFER,
    LINK_REPORT_FINANCIAL,
    //LINK_REPORT_INVOICE_DIFF_STATUS,
    LINK_REPORT_PRODUCT_CLASS_RECEIPTS,
    LINK_REPORT_INVOICE_TAX_REPORT,
    LINK_REPORT_WORK_ORDER_STATUS
  ]
};

export const SECTION_SETTINGS = {
  label: "System Settings",
  key: "dashboard-settings-section",
  icon: "settings",
  route: routes.WEB_ROUTE_SETTINGS_ALL
};
