import React from "react";

import { bool, func, string } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { Card, Grid } from "semantic-ui-react";

import { ErrorBoundaryComponent } from "../../../components";
import { ROLE_EMPLOYEE, APP_BRAND_NAME } from "../../../constants";
import {
  getToken,
  getUser,
  goToDefaultRoute,
  goToPortalRoute,
} from "../../../helpers/auth";
import * as actions from "../../../store/auth/actions";
import {
  getAuthenticationError,
  isAuthenticating,
} from "../../../store/auth/selectors";
import { LoginForm } from "./Form";

class LoginComponent extends ErrorBoundaryComponent {
  componentDidMount() {
    const { history, setAuthenticationError } = this.props;
    setAuthenticationError(null);
    if (getToken()) {
      const user = getUser();
      if (user.hasRole(ROLE_EMPLOYEE)) {
        // If user is employee, go to default route.
        return goToDefaultRoute(history);
      }
      // If user is not employee, go to portal route.
      goToPortalRoute(history);
    }
  }

  handleSubmit = (values) => {
    const { history, loginWithEmailPassword } = this.props;

    loginWithEmailPassword(values, (user) => {
      if (user.hasRole(ROLE_EMPLOYEE)) {
        // If user is admin, go to default route.
        goToDefaultRoute(history);
      } else {
        // If user is not admin, go to portal route.
        goToPortalRoute(history);
      }
    });
    return false;
  };

  render() {
    const { authenticationError, isAuthenticating } = this.props;

    return (
      <div className="view-login full-height">
        <div className="rose" />
        <Grid
          centered
          columns={1}
          verticalAlign="middle"
          className="full-height"
        >
          <Grid.Column>
            <Card className="hide-opacity login centered middle big-shadow fade-in-and-scale">
              <main className="login-area-container">
                <article className="login-area">
                  <header className="d-flex flex-column">
                    <div className="logo-container"></div>
                    <span className="company mt-2">
                      {APP_BRAND_NAME.toUpperCase()}
                    </span>
                    <span className="title mt-2">Account Log In</span>
                  </header>

                  <LoginForm
                    authenticationError={authenticationError}
                    isSubmitting={isAuthenticating}
                    onSubmit={this.handleSubmit}
                  />
                </article>
              </main>
              {process.env.REACT_APP_VERSION && (
                <footer className="mt-5">
                  <p className="fs-3 text-center text-gray15">
                    v{process.env.REACT_APP_VERSION}
                  </p>
                </footer>
              )}
            </Card>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

LoginComponent.propTypes = {
  authenticationError: string,
  isAuthenticating: bool,
  loginWithEmailPassword: func.isRequired,
  setIsAuthenticating: func.isRequired,
  setAuthenticationError: func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  authenticationError: getAuthenticationError(),
  isAuthenticating: isAuthenticating(),
});

export const Login = withRouter(
  connect(
    mapStateToProps,
    actions
  )(LoginComponent)
);
