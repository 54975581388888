import {
  get,
} from 'lodash';
import { normalize } from 'normalizr';
import {
  locationTypesSchema,
  objectsSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import { makeObjectActions } from "../actions";
import { allConstants as constants } from "./constants";
import { captureException, getErrorMessage } from "../../../helpers/error";
import {
  actions as locationTypeRequestActions,
} from '../../request/locationType/actions';
import { fetchObjectsFromAPI } from "../actions";


const objectActions = makeObjectActions(constants);

/**
 * 
 * @returns {Function}
 */
export const fetchObjects = () => {
  return async dispatch => {
    dispatch(locationTypeRequestActions.setLoadAllError(null));
    dispatch(locationTypeRequestActions.setIsLoadingAll(true));
    try {
      const result = await fetchObjectsFromAPI(`${constants.FETCH_LIST_URL}`);
      // Convert the location types
      const locationTypes = [];
      Object.keys(result.locationTypes).forEach(key => {
        locationTypes.push({ id: key, name: result.locationTypes[key] });
      });
      result.locationTypes = locationTypes;

      // Normalize the result and store in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          locationTypes: locationTypesSchema
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));
      dispatch(locationTypeRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(locationTypeRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(locationTypeRequestActions.setIsLoadingAll(false));
    }
  };
};


export const actions = {
  ...objectActions,
  fetchObjects
};
