import { get } from "lodash";
import { normalize } from "normalizr";

import { getErrorMessage, captureException } from "../../../helpers/error";
import {
  permissionsSchema,
  upsertNormalizedEntities,
} from "../../../helpers/normalizers";
import { actions as permissionRequestActions } from "../../request/permission/actions";
import { sortAndLimitResults } from "../../view/actions";
import { actions as permissionViewActions } from "../../view/permission/actions";
import { fetchObjectsFromAPI, makeObjectActions } from "../actions";

import { allConstants as constants } from "./constants";
import { permissionSelector } from "./selectors";
import { setPermissions } from "../../../helpers/auth";

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 */
export const fetchObjects = (tableState) => {
  return async (dispatch) => {
    dispatch(permissionRequestActions.setLoadAllError(null));
    dispatch(permissionRequestActions.setIsLoadingAll(true));
    try {
      const permissions = await fetchObjectsFromAPI(constants.FETCH_LIST_URL);

      // Normalize the result and store the permissions in redux
      const entities = get(
        normalize(permissions, permissionsSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Store permissions to local storage.
      setPermissions(permissions);
      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(permissions, tableState, (ids, count) => {
          dispatch(permissionViewActions.setList(ids, count));
        })
      );
      dispatch(permissionRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(permissionRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(permissionRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = (tableState) => {
  return async (dispatch, getState) => {
    const permissions = permissionSelector().getDenormalizedObjects()(
      getState()
    );
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(permissions, tableState, (ids, count) => {
        dispatch(permissionViewActions.setList(ids, count));
      })
    );
  };
};

export const actions = {
  ...objectActions,
  fetchObjects,
  updateSortFilterLimit,
};
