import dayjs from "dayjs";
import { fromJS } from "immutable";
import { END_DATE, START_DATE } from "../activityByLocation/constants";
import { initialReportState } from "../reducer";

export const initialFinancialReportState = constants => {
  const endDate = dayjs()
    .format("MM/DD/YYYY");
  const startDate = dayjs()
    .add(-1, "day")
    .format("MM/DD/YYYY");

  return fromJS({
    ...initialReportState(constants).toJS(),
    [START_DATE]: startDate,
    [END_DATE]: endDate,
    [constants.BALANCE]: [],
    [constants.BREAKDOWN]: []
  });
};

export const financialReportReducer = (state, action, constants) => {
  switch (action.type) {
    case constants.SET_BALANCE:
      return state.setIn([constants.BALANCE], fromJS(action.data));
    case constants.SET_IS_LOADED_BALANCE:
      return state.setIn([constants.IS_LOADED_BALANCE], action.isLoaded);

    case constants.SET_BREAKDOWN:
      return state.setIn([constants.BREAKDOWN], fromJS(action.data));
    case constants.SET_IS_LOADED_BREAKDOWN:
      return state.setIn([constants.IS_LOADED_BREAKDOWN], action.isLoaded);

    case constants.SET_START_DATE:
      return state.set(START_DATE, action.date);
    case constants.SET_END_DATE:
      return state.set(END_DATE, action.date);
    default:
      return state;
  }
};
