import React, { useState } from 'react';

import cx from 'classnames';
import {
  get,
  isEmpty,
  isFunction,
  isObject,
} from 'lodash';
import {
  arrayOf,
  object,
} from 'prop-types';
import { Table } from 'semantic-ui-react';

import {
  ROW_CLASSNAME_DISABLED,
  ROW_CLASSNAME_ERROR,
  ROW_CLASSNAME_POSITIVE,
  ROW_CLASSNAME_WARNING,
} from '../../../constants';
import { column } from '../../../helpers/shapes';
import { getObjectValueForColumn } from '../util';

export const DataTableBody = React.memo(({ columns, rows, tableProps }) => {
  const [activeRow, setActiveRow] = useState();
  const rowClassRenderer = get(tableProps, "rowClassRenderer");
  if (isEmpty(rows)) {
    return null;
  }

  return (
    <Table.Body>
      {rows.map((row, index) => {
        if (!isObject(row)) {
          return null;
        }

        let rowClassName = null;
        if (isFunction(rowClassRenderer)) {
          rowClassName = rowClassRenderer(row);
        }

        return (
          <Table.Row
            key={get(row, "id") || index}
            error={rowClassName === ROW_CLASSNAME_ERROR}
            disabled={rowClassName === ROW_CLASSNAME_DISABLED}
            positive={rowClassName === ROW_CLASSNAME_POSITIVE}
            warning={rowClassName === ROW_CLASSNAME_WARNING}
            onClick={ () => {
              const onRowClick = get(tableProps, 'onRowClick');
              if(onRowClick){
                setActiveRow(get(row, "id"))
                onRowClick(row);
              }
            }}
            className={cx({
              "highlighted": get(row, "id") === activeRow,
              [rowClassName]: !!rowClassName,
            })}
          >
            {!isEmpty(columns) &&
              columns.map(column => {
                const value = getObjectValueForColumn(row, column);
                const className = cx({ "d-none": column.isHidden });
                return (
                  <Table.Cell
                    key={column.title}
                    className={className}
                    width={column.width}
                    data-html2canvas-ignore={column.isExcludeFromExport}
                  >
                    {column.renderer
                      ? column.renderer({
                          value,
                          row,
                          rendererProps: column.rendererProps,
                          tableProps
                        })
                      : value}
                  </Table.Cell>
                );
              })}
          </Table.Row>
        );
      })}
    </Table.Body>
  );
});

DataTableBody.propTypes = {
  columns: arrayOf(column),
  rows: arrayOf(object),
  tableProps: object
};
