import React from "react";
import { ToastContainer } from "react-toastify";

export const ToastProvider = ({ children }) => {
  return (
  <React.Fragment>
    {children}
    <ToastContainer />
  </React.Fragment>
  );
}