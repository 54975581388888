import { fromJS } from "immutable";
import { WO_FILTER_PLACEHOLDER } from "../../../constants";
import { initialViewState } from "../reducer";
import { TYPE_FILTER, ASSIGNMENT_FILTER, ROUTE_FILTER, STATUS_FILTER, LOCATION_FILTER } from "./constants";

export const initialWorkOrderViewState = (constants) => {
  return fromJS({
    ...initialViewState(constants).toJS(),
    [TYPE_FILTER]: WO_FILTER_PLACEHOLDER,
    [ASSIGNMENT_FILTER]: WO_FILTER_PLACEHOLDER,
    [LOCATION_FILTER]: WO_FILTER_PLACEHOLDER,
    [ROUTE_FILTER]: WO_FILTER_PLACEHOLDER,
    [STATUS_FILTER]: WO_FILTER_PLACEHOLDER,
  });
};

export const workOrderReducer = (state, action, constants) => {
  switch (action.type) {
    // ASSIGNMENT FILTER
    case constants.SET_ASSIGNMENT_FILTER:
      return state.set(ASSIGNMENT_FILTER, action.assignmentFilter);
    case constants.SET_ASSIGNMENT_FILTERED_OBJECTS:
      return state.setIn(
        [constants.ASSIGNMENT_FILTERED_OBJECTS, action.assignmentFilter],
        fromJS(action.data)
      );
    case constants.SET_ASSIGNMENT_FILTERED_LIST:
      return state
        .setIn(
          [
            constants.ASSIGNMENT_FILTERED_LIST,
            action.assignmentFilter,
            constants.LIST,
          ],
          fromJS(action.data)
        )
        .setIn(
          [
            constants.ASSIGNMENT_FILTERED_LIST,
            action.assignmentFilter,
            constants.TOTAL,
          ],
          fromJS(action.count || 0)
        );
    // LOCATION FILTER
    case constants.SET_LOCATION_FILTER:
      return state.set(LOCATION_FILTER, action.locationFilter);

    case constants.SET_LOCATION_FILTERED_OBJECTS:
      return state.setIn(
        [constants.LOCATION_FILTERED_OBJECTS, action.locationFilter],
        fromJS(action.data)
      );
    case constants.SET_LOCATION_FILTERED_LIST:
      return state
        .setIn(
          [constants.LOCATION_FILTERED_LIST, action.locationFilter, constants.LIST],
          fromJS(action.data)
        )
        .setIn(
          [constants.LOCATION_FILTERED_LIST, action.locationFilter, constants.TOTAL],
          fromJS(action.count || 0)
        );
    // ROUTE FILTER
    case constants.SET_ROUTE_FILTER:
      return state.set(ROUTE_FILTER, action.routeFilter);

    case constants.SET_ROUTE_FILTERED_OBJECTS:
      return state.setIn(
        [constants.ROUTE_FILTERED_OBJECTS, action.routeFilter],
        fromJS(action.data)
      );
    case constants.SET_ROUTE_FILTERED_LIST:
      return state
        .setIn(
          [constants.ROUTE_FILTERED_LIST, action.routeFilter, constants.LIST],
          fromJS(action.data)
        )
        .setIn(
          [constants.ROUTE_FILTERED_LIST, action.routeFilter, constants.TOTAL],
          fromJS(action.count || 0)
        );
    // TYPE FILTER
    case constants.SET_TYPE_FILTER:
      return state.set(TYPE_FILTER, action.typeFilter);

    case constants.SET_TYPE_FILTERED_OBJECTS:
      return state.setIn(
        [constants.TYPE_FILTERED_OBJECTS, action.typeFilter],
        fromJS(action.data)
      );
    case constants.SET_TYPE_FILTERED_LIST:
      return state
        .setIn(
          [constants.TYPE_FILTERED_LIST, action.typeFilter, constants.LIST],
          fromJS(action.data)
        )
        .setIn(
          [constants.TYPE_FILTERED_LIST, action.typeFilter, constants.TOTAL],
          fromJS(action.count || 0)
        );
      // STATUS FILTER
      case constants.SET_STATUS_FILTER:
        return state.set(STATUS_FILTER, action.statusFilter);
  
      case constants.SET_STATUS_FILTERED_OBJECTS:
        return state.setIn(
          [constants.STATUS_FILTERED_OBJECTS, action.statusFilter],
          fromJS(action.data)
        );
      case constants.SET_STATUS_FILTERED_LIST:
        return state
          .setIn(
            [constants.STATUS_FILTERED_LIST, action.statusFilter, constants.LIST],
            fromJS(action.data)
          )
          .setIn(
            [constants.STATUS_FILTERED_LIST, action.statusFilter, constants.TOTAL],
            fromJS(action.count || 0)
          );
    default:
      return state;
  }
};
