import { fromJS } from "immutable";
import { keyBy } from "lodash";

export const initialObjectState = () => {
  return fromJS({});
};

export const makeObjectReducer = (
  constants,
  initialState = initialObjectState
) => {
  return (state = initialState(), action) => {
    switch (action.type) {
      case constants.UPSERT:
        const stateJS = state.toJS();
        const mergedObjects = Object.keys(action.data).map(key => {
          return stateJS[key]
            ? { ...stateJS[key], ...action.data[key] }
            : action.data[key];
        });
        return fromJS({ ...stateJS, ...keyBy(mergedObjects, "id") });

      case constants.RESET:
        return initialState();

      default:
        return state;
    }
  };
};
