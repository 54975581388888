import React from "react";
import { Checkbox, Segment, Grid } from "semantic-ui-react";
import { get } from "lodash";
import { FieldArray } from 'react-final-form-arrays';

export const AssignedRoutesForm = ({routes = [], title, disabled, form }) => {

  const userRoutes = form.getFieldState('service_routes')?.value;
  return (
    <Segment color={'green'} className="h-100" disabled={disabled}>
      {title && <h3 className="mb-3">{title}</h3>}
      <Grid columns={8}>
        <FieldArray name="service_routes">
          {() => routes.map(route => {
          const checked = userRoutes?.filter( r => r.service_route.name === route.name).length > 0;
          return <Grid.Column key={get(route, 'id')}>
            <Checkbox
                label={get(route, 'name')}
                checked={checked}
                onChange={() => {
                  if(disabled){
                    return;
                  }
                  if(checked){
                    // unset from user.service_routes
                    const updatedRoutes = Array.isArray(userRoutes) ? userRoutes.filter( r => r.service_route.name !== route.name) : [];
                    form.change('service_routes', updatedRoutes)

                    return;
                  }

                  const updatedRoutes = Array.isArray(userRoutes) ? [...userRoutes, {service_route: route}] : [{service_route: route}];
                  form.change('service_routes', updatedRoutes)
                }}
                />
            </Grid.Column>
          })}
        </FieldArray>
      </Grid>
    </Segment>
  );
};