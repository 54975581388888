import { schema } from 'normalizr';

import {
  REDUCER_NAME as GOOD_REDUCER_NAME,
} from '../../store/object/good/constants';
import { goodTypeSchema } from './goodType';
import { productClassSchema } from './productClass';
import { vendorsSchema } from './vendor';

export const goodSchema = new schema.Entity(GOOD_REDUCER_NAME, {
  type: goodTypeSchema,
  product_class: productClassSchema,
  vendors: vendorsSchema
});

export const goodsSchema = [goodSchema];
