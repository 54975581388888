import cx from "classnames";
import dayjs from "dayjs";
import React from "react";
import { DateInput } from "semantic-ui-calendar-react";

/**
 * A component used to render a group of checkboxes
 */
export const FormDatePickerInput = ({
  input,
  id,
  label,
  placeholder,
  focus,
  disabled,
  className,
  required,
  meta: { touched, error } = "",
  forceShowError = false,
}) => {
  const { name, value, minDate, maxDate } = input;
  const isValid = !error;
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true,
    "has-value": !!value,
  });
  const formattedDateValue = value
    ? dayjs(value, "YYYY-MM-DD").format("MM/DD/YYYY")
    : "";

  const showError = !!(forceShowError && error) || !!(touched && error);
  className = cx({ valid: isValid, error: showError }, className);
  return (
    <div className="form-input-container position-relative">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <DateInput
        minDate={minDate}
        maxDate={maxDate}
        clearable
        value={formattedDateValue}
        closable
        disabled={disabled}
        dateFormat={"MM/DD/YYYY"}
        id={id}
        animation="none"
        name={name}
        placeholder={placeholder}
        focus={focus}
        className={className}
        fluid
        onChange={(e, data) => {
          input.onChange(
            data.value
              ? dayjs(data.value, "MM/DD/YYYY").format("YYYY-MM-DD")
              : null
          );
        }}
      />
      {error && <span className='input-error'>{error}</span>}
    </div>
  );
};
