import React, { useState, useEffect } from "react";
import { object } from "prop-types";
import { get } from "lodash";
import { Card, Label } from "semantic-ui-react";
import { ADDRESS_BIT_TYPE_MAP } from "../../../../../constants/general";
import cx from "classnames";
import { PaginationBubbles } from "../../../../../components/PaginationBubbles";

export const CustomerAddresses = ({ customer }) => {
  const addresses = get(customer, "addresses", []);
  const [activeAddress, setActiveAddress] = useState();

  useEffect(() => {
    if (!activeAddress && addresses.length) {
      setActiveAddress(get(addresses[0], "id"));
    } else {
      if (addresses.length) {
        const hasAddress = addresses.filter((address) => {
          return address.id === activeAddress;
        }).length;
        if (!hasAddress) {
          setActiveAddress(null);
        }
      }
    }
  }, [activeAddress, setActiveAddress, addresses]);

  return (
    <div className="portal-customer-address-wrapper">
      {addresses.map((address) => {
        return (
          <Card
            key={get(address, "id", Math.random())}
            className={cx({
              "portal-customer-address b-0 mb-0 pl-3 mt-0": true,
              active: get(address, "id") === activeAddress,
            })}
          >
            <Card.Content>
              <Label ribbon className="mb-1">
                {ADDRESS_BIT_TYPE_MAP[get(address, "type_bit")]}
              </Label>
              <div>{get(address, "address1")}</div>
              {get(address, "address2") && (
                <div>{get(address, "address2")}</div>
              )}
              <div>
                {get(address, "city")}, {get(address, "state")}{" "}
                {get(address, "postal_code")}
              </div>
            </Card.Content>
          </Card>
        );
      })}
      {addresses.length > 1 && (
        <PaginationBubbles
          items={addresses}
          activeItem={activeAddress}
          setActiveItem={setActiveAddress}
        />
      )}
    </div>
  );
};

CustomerAddresses.propTypes = {
  customer: object,
};
