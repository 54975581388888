import React, {
  useEffect,
  useState,
} from 'react';

import cx from 'classnames';
import { get } from 'lodash';
import {
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOfType,
  string,
} from 'prop-types';
import { connect } from 'react-redux';
import { Select } from 'semantic-ui-react';

import {
  BUCKET_FORSALE,
  INVENTORY_LOT_LOADING,
  NEW_INVENTORY_LOT_LABEL,
} from '../../../constants/general';
import { formatCurrency } from '../../../helpers/util';
import {
  actions as goodObjectActions,
} from '../../../store/object/good/actions';
import { INVENTORY_LOT_REDUCER_NAME } from '../../../store/object/inventoryLot';
import {
  inventoryLotRequestSelector,
} from '../../../store/request/inventoryLot/selectors';
import { goodViewSelector } from '../../../store/view/good/selectors';
import dayjs from 'dayjs';

const LotSelectComponent = ({
  disabled,
  fetchLots,
  id,
  goodId,
  currentLocation,
  input,
  isLoadedAll,
  isLoadingAll,
  loadAllError,
  label,
  lotId,
  lots,
  onSelectLot,
  onSelectLotId,
  placeholder,
  required,
  setLotId,
}) => {
  if (input && input.value) {
    lotId = input.value;
  }
  useEffect(() => {
    if (!isLoadedAll && !isLoadingAll && !loadAllError) {
      fetchLots(goodId, currentLocation);
    }
  }, [
    isLoadedAll,
    isLoadingAll,
    fetchLots,
    loadAllError,
    goodId,
    currentLocation,
  ]);

  const [hasFocus, setHasFocus] = useState(false);
  const lotOptions = lots.map((lot) => {
    return {
      key: get(lot, "id"),
      value: get(lot, "id"),
      text: `${dayjs(get(lot, "created_at")).format("YYYY-MM-DD")} / OH: ${Math.round(get(lot, "onhand"), 1)} / FS: ${Math.round(
        get(lot, BUCKET_FORSALE),
        1
      )} / Cost: ${formatCurrency(get(lot, "cost"))} / (lot ID ${get(
        lot,
        "id"
      )})`,
    };
  });
  if (isLoadingAll) {
    lotOptions.push({
      key: INVENTORY_LOT_LOADING,
      value: INVENTORY_LOT_LOADING,
      text: "Loading...",
    });
  }
  lotOptions.push({
    key: 0,
    value: 0,
    text: NEW_INVENTORY_LOT_LABEL,
  });
  if (lotOptions.length === 1 && lotId !== INVENTORY_LOT_LOADING) {
    lotId = 0;
  } else if (lots.length && lotId === INVENTORY_LOT_LOADING) {
    lotId = get(lots[0], "id");
  }
  setLotId(lotId);
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true,
    "has-focus": hasFocus,
    "has-value": !!lotId,
  });
  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <Select
        upward={false}
        fluid
        disabled={disabled}
        value={lotId}
        size="huge"
        search
        name="lot"
        loading={isLoadingAll}
        placeholder={placeholder}
        selection
        options={lotOptions}
        onOpen={() => {
          setHasFocus(true);
        }}
        openOnFocus={false}
        onClose={() => {
          setHasFocus(false);
        }}
        onChange={(e, { value }) => {
          if (onSelectLot) {
            onSelectLot(
              lots.find((lot) => {
                return lot.id === value;
              })
            );
          }
          if (onSelectLotId) {
            onSelectLotId(value);
          }
          if (input && input.onChange) {
            input.onChange(value);
          }
        }}
        onBlur={(e, { value }) => {
          if (value && input && input.onChange) {
            input.onChange(value);
          }
        }}
      />
    </div>
  );
};

LotSelectComponent.propTypes = {
  fetchLots: func.isRequired,
  isLoadedAll: bool,
  isLoadingAll: bool,
  loadAllError: string,
  lotId: oneOfType([number, string]),
  setLotId: func,
  onSelectLot: func,
  onSelectLotId: func,
  placeholder: string,
  lots: arrayOf(object),
};

const mapStateToProps = (state, props) => {
  const goodId = get(props, "goodId") || 0;
  const requestSelector = inventoryLotRequestSelector();
  const viewSelector = goodViewSelector();
  return {
    isLoadingAll: requestSelector.getIsLoadingAll()(state),
    isLoadedAll: requestSelector.getIsLoadedAll()(state),
    loadAllError: requestSelector.getLoadAllError()(state),
    lots: viewSelector.getRelatedFilteredList(
      INVENTORY_LOT_REDUCER_NAME,
      goodId
    )(state),
  };
};

export const LotSelect = connect(
  mapStateToProps,
  {
    fetchLots: goodObjectActions.fetchLots,
  }
)(LotSelectComponent);
