import { schema } from 'normalizr';

import {
  REDUCER_NAME as USER_REDUCER_NAME,
} from '../../store/object/user/constants';
import { groupsSchema } from './group';
import { locationSchema } from './location';
import { permissionsSchema } from './permission';
import { rolesSchema } from './role';

export const userSchema = new schema.Entity(USER_REDUCER_NAME, {
  all_roles: rolesSchema,
  defaultLocation: locationSchema,
  groups: groupsSchema,
  roles: rolesSchema,
  permissions: permissionsSchema,
});

export const usersSchema = [userSchema];
