import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Icon, Loader, Select } from "semantic-ui-react";
import { any, arrayOf, bool, func, object, string, number } from "prop-types";
import { createOptionsFromObjects } from "../../../helpers/form/util";
import { goodVendorSelector } from "../../../store/object/goodVendor/selectors";
import { actions as goodVendorObjectActions } from "../../../store/object/goodVendor/actions";
import { goodVendorRequestSelector } from "../../../store/request/goodVendor/selectors";

const GoodVendorSelectComponent = React.memo(({
  input,
  fetchList,
  isLoadedAll,
  isLoadingAll,
  label,
  onChange,
  goodVendorId,
  goods,
  error = false,
  required,
}) => {
  useEffect(() => {
    if (!isLoadedAll && !isLoadingAll) {
      fetchList();
    }
  }, [isLoadedAll, isLoadingAll, fetchList]);
  const options = createOptionsFromObjects(goods, 'id', 'show');
  return (
    <div className="form-input form-input-huge  mb-4">
      <label className="strong form-label pb-1 d-block has-value">
        {label}
        {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
        )}
      </label>
      <div className="select-wrapper d-flex">
        <div className="select-wrapper-prefix">
          {isLoadingAll ? <Loader active={isLoadingAll} inline /> : <Icon name='list' />}
        </div>
        <div className="flex-grow-1 select-container">
          <Select
            upward={false}
            fluid
            value={goodVendorId}
            search
            name={input.name}
            error={error}
            selection
            options={options}
            onChange={(e, { value }) => {
              onChange(value);
            }}
            openOnFocus={false}
          />
        </div>
      </div>
    </div>
  );
});

GoodVendorSelectComponent.propTypes = {
  fetchList: func.isRequired,
  isLoadedAll: bool,
  isLoadingAll: bool,
  onChange: func.isRequired,
  label: string,
  goods: arrayOf(object),
  input: any,
  goodVendorId: number,
  error: any,
  required: bool,
};

const mapStateToProps = (state, props) => {
  const selector = goodVendorSelector();
  const requestSelector = goodVendorRequestSelector();
  return {
    isLoadingAll: requestSelector.getIsLoadingAll()(state),
    isLoadedAll: requestSelector.getIsLoadedAll()(state),
    goods: selector.getDenormalizedObjects()(state)
  };
};

export const GoodVendorSelect = connect(
  mapStateToProps,
  {
    fetchList: goodVendorObjectActions.fetchList
  }
)(GoodVendorSelectComponent);
