import { fromJS } from "immutable";
import { getPermissions } from "../../../helpers/auth";

import { initialViewState } from './../reducer';

export const initialPermissionsViewState = (constants) => {
  const f = fromJS({
    ...initialViewState(constants).toJS(),
    list: getPermissions(),
  });
  return f;
};

export const permissionsReducer = (
  state,
  action,
) => {
  switch (action.type) {
    default:
      return state;
  }
};
