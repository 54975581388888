import { get } from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import { upsertNormalizedEntities } from '../../../helpers/normalizers';
import { goodServicesSchema } from '../../../helpers/normalizers/goodService';
import {
  actions as goodServiceRequestActions,
} from '../../request/goodService/actions';
import { sortAndLimitResults } from '../../view/actions';
import {
  actions as goodServiceViewActions,
} from '../../view/goodService/actions';
import {
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';
import { goodServiceObjectActions } from './index';
import { goodServiceSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 */
export const fetchObjects = ({ queryTerm, location }, tableState) => {
  return async dispatch => {
    dispatch(goodServiceObjectActions.reset());
    dispatch(goodServiceRequestActions.setLoadAllError(null));
    dispatch(goodServiceRequestActions.setIsLoadingAll(true));
    try {
      const goodServices = await upsertObjectToAPI(
        constants.SEARCH_URL,
        {
          location,
          search: queryTerm
        },
        false,
        true,
        "data.data"
      );

      // Normalize the result and store the goodServices in redux
      const entities = get(
        normalize(goodServices, goodServicesSchema),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(goodServices, tableState, (ids, count) => {
          dispatch(goodServiceViewActions.setList(ids, count));
        })
      );
      dispatch(goodServiceRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(goodServiceRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(goodServiceRequestActions.setIsLoadingAll(false));
    }
  };
};

export const searchGoods = ({ queryTerm }, tableState) => {
  return async dispatch => {
    dispatch(goodServiceObjectActions.reset());
    dispatch(goodServiceRequestActions.setLoadAllError(null));
    dispatch(goodServiceRequestActions.setIsLoadingAll(true));
    try {
      const goodServices = await upsertObjectToAPI(
        constants.GOOD_SEARCH_URL,
        {
          search: queryTerm
        },
        false,
        true,
        "data.data"
      );

      // Normalize the result and store the goodServices in redux
      const entities = get(
        normalize(goodServices, goodServicesSchema),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(goodServices, tableState, (ids, count) => {
          dispatch(goodServiceViewActions.setList(ids, count));
        })
      );
      dispatch(goodServiceRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(goodServiceRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(goodServiceRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const goodServices = goodServiceSelector().getDenormalizedObjects()(
      getState()
    );
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(goodServices, tableState, (ids, count) => {
        dispatch(goodServiceViewActions.setList(ids, count));
      })
    );
  };
};

export const actions = {
  ...objectActions,
  fetchObjects,
  searchGoods,
  updateSortFilterLimit
};
