import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "statement";
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}admin/statements`;
export const FETCH_URL = `${process.env.REACT_APP_API_URL}admin/statement`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}admin/statement`;
export const ADD_LINE_URL = `${process.env.REACT_APP_API_URL}admin/statement/line`;
export const UPSERT_LINES_URL = `${process.env.REACT_APP_API_URL}admin/statement/saveStatement`;
export const INIT_URL = `${process.env.REACT_APP_API_URL}admin/statement/init/:statementId`;
export const REBUILD_URL = `${process.env.REACT_APP_API_URL}admin/statement/rebuild/:statementId`;
export const FETCH_STATEMENT_PDF_URL = `${process.env.REACT_APP_API_URL}admin/statement/pdf/:statementId`;
export const FETCH_SEARCH_RESULTS_URL = `${process.env.REACT_APP_API_URL}api/v2/statements/search`;
export const RECALCULATE_TAX_URL = `${process.env.REACT_APP_API_URL}admin/statement/calculate-tax`;
export const REDUCER_NAME = "statements";

// Portal
export const FETCH_PORTAL_LIST_URL = `${process.env.REACT_APP_API_URL}api/portal/statement/paginate`;
export const FETCH_PORTAL_STATEMENT_PDF_URL = `${process.env.REACT_APP_API_URL}api/portal/statement/pdf/:statementId`;

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_LIST_URL,
  FETCH_PORTAL_LIST_URL,
  FETCH_PORTAL_STATEMENT_PDF_URL,
  FETCH_URL,
  UPSERT_URL,
  REBUILD_URL,
  INIT_URL,
  ADD_LINE_URL,
  FETCH_STATEMENT_PDF_URL,
  RECALCULATE_TAX_URL,
  UPSERT_LINES_URL,
  UPSERT_PROPERTY,
};
