import { sortAndLimitResults } from "../../view/actions";
import { actions as inventoryLotViewActions } from "../../view/inventoryLot/actions";
import { makeObjectActions } from "../actions";
import { allConstants as constants } from "./constants";
import { inventoryLotSelector } from "./selectors";

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 */
export const fetchObjects = (tableState) => {};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = (tableState) => {
  return async (dispatch, getState) => {
    const inventoryLots = inventoryLotSelector().getDenormalizedObjects()(
      getState()
    );
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(inventoryLots, tableState, (ids, count) => {
        dispatch(inventoryLotViewActions.setList(ids, count));
      })
    );
  };
};

export const actions = {
  ...objectActions,
  fetchObjects,
  updateSortFilterLimit,
};
