import { any, object } from "prop-types";
import { get, isDate } from "lodash";
import React from "react";
import dayjs from "dayjs";
import cx from "classnames";

export const DatePeriodCell = React.memo(({ value, rendererProps = null }) => {
  const startDate = value
    ? isDate(get(value, "start_date"))
      ? dayjs(get(value, "start_date")).format("MM/DD/YYYY")
      : dayjs(get(value, "start_date"), "YYYY-MM-DD").format("MM/DD/YY")
    : "";
  const endDate = value
    ? isDate(get(value, "end_date"))
      ? dayjs(get(value, "end_date")).format("MM/DD/YYYY")
      : dayjs(get(value, "end_date"), "YYYY-MM-DD").format("MM/DD/YY")
    : "";
  const classes = cx({
    "date-cell pl-1": true,
    [get(rendererProps, "className")]: true,
  });
  return (
    <span className={classes}>
      {startDate} - {endDate}
    </span>
  );
});

DatePeriodCell.propTypes = {
  value: any,
  rendererProps: object,
};
