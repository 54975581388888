import React, { Component } from 'react';

import cx from 'classnames';
import {
  bool,
  func,
  string,
} from 'prop-types';
import {
  Field,
  Form as FinalForm,
} from 'react-final-form';
import { Link } from 'react-router-dom';
import {
  Button,
  Form,
  Input,
  Loader,
  Message,
} from 'semantic-ui-react';

import { WEB_ROUTE_FORGOT_PASSWORD } from '../../../../constants/routes';
import {
  composeValidators,
  email,
  required,
} from '../../../../helpers/form';

export class LoginForm extends Component {
  renderField = ({
    input,
    id,
    placeholder,
    label,
    focus,
    className,
    inputType,
    size,
    autoComplete,
    meta: { touched, error },
  }) => {
    const isValid = !error;
    className = cx({ valid: isValid }, className);
    return (
      <div className="pt-3">
        <label htmlFor={id} className="strong pb-1 d-block">
          {label}
        </label>
        <Input
          {...input}
          placeholder={placeholder}
          type={inputType}
          error={!!(touched && error)}
          size={size}
          focus={focus}
          autoComplete={autoComplete}
          className={className}
          fluid
        />
      </div>
    );
  };

  getButton = (isAuthenticating, isAuthenticating365, valid) => {
    return (
      <div className="pt-4">
        {!isAuthenticating ? (
          <Button
            type="submit"
            fluid
            size="big"
            primary
            disabled={!valid || isAuthenticating365}
          >
            Log In
          </Button>
        ) : (
          <Button disabled fluid primary size="big">
            <Loader active inverted inline size="small" />
          </Button>
        )}
      </div>
    );
  };

  render() {
    const {
      authenticationError,
      error,
      isSubmitting,
      isSubmitting365,
      onSubmit,
    } = this.props;
    return (
      <section className="login-form text-left">
        <FinalForm onSubmit={onSubmit}>
          {({ handleSubmit, valid }) => (
            <Form onSubmit={handleSubmit} className="right-status">
              <Field
                id="email"
                name="email"
                className="email"
                focus
                autoComplete="username"
                placeholder="Email Address"
                label="Email Address"
                component={this.renderField}
                inputType="email"
                size="big"
                required
                validate={composeValidators(required, email)}
              />
              <Field
                id="password"
                name="password"
                className="password big"
                autoComplete="current-password"
                label="Password"
                component={this.renderField}
                inputType="password"
                size="big"
                required
                validate={required}
              />
              <div className="pt-3 d-flex justify-flex-end">
                <Link
                  to={{
                    pathname: WEB_ROUTE_FORGOT_PASSWORD,
                  }}
                  className="pl-1 underline"
                >
                  Forgot password
                </Link>
              </div>
              {authenticationError && (
                <Message negative className="mb-0">
                  <Message.Header className="mb-1">Login Error</Message.Header>
                  <p>{authenticationError}</p>
                </Message>
              )}
              {this.getButton(isSubmitting, isSubmitting365, valid)}
            </Form>
          )}
        </FinalForm>
        {error && <Message color="red">{error}</Message>}
      </section>
    );
  }
}

LoginForm.propTypes = {
  authenticationError: string,
  error: string,
  onSubmit: func.isRequired, // Comes from redux form
  isSubmitting: bool,
  isSubmitting365: bool,
  on365Login: func,
};
