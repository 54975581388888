import React from 'react';

import { any } from 'prop-types';

import { numberWithCommas } from '../../../../helpers/util';

export const IntCell = React.memo(({ value }) => {
  return (
    <span className="int-cell">{`${numberWithCommas(
      parseInt(value, 10)
    )}`}</span>
  );
});

IntCell.propTypes = {
  value: any
};
