import { any } from "prop-types";
import React from "react";

export const NonNullCell = React.memo(({ value }) => {
  return <span className="non-null-cell pl-1">{value && value !== 'null' ? value : ""}</span>;
});

NonNullCell.propTypes = {
  value: any
};
