import { get, isArray } from 'lodash';
import { normalize } from 'normalizr';
import { toast } from 'react-toastify';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  objectsSchema,
  upsertNormalizedEntities,
  userSchema,
  usersSchema,
} from '../../../helpers/normalizers';
import { actions as userRequestActions } from '../../request/user/actions';
import { sortAndLimitResults } from '../../view/actions';
import { actions as viewUserActions } from '../../view/user/actions';
import {
  uploadFileToAPI,
  destroyObjectToAPI,
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';
import { userSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = id => {
  return async dispatch => {
    dispatch(userRequestActions.setLoadError(null));
    dispatch(userRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(`${constants.FETCH_URL}/${id}`);
      // Normalize the result and store the roles/users in redux
      const entities = get(
        normalize(result, { ...objectsSchema, user: userSchema }),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(userRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(userRequestActions.setIsLoading(false));
    }
  };
};
/**
 * Load a list of purchase orders
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(userRequestActions.setLoadAllError(null));
    dispatch(userRequestActions.setIsLoadingAll(true));
    try {
      const users = await fetchObjectsFromAPI(constants.FETCH_LIST_URL);

      // Normalize the result and store the roles/users in redux
      const entities = get(normalize(users, usersSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(users, tableState, (ids, count) => {
          dispatch(viewUserActions.setList(ids, count));
        })
      );
      dispatch(userRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(userRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(userRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Convert an object before we save it on the api
 * @param data
 */
const convertObjectForSave = data => {
  return {
    ...data,
    roles: isArray(data.roles)
      ? data.roles.map(role => {
          return role.identifier ? role.identifier : role;
        })
      : [],
    groups: isArray(data.groups)
      ? data.groups.map(group => {
          return group.identifier ? group.identifier : group;
        })
      : [],
    permissions: isArray(data.permissions)
      ? data.permissions.map(permission => {
          return permission.id ? permission.id : permission;
        })
      : []
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(userRequestActions.setSaveError(null));
    dispatch(userRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.UPSERT_URL,
        convertObjectForSave(data)
      );
      const entities = get(
        normalize([result.user], usersSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.user);
      }
    } catch (e) {
      captureException(e);
      dispatch(userRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(userRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Save a photo on customer
 * @param customerId
 * @param file
 * @returns {Function}
 */
export const upsertProfilePhoto = (id, file, onSuccess, onError) => {
  return async (dispatch, getState) => {
    dispatch(userRequestActions.setSaveError(null));
    dispatch(userRequestActions.setIsSaving(true));
    try {

      const result = await uploadFileToAPI(
        constants.UPSERT_PROFILE_PHOTO_URL,
        file, 
        {
          type: "user_profile_photo",
          id: id
        },
        'data',
        'file'
      );

      const data = get(result, 'data');
      
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(data, {
          ...objectsSchema,
          user: userSchema
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));
      dispatch(userRequestActions.setIsLoaded(id, true));

      if(get(data, 'user.photo')){
        onSuccess(data);
      } else {
        onError(data);
      }
    } catch (e) {
      captureException(e);
      dispatch(userRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(userRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Delete user (soft delete).
 * @param user
 * @returns {Function}
 */
export const destroyObject = (user, onSuccess, onError) => {
  return async dispatch => {
    dispatch(userRequestActions.setSaveError(null));
    dispatch(userRequestActions.setIsLoading(true));
    try {
      const response = await destroyObjectToAPI(
        `${constants.FETCH_URL}/${user.id}`,
        {}
      );

      if(get(response, 'data.status')){
        toast.success("User deleted successfully!");
        if(onSuccess){
          dispatch(userRequestActions.setIsLoadedAll(false));
          onSuccess();
        }
      } else {
        toast.error("There was an error deleting this user.");
        if(onError){
          onError();
        }
      }      
    } catch (e) {
      captureException(e);
      dispatch(userRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(userRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const users = userSelector().getDenormalizedObjects()(getState());
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(users, tableState, (ids, count) => {
        dispatch(viewUserActions.setList(ids, count));
      })
    );
  };
};

export const actions = {
  ...objectActions,
  updateSortFilterLimit,
  fetchObjects,
  fetchObject,
  destroyObject,
  upsertProfilePhoto,
  upsertObject,
};
