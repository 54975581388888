import React from "react";

import { get } from "lodash";
import { Card } from "semantic-ui-react";

import { PageFiller } from "../../../../../components/PageFiller";
import { object, array, bool } from "prop-types";

export const CustomerContacts = ({ customer, account, accounts, loading }) => {
  const routeLeader = get(customer, "csr.route.routeleader");
  const email = get(routeLeader, "display_email");
  return (
    <Card className={"sidebar-customer-card b-0 pb-8"}>
      <Card.Content>
        {loading || routeLeader ? <h1 className="header mt-2 fs-6">Contacts</h1> : null}
        {loading ? (
          <div className="pb-2">
            <PageFiller lines={4} />
          </div>
        ) : (
          <div>
            {routeLeader ? (
              <>
                <p>{get(routeLeader, "display_name")}</p>
                <p>{get(routeLeader, "display_title")}</p>
                <p>{get(routeLeader, "display_phone")}</p>
                {!!email && <p><a href={`mailto:${email}`}>{email}</a></p>}
              </>
            ) : null}
          </div>
        )}
      </Card.Content>
    </Card>
  );
};

CustomerContacts.propTypes = {
  account: object,
  accounts: array,
  loading: bool,
  customer: object,
};

CustomerContacts.defaultProps = {
  loading: true,
};
