import { REPORT_REDUCER_NAME } from "../constants";
import * as reportConstants from "../constants";
import { makeReportConstants } from "../util";
export const REDUCER_NAME = "financial";
export const BALANCE = "balance";
export const BREAKDOWN = "breakdown";
export const IS_LOADED_BALANCE = "isLoadedBalance";
export const SET_BALANCE = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_BALANCE`;
export const SET_IS_LOADED_BALANCE = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_IS_LOADED_BALANCE`;
export const SET_BREAKDOWN = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_BREAKDOWN`;
export const SET_IS_LOADED_BREAKDOWN = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_IS_LOADED_BREAKDOWN`;
export const START_DATE = "startDate";
export const END_DATE = "endDate";

export const SET_START_DATE = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_START_DATE`;
export const SET_END_DATE = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_END_DATE`;

export const FETCH_RESULTS_URL = `${process.env.REACT_APP_API_URL}admin/report/allpayments/:startDate/:endDate/json`;
export const FETCH_BALANCE_URL = `${process.env.REACT_APP_API_URL}admin/report/balance2/:startDate/:endDate?format=json`;
export const FETCH_BREAKDOWN_URL = `${process.env.REACT_APP_API_URL}admin/report/servicepayments/:startDate/:endDate?format=json`;
export const FETCH_RESULTS_XLS_URL = `${process.env.REACT_APP_API_URL}admin/report/allpayments/:startDate/:endDate/xlsx`;
export const FETCH_BALANCE_XLS_URL = `${process.env.REACT_APP_API_URL}admin/report/balance2/:startDate/:endDate?format=xlsx`;
export const FETCH_BREAKDOWN_XLS_URL = `${process.env.REACT_APP_API_URL}admin/report/servicepayments/:startDate/:endDate?format=xlsx`;

// Action Types
const defaultConstants = makeReportConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...reportConstants,
  REDUCER_NAME,
  FETCH_RESULTS_URL,
  FETCH_BALANCE_URL,
  SET_BALANCE,
  IS_LOADED_BALANCE,
  SET_IS_LOADED_BALANCE,
  START_DATE,
  END_DATE,
  SET_START_DATE,
  SET_END_DATE,
  BALANCE,
  BREAKDOWN,
  FETCH_BREAKDOWN_URL,
  SET_IS_LOADED_BREAKDOWN,
  SET_BREAKDOWN,
  FETCH_RESULTS_XLS_URL,
  FETCH_BALANCE_XLS_URL,
  FETCH_BREAKDOWN_XLS_URL
};
