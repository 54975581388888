import { VIEW_REDUCER_NAME } from "../constants";
import * as viewConstants from "../constants";
import { makeViewConstants } from "../util";
export const REDUCER_NAME = "purchaseOrders";

export const DATE_FILTER = "dateFilter";
export const DATE_FILTERED_LIST = "dateFilteredList";
export const DATE_FILTERED_OBJECTS = "dateFilteredObjects";

export const SET_DATE_FILTER = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_DATE_FILTER`;
export const SET_DATE_FILTERED_OBJECTS = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_DATE_FILTERED_OBJECTS`;
export const SET_DATE_FILTERED_LIST = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_DATE_FILTERED_LIST`;

// Action Types
const defaultConstants = makeViewConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...viewConstants,
  REDUCER_NAME,
  DATE_FILTERED_LIST,
  DATE_FILTER,
  SET_DATE_FILTER,
  SET_DATE_FILTERED_OBJECTS,
  DATE_FILTERED_OBJECTS,
  SET_DATE_FILTERED_LIST
};
