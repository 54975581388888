import * as links from "./links";

// Employee Dashboard Full Menu
export const MENU = [
  links.LINK_DASHBOARD,
  links.SECTION_USER_GROUPS,
  links.SECTION_CUSTOMERS,
  links.SECTION_SERVICE,
  links.LINK_VENDOR_LIST,
  links.SECTION_LOCATIONS,
  links.LINK_INVOICE_LIST,
  links.SECTION_PURCHASE_ORDERS,
  links.SECTION_INVENTORY,
  links.LINK_WORK_ORDER_LIST,
  links.LINK_REPORTS,
  links.SECTION_SETTINGS,
];

// Customer Portal Full Menu
export const PORTAL_MENU = [
  links.LINK_PORTAL_DASHBOARD,
  links.LINK_PORTAL_SERVICE,
  links.LINK_PORTAL_BILLING,
]