import { REPORT_REDUCER_NAME } from "../constants";
import * as reportConstants from "../constants";
import { makeReportConstants } from "../util";
export const REDUCER_NAME = "startsStops";

export const BREAKDOWN = "breakdown";
export const SUMMARY = "summary";
export const SUMMARY_START_DATE = "summaryStartDate";
export const SUMMARY_END_DATE = "summaryEndDate";
export const PROFIT_CENTER_SUMMARY = "summary";
export const PROFIT_CENTER_SUMMARY_START_DATE = "pcSummaryStartDate";
export const PROFIT_CENTER_SUMMARY_END_DATE = "pcSummaryEndDate";
export const SCHEDULED_SUMMARY = "scheduledSummary";

export const IS_LOADED_BREAKDOWN = "isLoadedBreakdown";
export const IS_LOADED_SUMMARY = "isLoadedSummary";
export const IS_LOADED_PROFIT_CENTER_SUMMARY = "isLoadedSummary";
export const IS_LOADED_SCHEDULED_SUMMARY = "isLoadedScheduledSummary";

export const DELETE_URL = `${process.env.REACT_APP_API_URL}admin/customer/activecancellistdel`;

export const SET_BREAKDOWN = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_BREAKDOWN`;
export const SET_IS_LOADED_BREAKDOWN = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_IS_LOADED_BREAKDOWN`;

export const SET_SUMMARY = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_SUMMARY`;
export const SET_IS_LOADED_SUMMARY = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_IS_LOADED_SUMMARY`;
export const SET_SUMMARY_START_DATE = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_SUMMARY_START_DATE`;
export const SET_SUMMARY_END_DATE = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_SUMMARY_END_DATE`;

export const SET_PROFIT_CENTER_SUMMARY = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_PROFIT_CENTER_SUMMARY`;
export const SET_IS_LOADED_PROFIT_CENTER_SUMMARY = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_IS_LOADED_PROFIT_CENTER_SUMMARY`;
export const SET_PROFIT_CENTER_SUMMARY_START_DATE = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_PROFIT_CENTER_SUMMARY_START_DATE`;
export const SET_PROFIT_CENTER_SUMMARY_END_DATE = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_PROFIT_CENTER_SUMMARY_END_DATE`;

export const SET_SCHEDULED_SUMMARY = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_SCHEDULED_SUMMARY`;
export const SET_IS_LOADED_SCHEDULED_SUMMARY = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_IS_LOADED_SCHEDULED_SUMMARY`;

export const FETCH_RESULTS_URL = `${process.env.REACT_APP_API_URL}admin/customer/activecancellist`;
export const FETCH_SCHEDULED_RESULTS_URL = `${process.env.REACT_APP_API_URL}api/v2/customer/service/scheduled/list`;
export const FETCH_BREAKDOWN_URL = `${process.env.REACT_APP_API_URL}admin/report/cust/num`;
export const FETCH_SUMMARY_URL = `${process.env.REACT_APP_API_URL}api/v2/customer/service/svccustadddel/list`;
export const FETCH_PC_SUMMARY_URL = `${process.env.REACT_APP_API_URL}api/v2/reports/active-customer/adds-discontinues`;

// Action Types
const defaultConstants = makeReportConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...reportConstants,
  DELETE_URL,
  REDUCER_NAME,
  FETCH_RESULTS_URL,
  FETCH_SCHEDULED_RESULTS_URL,
  FETCH_BREAKDOWN_URL,
  FETCH_SUMMARY_URL,
  FETCH_PC_SUMMARY_URL,
  SET_BREAKDOWN,
  IS_LOADED_BREAKDOWN,
  SET_IS_LOADED_BREAKDOWN,
  BREAKDOWN,
  SET_SUMMARY,
  IS_LOADED_SUMMARY,
  SET_IS_LOADED_SUMMARY,
  SUMMARY,
  SET_SCHEDULED_SUMMARY,
  IS_LOADED_SCHEDULED_SUMMARY,
  SET_IS_LOADED_SCHEDULED_SUMMARY,
  SCHEDULED_SUMMARY,
  SET_SUMMARY_START_DATE,
  SET_SUMMARY_END_DATE,
  SET_PROFIT_CENTER_SUMMARY_START_DATE,
  SET_PROFIT_CENTER_SUMMARY_END_DATE,
  SUMMARY_START_DATE,
  SUMMARY_END_DATE,
  SET_PROFIT_CENTER_SUMMARY,
  SET_IS_LOADED_PROFIT_CENTER_SUMMARY,
  IS_LOADED_PROFIT_CENTER_SUMMARY,
  PROFIT_CENTER_SUMMARY,
  PROFIT_CENTER_SUMMARY_START_DATE,
  PROFIT_CENTER_SUMMARY_END_DATE,
};
