import { get } from 'lodash';

import {
  filterCollection,
  sortAndLimit,
} from '../../components/DataTable/util';
import { DEFAULT_TABLE_STATE } from '../../constants';
import { makeActionCreator } from '../../helpers/actions';

/**
 * Creates a set of actions used by most of the reducers
 * @param constants
 */
export const makeViewActions = constants => {
  return {
    reset: makeActionCreator(constants.RESET),
    setList: makeActionCreator(constants.SET_LIST, "data", "count"),
    setAll: makeActionCreator(constants.SET_ALL, "data", "count"),
    setRelatedObjects: makeActionCreator(
      constants.SET_RELATED_OBJECTS,
      "relation",
      "id",
      "data"
    ),
    setRelatedFilteredList: makeActionCreator(
      constants.SET_RELATED_FILTERED_LIST,
      "relation",
      "id",
      "data",
      "count"
    )
  };
};

/**
 * For a list view, this provides local, in-memory sorting
 * @param results
 * @param tableState
 * @param action
 * @returns {Function}
 */
export const sortAndLimitResults = (results, tableState, action) => {
  tableState = tableState || DEFAULT_TABLE_STATE;
  return dispatch => {
    const filteredRows = filterCollection(
      results,
      get(tableState, "search"),
      get(tableState, "columns"),
      get(tableState, "filter")
    );

    const ids = sortAndLimit(filteredRows, tableState).map(result => {
      return result.id;
    });
    if (action) {
      action(ids, filteredRows.length);
    }
  };
};
