import { combineReducers } from "redux-immutable";
import { APP } from "./app/constants";
import appReducer from "./app/reducer";
import * as authConstants from "./auth/constants";
import { initialLocationObjectState } from "./object/location/reducer";
import {
  activityByLocationReportReducer,
  initialActivityByLocationReportState,
} from "./report/activityByLocation/reducer";
import {
  expiringCCReportReducer,
  initialExpiringCCReportState,
} from "./report/expiringCC/reducer";
import {
  updatedPaymentProfilesReportReducer,
  initialUpdatedPaymentProfilesReportState,
} from "./report/updatedPaymentProfiles/reducer";
import {
  activeCustomersReportReducer,
  initialActiveCustomersReportState,
} from "./report/activeCustomers/reducer";
import {
  arsmChartsReducer,
  initialArsmChartsState
} from "./report/arsmCharts/reducer";
import {
  arsmMyActiveRoutesReportReducer,
  initialArsmMyActiveRoutesReportState
} from "./report/arsmMyActiveRoutes/reducer";
import {
  arsmMyScheduledRoutesReportReducer,
  initialArsmMyScheduledRoutesReportState
} from "./report/arsmMyScheduledRoutes/reducer";
import {
  arsmOutstandingBedWorkReportReducer,
  initialArsmOutstandingBedWorkReportState
} from "./report/arsmOutstandingBedWork/reducer";
import {
  arsmOutstandingWorkordersReportReducer,
  initialArsmOutstandingWorkordersReportState
} from "./report/arsmOutstandingWorkorders/reducer";
import {
  arsmRouteActivitiesReportReducer,
  initialArsmRouteActivitiesReportState
} from "./report/arsmRouteActivities/reducer";
import {
  fatFingerReportReducer,
  initialFatFingerReportState,
} from "./report/fatFinger/reducer";
import {
  financialReportReducer,
  initialFinancialReportState,
} from "./report/financial/reducer";
import {
  gcActivityReportReducer,
  initialGcActivityReportState,
} from "./report/gcActivity/reducer";
import {
  initialInventoryReportState,
  inventoryReportReducer,
} from "./report/inventory/reducer";
import {
  initialInventoryAdjustmentsReportState,
  inventoryAdjustmentsReportReducer,
} from "./report/inventoryAdjustments/reducer";
import {
  initialTransferReportState,
  transferReportReducer,
} from "./report/transfer/reducer";
import {
  initialInventorySalesReportState,
  inventorySalesReportReducer,
} from "./report/inventorySales/reducer";
import {
  initialInvoiceTaxesReportState,
  invoiceTaxesReportReducer,
} from "./report/invoiceTaxes/reducer";
import {
  initialOpenItemsReportState,
  openItemsReportReducer,
} from "./report/openItems/reducer";
import {
  initialPaidRosesReportState,
  paidRosesReportReducer,
} from "./report/paidRoses/reducer";
import {
  initialProductClassReceiptsReportState,
  productClassReceiptsReportReducer,
} from "./report/productClassReceipts/reducer";
import { makeReportReducer } from "./report/reducer";
import {
  salesNonRoseReportReducer,
  initialSalesNonRoseReportState,
} from "./report/salesNonRose/reducer";
import {
  initialSalesTaxDetailReportState,
  salesTaxDetailReportReducer,
} from "./report/salesTaxDetail/reducer";
import {
  initialSalesTaxSummaryReportState,
  salesTaxSummaryReportReducer,
} from "./report/salesTaxSummary/reducer";
import {
  initialServiceAccountsReceivableReportState,
  serviceAccountsReceivableReportReducer,
} from "./report/serviceAccountsReceivable/reducer";
import {
  initialServiceCustomerReportState,
  serviceCustomerReportReducer,
} from "./report/serviceCustomer/reducer";
import {
  initialServiceRevenueSummaryReportState,
  serviceRevenueSummaryReportReducer,
} from "./report/serviceRevenueSummary/reducer";
import {
  initialServiceRevenueDetailReportState,
  serviceRevenueDetailReportReducer,
} from "./report/serviceRevenueDetail/reducer";
import {
  initialSprayMixInventoryReportState,
  sprayMixInventoryReportReducer,
} from "./report/sprayMixInventory/reducer";
import {
  initialSprayMixMonthlySummaryReportState,
  sprayMixMonthlySummaryReportReducer,
} from "./report/sprayMixMonthlySummary/reducer";
import {
  initialSprayMixMonthlyDetailsReportState,
  sprayMixMonthlyDetailsReportReducer,
} from "./report/sprayMixMonthlyDetails/reducer";
import {
  initialStartsStopsReportState,
  startsStopsReportReducer,
} from "./report/startsStops/reducer";
import {
  initialValueByProductClassReportState,
  valueByProductClassReportReducer,
} from "./report/valueByProductClass/reducer";
import { makeRequestReducer } from "./request/reducer";
import authReducer from "./auth/reducer";
import * as objectConstants from "./object/constants";
import { makeObjectReducer } from "./object/reducer";
import { goodReducer, initialGoodViewState } from "./view/good/reducer";
import {
  initialInventoryActivityViewState,
  inventoryActivityReducer,
} from "./view/inventoryActivity/reducer";
import {
  initialInventoryLotViewState,
  inventoryLotReducer,
} from "./view/inventoryLot/reducer";
import {
  initialPermissionsViewState,
  permissionsReducer,
} from "./view/permission/reducer";
import {
  initialPurchaseOrderViewState,
  purchaseOrderReducer,
} from "./view/purchaseOrder/reducer";
import { makeViewReducer } from "./view/reducer";
import * as requestConstants from "./request/constants";

import { allConstants as appointmentRequestConstants } from "./request/appointment/constants";
import { allConstants as bedActionRequestConstants } from "./request/bedAction/constants";
import { allConstants as bedInvoiceRequestConstants } from "./request/bedInvoice/constants";
import { allConstants as bedPacketRequestConstants } from "./request/bedPacket/constants";
import { allConstants as bigCommerceRequestConstants } from "./request/bigCommerce/constants";
import { allConstants as chemicalRequestConstants } from "./request/chemical/constants";
import { allConstants as customerRequestConstants } from "./request/customer/constants";
import { allConstants as cycleRequestConstants } from "./request/cycle/constants";
import { allConstants as deliveryActionRequestConstants } from "./request/deliveryAction/constants";
import { allConstants as docRequestConstants } from "./request/doc/constants";
import { allConstants as estimateRequestConstants } from "./request/estimate/constants";
import { allConstants as goodRequestConstants } from "./request/good/constants";
import { allConstants as goodTypeRequestConstants } from "./request/goodType/constants";
import { allConstants as goodServiceRequestConstants } from "./request/goodService/constants";
import { allConstants as goodVendorRequestConstants } from "./request/goodVendor/constants";
import { allConstants as groupRequestConstants } from "./request/group/constants";
import { allConstants as infoCallActionRequestConstants } from "./request/infoCallAction/constants";
import { allConstants as inventoryActivityRequestConstants } from "./request/inventoryActivity/constants";
import { allConstants as inventoryLotRequestConstants } from "./request/inventoryLot/constants";
import { allConstants as invoiceRequestConstants } from "./request/invoice/constants";
import { allConstants as locationRequestConstants } from "./request/location/constants";
import { allConstants as locationTypeRequestConstants } from "./request/locationType/constants";
import { allConstants as noteRequestConstants } from "./request/note/constants";
import { allConstants as paymentRequestConstants } from "./request/payment/constants";
import { allConstants as paymentLogRequestConstants } from "./request/paymentLog/constants";
import { allConstants as periodRequestConstants } from "./request/period/constants";
import { allConstants as permissionRequestConstants } from "./request/permission/constants";
import { allConstants as pickupActionRequestConstants } from "./request/pickupAction/constants";
import { allConstants as poRequestConstants } from "./request/purchaseOrder/constants";
import { allConstants as productClassRequestConstants } from "./request/productClass/constants";
import { allConstants as productTypeRequestConstants } from "./request/productType/constants";
import { allConstants as profitCenterRequestConstants } from "./request/profitCenter/constants";
import { allConstants as roleRequestConstants } from "./request/role/constants";
import { allConstants as routeRequestConstants } from "./request/route/constants";
import { allConstants as salesGoalRequestConstants } from "./request/salesGoal/constants";
import { allConstants as salesCallActionRequestConstants } from "./request/salesCallAction/constants";
import { allConstants as serviceRequestConstants } from "./request/service/constants";
import { allConstants as serviceLevelRequestConstants } from "./request/serviceLevel/constants";
import { allConstants as servicePeriodRequestConstants } from "./request/servicePeriod/constants";
import { allConstants as servicePriceRequestConstants } from "./request/servicePrice/constants";
import { allConstants as serviceScheduleRequestConstants } from "./request/serviceSchedule/constants";
import { allConstants as serviceVisitRequestConstants } from "./request/serviceVisit/constants";
import { allConstants as settingRequestConstants } from "./request/setting/constants";
import { allConstants as shipmentActionRequestConstants } from "./request/shipmentAction/constants";
import { allConstants as spotPlantCallRequestConstants } from "./request/spotPlantCall/constants";
import { allConstants as sprayMixContainerRequestConstants } from "./request/sprayMixContainer/constants";
import { allConstants as sprayMixRecipeRequestConstants } from "./request/sprayMixRecipe/constants";
import { allConstants as statementRequestConstants } from "./request/statement/constants";
import { allConstants as taxCodeRequestConstants } from "./request/taxCode/constants";
import { allConstants as transferRequestConstants } from "./request/transfer/constants";
import { allConstants as userRequestConstants } from "./request/user/constants";
import { allConstants as vendorRequestConstants } from "./request/vendor/constants";
import { allConstants as workOrderRequestConstants } from "./request/workOrder/constants";

import { allConstants as appointmentObjectConstants } from "./object/appointment/constants";
import { allConstants as bedActionObjectConstants } from "./object/bedAction/constants";
import { allConstants as bedInvoiceObjectConstants } from "./object/bedInvoice/constants";
import { allConstants as bedPacketObjectConstants } from "./object/bedPacket/constants";
import { allConstants as bigCommerceObjectConstants } from "./object/bigCommerce/constants";
import { allConstants as chemicalObjectConstants } from "./object/chemical/constants";
import { allConstants as customerObjectConstants } from "./object/customer/constants";
import { allConstants as cycleObjectConstants } from "./object/cycle/constants";
import { allConstants as deliveryActionObjectConstants } from "./object/deliveryAction/constants";
import { allConstants as docObjectConstants } from "./object/doc/constants";
import { allConstants as estimateObjectConstants } from "./object/estimate/constants";
import { allConstants as goodObjectConstants } from "./object/good/constants";
import { allConstants as goodTypeObjectConstants } from "./object/goodType/constants";
import { allConstants as goodServiceObjectConstants } from "./object/goodService/constants";
import { allConstants as goodVendorObjectConstants } from "./object/goodVendor/constants";
import { allConstants as groupObjectConstants } from "./object/group/constants";
import { allConstants as infoCallActionObjectConstants } from "./object/infoCallAction/constants";
import { allConstants as inventoryActivityObjectConstants } from "./object/inventoryActivity/constants";
import { allConstants as inventoryLotObjectConstants } from "./object/inventoryLot/constants";
import { allConstants as invoiceObjectConstants } from "./object/invoice/constants";
import { allConstants as locationObjectConstants } from "./object/location/constants";
import { allConstants as locationTypeObjectConstants } from "./object/locationType/constants";
import { allConstants as noteObjectConstants } from "./object/note/constants";
import { allConstants as paymentObjectConstants } from "./object/payment/constants";
import { allConstants as paymentLogObjectConstants } from "./object/paymentLog/constants";
import { allConstants as periodObjectConstants } from "./object/period/constants";
import { allConstants as permissionObjectConstants } from "./object/permission/constants";
import { allConstants as pickupActionObjectConstants } from "./object/pickupAction/constants";
import { allConstants as poObjectConstants } from "./object/purchaseOrder/constants";
import { allConstants as productClassObjectConstants } from "./object/productClass/constants";
import { allConstants as profitCenterObjectConstants } from "./object/profitCenter/constants";
import { allConstants as productTypeObjectConstants } from "./object/productType/constants";
import { allConstants as roleObjectConstants } from "./object/role/constants";
import { allConstants as routeObjectConstants } from "./object/route/constants";
import { allConstants as salesGoalObjectConstants } from "./object/salesGoal/constants";
import { allConstants as salesCallActionObjectConstants } from "./object/salesCallAction/constants";
import { allConstants as serviceObjectConstants } from "./object/service/constants";
import { allConstants as serviceLevelObjectConstants } from "./object/serviceLevel/constants";
import { allConstants as servicePeriodObjectConstants } from "./object/servicePeriod/constants";
import { allConstants as servicePriceObjectConstants } from "./object/servicePrice/constants";
import { allConstants as serviceScheduleObjectConstants } from "./object/serviceSchedule/constants";
import { allConstants as serviceVisitObjectConstants } from "./object/serviceVisit/constants";
import { allConstants as settingObjectConstants } from "./object/setting/constants";
import { allConstants as shipmentActionObjectConstants } from "./object/shipmentAction/constants";
import { allConstants as spotPlantCallObjectConstants } from "./object/spotPlantCall/constants";
import { allConstants as sprayMixContainerObjectConstants } from "./object/sprayMixContainer/constants";
import { allConstants as sprayMixRecipeObjectConstants } from "./object/sprayMixRecipe/constants";
import { allConstants as statementObjectConstants } from "./object/statement/constants";
import { allConstants as taxCodeObjectConstants } from "./object/taxCode/constants";
import { allConstants as transferObjectConstants } from "./object/transfer/constants";
import { allConstants as userObjectConstants } from "./object/user/constants";
import { allConstants as vendorObjectConstants } from "./object/vendor/constants";
import { allConstants as workOrderObjectConstants } from "./object/workOrder/constants";

import { allConstants as appointmentViewConstants } from "./view/appointment/constants";
import { allConstants as bedActionViewConstants } from "./view/bedAction/constants";
import { allConstants as bedInvoiceViewConstants } from "./view/bedInvoice/constants";
import { allConstants as bedPacketViewConstants } from "./view/bedPacket/constants";
import { allConstants as bigCommerceViewConstants } from "./view/bigCommerce/constants";
import { allConstants as chemicalViewConstants } from "./view/chemical/constants";
import { allConstants as customerViewConstants } from "./view/customer/constants";
import { allConstants as cycleViewConstants } from "./view/cycle/constants";
import { allConstants as deliveryActionViewConstants } from "./view/deliveryAction/constants";
import { allConstants as estimateViewConstants } from "./view/estimate/constants";
import { allConstants as goodViewConstants } from "./view/good/constants";
import { allConstants as goodTypeViewConstants } from "./view/goodType/constants";
import { allConstants as goodServiceViewConstants } from "./view/goodService/constants";
import { allConstants as goodVendorViewConstants } from "./view/goodVendor/constants";
import { allConstants as groupViewConstants } from "./view/group/constants";
import { allConstants as infoCallActionViewConstants } from "./view/infoCallAction/constants";
import { allConstants as inventoryActivityViewConstants } from "./view/inventoryActivity/constants";
import { allConstants as inventoryLotViewConstants } from "./view/inventoryLot/constants";
import { allConstants as invoiceViewConstants } from "./view/invoice/constants";
import { allConstants as locationViewConstants } from "./view/location/constants";
import { allConstants as paymentLogViewConstants } from "./view/paymentLog/constants";
import { allConstants as periodViewConstants } from "./view/period/constants";
import { allConstants as permissionViewConstants } from "./view/permission/constants";
import { allConstants as pickupActionViewConstants } from "./view/pickupAction/constants";
import { allConstants as productClassViewConstants } from "./view/productClass/constants";
import { allConstants as productTypeViewConstants } from "./view/productType/constants";
import { allConstants as profitCenterViewConstants } from "./view/profitCenter/constants";
import { allConstants as poViewConstants } from "./view/purchaseOrder/constants";
import { allConstants as roleViewConstants } from "./view/role/constants";
import { allConstants as routeViewConstants } from "./view/route/constants";
import { allConstants as salesGoalViewConstants } from "./view/salesGoal/constants";
import { allConstants as salesCallActionViewConstants } from "./view/salesCallAction/constants";
import { allConstants as serviceViewConstants } from "./view/service/constants";
import { allConstants as serviceLevelViewConstants } from "./view/serviceLevel/constants";
import { allConstants as settingViewConstants } from "./view/setting/constants";
import { allConstants as shipmentActionViewConstants } from "./view/shipmentAction/constants";
import { allConstants as spotPlantCallViewConstants } from "./view/spotPlantCall/constants";
import { allConstants as sprayMixContainerViewConstants } from "./view/sprayMixContainer/constants";
import { allConstants as sprayMixRecipeViewConstants } from "./view/sprayMixRecipe/constants";
import { allConstants as servicePeriodViewConstants } from "./view/servicePeriod/constants";
import { allConstants as servicePriceViewConstants } from "./view/servicePrice/constants";
import { allConstants as serviceScheduleViewConstants } from "./view/serviceSchedule/constants";
import { allConstants as serviceVisitViewConstants } from "./view/serviceVisit/constants";
import { allConstants as statementViewConstants } from "./view/statement/constants";
import { allConstants as taxCodeViewConstants } from "./view/taxCode/constants";
import { allConstants as transferViewConstants } from "./view/transfer/constants";
import { allConstants as userViewConstants } from "./view/user/constants";
import { allConstants as vendorViewConstants } from "./view/vendor/constants";
import { allConstants as workOrderViewConstants } from "./view/workOrder/constants";

import { allConstants as inventoryReportConstants } from "./report/inventory/constants";
import { allConstants as activityByLocationReportConstants } from "./report/activityByLocation/constants";
import { allConstants as commissionReportConstants } from "./report/commission/constants";
import { allConstants as fatFingerReportConstants } from "./report/fatFinger/constants";
import { allConstants as financialReportConstants } from "./report/financial/constants";
import { allConstants as expiringCCReportConstants } from "./report/expiringCC/constants";
import { allConstants as updatedPaymentProfilesReportConstants } from "./report/updatedPaymentProfiles/constants";
import { allConstants as activeCustomersReportConstants } from "./report/activeCustomers/constants";
import { allConstants as arsmChartsConstants } from "./report/arsmCharts/constants";
import { allConstants as arsmMyActiveRoutesReportConstants } from "./report/arsmMyActiveRoutes/constants";
import { allConstants as arsmMyScheduledRoutesReportConstants } from "./report/arsmMyScheduledRoutes/constants";
import { allConstants as arsmOutstandingBedWorkReportConstants } from "./report/arsmOutstandingBedWork/constants";
import { allConstants as arsmOutstandingWorkordersReportConstants } from "./report/arsmOutstandingWorkorders/constants";
import { allConstants as arsmRouteActivitiesReportConstants } from "./report/arsmRouteActivities/constants";
import { allConstants as gcActivityReportConstants } from "./report/gcActivity/constants";
import { allConstants as inventoryAdjustmentsReportConstants } from "./report/inventoryAdjustments/constants";
import { allConstants as inventorySalesReportConstants } from "./report/inventorySales/constants";
import { allConstants as invoiceTaxesReportConstants } from "./report/invoiceTaxes/constants";
import { allConstants as openItemsReportConstants } from "./report/openItems/constants";
import { allConstants as productClassReceiptsReportConstants } from "./report/productClassReceipts/constants";
import { allConstants as paidRosesReportConstants } from "./report/paidRoses/constants";
import { allConstants as reorderPointsReportConstants } from "./report/reorderPoints/constants";
import { allConstants as rosesOnHandReportConstants } from "./report/rosesOnHand/constants";
import { allConstants as salesNonRoseReportConstants } from "./report/salesNonRose/constants";
import { allConstants as salesTaxDetailReportConstants } from "./report/salesTaxDetail/constants";
import { allConstants as salesTaxSummaryReportConstants } from "./report/salesTaxSummary/constants";
import { allConstants as serviceAccountsReceivableReportConstants } from "./report/serviceAccountsReceivable/constants";
import { allConstants as serviceCustomerSummaryReportConstants } from "./report/serviceCustomer/constants";
import { allConstants as startsStopsSummaryReportConstants } from "./report/startsStops/constants";
import { allConstants as serviceRevenueSummaryReportConstants } from "./report/serviceRevenueSummary/constants";
import { allConstants as serviceRevenueDetailReportConstants } from "./report/serviceRevenueDetail/constants";
import { allConstants as sprayMixInventoryReportConstants } from "./report/sprayMixInventory/constants";
import { allConstants as sprayMixMonthlySummaryReportConstants } from "./report/sprayMixMonthlySummary/constants";
import { allConstants as sprayMixMonthlyDetailsReportConstants } from "./report/sprayMixMonthlyDetails/constants";
import { allConstants as transferReportConstants } from "./report/transfer/constants";
import { allConstants as valueByProductClassReportConstants } from "./report/valueByProductClass/constants";
import { allConstants as workOrderStatusReportConstants } from "./report/workOrderStatus/constants";

import {
  initialServiceVisitViewState,
  serviceVisitReducer,
} from "./view/serviceVisit/reducer";

import * as reportConstants from "./report/constants";
import * as viewConstants from "./view/constants";
import {
  initialWorkOrderViewState,
  workOrderReducer,
} from "./view/workOrder/reducer";

/**
 * All of our reducers in the redux store.  To save on boilerplate, we are using some "make...Reducer" methods to
 * create generic reducers
 */
export const reducers = combineReducers({
  [APP]: appReducer,
  [authConstants.REDUCER_NAME]: authReducer,
  [objectConstants.OBJECT_REDUCER_NAME]: combineReducers({
    [appointmentObjectConstants.REDUCER_NAME]: makeObjectReducer(
      appointmentObjectConstants
    ),
    [bedActionObjectConstants.REDUCER_NAME]: makeObjectReducer(
      bedActionObjectConstants
    ),
    [bedInvoiceObjectConstants.REDUCER_NAME]: makeObjectReducer(
      bedInvoiceObjectConstants
    ),
    [bedPacketObjectConstants.REDUCER_NAME]: makeObjectReducer(
      bedPacketObjectConstants
    ),
    [bigCommerceObjectConstants.REDUCER_NAME]: makeObjectReducer(
      bigCommerceObjectConstants
    ),
    [chemicalObjectConstants.REDUCER_NAME]: makeObjectReducer(
      chemicalObjectConstants
    ),
    [customerObjectConstants.REDUCER_NAME]: makeObjectReducer(
      customerObjectConstants
    ),
    [cycleObjectConstants.REDUCER_NAME]: makeObjectReducer(
      cycleObjectConstants
    ),
    [deliveryActionObjectConstants.REDUCER_NAME]: makeObjectReducer(
      deliveryActionObjectConstants
    ),
    [docObjectConstants.REDUCER_NAME]: makeObjectReducer(docObjectConstants),
    [estimateObjectConstants.REDUCER_NAME]: makeObjectReducer(
      estimateObjectConstants
    ),
    [goodObjectConstants.REDUCER_NAME]: makeObjectReducer(goodObjectConstants),
    [goodTypeObjectConstants.REDUCER_NAME]: makeObjectReducer(
      goodTypeObjectConstants
    ),
    [goodServiceObjectConstants.REDUCER_NAME]: makeObjectReducer(
      goodServiceObjectConstants
    ),
    [goodVendorObjectConstants.REDUCER_NAME]: makeObjectReducer(
      goodVendorObjectConstants
    ),
    [groupObjectConstants.REDUCER_NAME]: makeObjectReducer(
      groupObjectConstants
    ),
    [infoCallActionObjectConstants.REDUCER_NAME]: makeObjectReducer(
      infoCallActionObjectConstants
    ),
    [inventoryActivityObjectConstants.REDUCER_NAME]: makeObjectReducer(
      inventoryActivityObjectConstants
    ),
    [inventoryLotObjectConstants.REDUCER_NAME]: makeObjectReducer(
      inventoryLotObjectConstants
    ),
    [invoiceObjectConstants.REDUCER_NAME]: makeObjectReducer(
      invoiceObjectConstants
    ),
    [locationObjectConstants.REDUCER_NAME]: makeObjectReducer(
      locationObjectConstants,
      initialLocationObjectState
    ),
    [locationTypeObjectConstants.REDUCER_NAME]: makeObjectReducer(
      locationTypeObjectConstants
    ),
    [noteObjectConstants.REDUCER_NAME]: makeObjectReducer(noteObjectConstants),
    [paymentObjectConstants.REDUCER_NAME]: makeObjectReducer(
      paymentObjectConstants
    ),
    [paymentLogObjectConstants.REDUCER_NAME]: makeObjectReducer(
      paymentLogObjectConstants
    ),
    [periodObjectConstants.REDUCER_NAME]: makeObjectReducer(
      periodObjectConstants
    ),
    [permissionObjectConstants.REDUCER_NAME]: makeObjectReducer(
      permissionObjectConstants
    ),
    [pickupActionObjectConstants.REDUCER_NAME]: makeObjectReducer(
      pickupActionObjectConstants
    ),
    [poObjectConstants.REDUCER_NAME]: makeObjectReducer(poObjectConstants),
    [productClassObjectConstants.REDUCER_NAME]: makeObjectReducer(
      productClassObjectConstants
    ),
    [productTypeObjectConstants.REDUCER_NAME]: makeObjectReducer(
      productTypeObjectConstants
    ),
    [profitCenterObjectConstants.REDUCER_NAME]: makeObjectReducer(
      profitCenterObjectConstants
    ),
    [roleObjectConstants.REDUCER_NAME]: makeObjectReducer(roleObjectConstants),
    [routeObjectConstants.REDUCER_NAME]: makeObjectReducer(
      routeObjectConstants
    ),
    [salesGoalObjectConstants.REDUCER_NAME]: makeObjectReducer(
      salesGoalObjectConstants
    ),
    [salesCallActionObjectConstants.REDUCER_NAME]: makeObjectReducer(
      salesCallActionObjectConstants
    ),
    [serviceObjectConstants.REDUCER_NAME]: makeObjectReducer(
      serviceObjectConstants
    ),
    [serviceLevelObjectConstants.REDUCER_NAME]: makeObjectReducer(
      serviceLevelObjectConstants
    ),
    [servicePeriodObjectConstants.REDUCER_NAME]: makeObjectReducer(
      servicePeriodObjectConstants
    ),
    [servicePriceObjectConstants.REDUCER_NAME]: makeObjectReducer(
      servicePriceObjectConstants
    ),
    [serviceScheduleObjectConstants.REDUCER_NAME]: makeObjectReducer(
      serviceScheduleObjectConstants
    ),
    [serviceVisitObjectConstants.REDUCER_NAME]: makeObjectReducer(
      serviceVisitObjectConstants
    ),
    [settingObjectConstants.REDUCER_NAME]: makeObjectReducer(
      settingObjectConstants
    ),
    [shipmentActionObjectConstants.REDUCER_NAME]: makeObjectReducer(
      shipmentActionObjectConstants
    ),
    [spotPlantCallObjectConstants.REDUCER_NAME]: makeObjectReducer(
      spotPlantCallObjectConstants
    ),
    [sprayMixContainerObjectConstants.REDUCER_NAME]: makeObjectReducer(
      sprayMixContainerObjectConstants
    ),
    [sprayMixRecipeObjectConstants.REDUCER_NAME]: makeObjectReducer(
      sprayMixRecipeObjectConstants
    ),
    [statementObjectConstants.REDUCER_NAME]: makeObjectReducer(
      statementObjectConstants
    ),
    [taxCodeObjectConstants.REDUCER_NAME]: makeObjectReducer(
      taxCodeObjectConstants
    ),
    [transferObjectConstants.REDUCER_NAME]: makeObjectReducer(
      transferObjectConstants
    ),
    [userObjectConstants.REDUCER_NAME]: makeObjectReducer(userObjectConstants),
    [vendorObjectConstants.REDUCER_NAME]: makeObjectReducer(
      vendorObjectConstants
    ),
    [workOrderObjectConstants.REDUCER_NAME]: makeObjectReducer(
      workOrderObjectConstants
    ),
  }),
  [requestConstants.REQUEST_REDUCER_NAME]: combineReducers({
    [appointmentRequestConstants.REDUCER_NAME]: makeRequestReducer(
      appointmentRequestConstants
    ),
    [bedActionRequestConstants.REDUCER_NAME]: makeRequestReducer(
      bedActionRequestConstants
    ),
    [bedInvoiceRequestConstants.REDUCER_NAME]: makeRequestReducer(
      bedInvoiceRequestConstants
    ),
    [bedPacketRequestConstants.REDUCER_NAME]: makeRequestReducer(
      bedPacketRequestConstants
    ),
    [bigCommerceRequestConstants.REDUCER_NAME]: makeRequestReducer(
      bigCommerceRequestConstants
    ),
    [chemicalRequestConstants.REDUCER_NAME]: makeRequestReducer(
      chemicalRequestConstants
    ),
    [customerRequestConstants.REDUCER_NAME]: makeRequestReducer(
      customerRequestConstants
    ),
    [cycleRequestConstants.REDUCER_NAME]: makeRequestReducer(
      cycleRequestConstants
    ),
    [deliveryActionRequestConstants.REDUCER_NAME]: makeRequestReducer(
      deliveryActionRequestConstants
    ),
    [docRequestConstants.REDUCER_NAME]: makeRequestReducer(docRequestConstants),
    [estimateRequestConstants.REDUCER_NAME]: makeRequestReducer(
      estimateRequestConstants
    ),
    [goodRequestConstants.REDUCER_NAME]: makeRequestReducer(
      goodRequestConstants
    ),
    [goodTypeRequestConstants.REDUCER_NAME]: makeRequestReducer(
      goodTypeRequestConstants
    ),
    [goodServiceRequestConstants.REDUCER_NAME]: makeRequestReducer(
      goodServiceRequestConstants
    ),
    [goodVendorRequestConstants.REDUCER_NAME]: makeRequestReducer(
      goodVendorRequestConstants
    ),
    [groupRequestConstants.REDUCER_NAME]: makeRequestReducer(
      groupRequestConstants
    ),
    [infoCallActionRequestConstants.REDUCER_NAME]: makeRequestReducer(
      infoCallActionRequestConstants
    ),
    [inventoryActivityRequestConstants.REDUCER_NAME]: makeRequestReducer(
      inventoryActivityRequestConstants
    ),
    [inventoryLotRequestConstants.REDUCER_NAME]: makeRequestReducer(
      inventoryLotRequestConstants
    ),
    [invoiceRequestConstants.REDUCER_NAME]: makeRequestReducer(
      invoiceRequestConstants
    ),
    [locationRequestConstants.REDUCER_NAME]: makeRequestReducer(
      locationRequestConstants
    ),
    [locationTypeRequestConstants.REDUCER_NAME]: makeRequestReducer(
      locationTypeRequestConstants
    ),
    [noteRequestConstants.REDUCER_NAME]: makeRequestReducer(
      noteRequestConstants
    ),
    [paymentRequestConstants.REDUCER_NAME]: makeRequestReducer(
      paymentRequestConstants
    ),
    [paymentLogRequestConstants.REDUCER_NAME]: makeRequestReducer(
      paymentLogRequestConstants
    ),
    [periodRequestConstants.REDUCER_NAME]: makeRequestReducer(
      periodRequestConstants
    ),
    [permissionRequestConstants.REDUCER_NAME]: makeRequestReducer(
      permissionRequestConstants
    ),
    [pickupActionRequestConstants.REDUCER_NAME]: makeRequestReducer(
      pickupActionRequestConstants
    ),
    [productClassRequestConstants.REDUCER_NAME]: makeRequestReducer(
      productClassRequestConstants
    ),
    [productTypeRequestConstants.REDUCER_NAME]: makeRequestReducer(
      productTypeRequestConstants
    ),
    [profitCenterRequestConstants.REDUCER_NAME]: makeRequestReducer(
      profitCenterRequestConstants
    ),
    [poRequestConstants.REDUCER_NAME]: makeRequestReducer(poRequestConstants),
    [roleRequestConstants.REDUCER_NAME]: makeRequestReducer(
      roleRequestConstants
    ),
    [routeRequestConstants.REDUCER_NAME]: makeRequestReducer(
      routeRequestConstants
    ),
    [salesGoalRequestConstants.REDUCER_NAME]: makeRequestReducer(
      salesGoalRequestConstants
    ),
    [salesCallActionRequestConstants.REDUCER_NAME]: makeRequestReducer(
      salesCallActionRequestConstants
    ),
    [serviceRequestConstants.REDUCER_NAME]: makeRequestReducer(
      serviceRequestConstants
    ),
    [serviceLevelRequestConstants.REDUCER_NAME]: makeRequestReducer(
      serviceLevelRequestConstants
    ),
    [servicePeriodRequestConstants.REDUCER_NAME]: makeRequestReducer(
      servicePeriodRequestConstants
    ),
    [servicePriceRequestConstants.REDUCER_NAME]: makeRequestReducer(
      servicePriceRequestConstants
    ),
    [serviceScheduleRequestConstants.REDUCER_NAME]: makeRequestReducer(
      serviceScheduleRequestConstants
    ),
    [serviceVisitRequestConstants.REDUCER_NAME]: makeRequestReducer(
      serviceVisitRequestConstants
    ),
    [settingRequestConstants.REDUCER_NAME]: makeRequestReducer(
      settingRequestConstants
    ),
    [shipmentActionRequestConstants.REDUCER_NAME]: makeRequestReducer(
      shipmentActionRequestConstants
    ),
    [spotPlantCallRequestConstants.REDUCER_NAME]: makeRequestReducer(
      spotPlantCallRequestConstants
    ),
    [sprayMixContainerRequestConstants.REDUCER_NAME]: makeRequestReducer(
      sprayMixContainerRequestConstants
    ),
    [sprayMixRecipeRequestConstants.REDUCER_NAME]: makeRequestReducer(
      sprayMixRecipeRequestConstants
    ),
    [statementRequestConstants.REDUCER_NAME]: makeRequestReducer(
      statementRequestConstants
    ),
    [taxCodeRequestConstants.REDUCER_NAME]: makeRequestReducer(
      taxCodeRequestConstants
    ),
    [transferRequestConstants.REDUCER_NAME]: makeRequestReducer(
      transferRequestConstants
    ),
    [userRequestConstants.REDUCER_NAME]: makeRequestReducer(
      userRequestConstants
    ),
    [vendorRequestConstants.REDUCER_NAME]: makeRequestReducer(
      vendorRequestConstants
    ),
    [workOrderRequestConstants.REDUCER_NAME]: makeRequestReducer(
      workOrderRequestConstants
    ),
  }),
  [viewConstants.VIEW_REDUCER_NAME]: combineReducers({
    [appointmentViewConstants.REDUCER_NAME]: makeViewReducer(
      appointmentViewConstants
    ),
    [bedActionViewConstants.REDUCER_NAME]: makeViewReducer(
      bedActionViewConstants
    ),
    [bedInvoiceViewConstants.REDUCER_NAME]: makeViewReducer(
      bedInvoiceViewConstants
    ),
    [bedPacketViewConstants.REDUCER_NAME]: makeViewReducer(
      bedPacketViewConstants
    ),
    [bigCommerceViewConstants.REDUCER_NAME]: makeViewReducer(
      bigCommerceViewConstants
    ),
    [chemicalViewConstants.REDUCER_NAME]: makeViewReducer(
      chemicalViewConstants
    ),
    [customerViewConstants.REDUCER_NAME]: makeViewReducer(
      customerViewConstants
    ),
    [cycleViewConstants.REDUCER_NAME]: makeViewReducer(cycleViewConstants),
    [deliveryActionViewConstants.REDUCER_NAME]: makeViewReducer(
      deliveryActionViewConstants
    ),
    [estimateViewConstants.REDUCER_NAME]: makeViewReducer(
      estimateViewConstants
    ),
    [goodViewConstants.REDUCER_NAME]: makeViewReducer(
      goodViewConstants,
      initialGoodViewState,
      goodReducer
    ),
    [goodTypeViewConstants.REDUCER_NAME]: makeViewReducer(
      goodTypeViewConstants
    ),
    [goodServiceViewConstants.REDUCER_NAME]: makeViewReducer(
      goodServiceViewConstants
    ),
    [goodVendorViewConstants.REDUCER_NAME]: makeViewReducer(
      goodVendorViewConstants
    ),
    [groupViewConstants.REDUCER_NAME]: makeViewReducer(groupViewConstants),
    [infoCallActionViewConstants.REDUCER_NAME]: makeViewReducer(
      infoCallActionViewConstants
    ),
    [inventoryActivityViewConstants.REDUCER_NAME]: makeViewReducer(
      inventoryActivityViewConstants,
      initialInventoryActivityViewState,
      inventoryActivityReducer
    ),
    [inventoryLotViewConstants.REDUCER_NAME]: makeViewReducer(
      inventoryLotViewConstants,
      initialInventoryLotViewState,
      inventoryLotReducer
    ),
    [invoiceViewConstants.REDUCER_NAME]: makeViewReducer(invoiceViewConstants),
    [locationViewConstants.REDUCER_NAME]: makeViewReducer(
      locationViewConstants
    ),
    [paymentLogViewConstants.REDUCER_NAME]: makeViewReducer(
      paymentLogViewConstants
    ),
    [periodViewConstants.REDUCER_NAME]: makeViewReducer(periodViewConstants),
    [permissionViewConstants.REDUCER_NAME]: makeViewReducer(
      permissionViewConstants,
      initialPermissionsViewState,
      permissionsReducer
    ),
    [pickupActionViewConstants.REDUCER_NAME]: makeViewReducer(
      pickupActionViewConstants
    ),
    [productClassViewConstants.REDUCER_NAME]: makeViewReducer(
      productClassViewConstants
    ),
    [productTypeViewConstants.REDUCER_NAME]: makeViewReducer(
      productTypeViewConstants
    ),
    [profitCenterViewConstants.REDUCER_NAME]: makeViewReducer(
      profitCenterViewConstants
    ),
    [poViewConstants.REDUCER_NAME]: makeViewReducer(
      poViewConstants,
      initialPurchaseOrderViewState,
      purchaseOrderReducer
    ),
    [roleViewConstants.REDUCER_NAME]: makeViewReducer(roleViewConstants),
    [routeViewConstants.REDUCER_NAME]: makeViewReducer(routeViewConstants),
    [salesGoalViewConstants.REDUCER_NAME]: makeViewReducer(
      salesGoalViewConstants
    ),
    [salesCallActionViewConstants.REDUCER_NAME]: makeViewReducer(
      salesCallActionViewConstants
    ),
    [serviceViewConstants.REDUCER_NAME]: makeViewReducer(serviceViewConstants),
    [serviceLevelViewConstants.REDUCER_NAME]: makeViewReducer(
      serviceLevelViewConstants
    ),
    [servicePeriodViewConstants.REDUCER_NAME]: makeViewReducer(
      servicePeriodViewConstants
    ),
    [servicePriceViewConstants.REDUCER_NAME]: makeViewReducer(
      servicePriceViewConstants
    ),
    [serviceScheduleViewConstants.REDUCER_NAME]: makeViewReducer(
      serviceScheduleViewConstants
    ),
    [serviceVisitViewConstants.REDUCER_NAME]: makeViewReducer(
      serviceVisitViewConstants,
      initialServiceVisitViewState,
      serviceVisitReducer
    ),
    [settingViewConstants.REDUCER_NAME]: makeViewReducer(
      settingViewConstants
    ),
    [shipmentActionViewConstants.REDUCER_NAME]: makeViewReducer(
      shipmentActionViewConstants
    ),
    [spotPlantCallViewConstants.REDUCER_NAME]: makeViewReducer(
      spotPlantCallViewConstants
    ),
    [sprayMixContainerViewConstants.REDUCER_NAME]: makeViewReducer(
      sprayMixContainerViewConstants
    ),
    [sprayMixRecipeViewConstants.REDUCER_NAME]: makeViewReducer(
      sprayMixRecipeViewConstants
    ),
    [statementViewConstants.REDUCER_NAME]: makeViewReducer(
      statementViewConstants
    ),
    [taxCodeViewConstants.REDUCER_NAME]: makeViewReducer(
      taxCodeViewConstants
    ),
    [transferViewConstants.REDUCER_NAME]: makeViewReducer(
      transferViewConstants
    ),
    [userViewConstants.REDUCER_NAME]: makeViewReducer(userViewConstants),
    [vendorViewConstants.REDUCER_NAME]: makeViewReducer(vendorViewConstants),
    [workOrderViewConstants.REDUCER_NAME]: makeViewReducer(
      workOrderViewConstants,
      initialWorkOrderViewState,
      workOrderReducer
    ),
  }),
  [reportConstants.REPORT_REDUCER_NAME]: combineReducers({
    [activityByLocationReportConstants.REDUCER_NAME]: makeReportReducer(
      activityByLocationReportConstants,
      initialActivityByLocationReportState,
      activityByLocationReportReducer
    ),
    [commissionReportConstants.REDUCER_NAME]: makeReportReducer(
      commissionReportConstants
    ),
    [expiringCCReportConstants.REDUCER_NAME]: makeReportReducer(
      expiringCCReportConstants,
      initialExpiringCCReportState,
      expiringCCReportReducer
    ),
    [updatedPaymentProfilesReportConstants.REDUCER_NAME]: makeReportReducer(
      updatedPaymentProfilesReportConstants,
      initialUpdatedPaymentProfilesReportState,
      updatedPaymentProfilesReportReducer
    ),
    [activeCustomersReportConstants.REDUCER_NAME]: makeReportReducer(
      activeCustomersReportConstants,
      initialActiveCustomersReportState,
      activeCustomersReportReducer
    ),
    [arsmChartsConstants.REDUCER_NAME]: makeReportReducer(
      arsmChartsConstants,
      initialArsmChartsState,
      arsmChartsReducer
    ),
    [arsmMyActiveRoutesReportConstants.REDUCER_NAME]: makeReportReducer(
      arsmMyActiveRoutesReportConstants,
      initialArsmMyActiveRoutesReportState,
      arsmMyActiveRoutesReportReducer
    ),
    [arsmMyScheduledRoutesReportConstants.REDUCER_NAME]: makeReportReducer(
      arsmMyScheduledRoutesReportConstants,
      initialArsmMyScheduledRoutesReportState,
      arsmMyScheduledRoutesReportReducer
    ),
    [arsmOutstandingBedWorkReportConstants.REDUCER_NAME]: makeReportReducer(
      arsmOutstandingBedWorkReportConstants,
      initialArsmOutstandingBedWorkReportState,
      arsmOutstandingBedWorkReportReducer
    ),
    [arsmOutstandingWorkordersReportConstants.REDUCER_NAME]: makeReportReducer(
      arsmOutstandingWorkordersReportConstants,
      initialArsmOutstandingWorkordersReportState,
      arsmOutstandingWorkordersReportReducer
    ),
    [arsmRouteActivitiesReportConstants.REDUCER_NAME]: makeReportReducer(
      arsmRouteActivitiesReportConstants,
      initialArsmRouteActivitiesReportState,
      arsmRouteActivitiesReportReducer
    ),
    [fatFingerReportConstants.REDUCER_NAME]: makeReportReducer(
      fatFingerReportConstants,
      initialFatFingerReportState,
      fatFingerReportReducer
    ),
    [financialReportConstants.REDUCER_NAME]: makeReportReducer(
      financialReportConstants,
      initialFinancialReportState,
      financialReportReducer
    ),
    [gcActivityReportConstants.REDUCER_NAME]: makeReportReducer(
      gcActivityReportConstants,
      initialGcActivityReportState,
      gcActivityReportReducer
    ),
    [inventoryAdjustmentsReportConstants.REDUCER_NAME]: makeReportReducer(
      inventoryAdjustmentsReportConstants,
      initialInventoryAdjustmentsReportState,
      inventoryAdjustmentsReportReducer
    ),
    [transferReportConstants.REDUCER_NAME]: makeReportReducer(
      transferReportConstants,
      initialTransferReportState,
      transferReportReducer
    ),
    [inventoryReportConstants.REDUCER_NAME]: makeReportReducer(
      inventoryReportConstants,
      initialInventoryReportState,
      inventoryReportReducer
    ),
    [inventorySalesReportConstants.REDUCER_NAME]: makeReportReducer(
      inventorySalesReportConstants,
      initialInventorySalesReportState,
      inventorySalesReportReducer
    ),
    [invoiceTaxesReportConstants.REDUCER_NAME]: makeReportReducer(
      invoiceTaxesReportConstants,
      initialInvoiceTaxesReportState,
      invoiceTaxesReportReducer
    ),
    [openItemsReportConstants.REDUCER_NAME]: makeReportReducer(
      openItemsReportConstants,
      initialOpenItemsReportState,
      openItemsReportReducer
    ),
    [paidRosesReportConstants.REDUCER_NAME]: makeReportReducer(
      paidRosesReportConstants,
      initialPaidRosesReportState,
      paidRosesReportReducer
    ),
    [productClassReceiptsReportConstants.REDUCER_NAME]: makeReportReducer(
      productClassReceiptsReportConstants,
      initialProductClassReceiptsReportState,
      productClassReceiptsReportReducer
    ),
    [reorderPointsReportConstants.REDUCER_NAME]: makeReportReducer(
      reorderPointsReportConstants
    ),
    [rosesOnHandReportConstants.REDUCER_NAME]: makeReportReducer(
      rosesOnHandReportConstants
    ),
    [salesNonRoseReportConstants.REDUCER_NAME]: makeReportReducer(
      salesNonRoseReportConstants,
      initialSalesNonRoseReportState,
      salesNonRoseReportReducer
    ),
    [salesTaxDetailReportConstants.REDUCER_NAME]: makeReportReducer(
      salesTaxDetailReportConstants,
      initialSalesTaxDetailReportState,
      salesTaxDetailReportReducer
    ),
    [salesTaxSummaryReportConstants.REDUCER_NAME]: makeReportReducer(
      salesTaxSummaryReportConstants,
      initialSalesTaxSummaryReportState,
      salesTaxSummaryReportReducer
    ),
    [serviceAccountsReceivableReportConstants.REDUCER_NAME]: makeReportReducer(
      serviceAccountsReceivableReportConstants,
      initialServiceAccountsReceivableReportState,
      serviceAccountsReceivableReportReducer
    ),
    [serviceCustomerSummaryReportConstants.REDUCER_NAME]: makeReportReducer(
      serviceCustomerSummaryReportConstants,
      initialServiceCustomerReportState,
      serviceCustomerReportReducer
    ),
    [serviceRevenueSummaryReportConstants.REDUCER_NAME]: makeReportReducer(
      serviceRevenueSummaryReportConstants,
      initialServiceRevenueSummaryReportState,
      serviceRevenueSummaryReportReducer
    ),
    [serviceRevenueDetailReportConstants.REDUCER_NAME]: makeReportReducer(
      serviceRevenueDetailReportConstants,
      initialServiceRevenueDetailReportState,
      serviceRevenueDetailReportReducer
    ),
    [sprayMixInventoryReportConstants.REDUCER_NAME]: makeReportReducer(
      sprayMixInventoryReportConstants,
      initialSprayMixInventoryReportState,
      sprayMixInventoryReportReducer
    ),
    [sprayMixMonthlySummaryReportConstants.REDUCER_NAME]: makeReportReducer(
      sprayMixMonthlySummaryReportConstants,
      initialSprayMixMonthlySummaryReportState,
      sprayMixMonthlySummaryReportReducer
    ),
    [sprayMixMonthlyDetailsReportConstants.REDUCER_NAME]: makeReportReducer(
      sprayMixMonthlyDetailsReportConstants,
      initialSprayMixMonthlyDetailsReportState,
      sprayMixMonthlyDetailsReportReducer
    ),
    [startsStopsSummaryReportConstants.REDUCER_NAME]: makeReportReducer(
      startsStopsSummaryReportConstants,
      initialStartsStopsReportState,
      startsStopsReportReducer
    ),
    [valueByProductClassReportConstants.REDUCER_NAME]: makeReportReducer(
      valueByProductClassReportConstants,
      initialValueByProductClassReportState,
      valueByProductClassReportReducer
    ),
    [workOrderStatusReportConstants.REDUCER_NAME]: makeReportReducer(
      workOrderStatusReportConstants
    ),
  }),
});
