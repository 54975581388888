import { schema } from 'normalizr';

import {
  REDUCER_NAME as SERVICE_PRICE_REDUCER_NAME,
} from '../../store/object/servicePrice/constants';
import { serviceLevelSchema } from './serviceLevel';
import { servicePeriodSchema } from './servicePeriod';
import { serviceScheduleSchema } from './serviceSchedule';

export const servicePriceSchema = new schema.Entity(
  SERVICE_PRICE_REDUCER_NAME,
  {
    service_level: serviceLevelSchema,
    service_period: servicePeriodSchema,
    service_schedule: serviceScheduleSchema
  }
);

export const servicePricesSchema = [servicePriceSchema];
