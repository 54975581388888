import { get } from 'lodash';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';

import { objectsSchema } from '../../helpers/normalizers';
import { objectReducer } from '../object/selectors';
import * as constants from './constants';

export const viewReducer = () => (state) =>
  state.get(constants.VIEW_REDUCER_NAME);

/**
 * Creates a generic group of selectors that allow us to access our state stored in the view reducer
 * @param constants
 * @param reducer An view reducer such as users, vendors, etc...
 */
export const makeViewSelector = (constants, reducer) => {
  return {
    getNoteIds: (id) => {
      return createSelector(
        reducer(),
        (viewState, objectState) => {
          let ids = viewState.getIn([
            constants.RELATED_OBJECTS,
            constants.NOTES,
            id,
          ]);
          if (ids) {
            ids = ids.toJS();
          }
          return ids;
        }
      );
    },
    getDocIds: (id) => {
      return createSelector(
        reducer(),
        (viewState, objectState) => {
          let ids = viewState.getIn([
            constants.RELATED_OBJECTS,
            constants.DOCS,
            id,
          ]);
          if (ids) {
            ids = ids.toJS();
          }
          return ids;
        }
      );
    },
    getRelatedObjects: (relation, id, objectsKey) => {
      id = `${id}`;
      return createSelector(
        reducer(),
        objectReducer(),
        (viewState, objectState) => {
          let ids = viewState.getIn([constants.RELATED_OBJECTS, relation, id]);
          if (ids) {
            ids = ids.toJS();
            const entities = objectState.toJS();
            objectsKey = objectsKey || relation;
            return denormalize({ [objectsKey]: ids }, objectsSchema, entities)[
              [objectsKey]
            ];
          }
          return [];
        }
      );
    },
    getRelatedFilteredList: (relation, id) => {
      id = `${id}`;
      return createSelector(
        reducer(),
        objectReducer(),
        (viewState, objectState) => {
          let ids = viewState.getIn([
            constants.RELATED_FILTERED_LIST,
            relation,
            id,
            constants.LIST,
          ]);
          if (ids) {
            ids = ids.toJS();
            const entities = objectState.toJS();
            return denormalize({ [relation]: ids }, objectsSchema, entities)[
              [relation]
            ];
          }
          return [];
        }
      );
    },
    getRelatedFilteredTotal: (relation, id) =>
      createSelector(
        reducer(),
        (state) => {
          id = `${id}`;
          return (
            state.getIn([
              constants.RELATED_FILTERED_LIST,
              relation,
              id,
              constants.TOTAL,
            ]) || 0
          );
        }
      ),
    getAll: () => {
      return createSelector(
        reducer(),
        objectReducer(),
        (viewState, objectState) => {
          const ids = viewState.get(constants.ALL).toJS();
          if (ids.length > 0) {
            const entities = objectState.toJS();
            return denormalize(
              { [constants.REDUCER_NAME]: ids },
              objectsSchema,
              entities
            )[[constants.REDUCER_NAME]];
          }
          return [];
        }
      );
    },
    getList: () => {
      return createSelector(
        reducer(),
        objectReducer(),
        (viewState, objectState) => {
          const ids = viewState.get(constants.LIST).toJS();
          if (ids.length > 0) {
            const entities = objectState.toJS();
            return denormalize(
              { [constants.REDUCER_NAME]: ids },
              objectsSchema,
              entities
            )[[constants.REDUCER_NAME]];
          }
          return [];
        }
      );
    },

    getTotal: () =>
      createSelector(
        reducer(),
        (state) => {
          return state.get(constants.TOTAL) || 0;
        }
      ),
    getBalance: (id) =>
      createSelector(
        reducer(),
        objectReducer(),
        (viewState, objectState) => {
          let balance = viewState.getIn([constants.RELATED_OBJECTS, "balance"]);
          if (balance) {
            balance = balance.toJS();
          }
          return get(balance, id);
        }
      ),
  };
};
