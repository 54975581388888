import * as reportConstants from "../constants";
import { makeReportConstants } from "../util";
import { REPORT_REDUCER_NAME } from "../constants";
export const REDUCER_NAME = "arsmCharts";

export const FETCH_BILLABLE_EXTRAS_RESULTS_URL = `${process.env.REACT_APP_API_URL}api/v2/arsm/billable-extras`;
export const FETCH_ROUTE_DETAILS_RESULTS_URL = `${process.env.REACT_APP_API_URL}api/v2/arsm/route-details/:type`;
export const FETCH_OUTSTANDING_SPOTPLANT_REVENUE_URL = `${process.env.REACT_APP_API_URL}api/v2/arsm/outstanding-spotplant-revenue`;
export const FETCH_OUTSTANDING_BED_REVENUE_URL = `${process.env.REACT_APP_API_URL}api/v2/arsm/outstanding-bed-revenue`;

// Action Types
const defaultConstants = makeReportConstants(REDUCER_NAME);

const chartConstants = {
    // BILLABLE EXTRAS
    RESULTS_BILLABLE_EXTRAS: `resultsBillableExtras`,
    IS_LOADED_BILLABLE_EXTRAS: `isLoadedBillableExtras`,
    IS_LOADING_BILLABLE_EXTRAS: `isLoadingBillableExtras`,
    LOAD_ERROR_BILLABLE_EXTRAS: `loadErrorBillableExtras`,
    SET_IS_LOADED_BILLABLE_EXTRAS: `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_IS_LOADED_BILLABLE_EXTRAS`,
    SET_IS_LOADING_BILLABLE_EXTRAS: `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_IS_LOADING_BILLABLE_EXTRAS`,
    SET_LOAD_ERROR_BILLABLE_EXTRAS: `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_LOAD_ERROR_BILLABLE_EXTRAS`,
    SET_RESULTS_BILLABLE_EXTRAS: `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_RESULTS_BILLABLE_EXTRAS`,
    
    // ROUTE DETAILS
    RESULTS_ROUTE_DETAILS: `resultsRouteDetails`,
    IS_LOADED_ROUTE_DETAILS: `isLoadedRouteDetails`,
    IS_LOADING_ROUTE_DETAILS: `isLoadingRouteDetails`,
    LOAD_ERROR_ROUTE_DETAILS: `loadErrorRouteDetails`,
    SET_IS_LOADED_ROUTE_DETAILS: `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_IS_LOADED_ROUTE_DETAILS`,
    SET_IS_LOADING_ROUTE_DETAILS: `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_IS_LOADING_ROUTE_DETAILS`,
    SET_LOAD_ERROR_ROUTE_DETAILS: `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_LOAD_ERROR_ROUTE_DETAILS`,
    SET_RESULTS_ROUTE_DETAILS: `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_RESULTS_ROUTE_DETAILS`,
    
    // OUTSTANDING SPOTPLANT REVENUE
    RESULTS_OUTSTANDING_SPOTPLANT_REVENUE: `resultsOutstandingSpotplantRevenue`,
    IS_LOADED_OUTSTANDING_SPOTPLANT_REVENUE: `isLoadedOutstandingSpotPlantRevenue`,
    IS_LOADING_OUTSTANDING_SPOTPLANT_REVENUE: `isLoadingOutstandingSpotPlantRevenue`,
    LOAD_ERROR_OUTSTANDING_SPOTPLANT_REVENUE: `loadErrorOutstandingSpotPlantRevenue`,
    SET_IS_LOADED_OUTSTANDING_SPOTPLANT_REVENUE: `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_IS_LOADED_OUTSTANDING_SPOTPLANT_REVENUE`,
    SET_IS_LOADING_OUTSTANDING_SPOTPLANT_REVENUE: `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_IS_LOADING_OUTSTANDING_SPOTPLANT_REVENUE`,
    SET_LOAD_ERROR_OUTSTANDING_SPOTPLANT_REVENUE: `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_LOAD_ERROR_OUTSTANDING_SPOTPLANT_REVENUE`,
    SET_RESULTS_OUTSTANDING_SPOTPLANT_REVENUE: `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_RESULTS_OUTSTANDING_SPOTPLANT_REVENUE`,
    
    // OUTSTANDING BED REVENUE
    RESULTS_OUTSTANDING_BED_REVENUE: `resultsOutstandingBedRevenue`,
    IS_LOADED_OUTSTANDING_BED_REVENUE: `isLoadedOutstandingBedRevenue`,
    IS_LOADING_OUTSTANDING_BED_REVENUE: `isLoadingOutstandingBedRevenue`,
    LOAD_ERROR_OUTSTANDING_BED_REVENUE: `loadErrorOutstandingBedRevenue`,
    SET_IS_LOADED_OUTSTANDING_BED_REVENUE: `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_IS_LOADED_OUTSTANDING_BED_REVENUE`,
    SET_IS_LOADING_OUTSTANDING_BED_REVENUE: `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_IS_LOADING_OUTSTANDING_BED_REVENUE`,
    SET_LOAD_ERROR_OUTSTANDING_BED_REVENUE: `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_LOAD_ERROR_OUTSTANDING_BED_REVENUE`,
    SET_RESULTS_OUTSTANDING_BED_REVENUE: `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_RESULTS_OUTSTANDING_BED_REVENUE`,
}

export const allConstants = {
  ...defaultConstants,
  ...reportConstants,
  ...chartConstants,
  REDUCER_NAME,
  FETCH_BILLABLE_EXTRAS_RESULTS_URL,
  FETCH_ROUTE_DETAILS_RESULTS_URL,
  FETCH_OUTSTANDING_SPOTPLANT_REVENUE_URL,
  FETCH_OUTSTANDING_BED_REVENUE_URL,
};
