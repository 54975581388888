import { applyMiddleware, compose, createStore } from "redux";
import ReduxThunk from "redux-thunk";
import { reducers } from "./reducers";

const createStoreWithMiddleware = applyMiddleware(ReduxThunk)(createStore);

export const store = createStoreWithMiddleware(
  reducers,
  undefined,
  compose(
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f
  )
);
