import { any, object, bool } from "prop-types";
import { get } from "lodash";
import React from "react";
import cx from "classnames";
import dayjs from "dayjs";

export const DateCell = React.memo(({ value, format = "MM/DD/YY", rendererProps = null }) => {
  let date;
  if(value){
    date = dayjs(value).format(format);
  }
  const classes = cx({
    "date-cell pl-1": true,
    [get(rendererProps, "className", "")]: true,
  });
  return <span className={classes}>{date}</span>;
});

DateCell.propTypes = {
  value: any,
  rendererProps: object,
  utc: bool,
};
