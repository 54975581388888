import { fromJS } from "immutable";
import { PO_FILTER_ALL } from "../../../constants";
import { initialViewState } from "../reducer";
import { DATE_FILTER } from "./constants";

export const initialPurchaseOrderViewState = constants => {
  return fromJS({
    ...initialViewState(constants).toJS(),
    [DATE_FILTER]: PO_FILTER_ALL
  });
};

export const purchaseOrderReducer = (state, action, constants) => {
  switch (action.type) {
    case constants.SET_DATE_FILTER:
      return state.set(DATE_FILTER, action.dateFilter);

    case constants.SET_DATE_FILTERED_OBJECTS:
      return state.setIn(
        [constants.DATE_FILTERED_OBJECTS, action.dateFilter],
        fromJS(action.data)
      );

    case constants.SET_DATE_FILTERED_LIST:
      return state
        .setIn(
          [constants.DATE_FILTERED_LIST, action.dateFilter, constants.LIST],
          fromJS(action.data)
        )
        .setIn(
          [constants.DATE_FILTERED_LIST, action.dateFilter, constants.TOTAL],
          fromJS(action.count || 0)
        );
    default:
      return state;
  }
};
