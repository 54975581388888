import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';

import { MESSAGE_INSUFFICIENT_PERMISSIONS } from '../../constants/general';
import { assertViewPermission } from '../../helpers/auth';
import { permissionSelector } from '../../store/object/permission/selectors';

const ButtonGuardComponent = (props) => {
  const {
    permissions,
    history,
    guard,
    children,
    staticContext,
    location,
    match,
    onClick,
    title,
    ...buttonProps
  } = props;
  const disabled = guard && !assertViewPermission(history, permissions, guard);
  return (
    <>
      <Button
        {...buttonProps}
        title={disabled ? "Permission disabled" : title}
        className={disabled ? "not-allowed" : ""}
        onClick={
          disabled
            ? () => {
                toast.error(MESSAGE_INSUFFICIENT_PERMISSIONS);
              }
            : onClick
        }
      >
        {children}
      </Button>
    </>
  );
};

ButtonGuardComponent.defaultProps = {
  guard: null,
  history: null,
  permissions: [],
  title: "New",
};

const mapStateToProps = (state) => {
  const permissionsSelectorHandle = permissionSelector();
  return {
    permissions: permissionsSelectorHandle.getObjects()(state),
  };
};

export const ButtonGuard = connect(
  mapStateToProps,
  {}
)(withRouter(ButtonGuardComponent));
