import cx from "classnames";
import React, { useState } from "react";
import { TextArea } from "semantic-ui-react";

export const FormTextArea = ({
  input,
  id,
  placeholder,
  label,
  focus,
  className,
  disabled,
  rows,
  size,
  autoComplete,
  required,
  meta: { touched, error }
}) => {
  const [hasFocus, setHasFocus] = useState(!!focus);

  const isValid = !error;
  className = cx({ valid: isValid }, className);
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true,
    "has-focus": hasFocus,
    "has-value": !!input.value
  });
  const showError = !!(touched && error);
  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <TextArea
        {...input}
        placeholder={placeholder}
        size={size}
        disabled={disabled}
        focus={focus}
        rows={rows}
        autoComplete={autoComplete}
        className={className}
        onFocus={() => {
          setHasFocus(true);
        }}
        onBlur={() => {
          setHasFocus(false);
        }}
      />
      {showError && <span className="input-error">{error}</span>}
    </div>
  );
};
