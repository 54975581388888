import {
  LINK_BED_PACKET_LIST,
  LINK_ESTIMATE_LIST,
  LINK_CUSTOMER_LIST,
  LINK_GOOD_LIST,
  LINK_INVOICE_CREATE,
  LINK_INVOICE_LIST,
  LINK_PURCHASE_ORDER_CREATE,
  LINK_REPORT_EXPIRING_CC,
  LINK_REPORT_FINANCIAL,
  LINK_REPORT_ROSES_ON_HAND,
  LINK_REPORT_SERVICE_ACCOUNTS_RECEIVABLE,
  LINK_REPORT_SERVICE_REVENUE,
  LINK_REPORT_STARTS_STOPS,
  LINK_SERVICE_ACTIVITY_GRID,
  LINK_UTIL_REVERSE,
  LINK_UTIL_IMPORT,
  LINK_SPRAY_MIX,
} from "../../../../constants";
import IconCreateInvoice from "../../../../assets/icons/icon-create-invoice.svg";
import IconCreatePO from "../../../../assets/icons/icon-create-po.svg";
import IconBed from "../../../../assets/icons/icon-bed.svg";
import IconGoods from "../../../../assets/icons/icon-view-goods.svg";
import IconInvoices from "../../../../assets/icons/icon-view-invoices.svg";
import IconCustomers from "../../../../assets/icons/icon-view-customers.svg";
import IconReportAllPayments from "../../../../assets/icons/icon-report-all-payments.svg";
import IconReportAr from "../../../../assets/icons/icon-report-ar.svg";
import IconReportDailyPayments from "../../../../assets/icons/icon-report-daily-payments.svg";
import IconReportRevenueSummary from "../../../../assets/icons/icon-report-revenue-summary.svg";
import IconReportRoses from "../../../../assets/icons/icon-report-roses-on-hand.svg";
import IconReportStartStops from "../../../../assets/icons/icon-report-start-stops.svg";
import IconReportCC from "../../../../assets/icons/icon-report-cc.svg";
import IconSprayMixes from "../../../../assets/icons/icon-spray-mixes.svg";
import IconUtilityReverseDelete from "../../../../assets/icons/icon-utility-reverse-delete.svg";
import IconUtilityImport from "../../../../assets/icons/icon-utility-import.svg";
import IconReportServiceActivity from "../../../../assets/icons/icon-report-service-activity.svg";

export const quickLinks = [
  {
    icon: IconCreateInvoice,
    link: LINK_INVOICE_CREATE,
    title: "Create Invoice"
  },
  {
    icon: IconCreatePO,
    link: LINK_PURCHASE_ORDER_CREATE,
    title: "Create New PO"
  },
  {
    icon: IconBed,
    link: LINK_ESTIMATE_LIST,
    title: "Bed Estimates"
  },
  {
    icon: IconBed,
    link: LINK_BED_PACKET_LIST,
    title: "Bed Packets"
  },
  {
    icon: IconGoods,
    link: LINK_GOOD_LIST,
    title: "View Goods"
  },
  {
    icon: IconInvoices,
    link: LINK_INVOICE_LIST,
    title: "View Invoices"
  },
  {
    icon: IconCustomers,
    link: LINK_CUSTOMER_LIST,
    title: "View Customers"
  },
  {
    icon: IconSprayMixes,
    link: LINK_SPRAY_MIX,
    title: "Spray Mixes"
  }
];

export const reports = [
  {
    icon: IconReportStartStops,
    link: LINK_REPORT_STARTS_STOPS,
    title: "Adds",
  },
  {
    icon: IconReportDailyPayments,
    link: LINK_REPORT_FINANCIAL,
    title: "Daily",
  },
  {
    icon: IconReportAllPayments,
    link: LINK_REPORT_FINANCIAL,
    title: "All",
  },
  {
    icon: IconReportRevenueSummary,
    link: LINK_REPORT_SERVICE_REVENUE,
    title: "Revenue",
  },
  {
    icon: IconReportAr,
    link: LINK_REPORT_SERVICE_ACCOUNTS_RECEIVABLE,
    title: "Accounts",
  },
  {
    icon: IconReportRoses,
    link: LINK_REPORT_ROSES_ON_HAND,
    title: "Roses",
  },
  {
    icon: IconReportServiceActivity,
    link: LINK_SERVICE_ACTIVITY_GRID,
    title: "Activity",
  },
];

export const utilities = [
  {
    icon: IconUtilityReverseDelete,
    link: LINK_UTIL_REVERSE,
    title: "Reverse",
  },
  {
    icon: IconUtilityImport,
    link: LINK_UTIL_IMPORT,
    title: "Import",
  },
];

export const business = [
  {
    icon: IconReportCC,
    link: LINK_REPORT_EXPIRING_CC,
    title: "Expiring",
  },
  // {
  //   icon: IconReportActiveCustomers,
  //   link: LINK_REPORT_ACTIVE_CUSTOMERS,
  //   title: "Active"
  // }
];
