import dayjs from "dayjs";
import { fromJS } from "immutable";
import { initialReportState } from "../reducer";
import { DATE } from "./constants";

export const initialInventoryReportState = constants => {
  const date = dayjs().format("MM/DD/YYYY");
  return fromJS({
    ...initialReportState(constants).toJS(),
    [DATE]: date
  });
};

export const inventoryReportReducer = (state, action, constants) => {
  switch (action.type) {
    case constants.SET_DATE:
      return state.set(DATE, action.date);
    default:
      return state;
  }
};
