import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "workOrder";
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}admin/workorder/table`;
export const FETCH_LIST_V2_URL = `${process.env.REACT_APP_API_URL}api/v2/workorders`;
export const FETCH_URL = `${process.env.REACT_APP_API_URL}admin/workorder`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}admin/workorder`;
export const CREATE_URL = `${process.env.REACT_APP_API_URL}admin/workorder/create`;
export const CANCEL_WORK_ORDER_URL = `${process.env.REACT_APP_API_URL}admin/workorder/cancel/:workOrderId`;
export const SEND_NOTIFICATION_URL = `${process.env.REACT_APP_API_URL}admin/wo/notify`;
export const FETCH_DOC_LIST_URL = `${process.env.REACT_APP_API_URL}admin/doc/estimate`;
export const FETCH_NOTE_LIST_URL = `${process.env.REACT_APP_API_URL}admin/note/workorder`;
export const FETCH_NOTE_RELATED_ACTION_LIST_URL = `${process.env.REACT_APP_API_URL}admin/note/:type`;
export const FETCH_WORK_ORDER_PULL_URL = `${process.env.REACT_APP_API_URL}admin/customer/wo/:workOrderId/pdf`;
export const FETCH_BULK_WORK_ORDER_PDF_URL = `${process.env.REACT_APP_API_URL}api/v2/export/workorders/bulk`;
export const UPSERT_NOTE_URL = `${process.env.REACT_APP_API_URL}admin/note`;
export const REDUCER_NAME = "workOrders";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  CANCEL_WORK_ORDER_URL,
  REDUCER_NAME,
  FETCH_LIST_URL,
  FETCH_LIST_V2_URL,
  FETCH_URL,
  FETCH_DOC_LIST_URL,
  FETCH_NOTE_LIST_URL,
  FETCH_NOTE_RELATED_ACTION_LIST_URL,
  FETCH_WORK_ORDER_PULL_URL,
  FETCH_BULK_WORK_ORDER_PDF_URL,
  UPSERT_NOTE_URL,
  CREATE_URL,
  UPSERT_URL,
  UPSERT_PROPERTY,
  SEND_NOTIFICATION_URL,
};
