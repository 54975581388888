import React from 'react';

import { func } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import {
  Button,
  Form,
  Grid,
} from 'semantic-ui-react';

import {
  renderInput,
  required,
} from '../../../helpers/form';

export const VendorCreateModalForm = ({ onSubmit }) => {
  return (
    <section className="vendor-details-form">
      <FinalForm onSubmit={onSubmit}>
        {({ handleSubmit, valid }) => (
          <Form onSubmit={handleSubmit} className="floating-labels">
            <Grid>
              <Grid.Row className="m-0">
                <Grid.Column width={16}>
                  {renderInput({
                    name: "name",
                    label: "Name",
                    required: true,
                    validate: required
                  })}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="m-0">
                <Grid.Column>
                  {renderInput({
                    name: "address1",
                    label: "Street Address"
                  })}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="m-0">
                <Grid.Column width={8}>
                  {renderInput({
                    name: "city",
                    label: "City"
                  })}
                </Grid.Column>
                <Grid.Column width={4}>
                  {renderInput({
                    name: "state",
                    label: "State"
                  })}
                </Grid.Column>
                <Grid.Column width={4}>
                  {renderInput({
                    name: "postal_code",
                    label: "Zipcode"
                  })}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  {renderInput({
                    name: "homephone",
                    label: "Phone"
                  })}
                </Grid.Column>
                <Grid.Column width={8}>
                  <Button
                    type="submit"
                    primary
                    fluid
                    disabled={!valid}
                    className="mt-4"
                  >
                    Create Vendor
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </FinalForm>
    </section>
  );
};

VendorCreateModalForm.propTypes = {
  onSubmit: func.isRequired
};
