import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "bedInvoice";

export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}api/v2/invoices-by-type/bed`;
export const FETCH_BED_PACKETS_EXPORTS_CSV_URL = `${process.env.REACT_APP_API_URL}api/v2/export/bed-invoices?start-date=:startDate&end-date=:endDate`

export const REDUCER_NAME = "bedInvoices";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_LIST_URL,
  FETCH_BED_PACKETS_EXPORTS_CSV_URL,
};
