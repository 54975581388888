import { fromJS } from "immutable";
import { initialReportState } from "../reducer";

export const initialArsmChartsState = constants => {
  return fromJS({
    ...initialReportState(constants).toJS(),
    [constants.RESULTS_BILLABLE_EXTRAS]: {},
    [constants.RESULTS_ROUTE_DETAILS]: [],
    [constants.RESULTS_OUTSTANDING_SPOTPLANT_REVENUE]: [],
    [constants.RESULTS_OUTSTANDING_BED_REVENUE]: [],
  });
};

export const arsmChartsReducer = (state, action, constants) => {
  switch (action.type) {
    // BILLABLE EXTRAS
    case constants.SET_LOAD_ERROR_BILLABLE_EXTRAS:
      return state.set(constants.LOAD_ERROR_BILLABLE_EXTRAS, action.error);
    case constants.SET_IS_LOADED_BILLABLE_EXTRAS:
      return state.setIn([constants.IS_LOADED_BILLABLE_EXTRAS], action.isLoaded);
    case constants.SET_IS_LOADING_BILLABLE_EXTRAS:
      return state.setIn([constants.IS_LOADING_BILLABLE_EXTRAS], action.isLoading);
    case constants.SET_RESULTS_BILLABLE_EXTRAS:
      return state.setIn([constants.RESULTS_BILLABLE_EXTRAS], fromJS(action.data));

    // ROUTE DETAILS
    case constants.SET_LOAD_ERROR_ROUTE_DETAILS:
      return state.set(constants.LOAD_ERROR_ROUTE_DETAILS, action.error);
    case constants.SET_IS_LOADED_ROUTE_DETAILS:
      return state.setIn([constants.IS_LOADED_ROUTE_DETAILS], action.isLoaded);
    case constants.SET_IS_LOADING_ROUTE_DETAILS:
      return state.setIn([constants.IS_LOADING_ROUTE_DETAILS], action.isLoading);
    case constants.SET_RESULTS_ROUTE_DETAILS:
      return state.setIn([constants.RESULTS_ROUTE_DETAILS], fromJS(action.data));
      
    // OUTSTANDING SPOTPLANT REVENUE
    case constants.SET_LOAD_ERROR_OUTSTANDING_SPOTPLANT_REVENUE:
      return state.set(constants.LOAD_ERROR_OUTSTANDING_SPOTPLANT_REVENUE, action.error);
    case constants.SET_IS_LOADED_OUTSTANDING_SPOTPLANT_REVENUE:
      return state.setIn([constants.IS_LOADED_OUTSTANDING_SPOTPLANT_REVENUE], action.isLoaded);
    case constants.SET_IS_LOADING_OUTSTANDING_SPOTPLANT_REVENUE:
      return state.setIn([constants.IS_LOADING_OUTSTANDING_SPOTPLANT_REVENUE], action.isLoading);
    case constants.SET_RESULTS_OUTSTANDING_SPOTPLANT_REVENUE:
      return state.setIn([constants.RESULTS_OUTSTANDING_SPOTPLANT_REVENUE], fromJS(action.data));
      
    // OUTSTANDING BED REVENUE
    case constants.SET_LOAD_ERROR_OUTSTANDING_BED_REVENUE:
      return state.set(constants.LOAD_ERROR_OUTSTANDING_BED_REVENUE, action.error);
    case constants.SET_IS_LOADED_OUTSTANDING_BED_REVENUE:
      return state.setIn([constants.IS_LOADED_OUTSTANDING_BED_REVENUE], action.isLoaded);
    case constants.SET_IS_LOADING_OUTSTANDING_BED_REVENUE:
      return state.setIn([constants.IS_LOADING_OUTSTANDING_BED_REVENUE], action.isLoading);
    case constants.SET_RESULTS_OUTSTANDING_BED_REVENUE:
      return state.setIn([constants.RESULTS_OUTSTANDING_BED_REVENUE], fromJS(action.data));

    default:
      return state;
  }
};
