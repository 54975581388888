import React from "react";
import { get } from "lodash";
import { Button, Form, Grid, Input, Modal } from "semantic-ui-react";
import { Form as FinalForm } from "react-final-form";
import { PageFiller } from "../../../../../../components/PageFiller";

export const UpdateCustomerInfoModal = ({
  customer,
  open,
  onClose,
  onSubmit,
  isSaving,
}) => {
  if (!customer || !open) {
    return null;
  }
  return (
    <Modal open={open} onClose={onClose} centered={false} size="tiny">
      <Modal.Header className="d-flex justify-content-between">
        Edit Information
      </Modal.Header>
      <Modal.Content>
        {!isSaving ? <FinalForm initialValues={customer} onSubmit={onSubmit}>
          {({ handleSubmit, valid, form }) => {
            const formUpdate = (event, data) => {
              form.change(data.name, data.value.trim());
            };

            return (
              <Form onSubmit={handleSubmit} className="floating-labels">
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <Grid columns="equal">
                        <Grid.Row>
                          <Grid.Column
                            width={16}
                            className="bb-1 mx-2 pb-0 pl-0"
                          >
                            <span className="text-light-gray">First name:</span>
                            <Input
                              fluid
                              transparent
                              className="py-1"
                              name="first_name"
                              required
                              value={get(form.getState().values, "first_name") || ''}
                              onChange={formUpdate}
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column
                            width={16}
                            className="bb-1 mx-2 pb-0 pl-0"
                          >
                            <span className="text-light-gray">Last name:</span>
                            <Input
                              fluid
                              transparent
                              className="py-1"
                              name="last_name"
                              required
                              value={get(form.getState().values, "last_name") || ''}
                              onChange={formUpdate}
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column
                            width={16}
                            className="bb-1 mx-2 pb-0 pl-0"
                          >
                            <span className="text-light-gray">Email:</span>
                            <Input
                              fluid
                              transparent
                              className="py-1"
                              name="email"
                              required
                              value={get(form.getState().values, "email") || ''}
                              onChange={formUpdate}
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column
                            width={16}
                            className="bb-1 mx-2 pb-0 pl-0"
                          >
                            <span className="text-light-gray">Home phone:</span>
                            <Input
                              fluid
                              transparent
                              className="py-1"
                              name="homephone"
                              value={get(form.getState().values, "homephone") || ''}
                              onChange={formUpdate}
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column
                            width={16}
                            className="bb-1 mx-2 pb-0 pl-0"
                          >
                            <span className="text-light-gray">Cell phone:</span>
                            <Input
                              fluid
                              transparent
                              className="py-1"
                              name="cellphone"
                              value={get(form.getState().values, "cellphone") || ''}
                              onChange={formUpdate}
                            />
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column>
                            <Modal.Actions className="text-lg-right">
                              <Button type="button" onClick={onClose}>
                                Cancel
                              </Button>
                              <Button color="green" disabled={false} loading={isSaving}>
                                Save
                              </Button>
                            </Modal.Actions>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            );
          }}
        </FinalForm> : <div>
          <PageFiller lines={7} className='pb-2' />
          <p className='text-lg-center'>Updating information...</p>
        </div>}
      </Modal.Content>
    </Modal>
  );
};

UpdateCustomerInfoModal.defaultProps = {
  customer: null,
};
