import "./index.scss";
import "react-toastify/dist/ReactToastify.css";
import "semantic-ui-css/semantic.min.css";

import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import { PrivatePortalPeekRoute } from "./components/PrivatePortalPeekRoute";
import { PrivatePortalRoute } from "./components/PrivatePortalRoute";
import { PrivateRoute } from "./components/PrivateRoute";
import {
  WEB_ROUTE_DEFAULT,
  WEB_ROUTE_FORBIDDEN,
  WEB_ROUTE_FORGOT_PASSWORD,
  WEB_ROUTE_LOGIN,
  WEB_ROUTE_LOGOUT,
  WEB_ROUTE_NOT_FOUND,
  WEB_ROUTE_PORTAL_DEFAULT,
  WEB_ROUTE_PORTAL_PEEK_DEFAULT,
  WEB_ROUTE_PORTAL_REGISTER,
  WEB_ROUTE_RESET_PASSWORD,
} from "./constants/routes";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store";
import { App } from "./views/Admin";
import {
  CustomerRegister,
  Forbidden,
  ForgotPassword,
  Login,
  Logout,
  ResetPassword,
  PageNotFound,
} from "./views/Auth";
import { PortalApp } from "./views/Portal";

var Coralogix = require("coralogix-logger");
// global confing for application name, private key, subsystem name 
const config = new Coralogix.LoggerConfig({
    applicationName: process.env.REACT_APP_CORALOGIX_APP_NAME,
    privateKey: process.env.REACT_APP_CORALOGIX_PRIVATE_KEY,
    subsystemName: process.env.REACT_APP_CORALOGIX_SUBSYSTEM,
});
if(process.env.REACT_APP_CORALOGIX_PRIVATE_KEY){
  Coralogix.CoralogixLogger.configure(config);
}

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <div id="app-wrapper">
        <Switch>
          <PrivatePortalPeekRoute path={WEB_ROUTE_PORTAL_PEEK_DEFAULT} component={PortalApp} />
          <PrivatePortalRoute path={WEB_ROUTE_PORTAL_DEFAULT} component={PortalApp} />
          <PrivateRoute path={WEB_ROUTE_DEFAULT} component={App} />
          <Route path={WEB_ROUTE_LOGIN} component={Login} />
          <Route
            path={WEB_ROUTE_FORGOT_PASSWORD}
            exact
            component={ForgotPassword}
          />
          <Route
            path={WEB_ROUTE_RESET_PASSWORD}
            exact
            component={ResetPassword}
          />
          <Route
            path={WEB_ROUTE_PORTAL_REGISTER}
            exact
            component={CustomerRegister}
          />
          <Route
            path={"/"}
            exact
            component={() => <Redirect to={WEB_ROUTE_LOGIN} />}
          />
          <Route path={WEB_ROUTE_FORBIDDEN} exact component={Forbidden} />
          <Route path={WEB_ROUTE_LOGOUT} exact component={Logout} />
          <Route path={WEB_ROUTE_NOT_FOUND} component={PageNotFound} />
          <Redirect to={WEB_ROUTE_NOT_FOUND} />
        </Switch>
      </div>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
