import React from "react";
import { string } from "prop-types";

/**
 * A component used to render the header of a page such as a form or report
 */
export const PageHeader = React.memo(({ title, children, subtitle }) => {
  return (
    <header className="page-header d-flex justify-space-between mb-2">
      <div className="title" style={{flexBasis: '60%'}}>
        {title && (
          <h1 className="text-gray55">
            <span>{title}</span>
          </h1>
        )}
        {!!subtitle && 
          <div className="text-gray55" style={{fontWeight: 200, fontSize: '1.35rem'}}>
            <span>{subtitle}</span>
          </div>
        }
      </div>
      <div className="actions">{children}</div>
    </header>
  );
});

PageHeader.propTypes = {
  title: string
};
