export const REQUEST_REDUCER_NAME = "request";

// State Variables
export const IS_LOADED = "isLoaded";
export const IS_AUTHORIZE_LOADED = "isAuthorizeLoaded";
export const IS_AUTHORIZE_LOADING = "isAuthorizeLoading";
export const IS_LOADING = "isLoading";
export const IS_LOADING_ALL = "isLoadingAll";
export const LOAD_ALL_ERROR = "loadAllError";
export const IS_LOADED_ALL = "isLoadedAll";
export const IS_SAVING = "isSaving";
export const IS_CALCULATING = "isCalculating";
export const LOAD_ERROR = "loadError";
export const LOAD_AUTHORIZE_ERROR = "loadAuthorizeError";
export const SAVE_ERROR = "saveError";
export const CALCULATE_ERROR = "calculateError";
export const SMS_ERROR = "smsError";
export const MAIL_ERROR = "mailError";
export const IS_QUEUE_STATUS_LOADED = "isQueueStatusLoaded";
export const IS_QUEUE_STATUS_LOADING = "isQueueStatusLoading";

// Action Types
export const makeRequestConstants = (reducerName) => {
  return {
    RESET: `store/${REQUEST_REDUCER_NAME}/${reducerName}/RESET`,
    SET_IS_LOADING: `store/${REQUEST_REDUCER_NAME}/${reducerName}/SET_IS_LOADING`,
    SET_IS_AUTHORIZE_LOADING: `store/${REQUEST_REDUCER_NAME}/${reducerName}/SET_IS_AUTHORIZE_LOADING`,
    SET_IS_LOADING_ALL: `store/${REQUEST_REDUCER_NAME}/${reducerName}/SET_IS_LOADING_ALL`,
    SET_IS_LOADED: `store/${REQUEST_REDUCER_NAME}/${reducerName}/SET_IS_LOADED`,
    SET_IS_AUTHORIZE_LOADED: `store/${REQUEST_REDUCER_NAME}/${reducerName}/SET_IS_AUTHORIZE_LOADED`,
    SET_IS_LOADED_ALL: `store/${REQUEST_REDUCER_NAME}/${reducerName}/SET_IS_LOADED_ALL`,
    SET_LOAD_ERROR: `store/${REQUEST_REDUCER_NAME}/${reducerName}/SET_LOAD_ERROR`,
    SET_AUTHORIZE_LOAD_ERROR: `store/${REQUEST_REDUCER_NAME}/${reducerName}/SET_AUTHORIZE_LOAD_ERROR`,
    SET_LOAD_ALL_ERROR: `store/${REQUEST_REDUCER_NAME}/${reducerName}/SET_LOAD_ALL_ERROR`,
    SET_IS_SAVING: `store/${REQUEST_REDUCER_NAME}/${reducerName}/SET_IS_SAVING`,
    SET_IS_CALCULATING: `store/${REQUEST_REDUCER_NAME}/${reducerName}/SET_IS_CALCULATING`,
    SET_SAVE_ERROR: `store/${REQUEST_REDUCER_NAME}/${reducerName}/SET_SAVE_ERROR`,
    SET_CALCULATE_ERROR: `store/${REQUEST_REDUCER_NAME}/${reducerName}/SET_CALCULATE_ERROR`,
    SET_SMS_ERROR: `store/${REQUEST_REDUCER_NAME}/${reducerName}/SET_SMS_ERROR`,
    SET_MAIL_ERROR: `store/${REQUEST_REDUCER_NAME}/${reducerName}/SET_MAIL_ERROR`,
    SET_IS_QUEUE_STATUS_LOADING: `store/${REQUEST_REDUCER_NAME}/${reducerName}/SET_IS_QUEUE_STATUS_LOADING`,
    SET_IS_QUEUE_STATUS_LOADED: `store/${REQUEST_REDUCER_NAME}/${reducerName}/SET_IS_QUEUE_STATUS_LOADED`,
  };
};
