import {
  get,
} from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  upsertNormalizedEntities,
  taxCodesSchema,
} from '../../../helpers/normalizers';
import { actions as taxCodeRequestActions } from '../../request/taxCode/actions';
import { sortAndLimitResults } from '../../view/actions';
import { actions as taxCodeViewActions } from '../../view/taxCode/actions';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
} from '../actions';
import { allConstants as constants } from './constants';
import { taxCodeSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of purchase orders
 * @param tableState An optional configuration used to filter the results
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(taxCodeRequestActions.setLoadAllError(null));
    dispatch(taxCodeRequestActions.setIsLoadingAll(true));
    try {
      const taxCodes = await fetchObjectsFromAPI(constants.FETCH_LIST_URL, 'data');
      // Normalize the result and store the taxCodes in redux
      const entities = get(normalize(taxCodes, taxCodesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(taxCodes, tableState, (ids, count) => {
          dispatch(taxCodeViewActions.setList(ids, count));
        })
      );
      dispatch(taxCodeRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(taxCodeRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(taxCodeRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const taxCodes = taxCodeSelector().getDenormalizedObjects()(getState());
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(taxCodes, tableState, (ids, count) => {
        dispatch(taxCodeViewActions.setList(ids, count));
      })
    );
  };
};

export const actions = {
  ...objectActions,
  fetchObjects,
  updateSortFilterLimit
};
