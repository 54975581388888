import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { assertViewPermission, getPermissions } from "../../helpers/auth";
import * as actions from "../../store/auth/actions";
import * as permissionActions from "../../store/object/permission/actions";
import { isRefreshing } from "../../store/auth/selectors";
import { Error } from "../../views/Admin/Error";
import { Loading } from "../../views/Admin/Loading";

const AuthProviderContext = React.createContext();

const AuthProviderComponent = ({ children, refreshUser, fetchPermissions, permissions, logout }) => {
  const history = useHistory();
  const [refreshed, setRefreshed] = React.useState(false);

  React.useEffect(() => {
    if (!refreshed) {
      // Must update session.
      refreshUser(async () => {
        // After session updated, Must fetch all permissions.
        await fetchPermissions();
        setRefreshed(true);
      }, e => {
        console.error('Failed!', e)
        toast.error("Authentication error!");
        setTimeout(() => {
          logout();
        }, 3000);
      })
    }
  }, [fetchPermissions, refreshUser, refreshed, logout]);

  if(!refreshed) {
    return <Loading />;
  }

  if (!assertViewPermission(history, permissions)) {
    return <Error statusCode={403} />;
  }

  return (
    <AuthProviderContext.Provider>
      {children}
    </AuthProviderContext.Provider>
  );
}

const mapStateToProps = (state) => {
  return {
    isRefreshing: isRefreshing()(state),
    permissions: getPermissions(),
  };
};

export const AuthProvider = connect(
  mapStateToProps,
  {
    ...actions,
    fetchPermissions: permissionActions.fetchObjects,
  }
)(AuthProviderComponent);
