import { schema } from 'normalizr';

import {
  REDUCER_NAME as STATEMENT_REDUCER_NAME,
} from '../../store/object/statement/constants';
import { customerSchema } from './customer';
import { periodSchema } from './period';

export const statementSchema = new schema.Entity(STATEMENT_REDUCER_NAME, {
  customer: customerSchema,
  period: periodSchema
});

export const statementsSchema = [statementSchema];
