import {
  get,
  isString,
  omit,
} from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  bedActionSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import {
  actions as bedActionRequestActions,
} from '../../request/bedAction/actions';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';

const objectActions = makeObjectActions(constants);

/**
 * Retrieve a single object form the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = id => {
  return async dispatch => {
    dispatch(bedActionRequestActions.setLoadError(null));
    dispatch(bedActionRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        `${constants.FETCH_URL}/${id}`,
        "data"
      );
      // Normalize the result and store the roles/users in redux
      const entities = get(
        normalize(result.bedaction, bedActionSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
      dispatch(bedActionRequestActions.setIsLoaded(id, true));
    } catch (e) {
      captureException(e);
      dispatch(bedActionRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(bedActionRequestActions.setIsLoading(false));
    }
  };
};
/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(bedActionRequestActions.setSaveError(null));
    dispatch(bedActionRequestActions.setIsSaving(true));
    try {
      /** Clean up temporary ids we had set **/
      const cleanLines = [];
      const doneLines = [];
      data.lines.forEach(line => {
        if (isString(line.id)) {
          cleanLines.push(omit(line, "id"));
        } else {
          cleanLines.push(line);
        }
        if(line.installed || line.rejected){
          doneLines.push(line);
        }
      });
      if(doneLines.length === data.lines.length){
        data.savingStatus = 'closed';
      }

      const result = await upsertObjectToAPI(
        data.id ? `${constants.UPSERT_URL}/${data.id}` : constants.UPSERT_URL,
        { bedaction: { ...data, lines: cleanLines } },
        false,
        true
      );
      const entities = get(
        normalize(result.bedaction, bedActionSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.bedaction);
      }
    } catch (e) {
      captureException(e);
      dispatch(bedActionRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(bedActionRequestActions.setIsSaving(false));
    }
  };
};

export const actions = {
  ...objectActions,
  fetchObject,
  upsertObject
};
