import {
  get,
  uniq,
} from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  goodVendorsSchema,
  notesSchema,
  objectsSchema,
  upsertNormalizedEntities,
  vendorSchema,
  vendorsSchema,
} from '../../../helpers/normalizers';
import {
  actions as goodVendorRequestActions,
} from '../../request/goodVendor/actions';
import { actions as noteRequestActions } from '../../request/note/actions';
import { actions as vendorRequestActions } from '../../request/vendor/actions';
import { sortAndLimitResults } from '../../view/actions';
import { actions as vendorViewActions } from '../../view/vendor/actions';
import { vendorViewSelector } from '../../view/vendor/selectors';
import {
  fetchNotesFromAPI,
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';
import { vendorSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Retrieve a single object form the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = id => {
  return async dispatch => {
    dispatch(vendorRequestActions.setLoadError(null));
    dispatch(vendorRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(`${constants.FETCH_URL}/${id}`);
      // Normalize the result and store the roles/users in redux
      const entities = get(
        normalize(result, { ...objectsSchema, vendor: vendorSchema }),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
      dispatch(vendorRequestActions.setIsLoaded(id, true));
    } catch (e) {
      captureException(e);
      dispatch(vendorRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(vendorRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Load a list of purchase orders
 * @param tableState An optional configuration used to filter the results
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(vendorRequestActions.setLoadAllError(null));
    dispatch(vendorRequestActions.setIsLoadingAll(true));
    try {
      const vendors = await fetchObjectsFromAPI(constants.FETCH_LIST_URL);
      // Normalize the result and store the vendors in redux
      const entities = get(normalize(vendors, vendorsSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(vendors, tableState, (ids, count) => {
          dispatch(vendorViewActions.setList(ids, count));
        })
      );
      dispatch(vendorRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(vendorRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(vendorRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Fetch the notes from the api and store their result in our reducer
 * @param id
 * @returns {Function}
 */
export const fetchNotes = id => {
  return async dispatch => {
    dispatch(noteRequestActions.setLoadAllError(null));
    dispatch(noteRequestActions.setIsLoadingAll(true));
    try {
      const notes = await dispatch(
        fetchNotesFromAPI(id, `${constants.FETCH_NOTE_LIST_URL}/${id}`)
      );

      dispatch(
        vendorViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          id,
          notes.map(note => {
            return note.id;
          })
        )
      );
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save a note on the api
 * @param parentId
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertNote = (parentId, data, onSuccess) => {
  return async (dispatch, getState) => {
    dispatch(noteRequestActions.setSaveError(null));
    dispatch(noteRequestActions.setIsSaving(true));
    try {
      data.kind = data.kind || "vendor";

      const result = await upsertObjectToAPI(constants.UPSERT_NOTE_URL, data);
      const entities = get(normalize([result], notesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      const notes = vendorViewSelector().getNoteIds(parentId)(getState());
      const updatedIds = uniq([...notes, result.id]);
      dispatch(
        vendorViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          parentId,
          updatedIds
        )
      );

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Fetch the items from the api and store their result in our reducer
 * @param id
 * @returns {Function}
 */
export const fetchItems = id => {
  return async dispatch => {
    dispatch(goodVendorRequestActions.setLoadAllError(null));
    dispatch(goodVendorRequestActions.setIsLoadingAll(true));
    try {
      const items = await fetchObjectsFromAPI(
        `${constants.FETCH_ITEM_LIST_URL}/${id}`
      );
      const entities = get(normalize(items, goodVendorsSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));
      dispatch(
        vendorViewActions.setRelatedObjects(
          constants.RELATION_GOOD_VENDORS,
          id,
          items.map(item => {
            return item.id;
          })
        )
      );
    } catch (e) {
      captureException(e);
      dispatch(goodVendorRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(goodVendorRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const vendors = vendorSelector().getDenormalizedObjects()(getState());
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(vendors, tableState, (ids, count) => {
        dispatch(vendorViewActions.setList(ids, count));
      })
    );
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(vendorRequestActions.setSaveError(null));
    dispatch(vendorRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(constants.UPSERT_URL, data);
      const entities = get(
        normalize([result.vendor], vendorsSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.vendor);
      }
    } catch (e) {
      captureException(e);
      dispatch(vendorRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(vendorRequestActions.setIsSaving(false));
    }
  };
};

export const actions = {
  ...objectActions,
  fetchItems,
  fetchNotes,
  fetchObject,
  fetchObjects,
  upsertNote,
  upsertObject,
  updateSortFilterLimit
};
