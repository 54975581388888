import { any } from "prop-types";
import React from "react";

export const YesNoCell = React.memo(({ value }) => {
  return <span className="yes-no-cell pl-1">{!!value ? "Y" : "N"}</span>;
});

YesNoCell.propTypes = {
  value: any
};
