import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "goodService";
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}admin/vendor/goods`;
export const FETCH_URL = `${process.env.REACT_APP_API_URL}admin/vendor/goods`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}admin/vendor/goods`;
export const SEARCH_URL = `${process.env.REACT_APP_API_URL}admin/invoice/itemsearch`;
export const GOOD_SEARCH_URL = `${process.env.REACT_APP_API_URL}admin/invoice/goodsearch`;
export const REDUCER_NAME = "goodServices";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_LIST_URL,
  FETCH_URL,
  SEARCH_URL,
  GOOD_SEARCH_URL,
  UPSERT_URL,
  UPSERT_PROPERTY
};
