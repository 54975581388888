import React, { Component } from "react";
import { func, number } from "prop-types";

class CustomLimit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      limit: props.limit
    };
  }

  updateLimit = () => {
    if (this.state.limit > this.props.maxLimit) {
      this.setState({ limit: this.props.maxLimit });
      return this.props.onLimitChange(this.props.maxLimit);
    }
    this.props.onLimitChange(this.state.limit);
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.setState({ active: false });
      this.updateLimit();
    }
    let reg = new RegExp("^\\d+$");
    if (!reg.test(event.key)) {
      event.preventDefault();
    }
  };

  handleInputChange = event => {
    this.setState({
      limit: Number(event.currentTarget.value)
    });
  };

  handleOnFocus = event => {
    event.currentTarget.select();
  };

  handleOnBlur = event => {
    this.setState({ active: false });
    this.updateLimit();
  };

  render() {
    if (this.state.active) {
      return (
        <input
          ref={input => (this.limitInput = input)}
          id="change-limit-input"
          title={`Maximum of ${this.props.maxLimit} lines`}
          className={"custom-limit-input"}
          type="text"
          value={this.state.limit}
          onChange={this.handleInputChange}
          onKeyPress={this.handleKeyPress}
          onFocus={this.handleOnFocus}
          onBlur={this.handleOnBlur}
        />
      );
    } else {
      return (
        <span
          title={`Maximum of ${this.props.maxLimit} lines`}
          className="count mr-1 text-center c-blue pointer limit-width"
          onClick={e => {
            this.setState({ active: true }, () => {
              if (this.limitInput) {
                this.limitInput.focus();
              }
            });
          }}
        >
          <u>{this.props.limit}</u>
        </span>
      );
    }
  }
}

CustomLimit.propTypes = {
  limit: number,
  maxLimit: number,
  onLimitChange: func.isRequired
};

export default CustomLimit;
