import React from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { Container, Dimmer, Grid, Loader } from "semantic-ui-react";
import { PageHeader, ErrorBoundaryComponent, PageAlert } from "../../../components";
// Redux
import { actions as salesActions } from "../../../store/object/salesGoal/actions";
import { salesGoalRequestSelector } from "../../../store/request/salesGoal/selectors";
// Cards
import { AppointmentsCard } from "./Cards/AppointmentsCard";
import { BedInvoicesCard } from "./Cards/BedInvoicesCard";
import { BedPacketsCard } from "./Cards/BedPacketsCard";
import { CommissionsEarnedCard } from "./Cards/CommissionsEarnedCard";
import { PipelineFunnelCard } from "./Cards/PipelineFunnelCard";
import { SignedProposalsCard } from "./Cards/SignedProposalsCard";
// Modals
import { SetGoalsModal } from "./SetGoalsModal";
import { getUser } from './../../../helpers/auth';
import dayjs from "dayjs";
import {
  makeSignedProposalsGraphData,
  makeSignedProposalsMonthToDateData,
  makeSignedProposalsYearToDateData,
  makePipelineFunnelBigStatsData,
  makePipelineFunnelTotalsData,
  makeBedPacketsCardData,
  makeCommissionsEarnedCardGraphData,
  makeCommissionsEarnedCardData,
  makeBedInvoicesCardData,
} from "./helpers";

class SalesDashboardComponent extends ErrorBoundaryComponent {
  constructor(props){
    super(props)
    this.state = {
      data: {},
      setGoalsModalIsOpen: false
    };
  }
  componentDidMount() {
    const { isLoadedAll, isLoadingAll, fetchObjects } = this.props;
    if (!isLoadedAll && !isLoadingAll) {
      fetchObjects().then(data => {
        this.setState({data: data});
      })
    }
  }
  render() {
    const { isLoadingAll, loadAllError, user, fetchObjects } = this.props;
    const { data, setGoalsModalIsOpen } = this.state;
    // Signed Proposals Card Data
    const signedProposalsGraphData = makeSignedProposalsGraphData(data);
    const signedProposalsMonthToDateData = makeSignedProposalsMonthToDateData(data);
    const signedProposalsYearToDateData = makeSignedProposalsYearToDateData(data);
    // Pipeline Funnel Card Data
    const pipelineFunnelBigStatsData = makePipelineFunnelBigStatsData(data);
    const pipelineFunnelTotalsData = makePipelineFunnelTotalsData(data);
    const pipelineFunnelUpdatedAt = dayjs(get(data, 'pipeline_funnel.updated_at', {})).format("MM/DD/YYYY hh:mm");
    // Appointments Card Data
    const appointmentsData = get(data, 'appointments');
    // Bed Packets Card Data
    const bedPacketsCardData = makeBedPacketsCardData(data);
    // Commissions Earned Card Data
    const commissionsEarnedCardGraphData = makeCommissionsEarnedCardGraphData(data);
    const commissionsEarnedCardData = makeCommissionsEarnedCardData(data);
    // Bed Invoices Card Data
    const bedInvoicesCardData = makeBedInvoicesCardData(data);
    return (
      <Container
        className="view-dashboard view-list view pb-5"
        as="article"
        fluid
      >
        <PageHeader title="Sales Dashboard" />
        <Dimmer active={isLoadingAll} inverted>
          <Loader size="large">Refreshing Sales Dashboard...</Loader>
        </Dimmer>
        <PageAlert title="Error" message={loadAllError} />
        <section className="sales-dashboard-container mt-5">
          <Grid>
            <Grid.Row stretched>
              <Grid.Column width={8}>
                <SignedProposalsCard
                  graphData={signedProposalsGraphData}
                  monthToDateData={signedProposalsMonthToDateData}
                  yearToDateData={signedProposalsYearToDateData}
                  openSetGoalsModal={() => this.setState({setGoalsModalIsOpen: true})}
                  user={user}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <PipelineFunnelCard
                  bigStats={pipelineFunnelBigStatsData}
                  totals={pipelineFunnelTotalsData}
                  updatedAt={pipelineFunnelUpdatedAt}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched>
              <Grid.Column width={8}>
                <AppointmentsCard
                  data={appointmentsData}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <BedPacketsCard data={bedPacketsCardData} totalBedPackets={get(data, 'bed_packets.total')}  />
                <BedInvoicesCard data={bedInvoicesCardData} />
                {!isLoadingAll && <CommissionsEarnedCard
                  graphData={commissionsEarnedCardGraphData}
                  detailsData={commissionsEarnedCardData}
                />}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </section>
        <SetGoalsModal 
          isOpen={setGoalsModalIsOpen}
          typeFilters={['proposals_signed', 'adds_cw']} 
          onClose={() => {
            this.setState({setGoalsModalIsOpen: false});
            fetchObjects().then(data => {
              this.setState({data: data});
            })
          }} />
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  const requestSelector = salesGoalRequestSelector();
  return {
    isLoadedAll: requestSelector.getIsLoadedAll()(state),
    isLoadingAll: requestSelector.getIsLoadingAll()(state),
    loadAllError: requestSelector.getLoadAllError()(state),
    user: getUser(),
  };
};
export const SalesDashboard = connect(
  mapStateToProps,
  { ...salesActions }
)(SalesDashboardComponent);
