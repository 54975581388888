import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "paymentLog";
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}admin/autopaymentlog`;
export const FETCH_URL = `${process.env.REACT_APP_API_URL}admin/autopaymentlog`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}admin/autopaymentlog`;
export const REDUCER_NAME = "paymentLogs";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_LIST_URL,
  UPSERT_URL,
  FETCH_URL,
  UPSERT_PROPERTY
};
