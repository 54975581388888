import { isNull } from "lodash";
import dayjs from "dayjs";
import { DEFAULT_USER_PHOTO_URL } from "../constants";

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * Simple timeout for forcing async/await to wait
 * @param ms
 * @returns {Promise<any>}
 */
export const awaitTimeout = ms => new Promise(res => setTimeout(res, ms));

export const numberWithCommas = (x, decimals = 0) => {
  if (x) {
    return Number(x)
      .toFixed(decimals)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return 0;
  }
};

export const formatCurrency = (x, includeCurrency = true) => {
  if (!x && !x === 0) {
    return null;
  }
  return new Intl.NumberFormat(
    "en-US",
    includeCurrency
      ? {
          style: "currency",
          currency: "USD"
        }
      : { minimumFractionDigits: 2, maximumFractionDigits: 2 }
  ).format(x);
};

/**
 * Remove trailing zeros from a number
 * @param {float} qty 
 * @returns string
 */
export const formatQuantity = (qty) => {
  const decimalPlaces = qty % 1 === 0 ? 0 : Math.max(1 - Math.floor(Math.log10(qty)), 0);
  return qty.toFixed(decimalPlaces);
}

export const round = (value, decimals) => {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
};
export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const trackMixpanel = (name, data) => {
  try {
    if (window.mixpanel && window.mixpanel.track) {
      window.mixpanel.track(name, data);
    }
  } catch (e) {
    console.error(e);
  }
};

export const identifyMixpanel = user => {
  try {
    if (window.mixpanel) {
      if (window.mixpanel.identify) {
        window.mixpanel.identify(user.userId);
      }
      if (window.mixpanel.people) {
        window.mixpanel.people.set_once("email", user.email);
      }
    }
  } catch (e) {
    console.error(e);
  }
};

export const getRandomArbitrary = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const pad = (n, width, z) => {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

/**
 * Merge the updated object into the list
 * @param object
 * @param objects
 * @returns {*}
 */
export const mergeChangesToObjects = (object, objects) => {
  let existingIndex = objects.findIndex(c => {
    return c.id === object.id;
  });
  if (existingIndex >= 0) {
    objects[existingIndex] = object;
  } else {
    objects.push(object);
  }
  return objects;
};

/**
 * Remove object from a list
 * @param object
 * @param objects
 * @returns {Array}
 */
export const removeObjectFromList = (object, objects) => {
  return objects.filter(a => {
    return a.id !== object.id;
  });
};

export const moneyRound = num => {
  return Math.ceil(num * 100) / 100;
};

export const imageUri = path => {
  if(isNull(path)){
    return DEFAULT_USER_PHOTO_URL;
  }
  return path;
}
export const isEmail = str => {
  let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(str); 
}

export const isValidCreditCard = num => {
  let cardPattern = /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/;
  return cardPattern.test(num);
}

export const convertDateTime = (datetime, format = "MM/DD/YYYY, hh:mm A", tz = "America/New_York") => {
  return dayjs.utc(datetime).tz(tz).format(format);
}
