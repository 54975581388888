import { createSelector } from "reselect";
import * as baseConstants from "./constants";

export const requestReducer = () => (state) =>
  state.get(baseConstants.REQUEST_REDUCER_NAME);

/**
 * Creates a generic group of selectors that allow us to access our state stored in the view reducer
 * @param constants
 * @param reducer An view reducer such as users, vendors, etc...
 */
export const makeRequestSelector = (constants, reducer) => {
  return {
    getIsLoading: () =>
      createSelector(
        reducer(),
        (state) => {
          return !!state.get(constants.IS_LOADING);
        }
      ),
    getIsQueueStatusLoaded: () =>
      createSelector(
        reducer(),
        (state) => {
          return !!state.get(constants.IS_QUEUE_STATUS_LOADED);
        }
      ),
    getIsQueueStatusLoading: () =>
      createSelector(
        reducer(),
        (state) => {
          return !!state.get(constants.IS_QUEUE_STATUS_LOADING);
        }
      ),
    getIsAuthorizeLoading: () =>
      createSelector(
        reducer(),
        (state) => {
          return !!state.get(constants.IS_AUTHORIZE_LOADING);
        }
      ),
    getIsLoadingAll: () =>
      createSelector(
        reducer(),
        (state) => {
          return !!state.get(constants.IS_LOADING_ALL);
        }
      ),
    getIsSaving: () =>
      createSelector(
        reducer(),
        (state) => {
          return !!state.get(constants.IS_SAVING);
        }
      ),
    getIsCalculating: () =>
      createSelector(
        reducer(),
        (state) => {
          return !!state.get(constants.IS_CALCULATING);
        }
      ),
    getLoadError: () =>
      createSelector(
        reducer(),
        (state) => {
          return state.get(constants.LOAD_ERROR);
        }
      ),
    getAuthorizeLoadError: () =>
      createSelector(
        reducer(),
        (state) => {
          return state.get(constants.LOAD_AUTHORIZE_ERROR);
        }
      ),
    getLoadAllError: () =>
      createSelector(
        reducer(),
        (state) => {
          return state.get(constants.LOAD_ALL_ERROR);
        }
      ),
    getSaveError: () =>
      createSelector(
        reducer(),
        (state) => {
          return state.get(constants.SAVE_ERROR);
        }
      ),
    getCalculateError: () =>
      createSelector(
        reducer(),
        (state) => {
          return state.get(constants.CALCULATE_ERROR);
        }
      ),
    getSmsError: () =>
      createSelector(
        reducer(),
        (state) => {
          return state.get(constants.SMS_ERROR);
        }
      ),
    getMailError: () =>
      createSelector(
        reducer(),
        (state) => {
          return state.get(constants.MAIL_ERROR);
        }
      ),
    getIsLoaded: (id) =>
      createSelector(
        reducer(),
        (state) => {
          return !!state.getIn([constants.IS_LOADED, id]);
        }
      ),    
    getIsAuthorizeLoaded: () =>
      createSelector(
        reducer(),
        (state) => {
          return !!state.get(constants.IS_AUTHORIZE_LOADED);
        }
      ),
    getIsLoadedAll: () =>
      createSelector(
        reducer(),
        (state) => {
          return !!state.get(constants.IS_LOADED_ALL);
        }
      ),
  };
};
