import * as viewConstants from "../constants";
import { makeViewConstants } from "../util";
export const REDUCER_NAME = "vendors";
const SET_GOODS = `store/${viewConstants.VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_GOODS`;

// Action Types
const defaultConstants = makeViewConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...viewConstants,
  SET_GOODS,
  REDUCER_NAME
};
