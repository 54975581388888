import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "setting";
export const FETCH_URL = `${process.env.REACT_APP_API_URL}api/v2/settings/dashboard/:setting`;
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}api/v2/settings/dashboard`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}api/v2/settings/dashboard`;

export const REDUCER_NAME = "settings";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);

export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_URL,
  FETCH_LIST_URL,
  UPSERT_URL,
  UPSERT_PROPERTY,
};
