import React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const GoogleMapsProvider = ({ children }) => {
  return (
  <Wrapper apiKey={googleMapsApiKey} libraries={["places"]}>
    {children}
  </Wrapper>
  );
}