import React, { Component, Suspense } from "react";

import { func, object } from "prop-types";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { createStructuredSelector } from "reselect";
import { Loader } from "semantic-ui-react";
import { get } from "lodash";

import * as constants from "../../../constants";
import { goToLogin, getPortalAccounts } from "../../../helpers/auth";
import * as actions from "../../../store/auth/actions";
import * as components from "./components";
import { PortalFooter } from "./Footer";
import { PortalHeader } from "./Header";
import { PortalSidebar } from "./Sidebar";

// import { ConfirmationModal } from "../ConfirmationModal";
const Coralogix = require("coralogix-logger");

class PortalAppComponent extends Component {
  
  logger;
  
  componentDidMount(){
    this.logger = new Coralogix.CoralogixLogger("App");
  }
  
  logout = () => {
    const { history, logout } = this.props;
    logout();
    goToLogin(history);
  };

  componentDidCatch(error, errorInfo) {
    const message = get(error, 'message', 'Error');
    const componentStack = get(errorInfo, 'componentStack');
    const text = `${message}\nStacktrace:\n\t${componentStack}`
    const log = new Coralogix.Log({
        severity:Coralogix.Severity.error,
        text: text,
    });
    // send log to coralogix
    if(this.logger){
      this.logger.addLog(log);
    }
  }

  render() {
    const { history, account } = this.props;
    return (
      <div className="view-portal d-flex justify-flex-start">
        <div className="view-portal-container">
          <ToastContainer />
          <PortalHeader onLogout={this.logout} />
          <main className="view-portal-main position-relative">
            <PortalSidebar
              history={history}
              account={account}
              accounts={getPortalAccounts()}
            />
            <div className="portal-dynamic-view with-sidebar">
              <Suspense fallback={<Loader active>Loading</Loader>}>
                <Switch>
                  <Route
                    path={constants.WEB_ROUTE_PORTAL_DEFAULT}
                    exact
                    component={components.HomeComponent}
                  />
                  <Route
                    path={constants.WEB_ROUTE_PORTAL_BILLING}
                    exact
                    component={components.BillingComponent}
                  />
                  <Route
                    path={constants.WEB_ROUTE_PORTAL_SERVICE}
                    exact
                    component={components.ServiceComponent}
                  />
                </Switch>
              </Suspense>
            </div>
          </main>
          <PortalFooter />
        </div>
        {/* <AccountSelectModal account={account} /> */}
        {/* <ConfirmationModal /> */}
      </div>
    );
  }
}

PortalAppComponent.propTypes = {
  logout: func.isRequired,
  account: object,
};
PortalAppComponent.displayName = "Customer Portal";
PortalAppComponent.defaultProps = {
  account: null,
};

const mapStateToProps = createStructuredSelector({});

export const PortalApp = withRouter(
  connect(
    mapStateToProps,
    { ...actions }
  )(PortalAppComponent)
);
