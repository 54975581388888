import React from "react";
import { get } from "lodash";

const ExitImpersonateModeMessage = ({ user, userToLoginAs }) => {
  return (
    <div>
      IMPERSONATE MODE
      <br />
      {get(user, "name")} {"->"} {get(userToLoginAs, "name")}
      <br />
      Close this box to cancel
    </div>
  );
};

export { ExitImpersonateModeMessage };
