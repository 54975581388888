import { fromJS } from "immutable";

/**
 * A default initial state for all of the reducers that sit in the store under `view`
 * @param constants
 * @returns {any}
 */
export const initialViewState = constants =>
  fromJS({
    [constants.LIST]: {},
    [constants.ALL]: {},
    [constants.RELATED_OBJECTS]: {},
    [constants.RELATED_FILTERED_LIST]: {},
    [constants.TOTAL]: 0
  });

/**
 * Creates a generic reducer for all of our object list views
 * @param constants
 * @param initialState
 * @returns {Function}
 */
export const makeViewReducer = (constants, initialState, fallBackReducer) => {
  initialState = initialState || initialViewState;

  return (state = initialState(constants), action) => {
    switch (action.type) {
      case constants.SET_LIST:
        return state
          .set(constants.LIST, fromJS(action.data))
          .set(constants.TOTAL, action.count || 0);

      case constants.SET_ALL:
        return state
          .set(constants.ALL, fromJS(action.data))
          .set(constants.TOTAL, action.count || 0);
        
      case constants.SET_RELATED_OBJECTS:
        return state.setIn(
          [constants.RELATED_OBJECTS, action.relation, `${action.id}`],
          fromJS(action.data)
        );

      case constants.SET_RELATED_FILTERED_LIST:
        return state
          .setIn(
            [
              constants.RELATED_FILTERED_LIST,
              action.relation,
              `${action.id}`,
              constants.LIST
            ],
            fromJS(action.data)
          )
          .setIn(
            [
              constants.RELATED_FILTERED_LIST,
              action.relation,
              `${action.id}`,
              constants.TOTAL
            ],
            fromJS(action.count || 0)
          );

      case constants.RESET:
        return initialState(constants);

      default:
        return fallBackReducer
          ? fallBackReducer(state, action, constants)
          : state;
    }
  };
};
