import React from "react";

import cx from "classnames";
import { object, array } from "prop-types";

import { CustomerSidebar } from "../CustomerSidebar";
import { PortalMenu } from "../Menu";

export const PortalSidebar = ({ account, accounts }) => {
  const sidebarIsOpen = true;
  const classes = cx({
    "portal-sidebar": true,
    open: sidebarIsOpen,
  });
  return (
    <div className={classes}>
      <aside className="customer-column ml-1">
        <CustomerSidebar account={account} accounts={accounts} />
      </aside>
      <nav className="">
        <div className="button-container">
          <PortalMenu />
        </div>
      </nav>
    </div>
  );
};

PortalSidebar.propTypes = {
  account: object,
  accounts: array,
};
