import React from 'react';

import {
  Link,
  withRouter,
} from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

const PortalFooterComponent = () => {
    return <footer className={'portal-footer text-center'}>        
        <p>
            <Icon name="question circle outline" />
            If you have any questions, or are experiencing technical issues, please contact us: 
            <Link
                to={'mailto:support@quickrose.com'}
                onClick={() => {
                    window.open('mailto:support@quickrose.com')
                }}
                className="ml-1 underline">
                Email Support
            </Link>
        </p>
    </footer>
}

export const PortalFooter = withRouter(PortalFooterComponent);