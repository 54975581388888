import { get, isEmpty } from "lodash";
import { bool, func, object, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Dimmer, Loader } from "semantic-ui-react";
import { PageAlert, PageHeader, ErrorBoundaryComponent } from "../../../../components";
import { actions as objectActions } from "../../../../store/object/group/actions";
import { groupSelector } from "../../../../store/object/group/selectors";
import { groupRequestSelector } from "../../../../store/request/group/selectors";
import { GroupDetailsForm } from "./Form";

class GroupDetailsComponent extends ErrorBoundaryComponent {
  componentDidMount() {
    const { isLoadingAll, group, fetchObjects } = this.props;
    const groupId = get(this.props, "match.params.id", null);

    // Load all groups if we can't get the current group
    if (groupId && isEmpty(group) && !isLoadingAll) {
      fetchObjects();
    }
  }

  handleSubmit = values => {
    const { upsertObject } = this.props;
    upsertObject(values, () => {
      toast.success("Group updated successfully!");
    });
  };

  render() {
    const { group, history, isLoadingAll, isSaving, saveError } = this.props;
    return (
      <Container className="view-group-details view-details view" as="article">
        <Dimmer active={isLoadingAll || isSaving} inverted>
          <Loader size="large">
            {isLoadingAll ? "Loading" : "Submitting"}
          </Loader>
        </Dimmer>
        <PageHeader title="Group" history={history} />
        <PageAlert title="Error" message={saveError} />
        <GroupDetailsForm group={group} onSubmit={this.handleSubmit} />
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => {
  const requestSelector = groupRequestSelector();
  const objectSelector = groupSelector();
  const groupId = get(props, "match.params.id", {});
  return {
    isLoadedAll: requestSelector.getIsLoadedAll()(state),
    isLoadingAll: requestSelector.getIsLoadingAll()(state),
    isSaving: requestSelector.getIsSaving()(state),
    group: objectSelector.getDenormalizedObject(groupId)(state),
    saveError: requestSelector.getSaveError()(state)
  };
};

GroupDetailsComponent.displayName = "GroupDetails";
GroupDetailsComponent.propTypes = {
  isLoadingAll: bool,
  isSaving: bool,
  group: object,
  fetchObjects: func.isRequired,
  history: object,
  saveError: string,
  upsertObject: func.isRequired
};

export const GroupDetails = connect(
  mapStateToProps,
  { ...objectActions }
)(withRouter(GroupDetailsComponent));
