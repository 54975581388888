import React from "react";
import { get, filter } from "lodash";
import { GoogleMap } from './../../../../../../../components/Map/GoogleMap';

// Customer mood faces
const moodMap = {
  1: require('../../../../../../../assets/icons/angry-face.svg'),
  2: require('../../../../../../../assets/icons/frowning-face.svg'),
  3: require('../../../../../../../assets/icons/neutral-face.svg'),
  4: require('../../../../../../../assets/icons/slightly-smiling-face.svg'),
  5: require('../../../../../../../assets/icons/beaming-face-with-smiling-eyes.svg'),
};

const makeInfoWindow = function(address) {
  const customerName = get(address, 'customerName');
  const currentMood = get(address, 'customerCurrentMood');
  const customerRoute = get(address, 'customerRoute');
  return `<div style="display: grid;">
  <div style="font-weight: 900; padding: 0; font-size: 1.2rem; display: flex; justify-content: space-between; align-items: center;">
    <div style="display: inline-table;">
      <img src="${moodMap[currentMood]}" style="height: 25;  width: 25;" />
      <span style="margin-left: 0.5rem">${customerName}</span>
    </div>
    <div>
      <span style="background-color: #DEDEDF; border-radius: 4px; text-align: center; padding: 5px; font-size: 15px">${customerRoute}</span>
    </div>
  </div>
  <div>${get(address, 'address1')},<br />${get(address, 'city')}, ${get(address, 'state')} ${get(address, 'postal_code')}</div></div>`
}

export const Map = ({goToListView, data}) => {

  // Map markers with info window showing customer data.
  const markers = filter(data.map(address => {
    return get(address, 'lat') && get(address, 'lon') ? {
      lat: parseFloat(get(address, 'lat')),
      lng: parseFloat(get(address, 'lon')),
      info: makeInfoWindow(address),
    } : null;
  }), (marker) => !!marker);

  let center = undefined;
  if (markers.length === 1) {
    center = {
      lat: parseFloat(get(markers[0], 'lat')),
      lng: parseFloat(get(markers[0], 'lng')),
    }
  }

  return (
    <div className="h-100">
      <div className='pull-right pointer p-2' onClick={goToListView}>List View</div>
      <div className='appointments-map'>
        <GoogleMap markers={markers} center={center} zoom={10} fitBounds={true} />
      </div>
    </div>
  )
}
