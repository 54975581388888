import { schema } from 'normalizr';

import {
  REDUCER_NAME as NOTE_REDUCER_NAME,
} from '../../store/object/note/constants';
import { VENDOR_REDUCER_NAME } from '../../store/object/vendor';
import { notesSchema } from './note';

export const vendorSchema = new schema.Entity(VENDOR_REDUCER_NAME, {
  [NOTE_REDUCER_NAME]: notesSchema
});

export const vendorsSchema = [vendorSchema];
