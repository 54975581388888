import axios from 'axios';
import { request } from 'gaxios';
import {
  get,
  isEmpty,
} from 'lodash';
import { normalize } from 'normalizr';
import qs from 'qs';

import { captureException } from '../../helpers/error';
import {
  EXPORT_TYPE_EXCEL,
  EXPORT_TYPE_PDF,
} from '../../constants';
import { makeActionCreator } from '../../helpers/actions';
import { getToken, getCurrentAccount } from '../../helpers/auth';
import { getErrorMessage } from '../../helpers/error';
import {
  exportCopy,
  exportPdf,
  exportXls,
} from '../../helpers/export';
import {
  notesSchema,
  upsertNormalizedEntities,
} from '../../helpers/normalizers';
import { actions as noteRequestActions } from '../request/note/actions';
import { isArray } from 'lodash';
import { toast } from 'react-toastify';

export const version = process.env.REACT_APP_VERSION;

/**
 * Creates a set of actions used by most of the reducers
 * @param constants
 */
export const makeObjectActions = (constants) => {
  return {
    exportObjects: (type, objects, tableState) => {
      return (dispatch) => {
        switch (type) {
          case EXPORT_TYPE_PDF:
            exportPdf(objects, tableState);
            break;
          case EXPORT_TYPE_EXCEL:
            exportXls(objects, tableState);
            break;
          default:
            exportCopy(objects, tableState);
            break;
        }
      };
    },
    reset: makeActionCreator(constants.RESET),
    upsert: makeActionCreator(constants.UPSERT, "data"),
  };
};

/**
 * Load notes for the given object
 * @param id
 * @param url
 * @returns {Function}
 */
export const fetchNotesFromAPI = (id, url) => {
  return async (dispatch) => {
    dispatch(noteRequestActions.setLoadError(null));
    try {
      const notes = await fetchObjectsFromAPI(url, "data.data.notes");
      // Normalize the result and store the workOrders in redux
      const entities = get(normalize(notes, notesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));
      return notes;
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
    }
  };
};

export const fetchFileFromAPI = (
  url,
  fileName,
  download = true,
  type = null,
  forcePost = false,
  data = null,
) => {
  return request({
    url: url,
    method: forcePost ? "POST" : "GET",
    data: data,
    responseType: "blob",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-authtoken": getToken(),
      "x-account": getCurrentAccount(),
      "x-version": version,
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(
      new Blob([response.data], type && { type: type })
    );
    if (!download) {
      return window.open(url, "_blank");
    }
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  })
  .catch((e) => {
    console.error(e)
    throw getErrorMessage(e)
  });
};

export const fetchFileStreamFromAPI = (
  url,
  fileName,
  type = null,
  returnBlob = false
) => {
  return request({
    url: url,
    method: "GET",
    responseType: "blob",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-authtoken": getToken(),
      "x-account": getCurrentAccount(),
    },
  }).then((response) => {
    const blob = new Blob([response.data], type && { type: type });
    const url = window.URL.createObjectURL(blob);
    if(returnBlob){
      return blob;
    }
    return url;
  })
  .catch((e) => {
    console.error(e)
  });
};

/**
 * Fetches a list of objects from a url
 * @param url The full url that we are fetching from
 * @param resultPath The lodash path to the results
 * @returns {Promise<void>}
 */
export const fetchObjectsFromAPI = async (url, resultPath = "data.data") => {
  const response = await request({
    method: "GET",
    url,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-authtoken": getToken(),
      "x-account": getCurrentAccount(),
      "x-version": version,
    },
  });
  return get(response, resultPath, []);
};

/**
 * Fetches a list of objects from a url
 * @param url The full url that we are fetching from
 * @param tableState The pagination/sort of the table
 * @param resultPath The lodash path to the results
 * @param extraParams Any additional parameters to add to the query
 * @returns {Promise<void>}
 */
export const fetchObjectsFromAPIV2 = async (
  url,
  tableState,
  resultPath = "data.data",
  extraParams = {}
) => {
  const paramObject = {
    page: tableState.page,
    per_page: tableState.limit,
    sort_col: get(tableState, "sortOrder.column"),
    sort_asc: !!get(tableState, "sortOrder.isAscending"),
    search: tableState.search,
  };
  // Add any date filters to the params
  if (tableState.startDate) {
    paramObject.start_date = tableState.startDate;
  }
  if (tableState.endDate) {
    paramObject.end_date = tableState.endDate;
  }
  if(tableState.filter){
    Object.keys(tableState.filter).forEach((value) => {
      paramObject[value] = tableState.filter[value];
    })
  }
  // Add any extra params to the query
  Object.keys(extraParams).forEach((value) => {
    paramObject[value] = extraParams[value];
  });
  const params = qs.stringify(paramObject);

  const response = await request({
    method: "GET",
    url: `${url}?${params}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-authtoken": getToken(),
      "x-account": getCurrentAccount(),
      "x-version": version,
    },
  });
  return get(response, resultPath, []);
};

/**
 * POST or PUT an object to the api
 * @param url
 * @param data
 * @param appendId
 * @param forcePost
 * @param path
 * @param id
 * @returns {Promise<*>}
 */
export const upsertObjectToAPI = async (
  url,
  data,
  appendId = true,
  forcePost = false,
  path = "data",
  id = null,
  signal = null
) => {
  if (!data) {
    return
  }

  id = id || data.id;
  const method = id && !forcePost ? "PUT" : "POST";
  url = id && appendId ? `${url}/${id}` : url;
  const response = await request({
    method: method,
    url,
    data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-authtoken": getToken(),
      "x-account": getCurrentAccount(),
      "x-version": version,
    },
    signal
  });
  // if warnings, toast them
  if(response.data.hasOwnProperty('warnings')){
    get(response, 'data.warnings', []).forEach(warning => {
      console.warn(warning)
      toast.error(warning, { position: 'bottom-right'})
    })
  }
  // if messages, toast them
  if(response.data.hasOwnProperty('messages')){
    get(response, 'data.messages', []).forEach(messages => {
      toast.info(messages, { position: 'bottom-right'})
    })
  }
  return get(response, path, {});
};

/**
 * DELETE an object to the api
 * @param url
 * @param data
 * @param path
 * @returns {Promise<*>}
 */
export const destroyObjectToAPI = async (
  url,
  data,
  path = "data",
) => {
  const response = await request({
    method: "DELETE",
    url,
    data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-authtoken": getToken(),
      "x-account": getCurrentAccount(),
      "x-version": version,
    }
  });
  return get(response, path, {});
};

/**
 * Simple put to the api
 * @param url
 * @param data
 * @param path
 * @returns {Promise<*>}
 */
export const putObjectToAPI = async (url, data, path = "data") => {
  if (data) {
    const response = await request({
      method: "PUT",
      url,
      data,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-authtoken": getToken(),
        "x-account": getCurrentAccount(),
        "x-version": version,
      },
    });
    return get(response, path, {});
  }
};

export const uploadFileToAPI = async (url, file, data, path = "data", fieldName = "doc[]") => {
  const formData = new FormData();
  if(isArray(file)){
    for (var i = 0; i < file.length; i++) {
      formData.append(fieldName, file[i]);
    }
  } else {
    formData.append(fieldName, file);
  }
  if (!isEmpty(data)) {
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
  }
  const response = await axios.post(url, formData, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      "x-authtoken": getToken(),
      "x-account": getCurrentAccount(),
      "x-version": version,
    },
  });
  return get(response, path, {});
};
