import { any } from "prop-types";
import React from "react";

export const PrintedYesNoCell = React.memo(({ row }) => {
  return <span className="on-off-cell pl-1">{row.bulkprinted || row.lastprinted  ? "Y" : "N"}</span>;
});

PrintedYesNoCell.propTypes = {
  row: any
};
