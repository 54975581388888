import {
  concat,
  get,
} from 'lodash';
import { normalize } from 'normalizr';
import { toast } from 'react-toastify';

import { WO_FILTER_FETCH_MAP } from '../../../constants';
import { getUser } from '../../../helpers/auth';
import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  docsSchema,
  objectsSchema,
  upsertNormalizedEntities,
  workOrderSchema,
  workOrdersSchema,
} from "../../../helpers/normalizers";
import { actions as docRequestActions } from "../../request/doc/actions";
import { actions as noteRequestActions } from "../../request/note/actions";
import { actions as workOrderRequestActions } from "../../request/workOrder/actions";
import { sortAndLimitResults } from "../../view/actions";
import { actions as workOrderViewActions } from "../../view/workOrder/actions";
import { workOrderViewSelector } from "../../view/workOrder/selectors";
import {
  fetchFileFromAPI,
  fetchNotesFromAPI,
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';
import { workOrderSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = (id) => {
  return async (dispatch) => {
    dispatch(workOrderRequestActions.setLoadError(null));
    dispatch(workOrderRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(`${constants.FETCH_URL}/${id}`);
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          workorder: workOrderSchema,
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(workOrderRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(workOrderRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Load a list of objects
 */
export const fetchObjects = (
  assignmentFilter,
  tableState,
  additionalFilters = []
) => {
  return async (dispatch) => {
    dispatch(workOrderRequestActions.setLoadAllError(null));
    dispatch(workOrderRequestActions.setIsLoadingAll(true));
    try {
      const user = getUser();
      // If they've passed in a filter string, setup the url to hit the correct endpoint.
      const fetchFilter = WO_FILTER_FETCH_MAP[assignmentFilter];

      let url = `${constants.FETCH_LIST_V2_URL}/${
        fetchFilter ? fetchFilter.replace(":userId", user.id) : ""
      }`;
      const query = new URLSearchParams(additionalFilters).toString();
      if(query){
        url = `${url}?${query}`;
      }
      const workOrders = await fetchObjectsFromAPI(url, "data.workorders");
      // Normalize the result and store the workOrders in redux
      const entities = get(
        normalize(workOrders, workOrdersSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(workOrders, tableState, (ids, count) => {
          dispatch(workOrderViewActions.setList(ids, count));        
        })
      );
      
      dispatch(workOrderViewActions.setAll(workOrders.map(wo => wo.id), workOrders.length));
      
      dispatch(workOrderRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(workOrderRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(workOrderRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async (dispatch) => {
    dispatch(workOrderRequestActions.setSaveError(null));
    dispatch(workOrderRequestActions.setIsSaving(true));
    try {
      const url = data.id ? constants.UPSERT_URL : constants.CREATE_URL;
      const result = await upsertObjectToAPI(url, data);
      const entities = get(
        normalize(result.workorder, workOrderSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.workorder);
      }
    } catch (e) {
      captureException(e);
      dispatch(workOrderRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(workOrderRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Cancel a work order
 * @param workOrder
 * @returns {Function}
 */
export const cancelWorkOrder = (workOrder, onSuccess) => {
  return async (dispatch) => {
    dispatch(workOrderRequestActions.setSaveError(null));
    dispatch(workOrderRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.CANCEL_WORK_ORDER_URL.replace(":workOrderId", workOrder.id),
        {},
        true,
        true
      );
      const entities = get(
        normalize([result.workorder], workOrdersSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
      if(onSuccess){
        onSuccess(result.workorder)
      }
    } catch (e) {
      captureException(e);
      dispatch(workOrderRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(workOrderRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = (tableState) => {
  return async (dispatch, getState) => {
    const workOrders = tableState.fromList ? workOrderViewSelector().getAll()(getState()) : workOrderSelector().getDenormalizedObjects()(getState());
    // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(workOrders, tableState, (ids, count) => {
        dispatch(workOrderViewActions.setList(ids, count));
      })
    );
  };
};

/**
 * Fetch the docs from the api and store their result in our reducer
 * @param id
 * @returns {Function}
 */
export const fetchDocs = (id, relatedActionId) => {
  return async (dispatch) => {
    dispatch(docRequestActions.setLoadAllError(null));
    dispatch(docRequestActions.setIsLoadingAll(true));
    try {
      const docs = await fetchObjectsFromAPI(
        `${constants.FETCH_DOC_LIST_URL}/${relatedActionId}`,
        "data.docs"
      );
      const entities = get(normalize(docs, docsSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      dispatch(
        workOrderViewActions.setRelatedObjects(
          constants.RELATION_DOCS,
          id,
          docs.map((doc) => {
            return doc.id;
          })
        )
      );
    } catch (e) {
      captureException(e);
      dispatch(docRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(docRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Fetch the notes from the api and store their result in our reducer
 * @param id
 * @returns {Function}
 */
export const fetchNotes = (id, relatedActionId, type = "spotplantcall") => {
  return async (dispatch) => {
    dispatch(noteRequestActions.setLoadAllError(null));
    dispatch(noteRequestActions.setIsLoadingAll(true));
    try {
      const notesPromise = dispatch(
        fetchNotesFromAPI(id, `${constants.FETCH_NOTE_LIST_URL}/${id}`)
      );
      const relatedActionNotesPromise = dispatch(
        fetchNotesFromAPI(
          id,
          `${constants.FETCH_NOTE_RELATED_ACTION_LIST_URL.replace(
            ":type",
            type
          )}/${relatedActionId}`
        )
      );
      const notes = await notesPromise;
      const relatedActionNotes = await relatedActionNotesPromise;

      dispatch(
        workOrderViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          id,
          concat(notes, relatedActionNotes).map((note) => {
            return note.id;
          })
        )
      );
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save a note on the api
 * @param parentId
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertNote = (parentId, data, onSuccess) => {
  return async (dispatch, getState) => {
    dispatch(noteRequestActions.setSaveError(null));
    dispatch(noteRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(constants.UPSERT_NOTE_URL, data);

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const sendNotification = (data, onSuccess) => {
  return async (dispatch) => {
    dispatch(workOrderRequestActions.setSaveError(null));
    dispatch(workOrderRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(constants.SEND_NOTIFICATION_URL, { data: data });
      toast.success("Notification sent successfully!");
    } catch (e) {
      captureException(e);
      dispatch(workOrderRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(workOrderRequestActions.setIsSaving(false));
    }
  };
};

export const fetchWorkOrderPull = (workOrderId, download = true) => {
  return async (dispatch) => {
    dispatch(workOrderRequestActions.setLoadError(null));
    dispatch(workOrderRequestActions.setIsLoading(true));
    try {
      await fetchFileFromAPI(
        constants.FETCH_WORK_ORDER_PULL_URL.replace(
          ":workOrderId",
          workOrderId
        ),
        `pull-${workOrderId}.pdf`,
        download,
        'application/pdf'
      );
    } catch (e) {
      captureException(e);
      dispatch(workOrderRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(workOrderRequestActions.setIsLoading(false));
    }
  };
};

export const fetchBulkWorkOrderPdf = ids => {
  return async dispatch => {
    dispatch(workOrderRequestActions.setLoadError(null));
    dispatch(workOrderRequestActions.setIsLoading(true));
    try {
      await fetchFileFromAPI(
        constants.FETCH_BULK_WORK_ORDER_PDF_URL,
        `bulk-work-orders.pdf`,
        true,
        'application/pdf',
        true,
        {ids: ids}
      );
    } catch (e) {
      captureException(e);
      dispatch(workOrderRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(workOrderRequestActions.setIsLoading(false));
    }
  };
};

export const actions = {
  ...objectActions,
  fetchObject,
  fetchObjects,
  fetchDocs,
  fetchNotes,
  fetchWorkOrderPull,
  fetchBulkWorkOrderPdf,
  sendNotification,
  updateSortFilterLimit,
  upsertObject,
  upsertNote,
};
