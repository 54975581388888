import { fromJS } from "immutable";
import { initialReportState } from "../reducer";
import { DAYS } from "./constants";

export const initialExpiringCCReportState = constants => {
  return fromJS({
    ...initialReportState(constants).toJS(),
    [DAYS]: 60
  });
};

export const expiringCCReportReducer = (state, action, constants) => {
  switch (action.type) {
    case constants.SET_DAYS:
      return state.set(DAYS, action.days);
    default:
      return state;
  }
};
