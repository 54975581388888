import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "serviceLevel";
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}admin/services/levels`;
export const FETCH_URL = `${process.env.REACT_APP_API_URL}admin/services/level`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}admin/svc/level`;
export const UPSERT_V2_URL = `${process.env.REACT_APP_API_URL}api/v2/service/level`;
export const FETCH_CUSTOMER_LIST_URL = `${process.env.REACT_APP_API_URL}admin/services/levelc`;
export const REDUCER_NAME = "serviceLevels";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_LIST_URL,
  FETCH_URL,
  FETCH_CUSTOMER_LIST_URL,
  UPSERT_URL,
  UPSERT_V2_URL,
  UPSERT_PROPERTY
};
