import { Component } from "react";
import { get } from "lodash";

const Coralogix = require("coralogix-logger");

class BaseSentryComponent extends Component {
  componentDidCatch(error, errorInfo) {
    const message = get(error, 'message', 'Error');
    const componentStack = get(errorInfo, 'componentStack');
    const text = `${message}\nStacktrace:\n\t${componentStack}`
    const log = new Coralogix.Log({
        severity:Coralogix.Severity.error,
        text: text,
    });
    // send log to coralogix
    if(this.logger){
      this.logger.addLog(log);
    }
  }
}

export const ErrorBoundaryComponent = BaseSentryComponent;
