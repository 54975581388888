import { schema } from 'normalizr';

import {
  REDUCER_NAME as GOOD_VENDOR_REDUCER_NAME,
} from '../../store/object/goodVendor/constants';
import { goodSchema } from './good';
import { vendorSchema } from './vendor';

export const goodVendorSchema = new schema.Entity(GOOD_VENDOR_REDUCER_NAME, {
  good: goodSchema,
  vendor: vendorSchema,
});

export const goodVendorsSchema = [goodVendorSchema];
