import React from "react";

import { get } from "lodash";
import { Button, Card, Icon } from "semantic-ui-react";
import { PageFiller } from "../../../../../components/PageFiller";
import { object, array, bool } from "prop-types";
import { WEB_ROUTE_PORTAL_BILLING } from "./../../../../../constants/routes";
import { PORTAL_BILLING_TYPES } from "./../../../../../constants/general";

export const CustomerBilling = ({
  history,
  account,
  accounts,
  customer,
  loading,
}) => {
  const creditCardNumber = get(customer, "billing.ccnum", null);
  return (
    <Card className={"sidebar-customer-card b-0 pb-0"}>
      <Card.Content>
        <h1 className="header mt-2 fs-6">Billing</h1>
        {loading ? (
          <div className="pb-2">
            <PageFiller lines={3} />
          </div>
        ) : (
          <>
            {get(customer, "billing") ? (
              <div className="py-1">
                <p>
                  {get(
                    PORTAL_BILLING_TYPES,
                    get(customer, "billing.type"),
                    "None"
                  )}
                </p>
                <p>{get(customer, "billing.ccname")}</p>
                {creditCardNumber && (
                  <p className="fspace-1">
                    <Icon name="credit card" />
                    {creditCardNumber.replace(/x/gi, "•")}
                  </p>
                )}
              </div>
            ) : null }
            <Button
              className="portal-button px-4 my-2"
              onClick={() => {
                history.push(WEB_ROUTE_PORTAL_BILLING);
              }}
            >
              {get(customer, "billing") ? 'Change' : 'Add'} Billing
            </Button>
          </>
        )}
      </Card.Content>
    </Card>
  );
};

CustomerBilling.propTypes = {
  history: object,
  account: object,
  customer: object,
  accounts: array,
  loading: bool,
};

CustomerBilling.defaultProps = {
  loading: true,
};
