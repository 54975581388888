import { schema } from 'normalizr';

import {
  REDUCER_NAME as SERVICE_REDUCER_NAME,
} from '../../store/object/service/constants';
import { locationSchema } from './location';

export const serviceSchema = new schema.Entity(SERVICE_REDUCER_NAME, {
  location: locationSchema
});

export const servicesSchema = [serviceSchema];
