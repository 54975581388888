import { any, func } from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { LINK_PURCHASE_ORDER_EDIT } from "../../../../../constants";
import { connect } from "react-redux";
import { actions as objectActions } from "../../../../../store/object/purchaseOrder/actions";

const ActionCellComponent = React.memo(({ fetchPurchaseOrderPdf, value }) => {
  if (!value) {
    return null;
  }
  return (
    <span className="action-cell pl-1">
      <Link
        to={{
          pathname: LINK_PURCHASE_ORDER_EDIT.route.replace(":id", value),
          state: LINK_PURCHASE_ORDER_EDIT.state
        }}
        className="pl-1"
      >
        <Icon name="edit" size="large" />
      </Link>

      <button
        type="button"
        className="pl-1 btn-link"
        onClick={() => {
          fetchPurchaseOrderPdf(value);
        }}
      >
        <Icon name="file pdf" size="large" />
      </button>
    </span>
  );
});

ActionCellComponent.propTypes = {
  fetchPurchaseOrderPdf: func.isRequired,
  value: any
};

const mapStateToProps = state => {
  return {};
};

const ActionCell = connect(
  mapStateToProps,
  objectActions
)(ActionCellComponent);

export const renderActionCell = ({ value }) => {
  return <ActionCell value={value} />;
};
