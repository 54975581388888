import { REPORT_REDUCER_NAME } from "../../report/constants";
import * as reportConstants from "../constants";
import { makeReportConstants } from "../util";
export const REDUCER_NAME = "salesNonRoseDetail";
export const PERIOD_ID = "periodId";
export const DETAILS = "details";
export const SUMMARY = "summary";

export const SET_PERIOD_ID = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_PERIOD_ID`;
export const SET_DETAILS = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_DETAILS`;
export const SET_SUMMARY = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_SUMMARY`;

export const FETCH_EXCEL_URL = `${process.env.REACT_APP_API_URL}api/v2/export/non-rose-sales/:periodId`;
export const FETCH_RESULTS_URL = `${process.env.REACT_APP_API_URL}api/v2/reports/non-rose-sales/:periodId`;

// Action Types
const defaultConstants = makeReportConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...reportConstants,
  REDUCER_NAME,
  FETCH_RESULTS_URL,
  FETCH_EXCEL_URL,
  PERIOD_ID,
  SET_PERIOD_ID,
  SET_DETAILS,
  SET_SUMMARY,
  DETAILS,
  SUMMARY
};
