import { fromJS } from "immutable";
import { initialReportState } from "../reducer";
import { PERIOD_ID, PRICE_LEVELS, PROFIT_CENTERS, ROUTES } from "./constants";

export const initialServiceRevenueSummaryReportState = constants => {
  return fromJS({
    ...initialReportState(constants).toJS(),
    [PERIOD_ID]: null,
    [PROFIT_CENTERS]: [],
    [PRICE_LEVELS]: [],
    [ROUTES]: []
  });
};

export const serviceRevenueSummaryReportReducer = (
  state,
  action,
  constants
) => {
  switch (action.type) {
    case constants.SET_PERIOD_ID:
      return state.set(PERIOD_ID, action.periodId);

    case constants.SET_ROUTES:
      return state.setIn([constants.ROUTES], fromJS(action.data));

    case constants.SET_PRICE_LEVELS:
      return state.setIn([constants.PRICE_LEVELS], fromJS(action.data));

    case constants.SET_PROFIT_CENTERS:
      return state.setIn([constants.PROFIT_CENTERS], fromJS(action.data));
    default:
      return state;
  }
};
