import { fromJS } from "immutable";
import { initialViewState } from "../reducer";

export const initialInventoryLotViewState = (constants) => {
  return fromJS({
    ...initialViewState(constants).toJS(),
  });
};

export const inventoryLotReducer = (state, action, constants) => {
  switch (action.type) {
    default:
      return state;
  }
};
