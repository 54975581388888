import React from "react";
import { Chart } from "react-charts";

export const Graph = ({ data }) => {
  const primaryAxis = React.useMemo(
    () => ({
      getValue: (datum) => datum.date,
      elementType: 'bar',
    }),
    []
  );
  const secondaryAxes = React.useMemo(
    () => [
      {
        getValue: (datum) => datum.number,
        elementType: 'bar',
      },
    ],
    []
  );
  return (
    <div
      style={{
        width: "100%",
        height: "300px",
      }}
    >
      <Chart
        options={{
          primaryAxis: primaryAxis,
          secondaryAxes: secondaryAxes,
          data: data,
          getSeriesStyle: (series) => ({
            opacity: 0.5,
            color: series.index ? 'rgb(0, 27, 45)' : '#0B5130'
          }),
        }}
      />
    </div>
  );
};
