import { array, bool, func, number, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Container, Dimmer, Loader } from "semantic-ui-react";
import { uniq } from "lodash";
import { ListComponent, ListHeader, PageAlert } from "../../../../components";
import { LINK_USER_CREATE } from "../../../../constants/links";
import { WEB_ROUTE_DEFAULT } from "../../../../constants/routes";
import {
  getUser,
  setToken as setTokenOnSession,
  setUser as setUserOnSession
} from "../../../../helpers/auth";
import { actions as objectActions } from "../../../../store/object/user/actions";
import { userSelector as userObjectSelector } from "../../../../store/object/user/selectors";
import { userRequestSelector } from "../../../../store/request/user/selectors";
import { userViewSelector } from "../../../../store/view/user/selectors";
import { DataTableWrapper } from "../../../../components/DataTable/Wrapper";
import { ExitImpersonateModeMessage } from "../../../../components/Toast"
import { FilterSelect } from "../../../../components/DataTable/FilterSelect";
import {
  setConfirmationModalText,
  toggleConfirmationModalVisible,
  toggleConfirmationModalYesClicked,
} from '../../../../store/app/actions';
import { isConfirmationModalYesClicked } from '../../../../store/app/selectors';
import { columns } from './columns';

class UserListComponent extends ListComponent {
  constructor(props) {
    super(props);
    this.state.columns = columns;
    this.state.sortOrder = {
      column: "name",
      isAscending: true
    };
    this.state.filter = {
      organization: "WRC"
    }
  }

  componentDidMount() {
    const {
      isLoadingAll,
      isLoadedAll,
      fetchObjects,
      updateSortFilterLimit
    } = this.props;
    if (!isLoadedAll && !isLoadingAll) {
      fetchObjects(this.state);
    } else {
      // We don't need to load anything from the api, but we need to setup the first page of the grid
      updateSortFilterLimit(this.state);
    }
  }

  componentDidUpdate(prevProps) {
    const { isConfirmationModalYesClicked, history } = this.props;
    const { userToLoginAs } = this.state;
    const currentUser = getUser();
    if (isConfirmationModalYesClicked && userToLoginAs) {
      toast.error(<ExitImpersonateModeMessage user={currentUser} userToLoginAs={userToLoginAs}  />, {
        position: "bottom-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: ({user}) => {
          setTokenOnSession(user.api_token);
          setUserOnSession(user);
          window.location.reload(true);
        }
      });
      setTokenOnSession(userToLoginAs.api_token);
      setUserOnSession(userToLoginAs);
      history.push(WEB_ROUTE_DEFAULT)
    }
  }

  render() {
    const {
      total,
      isLoadingAll,
      loadError,
      objects,
      rows,
      setConfirmationModalText,
      toggleConfirmationModalVisible
    } = this.props;
    const tableState = {
      ...this.state,
      onRefreshClick: this.handleRefresh,
      onExportClick: this.handleExportClick,
      onPageChange: this.handlePageChange,
      onSortChange: this.handleSortChange,
      onLimitChange: this.handleLimitChange,
      onSearchChange: this.handleSearchChange
    };
    return (
      <Container className="view-users view-list view" as="article" fluid>
        <Dimmer active={isLoadingAll} inverted>
          <Loader size="large">Loading</Loader>
        </Dimmer>
        <ListHeader
          title="Users"
          link={LINK_USER_CREATE}
          searchPlaceholder="Search Users"
          tableState={tableState}
          total={total}
          limitCustomizable
          leftChildren={
            <div className="filters d-inline-block">
            <div className="d-flex">
              <div className="min-width-15 ml-2">
                <label className="strong">Organization</label>
                <FilterSelect
                  allItems={uniq(rows.map(option => option.organization))}
                  multiple={false}
                  selectedItems={this.state.filter.organization}
                  onChangeItems={value => {
                    this.setState({ filter: { ...this.state.filter, organization: value } }, () => {
                      this.handleRefresh();
                    });
                  }}
                  name="organization"
                  placeholder="Select organization"
                />
              </div>
            </div>
          </div>
          }
        />
        <PageAlert title="Error While Retrieving Users" message={loadError} />
        <DataTableWrapper
          columns={columns}
          total={total}
          isLoadingAll={isLoadingAll}
          rows={objects}
          tableState={tableState}
          tableProps={{
            currentUser: getUser(),
            onLoginAs: user => {
              this.setState({ userToLoginAs: user }, () => {
                setConfirmationModalText({
                  title: "Login As User?",
                  isDanger: true,
                  content: `Are you sure you want to login as user, ${user.name}?`
                });
                toggleConfirmationModalVisible(true);
              });
            }
          }}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => {
  const objectSelector = userObjectSelector();
  const requestSelector = userRequestSelector();
  const viewSelector = userViewSelector();
  return {
    isConfirmationModalYesClicked: isConfirmationModalYesClicked()(state),
    isLoadedAll: requestSelector.getIsLoadedAll()(state),
    isLoadingAll: requestSelector.getIsLoadingAll()(state),
    loadError: requestSelector.getLoadAllError()(state),
    objects: viewSelector.getList()(state),
    rows: objectSelector.getObjects()(state),
    total: viewSelector.getTotal()(state)
  };
};

UserListComponent.displayName = "UserList";
UserListComponent.propTypes = {
  exportObjects: func.isRequired,
  fetchObjects: func.isRequired,
  isLoadedAll: bool,
  isLoadingAll: bool,
  loadError: string,
  total: number,
  updateSortFilterLimit: func.isRequired,
  objects: array.isRequired
};

export const UserList = connect(
  mapStateToProps,
  {
    ...objectActions,
    setConfirmationModalText,
    toggleConfirmationModalVisible,
    toggleConfirmationModalYesClicked
  }
)(UserListComponent);
