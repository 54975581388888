import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "bigCommerce";
export const REDUCER_NAME = "bigCommerce";
export const FETCH_BIG_COMMERCE_LIST_URL = `${process.env.REACT_APP_API_URL}admin/invoice/bc/:startDate`;
export const FETCH_BIG_COMMERCE_ORDER_URL = `${process.env.REACT_APP_API_URL}admin/invoice/bcid/:bigCommerceId`;
export const UPSERT_IMPORT_BIG_COMMERCE_ORDER_URL = `${process.env.REACT_APP_API_URL}admin/invoice/import/:orderId`;

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  UPSERT_PROPERTY,
  FETCH_BIG_COMMERCE_LIST_URL,
  FETCH_BIG_COMMERCE_ORDER_URL,
  UPSERT_IMPORT_BIG_COMMERCE_ORDER_URL,
};
