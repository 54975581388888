import * as objectConstants from '../constants';
import { makeObjectConstants } from '../util';

export const UPSERT_PROPERTY = "customer";
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}admin/customer`;
export const FETCH_LIST_V2_URL = `${process.env.REACT_APP_API_URL}api/v2/customers`;
export const FETCH_URL = `${process.env.REACT_APP_API_URL}admin/customer`;
export const FETCH_V2_URL = `${process.env.REACT_APP_API_URL}api/v2/customers`;

export const UPSERT_URL = `${process.env.REACT_APP_API_URL}admin/customer`;
export const UPSERT_V2_URL = `${process.env.REACT_APP_API_URL}api/v2/customers`;
export const UPSERT_BILLING_URL = `${process.env.REACT_APP_API_URL}api/v2/customer/billing/:customerId`;
export const UPSERT_ACH_BILLING_URL = `${process.env.REACT_APP_API_URL}api/v2/customer/ach-billing/:customerId`;
export const DESTROY_BILLING_URL = `${process.env.REACT_APP_API_URL}api/v2/customer/billing/delete/:customerId`;
export const UPSERT_PAYMENT_URL = `${process.env.REACT_APP_API_URL}admin/customer/:customerId/payment`;
export const FETCH_INVOICE_LIST_URL = `${process.env.REACT_APP_API_URL}admin/invoice/customer/:customerId`;
export const FETCH_PAYMENT_LIST_URL = `${process.env.REACT_APP_API_URL}admin/customer/:customerId/payments`;
export const FETCH_FAILED_PAYMENT_LIST_URL = `${process.env.REACT_APP_API_URL}api/v2/customer/payments/:customerId/failed`;
export const FETCH_PAYMENT_PROFILE_LIST_URL = `${process.env.REACT_APP_API_URL}api/v2/customer/billing/payment-profiles/:customerId`;
export const FETCH_PAYMENT_RECEIPT_URL = `${process.env.REACT_APP_API_URL}admin/customer/:paymentId/payreceipt`;
export const REVERSE_PAYMENT_URL = `${process.env.REACT_APP_API_URL}admin/payment/reverse`;
export const FETCH_BED_PACKET_LIST_URL = `${process.env.REACT_APP_API_URL}api/v2/bed-packet/customer/:customerId`;
export const FETCH_DOC_LIST_URL = `${process.env.REACT_APP_API_URL}admin/doc/customer/:customerId`;
export const FETCH_WORK_ORDER_LIST_URL = `${process.env.REACT_APP_API_URL}admin/workorder/customer/:customerId`;
export const FETCH_SERVICE_VISIT_LIST_URL = `${process.env.REACT_APP_API_URL}admin/customer/showact/:customerId`;
export const FETCH_STATEMENT_LIST_URL = `${process.env.REACT_APP_API_URL}admin/customer/:customerId/statementlist`;
export const FETCH_CURRENT_SERVICE_URL = `${process.env.REACT_APP_API_URL}admin/customer/:customerId/currentService`;
export const FETCH_CUSTOMER_DUMP_URL = `${process.env.REACT_APP_API_URL}api/v2/export/customer/excel`;

export const REDUCER_NAME = "customers";
export const FETCH_NOTE_LIST_URL = `${process.env.REACT_APP_API_URL}admin/note/customer`;
export const UPSERT_NOTE_URL = `${process.env.REACT_APP_API_URL}admin/note`;
export const UPSERT_DOC_URL = `${process.env.REACT_APP_API_URL}admin/doc/upload`;
export const DESTROY_DOC_URL = `${process.env.REACT_APP_API_URL}admin/doc/del/:docId`;
export const DESTROY_SERVICE_ACTIVITY_URL = `${process.env.REACT_APP_API_URL}admin/customer/delvisit/:id`;
export const SAVE_STATEMENT_OPTIONS_URL = `${process.env.REACT_APP_API_URL}admin/customer/:customerId/updateStatementSettings`;
export const UPDATE_POST_VISIT_URL = `${process.env.REACT_APP_API_URL}admin/customer/postspottostatement`;
export const UPDATE_SERVICE_VISIT_URL = `${process.env.REACT_APP_API_URL}admin/customer/saveactivity`;
export const SEARCH_LIST_URL = `${process.env.REACT_APP_API_URL}admin/invoice/customersearch`;
export const FETCH_SERVICE_LIST_URL = `${process.env.REACT_APP_API_URL}admin/customer/svclist`;
export const FETCH_SERVICES_LIST_URL = `${process.env.REACT_APP_API_URL}api/v2/services`;
export const FETCH_SERVICE_PRICES_LIST_URL = `${process.env.REACT_APP_API_URL}api/v2/service-level-prices`;
export const FETCH_SERVICE_ROUTE_MAP_URL = `${process.env.REACT_APP_API_URL}api/v2/maps/service-addresses/:routes`;
export const UPDATE_SERVICE_ADDESS_GEODATA_URL = `${process.env.REACT_APP_API_URL}api/v2/maps/address/geolocate`;
export const FETCH_TAX_RATE = `${process.env.REACT_APP_API_URL}admin/customer/getTaxRate/:customerId`;
export const ACTIVATE_CANCEL_URL = `${process.env.REACT_APP_API_URL}admin/customer/:customerId/activecancel`;
export const SCHEDULE_START_STOP_URL = `${process.env.REACT_APP_API_URL}api/v2/customer/service/schedule/:id`;

export const UPSERT_PROFILE_PHOTO_URL = `${process.env.REACT_APP_API_URL}api/portal/profile-photo`;

// Portal
export const FETCH_V2_PORTAL_URL = `${process.env.REACT_APP_API_URL}api/portal/customer`;
export const FETCH_PORTAL_BILLING_URL = `${process.env.REACT_APP_API_URL}api/portal/billing`;
export const UPSERT_PORTAL_BILLING_URL = `${process.env.REACT_APP_API_URL}api/portal/billing`;
export const UPSERT_PORTAL_CUSTOMER_URL = `${process.env.REACT_APP_API_URL}api/portal/customer`;
export const CANCEL_PORTAL_APPOINTMENT_URL = `${process.env.REACT_APP_API_URL}api/portal/customer/appointment/cancel/:appointmentId`;
export const UPSERT_PORTAL_REGISTRATION_URL = `${process.env.REACT_APP_API_URL}api/v2/customer/portal/register/:customerId`;

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  ACTIVATE_CANCEL_URL,
  SCHEDULE_START_STOP_URL,
  FETCH_CURRENT_SERVICE_URL,
  FETCH_LIST_URL,
  FETCH_SERVICE_LIST_URL,
  FETCH_SERVICES_LIST_URL,
  FETCH_SERVICE_PRICES_LIST_URL,
  FETCH_LIST_V2_URL,
  FETCH_PAYMENT_RECEIPT_URL,
  FETCH_PAYMENT_LIST_URL,
  FETCH_FAILED_PAYMENT_LIST_URL,
  FETCH_PAYMENT_PROFILE_LIST_URL,
  FETCH_WORK_ORDER_LIST_URL,
  FETCH_NOTE_LIST_URL,
  FETCH_BED_PACKET_LIST_URL,
  FETCH_DOC_LIST_URL,
  FETCH_INVOICE_LIST_URL,
  FETCH_SERVICE_VISIT_LIST_URL,
  FETCH_SERVICE_ROUTE_MAP_URL,
  FETCH_STATEMENT_LIST_URL,
  UPSERT_NOTE_URL,
  FETCH_URL,
  FETCH_V2_URL,
  FETCH_V2_PORTAL_URL,
  FETCH_PORTAL_BILLING_URL,
  REVERSE_PAYMENT_URL,
  UPSERT_URL,
  UPSERT_V2_URL,
  UPSERT_BILLING_URL,
  UPSERT_ACH_BILLING_URL,
  DESTROY_BILLING_URL,
  UPSERT_PAYMENT_URL,
  UPSERT_PORTAL_REGISTRATION_URL,
  UPSERT_PORTAL_BILLING_URL,
  UPSERT_PORTAL_CUSTOMER_URL,
  UPSERT_DOC_URL,
  UPSERT_PROPERTY,
  UPSERT_PROFILE_PHOTO_URL,
  UPDATE_SERVICE_VISIT_URL,
  UPDATE_POST_VISIT_URL,
  SEARCH_LIST_URL,
  SAVE_STATEMENT_OPTIONS_URL,
  DESTROY_DOC_URL,
  FETCH_TAX_RATE,
  DESTROY_SERVICE_ACTIVITY_URL,
  CANCEL_PORTAL_APPOINTMENT_URL,
  FETCH_CUSTOMER_DUMP_URL,
};
