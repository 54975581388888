import React from "react";

import { func, object } from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Button, Icon } from "semantic-ui-react";

import { setCurrentLocationId } from "../../../../store/app/actions";
import { getCurrentLocation } from "../../../../store/app/selectors";

const PortalHeaderComponent = ({ onLogout }) => {
  return (
    <header className="portal-header shadow">
      <div className="pull-left">
        <ul className="portal-header-actions">
          <li className="brand">
            <div className="logo ml-3 mt-1"></div>
            <span>Account Dashboard</span>
          </li>
        </ul>
      </div>
      <div className="pull-right">
        <ul className="portal-header-actions">
          <li>
            <div>
              <Button
                size="tiny"
                icon
                basic
                labelPosition="right"
                className="strong logout-button"
                onClick={onLogout}
              >
                Logout
                <Icon name="sign-out" />
              </Button>
            </div>
          </li>
        </ul>
      </div>
    </header>
  );
};

PortalHeaderComponent.propTypes = {
  location: object,
  onLogout: func.isRequired,
  setCurrentLocationId: func.isRequired,
};
const mapStateToProps = createStructuredSelector({
  location: getCurrentLocation(),
});

export const PortalHeader = connect(
  mapStateToProps,
  { setCurrentLocationId }
)(PortalHeaderComponent);
