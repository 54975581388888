import * as reportConstants from "../constants";
import { makeReportConstants } from "../util";
export const REDUCER_NAME = "sprayMixMonthlyDetails";

export const FETCH_RESULTS_URL = `${process.env.REACT_APP_API_URL}api/v2/reports/spray-mix/usage/details/:startDate/:endDate`;
export const FETCH_EXCEL_URL = `${process.env.REACT_APP_API_URL}api/v2/reports/spray-mix/usage/details/:startDate/:endDate/xlsx`;

// Action Types
const defaultConstants = makeReportConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...reportConstants,
  REDUCER_NAME,
  FETCH_EXCEL_URL,
  FETCH_RESULTS_URL,
};
