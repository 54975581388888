import { schema } from 'normalizr';

import {
  REDUCER_NAME as BED_INVOICE_REDUCER_NAME,
} from '../../store/object/bedInvoice/constants';
import { customerSchema } from './customer';
import { locationSchema } from './location';
import { profitCenterSchema } from './profitCenter';

export const bedInvoiceSchema = new schema.Entity(BED_INVOICE_REDUCER_NAME, {
  customer: customerSchema,
  location: locationSchema,
  profit_center: profitCenterSchema
});

export const bedInvoicesSchema = [bedInvoiceSchema];
