import React from 'react';

import { pick } from 'lodash';
import {
  bool,
  func,
  string,
} from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Dimmer,
  Loader,
  Modal,
} from 'semantic-ui-react';

import { actions as objectActions } from '../../store/object/vendor/actions';
import { vendorRequestSelector } from '../../store/request/vendor/selectors';
import { PageAlert } from '../PageAlert';
import { VendorCreateModalForm } from './Form';

const VendorCreateModalComponent = ({
  isSaving,
  saveError,
  history,
  isOpen,
  onClose,
  onSubmit,
  upsertObject
}) => {
  return (
    <Modal open={isOpen} onClose={onClose} closeIcon size="small">
      <Modal.Header className="d-flex justify-content-between">
        Create Vendor
      </Modal.Header>
      <Modal.Content>
        <Dimmer active={isSaving} inverted>
          <Loader size="large">Creating Vendor...</Loader>
        </Dimmer>
        <PageAlert title="Error" message={saveError} />
        <VendorCreateModalForm
          onSubmit={values => {
            if (values.address1) {
              values.addresses = [
                {
                  ...pick(values, ["address1", "state", "city", "postal_code"])
                }
              ];
            }

            upsertObject(values, onSubmit);
          }}
        />
      </Modal.Content>
    </Modal>
  );
};

VendorCreateModalComponent.propTypes = {
  isSaving: bool,
  isOpen: bool,
  saveError: string,
  onClose: func.isRequired
};

const mapStateToProps = state => {
  const requestSelector = vendorRequestSelector();
  return {
    isSaving: requestSelector.getIsSaving()(state),
    saveError: requestSelector.getSaveError()(state)
  };
};

export const VendorCreateModal = connect(
  mapStateToProps,
  {
    ...objectActions
  }
)(withRouter(VendorCreateModalComponent));
