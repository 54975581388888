import { any, bool, number } from "prop-types";
import React from "react";

/**
 * FloatCell component
 */
export const FloatCell = React.memo(({ value, decimalPlaces = 2, approximately = false }) => {
  if (!parseInt(value, 10)) {
    decimalPlaces = 0;
  }
  if(approximately){
    return (
      <span className="float-cell">{`${value < 1 && value > 0 ? '~' : ''}${Number(value || 0).toFixed(
        decimalPlaces
      )}`}</span>
    );
  }  
  return (
    <span className="float-cell">{`${Number(value || 0).toFixed(
      decimalPlaces
    )}`}</span>
  );
});

FloatCell.propTypes = {
  value: any,
  decimalPlaces: number,
  approximately: bool,
};
