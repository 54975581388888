export const REDUCER_NAME = "auth";

// State Variables
export const AUTHENTICATION_ERROR = "authenticationError";
export const VALIDATION_ERROR = "validationError";
export const REFRESH_ERROR = "refreshError";
export const VERIFICATION_ERROR = "verificationError";

export const IS_VALIDATING = "isValidating";
export const IS_AUTHENTICATING = "isAuthenticating";
export const IS_REFRESHING = "isRefreshing";
export const IS_VERIFYING_TOKEN = "isVerifyingToken";

export const USER = "user";

// Action Types
export const RESET = "store/auth/RESET";
export const SET_IS_AUTHENTICATING = "store/auth/SET_IS_AUTHENTICATING";
export const SET_IS_REFRESHING = "store/auth/SET_IS_REFRESHING";
export const SET_IS_VERIFYING_TOKEN = "store/auth/SET_IS_VERIFYING_TOKEN";

export const SET_USER = "store/auth/SET_USER";
export const SET_AUTHENTICATION_ERROR = "store/auth/SET_AUTHENTICATION_ERROR";
export const SET_REFRESH_ERROR = "store/auth/SET_REFRESH_ERROR";
export const SET_VERIFICATION_ERROR = "store/auth/SET_VERIFICATION_ERROR";
