export const DEFAULT_LIMIT = 25;
export const MAX_LIMIT = 100;
export const DEFAULT_TABLE_STATE = {
  page: 1,
  limit: DEFAULT_LIMIT,
  search: "",
  sortOrder: {
    column: "number",
    isAscending: false
  }
};
