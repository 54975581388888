import { REPORT_REDUCER_NAME } from "../../report/constants";
import * as reportConstants from "../constants";
import { makeReportConstants } from "../util";
export const REDUCER_NAME = "inventoryAdjustments";
export const PERIOD_ID = "periodId";

export const SET_PERIOD_ID = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_PERIOD_ID`;
export const FETCH_RESULTS_URL = `${process.env.REACT_APP_API_URL}admin/report/xfer?periodId=:periodId&type=web`;
export const FETCH_XLSX_URL = `${process.env.REACT_APP_API_URL}admin/report/xfer?periodId=:periodId&type=xlsx`;
export const FETCH_PDF_URL = `${process.env.REACT_APP_API_URL}admin/report/xfer?periodId=:periodId&type=pdf`;

// Action Types
const defaultConstants = makeReportConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...reportConstants,
  PERIOD_ID,
  REDUCER_NAME,
  FETCH_XLSX_URL,
  FETCH_PDF_URL,
  SET_PERIOD_ID,
  FETCH_RESULTS_URL
};
