import { createSelector } from "reselect";
import { makeViewSelector } from "../selectors";
import { allConstants as constants } from "./constants";

export const inventoryActivityViewReducer = () => state =>
  state.getIn([constants.VIEW_REDUCER_NAME, constants.REDUCER_NAME]);

export const inventoryActivityViewSelector = () => {
  return {
    ...makeViewSelector(constants, inventoryActivityViewReducer),
    getEndDate: () => {
      return createSelector(
        inventoryActivityViewReducer(),
        viewState => {
          return viewState.get(constants.END_DATE);
        }
      );
    }
  };
};
