import React from "react";
import { get, filter, find } from "lodash";
import { Card } from "semantic-ui-react";
import { Map } from "./Panes/Map";
import { List } from "./Panes/List";

export const AppointmentsCard = ({data}) => {
  const [activePane, setActivePane] = React.useState('map');
  const upcomingAppointments = get(data, "upcoming", []);
  // Get the primary address for each appointment
  const mapData = filter(upcomingAppointments.map(appointment => {
    const addresses = get(appointment, 'customer.addresses', []);
    if (addresses.length === 0) return null;
    const primaryAddress = find(addresses, {is_primary: true});
    const address = !!primaryAddress ? primaryAddress : addresses[0];
    if(address){
      address.customerName = `${get(appointment, 'customer.first_name')} ${get(appointment, 'customer.last_name')}`;
      address.customerCurrentMood = get(appointment, 'customer.currentMood');
      address.customerRoute = `${get(appointment, 'customer.csr.route.name', '')}-${get(appointment, 'customer.csr.sort_order', '')}`
    }
    return address;
  }), address => !!address);
  return (
    <Card fluid>
      <Card.Content>
        <Card.Header className='card-tab-header'>
        Appointments
        <div className="pull-right big-stat md">{upcomingAppointments.length}</div>
        </Card.Header>
        <Card.Description>
          {activePane === 'map' && <Map data={mapData} goToListView={() => setActivePane('list')} />}
          {activePane === 'list' && <List data={upcomingAppointments} goToMapView={() => setActivePane('map')} />}
        </Card.Description>
      </Card.Content>
    </Card>
  )
}
