import * as viewConstants from "../constants";
import { makeViewConstants } from "../util";
export const REDUCER_NAME = "salesCallActions";

// Action Types
const defaultConstants = makeViewConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...viewConstants,
  REDUCER_NAME
};
