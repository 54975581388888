import { VIEW_REDUCER_NAME } from "../constants";
import * as viewConstants from "../constants";
import { makeViewConstants } from "../util";
export const REDUCER_NAME = "serviceVisits";

export const START_DATE = "startDate";
export const END_DATE = "endDate";
export const MONTH = "month";
export const YEAR = "year";

export const SET_DATE = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_DATE`;
export const SET_MONTH = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_MONTH`;
export const SET_YEAR = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_YEAR`;

// Action Types
const defaultConstants = makeViewConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...viewConstants,
  SET_DATE,
  SET_MONTH,
  SET_YEAR,
  MONTH,
  YEAR,
  START_DATE,
  END_DATE,
  REDUCER_NAME
};
