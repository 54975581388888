import dayjs from 'dayjs';
import { get } from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import { upsertNormalizedEntities } from '../../../helpers/normalizers';
import {
  inventoryActivitiesSchema,
} from '../../../helpers/normalizers/inventoryActivity';
import {
  actions as inventoryActivityRequestActions,
} from '../../request/inventoryActivity/actions';
import {
  actions as transferRequestActions,
} from '../../request/transfer/actions';
import { sortAndLimitResults } from '../../view/actions';
import {
  actions as inventoryActivityViewActions,
} from '../../view/inventoryActivity/actions';
import {
  inventoryActivityViewSelector,
} from '../../view/inventoryActivity/selectors';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';
import { inventoryActivitySelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(inventoryActivityRequestActions.setLoadAllError(null));
    dispatch(inventoryActivityRequestActions.setIsLoadingAll(true));
    try {
      const activities = await fetchObjectsFromAPI(constants.FETCH_LIST_URL);

      // Normalize the result and store the customers in redux
      const entities = get(
        normalize(activities, inventoryActivitiesSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(activities, tableState, (ids, count) => {
          dispatch(inventoryActivityViewActions.setList(ids, count));
        })
      );
      dispatch(inventoryActivityRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(
        inventoryActivityRequestActions.setLoadAllError(getErrorMessage(e))
      );
    } finally {
      dispatch(inventoryActivityRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const inventoryActivities = inventoryActivitySelector().getDenormalizedObjects()(
      getState()
    );
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(inventoryActivities, tableState, (ids, count) => {
        dispatch(inventoryActivityViewActions.setList(ids, count));
      })
    );
  };
};

/**
 * Fetch the activity that took place before a certain date
 * @param endDate
 * @param tableState
 * @returns {Function}
 */
export const fetchHistoryForDate = (endDate, tableState) => {
  return async dispatch => {
    dispatch(objectActions.reset());
    dispatch(inventoryActivityRequestActions.setLoadAllError(null));
    dispatch(inventoryActivityRequestActions.setIsLoadingAll(true));
    try {
      const endDateJs = endDate ? dayjs(endDate) : null;

      if (endDateJs) {
        const result = await fetchObjectsFromAPI(
          constants.FETCH_HISTORY_URL.replace(
            ":endDate",
            endDateJs.format("YYYY-MM-DD")
          ),
          "data.rows"
        );

        const entities = get(
          normalize(result, inventoryActivitiesSchema),
          "entities",
          {}
        );

        dispatch(upsertNormalizedEntities(entities));

        // Store the full list of ids in the reducer
        dispatch(
          inventoryActivityViewActions.setRelatedObjects(
            constants.RELATION_INVENTORY_ACTIVITIES,
            constants.ALL,
            result.map(inventoryActivity => {
              return inventoryActivity.id;
            })
          )
        );

        dispatch(updateInventoryActivitySortFilterLimit(tableState));
      } else {
      }
    } catch (e) {
      captureException(e);
      dispatch(
        inventoryActivityRequestActions.setLoadAllError(getErrorMessage(e))
      );
    } finally {
      dispatch(inventoryActivityRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the inventoryActivities for the table
 * @param tableState
 * @returns {Function}
 */
export const updateInventoryActivitySortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const state = getState();
    const inventoryActivities = inventoryActivityViewSelector().getRelatedObjects(
      constants.RELATION_INVENTORY_ACTIVITIES,
      constants.ALL
    )(state);

    // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(inventoryActivities, tableState, (ids, count) => {
        dispatch(
          inventoryActivityViewActions.setRelatedFilteredList(
            constants.RELATION_INVENTORY_ACTIVITIES,
            constants.ALL,
            ids,
            count
          )
        );
      })
    );
  };
};

/**
 * Reverse the activity
 * @param inventoryActivityId
 * @param date
 * @param onSuccess
 * @returns {Function}
 */
export const reverseInventoryActivity = (
  inventoryActivityId,
  date,
  onSuccess
) => {
  return async dispatch => {
    dispatch(transferRequestActions.setSaveError(null));
    dispatch(transferRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.REVERSE_URL.replace(":id", inventoryActivityId),
        {
          date: `reverse_${date}`
        },
        false,
        true
      );

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      captureException(e);
      dispatch(transferRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(transferRequestActions.setIsSaving(false));
    }
  };
};

export const actions = {
  ...objectActions,
  fetchObjects,
  fetchHistoryForDate,
  reverseInventoryActivity,
  updateSortFilterLimit,
  updateInventoryActivitySortFilterLimit
};
