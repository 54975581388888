import React, { Component, Suspense } from "react";

import { isNull, get } from "lodash";
import { func, object, bool } from "prop-types";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Loader, Message } from "semantic-ui-react";

import * as constants from "../../constants";
import {
  getCurrentAccountObject,
  getPortalAccounts,
  getUser,
  goToLogin,
  setCurrentAccount,
} from "../../helpers/auth";
import * as appActions from "../../store/app/actions";
import * as appSelectors from "../../store/app/selectors";
import * as actions from "../../store/auth/actions";

import { customerObjectActions } from "../../store/object/customer";
import { actions as invoiceObjectActions } from "../../store/object/invoice/actions";
import { actions as statementObjectActions } from "../../store/object/statement/actions";
import { actions as estimateObjectActions } from "../../store/object/estimate/actions";

import { customerSelector } from "../../store/object/customer/selectors";

import { AccountSelectModal } from "../Portal/App/AccountSelectModal";
import * as components from "./App/components";
import { PortalFooter } from "./App/Footer";
import { PortalHeader } from "./App/Header";
import { PortalSidebar } from "./App/Sidebar";

import { WEB_ROUTE_FORBIDDEN } from "../../constants/routes";

const Coralogix = require("coralogix-logger");

class PortalAppComponent extends Component {
  
  logger;

  constructor(props) {
    super(props);
    this.state = {
      accounts: getPortalAccounts(),
      user: getUser(),
      currentAccount: getCurrentAccountObject(),
    };
  }

  logout = () => {
    const { history, logout } = this.props;
    logout();
    goToLogin(history);
  };

  componentDidCatch(error, errorInfo) {
    const message = get(error, 'message', 'Error');
    const componentStack = get(errorInfo, 'componentStack');
    const text = `${message}\nStacktrace:\n\t${componentStack}`
    const log = new Coralogix.Log({
        severity:Coralogix.Severity.error,
        text: text,
    });
    // send log to coralogix
    if(this.logger){
      this.logger.addLog(log);
    }
  }

  componentDidMount() {
    this.logger = new Coralogix.CoralogixLogger("App");
    const { toggleAccountSelectModalVisible, history } = this.props;
    const { currentAccount, accounts } = this.state;
    // If the current account is null, must show account select modal.
    if (isNull(currentAccount) && accounts.length > 1) {
      return toggleAccountSelectModalVisible(true);
    }
    if(accounts.length){
      setCurrentAccount(accounts[0]);
      this.setState({
        currentAccount: accounts[0]
      });
    } else {
      // User has no portaL accounts, cannot access portal.
      history.push(WEB_ROUTE_FORBIDDEN);
    }
  }

  render() {
    const {
      history,
      isAccountSelectModalVisible,
      toggleAccountSelectModalVisible,
      customer,
      resetCustomer,
      resetEstimates,
      resetInvoices,
      resetStatements,
    } = this.props;
    const { user, accounts, currentAccount } = this.state;
    return (
      <div className="view-portal d-flex justify-flex-start">
        <div className="view-portal-container">
          <ToastContainer />
          <PortalHeader onLogout={this.logout} />
          <main className="view-portal-main position-relative">
            <PortalSidebar
              account={currentAccount}
              accounts={accounts}
              history={history}
            />
            <div className="portal-dynamic-view with-sidebar">
              {parseFloat(get(customer, "balance.credit_current", 0)) > 0 && (
                <Message positive className="m-1">
                  <Message.Header>Account Balance Credit</Message.Header>
                  <p>
                    Your account has a credit balance of{" "}
                    <strong>
                      {`$${parseFloat(get(customer, "balance.credit_current", 0)).toFixed(2)}`}
                    </strong>
                  </p>
                </Message>
              )}
              <Suspense fallback={<Loader active>Loading</Loader>}>
                <Switch>
                  <Route
                    path={constants.WEB_ROUTE_PORTAL_BILLING}
                    exact
                    component={components.BillingComponent}
                  />
                  <Route
                    path={constants.WEB_ROUTE_PORTAL_DEFAULT}
                    exact
                    component={components.HomeComponent}
                  />
                  <Route
                    path={constants.WEB_ROUTE_PORTAL_SERVICE}
                    exact
                    component={components.ServiceComponent}
                  />
                </Switch>
              </Suspense>
            </div>
          </main>
          <PortalFooter />
        </div>
        <AccountSelectModal
          isOpen={isAccountSelectModalVisible}
          user={user}
          activeAccount={currentAccount}
          accounts={accounts}
          onClose={() => {
            toggleAccountSelectModalVisible(false);
          }}
          onSelect={(account) => {
            setCurrentAccount(account);
            resetCustomer();
            resetInvoices();
            resetEstimates();
            resetStatements();
            this.setState({
              currentAccount: account,
            });
          }}
        />
      </div>
    );
  }
}

PortalAppComponent.propTypes = {
  logout: func.isRequired,
  account: object,
  toggleAccountSelectModalVisible: func,
  isAccountSelectModalVisible: bool,
  history: object,
  resetCustomer: func,
  resetEstimates: func,
  resetInvoices: func,
  resetStatements: func,
};
PortalAppComponent.displayName = "Customer Portal";
PortalAppComponent.defaultProps = {
  account: null,
};

const mapStateToProps = (state) => {
  const account = getCurrentAccountObject();
  const objectSelector = customerSelector();
  return {
    customer: objectSelector.getDenormalizedObject(get(account, "customer.id"))(
      state
    ),
    isAccountSelectModalVisible: appSelectors.isAccountSelectModalVisible()(
      state
    ),
  };
};

export const PortalApp = withRouter(
  connect(
    mapStateToProps,
    {
      ...actions,
      toggleAccountSelectModalVisible:
        appActions.toggleAccountSelectModalVisible,
      resetCustomer: customerObjectActions.reset,
      resetInvoices: invoiceObjectActions.reset,
      resetStatements: statementObjectActions.reset,
      resetEstimates: estimateObjectActions.reset,
    }
  )(PortalAppComponent)
);
