import { any } from "prop-types";
import React from "react";
import { WEB_ROUTE_CUSTOMER_EDIT } from "../../../../constants";
import { isNumber } from "lodash";
import { Icon } from "semantic-ui-react";

export const CustomerNameLinked = React.memo(({ value }) => {
  let tmp = value.split(" ");
  const id = isNumber(parseInt(tmp[tmp.length - 1])) ? tmp.pop() : null;
  if (id) {
    return (
      <div className='no-wrap'>
        <a
          className="mr-1"
          href={WEB_ROUTE_CUSTOMER_EDIT.replace(":id", id)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="external" />
        </a>
        <span className="cell">{tmp.join(" ")}</span>
      </div>
    );
  }
  return <span className="cell no-wrap">{tmp.join(" ")}</span>;
});

CustomerNameLinked.propTypes = {
  value: any,
};
