import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "chemical";
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}admin/chemical`;
export const FETCH_URL = `${process.env.REACT_APP_API_URL}admin/chemical/svc`;
export const DELETE_URL = `${process.env.REACT_APP_API_URL}admin/chemical/delete/:chemicalId`;
export const TOGGLE_URL = `${process.env.REACT_APP_API_URL}admin/chemical/toggle/:chemicalId`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}admin/chemical`;
export const FETCH_CHEMICALS_AT_SERVICE_LOCATION_URL = `${process.env.REACT_APP_API_URL}admin/chemical/svcChemAtSvcLoc`;
export const REDUCER_NAME = "chemicals";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  DELETE_URL,
  FETCH_CHEMICALS_AT_SERVICE_LOCATION_URL,
  FETCH_LIST_URL,
  FETCH_URL,
  TOGGLE_URL,
  UPSERT_URL,
  UPSERT_PROPERTY
};
