import { schema } from 'normalizr';

import {
  REDUCER_NAME as PURCHASE_ORDER_REDUCER_NAME,
} from '../../store/object/purchaseOrder/constants';
import { locationSchema } from './location';
import { notesSchema } from './note';
import { userSchema } from './user';
import { vendorSchema } from './vendor';

export const purchaseOrderSchema = new schema.Entity(
  PURCHASE_ORDER_REDUCER_NAME,
  {
    billing_location: locationSchema,
    location: locationSchema,
    notes: notesSchema,
    vendor: vendorSchema,
    user: userSchema
  }
);

export const purchaseOrdersSchema = [purchaseOrderSchema];
