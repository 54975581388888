import React from 'react';

import { get } from 'lodash';
import {
  bool,
  element,
  number,
  object,
  string,
} from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Button,
  Icon,
  Label,
} from 'semantic-ui-react';

import {
  MAX_LIMIT,
  WEB_ROUTE_INVOICE_CREATE,
} from '../../constants';
import { tableState } from '../../helpers/shapes';
import { ListHeaderLimitSearch } from './LimitSearch';

/**
 * A component used to render controls such as filters, page size, and search inputs for a list page that has a table
 */
export const ListHeader = React.memo(
  ({
    isSubtitle,
    label,
    labelColor,
    title,
    link,
    leftChildren,
    rightChildren,
    searchPlaceholder,
    tableState,
    total,
    limitCustomizable = false,
    maxLimit = MAX_LIMIT,
    isSearchable = true,
    extendedSearchOptions = {},
    searchDisabled = false,
  }) => {
    const onSearchChange = get(tableState, "onSearchChange");
    const onLimitChange = get(tableState, "onLimitChange");
    const onEnter = get(tableState, "onEnter");
    const hasLeftChildren = leftChildren;
    return (
      <header className="list-header">
        <div className="d-flex justify-space-between align-items-center flex-wrap mb-1">
          {title ? (
            <div className="title">
              {isSubtitle ? (
                <h2 className="text-gray55 text-light">
                  <span>{title}</span>
                </h2>
              ) : (
                <h1 className="text-gray55">
                  <span>{title}</span>
                  {label && (
                    <Label
                      color={labelColor || "green"}
                      className="ml-2 pull-right"
                    >
                      {label}
                    </Label>
                  )}
                </h1>
              )}
            </div>
          ) : (
            <div></div>
          )}
          <div className="actions">
            {!hasLeftChildren && (
              <div className="limit-search d-inline-block mr-2">
                <ListHeaderLimitSearch
                  searchPlaceholder={searchPlaceholder}
                  onLimitChange={onLimitChange}
                  onSearchChange={onSearchChange}
                  tableState={tableState}
                  total={total}
                  limitCustomizable={limitCustomizable}
                  maxLimit={maxLimit}
                  isSearchable={isSearchable}
                  extendedSearchOptions={extendedSearchOptions}
                  searchDisabled={searchDisabled}
                  onEnter={onEnter}
                />
              </div>
            )}
            {link && (
              <Button
                primary
                className="px-4"
                size="large"
                as={Link}
                to={{
                  pathname: link.route,
                  state: link.state,
                }}
              >
                { [WEB_ROUTE_INVOICE_CREATE].indexOf(get(link, 'route')) >= 0 && <Icon name='lock' /> }
                <span className="px-4">New</span>
              </Button>
            )}
            {rightChildren}
          </div>
        </div>

        <div className="d-flex align-items-end justify-flex-end flex-wrap">
          <div className="flex-grow-1">{leftChildren}</div>
          {hasLeftChildren && (
            <ListHeaderLimitSearch
              searchPlaceholder={searchPlaceholder}
              onLimitChange={onLimitChange}
              onSearchChange={onSearchChange}
              tableState={tableState}
              total={total}
              limitCustomizable={limitCustomizable}
              maxLimit={maxLimit}
              isSearchable={isSearchable}
              extendedSearchOptions={extendedSearchOptions}
              onEnter={onEnter}
            />
          )}
        </div>
      </header>
    );
  }
);

ListHeader.propTypes = {
  isSubtitle: bool,
  label: string,
  link: object,
  searchPlaceholder: string,
  rightChildren: element,
  tableState: tableState,
  title: string,
  total: number,
  extendedSearchOptions: object,
  limitCustomizable: bool,
};
