import React from "react";
import { useHistory } from "react-router-dom";
import { array, string, func } from "prop-types";
import { Tab } from "semantic-ui-react";

export const TabPanel = ({ panes, className, onTabChange = false }) => {
  const history = useHistory();
  const {
    location: { hash }
  } = history;

  const activeIndex = hash
    ? panes.findIndex(pane => {
        return `#${pane.slug}` === hash;
      })
    : 0;

  return (
    <Tab
      activeIndex={activeIndex}
      className={className}
      onTabChange={(event, data) => {
        const { activeIndex } = data;
        const pane = panes[activeIndex];
        history.push(`#${pane.slug}`);
        if(onTabChange){
          onTabChange(data);
        }
      }}
      panes={panes}
    />
  );
};

TabPanel.propTypes = {
  className: string,
  panes: array.isRequired,
  onTabChange: func,
};
