import { schema } from 'normalizr';

import {
  REDUCER_NAME as GOOD_VENDOR_REDUCER_NAME,
} from '../../store/object/goodService/constants';
import { goodSchema } from './good';
import { productClassSchema } from './productClass';

export const goodServiceSchema = new schema.Entity(GOOD_VENDOR_REDUCER_NAME, {
  good: goodSchema,
  product_class: productClassSchema
});

export const goodServicesSchema = [goodServiceSchema];
