import { createSelector } from 'reselect';

import * as constants from './constants';

export const authReducer = () => (state) => state.get(constants.REDUCER_NAME);

export const getUser = () =>
  createSelector(
    authReducer(),
    (state) => state.get(constants.USER).toJS()
  );

export const getAuthenticationError = () =>
  createSelector(
    authReducer(),
    (state) => state.get(constants.AUTHENTICATION_ERROR)
  );

export const getValidationError = () =>
  createSelector(
    authReducer(),
    state => state.get(constants.VALIDATION_ERROR)
  );

export const getVerificationError = () =>
  createSelector(
    authReducer(),
    state => state.get(constants.VERIFICATION_ERROR)
  );

export const isAuthenticating = () =>
  createSelector(
    authReducer(),
    (state) => state.get(constants.IS_AUTHENTICATING)
  );

export const getRefreshError = () =>
  createSelector(
    authReducer(),
    (state) => state.get(constants.REFRESH_ERROR)
  );

export const isRefreshing = () =>
  createSelector(
    authReducer(),
    (state) => state.get(constants.IS_REFRESHING)
  );

export const isValidating = () =>
  createSelector(
    authReducer(),
    state => state.get(constants.IS_VALIDATING)
  );

export const isVerifyingToken = () =>
  createSelector(
    authReducer(),
    state => state.get(constants.IS_VERIFYING_TOKEN)
  );  