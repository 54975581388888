import { schema } from 'normalizr';

import {
  INFO_CALL_ACTION_REDUCER_NAME,
} from '../../store/object/infoCallAction';

export const infoCallActionSchema = new schema.Entity(
  INFO_CALL_ACTION_REDUCER_NAME
);

export const infoCallActionsSchema = [infoCallActionSchema];
