import { any, string, bool } from "prop-types";
import React from "react";
import dayjs from "dayjs";

export const DateTimeCell = React.memo(({ value, label }) => {
  let date;
  if(value){
    date = dayjs(value).format("MM/DD/YYYY hh:mm A");
  }
  return <div>
    {label && <div><label><strong>{label}</strong></label></div>}
    <div className="date-cell fs-3 no-wrap pl-1">{date}</div>
  </div>;
});

DateTimeCell.propTypes = {
  value: any,
  utc: bool,
  label: string,
};
