import React, {
  useEffect,
} from 'react';
import { func, bool, any, array } from 'prop-types';
import { connect } from 'react-redux';
import {
  Dimmer,
  Loader,
  Message,
  Modal,
} from 'semantic-ui-react';

import { actions as objectActions } from "../../../../store/object/salesGoal/actions";
import { actions as routeActions } from "../../../../store/object/route/actions";
import {
  salesGoalRequestSelector,
} from '../../../../store/request/salesGoal/selectors';
import { routeRequestSelector } from '../../../../store/request/route/selectors';
import { routeSelector } from '../../../../store/object/route/selectors';
import { PageAlert } from '../../../../components/PageAlert';
import { AddGoalForm } from './AddGoalForm/index';
import { GoalsList } from './GoalsList';

/**
 * SetGoalsModal
 * Allows user to post sales goal values to API.
 * Only show this component to user with role of Commissions Manager
 * 
 */
export const SetGoalsModalComponent = ({
  isOpen,
  loadError,
  onClose,
  isLoadingAll,
  isLoadedAll,
  isSaving,
  fetchGoals,
  upsertGoal,
  typeFilters,
  routes,
  fetchRoutes,
  includeServiceRoute,
  isLoadingAllRoutes,
  isLoadedAllRoutes,
  loadErrorRoutes,
}) => {
  // Load the goals
  useEffect(() => {
    if (isOpen && !isLoadedAll && !loadError) {
      fetchGoals()
    }
    if (isOpen && !isLoadedAllRoutes && !loadErrorRoutes) {
      fetchRoutes()
    }
  }, [isOpen, fetchGoals, isLoadedAll, loadError, isLoadedAllRoutes, isLoadingAllRoutes, fetchRoutes, loadErrorRoutes]);
  return (
    <>
      <Modal open={isOpen} onClose={onClose} closeIcon>
        {!isLoadingAll && !isLoadingAllRoutes ? (
          <>
            <Modal.Header className="d-flex justify-content-between">
              <span className="flex-grow-1">
                Goals
              </span>
            </Modal.Header>
            <Modal.Content scrolling>
              {loadError && <Message error content={loadError} />}
              <GoalsList typeFilters={typeFilters} />
            </Modal.Content>
            <Modal.Actions>
              <AddGoalForm isSavingGoal={isSaving} onSubmit={upsertGoal} typeFilters={typeFilters} includeServiceRoute={includeServiceRoute} serviceRoutes={routes} />
            </Modal.Actions>
          </>
        ) : (
          <Modal.Content className="py-4 my-4">
            {loadError ? (
              <PageAlert title="Error" message={loadError} />
            ) : (
              <Dimmer active inverted>
                <Loader size="large" inline="centered">
                  {isSaving ? "Updating Goals..." : "Loading Goals..."}
                </Loader>
              </Dimmer>
            )}
          </Modal.Content>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state, props) => {
  const requestSelector = salesGoalRequestSelector();
  const routeSelectorHandle = routeSelector();
  const routeRequestSelectorHandle = routeRequestSelector();
  return {
    isLoadingAll: requestSelector.getIsLoadingAll()(state),
    isLoadedAll: requestSelector.getIsLoadedAll()(state),
    isSaving: requestSelector.getIsSaving()(state),
    loadError: requestSelector.getLoadAllError()(state),
    routes: routeSelectorHandle.getObjects()(state),
    isLoadingAllRoutes: routeRequestSelectorHandle.getIsLoadingAll()(state),
    isLoadedAllRoutes: routeRequestSelectorHandle.getIsLoadedAll()(state),
    loadErrorRoutes: routeRequestSelectorHandle.getLoadAllError()(state),
  };
};

SetGoalsModalComponent.propTypes = {
  isOpen: bool,
  loadError: any,
  onClose: func,
  isLoading: bool,
  isSaving: bool,
  addLine: func,
  fetchObject: func,
  upsertLines: func,
  typeFilters: array,
  fetchRoutes: func,
  includeServiceRoute: bool,
};

export const SetGoalsModal = connect(
  mapStateToProps,
  {
    ...objectActions,
    fetchRoutes: routeActions.fetchObjects,
  }
)(SetGoalsModalComponent);
