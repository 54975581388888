import { makeActionCreator } from '../../helpers/actions';
import {
  RESET,
  SET_CONFIRMATION_MODAL_TEXT,
  SET_CURRENT_LOCATION_ID,
  SET_CURRENT_PORTAL_ACCOUNT_ID,
  SET_PORTAL_ACCOUNTS,
  TOGGLE_CONFIRMATION_MODAL_VISIBLE,
  TOGGLE_CONFIRMATION_MODAL_YES_CLICKED,
  TOGGLE_PORTAL_ACCOUNT_SELECT_MODAL,
  LOCK_CURRENT_LOCATION,
  SET_SIDEBAR_IS_OPEN,
} from "./constants";

export const reset = makeActionCreator(RESET);

export const toggleConfirmationModalVisible = makeActionCreator(
  TOGGLE_CONFIRMATION_MODAL_VISIBLE,
  "isVisible"
);

export const toggleConfirmationModalYesClicked = makeActionCreator(
  TOGGLE_CONFIRMATION_MODAL_YES_CLICKED,
  "isClicked"
);

export const toggleAccountSelectModalVisible = makeActionCreator(
  TOGGLE_PORTAL_ACCOUNT_SELECT_MODAL,
  "isAccountSelectVisible"
);

export const setConfirmationModalText = makeActionCreator(
  SET_CONFIRMATION_MODAL_TEXT,
  "text"
);

export const setCurrentLocationId = makeActionCreator(
  SET_CURRENT_LOCATION_ID,
  "locationId"
);

export const setCurrentPortalAccountId = makeActionCreator(
  SET_CURRENT_PORTAL_ACCOUNT_ID,
  "accountId"
);

export const setPortalAccounts = makeActionCreator(
  SET_PORTAL_ACCOUNTS,
  "accounts"
);

export const lockCurrentLocation = makeActionCreator(
  LOCK_CURRENT_LOCATION,
  "isLocked"
);

export const setSidebarIsOpen = makeActionCreator(
  SET_SIDEBAR_IS_OPEN,
  "isOpen"
);
