import { makeActionCreator } from '../../../helpers/actions';
import { makeViewActions } from '../actions';
import { allConstants as constants } from './constants';

const viewActions = makeViewActions(constants);

const setDate = makeActionCreator(constants.SET_DATE, "dateType", "date");
const setMonth = makeActionCreator(constants.SET_MONTH, "month");
const setYear = makeActionCreator(constants.SET_YEAR, "year");
export const actions = { ...viewActions, setDate, setMonth, setYear };
