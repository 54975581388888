import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "serviceVisit";
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}admin/customer/saveactivity`;
export const TOGGLE_URL = `${process.env.REACT_APP_API_URL}admin/activity/toggleVisit/:visitId`;
export const FETCH_DATE_RANGE_URL = `${process.env.REACT_APP_API_URL}admin/activity/detail/:startDate/:endDate`;
export const FETCH_MONTH_YEAR_URL = `${process.env.REACT_APP_API_URL}admin/activity/:month/:year`;
export const REDUCER_NAME = "serviceVisits";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_DATE_RANGE_URL,
  FETCH_MONTH_YEAR_URL,
  TOGGLE_URL,
  UPSERT_URL,
  UPSERT_PROPERTY
};
