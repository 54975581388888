import {
  array,
  arrayOf,
  bool,
  func,
  number,
  object,
  shape,
  string
} from "prop-types";
import { column } from "./column";

export const sortOrder = shape({
  column: array.isRequired | string.isRequired,
  isAscending: bool
});

export const tableState = shape({
  columns: arrayOf(column).isRequired,
  onLimitChange: func,
  onPageChange: func,
  onRefreshClick: func,
  onSearchChange: func,
  onSortChange: func,
  filter: object,
  page: number,
  limit: number.isRequired,
  search: string,
  sortOrder: sortOrder
});
