import { get } from 'lodash';
import { formatCurrency } from '../../../helpers/util';
import moment from 'moment';

/**
 * Make a big stat object for a Sales Dashboard
 * @param {number} id 
 * @param {object} bigStat 
 * @param {object} rows 
 * @returns {object}
 */
const makeBigStat = (id, bigStat, rows) => {
  return {
    id: id,
    rows: Object.keys(rows).map((title, index) => {
      return {
        id: index,
        title: title,
        value: rows[title]
      }
    }),
    bigStat: bigStat,
  }
}

/**
 * Get graph data for the Signed Proposals Card
 * @param {object} signedMonthlyTotals 
 * @param {object} signedMonthlyGoals 
 * @param {string} year 
 * @returns {array}
 */
const makeSignedProposalsGraphData = (data) => {
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const signedMonthlyTotals = get(data, 'proposals.current_year.signed.monthly_totals', []);
  const signedMonthlyGoals = get(data, 'proposals.current_year.goals.signed.monthly_totals', []);
  const year = get(data, 'proposals.current_year.year', '');
  return [
    {
      label: "Signed",
      data: signedMonthlyTotals.length === 0 ? [
        {
          date: new Date(),
          number: 0,
        },
      ] : Object.keys(signedMonthlyTotals).sort().map((month) => {
        const monthNumber = new Date(`${month}-01-${year}`).getMonth();
        return {
          date: `${monthNames[monthNumber]}`,
          number: signedMonthlyTotals[month],
        }
      }),
    },
    {
      label: "Goal",
      data: signedMonthlyGoals.length === 0 ? [
        {
          date: new Date(),
          number: 0,
        },
      ] : Object.keys(signedMonthlyGoals).map((month) => {
        const monthNumber = new Date(`${month}-01-${year}`).getMonth();
        return {
          date: `${monthNames[monthNumber]}`,
          number: signedMonthlyGoals[month],
        }
      }),
    },
  ];
}

/**
 * Get data for the Signed Proposals Card
 * @param {object} data 
 * @returns {array}
 */
const makeSignedProposalsMonthToDateData = data => {
  return [
    makeBigStat(1,
      get(data, 'proposals.current_month.goals.signed_percent'),
      { 'Signed': get(data, 'proposals.current_month.signed'), 'Goal': get(data, 'proposals.current_month.goals.signed') }
    ),
    makeBigStat(2,
      get(data, 'proposals.current_month.goals.adds_cw_percent'),
      { 'Adds-CW': get(data, 'proposals.current_month.adds_cw'), 'Goal': get(data, 'proposals.current_month.goals.adds_cw') }
    ),
  ]
};

/**
 * Get year-to-date data for the Signed Proposals Card
 * @param {object} data 
 * @returns {array}
 */
const makeSignedProposalsYearToDateData = data => {
  return [
    makeBigStat(1,
      get(data, 'proposals.current_year.goals.signed_percent'),
      { 'Signed': get(data, 'proposals.current_year.signed.total'), 'Goal': get(data, 'proposals.current_year.goals.signed.total') }
    ),
    makeBigStat(2,
      formatCurrency(get(data, 'proposals.current_year.install_average', 0)),
      { 'Install Average': '' }
    ),
    makeBigStat(3,
      get(data, 'proposals.current_year.goals.adds_cw_percent'),
      { 'Adds-CW': get(data, 'proposals.current_year.adds_cw'), 'Goal': get(data, 'proposals.current_year.goals.adds_cw') }
    )
  ]
};

/**
 * Get data for the Pipeline Funnel Card
 * @param {object} data 
 * @returns {array}
 */
const makePipelineFunnelBigStatsData = data => {
  return [
    {
      title: "Bed Packets Filled Out",
      total: get(data, 'pipeline_funnel.bed_packets_filled_out', 0),
    },
    {
      title: "Bed Packet Diagrams",
      total: get(data, 'pipeline_funnel.bed_packet_diagrams', 0),
    },
    {
      title: "Rose Order",
      total: get(data, 'pipeline_funnel.rose_order', 0),
    },
    {
      title: "Deposit",
      total: get(data, 'pipeline_funnel.deposit', 0),
    },
  ]
};

/**
 * Get totals data for the Pipeline Funnel Card
 * @param {object} data
 * @returns {array}
 */
const makePipelineFunnelTotalsData = data => {
  return [
    {
      title: "Estimates Sent",
      total: get(data, 'pipeline_funnel.estimates_sent', 0),
    },
    {
      title: "Estimates Accepted",
      total: get(data, 'pipeline_funnel.estimates_accepted', 0),
    },
    {
      title: "Accepted & Deposited",
      total: get(data, 'pipeline_funnel.estimates_accepted_and_deposited', 0),
    },
    {
      title: "Bed Packets Ready",
      total: get(data, 'pipeline_funnel.bed_packets_ready', 0),
    },
  ];
};

/**
 * Get data for the Bed Packets Card
 * @param {object} data
 * @returns {array}
 */
const makeBedPacketsCardData = data => {
  return [
    makeBigStat(1,
      get(data, 'bed_packets.completed'),
      { 'Completed': '' }
    ),
    makeBigStat(2,
      get(data, 'bed_packets.billed'),
      { 'Billed': '' }
    ),
  ];
};

/**
 * Get graph data for the Commissions Earned Card
 * @param {object} data
 * @returns {array}
 */
const makeCommissionsEarnedCardGraphData = data => {
  const userCommissions = get(data, 'commissions.annual_by_user', []);
  const commissionsEarnedCardGraphData = userCommissions.map(user => {
    const userData = get(user, 'annual_commission_data', {});
    const forYear = Object.keys(userData).map(year => {
      return Object.keys(userData[year]).map(month => {
        return {
          date: new Date(`${month}-01-${year}`),
          number: userData[year][month],
        }
      })
    });
    return {
      label: get(user, 'user.name'),
      data: forYear[0]
    }
  });
  // If it's empty, give it a placeholder
  if (commissionsEarnedCardGraphData.length === 0) {
    commissionsEarnedCardGraphData.push({
      label: "Placeholder",
      data: [
        {
          date: new Date(),
          number: 0,
        }
      ]
    })
  }
  return commissionsEarnedCardGraphData;
};

/**
 * Get data for the Commissions Earned Card
 * @param {object} data 
 * @returns {array}
 */
const makeCommissionsEarnedCardData = (data) => {
  const commissions = get(data, 'commissions.earned', {});
  const currentYear = moment().format('YYYY');
  const currentMonth = moment().format('MM');
  const currentMonthCommissions = get(commissions, `${currentYear}.${currentMonth}`, 0);
  const currentYearCommissions = Object.values(get(commissions, `${currentYear}`, {})).reduce((prev, curr) => {
    return prev + parseFloat(curr);
  }, 0);
  return [
    makeBigStat(1,
      formatCurrency(currentMonthCommissions),
      { 'Month': '' }
    ),
    makeBigStat(2,
      formatCurrency(currentYearCommissions),
      { 'Year': '' }
    ),
  ]
};

/**
 * Get data for the Bed Invoices Card
 * @param {object} data
 * @returns {array}
 */
const makeBedInvoicesCardData = (data) => {
  return [
    makeBigStat(1,
      formatCurrency(get(data, 'bed_invoices.billed_outstanding_total')),
      { 'Billed but not collected': '' }
    ),
    makeBigStat(2,
      get(data, 'bed_invoices.billed_outstanding_count'),
      { 'Billed but not fully paid': '' }
    ),
    makeBigStat(3,
      get(data, 'bed_invoices.year_to_date.invoices.with_services_count'),
      { 'YTD: Bed invoices with services': '' }
    ),
    makeBigStat(4,
      get(data, 'bed_invoices.year_to_date.invoices.without_services_count'),
      { 'YTD: Bed invoices without services': '' }
    ),
    makeBigStat(5,
      formatCurrency(get(data, 'bed_invoices.year_to_date.estimates.all_total')),
      { 'YTD: Estimate Total $': '' }
    ),
    makeBigStat(6,
      get(data, 'bed_invoices.year_to_date.estimates.all_count'),
      { 'YTD: Estimate Total qty': '' }
    ),
    makeBigStat(7,
      formatCurrency(get(data, 'bed_invoices.year_to_date.estimates.invoiced_total')),
      { 'YTD: Estimate Total $ (invoiced)': '' }
    ),
    makeBigStat(8,
      get(data, 'bed_invoices.year_to_date.estimates.invoiced_count'),
      { 'YTD: Estimate Total qty (invoiced)': '' }
    ),
  ];
};

export {
  makeBigStat,
  makeSignedProposalsGraphData,
  makeSignedProposalsMonthToDateData,
  makeSignedProposalsYearToDateData,
  makePipelineFunnelBigStatsData,
  makePipelineFunnelTotalsData,
  makeBedPacketsCardData,
  makeCommissionsEarnedCardGraphData,
  makeCommissionsEarnedCardData,
  makeBedInvoicesCardData,
};
