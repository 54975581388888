import dayjs from "dayjs";
import { get, has, isNull, omit } from "lodash";
import {
  INVOICE_STATUS_SAVED,
  INVOICE_TYPE_POS,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PAID_DONE,
  INVOICE_STATUS_DONE,
} from "../../../../constants";
import { getLocationCodeById } from "../../../../helpers/invoice";
import * as accounting from "accounting";
import { calculateTax } from "./requests";
import uuid from "uuid";
const camelcaseKeys = require("camelcase-keys");
const doneStatuses = [INVOICE_STATUS_PAID, INVOICE_STATUS_PAID_DONE, INVOICE_STATUS_DONE];

export const convertInvoiceLinesForTaxCalc = (
  lines = [],
  taxRates,
  allLocations = []
) => {
  return lines.map((line) => {
    return {
      amount: line.amount,
      completed: !!line.completed,
      completed_date: !!line.completed_date,
      discount: line.discount,
      discount_pct: line.discount_pct,
      ext: line.ext || line.qty * line.price - line.discount,
      good: omit(line, [
        "amount",
        "discount",
        "qty",
        "completed",
        "ext",
        "paid",
        "price",
      ]),
      paid: !!line.paid,
      price: line.price,
      qty: line.qty,
      taxRate: get(taxRates, "location.rate"),
      taxRates: taxRates,
      taxable: !line.taxable || line.taxable === "n" || line.taxable === "0" ? false : true,
      taxjar_id: get(line, "taxjar_id"),
      loc:
        get(line, "loc") ||
        getLocationCodeById(allLocations, get(line, "inv.location_id")),
    };
  });
};

export const convertInvoiceLinesForSave = (
  lines = [],
  invoice,
  allLocations = []
) => {
  // Due to rounding errors in taxes we need to make sure this tolerance will handle
  // very small increments to avoid marking the invoice as paid when it is paid_done.

  const TOLERANCE = 1e-10;

  const balance = parseFloat(get(invoice, "balance"));
  const adjustedBalance = Math.abs(balance) < TOLERANCE ? 0 : balance;

  const paidPos =
    adjustedBalance === 0 &&
    get(invoice, "total") > 0 &&
    get(invoice, "type") === INVOICE_TYPE_POS;

  return lines.map((line) => {
    // If balance is 0 and invoice type is POS, mark all lines completed.
    if (paidPos) {
      if (isNull(line.completed_date)) {
        line.completed = true;
        line.completed_date = new Date();
      }
    }

    if (line.good) {
      // Pre-existing line item (good)
      return {
        ...line,
        good: omit(line.good, [
          "active_prices",
          "amount",
          "discount",
          "qty",
          "completed",
          "ext",
          "inventory_lots",
          "paid",
          "price",
          "vendors",
        ]),
        type: get(invoice, "type", null),
        loc: findLocationCodeInLineItem(line, allLocations),
        item: get(line, "good.name", null),
        sku: get(line, "good.sku", null),
        paid:
          get(line, "paid") === true || get(line, "paid") === "y"
            ? true
            : false,
      };
    } else if (line.original_good) {
      // Pre-existing line item (open-item)
      return {
        ...line,
        type: get(invoice, "type", null),
        loc: findLocationCodeInLineItem(line, allLocations),
        item: get(line, "item", null),
        sku: get(line, "sku", null),
        paid:
          get(line, "paid") === true || get(line, "paid") === "y"
            ? true
            : false,
      };
    }
    // New item (no id, i.e. not yet saved)
    return {
      amount: line.amount,
      completed: !!line.completed,
      completed_date: line.completed_date,
      discount: line.discount,
      discount_pct: line.discount_pct,
      ext: line.ext || line.qty * line.price - line.discount,
      good: omit(line, [
        "active_prices",
        "amount",
        "discount",
        "qty",
        "completed",
        "ext",
        "inventory_lots",
        "paid",
        "price",
        "vendors",
      ]),
      id: uuid(),
      initial_price: get(line, "initial_price"),
      item: get(line, "name"),
      paid: !!line.paid,
      price: line.price,
      price_change_pin: get(line, "price_change_pin", null),
      price_change_reason: get(line, "price_change_reason", null),
      price_changed_at: get(line, "price_changed_at", null),
      price_discount_pin: get(line, "price_discount_pin", null),
      price_discount_reason: get(line, "price_discount_reason", null),
      price_discounted_at: get(line, "price_discounted_at", null),
      qty: line.qty,
      type: get(invoice, "type", null),
      tax: get(line, "tax", null),
      taxRate: line.taxRate,
      tax_rate: get(line, "tax_rate", null),
      taxable: !!line.taxable,
      tax_exempt_at: get(line, "tax_exempt_at", null),
      tax_exempt_pin: get(line, "tax_exempt_pin", null),
      taxjar_id: get(line, "taxjar_id"),
      loc: findLocationCodeInLineItem(line, allLocations),
      location_id: get(line, "location_id"),
      sku: get(line, "sku", null),
    };
  });
};

export const findLocationCodeInLineItem = (line, allLocations) => {
  switch (true) {
    case has(line, "loc") && !isNull(get(line, "loc")):
      return get(line, "loc", null);
    case has(line, "location_code") && !isNull(get(line, "location_code")):
      return get(line, "location_code", null);
    case has(line, "good.inv.location_id") &&
      !isNull(get(line, "good.inv.location_id")):
      return getLocationCodeById(
        allLocations,
        get(line, "good.inv.location_id")
      );
    case has(line, "original_good_unserialized.location_id") &&
      !isNull(get(line, "original_good_unserialized.location_id")):
      return getLocationCodeById(
        allLocations,
        get(line, "original_good_unserialized.location_id")
      );
    case has(line, "original_good_unserialized.inv.location_id") &&
      !isNull(get(line, "original_good_unserialized.inv.location_id")):
      return getLocationCodeById(
        allLocations,
        get(line, "original_good_unserialized.inv.location_id")
      );
    default:
      return null;
  }
};

export const convertInvoiceForSave = (invoice, allLocations = []) => {
  const addresses = {};

  if (get(invoice, "shipment_name")) {
    addresses.shipment = {
      name: get(invoice, "shipment_name"),
      address1: get(invoice, "shipment_address1"),
      address2: get(invoice, "shipment_address2"),
      city: get(invoice, "shipment_city"),
      state: get(invoice, "shipment_state"),
      postal_code: get(invoice, "shipment_postal_code"),
      note: get(invoice, "shipment_note"),
    };
  }
  if (get(invoice, "delivery_name")) {
    addresses.delivery = {
      name: get(invoice, "delivery_name"),
      address1: get(invoice, "delivery_address1"),
      address2: get(invoice, "delivery_address2"),
      city: get(invoice, "delivery_city"),
      state: get(invoice, "delivery_state"),
      postal_code: get(invoice, "delivery_postal_code"),
      note: get(invoice, "delivery_note"),
    };
  }

  return {
    invoiceObject: {
      ...omit(invoice, ["lines", "inventoryActivities", "inventory_activities"]),
      ...camelcaseKeys(omit(invoice, ["lines", "inventoryActivities", "inventory_activities"])),
      created: invoice.created || dayjs().format("YYYY-MM-DD"),
      status: invoice.status || INVOICE_STATUS_SAVED,
      location: invoice.location_id,
      customer_id: get(invoice, "customer.id", null),
      data: {
        grid: convertInvoiceLinesForSave(invoice.lines, invoice, allLocations),
        money: {
          balance: invoice.balance,
          deliveryCharge: invoice.delivery,
          subtotal: invoice.subtotal,
          tax: invoice.tax,
          total: invoice.total,
        },
        payments: invoice.payments,
      },
      addresses,
    },
  };
};

/**
 * Updates the totals on the invoice
 * @param invoice
 * @returns {Promise<{delivery: number, total: number, shipping: number, balance: number, subtotal: number, tax: number}>}
 */
export const calculateTotals = async (invoice) => {

  // If invoice status is paid, paid_done, do not recalculate tax, return totals straight from DB
  const status = get(invoice, "status");
  const invoiceType = get(invoice, "type");
  const lines = get(invoice, "lines", []);

  // Check if has returned items.
  const hasReturnedItems = lines.map((line) => {
    return get(line, "amount", 0) < 0;
  }).length > 0;

  // We must have an invoice type.
  if ((lines.length > 0 && doneStatuses.indexOf(status) < 0 && invoiceType) || hasReturnedItems) {

    // We will have the API calculate the totals and sales tax for us.
    const response = await calculateTax(invoice);

    // Will receive back the invoice object with the tax and totals calculated.
    const responseInvoice = get(response, "invoice");
    const responseBreakdown = get(response, "breakdown");

    // If we don't have a response, calculateTax will toast the error.
    if (responseInvoice) {
      // So we can merge these values into our invoice object to update the view/form
      // Need to update the lines with this tax data.
      const lines = invoice.lines.map((line) => {
        // Find the line in the response
        const responseLine = responseInvoice.lines.find((responseLine) => {
          return responseLine.id === line.id;
        });
        if (responseLine) {
          return {
            ...line,
            tax: responseLine.tax,
            tax_rate: responseLine.tax_rate,
            taxjar_id: responseLine.taxjar_id,
            final_price: responseLine.final_price,
          };
        }
        return line;
      });

      return {...invoice,
        lines: lines,
        tax: get(responseInvoice, 'tax', 0),
        freight_tax: get(responseInvoice, 'freight_tax', 0),
        tax_rate: get(responseInvoice, 'tax_rate', 0),
        tax_version: get(responseInvoice, 'tax_version', 0),
        tax_source: get(responseInvoice, 'tax_source', 0),
        taxjar_id: get(responseInvoice, 'taxjar_id', 0),
        balance: get(responseInvoice, 'balance', 0),
        subtotal: get(responseInvoice, 'subtotal', 0),
        total: get(responseInvoice, 'total', 0),
        shipping: get(responseInvoice, 'shipping', 0),
        delivery: get(responseInvoice, 'delivery', 0),
        tax_breakdown: responseBreakdown,
      };
    }
  }

  let tax = parseFloat(invoice.tax) || 0;
  let subtotal = parseFloat(invoice.subtotal) || 0;
  let total = parseFloat(invoice.total) || 0;
  let shipping = parseFloat(invoice.shipping) || 0;
  let delivery = parseFloat(invoice.delivery) || 0;
  let paymentsTotal = get(invoice, "payments", []).reduce(
    (accumulator, payment) => {
      return accumulator + parseFloat(get(payment, "amount"));
    },
    0
  );

  // If we have 0 lines, we have 0 sales tax.
  if (lines.length === 0) {
    tax = 0;
    subtotal = 0;
    total = 0;
  }

  paymentsTotal = accounting.toFixed(paymentsTotal, 2);

  total = accounting.toFixed(
    parseFloat(subtotal) +
    parseFloat(tax) +
    parseFloat(shipping) +
    parseFloat(delivery),
    2);

  const balance = accounting.toFixed(parseFloat(total) - parseFloat(paymentsTotal), 2);
  return { ...invoice, tax, subtotal, shipping, total, delivery, balance };
};

export const convertInvoiceFromLoad = (invoice) => {
  return {
    ...omit(invoice, ["shipment_info"]),
    shipping_info: invoice.shipment_info,
    first_name: get(invoice, "customer.first_name"),
    last_name: get(invoice, "customer.last_name"),
    locname: get(invoice, "location.name"),
  };
};
