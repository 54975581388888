import { fromJS } from "immutable";

/**
 * A default initial state for all of the reducers that sit in the store under ``
 * @param constants
 * @returns {any}
 */
export const initialReportState = constants =>
  fromJS({
    [constants.IS_LOADING]: false,
    [constants.PAGE]: [],
    [constants.RESULTS]: [],
    [constants.TOTAL]: 0
  });

/**
 * Creates a generic reducer for all of our object list reports
 * @param constants
 * @param initialState
 * @param fallBackReducer
 * @returns {Function}
 */
export const makeReportReducer = (constants, initialState, fallBackReducer) => {
  initialState = initialState || initialReportState;

  return (state = initialState(constants), action) => {
    switch (action.type) {
      case constants.SET_PAGE:
        return state
          .set(constants.PAGE, fromJS(action.data))
          .set(constants.TOTAL, action.count || 0);

      case constants.SET_LOAD_ERROR:
        return state.set(constants.LOAD_ERROR, action.error);

      case constants.SET_IS_LOADED:
        return state.setIn([constants.IS_LOADED], action.isLoaded);

      case constants.SET_IS_LOADING:
        return state.setIn([constants.IS_LOADING], action.isLoading);

      case constants.SET_RESULTS:
        return state.setIn([constants.RESULTS], fromJS(action.data));

      case constants.RESET:
        return initialState(constants);

      case constants.RESET_DATA:
        return state
          .set(constants.LOAD_ERROR, null)
          .set(constants.IS_LOADED, false)
          .set(constants.IS_LOADING, false)
          .set(constants.PAGE, fromJS([]))
          .set(constants.RESULTS, fromJS([]))
          .set(constants.TOTAL, 0);

      default:
        return fallBackReducer
          ? fallBackReducer(state, action, constants)
          : state;
    }
  };
};
