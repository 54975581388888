import React from "react";
import { find } from "lodash";
import { array, string, func } from "prop-types";
import cx from "classnames";

export const CardTabs = ({tabs, activeTab, tabPanes = [], setActiveTab}) => {
  return (
    <div className='card-tabs-container'>
      <div className="card-tabs p-3">
        {tabs.map(tab => {
          return (
            <div 
            key={tab.key} 
            onClick={() => {
              setActiveTab(tab.key)
            }}
            className={cx({
              "card-tab": true,
              "pointer": true,
              "active": tab.key === activeTab
            })}
            >{tab.title}</div>
          )
        })}
      </div>
      <div className='card-tab-pane'>
        {find(tabPanes, {key: activeTab})?.content}
      </div>
    </div>
  )
}

CardTabs.propTypes = {
  tabs: array,
  activeTab: string,
  tabPanes: array,
  setActiveTab: func,
};