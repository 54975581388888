import React from "react";

import cx from "classnames";
import { get } from "lodash";
import { object } from "prop-types";
import { Link } from "react-router-dom";
import { Icon, Label } from "semantic-ui-react";

const isItemOpen = (item, location) => {
  return (
    location.pathname === item.route ||
    get(location, "state.parent") === item.key
  );
};

/**
 * Renders a single menu item that does not have any children as a link
 * @param item
 * @param location
 * @returns {*}
 */
const renderItem = (item, location) => {
  const className = cx({
    active: isItemOpen(item, location),
    "portal-menu-item": true,
  });
  return (
    <li className={className}>
      <Link
        to={{ pathname: item.route }}
        className="d-block portal-button mini text-center"
      >
        <Icon name={item.icon} />
        <Label
          pointing="left"
          className="p-absolute portal-button-label fs-4 p-2 ml-8 shadow"
        >
          {item.label}
        </Label>
      </Link>
    </li>
  );
};

export const PortalMenuItem = React.memo(({ location, item }) => {
  return renderItem(item, location);
});

PortalMenuItem.propTypes = {
  location: object,
  item: object.isRequired,
};
