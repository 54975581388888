import React from "react";
import { Chart } from "react-charts";

export const Graph = ({ data }) => {
  const primaryAxis = React.useMemo(
    () => ({
      getValue: (datum) => datum.date,
    }),
    []
  );
  const secondaryAxes = React.useMemo(
    () => [
      {
        getValue: (datum) => datum.number,
      },
    ],
    []
  );
  return (
    <div
      style={{
        width: "100%",
        height: "300px",
      }}
    >
      <Chart
        options={{
          primaryAxis: primaryAxis,
          secondaryAxes: secondaryAxes,
          data: data,
        }}
      />
    </div>
  );
};
