import dayjs from 'dayjs';
import {
  get,
  set,
} from 'lodash';
import { normalize } from 'normalizr';
import { toast } from 'react-toastify';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  objectsSchema,
  paymentsSchema,
  servicesSchema,
  statementSchema,
  statementsSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import {
  actions as statementRequestActions,
} from '../../request/statement/actions';
import { actions as statementViewActions } from '../../view/statement/actions';
import {
  fetchFileFromAPI,
  fetchFileStreamFromAPI,
  fetchObjectsFromAPI,
  fetchObjectsFromAPIV2,
  makeObjectActions,
  putObjectToAPI,
  upsertObjectToAPI,
} from '../actions';
import { convertObjectFromLoad } from '../customer/actions';
import { allConstants as constants } from './constants';

const objectActions = makeObjectActions(constants);

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = id => {
  return async dispatch => {
    dispatch(statementRequestActions.setLoadError(null));
    dispatch(statementRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        `${constants.FETCH_URL}/${id}`,
        "data"
      );
      set(result, "statement.customer", {
        ...convertObjectFromLoad(get(result, "statement.customer"))
      });
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          payments: paymentsSchema,
          statement: statementSchema,
          services: servicesSchema
        }),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(statementRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(statementRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Fetch a single object from the api and calculate tax on statement
 * @param id
 * @returns {Function}
 */
export const recalculateTax = id => {
  return async dispatch => {
    dispatch(statementRequestActions.setLoadError(null));
    dispatch(statementRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        `${constants.RECALCULATE_TAX_URL}/${id}`,
        "data"
      );
      set(result, "statement.customer", {
        ...convertObjectFromLoad(get(result, "statement.customer"))
      });
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          payments: paymentsSchema,
          statement: statementSchema,
          services: servicesSchema
        }),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(statementRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(statementRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Load a list of objects (PORTAL)
 * @param tableState
 * @returns {Function}
 */
export const fetchPortalObjects = tableState => {
  return async dispatch => {
    dispatch(statementRequestActions.setLoadAllError(null));
    dispatch(statementRequestActions.setIsLoadingAll(true));
    try {
      const statements = await fetchObjectsFromAPIV2(constants.FETCH_PORTAL_LIST_URL, tableState);

      // Normalize the result and store the statements in redux
      const entities = get(normalize(get(statements, "data"), statementsSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      dispatch(
        statementViewActions.setList(
          get(statements, "data").map(statement => {
            return statement.id;
          }),
          get(statements, "meta.total")
        )
      );
      dispatch(statementRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(statementRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(statementRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(statementRequestActions.setSaveError(null));
    dispatch(statementRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(constants.UPSERT_URL, data);
      const entities = get(
        normalize([result.statement], statementsSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.statement);
      }
    } catch (e) {
      captureException(e);
      dispatch(statementRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(statementRequestActions.setIsSaving(false));
    }
  };
};

export const addLine = (data, onSuccess) => {
  return async dispatch => {
    dispatch(statementRequestActions.setSaveError(null));
    dispatch(statementRequestActions.setIsSaving(true));
    try {
      // update the manual_date field
      data.manual_date = data.manual_date
        ? dayjs(data.manual_date).format("YYYY-MM-DD")
        : "";
      await upsertObjectToAPI(constants.ADD_LINE_URL, { line: data });

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      captureException(e);
      dispatch(statementRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(statementRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertLines = (data, onSuccess) => {
  return async dispatch => {
    dispatch(statementRequestActions.setSaveError(null));
    dispatch(statementRequestActions.setIsSaving(true));
    try {
      await putObjectToAPI(constants.UPSERT_LINES_URL, { statement: data });

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      captureException(e);
      dispatch(statementRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(statementRequestActions.setIsSaving(false));
    }
  };
};

export const fetchStatementPdf = statementId => {
  return async dispatch => {
    dispatch(statementRequestActions.setLoadError(null));
    dispatch(statementRequestActions.setIsLoading(true));
    try {
      await fetchFileFromAPI(
        constants.FETCH_STATEMENT_PDF_URL.replace(":statementId", statementId),
        `statement-${statementId}.pdf`
      );
    } catch (e) {
      captureException(e);
      dispatch(statementRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(statementRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Get the pdf of the invoice and download it
 * @param invoiceId
 * @returns {Function}
 */
export const fetchStatementPDFForPortal = statementId => {
  return async dispatch => {
    dispatch(statementRequestActions.setLoadError(null));
    try {
      const blob = await fetchFileStreamFromAPI(
        constants.FETCH_PORTAL_STATEMENT_PDF_URL.replace(":statementId", statementId),
        `statement-${statementId}.pdf`,
        'application/pdf'
      );
      return blob;

    } catch (e) {
      captureException(e);
      dispatch(statementRequestActions.setLoadError(getErrorMessage(e)));
    }
  };
};

export const initStatement = statementId => {
  return async dispatch => {
    dispatch(statementRequestActions.setSaveError(null));
    dispatch(statementRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.INIT_URL.replace(":statementId", statementId),
        {}
      );
      toast.success("Statement initialized successfully!");
      dispatch(fetchObject(statementId));
    } catch (e) {
      captureException(e);
      dispatch(statementRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(statementRequestActions.setIsSaving(false));
    }
  };
};

export const rebuildStatement = statementId => {
  return async dispatch => {
    dispatch(statementRequestActions.setSaveError(null));
    dispatch(statementRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.REBUILD_URL.replace(":statementId", statementId),
        {}
      );
      toast.success("Statement rebuilt successfully!");
      dispatch(fetchObject(statementId));
    } catch (e) {
      captureException(e);
      dispatch(statementRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(statementRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Reset.
 * @param id
 * @returns {Function}
 */
export const reset = () => {
  return async (dispatch, getState) => {
    dispatch(statementRequestActions.setIsLoading(false));
    dispatch(statementRequestActions.setIsLoaded(false));
    dispatch(statementRequestActions.setIsLoadingAll(false));
    dispatch(statementRequestActions.setIsLoadedAll(false));
    dispatch(upsertNormalizedEntities([]));
    dispatch(statementViewActions.setList([], 0));
  };
};

export const actions = {
  ...objectActions,
  fetchObject,
  fetchPortalObjects,
  fetchStatementPdf,
  fetchStatementPDFForPortal,
  initStatement,
  rebuildStatement,
  upsertLines,
  addLine,
  upsertObject,
  recalculateTax,
  reset,
};
