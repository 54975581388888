import React, { Component, Suspense } from "react";
import { get } from "lodash";
import { Route, Switch } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import { AuthProvider } from "../../../providers/AuthProvider";
import { GoogleMapsProvider } from "../../../providers/GoogleMapsProvider";
import { ToastProvider } from "../../../providers/ToastProvider";
import * as constants from "../../../constants";
import * as components from "./components";
import { AppHeader } from "./Header";
import { AppSidebar } from "./Sidebar";
import { ConfirmationModal } from "../ConfirmationModal";
import { AdminRoute } from '../../../components/AdminRoute';

const Coralogix = require("coralogix-logger");

class AppComponent extends Component {

  componentDidMount() {
    this.logger = new Coralogix.CoralogixLogger("App");
  }

  componentDidCatch(error, errorInfo) {
    const message = get(error, 'message', 'Error');
    const componentStack = get(errorInfo, 'componentStack');
    const text = `${message}\nStacktrace:\n\t${componentStack}`
    const log = new Coralogix.Log({
      severity: Coralogix.Severity.error,
      text: text,
    });
    // send log to coralogix
    if (this.logger) {
      this.logger.addLog(log);
    }
  }

  render() {
    return (
      <GoogleMapsProvider>
        <ToastProvider>
          <AuthProvider>
            <div className="view-app d-flex justify-flex-start">
              <AppSidebar />
              <div className="view-app-container">
                <AppHeader />
                <main className="view-app-main position-relative">
                  <Suspense fallback={<Loader active>Loading</Loader>}>
                    <Switch>
                      <Route
                        path={constants.WEB_ROUTE_ACTIVITY_LIST}
                        exact
                        component={components.InventoryActivityListComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_APPOINTMENT_LIST}
                        exact
                        component={components.AppointmentListComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_APPOINTMENT_EDIT}
                        exact
                        component={components.AppointmentDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_DEFAULT}
                        exact
                        component={components.DashboardComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_SALES_DASHBOARD}
                        exact
                        component={components.SalesDashboardComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_ARSM_DASHBOARD}
                        exact
                        component={components.ARSMDashboardComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_BED_PACKET_EDIT}
                        exact
                        component={components.BedPacketComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_CUSTOMER_LIST}
                        exact
                        component={components.CustomerListComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_CUSTOMER_CREATE}
                        exact
                        component={components.CustomerDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_CUSTOMER_EDIT}
                        exact
                        component={components.CustomerDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_CUSTOMER_MAP}
                        exact
                        component={components.CustomerMapComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_BED_INVOICE_LIST}
                        exact
                        component={components.BedInvoiceListComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_BED_PACKET_LIST}
                        exact
                        component={components.BedPacketListComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_ESTIMATE_LIST}
                        exact
                        component={components.EstimateListComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_ESTIMATE_EDIT}
                        exact
                        component={components.EstimateDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_ESTIMATE_CREATE}
                        exact
                        component={components.EstimateDetailsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_GOOD_LIST}
                        exact
                        component={components.GoodListComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_GOOD_CREATE}
                        exact
                        component={components.GoodDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_GOOD_EDIT}
                        exact
                        component={components.GoodDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_GOOD_TYPE_CREATE}
                        exact
                        component={components.GoodTypeDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_GOOD_TYPE_EDIT}
                        exact
                        component={components.GoodTypeDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_GOOD_TYPE_LIST}
                        exact
                        component={components.GoodTypeListComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_FS_ADJ}
                        exact
                        component={components.GoodCountListComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_LOT_COST_CHANGE}
                        exact
                        component={components.LotCostChangeComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_GROUP_CREATE}
                        exact
                        component={components.GroupDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_GROUP_EDIT}
                        exact
                        component={components.GroupDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_GROUP_LIST}
                        exact
                        component={components.GroupListComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_HISTORY_LIST}
                        exact
                        component={components.InventoryHistoryListComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_INVOICE_CREATE}
                        exact
                        component={components.InvoiceCreateComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_INVOICE_EDIT}
                        exact
                        component={components.InvoiceDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_INVOICE_LIST}
                        exact
                        component={components.InvoiceListComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_LOCATION_CREATE}
                        exact
                        component={components.LocationDetailsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_LOCATION_EDIT}
                        exact
                        component={components.LocationDetailsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_LOCATION_LIST}
                        exact
                        component={components.LocationListComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_PO_ALL}
                        exact
                        component={components.PurchaseOrderListComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_PO_CREATE}
                        exact
                        component={components.PurchaseOrderCreateComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_PO_EDIT}
                        exact
                        component={components.PurchaseOrderDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_PO_STATUS}
                        exact
                        component={components.PurchaseOrderListComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_PRODUCT_CLASS_CREATE}
                        exact
                        component={components.ProductClassDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_PRODUCT_CLASS_EDIT}
                        exact
                        component={components.ProductClassDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_PRODUCT_CLASS_LIST}
                        exact
                        component={components.ProductClassListComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_PROFIT_CENTER_CREATE}
                        exact
                        component={components.ProfitCenterDetailsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_PROFIT_CENTER_EDIT}
                        exact
                        component={components.ProfitCenterDetailsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_PROFIT_CENTER_LIST}
                        exact
                        component={components.ProfitCenterListComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_ROLE_CREATE}
                        exact
                        component={components.RoleDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_ROLE_EDIT}
                        exact
                        component={components.RoleDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_ROLE_LIST}
                        exact
                        component={components.RoleListComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_SERVICE_CREATE}
                        exact
                        component={components.ServiceDetailsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_SERVICE_EDIT}
                        exact
                        component={components.ServiceDetailsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_SERVICE_LIST}
                        exact
                        component={components.ServiceListComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_SERVICE_LEVEL_EDIT}
                        exact
                        component={components.ServiceLevelDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_SERVICE_LEVEL_CREATE}
                        exact
                        component={components.ServiceLevelDetailsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_SERVICE_LEVEL_LIST}
                        exact
                        component={components.ServiceLevelListComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_SERVICE_PERIOD_LIST}
                        exact
                        component={components.ServicePeriodListComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_SERVICE_PERIOD_CREATE}
                        exact
                        component={components.ServicePeriodDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_SERVICE_PERIOD_EDIT}
                        exact
                        component={components.ServicePeriodDetailsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_SERVICE_PRICE_LIST}
                        exact
                        component={components.ServicePriceListComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_SERVICE_PRICE_CREATE}
                        exact
                        component={components.ServicePriceDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_SERVICE_PRICE_EDIT}
                        exact
                        component={components.ServicePriceDetailsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_SERVICE_SCHEDULE_LIST}
                        exact
                        component={components.ServiceScheduleListComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_SERVICE_SCHEDULE_CREATE}
                        exact
                        component={components.ServiceScheduleDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_SERVICE_SCHEDULE_EDIT}
                        exact
                        component={components.ServiceScheduleDetailsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_SERVICE_STATEMENT_CREATE}
                        exact
                        component={components.ServiceStatementDetailsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_SERVICE_STATEMENT_EDIT}
                        exact
                        component={components.ServiceStatementDetailsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_SERVICE_STATEMENT_LIST}
                        exact
                        component={components.ServiceStatementListComponent}
                      />

                      <AdminRoute
                        path={constants.WEB_ROUTE_SERVICE_AUTORUNTAX_VIEW}
                        exact
                        component={components.AutoStatementsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_SERVICE_PAYMENT_LIST}
                        exact
                        component={components.AutoPaymentsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_SERVICE_ERROR_LOG}
                        exact
                        component={components.ServiceErorLogListComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_SERVICE_ROUTE_CREATE}
                        exact
                        component={components.ServiceRouteDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_SERVICE_ROUTE_EDIT}
                        exact
                        component={components.ServiceRouteDetailsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_SERVICE_ROUTE_LIST}
                        exact
                        component={components.ServiceRouteListComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_SERVICE_ACTIVITY_LIST}
                        exact
                        component={components.ServiceVisitsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_SERVICE_STATEMENT_LIST}
                        exact
                        component={components.ServiceStatementListComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_SERVICE_STATEMENT_VIEW}
                        exact
                        component={components.ServiceStatementPeriodComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_SERVICE_STATEMENT_FILES_LIST}
                        exact
                        component={components.ServiceStatementFilesListComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_SPRAY_MIX}
                        exact
                        component={components.SprayMixComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_CHEMICAL_EDIT}
                        exact
                        component={components.ChemicalDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_CHEMICAL_CREATE}
                        exact
                        component={components.ChemicalDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_CHEMICAL_LIST}
                        exact
                        component={components.ChemicalListComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_CYCLE_COUNT_LIST}
                        exact
                        component={components.CycleListComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_TRANSFER_LIST}
                        exact
                        component={components.TransferListComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_TRANSFER_EDIT}
                        exact
                        component={components.TransferDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_TRANSFER_CREATE}
                        exact
                        component={components.TransferDetailsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_USER_LIST}
                        exact
                        component={components.UserListComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_USER_EDIT}
                        exact
                        component={components.UserDetailsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_USER_CREATE}
                        exact
                        component={components.UserDetailsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_VENDOR_LIST}
                        exact
                        component={components.VendorListComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_VENDOR_CREATE}
                        exact
                        component={components.VendorDetailsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_VENDOR_EDIT}
                        exact
                        component={components.VendorDetailsComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_WORK_ORDER_CREATE}
                        exact
                        component={components.WorkOrderDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_WORK_ORDER_EDIT}
                        exact
                        component={components.WorkOrderDetailsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_WORK_ORDER_LIST}
                        exact
                        component={components.WorkOrderListComponent}
                      />

                      <Route
                        path={constants.WEB_ROUTE_UTILITY_REVERSE}
                        exact
                        component={components.ReversalsUtility}
                      />

                      <Route
                        path={constants.WEB_ROUTE_UTILITY_IMPORT}
                        exact
                        component={components.ImportUtility}
                      />

                      <Route
                        path={constants.WEB_ROUTE_REPORTS}
                        exact
                        component={components.Reports}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_ACTIVITY_BY_LOCATION}
                        exact
                        component={components.ActivityByLocationReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_GC_ACTIVITY}
                        exact
                        component={components.GcActivityReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_NON_ROSE_SALES}
                        exact
                        component={components.NonRoseSalesReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_INVENTORY}
                        exact
                        component={components.InventoryReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_ROSES_ON_HAND}
                        exact
                        component={components.RosesOnHandReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_COMMISSION}
                        exact
                        component={components.CommissionReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_PAID_ROSES}
                        exact
                        component={components.PaidRosesReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_INVENTORY_SALES_REPORT}
                        exact
                        component={components.InventorySalesReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_FAT_FINGER}
                        exact
                        component={components.FatFingerReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_OPEN_ITEM}
                        exact
                        component={components.OpenItemsReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_INVENTORY_ADJUSTMENTS}
                        exact
                        component={components.InventoryAdjustmentsReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_TRANSFER}
                        exact
                        component={components.TransferReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_SALES_TAX_SUMMARY}
                        exact
                        component={components.SalesTaxSummaryReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_SALES_TAX_DETAIL}
                        exact
                        component={components.SalesTaxDetailReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_TABS}
                        exact
                        component={components.TabsReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_SERVICE_REVENUE}
                        exact
                        component={components.ServiceRevenueSummaryReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_SERVICE_REVENUE_DETAIL}
                        exact
                        component={components.ServiceRevenueDetailReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_REORDER_POINTS}
                        exact
                        component={components.ReorderPointsReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_VALUE_BY_PRODUCT_CLASS}
                        exact
                        component={components.ValueByProductClassComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_EXPIRING_CC}
                        exact
                        component={components.ExpiringCCReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_UPDATED_PAYMENT_PROFILES}
                        exact
                        component={components.UpdatedPaymentProfilesReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_ACTIVE_CUSTOMERS}
                        exact
                        component={components.ActiveCustomersReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_SERVICE_ACCOUNTS_RECEIVABLE}
                        exact
                        component={components.ServiceAccountsReceivableComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_STARTS_STOPS}
                        exact
                        component={components.StartsStopsReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_PRODUCT_CLASS_RECEIPTS}
                        exact
                        component={components.ProductClassReceiptsReportsComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_INVOICE_TAX_REPORT}
                        exact
                        component={components.InvoiceTaxesReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_WORK_ORDER_STATUS}
                        exact
                        component={components.WorkOrderStatusReportComponent}
                      />
                      <Route
                        path={constants.WEB_ROUTE_REPORT_FINANCIAL}
                        exact
                        component={components.FinancialReport}
                      />
                      <Route
                        path={constants.WEB_ROUTE_SETTINGS_ALL}
                        exact
                        component={components.DashboardSettings}
                      />
                    </Switch>
                  </Suspense>
                </main>
              </div>
              <ConfirmationModal />
            </div>
          </AuthProvider>
        </ToastProvider>
      </GoogleMapsProvider>
    );
  }
}

AppComponent.displayName = "App";

export const App = AppComponent;
