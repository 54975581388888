import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import { useGoogle } from "../../Map/GoogleMap/hooks";

const AddressAutoCompleteInput = ({
  disabled,
  id,
  input,
  label,
  focus,
  className,
  size,
  placeholder,
  required,
  onSelect,
  meta: { touched, error }
}) => {
  const google = useGoogle();
  const [autocomplete, setAutocomplete] = useState(null);
  const [addressListenerSet, setAddressListenerSet] = useState(false);
  const [hasFocus, setHasFocus] = useState(!!focus);
  const address1Ref = React.useRef(null);

  const fillAddress = useCallback(() => {
    if (autocomplete && onSelect) {
      const place = autocomplete.getPlace();
      const addressComponents = place.address_components;
      let streetNumber = '';
      let route = '';
      let locality = '';
      let administrativeAreaLevel1 = '';
      let country = '';
      let postalCode = '';
      // Iterate through the address components
      addressComponents.forEach((component) => {
        const { long_name, short_name, types } = component;
        // Check the type of the address component
        if (types.includes('street_number')) {
          streetNumber = long_name;
        } else if (types.includes('route')) {
          route = long_name;
        } else if (types.includes('locality')) {
          locality = long_name;
        } else if (types.includes('administrative_area_level_1')) {
          administrativeAreaLevel1 = short_name;
        } else if (types.includes('country')) {
          country = short_name;
        } else if (types.includes('postal_code')) {
          postalCode = long_name;
        }
      });
      onSelect({
        address1: `${streetNumber} ${route}`,
        city: locality,
        state: administrativeAreaLevel1,
        postal_code: postalCode,
        country,
        lat: place.geometry.location.lat(),
        lon: place.geometry.location.lng(),
      });
    }
  }, [autocomplete, onSelect]);

  useEffect(() => {
    if (google && !autocomplete && address1Ref.current) {
      setAutocomplete(new google.maps.places.Autocomplete(address1Ref.current, {
        componentRestrictions: { country: ["us", "ca"] },
        fields: ["address_components", "geometry"],
        types: ["address"],
      }));
      address1Ref.current.focus();
    }
  }, [google, autocomplete]);

  useEffect(() => {
    if(autocomplete && !addressListenerSet){
      autocomplete.addListener("place_changed", fillAddress);
      setAddressListenerSet(true);
    }
  }, [autocomplete, fillAddress, addressListenerSet]);


  const isValid = !error;
  className = cx({ valid: isValid }, className);
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true,
    "has-focus": hasFocus,
    "has-value": !!input.value
  });
  const showError = !!(touched && error);

  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <div className="ui large fluid input">
        <input
          {...input}
          ref={address1Ref}
          placeholder={placeholder}
          size={size}
          disabled={disabled}
          focus={focus}
          className={className}
          autoComplete="off"
          onFocus={() => {
            setHasFocus(true);
          }}
          onBlur={() => {
            setHasFocus(false);
          }}
        />
        {showError && <span className="input-error">{error}</span>}
      </div>
    </div>

  );
};

export { AddressAutoCompleteInput };