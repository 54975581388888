import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';

import { objectsSchema } from '../../../helpers/normalizers';
import { objectReducer } from '../../object/selectors';
import { makeViewSelector } from '../selectors';
import { allConstants as constants } from './constants';

export const workOrderViewReducer = () => state =>
  state.getIn([constants.VIEW_REDUCER_NAME, constants.REDUCER_NAME]);

export const workOrderViewSelector = () => {
  return {
    ...makeViewSelector(constants, workOrderViewReducer),
    getAssignmentFilter: () => {
      return createSelector(
        workOrderViewReducer(),
        viewState => {
          return viewState.get(constants.ASSIGNMENT_FILTER);
        }
      );
    },
    getLocationFilter: () => {
      return createSelector(
        workOrderViewReducer(),
        viewState => {
          return viewState.get(constants.LOCATION_FILTER);
        }
      );
    },
    getRouteFilter: () => {
      return createSelector(
        workOrderViewReducer(),
        viewState => {
          return viewState.get(constants.ROUTE_FILTER);
        }
      );
    },
    getTypeFilter: () => {
      return createSelector(
        workOrderViewReducer(),
        viewState => {
          return viewState.get(constants.TYPE_FILTER);
        }
      );
    },
    getStatusFilter: () => {
      return createSelector(
        workOrderViewReducer(),
        viewState => {
          return viewState.get(constants.STATUS_FILTER);
        }
      );
    },
    getTypeRangeFilteredObjects: () => {
      return createSelector(
        workOrderViewReducer(),
        objectReducer(),
        (viewState, objectState) => {
          const typeFilter = viewState.get(constants.TYPE_FILTER);
          let ids = viewState.getIn([
            constants.TYPE_FILTERED_OBJECTS,
            typeFilter
          ]);
          if (ids) {
            ids = ids.toJS();
            const entities = objectState.toJS();
            return denormalize(
              { [constants.REDUCER_NAME]: ids },
              objectsSchema,
              entities
            )[[constants.REDUCER_NAME]];
          }
          return [];
        }
      );
    },
    getTypeRangeFilteredList: () => {
      return createSelector(
        workOrderViewReducer(),
        objectReducer(),
        (viewState, objectState) => {
          const typeFilter = viewState.get(constants.TYPE_FILTER);
          let ids = viewState.getIn([
            constants.TYPE_FILTERED_LIST,
            typeFilter,
            constants.LIST
          ]);

          if (ids) {
            ids = ids.toJS();
            const entities = objectState.toJS();
            return denormalize(
              { [constants.REDUCER_NAME]: ids },
              objectsSchema,
              entities
            )[[constants.REDUCER_NAME]];
          }
          return [];
        }
      );
    },
    getTypeRangeFilteredTotal: () =>
      createSelector(
        workOrderViewReducer(),
        state => {
          const typeFilter = state.get(constants.TYPE_FILTER);
          return (
            state.getIn([
              constants.TYPE_FILTERED_LIST,
              typeFilter,
              constants.TOTAL
            ]) || 0
          );
        }
      )
  };
};
