import cx from "classnames";
import { get, isEmpty } from "lodash";
import { object } from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const isItemOpen = (item, location) => {
  return (
    location.pathname === item.route ||
    get(location, "state.parent") === item.key
  );
};

const isChildActive = (child, location) => {
  return (
    child.route === location.pathname ||
    get(location, "state.sibling") === child.key
  );
};

/**
 * Renders a single menu item that does not have any children as a link
 * @param item
 * @param location
 * @returns {*}
 */
const renderItem = (item, location) => {
  const className = cx({
    active: isItemOpen(item, location),
    "app-menu-item": true
  });
  return (
    <li className={className}>
      <Link to={{ pathname: item.route }} className="d-block">
        <span className={cx("item-icon", item.icon)} />
        <span className="item-label">{item.label}</span>
      </Link>
    </li>
  );
};

/**
 * Renders a menu item as a button that can open/closes
 * @param item
 * @param location
 * @returns {*}
 */
const renderItemWithChildren = (item, location) => {
  const isOpen = isItemOpen(item, location);
  const className = cx({ "app-menu-item": true, "item-is-open": isOpen });

  return (
    <li className={className}>
      <Link to={{ pathname: item.route }} className="d-block pointer">
        <span className={cx("item-icon", item.icon)} />
        <span className="item-label">{item.label}</span>
      </Link>
      <ul>
        {item.children.map(child => {
          // Wether to highlight the selected item in the menu
          const isActive = isChildActive(child, location);

          const childClassName = cx({
            active: isActive
          });

          return (
            <li key={`${item.key}-${child.key}`} className={childClassName}>
              <Link
                to={{
                  pathname: child.route,
                  state: { parent: item.key, sibling: item.sibling }
                }}
                className="d-block"
              >
                <span className="item-label">{child.label}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </li>
  );
};

export const AppMenuItem = React.memo(({ location, item }) => {
  return isEmpty(item.children)
    ? renderItem(item, location)
    : renderItemWithChildren(item, location);
});

AppMenuItem.propTypes = {
  location: object,
  item: object.isRequired
};
