import React from "react";
import { Button } from "semantic-ui-react";
import { useMap } from "./hooks";

export const GoogleMap = ({
  mapRef,
  onClick,
  onIdle,
  route,
  render,
  center,
  zoom,
  fitBounds,
  clusterMarkers,
  markers = [],
  extraMarkers = 0,
  ...options
}) => {
  const [showRoute, setShowRoute] = React.useState(false);
  const { map } = useMap({ route, markers, center, zoom, fitBounds, clusterMarkers, showRoute });
  
  // TODO: Move this to a hook
  // Set the mapRef
  // Set the map center/zoom
  React.useEffect(() => {
    if (map && map.map && mapRef && !mapRef.current) {
      mapRef.current = map;
    }
    if (map && map.map && center) {
      map.map.setCenter(center);
      map.map.setZoom(zoom);
    }
  }, [map, center, zoom, mapRef]);
  
  // TODO: Move this to a hook
  // Add extra markers
  React.useEffect(() => {
    if(map && map.map && extraMarkers && extraMarkers.length > 0){
      extraMarkers.forEach(marker => {
        const {lat, lng, icon, title} = marker;
        const markerOptions = {
          position: {lat, lng},
          map: map.map,
          icon,
          title
        };
        const markerInstance = new window.google.maps.Marker(markerOptions);
        markerInstance.setMap(map.map);
      });
    }
  }, [extraMarkers, map]);

  return (
    <React.Fragment>
      <div ref={map.ref} id="map" className={'map'} />
      {!!route && <Button type="button" className="p-absolute m-1 map-route-button" icon="road" onClick={() => setShowRoute(!showRoute)} />}
    </React.Fragment>
  );
}