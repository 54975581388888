import {
  get,
  uniq,
} from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  sprayMixRecipeSchema,
  sprayMixRecipesSchema,
  notesSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import { actions as noteRequestActions } from '../../request/note/actions';
import {
  actions as sprayMixRecipeRequestActions,
} from '../../request/sprayMixRecipe/actions';
import { sortAndLimitResults } from '../../view/actions';
import { actions as sprayMixRecipeViewActions } from '../../view/sprayMixRecipe/actions';
import { sprayMixRecipeViewSelector } from '../../view/sprayMixRecipe/selectors';
import {
  fetchObjectsFromAPI,
  fetchNotesFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';
import { sprayMixRecipeSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 */
export const fetchObjects = (tableState) => {
  return async dispatch => {
    dispatch(sprayMixRecipeRequestActions.setLoadAllError(null));
    dispatch(sprayMixRecipeRequestActions.setIsLoadingAll(true));
    try {
      const recipes = await fetchObjectsFromAPI(constants.FETCH_LIST_URL, 'data');

      // Normalize the result and store the chemicals in redux
      const entities = get(
        normalize(recipes, sprayMixRecipesSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(recipes, tableState, (ids, count) => {
          dispatch(sprayMixRecipeViewActions.setList(ids, count));
        })
      );
      dispatch(sprayMixRecipeRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(sprayMixRecipeRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(sprayMixRecipeRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Fetch the notes from the api and store their result in our reducer
 * @param id
 * @returns {Function}
 */
export const fetchNotes = id => {
  return async dispatch => {
    dispatch(noteRequestActions.setLoadAllError(null));
    dispatch(noteRequestActions.setIsLoadingAll(true));
    try {
      const notes = await dispatch(
        fetchNotesFromAPI(id, `${constants.FETCH_NOTE_LIST_URL}/${id}`)
      );

      dispatch(
        sprayMixRecipeViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          id,
          notes.map(note => {
            return note.id;
          })
        )
      );
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Delete a spray mix recipe
 * @param recipe
 * @returns {Function}
 */
export const deleteObject = (recipe, onSuccess) => {
  return async dispatch => {
    dispatch(sprayMixRecipeRequestActions.setSaveError(null));
    dispatch(sprayMixRecipeRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.DELETE_URL.replace(":id", recipe.id),
        {}
      );
      if(onSuccess) {
        onSuccess();
      }
    } catch (e) {
      captureException(e);
      dispatch(sprayMixRecipeRequestActions.setSaveError(getErrorMessage(e)));
      dispatch(sprayMixRecipeRequestActions.setIsSaving(false));
    } finally {
      dispatch(sprayMixRecipeRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = id => {
  return async dispatch => {
    dispatch(sprayMixRecipeRequestActions.setLoadError(null));
    dispatch(sprayMixRecipeRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        `${constants.FETCH_URL}/${id}`,
        "data"
      );

      // Normalize the result and store the other attributes in redux
      const entities = get(normalize(result, sprayMixRecipeSchema), "entities", {});

      dispatch(upsertNormalizedEntities(entities));
      dispatch(sprayMixRecipeRequestActions.setIsLoaded(id, true));
    } catch (e) {
      captureException(e);
      dispatch(sprayMixRecipeRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(sprayMixRecipeRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Toggle the active status on a spray mix recipe
 * @param recipe
 * @returns {Function}
 */
export const toggleActive = recipe => {
  return async dispatch => {
    dispatch(sprayMixRecipeRequestActions.setLoadAllError(null));
    dispatch(sprayMixRecipeRequestActions.setIsLoadingAll(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.TOGGLE_URL.replace(":id", recipe.id),
        "data"
      );

      // Normalize the result and store the chemicals in redux
      const entities = get(
        normalize(result, sprayMixRecipeSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
      dispatch(sprayMixRecipeRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(sprayMixRecipeRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(sprayMixRecipeRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(sprayMixRecipeRequestActions.setSaveError(null));
    dispatch(sprayMixRecipeRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(constants.UPSERT_URL, data, false);

      const entities = get(
        normalize(result, sprayMixRecipeSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      dispatch(sprayMixRecipeRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(sprayMixRecipeRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Save a note on the api
 * @param parentId
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertNote = (parentId, data, onSuccess) => {
  return async (dispatch, getState) => {
    dispatch(noteRequestActions.setSaveError(null));
    dispatch(noteRequestActions.setIsSaving(true));
    try {
      data.kind = data.kind || "spraymixrecipe";

      const result = await upsertObjectToAPI(constants.UPSERT_NOTE_URL, data);
      const entities = get(normalize([result], notesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      const notes = sprayMixRecipeViewSelector().getNoteIds(parentId)(getState());
      const updatedIds = uniq([...notes, result.id]);
      dispatch(
        sprayMixRecipeViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          parentId,
          updatedIds
        )
      );

      if (onSuccess) {
        onSuccess(notes);
      }
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const recipes = sprayMixRecipeSelector().getDenormalizedObjects()(getState());
    // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(recipes, tableState, (ids, count) => {
        dispatch(sprayMixRecipeViewActions.setList(ids, count));
      })
    );
  };
};


/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const reset = () => {
  return async (dispatch, getState) => {    
    dispatch(sprayMixRecipeRequestActions.setSaveError(null));
  };
};

export const actions = {
  ...objectActions,
  deleteObject,
  fetchObject,
  fetchObjects,
  fetchNotes,
  toggleActive,
  updateSortFilterLimit,
  upsertObject,
  upsertNote,
  reset,
};
