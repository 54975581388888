import { get } from 'lodash';
import { normalize } from 'normalizr';

import { captureException } from '../../../helpers/error';

import { getErrorMessage } from '../../../helpers/error';
import {
  appointmentSchema,
  appointmentsSchema,
  estimatesSchema,
  objectsSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import {
  actions as appointmentRequestActions,
} from '../../request/appointment/actions';
import {
  actions as estimateRequestActions,
} from '../../request/estimate/actions';
import { sortAndLimitResults } from '../../view/actions';
import {
  actions as appointmentViewActions,
} from '../../view/appointment/actions';
import { appointmentViewSelector } from '../../view/appointment/selectors';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { ESTIMATE_REDUCER_NAME } from '../estimate';
import { allConstants as constants } from './constants';
import { appointmentSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(appointmentRequestActions.setLoadAllError(null));
    dispatch(appointmentRequestActions.setIsLoadingAll(true));
    try {
      const appointments = await fetchObjectsFromAPI(constants.FETCH_LIST_URL);

      // Normalize the result and store the appointments in redux
      const entities = get(
        normalize(appointments, appointmentsSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      dispatch(appointmentViewActions.setAll(appointments.map(appointment => appointment.id), appointments.length));
      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(appointments, tableState, (ids, count) => {
          dispatch(appointmentViewActions.setList(ids, count));
        })
      );
      dispatch(appointmentRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(appointmentRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(appointmentRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = id => {
  return async (dispatch, getState) => {
    dispatch(appointmentRequestActions.setLoadError(null));
    dispatch(appointmentRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        `${constants.FETCH_URL}/${id}`,
        "data"
      );

      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(
          { appointment: result },
          {
            ...objectsSchema,
            appointment: appointmentSchema
          }
        ),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(appointmentRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(appointmentRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(appointmentRequestActions.setSaveError(null));
    dispatch(appointmentRequestActions.setIsSaving(true));
    try {
      data.appointment_date = new Date(data.appointment_date).toUTCString();
      const result = await upsertObjectToAPI(constants.UPSERT_URL, data);
      const entities = get(
        normalize([result], appointmentsSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      dispatch(appointmentRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(appointmentRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const appointments = appointmentSelector().getDenormalizedObjects()(
      getState()
    );
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(appointments, tableState, (ids, count) => {
        dispatch(appointmentViewActions.setList(ids, count));
      })
    );
  };
};

/**
 * Fetch the estimates associated with this service level
 * @param appointmentId
 * @param tableState
 * @returns {Function}
 */
export const fetchEstimates = (appointmentId, tableState) => {
  return async dispatch => {
    dispatch(estimateRequestActions.setLoadAllError(null));
    dispatch(estimateRequestActions.setIsLoadingAll(true));
    try {
      const result = await fetchObjectsFromAPI(
        constants.FETCH_ESTIMATE_LIST_URL.replace(
          ":appointmentId",
          appointmentId
        )
      );

      const entities = get(normalize(result, estimatesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      // Store the full list of ids in the reducer
      dispatch(
        appointmentViewActions.setRelatedObjects(
          constants.RELATION_ESTIMATES,
          appointmentId,
          result.map(estimate => {
            return estimate.id;
          })
        )
      );

      dispatch(updateEstimatesSortFilterLimit(appointmentId, tableState));
    } catch (e) {
      captureException(e);
      dispatch(estimateRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(estimateRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the estimates for the table
 * @param appointmentId
 * @param tableState
 * @returns {Function}
 */
export const updateEstimatesSortFilterLimit = (appointmentId, tableState) => {
  return async (dispatch, getState) => {
    const state = getState();
    const estimates = appointmentViewSelector().getRelatedObjects(
      ESTIMATE_REDUCER_NAME,
      appointmentId
    )(state);

    // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(estimates, tableState, (ids, count) => {
        dispatch(
          appointmentViewActions.setRelatedFilteredList(
            constants.RELATION_ESTIMATES,
            appointmentId,
            ids,
            count
          )
        );
      })
    );
  };
};

export const actions = {
  ...objectActions,
  fetchObjects,
  fetchEstimates,
  updateEstimatesSortFilterLimit,
  fetchObject,
  updateSortFilterLimit,
  upsertObject
};
