import React from "react";
import { array } from "prop-types";
import { formatCurrency } from "./../../../../helpers/util";

export const CustomerPortalInvoicePriceCell = React.memo(
  ({ value }) => {
    const price = value.reduce((previousValue, currentValue) => {
      return (
        previousValue +
        parseFloat(
          parseFloat(currentValue.price) * parseFloat(currentValue.qty)
        )
      );
    }, 0);
    return <span className="cell mr-1">{formatCurrency(price)}</span>;
  }
);

CustomerPortalInvoicePriceCell.propTypes = {
  value: array,
};
