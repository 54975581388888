export const RESET = "store/app/RESET";

// Admin App
export const TOGGLE_CONFIRMATION_MODAL_VISIBLE =
  "store/app/TOGGLE_CONFIRMATION_MODAL_VISIBLE";
export const SET_CONFIRMATION_MODAL_TEXT =
  "store/app/SET_CONFIRMATION_MODAL_TEXT";
export const TOGGLE_CONFIRMATION_MODAL_YES_CLICKED =
  "store/app/TOGGLE_CONFIRMATION_MODAL_YES_CLICKED";
export const SET_CURRENT_LOCATION = "store/app/SET_CURRENT_LOCATION";
export const SET_CURRENT_LOCATION_ID = "store/app/SET_CURRENT_LOCATION_ID";
export const LOCK_CURRENT_LOCATION = "store/app/LOCK_CURRENT_LOCATION";
export const SET_SIDEBAR_IS_OPEN = "store/app/SET_SIDEBAR_IS_OPEN";

export const APP = "app";
export const CONFIRMATION_MODAL_IS_VISIBLE = "confirmationModalIsVisible";
export const CONFIRMATION_MODAL_TEXT = "confirmationModalText";
export const CONFIRMATION_MODAL_YES_CLICKED = "confirmationModalYesClicked";
export const CURRENT_LOCATION_ID = "currentLocationId";
export const CURRENT_LOCATION_IS_LOCKED = "currentLocationIsLocked";
export const SIDEBAR_IS_OPEN = "sidebarIsOpen";

// Portal App
export const TOGGLE_PORTAL_ACCOUNT_SELECT_MODAL =
  "store/app/TOGGLE_PORTAL_ACCOUNT_SELECT_MODAL";
export const SET_CURRENT_PORTAL_ACCOUNT_ID =
  "store/app/SET_CURRENT_PORTAL_ACCOUNT_ID";
export const SET_PORTAL_ACCOUNTS = "store/app/SET_PORTAL_ACCOUNTS";

export const CURRENT_PORTAL_ACCOUNT_ID = "accountId";
export const PORTAL_ACCOUNTS = "accounts";
export const PORTAL_ACCOUNT_SELECT_MODAL_IS_VISIBLE =
  "portalAccountSelectModalIsVisible";
