import React from 'react';

import {
  bool,
  func,
  number,
  string,
} from 'prop-types';

import { MAX_LIMIT } from '../../../constants';
import { tableState } from '../../../helpers/shapes';
import { ListHeaderLimit } from '../Limit';
import { ListHeaderSearch } from '../Search';

export const ListHeaderLimitSearch = React.memo(
  ({
    tableState,
    onLimitChange,
    onSearchChange,
    searchPlaceholder,
    total,
    limitCustomizable = false,
    maxLimit = MAX_LIMIT,
    isSearchable = true,
    extendedSearchOptions,
    searchDisabled = false,
    onEnter = null,
  }) => {
    return (
      <div className="d-flex justify-space-between align-items-center">
        <div className="filters text-right flex-grow-1">
          <div className="d-inline-block">
            <ListHeaderLimit
              limit={tableState.limit}
              maxLimit={maxLimit}
              onLimitChange={onLimitChange}
              total={total}
              customizable={limitCustomizable}
            />
          </div>
          {onSearchChange && isSearchable && (
            <div className="d-inline-block pl-2">
              <ListHeaderSearch
                placeholder={searchPlaceholder}
                search={tableState.search}
                onSearchChange={onSearchChange}
                extendedSearchOptions={extendedSearchOptions}
                disabled={searchDisabled}
                onEnter={onEnter}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);

ListHeaderLimitSearch.propTypes = {
  tableState: tableState,
  searchPlaceholder: string,
  onLimitChange: func.isRequired,
  onSearchChange: func.isRequired,
  limitCustomizable: bool,
  total: number,
  maxLimit: number,
  isSearchable: bool,
  onEnter: func,
};
