import React from "react";
import { array } from "prop-types";

export const CustomerPortalInvoiceItemsCell = React.memo(({ value }) => {
  if(value.length === 0){
    return <span className="cell mr-1">0 items</span>;
  }
  const item = value.length > 1 ? "Multiple" : value[0].item;
  return <span className="cell mr-1">{item}</span>;
});

CustomerPortalInvoiceItemsCell.propTypes = {
  value: array,
};
