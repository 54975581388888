import { schema } from 'normalizr';

import {
  REDUCER_NAME as LOCATION_REDUCER_NAME,
} from '../../store/object/location/constants';
import { profitCenterSchema } from './profitCenter';

export const locationSchema = new schema.Entity(LOCATION_REDUCER_NAME, {
  profit_center: profitCenterSchema
});

export const locationsSchema = [locationSchema];
