import { makeActionCreator } from '../../../helpers/actions';
import { makeViewActions } from '../actions';
import { allConstants as constants } from './constants';

const viewActions = makeViewActions(constants);

// ASSIGNMENT
const setAssignmentFilter = makeActionCreator(
  constants.SET_ASSIGNMENT_FILTER,
  "assignmentFilter"
);
const setAssignmentFilteredList = makeActionCreator(
  constants.SET_ASSIGNMENT_FILTERED_LIST,
  "assignmentFilter",
  "data",
  "count"
);
const setAssignmentFilteredObjects = makeActionCreator(
  constants.SET_ASSIGNMENT_FILTERED_OBJECTS,
  "assignmentFilter",
  "data"
);
// LOCATION
const setLocationFilter = makeActionCreator(
  constants.SET_LOCATION_FILTER,
  "locationFilter"
);
const setLocationFilteredList = makeActionCreator(
  constants.SET_LOCATION_FILTERED_LIST,
  "locationFilter",
  "data",
  "count"
);
const setLocationFilteredObjects = makeActionCreator(
  constants.SET_LOCATION_FILTERED_OBJECTS,
  "locationFilter",
  "data"
);
// ROUTES
const setRouteFilter = makeActionCreator(
  constants.SET_ROUTE_FILTER,
  "routeFilter"
);
const setRouteFilteredList = makeActionCreator(
  constants.SET_ROUTE_FILTERED_LIST,
  "routeFilter",
  "data",
  "count"
);
const setRouteFilteredObjects = makeActionCreator(
  constants.SET_ROUTE_FILTERED_OBJECTS,
  "routeFilter",
  "data"
);
// TYPE
const setTypeFilter = makeActionCreator(
  constants.SET_TYPE_FILTER,
  "typeFilter"
);
const setTypeFilteredList = makeActionCreator(
  constants.SET_TYPE_FILTERED_LIST,
  "typeFilter",
  "data",
  "count"
);
const setTypeFilteredObjects = makeActionCreator(
  constants.SET_TYPE_FILTERED_OBJECTS,
  "typeFilter",
  "data"
);
// STATUS
const setStatusFilter = makeActionCreator(
  constants.SET_STATUS_FILTER,
  "statusFilter"
);
const setStatusFilteredList = makeActionCreator(
  constants.SET_STATUS_FILTERED_LIST,
  "statusFilter",
  "data",
  "count"
);
const setStatusFilteredObjects = makeActionCreator(
  constants.SET_STATUS_FILTERED_OBJECTS,
  "statusFilter",
  "data"
);
export const actions = {
  ...viewActions,
  setAssignmentFilter,
  setAssignmentFilteredObjects,
  setAssignmentFilteredList,
  setRouteFilter,
  setRouteFilteredObjects,
  setRouteFilteredList,
  setTypeFilter,
  setTypeFilteredObjects,
  setTypeFilteredList,
  setStatusFilter,
  setStatusFilteredObjects,
  setStatusFilteredList,
  setLocationFilter,
  setLocationFilteredObjects,
  setLocationFilteredList,
};
