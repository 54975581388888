import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "bedPacket";
export const FETCH_URL = `${process.env.REACT_APP_API_URL}api/v2/bed-packet`;
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}api/v2/bed-packets`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}api/v2/bed-packet/update/:id`;
export const UPSERT_SECTION_URL = `${process.env.REACT_APP_API_URL}api/v2/bed-packet/update/:section/:id`;
export const FETCH_PDF_URL = `${process.env.REACT_APP_API_URL}api/v2/export/bed-packet/:id`;
export const FETCH_NOTE_LIST_URL = `${process.env.REACT_APP_API_URL}admin/note/bedPacket`;
export const UPSERT_MEDIA_URL = `${process.env.REACT_APP_API_URL}api/v2/bed-packet/media/upload/:id`;
export const DESTROY_MEDIA_URL = `${process.env.REACT_APP_API_URL}api/v2/bed-packet/media/delete/:id`;
export const UPSERT_NOTE_URL = `${process.env.REACT_APP_API_URL}admin/note`;
export const SEND_EMAIL_URL = `${process.env.REACT_APP_API_URL}api/v2/bed-packet/mail`;
export const SEND_NOTIFICATION_URL = `${process.env.REACT_APP_API_URL}api/v2/bed-packet/notify`;
export const SEND_NOTIFICATION_ALL_URL = `${process.env.REACT_APP_API_URL}api/v2/bed-packet/notify-all`;
export const CANCEL_BEDPACKET_URL = `${process.env.REACT_APP_API_URL}api/v2/bed-packet/cancel`;
export const FETCH_RESULTS_XLS_URL = `${process.env.REACT_APP_API_URL}api/v2/export/bed-packets/:startDate/:endDate`;
// ARSM Routes
export const APPROVE_BEDPACKET_URL = `${process.env.REACT_APP_API_URL}api/v2/arsm/bed-packet/approve`;
export const DECLINE_BEDPACKET_URL = `${process.env.REACT_APP_API_URL}api/v2/arsm/bed-packet/decline`;
export const REDUCER_NAME = "bedPackets";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_URL,
  FETCH_LIST_URL,
  UPSERT_URL,
  UPSERT_SECTION_URL,
  UPSERT_PROPERTY,
  FETCH_PDF_URL,
  UPSERT_MEDIA_URL,
  DESTROY_MEDIA_URL,
  SEND_EMAIL_URL,
  SEND_NOTIFICATION_URL,
  SEND_NOTIFICATION_ALL_URL,
  FETCH_NOTE_LIST_URL,
  UPSERT_NOTE_URL,
  CANCEL_BEDPACKET_URL,
  APPROVE_BEDPACKET_URL,
  DECLINE_BEDPACKET_URL,
  FETCH_RESULTS_XLS_URL,
};
