import { get, uniq, isArray } from 'lodash';
import { normalize } from 'normalizr';
import dayjs from 'dayjs';
import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  objectsSchema,
  bedPacketSchema,
  bedPacketsSchema,
  notesSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import { actions as bedPacketRequestActions } from '../../request/bedPacket/actions';
import { actions as bedPacketViewActions } from '../../view/bedPacket/actions';
import { actions as noteRequestActions } from '../../request/note/actions';
import { bedPacketViewSelector } from '../../view/bedPacket/selectors';
import { sortAndLimitResults } from '../../view/actions';
import {
  makeObjectActions,
  upsertObjectToAPI,
  uploadFileToAPI,
  fetchObjectsFromAPI,
  fetchObjectsFromAPIV2,
  fetchFileStreamFromAPI,
  fetchNotesFromAPI,
  fetchFileFromAPI,
} from '../actions';
import { allConstants as constants } from './constants';
import { userSelector } from '../user/selectors';
import { bedPacketSelector } from './selectors';
import { toast } from 'react-toastify';

const objectActions = makeObjectActions(constants);

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
 export const fetchObject = (id) => {
  return async (dispatch, getState) => {
    dispatch(bedPacketRequestActions.setLoadError(null));
    dispatch(bedPacketRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        `${constants.FETCH_URL}/${id}`,
        "data.data"
      );
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(
          { bedPacket: result },
          {
            ...objectsSchema,
            bedPacket: bedPacketSchema,
          }
        ),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(bedPacketRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(bedPacketRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Load a list of objects
 */
 export const fetchObjects = (tableState) => {
  return async (dispatch) => {
    dispatch(bedPacketRequestActions.setLoadAllError(null));
    dispatch(bedPacketRequestActions.setIsLoadingAll(true));
    try {
      const response = await fetchObjectsFromAPIV2(constants.FETCH_LIST_URL, tableState, "data", {
        update_start_date: tableState.updateStartDate,
        update_end_date: tableState.updateEndDate,
      });

      // Normalize the result and store the estimates in redux
      const entities = get(
        normalize(get(response, "data"), bedPacketsSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        bedPacketViewActions.setList(
          get(response, "data").map(bedPacket => {
            return bedPacket.id;
          }),
          get(response, "meta.total")
        )
      );
      dispatch(bedPacketRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(bedPacketRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(bedPacketRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(bedPacketRequestActions.setSaveError(null));
    dispatch(bedPacketRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.UPSERT_URL.replace(':id', data.id),
        data,
        false,
        true
      );
      
      dispatch(fetchObject(data.id));

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      dispatch(bedPacketRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(bedPacketRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
 export const upsertReturns = (data, bedPacketId, onSuccess) => {
  return async dispatch => {
    dispatch(bedPacketRequestActions.setSaveError(null));
    dispatch(bedPacketRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.UPSERT_SECTION_URL.replace(':section', 'returns').replace(':id', bedPacketId),
        data,
        false,
        true
      );
      
      dispatch(fetchObject(bedPacketId));

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      dispatch(bedPacketRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(bedPacketRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertSectionObject = (section, bedPacketId, data, onSuccess) => {
  return async dispatch => {
    dispatch(bedPacketRequestActions.setSaveError(null));
    dispatch(bedPacketRequestActions.setIsSaving(true));
    try {
      if(data.date){
        data.date = new Date(data.date).toUTCString();
      }
      const result = await upsertObjectToAPI(constants.UPSERT_SECTION_URL
          .replace(':section', section)
          .replace(':id', bedPacketId),
          data, false, true, 'data.data');

      dispatch(fetchObject(bedPacketId));

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      console.error(e)
      captureException(e);
      dispatch(bedPacketRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(bedPacketRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Get the pdf of the invoice and download it
 * @param invoiceId
 * @returns {Function}
 */
 export const fetchBedPacketPDF = bedPacketId => {
  return async dispatch => {
    dispatch(bedPacketRequestActions.setLoadError(null));
    dispatch(bedPacketRequestActions.setIsLoading(true));
    try {
      const blob = await fetchFileStreamFromAPI(
        constants.FETCH_PDF_URL.replace(":id", bedPacketId),
        `bed-packet-${bedPacketId}.pdf`,
        'application/pdf'
      );
      dispatch(bedPacketRequestActions.setIsLoading(false));
      return blob;

    } catch (e) {
      captureException(e);
      dispatch(bedPacketRequestActions.setLoadError(getErrorMessage(e)));
      dispatch(bedPacketRequestActions.setIsLoading(false));
      return false;
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
 export const updateSortFilterLimit = (tableState) => {
  return async (dispatch, getState) => {
    const bedPackets = bedPacketSelector().getDenormalizedObjects()(getState());
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(bedPackets, tableState, (ids, count) => {
        dispatch(bedPacketViewActions.setList(ids, count));
      })
    );
  };
};

/**
 * Fetch the notes from the api and store their result in our reducer
 * @param id
 * @returns {Function}
 */
 export const fetchNotes = (id) => {
  return async (dispatch) => {
    dispatch(noteRequestActions.setLoadAllError(null));
    dispatch(noteRequestActions.setIsLoadingAll(true));
    try {
      const notes = await dispatch(
        fetchNotesFromAPI(id, `${constants.FETCH_NOTE_LIST_URL}/${id}`)
      );

      dispatch(
        bedPacketViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          id,
          notes.map((note) => {
            return note.id;
          })
        )
      );
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save a note on the api
 * @param parentId
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
 export const upsertNote = (parentId, data, onSuccess) => {
  return async (dispatch, getState) => {
    dispatch(noteRequestActions.setSaveError(null));
    dispatch(noteRequestActions.setIsSaving(true));
    try {
      data.kind = data.kind || "bedPacket";
      const result = await upsertObjectToAPI(constants.UPSERT_NOTE_URL, data);
      const entities = get(normalize([result], notesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      const notes = bedPacketViewSelector().getNoteIds(parentId)(getState());
      if(isArray(notes)){
        const updatedIds = uniq([...notes, result.id]);
        dispatch(
          bedPacketViewActions.setRelatedObjects(
            constants.RELATION_NOTES,
            parentId,
            updatedIds
          )
        );
      }      

      if (onSuccess) {
        onSuccess(notes);
      }
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Save a media on the api
 * @param bedPacketId
 * @param type
 * @param file
 * @returns {Function}
 */
 export const upsertMedia = (bedPacketId, type, file) => {
  return async (dispatch) => {
    dispatch(bedPacketRequestActions.setSaveError(null));
    dispatch(bedPacketRequestActions.setIsSaving(true));
    try {
      await uploadFileToAPI(
        constants.UPSERT_MEDIA_URL.replace(':id', bedPacketId),
        file,
        {
          type: type,
          objectId: bedPacketId,
        },
        '',
        'files[]'
      );

      dispatch(fetchObject(bedPacketId));
    } catch (e) {
      console.error(e);
      captureException(e);
      dispatch(bedPacketRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(bedPacketRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Remove a media from the bed packet
 * @param mediaId
 * @param bedPacketId
 * @returns {Function}
 */
 export const destroyMedia = (mediaId, bedPacketId) => {
  return async (dispatch, getState) => {
    dispatch(bedPacketRequestActions.setSaveError(null));
    dispatch(bedPacketRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(constants.DESTROY_MEDIA_URL.replace(":id", mediaId), true, false, true);
      dispatch(fetchObject(bedPacketId));
    } catch (e) {
      captureException(e);
      dispatch(bedPacketRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(bedPacketRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Email a copy of the pdf of the bed packet on the api
 * @param bedPacketId
 * @param email
 * @param onSuccess
 * @returns {Function}
 */
 export const emailBedPacket = (bedPacketId, email, onSuccess, onError) => {
  return async (dispatch) => {
    dispatch(bedPacketRequestActions.setMailError(null));
    try {
      const result = await upsertObjectToAPI(
        constants.SEND_EMAIL_URL,
        { bedPacketId, email },
        false,
        true
      );
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      dispatch(bedPacketRequestActions.setMailError(getErrorMessage(e)));
      onError(getErrorMessage(e));
    } finally {}
  };
};

/**
 * Send a notification about the bed packet
 * @param userId
 * @param message
 * @param bedPacketId
 * @param onSuccess
 * @returns {Function}
 */
 export const notifyBedPacket = (userId, message, bedPacketId, attachpdf = false, onSuccess, onError) => {
  return async (dispatch, getState) => {
    dispatch(bedPacketRequestActions.setSaveError(null));
    try {
      const user = userSelector().getDenormalizedObject(userId)(getState());
      const sendto = get(user, "email");
      const result = await upsertObjectToAPI(
        constants.SEND_NOTIFICATION_URL,
        {
          data: { id: bedPacketId, message, sendto, attachpdf },
        },
        false,
        true
      );

      toast.success("Notification sent successfully!");

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      dispatch(bedPacketRequestActions.setSaveError(getErrorMessage(e)));
      if (onError) {
        onError();
      }
    } finally {}
  };
};

/**
 * Send a notification about the bed packet
 * @param message
 * @param bedPacketId
 * @param onSuccess
 * @returns {Function}
 */
 export const notifyBedPacketAll = (message, bedPacketId, onSuccess, onError) => {
  return async (dispatch, getState) => {
    dispatch(bedPacketRequestActions.setSaveError(null));
    try {
      const result = await upsertObjectToAPI(
        constants.SEND_NOTIFICATION_ALL_URL,
        {
          data: { id: bedPacketId, message },
        },
        false,
        true
      );

      toast.success("Notification sent successfully!");

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      dispatch(bedPacketRequestActions.setSaveError(getErrorMessage(e)));
      if (onError) {
        onError();
      }
    } finally {}
  };
};

/**
 * Cancel the bed packet
 * @param bedPacketId
 * @param data
 * @param onSuccess
 * @param onError
 * @returns {Function}
 */
 export const cancelBedPacket = (bedPacketId, data, onSuccess, onError) => {
  return async (dispatch, getState) => {
    dispatch(bedPacketRequestActions.setSaveError(null));
    dispatch(bedPacketRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.CANCEL_BEDPACKET_URL,
        { id: bedPacketId, ...data },
        false,
        true,
        "data.data"
      );
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(
          { bedPacket: get(result, 'bedPacket') },
          {
            ...objectsSchema,
            bedPacket: bedPacketSchema,
          }
        ),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      dispatch(bedPacketRequestActions.setSaveError(getErrorMessage(e)));
      if (onError) {
        onError();
      }
    } finally {
      dispatch(bedPacketRequestActions.setIsSaving(false));
    }
  };
};

/**
 * ARSM Approve the bed packet
 * @param bedPacketId
 * @param data
 * @param onSuccess
 * @param onError
 * @returns {Function}
 */
 export const approveBedPacket = (bedPacketId, data, onSuccess, onError) => {
  return async (dispatch, getState) => {
    dispatch(bedPacketRequestActions.setSaveError(null));
    dispatch(bedPacketRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.APPROVE_BEDPACKET_URL,
        { id: bedPacketId, ...data },
        true,
        true,
        "data.data"
      );
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(
          { bedPacket: get(result, 'bedPacket') },
          {
            ...objectsSchema,
            bedPacket: bedPacketSchema,
          }
        ),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      dispatch(bedPacketRequestActions.setSaveError(getErrorMessage(e)));
      if (onError) {
        onError();
      }
    } finally {
      dispatch(bedPacketRequestActions.setIsSaving(false));
    }
  };
};

/**
 * ARSM Decline the bed packet
 * @param bedPacketId
 * @param data
 * @param onSuccess
 * @param onError
 * @returns {Function}
 */
 export const declineBedPacket = (bedPacketId, data, onSuccess, onError) => {
  return async (dispatch, getState) => {
    dispatch(bedPacketRequestActions.setSaveError(null));
    dispatch(bedPacketRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.DECLINE_BEDPACKET_URL,
        { id: bedPacketId, ...data },
        true,
        true,
        "data.data"
      );
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(
          { bedPacket: get(result, 'bedPacket') },
          {
            ...objectsSchema,
            bedPacket: bedPacketSchema,
          }
        ),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      dispatch(bedPacketRequestActions.setSaveError(getErrorMessage(e)));
      if (onError) {
        onError();
      }
    } finally {
      dispatch(bedPacketRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Spit out an excel version of the report
 * @returns {Function}
 */
export const fetchXlsx = (startDate, endDate, filter) => {
  return async (dispatch, getState) => {
    if (startDate && endDate) {
      dispatch(bedPacketRequestActions.setLoadError(null));
      dispatch(bedPacketRequestActions.setIsLoading(true));
      try {
        let url = constants.FETCH_RESULTS_XLS_URL.replace(
          ":startDate",
          dayjs(startDate).format("YYYY-MM-DD")
        ).replace(":endDate", dayjs(endDate).format("YYYY-MM-DD"));
        // Parameterize filter object and append to url if not empty
        if (filter && Object.keys(filter).length > 0) {
          const queryString = Object.keys(filter).map((key) => {
            return `${key}=${filter[key]}`;
          });
          url += `?${queryString.join("&")}`;
        }
        await fetchFileFromAPI(
          url,
          `all-bed-packets-${startDate}-${endDate}.csv`
        );
      } catch (e) {
        console.error(e);
        captureException(e);
        dispatch(bedPacketRequestActions.setLoadError(getErrorMessage(e)));
      } finally {
        dispatch(bedPacketRequestActions.setIsLoading(false));
      }
    }
  };
};

export const actions = {
  ...objectActions,
  fetchObject,
  fetchObjects,
  upsertObject,
  upsertSectionObject,
  upsertMedia,
  destroyMedia,
  fetchBedPacketPDF,
  updateSortFilterLimit,
  emailBedPacket,
  notifyBedPacket,
  notifyBedPacketAll,
  fetchNotes,
  upsertNote,
  cancelBedPacket,
  approveBedPacket,
  declineBedPacket,
  fetchXlsx,
};
