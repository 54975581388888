import React from "react";

import { get } from "lodash";
import { bool, func, string } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { Card, Grid, Icon, Message } from "semantic-ui-react";
import { APP_BRAND_NAME, ROLE_EMPLOYEE } from "../../../constants";
import { ErrorBoundaryComponent } from "../../../components";
import { goToDefaultRoute, goToPortalRoute, getToken, getUser } from "../../../helpers/auth";
import * as actions from "../../../store/auth/actions";
import {
  getAuthenticationError,
  getVerificationError,
  isAuthenticating,
  isVerifyingToken,
} from "../../../store/auth/selectors";
import { CustomerRegisterForm } from "./Form";

class CustomerRegisterComponent extends ErrorBoundaryComponent {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.history.location.search);
    const token = params.get("token");
    this.state = {
      tokenIsValid: false,
      token: token,
    };
  }

  componentDidMount() {
    const { verifyRegistrationToken, history } = this.props;
    const { token } = this.state;
    // Redirect if logged in already.
    if (getToken()) {
      const user = getUser();
      if (user.hasRole(ROLE_EMPLOYEE)) {
        // If user is employee, go to default route.
        goToDefaultRoute(history);
      } else {
        // If user is not employee, go to portal route.
        goToPortalRoute(history);
      }
    }
    verifyRegistrationToken(
      { registration_token: token },
      () => {
        this.setState({
          tokenIsValid: true,
        });
      },
      () => {}
    );
  }

  handleSubmit = (values) => {
    const { postCustomerRegistration, history } = this.props;
    const { token } = this.state;
    postCustomerRegistration(
      {
        password: get(values, "password"),
        password_confirmation: get(values, "password_confirmation"),
        registration_token: token,
      },
      (user) => {
        goToPortalRoute(history);
      }
    );
    return false;
  };

  render() {
    const {
      authenticationError,
      verificationError,
      isAuthenticating,
      isVerifyingToken,
    } = this.props;

    const { tokenIsValid } = this.state;
    return (
      <div className="view-login full-height">
        <div className="rose" />
        <Grid
          centered
          columns={1}
          verticalAlign="middle"
          className="full-height"
        >
          <Grid.Column>
            <Card className="hide-opacity login centered middle big-shadow fade-in-and-scale">
              <main className="login-area-container">
                <article className="login-area">
                  <header className="d-flex flex-column">
                    <div className="logo-container"></div>
                    <span className="company mt-2">
                      {APP_BRAND_NAME.toUpperCase()}
                    </span>
                    <span className="title mt-5">
                      {!verificationError ? "Welcome!" : "Sorry"}
                    </span>
                  </header>

                  {isVerifyingToken && (
                    <div className="fs-5 mt-5">
                      <Icon
                        name="hourglass outline"
                        className="m-3 pulse-more"
                      />
                      <p className="fs-3">Verifying link...</p>
                    </div>
                  )}
                  {tokenIsValid && (
                    <div>
                      <p className="mt-3">
                        To sign in to the customer portal,
                        <br />
                        please create a secure password.
                      </p>
                      <CustomerRegisterForm
                        authenticationError={authenticationError}
                        isSubmitting={isAuthenticating}
                        onSubmit={this.handleSubmit}
                      />
                    </div>
                  )}
                  {!tokenIsValid && !isVerifyingToken && (
                    <div>
                      <Message negative className="mt-8">
                        <p className="text-red">{verificationError}</p>
                      </Message>
                    </div>
                  )}
                </article>
              </main>
              {process.env.REACT_APP_VERSION && (
                <footer className="mt-5">
                  <p className="fs-3 text-center text-gray15">
                    v{process.env.REACT_APP_VERSION}
                  </p>
                </footer>
              )}
            </Card>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

CustomerRegisterComponent.propTypes = {
  authenticationError: string,
  verificationError: string,
  isAuthenticating: bool,
  isVerifyingToken: bool,
  setIsAuthenticating: func.isRequired,
  setAuthenticationError: func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  authenticationError: getAuthenticationError(),
  verificationError: getVerificationError(),
  isAuthenticating: isAuthenticating(),
  isVerifyingToken: isVerifyingToken(),
});

export const CustomerRegister = withRouter(
  connect(
    mapStateToProps,
    actions
  )(CustomerRegisterComponent)
);
