import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "location";
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}admin/location`;
export const FETCH_URL = `${process.env.REACT_APP_API_URL}admin/location`;
export const FETCH_NOTE_LIST_URL = `${process.env.REACT_APP_API_URL}admin/note/location`;
export const UPSERT_NOTE_URL = `${process.env.REACT_APP_API_URL}admin/note`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}admin/location`;
export const FETCH_TAXES_URL = `${process.env.REACT_APP_API_URL}admin/invoice/getTaxes`;
export const CALCULATE_TAXES_URL = `${process.env.REACT_APP_API_URL}api/v2/invoice/taxcalc`;

export const REDUCER_NAME = "locations";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  CALCULATE_TAXES_URL,
  FETCH_LIST_URL,
  UPSERT_URL,
  FETCH_URL,
  FETCH_NOTE_LIST_URL,
  FETCH_TAXES_URL,
  UPSERT_NOTE_URL,
  UPSERT_PROPERTY
};
