import { bool, func, string } from "prop-types";
import React from "react";
import { get } from "lodash";
import { Card, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { ErrorBoundaryComponent } from "../../../components";
import * as actions from "../../../store/auth/actions";
import { getValidationError, isValidating, getAuthenticationError, isAuthenticating } from "../../../store/auth/selectors";
import { ResetPasswordForm } from "./Form";
import { WEB_ROUTE_LOGIN } from './../../../constants/routes';

class ResetPasswordComponent extends ErrorBoundaryComponent {
  state = {
    isValidating: true,
    token: '',
    successMessage: '',
  };

  componentDidMount() {
    const { history } = this.props;
    // Secure reset token is in search query = token
    const params = new URLSearchParams(history.location.search);
    const token = params.get('token');
    this.setState({token: token});
  }

  handleSubmit = (values) => {
    const { resetPassword } = this.props;
    const { token } = this.state;
    this.setState({
      successMessage: ''
    });
    resetPassword(values, token, (response) => {
      // Handle response
      if(get(response, 'status') === 'success'){
        this.setState({
          successMessage: get(response, 'message')
        })
      }
    })
    return false;
  };

  render() {
    const { isAuthenticating, authenticationError } = this.props;
    const { successMessage } = this.state;
    return (
      <div className="view-login full-height">
        <div className="rose" />
        <Grid
          centered
          columns={1}
          verticalAlign="middle"
          className="full-height"
        >
          <Grid.Column>
            <Card className="hide-opacity login centered middle big-shadow fade-in-and-scale">
              <main className="login-area-container">
                <article className="login-area">
                  <header className="d-flex flex-column">
                    <div className="logo-container"></div>
                    <span className="company mt-2">
                      WITHERSPOON ROSE CULTURE
                    </span>
                    <span className="title mt-2">Reset Password</span>
                  </header>
                  <ResetPasswordForm
                    authenticationError={authenticationError}
                    isAuthenticating={isAuthenticating}
                    onSubmit={this.handleSubmit}
                    successMessage={successMessage}
                  />
                  <div className='mt-5'>
                    <Link
                      to={{
                        pathname: WEB_ROUTE_LOGIN,
                      }}
                      className="pl-1 underline"
                    >
                      Back
                    </Link>
                  </div>

                </article>
              </main>
              {process.env.REACT_APP_VERSION && (
                <footer className="mt-5">
                  <p className="fs-3 text-center text-gray15">
                    v{process.env.REACT_APP_VERSION}
                  </p>
                </footer>
              )}
            </Card>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

ResetPasswordComponent.propTypes = {
  authenticationError: string,
  isAuthenticating: bool,
  loginWithEmailPassword: func.isRequired,
  setIsAuthenticating: func.isRequired,
  setAuthenticationError: func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  validationError: getValidationError(),
  authenticationError: getAuthenticationError(),
  isValidating: isValidating(),
  isAuthenticating: isAuthenticating(),
});

export const ResetPassword = withRouter(
  connect(
    mapStateToProps,
    actions
  )(ResetPasswordComponent)
);
