import React from 'react';

import { string } from 'prop-types';
import { Message } from 'semantic-ui-react';

import { ALERT_ERROR } from '../../constants/general';

/**
 * A component used to render the header of a page such as a form or report
 */
export const PageAlert = React.memo(
  ({ className, message, title, level = ALERT_ERROR }) => {
    if (!message) {
      return null;
    }
    return (
      <Message negative={level === ALERT_ERROR} className={className}>
        {title && <Message.Header>{title}</Message.Header>}
        <p className="pre-wrap">{message}</p>
      </Message>
    );
  }
);

PageAlert.propTypes = {
  level: string,
  message: string,
  title: string
};
