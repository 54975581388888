import { APPROVED, DRAFT, PENDING } from "./objects";

export const WEB_ROUTE_LOGIN = "/login";
export const WEB_ROUTE_LOGOUT = "/logout";
export const WEB_ROUTE_FORGOT_PASSWORD = "/forgot-password";
export const WEB_ROUTE_RESET_PASSWORD = "/reset-password";
export const WEB_ROUTE_PORTAL_REGISTER = "/portal-register";
export const WEB_ROUTE_DEFAULT = "/admin";
export const WEB_ROUTE_PORTAL_DEFAULT = "/portal";
export const WEB_ROUTE_PORTAL_PEEK_DEFAULT = "/portal-peek";

/**
 * Error pages
 */
export const WEB_ROUTE_NOT_FOUND =  "/404";
export const WEB_ROUTE_FORBIDDEN =  "/403";

/**
 * Routes for customer portal.
 */

export const WEB_ROUTE_PORTAL_SERVICE = `${WEB_ROUTE_PORTAL_DEFAULT}/service`;
export const WEB_ROUTE_PORTAL_BILLING = `${WEB_ROUTE_PORTAL_DEFAULT}/billing`;
export const WEB_ROUTE_PORTAL_INVOICE_LIST = `${WEB_ROUTE_PORTAL_DEFAULT}/invoices`;

/**
 * Routes for employee dashboard.
 */

// Customers
export const WEB_ROUTE_CUSTOMER_LIST = `${WEB_ROUTE_DEFAULT}/customers/list`;
export const WEB_ROUTE_CUSTOMER_EDIT = `${WEB_ROUTE_DEFAULT}/customers/edit/:id`;
export const WEB_ROUTE_CUSTOMER_CREATE = `${WEB_ROUTE_DEFAULT}/customers/create`;
export const WEB_ROUTE_CUSTOMER_MAP = `${WEB_ROUTE_DEFAULT}/customers/map`;

export const WEB_ROUTE_APPOINTMENT_LIST = `${WEB_ROUTE_DEFAULT}/appointments/list`;
export const WEB_ROUTE_APPOINTMENT_EDIT = `${WEB_ROUTE_DEFAULT}/appointments/edit/:id`;
export const WEB_ROUTE_APPOINTMENT_CREATE = `${WEB_ROUTE_DEFAULT}/appointments/create`;

export const WEB_ROUTE_BED_INVOICE_LIST = `${WEB_ROUTE_DEFAULT}/bed-invoices/list`;

export const WEB_ROUTE_BED_PACKET_LIST = `${WEB_ROUTE_DEFAULT}/bed-packets/list`;
export const WEB_ROUTE_BED_PACKET_EDIT = `${WEB_ROUTE_DEFAULT}/bed-packets/edit/:id`;

export const WEB_ROUTE_ESTIMATE_LIST = `${WEB_ROUTE_DEFAULT}/estimates/list`;
export const WEB_ROUTE_ESTIMATE_EDIT = `${WEB_ROUTE_DEFAULT}/estimates/edit/:id`;
export const WEB_ROUTE_ESTIMATE_CREATE = `${WEB_ROUTE_DEFAULT}/estimates/create`;

export const WEB_ROUTE_GOOD_TYPE_LIST = `${WEB_ROUTE_DEFAULT}/inventory/good-types/list`;
export const WEB_ROUTE_GOOD_TYPE_EDIT = `${WEB_ROUTE_DEFAULT}/inventory/good-types/edit/:id`;
export const WEB_ROUTE_GOOD_TYPE_CREATE = `${WEB_ROUTE_DEFAULT}/inventory/good-types/create`;

// Services
export const WEB_ROUTE_SERVICE_ROUTE_LIST = `${WEB_ROUTE_DEFAULT}/services/routes/list`;
export const WEB_ROUTE_SERVICE_ROUTE_EDIT = `${WEB_ROUTE_DEFAULT}/services/routes/edit/:id`;
export const WEB_ROUTE_SERVICE_ROUTE_CREATE = `${WEB_ROUTE_DEFAULT}/services/routes/create`;

export const WEB_ROUTE_SERVICE_ACTIVITY_LIST = `${WEB_ROUTE_DEFAULT}/services/activity/list`;
export const WEB_ROUTE_SERVICE_ACTIVITY_EDIT = `${WEB_ROUTE_DEFAULT}/services/activity/edit/:id`;
export const WEB_ROUTE_SERVICE_ACTIVITY_CREATE = `${WEB_ROUTE_DEFAULT}/services/activity/create`;

export const WEB_ROUTE_SERVICE_LIST = `${WEB_ROUTE_DEFAULT}/services/list`;
export const WEB_ROUTE_SERVICE_EDIT = `${WEB_ROUTE_DEFAULT}/services/edit/:id`;
export const WEB_ROUTE_SERVICE_CREATE = `${WEB_ROUTE_DEFAULT}/services/create`;

export const WEB_ROUTE_SERVICE_LEVEL_LIST = `${WEB_ROUTE_DEFAULT}/services/levels/list`;
export const WEB_ROUTE_SERVICE_LEVEL_EDIT = `${WEB_ROUTE_DEFAULT}/services/levels/edit/:id`;
export const WEB_ROUTE_SERVICE_LEVEL_CREATE = `${WEB_ROUTE_DEFAULT}/services/levels/create`;

export const WEB_ROUTE_SERVICE_PERIOD_LIST = `${WEB_ROUTE_DEFAULT}/services/periods/list`;
export const WEB_ROUTE_SERVICE_PERIOD_EDIT = `${WEB_ROUTE_DEFAULT}/services/periods/edit/:id`;
export const WEB_ROUTE_SERVICE_PERIOD_CREATE = `${WEB_ROUTE_DEFAULT}/services/periods/create`;

export const WEB_ROUTE_SERVICE_SCHEDULE_LIST = `${WEB_ROUTE_DEFAULT}/services/schedules/list`;
export const WEB_ROUTE_SERVICE_SCHEDULE_EDIT = `${WEB_ROUTE_DEFAULT}/services/schedules/edit/:id`;
export const WEB_ROUTE_SERVICE_SCHEDULE_CREATE = `${WEB_ROUTE_DEFAULT}/services/schedules/create`;

export const WEB_ROUTE_SERVICE_PRICE_LIST = `${WEB_ROUTE_DEFAULT}/services/prices/list`;
export const WEB_ROUTE_SERVICE_PRICE_EDIT = `${WEB_ROUTE_DEFAULT}/services/prices/edit/:id`;
export const WEB_ROUTE_SERVICE_PRICE_CREATE = `${WEB_ROUTE_DEFAULT}/services/prices/create`;

export const WEB_ROUTE_SERVICE_STATEMENT_LIST = `${WEB_ROUTE_DEFAULT}/services/statements/list`;
export const WEB_ROUTE_SERVICE_STATEMENT_VIEW = `${WEB_ROUTE_DEFAULT}/services/statements/view/:id`;
export const WEB_ROUTE_SERVICE_STATEMENT_FILES_LIST = `${WEB_ROUTE_DEFAULT}/services/statements/view/files/:id`;
export const WEB_ROUTE_SERVICE_STATEMENT_EDIT = `${WEB_ROUTE_DEFAULT}/services/statements/edit/:id`;
export const WEB_ROUTE_SERVICE_STATEMENT_CREATE = `${WEB_ROUTE_DEFAULT}/services/statements/create`;

export const WEB_ROUTE_SERVICE_AUTORUNTAX_VIEW = `${WEB_ROUTE_DEFAULT}/services/runtax/view/:id`;

export const WEB_ROUTE_SERVICE_PAYMENT_LIST = `${WEB_ROUTE_DEFAULT}/services/payments/list`;
export const WEB_ROUTE_SERVICE_PAYMENT_EDIT = `${WEB_ROUTE_DEFAULT}/services/payments/edit/:id`;
export const WEB_ROUTE_SERVICE_PAYMENT_CREATE = `${WEB_ROUTE_DEFAULT}/services/payments/create`;

export const WEB_ROUTE_SERVICE_ERROR_LOG = `${WEB_ROUTE_DEFAULT}/services/errors`;

// Locations
export const WEB_ROUTE_LOCATION_LIST = `${WEB_ROUTE_DEFAULT}/locations/list`;
export const WEB_ROUTE_LOCATION_EDIT = `${WEB_ROUTE_DEFAULT}/locations/edit/:id`;
export const WEB_ROUTE_LOCATION_CREATE = `${WEB_ROUTE_DEFAULT}/locations/create`;
export const WEB_ROUTE_PROFIT_CENTER_LIST = `${WEB_ROUTE_DEFAULT}/locations/profit-centers/list`;
export const WEB_ROUTE_PROFIT_CENTER_EDIT =
  `${WEB_ROUTE_DEFAULT}/locations/profit-centers/edit/:id`;
export const WEB_ROUTE_PROFIT_CENTER_CREATE =
  `${WEB_ROUTE_DEFAULT}/locations/profit-centers/create`;

// Invoices
export const WEB_ROUTE_INVOICE_LIST = `${WEB_ROUTE_DEFAULT}/invoices/list`;
export const WEB_ROUTE_INVOICE_EDIT = `${WEB_ROUTE_DEFAULT}/invoices/edit/:id`;
export const WEB_ROUTE_INVOICE_CREATE = `${WEB_ROUTE_DEFAULT}/invoices/create`;

// Inventory
export const WEB_ROUTE_PRODUCT_CLASS_LIST = `${WEB_ROUTE_DEFAULT}/inventory/product-classes/list`;
export const WEB_ROUTE_PRODUCT_CLASS_EDIT =
  `${WEB_ROUTE_DEFAULT}/inventory/product-classes/edit/:id`;
export const WEB_ROUTE_PRODUCT_CLASS_CREATE =
  `${WEB_ROUTE_DEFAULT}/inventory/product-classes/create`;
export const WEB_ROUTE_GOOD_LIST = `${WEB_ROUTE_DEFAULT}/inventory/goods/list`;
export const WEB_ROUTE_GOOD_EDIT = `${WEB_ROUTE_DEFAULT}/inventory/goods/edit/:id`;
export const WEB_ROUTE_GOOD_CREATE = `${WEB_ROUTE_DEFAULT}/inventory/goods/create`;
export const WEB_ROUTE_TRANSFER_LIST = `${WEB_ROUTE_DEFAULT}/inventory/transfers/list`;
export const WEB_ROUTE_TRANSFER_EDIT = `${WEB_ROUTE_DEFAULT}/inventory/transfers/edit/:id`;
export const WEB_ROUTE_TRANSFER_CREATE = `${WEB_ROUTE_DEFAULT}/inventory/transfers/create`;
export const WEB_ROUTE_SPRAY_MIX = `${WEB_ROUTE_DEFAULT}/inventory/spray-mix`;
export const WEB_ROUTE_CHEMICAL_LIST = `${WEB_ROUTE_DEFAULT}/inventory/chemicals/list`;
export const WEB_ROUTE_CHEMICAL_EDIT = `${WEB_ROUTE_DEFAULT}/inventory/chemicals/edit/:id`;
export const WEB_ROUTE_CHEMICAL_CREATE = `${WEB_ROUTE_DEFAULT}/inventory/chemicals/create`;
export const WEB_ROUTE_ACTIVITY_LIST = `${WEB_ROUTE_DEFAULT}/inventory/activity/list`;
export const WEB_ROUTE_MANAGE_IA = `${WEB_ROUTE_DEFAULT}/inventory/ia/manage`;
export const WEB_ROUTE_HISTORY_LIST = `${WEB_ROUTE_DEFAULT}/inventory/history/list`;
export const WEB_ROUTE_FS_ADJ = `${WEB_ROUTE_DEFAULT}/inventory/fs/adj`;
export const WEB_ROUTE_CYCLE_COUNT_LIST = `${WEB_ROUTE_DEFAULT}/inventory/cycle-counts/list`;
export const WEB_ROUTE_CYCLE_COUNT_EDIT = `${WEB_ROUTE_DEFAULT}/inventory/cycle-counts/edit/:id`;
export const WEB_ROUTE_CYCLE_COUNT_CREATE = `${WEB_ROUTE_DEFAULT}/inventory/cycle-counts/create`;
export const WEB_ROUTE_LOT_COST_CHANGE = `${WEB_ROUTE_DEFAULT}/inventory/lot-cost-change`;

// Work Orders
export const WEB_ROUTE_WORK_ORDER_LIST = `${WEB_ROUTE_DEFAULT}/work-orders/list`;
export const WEB_ROUTE_WORK_ORDER_EDIT = `${WEB_ROUTE_DEFAULT}/work-orders/edit/:id`;
export const WEB_ROUTE_WORK_ORDER_CREATE = `${WEB_ROUTE_DEFAULT}/work-orders/create`;

// Users
export const WEB_ROUTE_USER_LIST = `${WEB_ROUTE_DEFAULT}/users/list`;
export const WEB_ROUTE_USER_EDIT = `${WEB_ROUTE_DEFAULT}/users/edit/:id`;
export const WEB_ROUTE_USER_CREATE = `${WEB_ROUTE_DEFAULT}/users/create`;

// Groups
export const WEB_ROUTE_GROUP_LIST = `${WEB_ROUTE_DEFAULT}/groups/list`;
export const WEB_ROUTE_GROUP_EDIT = `${WEB_ROUTE_DEFAULT}/groups/edit/:id`;
export const WEB_ROUTE_GROUP_CREATE = `${WEB_ROUTE_DEFAULT}/groups/new`;

// Purchase Orders
export const WEB_ROUTE_PO_ALL = `${WEB_ROUTE_DEFAULT}/po/list`;
export const WEB_ROUTE_PO_EDIT = `${WEB_ROUTE_DEFAULT}/po/edit/:id`;
export const WEB_ROUTE_PO_PDF = `${process.env.REACT_APP_API_URL}po/pdf/:id`;
export const WEB_ROUTE_PO_CREATE = `${WEB_ROUTE_DEFAULT}/po/list/create`;
export const WEB_ROUTE_PO_STATUS = `/po/list/:status`;
export const WEB_ROUTE_PO_DRAFT = `/po/list/${DRAFT}`;
export const WEB_ROUTE_PO_PENDING = `/po/list/${PENDING}`;
export const WEB_ROUTE_PO_APPROVED = `/po/list/${APPROVED}`;

// Reports
export const WEB_ROUTE_REPORTS = `${WEB_ROUTE_DEFAULT}/reports`;

// Roles
export const WEB_ROUTE_ROLE_LIST = `${WEB_ROUTE_DEFAULT}/roles/list`;
export const WEB_ROUTE_ROLE_EDIT = `${WEB_ROUTE_DEFAULT}/roles/edit/:id`;
export const WEB_ROUTE_ROLE_CREATE = `${WEB_ROUTE_DEFAULT}/roles/new`;

// Sales Dashboard
export const WEB_ROUTE_SALES_DASHBOARD = `${WEB_ROUTE_DEFAULT}/sales`;

// ARSM Dashboard
export const WEB_ROUTE_ARSM_DASHBOARD = `${WEB_ROUTE_DEFAULT}/arsm`;

// Settings
export const WEB_ROUTE_SETTINGS_ALL = `${WEB_ROUTE_DEFAULT}/settings/list`;

// Vendors
export const WEB_ROUTE_VENDOR_LIST = `${WEB_ROUTE_DEFAULT}/vendors/list`;
export const WEB_ROUTE_VENDOR_CREATE = `${WEB_ROUTE_DEFAULT}/vendors/new`;
export const WEB_ROUTE_VENDOR_EDIT = `${WEB_ROUTE_DEFAULT}/vendors/edit/:id`;

// Reports
//Inventory Reports
export const WEB_ROUTE_REPORT_INVENTORY = `${WEB_ROUTE_DEFAULT}/report/inventory/inventory`;
export const WEB_ROUTE_REPORT_REORDER_POINTS =
  `${WEB_ROUTE_DEFAULT}/report/inventory/reorder-points`;
export const WEB_ROUTE_REPORT_INVENTORY_SALES_REPORT =
  `${WEB_ROUTE_DEFAULT}/report/inventory/inventory-sales-report`;
export const WEB_ROUTE_REPORT_VALUE_BY_PRODUCT_CLASS =
  `${WEB_ROUTE_DEFAULT}/report/inventory/value-by-product-class`;
export const WEB_ROUTE_REPORT_ROSES_ON_HAND = `${WEB_ROUTE_DEFAULT}/report/inventory/roses-on-hand`;
export const WEB_ROUTE_REPORT_ACTIVITY_BY_LOT_COST =
  `${WEB_ROUTE_DEFAULT}/report/inventory/activity-by-lot-cost`;
export const WEB_ROUTE_REPORT_ACTIVITY_BY_LOCATION =
  `${WEB_ROUTE_DEFAULT}/report/inventory/activity-by-location`;

// Retail Reports
export const WEB_ROUTE_REPORT_GC_ACTIVITY = `${WEB_ROUTE_DEFAULT}/report/retail/gc-activity`;
export const WEB_ROUTE_REPORT_PAID_ROSES = `${WEB_ROUTE_DEFAULT}/report/retail/paid-roses`;
export const WEB_ROUTE_REPORT_NON_ROSE_SALES = `${WEB_ROUTE_DEFAULT}/report/retail/non-rose-sales`;

// Statement Reports
export const WEB_ROUTE_REPORT_FAT_FINGER = `${WEB_ROUTE_DEFAULT}/report/statement/fat-finger`;
export const WEB_ROUTE_REPORT_SERVICE_REVENUE =
  `${WEB_ROUTE_DEFAULT}/report/statement/service-revenue`;
export const WEB_ROUTE_REPORT_SERVICE_REVENUE_DETAIL =
  `${WEB_ROUTE_DEFAULT}/report/statement/service-revenue-detail`;
export const WEB_ROUTE_REPORT_SALES_TAX_SUMMARY =
  `${WEB_ROUTE_DEFAULT}/report/statement/sales-tax-summary`;
export const WEB_ROUTE_REPORT_SALES_TAX_DETAIL =
  `${WEB_ROUTE_DEFAULT}/report/statement/salex-tax-detail`;

// Service Customer Reports
export const WEB_ROUTE_REPORT_TABS = `${WEB_ROUTE_DEFAULT}/report/service-customers/tabs`;
export const WEB_ROUTE_REPORT_EXPIRING_CC =
  `${WEB_ROUTE_DEFAULT}/report/service-customers/expiring-cc`;
  export const WEB_ROUTE_REPORT_UPDATED_PAYMENT_PROFILES =
    `${WEB_ROUTE_DEFAULT}/report/service-customers/updated-payment-profiles`;
export const WEB_ROUTE_REPORT_ACTIVE_CUSTOMERS =
  `${WEB_ROUTE_DEFAULT}/report/service-customers/active-customers`;

// Other Reports
export const WEB_ROUTE_REPORT_COMMISSION = `${WEB_ROUTE_DEFAULT}/report/other/commission`;
export const WEB_ROUTE_REPORT_OPEN_ITEM = `${WEB_ROUTE_DEFAULT}/report/other/open-item`;
export const WEB_ROUTE_REPORT_INVENTORY_ADJUSTMENTS =
  `${WEB_ROUTE_DEFAULT}/report/other/inventory-adjustments`;
export const WEB_ROUTE_REPORT_INVENTORY_ADJUSTMENTS_V2 =
  `${WEB_ROUTE_DEFAULT}/report/other/inventory-adjustments-v2`;
export const WEB_ROUTE_REPORT_TRANSFER = `${WEB_ROUTE_DEFAULT}/report/other/transfer`;
export const WEB_ROUTE_REPORT_FINANCIAL = `${WEB_ROUTE_DEFAULT}/report/other/financial`;
export const WEB_ROUTE_REPORT_INVOICE_DIFF_STATUS =
  `${WEB_ROUTE_DEFAULT}/report/other/invoice-diff-status`;
export const WEB_ROUTE_REPORT_PRODUCT_CLASS_RECEIPTS =
  `${WEB_ROUTE_DEFAULT}/report/other/product-class-receipts`;
export const WEB_ROUTE_REPORT_INVOICE_TAX_REPORT =
  `${WEB_ROUTE_DEFAULT}/report/other/invoice-tax-report`;
export const WEB_ROUTE_REPORT_WORK_ORDER_STATUS =
  `${WEB_ROUTE_DEFAULT}/report/other/work-order-status`;

export const WEB_ROUTE_REPORT_STARTS_STOPS = `${WEB_ROUTE_DEFAULT}/report/svccust/cust_num`;
export const WEB_ROUTE_REPORT_SERVICE_ACCOUNTS_RECEIVABLE =
  `${WEB_ROUTE_DEFAULT}/report/service-accounts-receivable`;

export const WEB_ROUTE_REPORT_SERVICE_ACTIVITY = `${WEB_ROUTE_DEFAULT}/report/service-activity`;
export const WEB_ROUTE_REPORT_OPEN_WORK_ORDERS = `${WEB_ROUTE_DEFAULT}/report/work-orders`;

export const WEB_ROUTE_UTILITY_REVERSE = `${WEB_ROUTE_DEFAULT}/util/reversal-deletions`;
export const WEB_ROUTE_UTILITY_IMPORT = `${WEB_ROUTE_DEFAULT}/util/invoice/import`;
