import {
  get,
  uniq,
} from 'lodash';
import { normalize } from 'normalizr';

import { PO_FILTER_FETCH_MAP } from '../../../constants';
import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  notesSchema,
  objectsSchema,
  purchaseOrderSchema,
  purchaseOrdersSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import { actions as noteRequestActions } from '../../request/note/actions';
import {
  actions as poRequestActions,
} from '../../request/purchaseOrder/actions';
import { sortAndLimitResults } from '../../view/actions';
import {
  actions as purchaseOrderViewActions,
} from '../../view/purchaseOrder/actions';
import { poViewSelector } from '../../view/purchaseOrder/selectors';
import { vendorViewSelector } from '../../view/vendor/selectors';
import {
  fetchFileFromAPI,
  fetchNotesFromAPI,
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';

const objectActions = makeObjectActions(constants);
/**
 * Load a list of purchase orders
 */
export const fetchObjects = (dateFilter, tableState) => {
  return async dispatch => {
    dispatch(poRequestActions.setLoadAllError(null));
    dispatch(poRequestActions.setIsLoadingAll(true));
    try {
      // If they've passed in a filter string, setup the url to hit the correct endpoint.
      const fetchFilter = PO_FILTER_FETCH_MAP[dateFilter];

      const purchaseOrders = await fetchObjectsFromAPI(
        fetchFilter
          ? `${constants.FETCH_LIST_URL}/limit/${fetchFilter}`
          : constants.FETCH_LIST_URL
      );
      // Normalize the result and store the roles/users in redux
      const entities = get(
        normalize(purchaseOrders, purchaseOrdersSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Store the full list of ids in the reducer
      dispatch(
        purchaseOrderViewActions.setDateFilteredObjects(
          dateFilter,
          purchaseOrders.map(purchaseOrder => {
            return purchaseOrder.id;
          })
        )
      );

      dispatch(updateSortFilterLimit(dateFilter, tableState));
      dispatch(poRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(poRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(poRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param dateFilter
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = (dateFilter, tableState) => {
  return async (dispatch, getState) => {
    const purchaseOrders = poViewSelector().getDateRangeFilteredObjects()(
      getState()
    );

    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(purchaseOrders, tableState, (ids, count) => {
        dispatch(
          purchaseOrderViewActions.setDateFilteredList(dateFilter, ids, count)
        );
      })
    );
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(poRequestActions.setSaveError(null));
    dispatch(poRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(constants.UPSERT_URL, data);

      const entities = get(
        normalize(result.po, purchaseOrderSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.po);
      }
    } catch (e) {
      captureException(e);
      dispatch(poRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(poRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = id => {
  return async dispatch => {
    dispatch(poRequestActions.setLoadError(null));
    dispatch(poRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        `${constants.FETCH_URL}/${id}`,
        "data"
      );
      // Normalize the result and store the roles/users in redux
      const entities = get(
        normalize(result, { ...objectsSchema, po: purchaseOrderSchema }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(poRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(poRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Fetch the notes from the api and store their result in our reducer
 * @param id
 * @returns {Function}
 */
export const fetchNotes = id => {
  return async dispatch => {
    dispatch(noteRequestActions.setLoadAllError(null));
    dispatch(noteRequestActions.setIsLoadingAll(true));
    try {
      const notes = await dispatch(
        fetchNotesFromAPI(id, `${constants.FETCH_NOTE_LIST_URL}/${id}`)
      );

      dispatch(
        purchaseOrderViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          id,
          notes.map(note => {
            return note.id;
          })
        )
      );
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save a note on the api
 * @param parentId
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertNote = (parentId, data, onSuccess) => {
  return async (dispatch, getState) => {
    dispatch(noteRequestActions.setSaveError(null));
    dispatch(noteRequestActions.setIsSaving(true));
    try {
      data.kind = data.kind || "po";

      const result = await upsertObjectToAPI(constants.UPSERT_NOTE_URL, data);
      const entities = get(normalize([result], notesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      const notes = vendorViewSelector().getNoteIds(parentId)(getState());
      const updatedIds = uniq([...notes, result.id]);
      dispatch(
        purchaseOrderViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          parentId,
          updatedIds
        )
      );

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Get the pdf of the invoice and download it
 * @param purchaseOrderId
 * @returns {Function}
 */
export const fetchPurchaseOrderPdf = purchaseOrderId => {
  return async dispatch => {
    dispatch(poRequestActions.setLoadError(null));
    dispatch(poRequestActions.setIsLoadingAll(true));
    try {
      await fetchFileFromAPI(
        constants.FETCH_PURCHASE_ORDER_PDF_URL.replace(
          ":purchaseOrderId",
          purchaseOrderId
        ),
        `po-${purchaseOrderId}.pdf`
      );
    } catch (e) {
      captureException(e);
      dispatch(poRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(poRequestActions.setIsLoadingAll(false));
    }
  };
};

export const actions = {
  ...objectActions,
  updateSortFilterLimit,
  fetchNotes,
  fetchObject,
  fetchObjects,
  fetchPurchaseOrderPdf,
  upsertNote,
  upsertObject
};
