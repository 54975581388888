import React, {
  useEffect,
  useState,
} from 'react';

import cx from 'classnames';
import {
  arrayOf,
  bool,
  func,
  object,
  string,
} from 'prop-types';
import { connect } from 'react-redux';
import { Select } from 'semantic-ui-react';

import { createOptionsFromObjects } from '../../../helpers/form/util';
import {
  actions as taxCodeObjectActions,
} from '../../../store/object/taxCode/actions';
import { taxCodeSelector } from '../../../store/object/taxCode/selectors';
import { taxCodeRequestSelector } from '../../../store/request/taxCode/selectors';

const TaxCodeSelectComponent = ({
  disabled,
  fetchTaxCodes,
  id,
  input,
  isLoadedAll,
  isLoadingAll,
  loadAllError,
  label,
  taxCode,
  taxCodes,
  onSelectTaxCode,
  onSelectTaxCodeId,
  placeholder,
  required
}) => {
  if (input && input.value) {
    taxCode = input.value;
  }
  useEffect(() => {
    if (!isLoadedAll && !isLoadingAll && !loadAllError) {
      fetchTaxCodes();
    }
  }, [isLoadedAll, isLoadingAll, fetchTaxCodes, loadAllError]);

  const [hasFocus, setHasFocus] = useState(false);
  const taxCodeOptions = createOptionsFromObjects(taxCodes, 'product_tax_code', 'name');
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true,
    "has-focus": hasFocus,
    "has-value": !!taxCode
  });
  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <Select
        upward={false}
        fluid
        disabled={disabled}
        size="huge"
        value={taxCode}
        search
        name="tax_code"
        loading={isLoadingAll}
        placeholder={placeholder}
        selection
        options={taxCodeOptions}
        onOpen={() => {
          setHasFocus(true);
        }}
        openOnFocus={false}
        onClose={() => {
          setHasFocus(false);
        }}
        onChange={(e, { value }) => {
          if (onSelectTaxCode) {
            onSelectTaxCode(taxCodes.find(taxCode => taxCode === value));
          }
          if (onSelectTaxCodeId) {
            onSelectTaxCodeId(value);
          }
          if (input && input.onChange) {
            input.onChange(value);
          }
        }}
        onBlur={(e, { value }) => {
          if (value && input && input.onChange) {
            input.onChange(value);
          }
        }}
      />
    </div>
  );
};

TaxCodeSelectComponent.propTypes = {
  fetchTaxCodes: func.isRequired,
  isLoadedAll: bool,
  isLoadingAll: bool,
  loadAllError: string,
  taxCode: string,
  onSelectTaxCode: func,
  onSelectTaxCodeId: func,
  placeholder: string,
  taxCodes: arrayOf(object)
};

const mapStateToProps = (state, props) => {
  const taxCodeObjectSelector = taxCodeSelector();
  const requestSelector = taxCodeRequestSelector();
  return {
    isLoadingAll: requestSelector.getIsLoadingAll()(state),
    isLoadedAll: requestSelector.getIsLoadedAll()(state),
    loadAllError: requestSelector.getLoadAllError()(state),
    taxCodes: taxCodeObjectSelector.getDenormalizedObjects()(state)
  };
};

export const TaxCodeSelect = connect(
  mapStateToProps,
  {
    fetchTaxCodes: taxCodeObjectActions.fetchObjects
  }
)(TaxCodeSelectComponent);
