import React from 'react';

import { get } from 'lodash';
import {
  arrayOf,
  bool,
  number,
  object,
} from 'prop-types';
import { Table } from 'semantic-ui-react';

import { DEFAULT_LIMIT } from '../../constants/table';
import {
  column,
  tableState,
} from '../../helpers/shapes';
import { DataTableBody } from './Body';
import { DataTableFooter } from './Footer';
import { DataTableHeader } from './Header';

export const DataTable = React.memo(
  ({
    compact = true,
    size,
    columns,
    fixed,
    rows,
    tableProps,
    tableState,
    total,
    exportable = true,
    loading = false,
    stickyRow,
  }) => {
    return (
      <div className="data-table-container">
        <Table
          striped
          size={size}
          className="data-table"
          sortable
          fixed={fixed}
          compact={compact}
        >
          <DataTableHeader
            columns={columns}
            tableState={tableState}
            onSortChange={tableState.onSortChange}
          />
          {stickyRow && stickyRow.position === "top" && (
            <DataTableBody
              columns={stickyRow.columns}
              rows={[stickyRow.row]}
              tableProps={stickyRow.tableProps}
            />
          )}
          <DataTableBody
            columns={columns}
            rows={rows}
            tableProps={tableProps}
          />
          {stickyRow && stickyRow.position !== "top" && (
            <DataTableBody
              columns={stickyRow.columns}
              rows={[stickyRow.row]}
              tableProps={stickyRow.tableProps}
            />
          )}
          <DataTableFooter
            loading={loading}
            columnCount={columns.length}
            currentPage={get(tableState, "page", 0)}
            onPageChange={tableState.onPageChange}
            perPage={get(tableState, "limit", DEFAULT_LIMIT)}
            total={total}
            onExportClick={tableState.onExportClick}
            onRefreshClick={tableState.onRefreshClick}
            exportable={exportable}
          />
        </Table>
      </div>
    );
  }
);

DataTable.propTypes = {
  compact: bool,
  columns: arrayOf(column),
  fixed: bool,
  rows: arrayOf(object),
  tableProps: object,
  tableState: tableState,
  total: number,
  exportable: bool,
  loading: bool,
};
