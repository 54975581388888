import { createSelector } from "reselect";
import { makeViewSelector } from "../selectors";
import { allConstants as constants } from "./constants";

export const serviceVisitViewReducer = () => state =>
  state.getIn([constants.VIEW_REDUCER_NAME, constants.REDUCER_NAME]);

export const serviceVisitViewSelector = () => {
  return {
    ...makeViewSelector(constants, serviceVisitViewReducer),
    getStartDate: () => {
      return createSelector(
        serviceVisitViewReducer(),
        viewState => {
          return viewState.get(constants.START_DATE);
        }
      );
    },
    getEndDate: () => {
      return createSelector(
        serviceVisitViewReducer(),
        viewState => {
          return viewState.get(constants.END_DATE);
        }
      );
    },
    getMonth: () => {
      return createSelector(
        serviceVisitViewReducer(),
        viewState => {
          return viewState.get(constants.MONTH);
        }
      );
    },
    getYear: () => {
      return createSelector(
        serviceVisitViewReducer(),
        viewState => {
          return viewState.get(constants.YEAR);
        }
      );
    }
  };
};
