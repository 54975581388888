import { DEFAULT_TABLE_STATE } from "../../../constants";
import { ErrorBoundaryComponent } from "../ErrorBoundaryComponent";
import { debounce } from "lodash";

class BaseListComponent extends ErrorBoundaryComponent {
  state = DEFAULT_TABLE_STATE;

  constructor(props) {
    super(props);
    this.state.search = "";
  }

  debouncedUpdateTable = debounce(() => {
    this.updateTable();
  }, 300);

  updateTable = () => {
    if (this.fetchOnTableStateChange) {
      this.props.fetchObjects(this.state);
    } else if (this.props.updateSortFilterLimit) {
      this.props.updateSortFilterLimit(this.state);
    }
  };

  handleExportClick = type => {
    const { objects } = this.props;
    this.props.exportObjects(type, objects, this.state);
  };

  handlePageChange = (event, paginationData) => {
    this.setState({ page: paginationData.activePage }, this.updateTable);
  };

  handleSortChange = (column, isAscending) => {
    this.setState(
      { sortOrder: { column, isAscending: !isAscending } },
      this.updateTable
    );
  };

  handleSearchChange = event => {
    if (event && event.currentTarget) {
      const search = event.currentTarget.value;
      this.setState({ search, page: 0 }, () => {
        this.debouncedUpdateTable();
      });
    }
  };

  handleSearchChangeWithoutUpdate = event => {
    if (event && event.currentTarget) {
      const search = event.currentTarget.value;
      this.setState({ search, page: 0 });
    }
  };

  handleSearchEnterPress = event => {
    if (event && event.currentTarget) {
      const search = event.currentTarget.value;
      this.setState({ search, page: 0 }, () => {
        this.debouncedUpdateTable();
      });
    }
  };

  handleFilterChange = (column, value) => {
    const { filter } = this.state;
    const newFilter = { ...filter, [column]: value };
    if(!value) {
      delete newFilter[column];
    }
    this.setState({ filter: newFilter }, () => {
      if (this.props.updateSortFilterLimit) {
        this.props.updateSortFilterLimit(this.state);
      }
    });
  };

  handleLimitChange = limit => {
    this.setState({ limit, page: 0 }, this.updateTable);
  };

  handleRefresh = (meta) => {
    const { isLoadingAll, fetchObjects } = this.props;
    if (!isLoadingAll) {
      fetchObjects({
        ...this.state,
        ...meta,
      });
    }
  };
}

export const ListComponent = BaseListComponent;
