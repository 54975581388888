import dayjs from "dayjs";
import { fromJS } from "immutable";
import { initialReportState } from "../reducer";
import { START_DATE, END_DATE } from "./constants";

export const initialSalesTaxSummaryReportState = constants => {
  const endDate = dayjs().format("MM/DD/YYYY");
  const startDate = dayjs()
    .add(-1, "day")
    .format("MM/DD/YYYY");
  return fromJS({
    ...initialReportState(constants).toJS(),
    [START_DATE]: startDate,
    [END_DATE]: endDate
  });
};

export const salesTaxSummaryReportReducer = (state, action, constants) => {
  switch (action.type) {
    case constants.SET_START_DATE:
      return state.set(START_DATE, action.date);
    case constants.SET_END_DATE:
      return state.set(END_DATE, action.date);
    default:
      return state;
  }
};
