export const DRAFT = "draft";
export const PENDING = "pending";
export const APPROVED = "approved";

export const PURCHASE_ORDER_STATUS_DRAFT = "draft";
export const PURCHASE_ORDER_STATUS_PENDING = "pending";
export const PURCHASE_ORDER_STATUS_APPROVED = "done";

export const PURCHASE_ORDER_STATUS_MAP = {
  [DRAFT]: PURCHASE_ORDER_STATUS_DRAFT,
  [PENDING]: PURCHASE_ORDER_STATUS_PENDING,
  [APPROVED]: PURCHASE_ORDER_STATUS_APPROVED
};
