import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "transfer";
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}admin/transfer/grid`;
export const FETCH_URL = `${process.env.REACT_APP_API_URL}admin/transfer`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}admin/transfer`;
export const MAKE_URL = `${process.env.REACT_APP_API_URL}admin/transfer/makenew`;
export const REVERSE_URL = `${process.env.REACT_APP_API_URL}admin/util/xferreverse/:transferId`;
export const RECEIVE_LINE_URL = `${process.env.REACT_APP_API_URL}admin/transfer/receiveLine/:transferLineId/:status`;
export const FETCH_TRANFER_PDF_URL = `${process.env.REACT_APP_API_URL}admin/transfer/pdf/:transferId`;
export const FETCH_NOTE_LIST_URL = `${process.env.REACT_APP_API_URL}admin/note/transfer`;
export const UPSERT_NOTE_URL = `${process.env.REACT_APP_API_URL}admin/note`;
export const REDUCER_NAME = "transfers";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_LIST_URL,
  FETCH_TRANFER_PDF_URL,
  FETCH_NOTE_LIST_URL,
  FETCH_URL,
  MAKE_URL,
  RECEIVE_LINE_URL,
  REVERSE_URL,
  UPSERT_NOTE_URL,
  UPSERT_URL,
  UPSERT_PROPERTY
};
