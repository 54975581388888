import { schema } from 'normalizr';

import {
  DELIVERY_ACTION_REDUCER_NAME,
} from '../../store/object/deliveryAction';

export const deliveryActionSchema = new schema.Entity(
  DELIVERY_ACTION_REDUCER_NAME
);

export const deliveryActionsSchema = [deliveryActionSchema];
