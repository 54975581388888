import { schema } from 'normalizr';

import {
  REDUCER_NAME as CYCLE_REDUCER_NAME,
} from '../../store/object/cycle/constants';
import { locationSchema } from './location';
import { userSchema } from './user';

export const cycleSchema = new schema.Entity(CYCLE_REDUCER_NAME, {
  assigned: userSchema,
  location: locationSchema,
  user: userSchema
});

export const cyclesSchema = [cycleSchema];
