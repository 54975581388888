import { renderEditActionCell } from "../../../../components/DataTable/Cells/EditActionCell";
import { LINK_GROUP_EDIT } from "../../../../constants";

export const columns = [
  {
    title: "Actions",
    isExcludeFromExport: true,
    isSortDisabled: true,
    width: 1,
    renderer: renderEditActionCell,
    rendererProps: { link: LINK_GROUP_EDIT },
    path: "id"
  },
  {
    title: "Name",
    path: "name",
    width: 6,
    isSearchable: true
  },
  {
    title: "Identifier",
    path: "identifier",
    width: 5,
    isSearchable: true
  }
];
