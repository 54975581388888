import {
  get,
  omit,
} from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  customersSchema,
  objectsSchema,
  serviceLevelSchema,
  serviceLevelsSchema,
  servicesSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import {
  actions as customerRequestActions,
} from '../../request/customer/actions';
import {
  actions as serviceLevelRequestActions,
} from '../../request/serviceLevel/actions';
import { sortAndLimitResults } from '../../view/actions';
import {
  actions as serviceLevelViewActions,
} from '../../view/serviceLevel/actions';
import { serviceLevelViewSelector } from '../../view/serviceLevel/selectors';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { CUSTOMER_REDUCER_NAME } from '../customer';
import { allConstants as constants } from './constants';
import { serviceLevelSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(serviceLevelRequestActions.setLoadAllError(null));
    dispatch(serviceLevelRequestActions.setIsLoadingAll(true));
    try {
      const serviceLevels = await fetchObjectsFromAPI(
        constants.FETCH_LIST_URL,
        "data.serviceLevels"
      );

      // Normalize the result and store the serviceLevels in redux
      const entities = get(
        normalize(serviceLevels, serviceLevelsSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(serviceLevels, tableState, (ids, count) => {
          dispatch(serviceLevelViewActions.setList(ids, count));
        })
      );
      dispatch(serviceLevelRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(serviceLevelRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(serviceLevelRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = id => {
  return async dispatch => {
    dispatch(serviceLevelRequestActions.setLoadError(null));
    dispatch(serviceLevelRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(`${constants.FETCH_URL}/${id}`);
      if (result && result.svc) {
        result.svc.services = result.services;
      }
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          svc: serviceLevelSchema,
          allServices: servicesSchema
        }),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(serviceLevelRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(serviceLevelRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Convert an object before we save it on the api
 * @param data
 */
const convertObjectForSave = data => {
  return {
    id: data.id,
    svc: omit(data, "services"),
    services: data.services
      ? data.services.map(service => {
          return service.id;
        })
      : []
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(serviceLevelRequestActions.setSaveError(null));
    dispatch(serviceLevelRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.UPSERT_V2_URL,
        convertObjectForSave(data)
      );
      const entities = get(
        normalize([result.serviceLevel], serviceLevelsSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.serviceLevel);
      }
    } catch (e) {
      captureException(e);
      dispatch(serviceLevelRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(serviceLevelRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const serviceLevels = serviceLevelSelector().getDenormalizedObjects()(
      getState()
    );
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(serviceLevels, tableState, (ids, count) => {
        dispatch(serviceLevelViewActions.setList(ids, count));
      })
    );
  };
};

/**
 * Fetch the customers associated with this service level
 * @param serviceLevelId
 * @param tableState
 * @returns {Function}
 */
export const fetchCustomers = (serviceLevelId, tableState) => {
  return async dispatch => {
    dispatch(customerRequestActions.setLoadAllError(null));
    dispatch(customerRequestActions.setIsLoadingAll(true));
    try {
      const result = await fetchObjectsFromAPI(
        `${constants.FETCH_CUSTOMER_LIST_URL}/${serviceLevelId}`,
        "data"
      );

      const entities = get(
        normalize(result.customers, customersSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Store the full list of ids in the reducer
      dispatch(
        serviceLevelViewActions.setRelatedObjects(
          constants.RELATION_CUSTOMERS,
          serviceLevelId,
          result.customers.map(customer => {
            return customer.id;
          })
        )
      );

      dispatch(updateCustomersSortFilterLimit(serviceLevelId, tableState));
    } catch (e) {
      captureException(e);
      dispatch(customerRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(customerRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the customers for the table
 * @param serviceLevelId
 * @param tableState
 * @returns {Function}
 */
export const updateCustomersSortFilterLimit = (serviceLevelId, tableState) => {
  return async (dispatch, getState) => {
    const state = getState();
    const customers = serviceLevelViewSelector().getRelatedObjects(
      CUSTOMER_REDUCER_NAME,
      serviceLevelId
    )(state);

    // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(customers, tableState, (ids, count) => {
        dispatch(
          serviceLevelViewActions.setRelatedFilteredList(
            constants.RELATION_CUSTOMERS,
            serviceLevelId,
            ids,
            count
          )
        );
      })
    );
  };
};

export const actions = {
  ...objectActions,
  fetchObject,
  fetchObjects,
  fetchCustomers,
  updateSortFilterLimit,
  updateCustomersSortFilterLimit,
  upsertObject
};
