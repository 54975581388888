import { VIEW_REDUCER_NAME } from "./constants";

export const makeViewConstants = reducerName => {
  return {
    RESET: `store/${VIEW_REDUCER_NAME}/${reducerName}/RESET`,
    SET_LIST: `store/${VIEW_REDUCER_NAME}/${reducerName}/SET_LIST`,
    SET_ALL: `store/${VIEW_REDUCER_NAME}/${reducerName}/SET_ALL`,
    SET_RELATED_OBJECTS: `store/${VIEW_REDUCER_NAME}/${reducerName}/SET_RELATED_OBJECTS`,
    SET_RELATED_FILTERED_LIST: `store/${VIEW_REDUCER_NAME}/${reducerName}/SET_RELATED_FILTERED_LIST`
  };
};
