import React from 'react';

import {
  arrayOf,
  bool,
  func,
  string,
  oneOfType,
} from 'prop-types';
import { Select } from 'semantic-ui-react';

import { createOptionsFromArray } from '../../../helpers/form/util';

/**
 * 
 * @param {*} allItems
 * @param {*} onChangeItems
 * @param {*} selectedItems
 * @param {*} name
 * @param {*} placeholder
 * @param {*} multiple 
 */
export const FilterSelect = ({
  allItems,
  onChangeItems,
  selectedItems,
  name,
  placeholder = "Make a selection",
  multiple = true,
  clearable = false,
}) => {
  const options = createOptionsFromArray(allItems);
  
  return (
    <div className="select-wrapper d-flex">
      <div className="flex-grow-1 select-container">
        <Select
          upward={false}
          multiple={multiple}
          fluid
          size="huge"
          value={selectedItems}
          openOnFocus={false}
          search
          name={name}
          placeholder={placeholder}
          selection
          clearable={clearable}
          options={options}
          onChange={(e, { value }) => {
            if(multiple){
              return onChangeItems([...value]);
            }
            onChangeItems(value);
          }}
        />
      </div>
    </div>
  );
};

FilterSelect.propTypes = {
  allItems: arrayOf(string),
  onChangeItems: func.isRequired,
  selectedItems: oneOfType([arrayOf(string), string]),
  name: string.isRequired,
  placeholder: string,
  multiple: bool,
};
