import { schema } from 'normalizr';

import {
  REDUCER_NAME as SERVICE_LEVEL_REDUCER_NAME,
} from '../../store/object/serviceLevel/constants';
// import { servicesSchema } from './service';

export const serviceLevelSchema = new schema.Entity(SERVICE_LEVEL_REDUCER_NAME);

export const serviceLevelsSchema = [serviceLevelSchema];
