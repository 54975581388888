import React from "react";

import { times } from "lodash";
import { Progress } from "semantic-ui-react";
import { number, object } from "prop-types";

export const PageFiller = ({ lines, header, footer, className }) => {
  return (
    <div className={`d-flex flex-column half-opacity ${className}`}>
      {!!header && header}
      {times(lines, (i) => {
        return (
          <Progress
            key={i}
            active
            size="small"
            className="mx-1 mt-4 mb-0"
            percent={100}
          />
        );
      })}

      {footer && footer}
    </div>
  );
};

PageFiller.defaultProps = {
  lines: 1,
  header: null,
  footer: null,
};

PageFiller.propTypes = {
  lines: number,
  header: object,
  footer: object,
};
