import { schema } from 'normalizr';

import {
  REDUCER_NAME as TRANSFER_REDUCER_NAME,
} from '../../store/object/transfer/constants';
import { locationSchema } from './location';
import { userSchema } from './user';

export const transferSchema = new schema.Entity(TRANSFER_REDUCER_NAME, {
  location_from: locationSchema,
  location_to: locationSchema,
  user: userSchema
});

export const transfersSchema = [transferSchema];
