import { fromJS } from 'immutable';
import { get } from 'lodash';

import { getUser } from '../../../helpers/auth';

export const initialLocationObjectState = () => {
  const user = getUser();
  const location = get(user, "defaultLocation");
  if (location) {
    return fromJS({ [location.id]: location });
  }
  return fromJS({});
};
