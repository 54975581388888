import React, { useState } from "react";
import { get } from "lodash";
import cx from "classnames";
import { array, bool, number, func } from "prop-types";

export const PaginationBubbles = ({
  items,
  activeItem,
  setActiveItem,
  clickable,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className="list-pagination pb-2">
      {items.map((item, index) => {
        return (
          <div
            key={get(item, "id")}
            onClick={() => {
              if (activeIndex === index || !clickable) {
                return;
              }
              setActiveIndex(index);
              setActiveItem(get(items[index], "id"));
            }}
            className={cx({
              "bubble ml-1": true,
              active: activeItem === get(item, "id"),
              pointer: clickable,
            })}
          ></div>
        );
      })}
    </div>
  );
};

PaginationBubbles.defaultProps = {
  clickable: true,
};

PaginationBubbles.propTypes = {
  clickable: bool,
  items: array,
  activeItem: number,
  setActiveItem: func,
};
