import { createSelector } from "reselect";
import { makeViewSelector } from "../selectors";
import { allConstants as constants } from "./constants";

export const goodViewReducer = () => state =>
  state.getIn([constants.VIEW_REDUCER_NAME, constants.REDUCER_NAME]);

export const goodViewSelector = () => {
  return {
    ...makeViewSelector(constants, goodViewReducer),
    getLocationId: () => {
      return createSelector(
        goodViewReducer(),
        viewState => {
          return viewState.get(constants.LOCATION_ID);
        }
      );
    },
    getProductClassId: () => {
      return createSelector(
        goodViewReducer(),
        viewState => {
          return viewState.get(constants.PRODUCTION_CLASS_ID);
        }
      );
    }
  };
};
