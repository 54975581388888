import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "inventoryActivities";
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}admin/ia/5000`;
export const FETCH_URL = `${process.env.REACT_APP_API_URL}admin/ia`;
export const FETCH_HISTORY_URL = `${process.env.REACT_APP_API_URL}admin/history/:endDate.json`;
export const REVERSE_URL = `${process.env.REACT_APP_API_URL}admin/ia/reverse/:id`;
export const REDUCER_NAME = "inventoryActivities";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_LIST_URL,
  FETCH_URL,
  REVERSE_URL,
  FETCH_HISTORY_URL,
  UPSERT_PROPERTY
};
