import { REPORT_REDUCER_NAME } from "../../report/constants";
import * as reportConstants from "../constants";
import { makeReportConstants } from "../util";
export const REDUCER_NAME = "productClassReceipts";
export const START_DATE = "startDate";
export const END_DATE = "endDate";

export const SET_START_DATE = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_START_DATE`;
export const SET_END_DATE = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_END_DATE`;
export const FETCH_EXCEL_URL = `${process.env.REACT_APP_API_URL}admin/report/invoice_sales/:date1/:date2/xlsx`;
export const FETCH_RESULTS_URL = `${process.env.REACT_APP_API_URL}admin/report/invoice_sales/:date1/:date2/json`;

// Action Types
const defaultConstants = makeReportConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...reportConstants,
  START_DATE,
  END_DATE,
  REDUCER_NAME,
  FETCH_EXCEL_URL,
  FETCH_RESULTS_URL,
  SET_START_DATE,
  SET_END_DATE
};
