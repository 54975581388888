import { get } from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  objectsSchema,
  serviceScheduleSchema,
  serviceSchedulesSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import {
  actions as serviceScheduleRequestActions,
} from '../../request/serviceSchedule/actions';
import { sortAndLimitResults } from '../../view/actions';
import {
  actions as serviceScheduleViewActions,
} from '../../view/serviceSchedule/actions';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';
import { serviceScheduleSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of objects
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(serviceScheduleRequestActions.setLoadAllError(null));
    dispatch(serviceScheduleRequestActions.setIsLoadingAll(true));
    try {
      const serviceSchedules = await fetchObjectsFromAPI(
        constants.FETCH_LIST_URL,
        "data.serviceSchedules"
      );

      // Normalize the result and store the serviceSchedules in redux
      const entities = get(
        normalize(serviceSchedules, serviceSchedulesSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(serviceSchedules, tableState, (ids, count) => {
          dispatch(serviceScheduleViewActions.setList(ids, count));
        })
      );
      dispatch(serviceScheduleRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(
        serviceScheduleRequestActions.setLoadAllError(getErrorMessage(e))
      );
    } finally {
      dispatch(serviceScheduleRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = id => {
  return async dispatch => {
    dispatch(serviceScheduleRequestActions.setLoadError(null));
    dispatch(serviceScheduleRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(`${constants.FETCH_URL}/${id}`);
      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          serviceSchedule: serviceScheduleSchema
        }),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(serviceScheduleRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(serviceScheduleRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(serviceScheduleRequestActions.setSaveError(null));
    dispatch(serviceScheduleRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(constants.UPSERT_URL, data);
      const entities = get(
        normalize([result.serviceSchedule], serviceSchedulesSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.serviceSchedule);
      }
    } catch (e) {
      captureException(e);
      dispatch(serviceScheduleRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(serviceScheduleRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const serviceSchedules = serviceScheduleSelector().getDenormalizedObjects()(
      getState()
    );
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(serviceSchedules, tableState, (ids, count) => {
        dispatch(serviceScheduleViewActions.setList(ids, count));
      })
    );
  };
};

export const actions = {
  ...objectActions,
  fetchObject,
  fetchObjects,
  updateSortFilterLimit,
  upsertObject
};
