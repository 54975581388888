import React from 'react';

import {
  get,
  isEmpty,
} from 'lodash';
import {
  array,
  bool,
  func,
  object,
} from 'prop-types';
import {
  Button,
  Grid,
  Icon,
  Modal,
} from 'semantic-ui-react';

import {
  CheckboxGroup,
  FormSection,
} from '../../../../../components/Form';
import {
  ROLE_ADMIN,
  ROLE_EMPLOYEE,
  ROLE_SUPER_ADMIN,
} from '../../../../../constants/session';
import { hasRoles } from '../../../../../helpers/auth';
import { PermissionsList } from './PermissionsList';

const toSentenceCase = (identifier) => {
  return identifier
    .replace(/_/g, ' ')
    .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()); // Convert to sentence case
}

const AccessControlModalComponent = ({
  form,
  onClose,
  isOpen,
  roles,
  groups,
  permissions,
  user,
}) => {
  const adminPermissionsDisabled = !hasRoles(
    { roles: get(form.getFieldState("roles"), "value", []) },
    [ROLE_EMPLOYEE, ROLE_ADMIN, ROLE_SUPER_ADMIN]
  );
  return (
    <>
      <Modal
        title="Access Control"
        icon="key"
        open={isOpen}
        onClose={onClose}
        closeIcon
      >
        <Modal.Header className="d-flex justify-content-between pb-2">
          <Icon name="key" className="mr-2" />
          <span className="flex-grow-1">Access Control</span>
        </Modal.Header>
        <Modal.Content scrolling>
          <Grid>
            <Grid.Row columns={2}>
              {!isEmpty(roles) && (
                <Grid.Column>
                  <FormSection
                    color="red"
                    title="Roles"
                    className="h-100"
                    intro="Select which roles this user should have."
                  >
                    <CheckboxGroup
                      options={roles.map(role => {
                        return {
                          ...role,
                          label: toSentenceCase(role.name),
                        }
                      })}
                      name="roles"
                      labelPath="label"
                    />
                  </FormSection>
                </Grid.Column>
              )}
              {!isEmpty(groups) && (
                <Grid.Column>
                  <FormSection
                    color="green"
                    title="Groups"
                    className="h-100"
                    intro="Select which groups this user belongs to."
                  >
                    <CheckboxGroup
                      options={groups}
                      name="groups"
                      labelPath="identifier"
                    />
                  </FormSection>
                </Grid.Column>
              )}
            </Grid.Row>
            <Grid.Row columns={1}>
              {!isEmpty(permissions) && (
                <Grid.Column>
                  <FormSection
                    color="yellow"
                    title="Functions"
                    className="h-100"
                    disabled={adminPermissionsDisabled}
                    intro="Grant user permissions to pre-defined system functions."
                  >
                    <PermissionsList
                      permissions={permissions}
                      user={user}
                      form={form}
                      disabled={adminPermissionsDisabled}
                    />
                  </FormSection>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" primary onClick={onClose}>
            Close <Icon name="right chevron" />
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

AccessControlModalComponent.propTypes = {
  user: object.isRequired,
  permissions: array.isRequired,
  roles: array.isRequired,
  groups: array.isRequired,
  isOpen: bool,
  onClose: func.isRequired,
};

AccessControlModalComponent.defaultProps = {
  onClose: () => {},
};

export const AccessControlModal = AccessControlModalComponent;
