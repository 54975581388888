import React from "react";

import { withRouter } from "react-router-dom";
import { Button, Card, Grid, Icon } from "semantic-ui-react";

import { ErrorBoundaryComponent } from "../../../components";
import { object } from "prop-types";
import { WEB_ROUTE_LOGOUT } from "../../../constants/routes";

class ForbiddenComponent extends ErrorBoundaryComponent {
  render() {
    const { history } = this.props;
    return (
      <div className="view-login full-height">
        <div className="rose" />
        <Grid
          centered
          columns={1}
          verticalAlign="middle"
          className="full-height"
        >
          <Grid.Column>
            <Card className="hide-opacity centered middle big-shadow fade-in-and-scale text-center">
              <Card.Content className="p-8">
                <Icon
                  name="ban"
                  color="red"
                  className="mb-3 mx-auto"
                  size="big"
                />
                <Card.Header>403</Card.Header>
                <Card.Description>
                  Sorry, you do not have access to that page.
                  <div className="mt-2">
                    <Button
                      basic
                      size="mini"
                      color="red"
                      onClick={() => {
                        history.push("/");
                      }}
                    >
                      Go Back
                    </Button>
                  </div>
                  <div className="mt-2">
                    <a href={WEB_ROUTE_LOGOUT}>Logout</a>
                  </div>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

ForbiddenComponent.propTypes = {
  history: object,
};

export const Forbidden = withRouter(ForbiddenComponent);
