import { VIEW_REDUCER_NAME } from "../constants";
import * as viewConstants from "../constants";
import { makeViewConstants } from "../util";
export const REDUCER_NAME = "goods";

export const LOCATION_ID = "locationId";
export const PRODUCTION_CLASS_ID = "productClassId";

export const SET_LOCATION_ID = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_LOCATION_ID`;
export const SET_PRODUCTION_CLASS_ID = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_PRODUCTION_CLASS_ID`;

// Action Types
const defaultConstants = makeViewConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...viewConstants,
  REDUCER_NAME,
  LOCATION_ID,
  PRODUCTION_CLASS_ID,
  SET_LOCATION_ID,
  SET_PRODUCTION_CLASS_ID
};
