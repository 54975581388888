import React from 'react';

import {
  get,
  isEmpty,
  find,
  orderBy
} from 'lodash';
import {
  array, bool,
} from 'prop-types';
import { connect } from 'react-redux';
import {
  Table,
} from 'semantic-ui-react';
import { DateTimeCell } from '../../../../../../components/DataTable/Cells/DateTimeCell';
import { SALES_GOAL_TYPES } from './../../../../../../constants/general';
import { routeSelector } from './../../../../../../store/object/route/selectors';

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

/**
 * A component used to render the header of a page such as a form or report
 */
const GoalsListTableComponent = ({
  goals,
  routes = [],
  includeServiceRoute = false
}) => {
  const sortedGoals = orderBy(goals, [(goal) => goal.type, (goal) => goal.year, (goal) => Number(goal.month)], ['asc', 'desc', 'asc']);
  return (
    <Table striped compact className="data-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Type</Table.HeaderCell>
          {includeServiceRoute && <Table.HeaderCell>Route</Table.HeaderCell>}
          <Table.HeaderCell>Month</Table.HeaderCell>
          <Table.HeaderCell>Year</Table.HeaderCell>
          <Table.HeaderCell>Value</Table.HeaderCell>
          <Table.HeaderCell>Date Set</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      {!isEmpty(goals) && (
        <Table.Body>
          {sortedGoals.map(goal => {
              const type = find(SALES_GOAL_TYPES, {value: get(goal, "type")});
              const month = monthNames[get(goal, "month") - 1]
              const route = find(routes, {id: get(goal, "service_route_id")})
              return (
                <Table.Row key={get(goal, "id")}>
                  <Table.Cell>
                    {type ? type.text : get(goal, "type")}
                  </Table.Cell>
                  {includeServiceRoute && <Table.Cell>
                    {get(route, "name")}
                  </Table.Cell>}
                  <Table.Cell>
                    {month}
                  </Table.Cell>
                  <Table.Cell>
                    {get(goal, "year")}
                  </Table.Cell>
                  <Table.Cell>
                    {get(goal, "value")}
                  </Table.Cell>
                  <Table.Cell>
                    <DateTimeCell value={get(goal, "updated_at")} />
                  </Table.Cell>
                </Table.Row>
              );
          })}
        </Table.Body>
      )}
    </Table>
  );
};

GoalsListTableComponent.propTypes = {
  goals: array,
  includeServiceRoute: bool,
};

const mapStateToProps = (state, props) => {
  const routeSelectorHandle = routeSelector();
  return {
    routes: routeSelectorHandle.getObjects()(state),
  };
};

export const GoalsListTable = connect(
  mapStateToProps,
  {}
)(GoalsListTableComponent);
