import { createSelector } from 'reselect';

import { objectReducer } from '../object/selectors';
import {
  APP,
  CONFIRMATION_MODAL_IS_VISIBLE,
  CONFIRMATION_MODAL_TEXT,
  CONFIRMATION_MODAL_YES_CLICKED,
  CURRENT_LOCATION_ID,
  CURRENT_LOCATION_IS_LOCKED,
  CURRENT_PORTAL_ACCOUNT_ID,
  PORTAL_ACCOUNT_SELECT_MODAL_IS_VISIBLE,
  PORTAL_ACCOUNTS,
  SIDEBAR_IS_OPEN
} from "./constants";

export const appSelector = () => state => state.get(APP);

export const isConfirmationModalVisible = () =>
  createSelector(
    appSelector(),
    state => state.get(CONFIRMATION_MODAL_IS_VISIBLE)
  );

export const getConfirmationModalText = () =>
  createSelector(
    appSelector(),
    state => state.get(CONFIRMATION_MODAL_TEXT).toJS()
  );

export const isConfirmationModalYesClicked = () =>
  createSelector(
    appSelector(),
    state => state.get(CONFIRMATION_MODAL_YES_CLICKED)
  );

export const isCurrentLocationLocked = () =>
  createSelector(
    appSelector(),
    state => state.get(CURRENT_LOCATION_IS_LOCKED)
  );

export const getCurrentLocation = () =>
  createSelector(
    appSelector(),
    objectReducer(),
    (appState, objectState) => {
      const id = appState.get(CURRENT_LOCATION_ID);
      if (id) {
        const entities = objectState.toJS();
        return entities["locations"][id];
      }
    }
  );

export const isAccountSelectModalVisible = () =>
  createSelector(
    appSelector(),
    state => state.get(PORTAL_ACCOUNT_SELECT_MODAL_IS_VISIBLE)
  );

export const getCurrentPortalAccount = () =>
  createSelector(
    appSelector(),
    state => state.get(CURRENT_PORTAL_ACCOUNT_ID)
  );

export const getPortalAccounts = () =>
  createSelector(
    appSelector(),
    state => state.get(PORTAL_ACCOUNTS)
  );

export const isSidebarOpen = () =>
  createSelector(
    appSelector(),
    state => state.get(SIDEBAR_IS_OPEN)
  );