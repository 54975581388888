import { REPORT_REDUCER_NAME } from "../../report/constants";
import * as reportConstants from "../constants";
import { makeReportConstants } from "../util";
export const REDUCER_NAME = "gcActivity";
export const PERIOD_ID = "periodId";
export const SALES = "sales";
export const REDEMPTIONS = "redemptions";

export const SET_PERIOD_ID = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_PERIOD_ID`;
export const SET_REDEMPTIONS = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_REDEMPTIONS`;
export const SET_SALES = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_SALES`;
export const FETCH_RESULTS_URL = `${process.env.REACT_APP_API_URL}admin/report/gc?periodId=:periodId`;

// Action Types
const defaultConstants = makeReportConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...reportConstants,
  PERIOD_ID,
  REDUCER_NAME,
  SET_PERIOD_ID,
  FETCH_RESULTS_URL,
  SET_REDEMPTIONS,
  SET_SALES,
  REDEMPTIONS,
  SALES
};
