import { any } from "prop-types";
import React from "react";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { hasPermission, hasRoles } from "../../../../../helpers/auth";
import { LINK_USER_EDIT } from "../../../../../constants/links";
import { PERMISSION_USER_IMPERSONATE, ROLE_EMPLOYEE } from "../../../../../constants/session";

const ActionCell = React.memo(({ row, value, tableProps }) => {
  if (!value) {
    return null;
  }
  const currentUser = get(tableProps, "currentUser");
  return (
    <span className="action-cell">
      <Link
        to={{
          pathname: LINK_USER_EDIT.route.replace(":id", value),
          state: LINK_USER_EDIT.state,
        }}
        className="pl-1"
      >
        <Icon name="edit" size="large" />
      </Link>

      {currentUser && hasPermission(currentUser, PERMISSION_USER_IMPERSONATE) && hasRoles(row, [ROLE_EMPLOYEE]) && (
        <button
          type="button"
          className="pl-1 btn-link"
          onClick={() => {
            tableProps.onLoginAs(row);
          }}
        >
          <Icon name="sign-in" size="large" />
        </button>
      )}
    </span>
  );
});

ActionCell.propTypes = {
  value: any,
};

export const renderActionCell = (props) => {
  return <ActionCell {...props} />;
};
