import { schema } from 'normalizr';

import {
  REDUCER_NAME as SERVICE_PERIOD_REDUCER_NAME,
} from '../../store/object/servicePeriod/constants';

export const servicePeriodSchema = new schema.Entity(
  SERVICE_PERIOD_REDUCER_NAME,
  {}
);

export const servicePeriodsSchema = [servicePeriodSchema];
