import cx from "classnames";
import { bool } from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import { Icon, Input } from "semantic-ui-react";
import { AppMenu } from "../Menu";
import { WEB_ROUTE_CUSTOMER_LIST } from "../../../../constants";
import { isSidebarOpen } from "../../../../store/app/selectors";
import { setSidebarIsOpen } from "../../../../store/app/actions";

export const AppSidebarComponent = ({isOpen, setSidebarIsOpen}) => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const handleSearchChange = (e, input) => {
    setSearchValue(input.value)
    return;
  }
  const handleSearchOnBlur = () => {
    // Redirect to Customer list view.
    history.push({
      pathname: WEB_ROUTE_CUSTOMER_LIST,
      state: { search: searchValue }
    });
    // Clear search input.
    setSearchValue('');
  }
  const classes = cx({
    "app-sidebar": true,
    "is-open": isOpen
  });
  return (
    <div className={classes}>
      <header className="app-sidebar-header">
        <h2>{isOpen ? "WITHERSPOON" : ""}</h2>
        <button
            className="menu-toggle pointer mr-1"
            onClick={() => {
              setSidebarIsOpen(!isOpen);
            }}
          >
            <Icon name={isOpen ? "chevron right" : "bars"} size="large" />
          </button>
      </header>
      <div className="app-menu">
        <div className="px-1" onClick={() => {
          if(!isOpen){
            setSidebarIsOpen(!isOpen);
          }
        }}>
          <Input
            icon="search"
            fluid
            disabled={!isOpen || history.location.pathname === WEB_ROUTE_CUSTOMER_LIST}
            size="mini"
            onChange={handleSearchChange}
            placeholder="Customer..."
            value={searchValue}
            onBlur={handleSearchOnBlur}
            onKeyPress={ e => {
              if(e.key === "Enter"){
                handleSearchOnBlur();
              }
            }}
          />
        </div>
        <nav>
          <AppMenu />
        </nav>
      </div>
    </div>
  );
};

AppSidebarComponent.propTypes = {
  isOpen: bool,
};

const mapStateToProps = state => {
  return {
    isOpen: isSidebarOpen()(state),
  };
};

export const AppSidebar = connect(
  mapStateToProps,
  {
    setSidebarIsOpen: setSidebarIsOpen
  }
)(AppSidebarComponent);
