import dayjs from "dayjs";
import { fromJS } from "immutable";
import { initialViewState } from "../reducer";
import { MONTH, YEAR } from "./constants";

export const initialServiceVisitViewState = constants => {
  const endDate = dayjs();
  const startDate = endDate.add(-1, "day");
  return fromJS({
    ...initialViewState(constants).toJS(),
    startDate: startDate.format("MM/DD/YYYY"),
    endDate: endDate.format("MM/DD/YYYY"),
    month: parseInt(endDate.format("MM")),
    year: parseInt(endDate.format("YYYY"))
  });
};

export const serviceVisitReducer = (state, action, constants) => {
  switch (action.type) {
    case constants.SET_DATE:
      return state.set(action.dateType, action.date);
    case constants.SET_MONTH:
      return state.set(MONTH, action.month);
    case constants.SET_YEAR:
      return state.set(YEAR, action.year);
    default:
      return state;
  }
};
