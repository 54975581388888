import { isEmpty } from 'lodash';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { objectsSchema } from '../../helpers/normalizers';
import * as baseConstants from './constants';

export const objectReducer = () => state =>
  state.get(baseConstants.OBJECT_REDUCER_NAME);

/**
 * Creates a generic group of selectors that allow us to access our objects stored in the object reducer
 * @param constants
 * @param reducer An object reducer such as users, vendors, etc...
 */
export const makeObjectSelector = (constants, reducer) => {
  return {
    getLoadAllError: () => {
      return createSelector(
        reducer(),
        state => state.get(constants.LOADING_ALL_ERROR)
      );
    },

    isLoaded: id => {
      return createSelector(
        reducer(),
        state => state.getIn([constants.IS_LOADED, id])
      );
    },

    isLoadingAll: () => {
      return createSelector(
        reducer(),
        state => state.get(constants.IS_LOADING_ALL)
      );
    },

    getDenormalizedObject: (id = 0) => {
      id = parseInt(id, 10);

      return createSelector(
        objectReducer(),
        state => {
          // If there is no id, just create an empty object
          if (!id) {
            return {};
          }
          const entities = state.toJS();
          const object = entities[constants.REDUCER_NAME][id];
          if (object) {
            const results = denormalize(
              { [constants.REDUCER_NAME]: [object.id] },
              objectsSchema,
              entities
            )[[constants.REDUCER_NAME]];
            if (!isEmpty(results)) {
              return results[0];
            }
            return {};
          }
        }
      );
    },

    getObject: id => {
      return createSelector(
        reducer(),
        state => {
          const object = state.get('' + id);
          return object ? object.toJS() : null;
        }
      );
    },

    getDenormalizedObjects: () =>
      createSelector(
        objectReducer(),
        state => {
          const entities = state.toJS();
          const objects = entities[constants.REDUCER_NAME];
          return denormalize(
            { [constants.REDUCER_NAME]: Object.keys(objects) },
            objectsSchema,
            entities
          )[[constants.REDUCER_NAME]];
        }
      ),

    getObjects: () => {
      return createSelector(
        reducer(),
        state => {
          let objects = state;
          if (objects) {
            objects = objects.toJS();
            return Object.keys(objects).map(key => {
              return objects[key];
            });
          }

          return [];
        }
      );
    }
  };
};
