import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "noteCategory";
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}api/v2/note/category`;
export const FETCH_URL = `${process.env.REACT_APP_API_URL}api/v2/note/category/:id`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}api/v2/note/category`;

export const REDUCER_NAME = "noteCategories";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_LIST_URL,
  UPSERT_URL,
  FETCH_URL,
};
