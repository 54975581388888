import React, { useEffect } from "react";
import { get, isNull } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrentAccountObject } from "../../../../helpers/auth";
import * as appActions from "../../../../store/app/actions";
import { CustomerBilling } from "./CustomerBilling";
import { CustomerContacts } from "./CustomerContacts";
import { CustomerInfo } from "./CustomerInfo";
import { object, array, func, bool } from "prop-types";
import { customerObjectActions } from "../../../../store/object/customer";
import { customerRequestSelector } from "../../../../store/request/customer/selectors";
import { customerSelector } from "../../../../store/object/customer/selectors";

const CustomerSidebarComponent = ({
  history,
  account,
  accounts,
  customer,
  toggleAccountSelectModalVisible,
  customerIsLoading,
  customerIsLoaded,
  customerIsSaving,
  customerLoadError,
  fetchCustomer,
  upsertProfilePhoto,
  upsertPortalObject,
}) => {
  const currentAccount = getCurrentAccountObject();
  useEffect(() => {
    if (!customerIsLoaded && !customerIsLoading && !customerLoadError && currentAccount) {
      fetchCustomer(get(currentAccount, "customer.id"));
    }
  }, [customerLoadError, customerIsLoading, customerIsLoaded, fetchCustomer, currentAccount]);
  // Need to fetch customer.
  return (
    <div className="sidebar-card-column">
      <CustomerInfo
        history={history}
        account={account}
        accounts={accounts}
        customer={customer}
        customerIsLoading={customerIsLoading || customerIsSaving}
        openAccountSwitchModal={() => {
          toggleAccountSelectModalVisible(true);
        }}
        uploadProfilePhoto={upsertProfilePhoto}
        upsertPortalObject={upsertPortalObject}
        isSaving={customerIsSaving}
      />

      <CustomerBilling
        history={history}
        account={account}
        accounts={accounts}
        customer={customer}
        loading={customerIsLoading && isNull(get(customer, "billing", null))}
      />

      <CustomerContacts
        account={account}
        accounts={accounts}
        customer={customer}
        loading={customerIsLoading && isNull(get(customer, "contacts", null))}
      />
    </div>
  );
};

CustomerSidebarComponent.propTypes = {
  account: object,
  accounts: array,
  toggleAccountSelectModalVisible: func,
  history: object,
  customer: object,
  customerIsLoading: bool,
  customerIsLoaded: bool,
  fetchCustomer: func,
};

const mapStateToProps = (state, props) => {
  let customerId = get(getCurrentAccountObject(), "customer.id");
  const requestSelector = customerRequestSelector();
  const objectSelector = customerSelector();
  return {
    customerIsLoading: requestSelector.getIsLoading()(state),
    customerIsSaving: requestSelector.getIsSaving()(state),
    customerIsLoaded: requestSelector.getIsLoaded(customerId)(state),
    customerLoadError: requestSelector.getLoadError()(state),
    customer: objectSelector.getDenormalizedObject(customerId)(state),
  };
};

export const CustomerSidebar = withRouter(
  connect(
    mapStateToProps,
    {
      toggleAccountSelectModalVisible: appActions.toggleAccountSelectModalVisible,
      fetchCustomer: customerObjectActions.fetchPortalObject,
      upsertProfilePhoto: customerObjectActions.upsertProfilePhoto,
      upsertPortalObject: customerObjectActions.upsertPortalObject,
    }
  )(CustomerSidebarComponent)
);
