import React from 'react';

import { isEmpty } from 'lodash';
import {
  arrayOf,
  bool,
  element,
  string,
} from 'prop-types';
import { Segment } from 'semantic-ui-react';

import { renderBasicForm } from '../../../helpers/form';
import { formField } from '../../../helpers/shapes';

/**
 * A component used to render the header of a page such as a form or report
 */
export const FormSection = React.memo(
  ({ children, className, title, color, intro, fields, disabled = false }) => {
    if (isEmpty(fields) && isEmpty(children)) {
      return null;
    }
    return (
      <Segment color={color} className={className} disabled={disabled}>
        {title && <h3 className="mb-1">{title}</h3>}
        {intro && <p className="mb-0 text-gray50">{intro}</p>}
        {fields && renderBasicForm(fields)}
        {children}
      </Segment>
    );
  }
);

FormSection.propTypes = {
  className: string,
  children: element,
  disabled: bool,
  title: string,
  color: string,
  fields: arrayOf(formField),
};
