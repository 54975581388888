import { schema } from 'normalizr';

import {
  REDUCER_NAME as SERVICE_SCHEDULE_REDUCER_NAME,
} from '../../store/object/serviceSchedule/constants';

export const serviceScheduleSchema = new schema.Entity(
  SERVICE_SCHEDULE_REDUCER_NAME,
  {}
);

export const serviceSchedulesSchema = [serviceScheduleSchema];
