import { schema } from 'normalizr';

import {
  SHIPMENT_ACTION_REDUCER_NAME,
} from '../../store/object/shipmentAction';

export const shipmentActionSchema = new schema.Entity(
  SHIPMENT_ACTION_REDUCER_NAME
);

export const shipmentActionsSchema = [shipmentActionSchema];
