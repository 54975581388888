import { fromJS } from "immutable";
import { initialReportState } from "../reducer";
import { PERIOD_ID, REDEMPTIONS, SALES } from "./constants";

export const initialGcActivityReportState = constants => {
  return fromJS({
    ...initialReportState(constants).toJS(),
    [PERIOD_ID]: null,
    [SALES]: [],
    [REDEMPTIONS]: []
  });
};

export const gcActivityReportReducer = (state, action, constants) => {
  switch (action.type) {
    case constants.SET_PERIOD_ID:
      return state.set(PERIOD_ID, action.periodId);

    case constants.SET_SALES:
      return state.setIn([constants.SALES], fromJS(action.data));

    case constants.SET_REDEMPTIONS:
      return state.setIn([constants.REDEMPTIONS], fromJS(action.data));
    default:
      return state;
  }
};
