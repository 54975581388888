import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "purchaseorder";
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}admin/po/grid`;
export const FETCH_URL = `${process.env.REACT_APP_API_URL}admin/po`;
export const FETCH_NOTE_LIST_URL = `${process.env.REACT_APP_API_URL}admin/note/po`;
export const FETCH_PURCHASE_ORDER_PDF_URL = `${process.env.REACT_APP_API_URL}admin/po/pdf/:purchaseOrderId`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}admin/po`;
export const UPSERT_NOTE_URL = `${process.env.REACT_APP_API_URL}admin/note`;

export const REDUCER_NAME = "purchaseOrders";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_LIST_URL,
  FETCH_PURCHASE_ORDER_PDF_URL,
  UPSERT_URL,
  FETCH_NOTE_LIST_URL,
  FETCH_URL,
  UPSERT_NOTE_URL,
  UPSERT_PROPERTY
};
