import cx from "classnames";
import React, { useState, useRef } from "react";
import { Input, Label } from "semantic-ui-react";

/**
 * Form Input 
 */
export const FormInput = ({
  input,
  id,
  placeholder,
  label,
  focus = false,
  className,
  inputType = "text",
  size,
  autoComplete,
  required,
  disabled,
  readOnly,
  transparent,
  appendedLabel,
  meta: { modified, error },
  forceShowError = false,
  min = false,
  max = false,
  step = false,
  selectOnFocus = false,
  labelPosition,
  padded = true,
  floatingError = false,
}) => {
  const [hasFocus, setHasFocus] = useState(focus);
  const [hasAutoFocus, setHasAutoFocus] = useState();
  const inputRef = useRef();
  const isValid = !error;
  className = cx({ valid: isValid }, className);
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true,
    "has-focus": hasFocus,
    "has-value": !!input.value
  });
  const containerClassName = cx({
    "form-input-container": true,
    "position-relative": true,
    "mb-0": !padded,
    "m-0": inputType === 'hidden',
  });
  const showError = (!!(forceShowError && error) || !!(modified && error)) && inputType !== 'hidden';

  const handleChange = (event) => {
    let value = event.target.value;
    // Filter phones to prevent letters
    if (inputType === "tel") {
      value = value.replaceAll(/[a-z]/g, "");
      value = value.replaceAll(/[A-Z]/g, "");
    }
    input.onChange(value);
  }

  if (focus && inputRef && inputRef.current && !hasAutoFocus) {
    inputRef.current.focus()
    setHasAutoFocus(true);
  }

  return (
    <div className={containerClassName}>
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      {(showError && floatingError) && <Label basic color='red' pointing='below' style={{ position: 'absolute', top: '-2.7rem', whiteSpace: 'nowrap' }}>
        {error}
      </Label>}
      <Input
        {...input}
        ref={inputRef}
        placeholder={placeholder}
        type={inputType}
        error={showError}
        readOnly={readOnly}
        size={size}
        min={min.toString()}
        max={max.toString()}
        step={step.toString()}
        focus={focus}
        transparent={transparent}
        disabled={disabled}
        autoComplete={autoComplete}
        className={className}
        onFocus={() => {
          setHasFocus(true);
          if (selectOnFocus) {
            inputRef.current.select()
          }
        }}
        onBlur={() => {
          setHasFocus(false);
        }}
        onChange={handleChange}
        label={
          appendedLabel ? { basic: true, content: appendedLabel } : undefined
        }
        labelPosition={appendedLabel ? labelPosition : undefined}
        fluid
      ><input className={className} /></Input>
      {(showError && !floatingError) && <span className="input-error">{error}</span>}
    </div>
  );
};
