import { lazy } from "react";

/**
 * Lazy load all of the components
 */

export const HomeComponent = lazy(() =>
  import("../Home").then(module => ({
    default: module.Home
  }))
);

export const BillingComponent = lazy(() =>
  import("../Billing").then(module => ({
    default: module.Billing
  }))
);

export const ServiceComponent = lazy(() =>
  import("../Service").then(module => ({
    default: module.Service
  }))
);
