import { VIEW_REDUCER_NAME } from "../constants";
import * as viewConstants from "../constants";
import { makeViewConstants } from "../util";
export const REDUCER_NAME = "inventoryActivities";

export const END_DATE = "endDate";
export const SET_DATE = `store/${VIEW_REDUCER_NAME}/${REDUCER_NAME}/SET_DATE`;

// Action Types
const defaultConstants = makeViewConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...viewConstants,
  REDUCER_NAME,
  END_DATE,
  SET_DATE
};
