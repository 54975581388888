import { any } from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { LINK_VENDOR_EDIT } from "../../../../../constants";

const ActionCell = React.memo(({ value }) => {
  if (!value) {
    return null;
  }
  return (
    <span className="action-cell pl-1">
      <Link
        to={{
          pathname: LINK_VENDOR_EDIT.route.replace(":id", value),
          state: LINK_VENDOR_EDIT.state
        }}
        className="pl-1"
      >
        <Icon name="edit" size="large" />
      </Link>

      <button type="button" className="pl-1 btn-link">
        <Icon name="file pdf" size="large" />
      </button>
    </span>
  );
});

ActionCell.propTypes = {
  value: any
};

export const renderActionCell = ({ value }) => {
  return <ActionCell value={value} />;
};
