import { REPORT_REDUCER_NAME } from "../../report/constants";
import * as reportConstants from "../constants";
import { makeReportConstants } from "../util";
export const REDUCER_NAME = "fatFinger";
export const PERIOD_ID = "periodId";
export const PREVIOUS = "previous";

export const SET_PERIOD_ID = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_PERIOD_ID`;
export const SET_PREVIOUS = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_PREVIOUS`;
export const GET_PREVIOUS = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/GET_PREVIOUS`;
export const SET_PREVIOUS_IS_LOADING = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_PREVIOUS_IS_LOADING`;
export const SET_PREVIOUS_IS_LOADED = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_PREVIOUS_IS_LOADED`;
export const FETCH_RESULTS_URL = `${process.env.REACT_APP_API_URL}admin/report/statement_fat_finger/:periodId/json`;
export const FETCH_PREVIOUS_FAT_FINGER_URL = `${process.env.REACT_APP_API_URL}admin/report/statement_ff_prev/:statementId`;
export const POST_ACCEPT_FAT_FINGER_URL = `${process.env.REACT_APP_API_URL}admin/report/statement_fat_finger_ex`;

// Action Types
const defaultConstants = makeReportConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...reportConstants,
  PERIOD_ID,
  PREVIOUS,
  REDUCER_NAME,
  GET_PREVIOUS,
  SET_PERIOD_ID,
  SET_PREVIOUS,
  SET_PREVIOUS_IS_LOADING,
  SET_PREVIOUS_IS_LOADED,
  FETCH_RESULTS_URL,
  FETCH_PREVIOUS_FAT_FINGER_URL,
  POST_ACCEPT_FAT_FINGER_URL,
};
