import { array, bool, func, number, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Button, Container, Dimmer, Loader } from "semantic-ui-react";
import { ListComponent, ListHeader, PageAlert } from "../../../../components";
import { VendorCreateModal } from "../../../../components/VendorCreateModal";
import { LINK_VENDOR_EDIT } from "../../../../constants";
import { actions as objectActions } from "../../../../store/object/vendor/actions";
import { vendorRequestSelector } from "../../../../store/request/vendor/selectors";
import { vendorViewSelector } from "../../../../store/view/vendor/selectors";
import { DataTableWrapper } from "../../../../components/DataTable/Wrapper";
import { columns } from "./columns";

class VendorListComponent extends ListComponent {
  constructor(props) {
    super(props);
    this.state.columns = columns;
    this.state.sortOrder = {
      column: "name",
      isAscending: true
    };
  }

  componentDidMount() {
    const {
      isLoadingAll,
      isLoadedAll,
      fetchObjects,
      updateSortFilterLimit
    } = this.props;
    if (!isLoadedAll && !isLoadingAll) {
      fetchObjects(this.state);
    } else {
      // We don't need to load anything from the api, but we need to setup the first page of the grid
      updateSortFilterLimit(this.state);
    }
  }

  setIsVendorModalOpen = isVendorModalOpen => {
    this.setState({ isVendorModalOpen });
  };

  render() {
    const { history, total, isLoadingAll, loadError, objects } = this.props;
    const { isVendorModalOpen } = this.state;
    const tableState = {
      ...this.state,
      onRefreshClick: this.handleRefresh,
      onExportClick: this.handleExportClick,
      onPageChange: this.handlePageChange,
      onSortChange: this.handleSortChange,
      onLimitChange: this.handleLimitChange,
      onSearchChange: this.handleSearchChange
    };
    return (
      <Container className="view-vendors view-list view" as="article" fluid>
        <Dimmer active={isLoadingAll} inverted>
          <Loader size="large">Loading</Loader>
        </Dimmer>
        <ListHeader
          searchPlaceholder="Search Vendors"
          title="Vendors"
          rightChildren={
            <Button primary onClick={() => this.setIsVendorModalOpen(true)}>
              New
            </Button>
          }
          tableState={tableState}
          total={total}
          limitCustomizable
        />
        <PageAlert title="Error While Retrieving Vendors" message={loadError} />
        <DataTableWrapper
          columns={columns}
          rows={objects}
          tableState={tableState}
          total={total}
        />

        <VendorCreateModal
          isOpen={isVendorModalOpen}
          onClose={() => {
            this.setIsVendorModalOpen(false);
          }}
          onSubmit={vendor => {
            history.push(
              LINK_VENDOR_EDIT.route.replace(":id", vendor.id),
              LINK_VENDOR_EDIT.state
            );
            this.setIsVendorModalOpen(false);
          }}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const requestSelector = vendorRequestSelector();
  const viewSelector = vendorViewSelector();
  return {
    isLoadedAll: requestSelector.getIsLoadedAll()(state),
    isLoadingAll: requestSelector.getIsLoadingAll()(state),
    loadError: requestSelector.getLoadAllError()(state),
    objects: viewSelector.getList()(state),
    total: viewSelector.getTotal()(state)
  };
};

VendorListComponent.displayName = "VendorList";
VendorListComponent.propTypes = {
  exportObjects: func.isRequired,
  fetchObjects: func.isRequired,
  isLoadedAll: bool,
  isLoadingAll: bool,
  loadError: string,
  total: number,
  updateSortFilterLimit: func.isRequired,
  objects: array.isRequired
};

export const VendorList = connect(
  mapStateToProps,
  { ...objectActions }
)(VendorListComponent);
