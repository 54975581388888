import { getApiVersion, getApiEnvironment, getUser } from "../../../../helpers/auth";
import { get } from "lodash";
import { bool, func, object } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { Button, Icon, Popup } from "semantic-ui-react";
import * as actions from "../../../../store/auth/actions";
import { goToLogin } from "../../../../helpers/auth";
import { LocationSelect } from '../../../../components/Form/LocationSelect';
import { setCurrentLocationId } from '../../../../store/app/actions';
import { fetchApiChangelog } from './requests';
import {
  getCurrentLocation,
  isCurrentLocationLocked,
} from "../../../../store/app/selectors";
import { WEB_ROUTE_PORTAL_DEFAULT } from '../../../../constants/routes';
import { ROLE_CUSTOMER } from '../../../../constants/session';
import ChangelogViewer from "../ChangelogViewer";

const AppHeaderComponent = ({
  history,
  match,
  location,
  isCurrentLocationLocked,
  setCurrentLocationId,
  logout,
}) => {
  const { location: { hash } } = history;
  const [changelogViewerOpen, setChangelogViewerOpen] = React.useState(hash.includes('#changelog'));
  const user = getUser();
  const name = get(user, "name");
  const apiVersion = getApiVersion();
  const apiEnvironment = getApiEnvironment();
  const onLogout = () => {
    logout();
    goToLogin(history);
  };
  return (<>
    <header className="app-header">
      <div className="pull-right">
        <ul className="app-header-actions">
          {location && (
            <li className="app-header-location">
              <Popup
                flowing
                hoverable
                trigger={
                  <Button className="btn-link">
                    <Icon name="map marker alternate" />
                    {location.name}
                  </Button>
                }
              >
                <div className="d-flex align-items-center">
                  <label className="flex-grow-1 mr-3 fw-600">
                    {!isCurrentLocationLocked
                      ? "Choose Location:"
                      : "Location locked:"}
                  </label>
                  <div className="flex-grow-1">
                    <LocationSelect
                      disabled={isCurrentLocationLocked}
                      error={isCurrentLocationLocked}
                      onSelectLocation={(location) => {
                        setCurrentLocationId(location.id);
                      }}
                      locationId={location.id}
                    />
                  </div>
                </div>
              </Popup>
            </li>
          )}
          {user && user.hasRole(ROLE_CUSTOMER) && get(user, 'all_accounts', []).length > 0 && (
            <li>
              <Button className="btn-link" onClick={() => {
                window.open(WEB_ROUTE_PORTAL_DEFAULT, '_blank');
              }}>
                <Icon name="home" />
                My Customer Portal
              </Button>
            </li>
          )}
          <li>
            <span>{name}</span>
          </li>
          {process.env.REACT_APP_VERSION && (
            <li>
              <Button className="btn-link strong" title={`API: ${apiVersion} env: ${apiEnvironment || 'n/a'}`} onClick={() => {
                setChangelogViewerOpen(true);
                history.push(`${history.location.pathname}#changelog`);
                }}>
                v{process.env.REACT_APP_VERSION}
              </Button>
            </li>
          )}
          <li>
            <Button className="btn-link strong" onClick={onLogout}>
              Logout
            </Button>
          </li>
        </ul>
      </div>
    </header>
    <ChangelogViewer
      history={history} 
      match={match} 
      open={changelogViewerOpen} 
      onClose={() => {
        setChangelogViewerOpen(false);
        history.push(`${history.location.pathname}`);
      }}
      fetchApiChangelog={fetchApiChangelog}
      />
    </>
  );
};

AppHeaderComponent.propTypes = {
  location: object,
  setCurrentLocationId: func.isRequired,
  isCurrentLocationLocked: bool,
};

const mapStateToProps = createStructuredSelector({
  location: getCurrentLocation(),
  isCurrentLocationLocked: isCurrentLocationLocked(),
});

export const AppHeader = withRouter(connect(
    mapStateToProps,
    { 
      setCurrentLocationId,
      logout: actions.logout,
    }
  )(AppHeaderComponent)
);
