import { object } from "prop-types";
import React from "react";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { LINK_CUSTOMER_EDIT } from "../../../../constants";

export const CustomerCell = React.memo(({ row, primaryKeyName = "customer_id", tab = "general" }) => {
  if (!row || !get(row, primaryKeyName)) {
    return null;
  }
  return (
    <span className="action-cell pl-1">
      <Link
        target="_blank"
        to={{
          pathname: `${LINK_CUSTOMER_EDIT.route.replace(":id", get(row, primaryKeyName))}#${tab}`,
          state: LINK_CUSTOMER_EDIT.state
        }}
        className="pl-1"
      >
        <Icon name="edit" size="small" />
        {get(row, "identifier")}
      </Link>
    </span>
  );
});

CustomerCell.propTypes = {
  row: object.isRequired
};

export const renderCustomerCell = ({ value, row }) => {
  return <CustomerCell row={row} />;
};
