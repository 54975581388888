import { REPORT_REDUCER_NAME } from "../../report/constants";
import * as reportConstants from "../constants";
import { makeReportConstants } from "../util";
export const REDUCER_NAME = "expiringCC";
export const DAYS = "days";

export const SET_DAYS = `store/${REPORT_REDUCER_NAME}/${REDUCER_NAME}/SET_DAYS`;
export const FETCH_RESULTS_URL = `${process.env.REACT_APP_API_URL}admin/report/cc?days=:days&type=json`;
export const FETCH_PDF_URL = `${process.env.REACT_APP_API_URL}admin/report/cc?days=:days&type=pdf`;

// Action Types
const defaultConstants = makeReportConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...reportConstants,
  DAYS,
  REDUCER_NAME,
  FETCH_PDF_URL,
  FETCH_RESULTS_URL,
  SET_DAYS
};
