import { get } from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  rolesSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import { actions as roleRequestActions } from '../../request/role/actions';
import { sortAndLimitResults } from '../../view/actions';
import { actions as roleViewActions } from '../../view/role/actions';
import {
  fetchObjectsFromAPI,
  makeObjectActions,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';
import { roleSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Load a list of roles
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(roleRequestActions.setLoadAllError(null));
    dispatch(roleRequestActions.setIsLoadingAll(true));
    try {
      const roles = await fetchObjectsFromAPI(constants.FETCH_LIST_URL);
      // Normalize the result and store the roles in redux
      const entities = get(normalize(roles, rolesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(roles, tableState, (ids, count) => {
          dispatch(roleViewActions.setList(ids, count));
        })
      );
      dispatch(roleRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(roleRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(roleRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(roleRequestActions.setSaveError(null));
    dispatch(roleRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(constants.UPSERT_URL, data);
      const entities = get(
        normalize([result.role], rolesSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.role);
      }
    } catch (e) {
      captureException(e);
      dispatch(roleRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(roleRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const roles = roleSelector().getDenormalizedObjects()(getState());
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(roles, tableState, (ids, count) => {
        dispatch(roleViewActions.setList(ids, count));
      })
    );
  };
};

export const actions = {
  ...objectActions,
  fetchObjects,
  updateSortFilterLimit,
  upsertObject
};
