export const OBJECT_REDUCER_NAME = "object";
export const ALL = "all";
export const RELATION_BED_PACKETS = "bedPackets";
export const RELATION_DOCS = "docs";
export const RELATION_NOTES = "notes";
export const RELATION_CUSTOMERS = "customers";
export const RELATION_SEARCH = "search";
export const RELATION_GOODS = "goods";
export const RELATION_GOOD_VENDORS = "goodVendors";
export const RELATION_ESTIMATES = "estimates";
export const RELATION_INVOICES = "invoices";
export const RELATION_PAYMENTS = "payments";
export const RELATION_PAYMENT_LOGS = "paymentLogs";
export const RELATION_BALANCE = "balance";
export const RELATION_SERVICE_VISITS = "serviceVisits";
export const RELATION_SERVICE_VISITS_BY_MONTH = "serviceVisitsByMonth";
export const RELATION_INVENTORY_ACTIVITIES = "inventoryActivities";
export const RELATION_INVENTORY_LOTS = "inventoryLots";
export const RELATION_STATEMENTS = "statements";
export const RELATION_WORK_ORDERS = "workOrders";
