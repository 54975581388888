import React from 'react';
import { Field } from 'react-final-form';
import { OrganizationSelect } from './OrganizationSelect';
import { DefaultLocationSelect } from './DefaultLocationSelect/index';

export const renderDefaultLocationSelect = (field) => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      component={DefaultLocationSelect}
      size={field.size || "large"}
      validate={field.validate}
      multi={field.multiple}
      filterBy={field.filterBy}
      options={field.options}
    />
  );
}

export const renderOrganizationSelect = (field) => {
  return (
    <Field
      id={field.name}
      key={field.name}
      name={field.name}
      disabled={field.disabled}
      required={field.required}
      className={field.className}
      label={field.label}
      component={OrganizationSelect}
      size={field.size || "large"}
      validate={field.validate}
      multi={field.multiple}
      filterBy={field.filterBy}
    />
  );
}