import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "profitcenter";
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}admin/profitcenter`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}admin/profitcenter`;
export const FETCH_URL = `${process.env.REACT_APP_API_URL}admin/profitcenter`;

export const REDUCER_NAME = "profitCenters";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_LIST_URL,
  FETCH_URL,
  UPSERT_URL,
  UPSERT_PROPERTY
};
