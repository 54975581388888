import * as requestConstants from "../constants";
export const REDUCER_NAME = "chemicals";

// Action Types
const defaultConstants = requestConstants.makeRequestConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...requestConstants,
  REDUCER_NAME
};
