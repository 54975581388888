import { schema } from 'normalizr';

import {
  REDUCER_NAME as DOC_REDUCER_NAME,
} from '../../store/object/doc/constants';
import { userSchema } from './user';

export const docSchema = new schema.Entity(DOC_REDUCER_NAME, {
  author: userSchema
});

export const docsSchema = [docSchema];
