import { fromJS } from "immutable";
import { initialReportState } from "../reducer";

export const initialArsmOutstandingBedWorkReportState = constants => {
  return fromJS({
    ...initialReportState(constants).toJS(),
  });
};

export const arsmOutstandingBedWorkReportReducer = (state, action, constants) => {
  switch (action.type) {
    default:
      return state;
  }
};
