import {
  filter,
  get,
  isArray,
  isEmpty,
  isNull,
  isNumber,
  isString,
  orderBy,
  slice,
  uniq
} from "lodash";

import { filterWithMultiple } from "./helpers";

/**
 * Sort an array of rows and limit them based on the table state
 * @param rows
 * @param tableState
 * @param columns
 * @returns {Array|*}
 */
export const sortFilterAndLimit = (rows, tableState, columns) => {
  if (!tableState || isEmpty(rows)) {
    return rows;
  }
  const { page, limit, search, sortOrder } = tableState;
  rows = filterCollection(rows, search, columns);
  rows = sortCollection(rows, sortOrder, columns);
  return limitCollection(rows, page, limit);
};

/**
 * Sort and limit the rows
 * @param rows
 * @param tableState
 * @param columns
 * @returns {Array}
 */
export const sortAndLimit = (rows, tableState) => {
  if (!tableState) {
    return rows;
  }
  const { page, limit, sortOrder, columns } = tableState;
  rows = sortCollection(rows, sortOrder, columns);
  return limitCollection(rows, page, limit);
};

/**
 * Filter out any rows that don't have matching values for the searchable columns
 * @param search
 * @param rows
 * @param columns
 * @param attributeFilter
 */
export const filterCollection = (rows, search, columns, attributeFilter) => {
  if (search) {
    const exp = new RegExp(search, "gi");
    const searchableColumns = filter(columns, column => {
      return column.isSearchable;
    });
    let values = [];
    let match = [];
    if (!isEmpty(searchableColumns)) {
      rows = filter(rows, row => {
        match.length = 0;
        values.length = 0;
        searchableColumns.forEach(column => {
          let value;
          if(isArray(column.path)){
            value = column.path.reduce((previousValue, currentValue) => {
              return `${previousValue} ${get(row, currentValue)}`;
            }, '');
          } else {
            value = get(row, column.path);
          }
          values.push(value)
        });
        values = uniq(values);
        match = values.filter(v => {
          return exp.test(v)
        });        
        return match.length > 0;
      });
    }
  }
  if (attributeFilter) {
    rows = filterWithMultiple(rows, attributeFilter);
  }
  return rows;
};

/**
 * Sort an array
 * @param sortOrder
 * @param rows
 * @param columns
 * @returns {Array}
 */
export const sortCollection = (rows, sortOrder, columns = []) => {
  if (sortOrder) {
    const { column: sortColumn, isAscending } = sortOrder;
    const column = columns.find(col => {
      return col.path === sortColumn;
    });

    // If this is a numeric sort
    if (column && column.isNumeric) {
      return orderBy(
        rows,
        [
          row => {
            const value = get(row, sortColumn, "") || "";
            return isNumber(value) ? value : parseFloat(value);
          }
        ],
        [isAscending ? "asc" : "desc"]
      );
    }

    rows = orderBy(
      rows,
      [
        row => {
          if (isArray(sortColumn)) {
            let value = "";
            sortColumn.forEach(columnPath => {
              value = `${value} ${get(row, columnPath, "")}`;
            });
            value = value.trim();
            return value.toLowerCase();
          } else {
            const value = get(row, sortColumn, "") || "";
            return isString(value) ? value.toLowerCase() : value;
          }
        }
      ],
      [isAscending ? "asc" : "desc"]
    );
  }

  return rows;
};

export const limitCollection = (rows, page, limit) => {
  const start = Math.max(page - 1, 0) * limit;
  const end = start + limit;

  return slice(rows, start, end);
};

export const getObjectValueForColumn = (row, column) => {
  let value = "";
  if (column.exportRenderer) {
    return column.exportRenderer(row);
  }
  if (column.path && isArray(column.path)) {
    column.path.forEach(columnPath => {
      const val = get(row, columnPath, "");
      value = !isNull(val) ? `${value} ${val}` : value;
    });
    value = value.trim();
  } else {
    value = get(row, column.path, "");
  }
  if (!value && !isEmpty(column.path2)) {
    value = get(row, column.path2, "");
  }
  return value;
};
