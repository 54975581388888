import React from "react";
import { func, array } from "prop-types";
import { chunk, get } from "lodash";
import { Link } from "react-router-dom";
import { Icon, Menu, Table } from "semantic-ui-react";
import { WEB_ROUTE_APPOINTMENT_EDIT } from "../../../../../../../constants";
import dayjs from "dayjs";

export const List = ({goToMapView, data = []}) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const perPage = 11;
  const totalPages = Math.ceil(data.length / perPage);
  const nextPage = () => {
    if(currentPage < totalPages){
      setCurrentPage(currentPage + 1);
    }
  }
  const previousPage = () => {
    if(currentPage > 1){
      setCurrentPage(currentPage - 1);
    }
  }
  const pages = chunk(data, perPage);
  return (
    <div>
      <div className='pull-right p-2 pointer' onClick={goToMapView}>Back to Map</div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Customer Name</Table.HeaderCell>
            <Table.HeaderCell>Route</Table.HeaderCell>
            <Table.HeaderCell>Time/Date</Table.HeaderCell>
            <Table.HeaderCell>Service Worker</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {(pages.length > 0) && pages[currentPage - 1].map(row => {
            return <Table.Row key={get(row, 'id')}>
              <Table.Cell>{get(row, 'customer.first_name')} {get(row, 'customer.last_name')}</Table.Cell>
              <Table.Cell>{get(row, 'customer.csr.route.name')}</Table.Cell>
              <Table.Cell>
                <Link to={WEB_ROUTE_APPOINTMENT_EDIT.replace(':id', get(row, 'id'))}>
                  <Icon name='external' />
                  { dayjs(get(row, 'appointment_date')).format('MM/DD/YYYY hh:mm a') }
                </Link>
              </Table.Cell>
              <Table.Cell>{get(row, 'user.name')}</Table.Cell>
            </Table.Row>
          })}
        </Table.Body>
        {(totalPages > 1) && <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='4'>
              <Menu floated='right' pagination>
                <Menu.Item as='a' icon onClick={previousPage}>
                  <Icon name='chevron left' />
                </Menu.Item>
                <Menu.Item as='a' icon onClick={nextPage}>
                  <Icon name='chevron right' />
                </Menu.Item>
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>}
      </Table>
    </div>
  )
}

List.propTypes = {
  data: array,
  goToMapView: func,
};
