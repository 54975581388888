import React from 'react';

import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';

import { MENU } from '../../../../constants/menu';
import { AppMenuItem } from '../MenuItem';

const AppMenuComponent = ({ location }) => {
  return (
    <ul className="app-menu">
      {MENU.map(item => {
        return <AppMenuItem item={item} location={location} key={item.key} />;
      })}
    </ul>
  );
};

AppMenuComponent.propTypes = {
  location: object.isRequired
};

export const AppMenu = withRouter(AppMenuComponent);
