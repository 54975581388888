import {
  get,
  uniq,
} from 'lodash';
import { normalize } from 'normalizr';

import { getErrorMessage, captureException } from '../../../helpers/error';
import {
  notesSchema,
  objectsSchema,
  transferSchema,
  transfersSchema,
  upsertNormalizedEntities,
} from '../../../helpers/normalizers';
import { actions as noteRequestActions } from '../../request/note/actions';
import {
  actions as transferRequestActions,
} from '../../request/transfer/actions';
import { sortAndLimitResults } from '../../view/actions';
import { actions as transferViewActions } from '../../view/transfer/actions';
import { transferViewSelector } from '../../view/transfer/selectors';
import {
  fetchFileFromAPI,
  fetchNotesFromAPI,
  fetchObjectsFromAPI,
  makeObjectActions,
  putObjectToAPI,
  upsertObjectToAPI,
} from '../actions';
import { allConstants as constants } from './constants';
import { transferSelector } from './selectors';

const objectActions = makeObjectActions(constants);

/**
 * Fetch a single object from the api
 * @param id
 * @returns {Function}
 */
export const fetchObject = id => {
  return async dispatch => {
    dispatch(transferRequestActions.setLoadError(null));
    dispatch(transferRequestActions.setIsLoading(true));
    try {
      const result = await fetchObjectsFromAPI(
        `${constants.FETCH_URL}/${id}`,
        "data"
      );

      // Normalize the result and store the other attributes in redux
      const entities = get(
        normalize(result, {
          ...objectsSchema,
          transfer: transferSchema
        }),
        "entities",
        {}
      );

      dispatch(upsertNormalizedEntities(entities));
    } catch (e) {
      captureException(e);
      dispatch(transferRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(transferRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Load a list of objects
 */
export const fetchObjects = tableState => {
  return async dispatch => {
    dispatch(transferRequestActions.setLoadAllError(null));
    dispatch(transferRequestActions.setIsLoadingAll(true));
    try {
      const transfers = await fetchObjectsFromAPI(constants.FETCH_LIST_URL);

      // Normalize the result and store the transfers in redux
      const entities = get(
        normalize(transfers, transfersSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      // Do a sort/filter on the results and store it in the view store
      dispatch(
        sortAndLimitResults(transfers, tableState, (ids, count) => {
          dispatch(transferViewActions.setList(ids, count));
        })
      );
      dispatch(transferRequestActions.setIsLoadedAll(true));
    } catch (e) {
      captureException(e);
      dispatch(transferRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(transferRequestActions.setIsLoadingAll(false));
    }
  };
};

export const convertObjectForSave = data => {
  return {
    id: data.id,
    xfer: {
      ...data,
      items: data.lines || [],
      toLocation: {
        id: data.location_to_id
      },
      fromLocation: {
        id: data.location_from_id
      },
      when: data.target_date
    }
  };
};

/**
 * Save an object on the api
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertObject = (data, onSuccess) => {
  return async dispatch => {
    dispatch(transferRequestActions.setSaveError(null));
    dispatch(transferRequestActions.setIsSaving(true));
    try {
      const result = await upsertObjectToAPI(
        constants.UPSERT_URL,
        convertObjectForSave(data)
      );
      const entities = get(
        normalize([result.transfer], transfersSchema),
        "entities",
        {}
      );
      dispatch(upsertNormalizedEntities(entities));

      if (onSuccess) {
        onSuccess(result.transfer);
      }
    } catch (e) {
      captureException(e);
      dispatch(transferRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(transferRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Reverse the transfer
 * @param transferLineId
 * @param transferId
 * @param status
 * @returns {Function}
 */
export const receiveTransferLine = (transferLineId, transferId, status) => {
  return async dispatch => {
    dispatch(transferRequestActions.setSaveError(null));
    dispatch(transferRequestActions.setIsSaving(true));
    try {
      await putObjectToAPI(
        constants.RECEIVE_LINE_URL.replace(
          ":transferLineId",
          transferLineId
        ).replace(":status", status),
        {}
      );
    } catch (e) {
      captureException(e);
      dispatch(transferRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(transferRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Reverse the transfer
 * @param transferId
 * @param onSuccess
 * @returns {Function}
 */
export const reverseTransfer = (transferId, onSuccess) => {
  return async dispatch => {
    dispatch(transferRequestActions.setSaveError(null));
    dispatch(transferRequestActions.setIsSaving(true));
    try {
      await upsertObjectToAPI(
        constants.REVERSE_URL.replace(":transferId", transferId),
        {}
      );

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      captureException(e);
      dispatch(transferRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(transferRequestActions.setIsSaving(false));
    }
  };
};

/**
 * Filter, Sort, and Trim the results for the table
 * @param tableState
 * @returns {Function}
 */
export const updateSortFilterLimit = tableState => {
  return async (dispatch, getState) => {
    const transfers = transferSelector().getDenormalizedObjects()(getState());
    // // Do a sort/filter on the results and store it in the view store
    dispatch(
      sortAndLimitResults(transfers, tableState, (ids, count) => {
        dispatch(transferViewActions.setList(ids, count));
      })
    );
  };
};

/**
 * Get the pdf of the transfer and download it
 * @param transferId
 * @returns {Function}
 */
export const fetchTransferPdf = transferId => {
  return async dispatch => {
    dispatch(transferRequestActions.setLoadError(null));
    dispatch(transferRequestActions.setIsLoading(true));
    try {
      await fetchFileFromAPI(
        constants.FETCH_TRANFER_PDF_URL.replace(":transferId", transferId),
        `transfer-${transferId}.pdf`
      );
    } catch (e) {
      captureException(e);
      dispatch(transferRequestActions.setLoadError(getErrorMessage(e)));
    } finally {
      dispatch(transferRequestActions.setIsLoading(false));
    }
  };
};

/**
 * Fetch the notes from the api and store their result in our reducer
 * @param id
 * @returns {Function}
 */
export const fetchNotes = id => {
  return async dispatch => {
    dispatch(noteRequestActions.setLoadAllError(null));
    dispatch(noteRequestActions.setIsLoadingAll(true));
    try {
      const notes = await dispatch(
        fetchNotesFromAPI(id, `${constants.FETCH_NOTE_LIST_URL}/${id}`)
      );

      dispatch(
        transferViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          id,
          notes.map(note => {
            return note.id;
          })
        )
      );
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setLoadAllError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsLoadingAll(false));
    }
  };
};

/**
 * Save a note on the api
 * @param parentId
 * @param data
 * @param onSuccess
 * @returns {Function}
 */
export const upsertNote = (parentId, data, onSuccess) => {
  return async (dispatch, getState) => {
    dispatch(noteRequestActions.setSaveError(null));
    dispatch(noteRequestActions.setIsSaving(true));
    try {
      data.kind = data.kind || "transfer";

      const result = await upsertObjectToAPI(constants.UPSERT_NOTE_URL, data);
      const entities = get(normalize([result], notesSchema), "entities", {});
      dispatch(upsertNormalizedEntities(entities));

      const notes = transferViewSelector().getNoteIds(parentId)(getState());
      const updatedIds = uniq([...notes, result.id]);
      dispatch(
        transferViewActions.setRelatedObjects(
          constants.RELATION_NOTES,
          parentId,
          updatedIds
        )
      );

      if (onSuccess) {
        onSuccess(notes);
      }
    } catch (e) {
      captureException(e);
      dispatch(noteRequestActions.setSaveError(getErrorMessage(e)));
    } finally {
      dispatch(noteRequestActions.setIsSaving(false));
    }
  };
};

export const actions = {
  ...objectActions,
  fetchNotes,
  fetchObject,
  fetchObjects,
  fetchTransferPdf,
  receiveTransferLine,
  reverseTransfer,
  updateSortFilterLimit,
  upsertNote,
  upsertObject
};
