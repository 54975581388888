import React, {
  useEffect,
  useState,
} from 'react';

import cx from 'classnames';
import {
  arrayOf,
  bool,
  func,
  number,
  object,
  string,
} from 'prop-types';
import { connect } from 'react-redux';
import { Select } from 'semantic-ui-react';

import { createOptionsFromObjects } from '../../../helpers/form/util';
import {
  actions as profitCenterObjectActions,
} from '../../../store/object/profitCenter/actions';
import {
  profitCenterSelector,
} from '../../../store/object/profitCenter/selectors';
import {
  profitCenterRequestSelector,
} from '../../../store/request/profitCenter/selectors';

const ProfitCenterSelectComponent = ({
  disabled,
  fetchProfitCenters,
  id,
  input,
  isLoadedAll,
  isLoadingAll,
  loadAllError,
  label,
  profitCenterId,
  profitCenters,
  onSelectProfitCenter,
  onSelectProfitCenterId,
  placeholder,
  required
}) => {
  if (input && input.value) {
    profitCenterId = input.value;
  }
  useEffect(() => {
    if (!isLoadedAll && !isLoadingAll && !loadAllError) {
      fetchProfitCenters();
    }
  }, [isLoadedAll, isLoadingAll, fetchProfitCenters, loadAllError]);

  const [hasFocus, setHasFocus] = useState(false);
  const profitCenterOptions = createOptionsFromObjects(profitCenters);
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1": true,
    "d-block": true,
    "has-focus": hasFocus,
    "has-value": !!profitCenterId
  });
  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <Select
        upward={false}
        fluid
        disabled={disabled}
        size="huge"
        value={profitCenterId}
        search
        name="profitCenter"
        loading={isLoadingAll}
        placeholder={placeholder}
        selection
        options={profitCenterOptions}
        onOpen={() => {
          setHasFocus(true);
        }}
        openOnFocus={false}
        onClose={() => {
          setHasFocus(false);
        }}
        onChange={(e, { value }) => {
          if (onSelectProfitCenter) {
            onSelectProfitCenter(
              profitCenters.find(profitCenter => profitCenter.id === value)
            );
          }
          if (onSelectProfitCenterId) {
            onSelectProfitCenterId(value);
          }
          if (input && input.onChange) {
            input.onChange(value);
          }
        }}
        onBlur={(e, { value }) => {
          if (value && input && input.onChange) {
            input.onChange(value);
          }
        }}
      />
    </div>
  );
};

ProfitCenterSelectComponent.propTypes = {
  fetchProfitCenters: func.isRequired,
  isLoadedAll: bool,
  isLoadingAll: bool,
  loadAllError: string,
  profitCenterId: number,
  onSelectProfitCenter: func,
  onSelectProfitCenterId: func,
  placeholder: string,
  profitCenters: arrayOf(object)
};

const mapStateToProps = (state, props) => {
  const profitCenterObjectSelector = profitCenterSelector();
  const requestSelector = profitCenterRequestSelector();
  return {
    isLoadingAll: requestSelector.getIsLoadingAll()(state),
    isLoadedAll: requestSelector.getIsLoadedAll()(state),
    loadAllError: requestSelector.getLoadAllError()(state),
    profitCenters: profitCenterObjectSelector.getDenormalizedObjects()(state)
  };
};

export const ProfitCenterSelect = connect(
  mapStateToProps,
  {
    fetchProfitCenters: profitCenterObjectActions.fetchObjects
  }
)(ProfitCenterSelectComponent);
