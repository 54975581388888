import * as objectConstants from "../constants";
import { makeObjectConstants } from "../util";

export const UPSERT_PROPERTY = "goodVendor";
export const FETCH_LIST_URL = `${process.env.REACT_APP_API_URL}admin/vendor/goods`;
export const FETCH_URL = `${process.env.REACT_APP_API_URL}admin/vendor/goods`;
export const FETCH_GOOD_VENDOR_LIST_URL = `${process.env.REACT_APP_API_URL}admin/goodvendorlist`;
export const FETCH_GOOD_VENDOR_PRICE_URL = `${process.env.REACT_APP_API_URL}admin/good/getCostForGoodVendor`;
export const UPSERT_URL = `${process.env.REACT_APP_API_URL}admin/vendor/goods`;
export const REDUCER_NAME = "goodVendors";

// Action Types
const defaultConstants = makeObjectConstants(REDUCER_NAME);
export const allConstants = {
  ...defaultConstants,
  ...objectConstants,
  REDUCER_NAME,
  FETCH_LIST_URL,
  FETCH_URL,
  FETCH_GOOD_VENDOR_LIST_URL,
  FETCH_GOOD_VENDOR_PRICE_URL,
  UPSERT_URL,
  UPSERT_PROPERTY
};
