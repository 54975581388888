export const SESSION_TOKEN_NAME = "qr_access_token";
export const SESSION_REFRESH_TOKEN_NAME = "qr_refresh_token";
export const SESSION_USER_NAME = "qr_user";
export const SESSION_API_VERSION = "api_version";
export const SESSION_API_ENVIRONMENT = "api_environment";
export const SESSION_CURRENT_PORTAL_ACCOUNT = "qr_portal_account";
export const SESSION_PORTAL_ACCOUNTS = "qr_portal_accounts";
export const SESSION_PERMISSIONS_NAME = "qr_permissions";

export const ROLE_USER = "ROLE_USER";
export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_EMPLOYEE = "ROLE_EMPLOYEE";
export const ROLE_ROUTE_LEADER = "ROLE_ROUTE_LEADER";
export const ROLE_SWITCH_USER = "ROLE_SWITCH_USER";
export const ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN";
export const ROLE_COMMISSION_MGR = "ROLE_COMMISSION_MGR";
export const ROLE_CUSTOMER = "ROLE_CUSTOMER";
export const ROLE_ROUTE_SUPERVISOR = "ROLE_ROUTE_SUPERVISOR";

export const PERMISSION_USER_IMPERSONATE = "admin.users.impersonate";
export const PERMISSION_TAX_EXEMPTION = "admin.invoices.tax_exemptions";
export const PERMISSION_INVOICE_UPDATE = "admin.invoices.edit";
