import { fromJS } from "immutable";
import { initialReportState } from "../reducer";
import { PERIOD_ID } from "./constants";

export const initialFatFingerReportState = (constants) => {
  return fromJS({
    ...initialReportState(constants).toJS(),
    [PERIOD_ID]: null,
  });
};

export const fatFingerReportReducer = (state, action, constants) => {
  switch (action.type) {
    case constants.SET_PERIOD_ID:
      return state.set(PERIOD_ID, action.periodId);
    case constants.SET_PREVIOUS_IS_LOADING:
      return state.set("previousIsLoading", action.previousIsLoading);
    case constants.SET_PREVIOUS_IS_LOADED:
      return state.set("previousIsLoaded", action.previousIsLoaded);
    case constants.SET_PREVIOUS:
      return state.set("previous", action.data);
    default:
      return state;
  }
};
