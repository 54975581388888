import { fromJS } from 'immutable';

import {
  AUTHENTICATION_ERROR,
  IS_AUTHENTICATING,
  IS_REFRESHING,
  IS_VERIFYING_TOKEN,
  REFRESH_ERROR,
  RESET,
  SET_AUTHENTICATION_ERROR,
  SET_IS_AUTHENTICATING,
  SET_IS_REFRESHING,
  SET_IS_VERIFYING_TOKEN,
  SET_REFRESH_ERROR,
  SET_USER,
  SET_VERIFICATION_ERROR,
  USER,
  VERIFICATION_ERROR,
} from './constants';

const initialState = () => {
  return fromJS({
    [AUTHENTICATION_ERROR]: null,
    [VERIFICATION_ERROR]: null,
    [REFRESH_ERROR]: null,
    [IS_AUTHENTICATING]: false,
    [IS_REFRESHING]: false,
    [IS_VERIFYING_TOKEN]: false,
    [USER]: {}
  });
};

function authReducer(state = initialState(), action) {
  switch (action.type) {
    case SET_AUTHENTICATION_ERROR:
      return state.set(AUTHENTICATION_ERROR, action.error);

    case SET_IS_AUTHENTICATING:
      return state.set(IS_AUTHENTICATING, action.status);

    case SET_REFRESH_ERROR:
      return state.set(REFRESH_ERROR, action.status);

    case SET_IS_REFRESHING:
      return state.set(IS_REFRESHING, action.status);

    case SET_VERIFICATION_ERROR:
      return state.set(VERIFICATION_ERROR, action.error);

    case SET_IS_VERIFYING_TOKEN:
      return state.set(IS_VERIFYING_TOKEN, action.status);
      
    case SET_USER:
      return state.set(USER, fromJS(action.user));

    case RESET:
      return initialState();

    default:
      return state;
  }
}

export default authReducer;
