import { camelCase, get, isEmpty, upperFirst } from "lodash";
import { toast } from "react-toastify";
import { getObjectValueForColumn } from "../components/DataTable/util";
import copy from "copy-to-clipboard";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import html2pdf from "html2pdf.js";

/**
 * Export a table to the clipboard
 * @param objects
 * @param tableState
 */
export const exportCopy = (objects, tableState) => {
  const exportableColumns = get(tableState, "columns", []).filter(
    column => !column.isExcludeFromExport
  );

  if (!isEmpty(exportableColumns)) {
    let headers = "";
    exportableColumns.forEach((column, index) => {
      headers = `${headers}${index > 0 ? "\t" : ""}${column.title}`;
    });

    let rows = "";
    objects.forEach(object => {
      let row = "";
      exportableColumns.forEach((column, index) => {
        const value = getObjectValueForColumn(object, column) || "";
        row = `${row}${index > 0 ? "\t" : ""}${
          value && value.trim ? value.trim() : value
        }`;
      });
      rows = `${rows}${String.fromCharCode(13)}${row}`;
    });

    const result = `${headers}${rows}`;
    copy(result);

    toast.success(`${objects.length} rows to clipboard successfully.`);
  }
};

/**
 * Exports the data in the table as xls
 * @param objects
 * @param tableState
 */
export const exportXls = (objects, tableState) => {
  const jsonData = createJsonStructure(objects, tableState);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const ws = XLSX.utils.json_to_sheet(jsonData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  saveAs(data, "export" + fileExtension);
  toast.success(`${objects.length} rows exported to excel successfully.`);
  return true;
};

/**
 * Take the data from the objects/tableState and structure it for json
 * @param objects
 * @param tableState
 * @returns {*}
 */
const createJsonStructure = (objects, tableState) => {
  const exportableColumns = get(tableState, "columns").filter(
    column => !column.isExcludeFromExport
  );
  return objects.map(object => {
    let row = {};

    exportableColumns.forEach((column, index) => {
      row[upperFirst(camelCase(column.title))] = getObjectValueForColumn(
        object,
        column
      );
    });
    return row;
  });
};

/**
 * Render the table as a pdf document
 * @param objects
 * @param tableState
 */
export const exportPdf = (objects, tableState) => {
  const table = document.querySelector(".data-table-container");
  // Unhide hidden columns.
  const hiddenColumns = document.querySelectorAll(".data-table-container .d-none");
  [].forEach.call(hiddenColumns, function(el) {
    el.classList.remove("d-none");
  });

  html2pdf()
    .set({
      html2canvas: { scale: 4 },
      filename: "export.pdf",
      margin: 1,
      jsPDF: { unit: "in", format: "legal", orientation: "landscape" }
    })
    .from(table)
    .save()
    .then( promise => {
      [].forEach.call(hiddenColumns, function(el) {
        el.classList.add("d-none");
      });
    })
};
