import { any, object, bool } from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

const EditActionCell = React.memo(({ value, link, displayId = false }) => {
  if (!value) {
    return null;
  }
  return (
    <span className="action-cell">
      <Link
        to={{
          pathname: link.route.replace(":id", value),
          state: link.state,
        }}
        className="pl-1"
      >
        {displayId ? <span className="fs-3 mr-1">{value}</span> : null}
        <Icon name="edit" size="large" />
      </Link>
    </span>
  );
});

EditActionCell.propTypes = {
  link: object.isRequired,
  value: any,
  displayId: bool,
};

export const renderEditActionCell = ({ value, rendererProps }) => {
  return (
    <EditActionCell
      value={value}
      link={rendererProps.link}
      displayId={rendererProps.displayId}
    />
  );
};
