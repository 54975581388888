import React from "react";

import { bool, func, number } from "prop-types";
import { Button, Icon, Pagination, Table } from "semantic-ui-react";

import { numberWithCommas } from "../../../helpers/util";
import { ExportPopup } from "../ExportPopup";

export const DataTableFooter = React.memo(
  ({
    columnCount,
    currentPage,
    perPage,
    total = 0,
    onPageChange,
    onExportClick,
    onRefreshClick,
    exportable = true,
    loading = false,
  }) => {
    const totalPages = Math.ceil(total / perPage);
    return (
      <Table.Footer data-html2canvas-ignore>
        <Table.Row>
          <Table.HeaderCell colSpan={columnCount}>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                {onRefreshClick && (
                  <Button
                    type="button"
                    basic
                    size="tiny"
                    icon="refresh"
                    onClick={onRefreshClick}
                  />
                )}
                {exportable && <ExportPopup onClick={onExportClick} />}
              </div>
              <div className="text-right d-block flex-grow-1">
                {total > perPage && (
                  <Pagination
                    totalPages={totalPages}
                    defaultActivePage={currentPage || 0}
                    onPageChange={onPageChange}
                    siblingRange={1}
                    boundaryRange={0}
                    ellipsisItem={null}
                  />
                )}
                {!loading ? (
                  <span>
                    <strong>{numberWithCommas(total)} items</strong>
                  </span>
                ) : (
                  <Icon name="hourglass outline" className="m-2 pulse-more" />
                )}
              </div>
            </div>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    );
  }
);

DataTableFooter.propTypes = {
  columnCount: number,
  currentPage: number,
  onExportClick: func.isRequired,
  onPageChange: func.isRequired,
  perPage: number,
  total: number,
  exportable: bool,
  loading: bool,
};
