import cx from "classnames";
import dayjs from "dayjs";
import React from "react";
import { DateTimeInput } from "semantic-ui-calendar-react";

/**
 * A component used to render a group of checkboxes
 */
export const FormDateTimePickerInput = ({
  input,
  id,
  label,
  placeholder,
  disabled,
  focus,
  className,
  required,
  dateFormat = null,
  minDate = null,
  maxDate = null,
  meta: { touched, error } = ""
}) => {
  const { name } = input;
  const isValid = !error;
  const labelClassName = cx({
    strong: true,
    "form-label": true,
    "pb-1 pl-1": true,
    "d-block": true,
    "has-value": !!input.value
  });

  const defaultFormat = dateFormat ? dateFormat : "MM/DD/YYYY hh:mm A"

  const value = input.value
    ? dayjs(input.value, "YYYY-MM-DD hh:mm A").format(defaultFormat)
    : "";

  className = cx({ valid: isValid }, className);
  return (
    <div className="form-input-container position-relative">
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <span className="pull-right text-gray25 text-normal">Required</span>
          )}
        </label>
      )}
      <DateTimeInput
        clearable
        value={value}
        closable
        disabled={disabled}
        dateTimeFormat={defaultFormat}
        id={id}
        animation="none"
        name={name}
        placeholder={placeholder}
        focus={focus}
        className={className}
        fluid
        minDate={minDate}
        maxDate={maxDate}
        onChange={(e, data) => {
          input.onChange(
            data.value
              ? dayjs(data.value, defaultFormat).format(defaultFormat)
              : null
          );
        }}
      />
    </div>
  );
};
